import React, { useEffect, useState, useMemo, useContext } from "react";
import "./EconomyDialog.css";
import {
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
  IconButton,
  Tooltip,
  RadioGroup,
  FormControlLabel,
  Radio,
  Tabs,
  Tab,
  Stack,
} from "@mui/material";
import print from "~/print-contracts/print-report";
import { centsToLocalString } from "~/shared/money";
import {
  addHours,
  differenceInMinutes,
  startOfDay,
  setHours,
  isValid,
  format,
} from "date-fns";
import MobileView from "./EconomyDialogMobileView";
import PrintReport from "./EconomyDialogPrint";

import {
  FcPlanner,
  FcPieChart,
  FcSalesPerformance,
  FcPrint,
} from "react-icons/fc";
import InfoContainerLarge from "./InfoContainerLarge";
import InfoContainerSmall from "./InfoContainerSmall";
import ProductsTable from "./ProductsTable";
import AdditionalServicesTable from "./AdditionalServicesTable";
import { Box } from "@mui/system";
import { AppContext } from "~/AppContext";
import { useFetchReservationsForEconomy } from "~/services/fetchReservations";
import ReservationTable from "../ReservationTable";
import { pageContainerStyle } from "~/styles/page-container";
import ReservationArchiveTable from "./ReservationsArchive.tsx";
import { development } from "../../features.js";

export default function EconomyDialog() {
  const { business, company, showAllCompanies, economyQueryDates, setEconomyQueryDates } =
    useContext(AppContext);

  const theme = useTheme();

  const selectedCompany = useMemo(() => {
    return company;
  }, [company]);

  const [resultsBy, setResultsBy] = useState("startAt");
  const [selectedTab, setSelectedTab] = useState("economy");
  const {
    paymentMethods,
    reservationServices,
    reservationsLoading,
    reservedVehicles,
    reservations,
  } = useFetchReservationsForEconomy(resultsBy, economyQueryDates);

  const categories = useMemo(() => {
    // Create an object to store unique categories
    const uniqueCategoriesMap = Array.from(reservedVehicles.values()).reduce(
      (categoriesMap, vehicle) => {
        const { category } = vehicle;
        const categoryId = category?.id;
        // Use 'no-category' as the default category ID if it is not provided
        const currentCategoryId = categoryId || "no-category";

        // Check if the vehicle has a category ID
        if (categoryId !== null && categoryId !== undefined) {
          // Check if the categoryId is already in the map
          if (!categoriesMap.hasOwnProperty(currentCategoryId)) {
            categoriesMap[currentCategoryId] = {
              ...category,
              id: category?.id ?? "no-category",
              name: category?.name ?? "Ei kategoriaa",
              orderNumber:
                categoryId === "no-category" ? 0 : category?.orderNumber,
            };
          }
        }
        return categoriesMap;
      },
      {}
    );

    // Convert the categoriesMap values back to an array
    const uniqueCategories = Object.values(uniqueCategoriesMap);

    const showAllCategoriesOption = {
      id: "showAllCategories",
      name: "Kaikki kategoriat",
    };
    // Sort the uniqueCategories array by orderNumber, placing null values at the end and sorting alphabetically

    const categories = [
      showAllCategoriesOption,
      ...uniqueCategories.sort((a, b) => {
        // Handle null values by placing them at the end
        const orderNumberA = a.orderNumber === null ? Infinity : a.orderNumber;
        const orderNumberB = b.orderNumber === null ? Infinity : b.orderNumber;

        // Sort by orderNumber, placing null values at the end
        return (
          orderNumberA - orderNumberB ||
          (a.name || "").localeCompare(b.name || "")
        );
      }),
    ];

    return categories;
  }, [reservedVehicles]);

  // console.log("reservationServices", reservationServices);
  // console.log("reservedVehicles", reservedVehicles);

  const [selectedCategory, setSelectedCategory] = useState(categories[0]?.id);
  const [showAllCategories, setShowAllCategories] = useState(true);
  const mobileView = useMediaQuery(theme.breakpoints.down("md"));
  const [printContract, setPrintContract] = useState(false);

  const weeklyhours = useMemo(() => {
    if (!selectedCompany) return;
    const date = startOfDay(new Date());
    const convertToHours = selectedCompany?.weeklyBusinessHours.map((item) => {
      if (item === null) return 0;
      const startTime = item.openAt.split(":");
      const start = addHours(
        new Date(date.getFullYear(), date.getMonth(), date.getDay()),
        startTime[0],
        startTime[1]
      );
      const endTime = item.closeAt.split(":");
      const end = addHours(
        new Date(date.getFullYear(), date.getMonth(), date.getDay()),
        endTime[0],
        endTime[1]
      );
      const result = differenceInMinutes(end, start) / 60;
      return result;
    });
    return convertToHours;
  }, [selectedCompany]);

  const changeSelectedCategory = (selectedCategory) => {
    if (selectedCategory === "showAllCategories") {
      setShowAllCategories(true);
    } else {
      setShowAllCategories(false);
      setSelectedCategory(selectedCategory.id);
    }
  };

  // Create an array of all the clients products
  // combined with the sales from selected time frame

  //set the star dates time to 00.00 and end dates time to 23.59. This prevents showing weird values when selecting a single day
  const handleChangeEconomyDate = (newValue) => {
    const start = setHours(newValue[0], 0);
    const end = setHours(newValue[1], 23);
    setEconomyQueryDates([start, end]);
    // setMinutes(new Date(2014, 8, 1, 11, 30, 40), 45)
  };

  // Calculate the values from sold products, services and total revenue from both of them

  const incomeFromVehicles = useMemo(() => {
    return reservedVehicles.reduce((a, b) => a + b.total, 0);
  }, [reservedVehicles]);

  const incomeFromServices = useMemo(() => {
    return reservationServices.reduce((a, b) => a + b.total, 0);
  }, [reservationServices]);

  const totalIncome = incomeFromServices + incomeFromVehicles;

  // Printing the report
  useEffect(() => {
    if (printContract) {
      setTimeout(() => {
        print(
          <PrintReport
            reservationServices={reservationServices}
            categories={categories}
            totalIncome={totalIncome}
            startDate={economyQueryDates[0]}
            endDate={economyQueryDates[1]}
            incomeFromServices={incomeFromServices}
            incomeFromVehicles={incomeFromVehicles}
            changeSelectedCategory={changeSelectedCategory}
            reservedVehicles={reservedVehicles}
            showAllCategories={showAllCategories}
            selectedCategory={selectedCategory}
          />
        );
        setPrintContract(false);
      }, 0);
    }
  }, [
    reservationServices,
    reservedVehicles,
    printContract,
    categories,
    economyQueryDates,
    incomeFromServices,
    incomeFromVehicles,
    selectedCategory,
    showAllCategories,
    totalIncome,
  ]);

  const economyTimeTitle = (
    <>
      {isValid(economyQueryDates[0]) && (
        <Typography
          style={{ fontFamily: "Roboto", fontSize: 24, marginRight: 5 }}
        >
          {format(new Date(economyQueryDates[0]), " dd.MM.yyyy ")}
        </Typography>
      )}
      <Typography style={{ fontFamily: "Roboto", fontSize: 20 }}>
        {" "}
        -{" "}
      </Typography>
      {isValid(economyQueryDates[1]) && (
        <Typography
          style={{ fontFamily: "Roboto", fontSize: 24, marginLeft: 5 }}
        >
          {format(new Date(economyQueryDates[1]), " dd.MM.yyyy ")}
        </Typography>
      )}
    </>
  );
  const handleChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  return (
    <Box
      sx={{
        ...pageContainerStyle,
        display: "flex",
        flexDirection: "column",
        position: "relative",
        paddingX: "10px",
        paddingBottom: "10px",
      }}
    >
      {!mobileView && (
        <Stack>
          <Tabs
            value={selectedTab}
            onChange={handleChange}
            textColor="primary"
            indicatorColor="primary"
            aria-label="economy-tabs"
          >
            <Tab value="economy" label="Tuotteet" />
            <Tab value="reservations" label="Varaukset" />
            {business?.organizationId && (
              <Tab value="reservationsArchive" label="Perutut" />
            )}
          </Tabs>
        </Stack>
      )}

      {!mobileView && selectedCompany !== null && (
        <>
          <Box sx={{ position: "absolute", top: 5, right: 5 }}>
            <Tooltip
              disableFocusListener
              disableTouchListener
              title="Tulosta Raportointi"
            >
              <IconButton onClick={() => setPrintContract(true)}>
                <FcPrint
                  style={{
                    fontSize: 40,
                    //  backgroundColor: "rgba(0, 0, 0, 0.3)",
                  }}
                />
              </IconButton>
            </Tooltip>
          </Box>
          <RadioGroup
            row
            sx={{ marginX: "auto", marginY: "10px" }}
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
            onChange={(e, value) => setResultsBy(value)}
            value={resultsBy}
          >
            {resultAdjusters.map((adjuster) => (
              <FormControlLabel
                value={adjuster.type}
                control={<Radio />}
                label={adjuster.title}
                key={adjuster.title}
              />
            ))}
          </RadioGroup>

          <Grid
            container
            direction="column"
            sx={{
              ...pageContainerStyle,
              height: 0,
              flexGrow: 1,
            }}
          >
            <EconomyView
              categories={categories}
              changeSelectedCategory={changeSelectedCategory}
              economyTimeTitle={economyTimeTitle}
              incomeFromServices={incomeFromServices}
              incomeFromVehicles={incomeFromVehicles}
              paymentMethods={paymentMethods}
              reservationServices={reservationServices}
              reservationsLoading={reservationsLoading}
              reservedVehicles={reservedVehicles}
              selectedCategory={selectedCategory}
              showAllCategories={showAllCategories}
              showAllCompanies={showAllCompanies}
              totalIncome={totalIncome}
              visibility={selectedTab === "economy"}
              weeklyhours={weeklyhours}
            />
            <ReservationTable
              economyReservations={reservations}
              reservationsLoading={reservationsLoading}
              visibility={selectedTab === "reservations"}
            />
            {selectedTab === "reservationsArchive" && (
              <ReservationArchiveTable
                searchType={resultsBy}
                economyQueryDates={economyQueryDates}
              />
            )}
          </Grid>
        </>
      )}
      {mobileView && (
        <MobileView
          categories={categories}
          changeSelectedCategory={changeSelectedCategory}
          economyQueryDates={economyQueryDates}
          handleChangeEconomyDate={handleChangeEconomyDate}
          incomeFromServices={incomeFromServices}
          incomeFromVehicles={incomeFromVehicles}
          loading={reservationsLoading}
          paymentMethods={paymentMethods}
          products={reservedVehicles}
          reservationServices={reservationServices}
          reservedVehicles={reservedVehicles}
          selectedCategory={selectedCategory}
          selectedCompany={selectedCompany}
          showAllCategories={showAllCategories}
          theme={theme}
          totalIncome={totalIncome}
        />
      )}
    </Box>
  );
}

const resultAdjusters = [
  { type: "createdAt", title: "Tulokset luontipäivän mukaan" },
  { type: "startAt", title: "Tulokset aloituspäivän mukaan" },
  { type: "endsAt", title: "Tulokset lopetuspäivän mukaan" },
];

function EconomyView(props) {
  const {
    categories,
    changeSelectedCategory,
    economyTimeTitle,
    incomeFromServices,
    incomeFromVehicles,
    paymentMethods,
    reservationServices,
    reservationsLoading,
    reservedVehicles,
    selectedCategory,
    showAllCategories,
    showAllCompanies,
    totalIncome,
    visibility,
    weeklyhours,
  } = props;
  if (visibility)
    return (
      <Grid item sm={12} md={12} lg={10} xl={8} container spacing={1}>
        <Grid item xs={4}>
          <InfoContainerLarge
            loading={reservationsLoading}
            containerStyle="infoContainer-large-light"
            mainValue={`${centsToLocalString(totalIncome)} €`}
            mainTitle={"Tulos ajalta"}
            //     secondaryTitle={economySumTitle}
            //     secondaryValue={centsToLocalString(totalIncome)}
            icon={
              <FcPlanner
                style={{
                  fontSize: 42,
                  backgroundColor: "rgba(0, 0, 0, 0.3)",
                  padding: 5,
                  borderRadius: 10,
                  marginLeft: 10,
                  marginTop: 10,
                }}
              />
            }
            desc={economyTimeTitle}
          />
        </Grid>
        <Grid item xs={4}>
          <InfoContainerLarge
            loading={reservationsLoading}
            containerStyle="infoContainer-large-dark"
            mainValue={`${centsToLocalString(incomeFromVehicles)} €`}
            mainTitle={"Tuotteet"}
            secondaryTitle={"Lisäpalvelut"}
            secondaryValue={centsToLocalString(incomeFromServices)}
            icon={
              <IconButton disabled size="large">
                <FcSalesPerformance
                  style={{
                    fontSize: 42,
                    backgroundColor: "rgba(0, 0, 0, 0.3)",
                    padding: 5,
                    borderRadius: 10,
                  }}
                />
              </IconButton>
            }
          />
        </Grid>
        <Grid item xs={3}>
          <Grid
            container
            direction="column"
            justifyContent="space-evenly"
            style={{ minHeight: "100%" }}
          >
            {paymentMethods.map((item, index) => {
              return (
                <InfoContainerSmall
                  loading={reservationsLoading}
                  key={item.name}
                  containerStyle="infoContainer-large-light"
                  value={`${centsToLocalString(item.total)} €`}
                  title={item.name}
                  icon={
                    <FcPieChart
                      style={{
                        fontSize: 42,

                        backgroundColor: "rgba(0, 0, 0, 0.3)",
                        padding: 5,
                        borderRadius: 10,
                        rotate:
                          index === 1 ? "180deg" : index === 2 ? "90deg" : null,
                      }}
                    />
                  }
                />
              );
            })}
          </Grid>
        </Grid>

        <Grid
          container
          direction="row"
          spacing={2}
          justifyContent="space-between"
          sx={{ marginTop: "10px" }}
        >
          <Grid item lg={7} md={7} style={{ height: "50vh" }}>
            <ProductsTable
              categories={categories}
              changeSelectedCategory={changeSelectedCategory}
              loading={reservationsLoading}
              reservedVehicles={reservedVehicles}
              selectedCategory={selectedCategory}
              showAllCategories={showAllCategories}
              showAllCompanies={showAllCompanies}
              weeklyhours={weeklyhours}
            />
          </Grid>

          <Grid item lg={5} md={5} style={{ height: "50vh" }}>
            <AdditionalServicesTable
              reservationServices={reservationServices}
            />
          </Grid>
        </Grid>
      </Grid>
    );
}
