/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.

export type UpdateStatusInput = {
  orderId: string,
  uRentCarRentalId: string,
  vehicle?: StatusVehicle | null,
  deliveryTimestamp?: string | null,
  deliveryLocation?: StatusLocationInput | null,
  returnTimestamp?: string | null,
  returnLocation?: StatusLocationInput | null,
  actualRentalDays?: number | null,
  cancelReason?: string | null,
};

export type StatusVehicle = {
  mark?: string | null,
  model?: string | null,
  class?: string | null,
};

export type StatusLocationInput = {
  address?: string | null,
  zipcode?: string | null,
  city?: string | null,
  country?: string | null,
  description?: string | null,
  latitude?: string | null,
  longitude?: string | null,
};

export type ExecutionResult = {
  __typename: "ExecutionResult",
  success: boolean,
  message?: string | null,
};

export type ReservationVehicleAdminInput = {
  id: string,
  price: number,
  tax?: number | null,
  quantity?: number | null,
  useCategoryDefaults?: boolean | null,
  category?: CategoryIdInput | null,
  name?: string | null,
  registrationPlate?: string | null,
  deductible?: number | null,
  includedDistance?: string | null,
  powerType?: string | null,
  mileage?: string | null,
  startMileage?: string | null,
  endMileage?: string | null,
  extraInfo?: string | null,
  damages?: string | null,
  hideExport?: boolean | null,
  createdAt?: string | null,
  categoryName?: string | null,
};

export type CategoryIdInput = {
  id?: string | null,
};

export type WildCardVehicleInput = {
  name?: string | null,
  price?: number | null,
  tax?: number | null,
  deductible?: number | null,
  categoryId?: string | null,
  quantity?: number | null,
};

export type ReservationResponse = {
  __typename: "ReservationResponse",
  success: boolean,
  message?: string | null,
  reservation?: Reservation | null,
};

export type Reservation = {
  __typename: "Reservation",
  id: string,
  shortId?: string | null,
  language?: Language | null,
  group?: string | null,
  organizationId?: string | null,
  orgBusinessId?: string | null,
  orgExternalBusinessId?: string | null,
  companyId?: string | null,
  returnCompanyId?: string | null,
  startLocation?: string | null,
  returnLocation?: string | null,
  startLocationID?: string | null,
  returnLocationID?: string | null,
  reviewId?: string | null,
  customerBrokerId?: string | null,
  customerCompanyId?: string | null,
  customerPersonalId?: string | null,
  reservationVehicleId?: string | null,
  reservationVehicles?:  Array<ReservationVehicle | null > | null,
  wildCardVehicles?:  Array<WildCardVehicle | null > | null,
  createdAt: string,
  updatedAt: string,
  startTime?: string | null,
  returnTime?: string | null,
  endTime?: string | null,
  name?: string | null,
  address?: string | null,
  postalCode?: string | null,
  city?: string | null,
  phone?: string | null,
  email?: string | null,
  billingRef?: string | null,
  customerNotes?: string | null,
  ssn?: string | null,
  rentalNotes?: string | null,
  internalNotes?: string | null,
  externalNotes?: string | null,
  outOfCommission?: boolean | null,
  vehiclePrice?: number | null,
  additionalServices?:  Array<AdditionalServiceReservation > | null,
  driverSSN?: string | null,
  differentDriver?: boolean | null,
  driverName?: string | null,
  driverAddress?: string | null,
  driverPostalCode?: string | null,
  driverCity?: string | null,
  driverPhone?: string | null,
  driverEmail?: string | null,
  additionalDriver?: boolean | null,
  additionalDriverName?: string | null,
  additionalDriverPhone?: string | null,
  additionalDriverSSN?: string | null,
  billPayment?: boolean | null,
  isCompany?: boolean | null,
  carStatus?: RentalStatus | null,
  rentalStatus?: RentalStatus | null,
  innovoiceStatus?: ReservationInnovoiceStatus | null,
  companyName?: string | null,
  companyBusinessId?: string | null,
  companyCity?: string | null,
  companyPostalCode?: string | null,
  companyBillingRef?: string | null,
  companyBillingAddress?: string | null,
  companyEBillingOperator?: string | null,
  companyEBillingAddress?: string | null,
  paymentToken?: string | null,
  npsStatus?: NpsStatus | null,
  paymentStatus?: PaymentStatus | null,
  paymentData?: PaymentData | null,
  signStatus?: SignStatus | null,
  signDocumentId?: string | null,
  signerName?: string | null,
  reviewStatus?: ReviewStatus | null,
  authStatus?: AuthStatus | null,
  authTime?: string | null,
  completeBefore?: string | null,
  mustSign?: boolean | null,
  livionData?:  Array<LivionData | null > | null,
  tilakoneData?: TilakoneData | null,
  voucherData?: VoucherData | null,
  customerCarRegistrationPlate?: string | null,
  insuranceData?: InsuranceData | null,
  externalData?: ExternalData | null,
  enableRegistrationPlate?: boolean | null,
  disablePricesInPrintouts?: boolean | null,
  hanselData?: HanselData | null,
  invoiceIds?: Array< string > | null,
  consolidatedInvoiceIds?: Array< string > | null,
  channel?: ReservationChannel | null,
  originURL?: string | null,
  type?: ReservationType | null,
  status?: string | null,
  reservationStatusInfo?: ReservationStatusInfo | null,
  initialCategory?: CategoryInfo | null,
  initialClassInfo?: InitialClassInfo | null,
  orgInvoiceCustomer?: OrganizationInvoiceCustomer | null,
  pricingCategoryId?: string | null,
  customImportDate?: string | null,
  vehicle?: Vehicle | null,
  invoices?: ModelInvoiceConnection | null,
  paymentEvents?: ModelPaymentEventConnection | null,
};

export enum Language {
  fi = "fi",
  en = "en",
  sv = "sv",
}


export type ReservationVehicle = {
  __typename: "ReservationVehicle",
  id?: string | null,
  price?: number | null,
  tax?: number | null,
  name?: string | null,
  registrationPlate?: string | null,
  deductible?: number | null,
  includedDistance?: string | null,
  powerType?: string | null,
  mileage?: string | null,
  startMileage?: string | null,
  endMileage?: string | null,
  extraInfo?: string | null,
  damages?: string | null,
  hideExport?: boolean | null,
  createdAt?: string | null,
  addedAt?: string | null,
  categoryName?: string | null,
};

export type WildCardVehicle = {
  __typename: "WildCardVehicle",
  name?: string | null,
  price?: number | null,
  tax?: number | null,
  categoryId?: string | null,
  quantity?: number | null,
  deductible?: number | null,
};

export type AdditionalServiceReservation = {
  __typename: "AdditionalServiceReservation",
  key: string,
  price: number,
  details?: string | null,
  quantity?: number | null,
  type?: ServiceType | null,
  deductibleValue?: number | null,
  groupFields?: Array< string | null > | null,
  tax?: number | null,
  enableNotification?: boolean | null,
  description?: string | null,
  category?: string | null,
};

export enum ServiceType {
  EXPORT_LICENSE = "EXPORT_LICENSE",
}


export enum RentalStatus {
  NONE = "NONE",
  ACCEPTED = "ACCEPTED",
  RETRIEVED = "RETRIEVED",
  RETURNED = "RETURNED",
}


export enum ReservationInnovoiceStatus {
  NONE = "NONE",
  ACCEPTED = "ACCEPTED",
  RETRIEVED = "RETRIEVED",
  RETURNED = "RETURNED",
}


export enum NpsStatus {
  SCHEDULED = "SCHEDULED",
  FAILURE = "FAILURE",
  SENT = "SENT",
}


export enum PaymentStatus {
  CANCELLED = "CANCELLED",
  PENDING = "PENDING",
  PENDING_FULL = "PENDING_FULL",
  PENDING_INVOICE = "PENDING_INVOICE",
  COMPLETED_DEPOSIT = "COMPLETED_DEPOSIT",
  COMPLETED_MANUAL = "COMPLETED_MANUAL",
  COMPLETED_INVOICE = "COMPLETED_INVOICE",
  COMPLETED = "COMPLETED",
}


export type PaymentData = {
  __typename: "PaymentData",
  paymentTotal?: number | null,
  paymentTodo?: number | null,
  depositPending?: boolean | null,
  deposit?: number | null,
};

export enum SignStatus {
  INITIATING = "INITIATING",
  PENDING = "PENDING",
  CANCELLED = "CANCELLED",
  COMPLETED = "COMPLETED",
}


export enum ReviewStatus {
  SCHEDULED = "SCHEDULED",
  SENT = "SENT",
}


export enum AuthStatus {
  INITIATING = "INITIATING",
  PENDING = "PENDING",
  PENDING_MUST = "PENDING_MUST",
  CUSTOMER_BYPASS = "CUSTOMER_BYPASS",
  CANCELLED = "CANCELLED",
  COMPLETED = "COMPLETED",
}


export type LivionData = {
  __typename: "LivionData",
  id?: string | null,
  pincode?: string | null,
  keyId?: string | null,
  name?: string | null,
  registrationPlate?: string | null,
};

export type TilakoneData = {
  __typename: "TilakoneData",
  access_id?: string | null,
  begin_timestamp?: string | null,
  end_timestamp?: string | null,
  pincode?: string | null,
};

export type VoucherData = {
  __typename: "VoucherData",
  name: string,
  code: string,
  amount: number,
};

export type InsuranceData = {
  __typename: "InsuranceData",
  customerContact?: boolean | null,
  urgent?: boolean | null,
  customerCarModel?: string | null,
  customerCarMaker?: string | null,
  product?: string | null,
  coveragePercentage?: number | null,
  compensatableDays?: string | null,
  billingPermitCode?: string | null,
  coverageDuration?: number | null,
  providerCompany?: string | null,
  providerBusinessId?: string | null,
  claimAdjusterName?: string | null,
  claimAdjusterEmail?: string | null,
  receipt?: boolean | null,
  replacementVehicleClass?: string | null,
  claimId?: string | null,
  vatDeductible?: boolean | null,
  deliveryCosts?: boolean | null,
  returnCosts?: boolean | null,
};

export type ExternalData = {
  __typename: "ExternalData",
  orderId?: string | null,
  orderReference?: string | null,
  serviceAgreementName?: string | null,
  operatorName?: string | null,
  orderInfo?: string | null,
  billingPayer?: string | null,
  billingDescription?: string | null,
  customerVehicle?: CustomerVehicle | null,
  incidentLocation?: Location | null,
  repairShop?: Location | null,
};

export type CustomerVehicle = {
  __typename: "CustomerVehicle",
  brand?: string | null,
  model?: string | null,
  registrationPlate?: string | null,
  owner?: Owner | null,
};

export type Owner = {
  __typename: "Owner",
  name?: string | null,
  address?: string | null,
  city?: string | null,
  zipCode?: string | null,
  businessId?: string | null,
};

export type Location = {
  __typename: "Location",
  description?: string | null,
  address?: string | null,
  city?: string | null,
  zipCode?: string | null,
  coords?: Coordinates | null,
};

export type Coordinates = {
  __typename: "Coordinates",
  long?: string | null,
  lat?: string | null,
};

export type HanselData = {
  __typename: "HanselData",
  contractNro?: string | null,
  customerInvoiceId?: string | null,
  customerInvoiceDate?: string | null,
  hanselAgent?: string | null,
  hanselClass?: string | null,
  customerId?: string | null,
  subContractorNumber?: string | null,
  subContractorName?: string | null,
  suplierProductId?: string | null,
  suplierProductName?: string | null,
  quantity?: string | null,
  price?: string | null,
  totalPrice?: string | null,
  priceNoVAT?: string | null,
  additionalInfo1?: string | null,
  additionalInfo2?: string | null,
  additionalInfo3?: string | null,
  desc?: string | null,
};

export enum ReservationChannel {
  DEALER = "DEALER",
  DEALER_ORGANIZATION = "DEALER_ORGANIZATION",
  DEALER_EXTERNAL = "DEALER_EXTERNAL",
  CUSTOMER = "CUSTOMER",
  SOS = "SOS",
  AUTOLIITTO = "AUTOLIITTO",
}


export enum ReservationType {
  INSURANCE = "INSURANCE",
  HANSEL = "HANSEL",
}


export type ReservationStatusInfo = {
  __typename: "ReservationStatusInfo",
  deliveryTimestamp?: string | null,
  deliveryAddress?: string | null,
  deliveryZipcode?: string | null,
  deliveryCity?: string | null,
  deliveryCountry?: string | null,
  deliveryDescription?: string | null,
  returnTimestamp?: string | null,
  returnAddress?: string | null,
  returnZipcode?: string | null,
  returnCity?: string | null,
  returnCountry?: string | null,
  returnDescription?: string | null,
  cancelReason?: string | null,
};

export type CategoryInfo = {
  __typename: "CategoryInfo",
  id?: string | null,
  name?: string | null,
  hanselClass?: string | null,
};

export type InitialClassInfo = {
  __typename: "InitialClassInfo",
  categoryId?: string | null,
  categoryName?: string | null,
  hanselClass?: string | null,
};

export type OrganizationInvoiceCustomer = {
  __typename: "OrganizationInvoiceCustomer",
  name?: string | null,
  businessId?: string | null,
};

export type Vehicle = {
  __typename: "Vehicle",
  id: string,
  group?: string | null,
  organizationId?: string | null,
  orgBusinessId?: string | null,
  companyId: string,
  name: string,
  nameT?: Translation | null,
  registrationPlate?: string | null,
  damages?: string | null,
  damageRecords?:  Array<DamageRecord > | null,
  additionalFields:  Array<AdditionalField >,
  image?: string | null,
  powerType?: string | null,
  deductible?: number | null,
  includedDistance?: string | null,
  mileage?: string | null,
  orderNumber?: number | null,
  vehicleCategoryId?: string | null,
  useCategoryDefaults?: boolean | null,
  useStartReturnTimes?: boolean | null,
  customStartReturns?:  Array<CustomStartReturn | null > | null,
  startReturnInterval?: StartReturnInterval | null,
  startReturnTimes?:  Array<StartReturnTimes | null > | null,
  hideExport?: boolean | null,
  extraInfo?: string | null,
  extraInfoT?: string | null,
  extraToConfirmation?: string | null,
  extraToConfirmationT?: string | null,
  livionKeyId?: string | null,
  phoneNumber?: string | null,
  importData?: string | null,
  disableOnline?: boolean | null,
  activeRange?: StartReturnInterval | null,
  createdAt: string,
  updatedAt: string,
  category?: Category | null,
  additionalServices?: ModelAdditionalServicePricingConnection | null,
  reservations?: ModelReservationConnection | null,
  pricing?: Pricing | null,
};

export type Translation = {
  __typename: "Translation",
  fi?: string | null,
  en?: string | null,
  sv?: string | null,
};

export type DamageRecord = {
  __typename: "DamageRecord",
  normalizedX?: number | null,
  normalizedY?: number | null,
  label?: string | null,
  description?: string | null,
  createdAt?: string | null,
};

export type AdditionalField = {
  __typename: "AdditionalField",
  key: string,
  value?: string | null,
  valueT?: Translation | null,
};

export type CustomStartReturn = {
  __typename: "CustomStartReturn",
  priority?: number | null,
  startReturnInterval?: StartReturnInterval | null,
  startReturnTimes?:  Array<StartReturnTimes | null > | null,
};

export type StartReturnInterval = {
  __typename: "StartReturnInterval",
  start?: string | null,
  end?: string | null,
};

export type StartReturnTimes = {
  __typename: "StartReturnTimes",
  start?: Array< string | null > | null,
  return?: Array< string | null > | null,
};

export type Category = {
  __typename: "Category",
  id: string,
  group?: string | null,
  organizationId?: string | null,
  name: string,
  image?: string | null,
  orderNumber?: number | null,
  backgroundColor?: string | null,
  minuteBuffer?: number | null,
  reservationBlockFromNow?: number | null,
  typeId?: string | null,
  _removed?: string | null,
  oldTujausValue?: string | null,
  exampleModelName?: string | null,
  exampleModelImage?: string | null,
  createdAt: string,
  updatedAt: string,
  additionalServices?: ModelAdditionalServicePricingConnection | null,
  pricing?: Pricing | null,
  externalBusinessPricings?: ModelPricingConnection | null,
};

export type ModelAdditionalServicePricingConnection = {
  __typename: "ModelAdditionalServicePricingConnection",
  items:  Array<AdditionalServicePricing | null >,
  nextToken?: string | null,
};

export type AdditionalServicePricing = {
  __typename: "AdditionalServicePricing",
  id: string,
  group?: string | null,
  organizationId?: string | null,
  orgBusinessId?: string | null,
  key: string,
  vehicleId?: string | null,
  categoryId?: string | null,
  pricingId: string,
  deductibleValue?: number | null,
  createdAt: string,
  updatedAt: string,
  pricing?: Pricing | null,
};

export type Pricing = {
  __typename: "Pricing",
  id: string,
  name: string,
  group?: string | null,
  organizationId?: string | null,
  orgBusinessId?: string | null,
  orgExternalBusinessId?: string | null,
  externalBusinessCategoryAssociation?: string | null,
  businessCategoryAssociation?: string | null,
  elements:  Array<PricingElement >,
  useNextDayPrice?: boolean | null,
  useLastDayPrice?: boolean | null,
  tax?: number | null,
  _removed?: string | null,
  deductible?: number | null,
  reducedDeductible?: number | null,
  reducedDeductiblePrice?: number | null,
  createdAt: string,
  updatedAt: string,
};

export type PricingElement = {
  __typename: "PricingElement",
  duration: string,
  price: number,
};

export type ModelPricingConnection = {
  __typename: "ModelPricingConnection",
  items:  Array<Pricing | null >,
  nextToken?: string | null,
};

export type ModelReservationConnection = {
  __typename: "ModelReservationConnection",
  items:  Array<Reservation | null >,
  nextToken?: string | null,
};

export type ModelInvoiceConnection = {
  __typename: "ModelInvoiceConnection",
  items:  Array<Invoice | null >,
  nextToken?: string | null,
};

export type Invoice = {
  __typename: "Invoice",
  id: string,
  createdAt: string,
  innovoiceId?: string | null,
  reservationId?: string | null,
  reservationIds?: Array< string > | null,
  companyId?: string | null,
  innovoiceCompanyId?: string | null,
  innovoiceuRentCarRentalId?: string | null,
  innovoiceOrderId?: string | null,
  group?: string | null,
  organizationId?: string | null,
  orgBusinessId?: string | null,
  type?: InvoicePaymentType | null,
  creditNoteInvoiceId?: string | null,
  payment_terms?: number | null,
  vat_included?: number | null,
  delivery_by: InvoiceDeliveryBy,
  invoice_type: InvoiceType,
  delivery_date?: string | null,
  seller_reference?: string | null,
  buyer_reference?: string | null,
  items:  Array<InvoiceItem >,
  customer: InvoiceCustomer,
  insurance?: InvoiceInsurance | null,
  updatedAt: string,
};

export enum InvoicePaymentType {
  FULL = "FULL",
  EXTRA = "EXTRA",
  INSURANCE_COMPANY = "INSURANCE_COMPANY",
  INSURANCE_CUSTOMER = "INSURANCE_CUSTOMER",
  AUTOLIITTO = "AUTOLIITTO",
  CREDIT_NOTE = "CREDIT_NOTE",
  CONSOLIDATED = "CONSOLIDATED",
}


export enum InvoiceDeliveryBy {
  mail = "mail",
  email = "email",
  e_invoice = "e_invoice",
}


export enum InvoiceType {
  normal = "normal",
  insurance = "insurance",
}


export type InvoiceItem = {
  __typename: "InvoiceItem",
  name: string,
  description?: string | null,
  quantity: number,
  unit: string,
  unitprice: number,
  discount?: number | null,
  vat: number,
  type?: string | null,
  insuranceItemType?: InsuranceItemType | null,
  reservationId?: string | null,
};

export enum InsuranceItemType {
  CUSTOMER_PORTION = "CUSTOMER_PORTION",
  VAT_DEDUCTION = "VAT_DEDUCTION",
  VAT_EXCLUDED_DEDUCTION = "VAT_EXCLUDED_DEDUCTION",
}


export type InvoiceCustomer = {
  __typename: "InvoiceCustomer",
  customer_type?: InvoiceCustomerType | null,
  firstname?: string | null,
  lastname?: string | null,
  ssn?: string | null,
  address?: string | null,
  zipcode?: string | null,
  city?: string | null,
  email?: string | null,
  phone?: string | null,
  company_name?: string | null,
  contact_person?: string | null,
  e_invoice_operator?: string | null,
  e_invoice_address?: string | null,
};

export enum InvoiceCustomerType {
  person = "person",
  company = "company",
}


export type InvoiceInsurance = {
  __typename: "InvoiceInsurance",
  insurance_company_id?: number | null,
  vehicle_class?: string | null,
  vehicle_regno?: string | null,
  claim_no?: string | null,
  rental_start_date?: string | null,
  rental_end_date?: string | null,
  rental_days?: number | null,
  amount_cost_per_day?: number | null,
  amount_to_pay_customer?: number | null,
  amount_to_pay_insurance?: number | null,
  coveragePercentage?: number | null,
};

export type ModelPaymentEventConnection = {
  __typename: "ModelPaymentEventConnection",
  items:  Array<PaymentEvent | null >,
  nextToken?: string | null,
};

export type PaymentEvent = {
  __typename: "PaymentEvent",
  id: string,
  channel?: string | null,
  paymentMethod?: string | null,
  paymentTotal?: number | null,
  isDeleted?: boolean | null,
  deletedBy?: string | null,
  deletedAt?: string | null,
  createdByUserID?: string | null,
  createdByUserName?: string | null,
  reservationId?: string | null,
  organizationId?: string | null,
  orgBusinessId?: string | null,
  group?: string | null,
  msg?: string | null,
  state?: PaymentEventState | null,
  returnCode?: number | null,
  incidentId?: string | null,
  parameters?: string | null,
  apiPath?: string | null,
  paymentStatus?: string | null,
  orderNumber?: string | null,
  createdAt: string,
  updatedAt: string,
  reservation?: Reservation | null,
};

export enum PaymentEventState {
  UNPROCESSED = "UNPROCESSED",
  PROCESSED = "PROCESSED",
}


export type UpsertReservationInputAdmin = {
  id?: string | null,
  language?: Language | null,
  group?: string | null,
  organizationId?: string | null,
  orgBusinessId?: string | null,
  orgExternalBusinessId?: string | null,
  companyId?: string | null,
  startLocation?: string | null,
  startLocationID?: string | null,
  returnLocation?: string | null,
  returnLocationID?: string | null,
  startTime?: string | null,
  returnTime?: string | null,
  endTime?: string | null,
  name?: string | null,
  address?: string | null,
  postalCode?: string | null,
  city?: string | null,
  phone?: string | null,
  email: string,
  billingRef?: string | null,
  customerNotes?: string | null,
  ssn?: string | null,
  reservationVehicleId?: string | null,
  reservationVehicles?: Array< ReservationVehicleAdminInput | null > | null,
  wildCardVehicles?: Array< WildCardVehicleInput | null > | null,
  vehiclePrice?: number | null,
  additionalServices?: Array< AdditionalServiceReservationInput > | null,
  rentalNotes?: string | null,
  internalNotes?: string | null,
  externalNotes?: string | null,
  outOfCommission?: boolean | null,
  driverSSN?: string | null,
  differentDriver?: boolean | null,
  driverName?: string | null,
  driverAddress?: string | null,
  driverPostalCode?: string | null,
  driverCity?: string | null,
  driverPhone?: string | null,
  driverEmail?: string | null,
  additionalDriver?: boolean | null,
  additionalDriverName?: string | null,
  additionalDriverPhone?: string | null,
  additionalDriverSSN?: string | null,
  billPayment?: boolean | null,
  isCompany?: boolean | null,
  enableRegistrationPlate?: boolean | null,
  disablePricesInPrintouts?: boolean | null,
  customerBrokerId?: string | null,
  customerCompanyId?: string | null,
  customerPersonalId?: string | null,
  companyName?: string | null,
  companyBusinessId?: string | null,
  companyCity?: string | null,
  companyPostalCode?: string | null,
  companyBillingRef?: string | null,
  companyBillingAddress?: string | null,
  companyEBillingOperator?: string | null,
  companyEBillingAddress?: string | null,
  paymentStatus?: string | null,
  paymentData?: PaymentDataInputAdmin | null,
  authStatus?: string | null,
  mustSign?: boolean | null,
  completeBefore?: string | null,
  rentalStatus?: RentalStatus | null,
  innovoiceStatus?: ReservationInnovoiceStatus | null,
  insuranceData?: InsuranceDataAdminInput | null,
  hanselData?: HanselDataInput | null,
  customerCarRegistrationPlate?: string | null,
  channel?: string | null,
  type?: string | null,
  status?: string | null,
  reservationStatusInfo?: ReservationStatusInfoAdminInput | null,
  initialCategory?: CategoryInfoInput | null,
  initialClassInfo?: InitialClassInfoInput | null,
  pricingCategoryId?: string | null,
};

export type AdditionalServiceReservationInput = {
  key: string,
  price: number,
  tax?: number | null,
  details?: string | null,
  quantity?: number | null,
  deductibleValue?: number | null,
  enableNotification?: boolean | null,
  description?: string | null,
  category?: string | null,
};

export type PaymentDataInputAdmin = {
  paymentTotal?: number | null,
  paymentTodo?: number | null,
  depositPending?: boolean | null,
  deposit?: number | null,
};

export type InsuranceDataAdminInput = {
  customerContact?: boolean | null,
  urgent?: boolean | null,
  customerCarModel?: string | null,
  customerCarMaker?: string | null,
  product?: string | null,
  coveragePercentage?: number | null,
  compensatableDays?: string | null,
  billingPermitCode?: string | null,
  coverageDuration?: number | null,
  providerCompany?: string | null,
  providerBusinessId?: string | null,
  claimAdjusterName?: string | null,
  claimAdjusterEmail?: string | null,
  receipt?: boolean | null,
  replacementVehicleClass?: string | null,
  claimId?: string | null,
  vatDeductible?: boolean | null,
  deliveryCosts?: boolean | null,
  returnCosts?: boolean | null,
};

export type HanselDataInput = {
  contractNro?: string | null,
  customerInvoiceId?: string | null,
  customerInvoiceDate?: string | null,
  hanselAgent?: string | null,
  hanselClass?: string | null,
  customerId?: string | null,
  subContractorNumber?: string | null,
  subContractorName?: string | null,
  suplierProductId?: string | null,
  suplierProductName?: string | null,
  quantity?: string | null,
  price?: string | null,
  totalPrice?: string | null,
  priceNoVAT?: string | null,
  additionalInfo1?: string | null,
  additionalInfo2?: string | null,
  additionalInfo3?: string | null,
  desc?: string | null,
};

export type ReservationStatusInfoAdminInput = {
  deliveryTimestamp?: string | null,
  deliveryAddress?: string | null,
  deliveryZipcode?: string | null,
  deliveryCity?: string | null,
  deliveryCountry?: string | null,
  deliveryDescription?: string | null,
  returnTimestamp?: string | null,
  returnAddress?: string | null,
  returnZipcode?: string | null,
  returnCity?: string | null,
  returnCountry?: string | null,
  returnDescription?: string | null,
  cancelReason?: string | null,
};

export type CategoryInfoInput = {
  id?: string | null,
  name?: string | null,
};

export type InitialClassInfoInput = {
  categoryId?: string | null,
  categoryName?: string | null,
  hanselClass?: string | null,
};

export type UpsertReservationResultAdmin = {
  __typename: "UpsertReservationResultAdmin",
  reservationId?: string | null,
  reservation?: Reservation | null,
  emailConfirmationSuccess?: boolean | null,
  code?: string | null,
  success: boolean,
  destinationEmail?: string | null,
};

export type CreateLivionResult = {
  __typename: "CreateLivionResult",
  success: boolean,
  pincode?: string | null,
  registrationPlate?: string | null,
};

export type TelegramConnectionResult = {
  __typename: "TelegramConnectionResult",
  message?: string | null,
  newConnectionsCount?: number | null,
  connections?:  Array<TelegramInformation | null > | null,
};

export type TelegramInformation = {
  __typename: "TelegramInformation",
  chatId?: string | null,
  name?: string | null,
};

export type CreateInnvoiceInput = {
  invoice: InvoiceInput,
  items: Array< InvoiceItemInput >,
  customer: InvoiceCustomerInput,
  insurance?: InvoiceInsuranceInputAdmin | null,
};

export type InvoiceInput = {
  payment_terms?: number | null,
  vat_included: number,
  invoice_type?: string | null,
  delivery_by: InvoiceDeliveryBy,
  delivery_date?: string | null,
  seller_reference?: string | null,
  buyer_reference?: string | null,
};

export type InvoiceItemInput = {
  name: string,
  description?: string | null,
  quantity: number,
  unit: string,
  unitprice: number,
  vat?: number | null,
  type?: string | null,
  insuranceItemType?: string | null,
  reservationId?: string | null,
};

export type InvoiceCustomerInput = {
  customer_type: InvoiceCustomerType,
  firstname?: string | null,
  lastname?: string | null,
  ssn?: string | null,
  address?: string | null,
  zipcode?: string | null,
  email?: string | null,
  phone?: string | null,
  city?: string | null,
  company_name?: string | null,
  business_id?: string | null,
  e_invoice_address?: string | null,
  e_invoice_operator?: string | null,
  contact_person?: string | null,
};

export type InvoiceInsuranceInputAdmin = {
  amount_to_pay_insurance?: number | null,
  amount_to_pay_customer?: number | null,
  amount_cost_per_day?: number | null,
  rental_days?: number | null,
  rental_end_date?: string | null,
  rental_start_date?: string | null,
  claim_no?: string | null,
  vehicle_regno?: string | null,
  vehicle_class?: string | null,
  insurance_company_id?: number | null,
  coveragePercentage?: number | null,
};

export type CreateConsolidatedInnvoiceInput = {
  invoice: InvoiceInput,
  items: Array< InvoiceItemInput >,
  customer: InvoiceCustomerInput,
};

export type CreateNewsItemInput = {
  id?: string | null,
  header: string,
  link?: string | null,
  content?: string | null,
};

export type ModelNewsItemConditionInput = {
  header?: ModelStringInput | null,
  link?: ModelStringInput | null,
  content?: ModelStringInput | null,
  and?: Array< ModelNewsItemConditionInput | null > | null,
  or?: Array< ModelNewsItemConditionInput | null > | null,
  not?: ModelNewsItemConditionInput | null,
};

export type ModelStringInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export enum ModelAttributeTypes {
  binary = "binary",
  binarySet = "binarySet",
  bool = "bool",
  list = "list",
  map = "map",
  number = "number",
  numberSet = "numberSet",
  string = "string",
  stringSet = "stringSet",
  _null = "_null",
}


export type ModelSizeInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
};

export type NewsItem = {
  __typename: "NewsItem",
  id: string,
  header: string,
  link?: string | null,
  content?: string | null,
  createdAt: string,
  updatedAt: string,
};

export type UpdateNewsItemInput = {
  id: string,
  header?: string | null,
  link?: string | null,
  content?: string | null,
};

export type DeleteNewsItemInput = {
  id: string,
};

export type UpsertReviewInput = {
  id?: string | null,
  rating: number,
  comment?: string | null,
  explanation?: string | null,
  reservationId: string,
};

export type UpsertReviewResult = {
  __typename: "UpsertReviewResult",
  reviewId?: string | null,
  success: boolean,
};

export type UpsertReservationInputCustomer = {
  language?: Language | null,
  group?: string | null,
  organizationId?: string | null,
  orgBusinessId?: string | null,
  startTime: string,
  returnTime: string,
  name: string,
  address: string,
  postalCode: string,
  city: string,
  phone: string,
  email: string,
  billingRef?: string | null,
  companyId?: string | null,
  ssn?: string | null,
  externalNotes?: string | null,
  reservationVehicleId?: string | null,
  reservationVehicles?: Array< ReservationVehicleOfferInput | null > | null,
  vehicleOfferPrice?: number | null,
  additionalServiceOffers: Array< AdditionalServiceOfferInput >,
  voucherCode?: string | null,
  differentDriver?: boolean | null,
  driverName?: string | null,
  driverAddress?: string | null,
  driverPostalCode?: string | null,
  driverCity?: string | null,
  driverPhone?: string | null,
  driverEmail?: string | null,
  driverSSN?: string | null,
  additionalDriver?: boolean | null,
  additionalDriverName?: string | null,
  additionalDriverPhone?: string | null,
  billPayment?: boolean | null,
  isCompany?: boolean | null,
  companyName?: string | null,
  companyBusinessId?: string | null,
  companyBillingRef?: string | null,
  companyBillingAddress?: string | null,
  companyEBillingOperator?: string | null,
  companyEBillingAddress?: string | null,
  paymentStatus?: string | null,
};

export type ReservationVehicleOfferInput = {
  id: string,
  quantity: number,
  offerPrice: number,
};

export type AdditionalServiceOfferInput = {
  key: string,
  offerPrice: number,
  details?: string | null,
  quantity?: number | null,
};

export type UpsertReservationResult = {
  __typename: "UpsertReservationResult",
  emailConfirmationSuccess?: boolean | null,
  code?: string | null,
  success: boolean,
  destinationEmail?: string | null,
  reservationId?: string | null,
};

export type ReservationInfoInput = {
  id: string,
  name: string,
  address: string,
  postalCode: string,
  city: string,
  phone: string,
  billingRef?: string | null,
  email: string,
  ssn?: string | null,
  externalNotes?: string | null,
  differentDriver?: boolean | null,
  driverName?: string | null,
  driverAddress?: string | null,
  driverPostalCode?: string | null,
  driverCity?: string | null,
  driverPhone?: string | null,
  driverEmail?: string | null,
  driverSSN?: string | null,
  additionalDriver?: boolean | null,
  additionalDriverName?: string | null,
  additionalDriverPhone?: string | null,
  billPayment?: boolean | null,
  isCompany?: boolean | null,
  companyName?: string | null,
  companyBusinessId?: string | null,
  companyBillingRef?: string | null,
  companyBillingAddress?: string | null,
  companyEBillingOperator?: string | null,
  companyEBillingAddress?: string | null,
  voucherCode?: string | null,
};

export type ReservationInfo = {
  __typename: "ReservationInfo",
  id?: string | null,
  shortId?: string | null,
  language?: Language | null,
  startTime?: string | null,
  returnTime?: string | null,
  paymentPending?: boolean | null,
  paymentStatus?: string | null,
  signStatus?: string | null,
  enableSign?: boolean | null,
  disablePricesInPrintouts?: boolean | null,
  mustSign?: boolean | null,
  paymentData?: PaymentDataPublic | null,
  askSsn?: boolean | null,
  ssn?: string | null,
  group?: string | null,
  companyTermsUrl?: string | null,
  companyId?: string | null,
  name?: string | null,
  address?: string | null,
  postalCode?: string | null,
  city?: string | null,
  phone?: string | null,
  billingRef?: string | null,
  email?: string | null,
  externalNotes?: string | null,
  differentDriver?: boolean | null,
  driverName?: string | null,
  driverAddress?: string | null,
  driverPostalCode?: string | null,
  driverCity?: string | null,
  driverPhone?: string | null,
  driverEmail?: string | null,
  driverSSN?: string | null,
  additionalDriver?: boolean | null,
  additionalDriverName?: string | null,
  additionalDriverPhone?: string | null,
  allowCancellation?: boolean | null,
  billPayment?: boolean | null,
  isCompany?: boolean | null,
  companyName?: string | null,
  companyBusinessId?: string | null,
  companyBillingRef?: string | null,
  companyBillingAddress?: string | null,
  companyEBillingOperator?: string | null,
  companyEBillingAddress?: string | null,
  authStatus?: string | null,
  vehicles?:  Array<VehiclePublic | null > | null,
  services?:  Array<AdditionalServicesPublic | null > | null,
  expiryTime?: string | null,
  voucherData?: VoucherDataPublic | null,
  enableGTM?: string | null,
  errorMsg?: ReservationInfoError | null,
};

export type PaymentDataPublic = {
  __typename: "PaymentDataPublic",
  paymentTodo?: number | null,
  depositPending?: boolean | null,
  deposit?: number | null,
  paymentTotal?: number | null,
};

export type VehiclePublic = {
  __typename: "VehiclePublic",
  group?: string | null,
  organizationId?: string | null,
  companyId?: string | null,
  name?: string | null,
  nameT?: Translation | null,
  registrationPlate?: string | null,
  additionalFields?:  Array<AdditionalField > | null,
  image?: string | null,
  powerType?: string | null,
  deductible?: number | null,
  includedDistance?: string | null,
  orderNumber?: number | null,
  price?: number | null,
  tax?: number | null,
  category?: VehicleCategoryPublic | null,
  id?: string | null,
};

export type VehicleCategoryPublic = {
  __typename: "VehicleCategoryPublic",
  name?: string | null,
  typeId?: string | null,
};

export type AdditionalServicesPublic = {
  __typename: "AdditionalServicesPublic",
  quantity?: number | null,
  description?: string | null,
  descriptionT?: Translation | null,
  price?: number | null,
  tax?: number | null,
  key?: string | null,
};

export type VoucherDataPublic = {
  __typename: "VoucherDataPublic",
  amount?: number | null,
  code?: string | null,
};

export enum ReservationInfoError {
  NOT_FOUND = "NOT_FOUND",
  EXPIRED = "EXPIRED",
}


export type CreateCompanyInput = {
  id?: string | null,
  organizationId?: string | null,
  orgBusinessId?: string | null,
  group?: string | null,
  name: string,
  displayName?: string | null,
  email?: string | null,
  address?: string | null,
  postalCode?: string | null,
  city?: string | null,
  orderNumber?: number | null,
  orderNumberDealer?: number | null,
  phone?: string | null,
  businessId?: string | null,
  termsUrl?: string | null,
  timezone: string,
  weeklyBusinessHours: Array< ClockRangeInput | null >,
  merchantDetails?: MerchantDetailsInput | null,
  signConfirmationEmail?: string | null,
  nameInEmail?: string | null,
  senderNameSMS?: string | null,
  confirmationMsg?: string | null,
  confirmationMsgT?: TranslationInput | null,
  feedbackMsg?: string | null,
  feedbackMsgT?: TranslationInput | null,
  statusLinkMsg?: string | null,
  statusLinkMsgT?: TranslationInput | null,
  livionDeviceId?: string | null,
  livionDeviceType?: LivionDevice | null,
  enableReservationDeposit?: boolean | null,
  reservationDeposit?: ReservationDepositInput | null,
  reservationLinkExpirationHours?: number | null,
  disableOffHourReturn?: boolean | null,
  disableMultiday?: boolean | null,
  disableSameDay?: boolean | null,
  disableStartDaysFromNow?: number | null,
  enableCart?: boolean | null,
  enableMonthlyView?: boolean | null,
  enableRegistrationPlate?: boolean | null,
  enableVehicleStartReturnTimes?: boolean | null,
  enableMustSign?: boolean | null,
  enablePaymentOnlineOnly?: boolean | null,
  enableGTM?: boolean | null,
  enableNetPromoterScore?: boolean | null,
  enableNPS?: boolean | null,
  reservationCancellation?: ReservationCancellationSettingsInput | null,
  creditCheck?: AsiakastietoCreditCheckInput | null,
  limitReservationOncePerDay?: boolean | null,
  hidden?: boolean | null,
  receiptInfo?: ReceiptInfoInput | null,
  innovoice?: InnovoiceDataInput | null,
  locationType?: string | null,
  offHourAvailability?: boolean | null,
  offHourAvailabilityFee?: number | null,
  deliveryFee?: number | null,
  deliveryBufferHours?: number | null,
  notAllowedCategoryIds?: Array< string | null > | null,
  importIdentifier?: string | null,
  features?: FeaturesInput | null,
  contractSettings?: Array< FeatureSettingInput | null > | null,
  contractSettingsJSON?: string | null,
  categoryDynamicPricings?: Array< CategoryDynamicPricingInput | null > | null,
  disableReservationCategoryIds?: Array< string | null > | null,
  locationColor?: string | null,
};

export type ClockRangeInput = {
  openAt: string,
  closeAt: string,
};

export type MerchantDetailsInput = {
  api?: VismaPayApi | null,
  merchant_id?: string | null,
  cp?: string | null,
};

export enum VismaPayApi {
  BINO = "BINO",
  SCANDIARENT = "SCANDIARENT",
}


export type TranslationInput = {
  fi?: string | null,
  en?: string | null,
  sv?: string | null,
};

export enum LivionDevice {
  KEY30 = "KEY30",
  KEYPAD = "KEYPAD",
}


export type ReservationDepositInput = {
  amount?: number | null,
  reminderBefore?: number | null,
  paymentBefore?: number | null,
};

export type ReservationCancellationSettingsInput = {
  cancellationEnabled?: boolean | null,
  daysBeforeStart?: number | null,
};

export type AsiakastietoCreditCheckInput = {
  enabled?: boolean | null,
  userId?: string | null,
  passwordId?: string | null,
  keyLookup?: boolean | null,
};

export type ReceiptInfoInput = {
  enabled?: boolean | null,
  name?: string | null,
  address?: string | null,
  city?: string | null,
  postalCode?: string | null,
};

export type InnovoiceDataInput = {
  enabled?: boolean | null,
  companyId?: string | null,
  uRentCarRentalId?: string | null,
};

export type FeaturesInput = {
  enableNPS?: boolean | null,
  enableVismaPay?: boolean | null,
  enableReservationDialogV2?: boolean | null,
  enableTilakone?: boolean | null,
  enableGTM?: GTMVersion | null,
  enableSMSConfirmation?: boolean | null,
  enableMustSignBypass?: boolean | null,
};

export enum GTMVersion {
  LEGACY = "LEGACY",
  ECOMMERCE = "ECOMMERCE",
}


export type FeatureSettingInput = {
  name?: string | null,
  enabled?: boolean | null,
  input?: string | null,
};

export type CategoryDynamicPricingInput = {
  categoryId?: string | null,
  pricingId?: string | null,
  percentage?: number | null,
};

export type ModelCompanyConditionInput = {
  name?: ModelStringInput | null,
  displayName?: ModelStringInput | null,
  email?: ModelStringInput | null,
  address?: ModelStringInput | null,
  postalCode?: ModelStringInput | null,
  city?: ModelStringInput | null,
  orderNumber?: ModelIntInput | null,
  orderNumberDealer?: ModelIntInput | null,
  phone?: ModelStringInput | null,
  businessId?: ModelStringInput | null,
  termsUrl?: ModelStringInput | null,
  timezone?: ModelStringInput | null,
  signConfirmationEmail?: ModelStringInput | null,
  nameInEmail?: ModelStringInput | null,
  senderNameSMS?: ModelStringInput | null,
  confirmationMsg?: ModelStringInput | null,
  feedbackMsg?: ModelStringInput | null,
  statusLinkMsg?: ModelStringInput | null,
  livionDeviceId?: ModelStringInput | null,
  livionDeviceType?: ModelLivionDeviceInput | null,
  enableReservationDeposit?: ModelBooleanInput | null,
  reservationLinkExpirationHours?: ModelIntInput | null,
  disableOffHourReturn?: ModelBooleanInput | null,
  disableMultiday?: ModelBooleanInput | null,
  disableSameDay?: ModelBooleanInput | null,
  disableStartDaysFromNow?: ModelIntInput | null,
  enableCart?: ModelBooleanInput | null,
  enableMonthlyView?: ModelBooleanInput | null,
  enableRegistrationPlate?: ModelBooleanInput | null,
  enableVehicleStartReturnTimes?: ModelBooleanInput | null,
  enableMustSign?: ModelBooleanInput | null,
  enablePaymentOnlineOnly?: ModelBooleanInput | null,
  enableGTM?: ModelBooleanInput | null,
  enableNetPromoterScore?: ModelBooleanInput | null,
  enableNPS?: ModelBooleanInput | null,
  limitReservationOncePerDay?: ModelBooleanInput | null,
  hidden?: ModelBooleanInput | null,
  locationType?: ModelStringInput | null,
  offHourAvailability?: ModelBooleanInput | null,
  offHourAvailabilityFee?: ModelIntInput | null,
  deliveryFee?: ModelIntInput | null,
  deliveryBufferHours?: ModelIntInput | null,
  notAllowedCategoryIds?: ModelIDInput | null,
  importIdentifier?: ModelStringInput | null,
  contractSettingsJSON?: ModelStringInput | null,
  disableReservationCategoryIds?: ModelIDInput | null,
  locationColor?: ModelStringInput | null,
  and?: Array< ModelCompanyConditionInput | null > | null,
  or?: Array< ModelCompanyConditionInput | null > | null,
  not?: ModelCompanyConditionInput | null,
};

export type ModelIntInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type ModelLivionDeviceInput = {
  eq?: LivionDevice | null,
  ne?: LivionDevice | null,
};

export type ModelBooleanInput = {
  ne?: boolean | null,
  eq?: boolean | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type ModelIDInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export type Company = {
  __typename: "Company",
  id: string,
  organizationId?: string | null,
  orgBusinessId?: string | null,
  group?: string | null,
  name: string,
  displayName?: string | null,
  email?: string | null,
  address?: string | null,
  postalCode?: string | null,
  city?: string | null,
  orderNumber?: number | null,
  orderNumberDealer?: number | null,
  phone?: string | null,
  businessId?: string | null,
  termsUrl?: string | null,
  timezone: string,
  weeklyBusinessHours:  Array<ClockRange | null >,
  signConfirmationEmail?: string | null,
  nameInEmail?: string | null,
  senderNameSMS?: string | null,
  confirmationMsg?: string | null,
  confirmationMsgT?: Translation | null,
  feedbackMsg?: string | null,
  feedbackMsgT?: Translation | null,
  statusLinkMsg?: string | null,
  statusLinkMsgT?: Translation | null,
  livionDeviceId?: string | null,
  livionDeviceType?: LivionDevice | null,
  enableReservationDeposit?: boolean | null,
  reservationDeposit?: ReservationDeposit | null,
  reservationLinkExpirationHours?: number | null,
  disableOffHourReturn?: boolean | null,
  disableMultiday?: boolean | null,
  disableSameDay?: boolean | null,
  disableStartDaysFromNow?: number | null,
  enableCart?: boolean | null,
  enableMonthlyView?: boolean | null,
  enableRegistrationPlate?: boolean | null,
  enableVehicleStartReturnTimes?: boolean | null,
  enableMustSign?: boolean | null,
  enablePaymentOnlineOnly?: boolean | null,
  enableGTM?: boolean | null,
  enableNetPromoterScore?: boolean | null,
  enableNPS?: boolean | null,
  reservationCancellation?: ReservationCancellationSettings | null,
  creditCheck?: AsiakastietoCreditCheck | null,
  limitReservationOncePerDay?: boolean | null,
  hidden?: boolean | null,
  receiptInfo?: ReceiptInfo | null,
  innovoice?: InnovoiceData | null,
  locationType?: string | null,
  offHourAvailability?: boolean | null,
  offHourAvailabilityFee?: number | null,
  deliveryFee?: number | null,
  deliveryBufferHours?: number | null,
  notAllowedCategoryIds?: Array< string | null > | null,
  importIdentifier?: string | null,
  features?: Features | null,
  contractSettings?:  Array<FeatureSetting | null > | null,
  contractSettingsJSON?: string | null,
  categoryDynamicPricings?:  Array<CategoryDynamicPricing | null > | null,
  disableReservationCategoryIds?: Array< string | null > | null,
  locationColor?: string | null,
  createdAt: string,
  updatedAt: string,
  merchantDetails?: MerchantDetails | null,
  businessHoursExceptions?: ModelBusinessHoursExceptionConnection | null,
  vehicles?: ModelVehicleConnection | null,
  categories?: ModelCategoryConnection | null,
  categoriesByOrg?: ModelCategoryConnection | null,
};

export type ClockRange = {
  __typename: "ClockRange",
  openAt: string,
  closeAt: string,
};

export type ReservationDeposit = {
  __typename: "ReservationDeposit",
  amount?: number | null,
  reminderBefore?: number | null,
  paymentBefore?: number | null,
};

export type ReservationCancellationSettings = {
  __typename: "ReservationCancellationSettings",
  cancellationEnabled?: boolean | null,
  daysBeforeStart?: number | null,
};

export type AsiakastietoCreditCheck = {
  __typename: "AsiakastietoCreditCheck",
  enabled?: boolean | null,
  keyLookup?: boolean | null,
  userId?: string | null,
  passwordId?: string | null,
};

export type ReceiptInfo = {
  __typename: "ReceiptInfo",
  enabled?: boolean | null,
  name?: string | null,
  address?: string | null,
  city?: string | null,
  postalCode?: string | null,
};

export type InnovoiceData = {
  __typename: "InnovoiceData",
  enabled?: boolean | null,
  companyId?: string | null,
  uRentCarRentalId?: string | null,
};

export type Features = {
  __typename: "Features",
  enableNPS?: boolean | null,
  enableVismaPay?: boolean | null,
  enableReservationDialogV2?: boolean | null,
  enableTilakone?: boolean | null,
  enableGTM?: GTMVersion | null,
  enableSMSConfirmation?: boolean | null,
  enableMustSignBypass?: boolean | null,
};

export type FeatureSetting = {
  __typename: "FeatureSetting",
  name?: string | null,
  enabled?: boolean | null,
  input?: string | null,
};

export type CategoryDynamicPricing = {
  __typename: "CategoryDynamicPricing",
  categoryId?: string | null,
  pricingId?: string | null,
  percentage?: number | null,
};

export type MerchantDetails = {
  __typename: "MerchantDetails",
  api?: VismaPayApi | null,
  merchant_id?: string | null,
  cp?: string | null,
};

export type ModelBusinessHoursExceptionConnection = {
  __typename: "ModelBusinessHoursExceptionConnection",
  items:  Array<BusinessHoursException | null >,
  nextToken?: string | null,
};

export type BusinessHoursException = {
  __typename: "BusinessHoursException",
  organizationId?: string | null,
  orgBusinessId?: string | null,
  group?: string | null,
  date: string,
  name?: string | null,
  businessHours?: ClockRange | null,
  companyId: string,
  createdAt: string,
  updatedAt: string,
};

export type ModelVehicleConnection = {
  __typename: "ModelVehicleConnection",
  items:  Array<Vehicle | null >,
  nextToken?: string | null,
};

export type ModelCategoryConnection = {
  __typename: "ModelCategoryConnection",
  items:  Array<Category | null >,
  nextToken?: string | null,
};

export type UpdateCompanyInput = {
  id: string,
  organizationId?: string | null,
  orgBusinessId?: string | null,
  group?: string | null,
  name?: string | null,
  displayName?: string | null,
  email?: string | null,
  address?: string | null,
  postalCode?: string | null,
  city?: string | null,
  orderNumber?: number | null,
  orderNumberDealer?: number | null,
  phone?: string | null,
  businessId?: string | null,
  termsUrl?: string | null,
  timezone?: string | null,
  weeklyBusinessHours?: Array< ClockRangeInput | null > | null,
  merchantDetails?: MerchantDetailsInput | null,
  signConfirmationEmail?: string | null,
  nameInEmail?: string | null,
  senderNameSMS?: string | null,
  confirmationMsg?: string | null,
  confirmationMsgT?: TranslationInput | null,
  feedbackMsg?: string | null,
  feedbackMsgT?: TranslationInput | null,
  statusLinkMsg?: string | null,
  statusLinkMsgT?: TranslationInput | null,
  livionDeviceId?: string | null,
  livionDeviceType?: LivionDevice | null,
  enableReservationDeposit?: boolean | null,
  reservationDeposit?: ReservationDepositInput | null,
  reservationLinkExpirationHours?: number | null,
  disableOffHourReturn?: boolean | null,
  disableMultiday?: boolean | null,
  disableSameDay?: boolean | null,
  disableStartDaysFromNow?: number | null,
  enableCart?: boolean | null,
  enableMonthlyView?: boolean | null,
  enableRegistrationPlate?: boolean | null,
  enableVehicleStartReturnTimes?: boolean | null,
  enableMustSign?: boolean | null,
  enablePaymentOnlineOnly?: boolean | null,
  enableGTM?: boolean | null,
  enableNetPromoterScore?: boolean | null,
  enableNPS?: boolean | null,
  reservationCancellation?: ReservationCancellationSettingsInput | null,
  creditCheck?: AsiakastietoCreditCheckInput | null,
  limitReservationOncePerDay?: boolean | null,
  hidden?: boolean | null,
  receiptInfo?: ReceiptInfoInput | null,
  innovoice?: InnovoiceDataInput | null,
  locationType?: string | null,
  offHourAvailability?: boolean | null,
  offHourAvailabilityFee?: number | null,
  deliveryFee?: number | null,
  deliveryBufferHours?: number | null,
  notAllowedCategoryIds?: Array< string | null > | null,
  importIdentifier?: string | null,
  features?: FeaturesInput | null,
  contractSettings?: Array< FeatureSettingInput | null > | null,
  contractSettingsJSON?: string | null,
  categoryDynamicPricings?: Array< CategoryDynamicPricingInput | null > | null,
  disableReservationCategoryIds?: Array< string | null > | null,
  locationColor?: string | null,
};

export type DeleteCompanyInput = {
  id: string,
};

export type CreateBusinessHoursExceptionInput = {
  organizationId?: string | null,
  orgBusinessId?: string | null,
  group?: string | null,
  date: string,
  name?: string | null,
  businessHours?: ClockRangeInput | null,
  companyId: string,
};

export type ModelBusinessHoursExceptionConditionInput = {
  name?: ModelStringInput | null,
  and?: Array< ModelBusinessHoursExceptionConditionInput | null > | null,
  or?: Array< ModelBusinessHoursExceptionConditionInput | null > | null,
  not?: ModelBusinessHoursExceptionConditionInput | null,
};

export type UpdateBusinessHoursExceptionInput = {
  organizationId?: string | null,
  orgBusinessId?: string | null,
  group?: string | null,
  date: string,
  name?: string | null,
  businessHours?: ClockRangeInput | null,
  companyId: string,
};

export type DeleteBusinessHoursExceptionInput = {
  companyId: string,
  date: string,
};

export type CreateVehicleInput = {
  id?: string | null,
  group?: string | null,
  organizationId?: string | null,
  orgBusinessId?: string | null,
  companyId: string,
  name: string,
  nameT?: TranslationInput | null,
  registrationPlate?: string | null,
  damages?: string | null,
  damageRecords?: Array< DamageRecordInput > | null,
  additionalFields: Array< AdditionalFieldInput >,
  image?: string | null,
  powerType?: string | null,
  deductible?: number | null,
  includedDistance?: string | null,
  mileage?: string | null,
  orderNumber?: number | null,
  vehicleCategoryId?: string | null,
  useCategoryDefaults?: boolean | null,
  useStartReturnTimes?: boolean | null,
  customStartReturns?: Array< CustomStartReturnInput | null > | null,
  startReturnInterval?: StartReturnIntervalInput | null,
  startReturnTimes?: Array< StartReturnTimesInput | null > | null,
  hideExport?: boolean | null,
  extraInfo?: string | null,
  extraInfoT?: string | null,
  extraToConfirmation?: string | null,
  extraToConfirmationT?: string | null,
  livionKeyId?: string | null,
  phoneNumber?: string | null,
  importData?: string | null,
  disableOnline?: boolean | null,
  activeRange?: StartReturnIntervalInput | null,
  vehiclePricingId?: string | null,
};

export type DamageRecordInput = {
  normalizedX?: number | null,
  normalizedY?: number | null,
  label?: string | null,
  description?: string | null,
  createdAt?: string | null,
};

export type AdditionalFieldInput = {
  key: string,
  value?: string | null,
  valueT?: TranslationInput | null,
};

export type CustomStartReturnInput = {
  priority?: number | null,
  startReturnInterval?: StartReturnIntervalInput | null,
  startReturnTimes?: Array< StartReturnTimesInput | null > | null,
};

export type StartReturnIntervalInput = {
  start?: string | null,
  end?: string | null,
};

export type StartReturnTimesInput = {
  start?: Array< string | null > | null,
  return?: Array< string | null > | null,
};

export type ModelVehicleConditionInput = {
  companyId?: ModelIDInput | null,
  name?: ModelStringInput | null,
  registrationPlate?: ModelStringInput | null,
  damages?: ModelStringInput | null,
  image?: ModelStringInput | null,
  powerType?: ModelStringInput | null,
  deductible?: ModelIntInput | null,
  includedDistance?: ModelStringInput | null,
  mileage?: ModelStringInput | null,
  orderNumber?: ModelIntInput | null,
  vehicleCategoryId?: ModelIDInput | null,
  useCategoryDefaults?: ModelBooleanInput | null,
  useStartReturnTimes?: ModelBooleanInput | null,
  hideExport?: ModelBooleanInput | null,
  extraInfo?: ModelStringInput | null,
  extraInfoT?: ModelStringInput | null,
  extraToConfirmation?: ModelStringInput | null,
  extraToConfirmationT?: ModelStringInput | null,
  livionKeyId?: ModelStringInput | null,
  phoneNumber?: ModelStringInput | null,
  importData?: ModelStringInput | null,
  disableOnline?: ModelBooleanInput | null,
  and?: Array< ModelVehicleConditionInput | null > | null,
  or?: Array< ModelVehicleConditionInput | null > | null,
  not?: ModelVehicleConditionInput | null,
};

export type UpdateVehicleInput = {
  id: string,
  group?: string | null,
  organizationId?: string | null,
  orgBusinessId?: string | null,
  companyId?: string | null,
  name?: string | null,
  nameT?: TranslationInput | null,
  registrationPlate?: string | null,
  damages?: string | null,
  damageRecords?: Array< DamageRecordInput > | null,
  additionalFields?: Array< AdditionalFieldInput > | null,
  image?: string | null,
  powerType?: string | null,
  deductible?: number | null,
  includedDistance?: string | null,
  mileage?: string | null,
  orderNumber?: number | null,
  vehicleCategoryId?: string | null,
  useCategoryDefaults?: boolean | null,
  useStartReturnTimes?: boolean | null,
  customStartReturns?: Array< CustomStartReturnInput | null > | null,
  startReturnInterval?: StartReturnIntervalInput | null,
  startReturnTimes?: Array< StartReturnTimesInput | null > | null,
  hideExport?: boolean | null,
  extraInfo?: string | null,
  extraInfoT?: string | null,
  extraToConfirmation?: string | null,
  extraToConfirmationT?: string | null,
  livionKeyId?: string | null,
  phoneNumber?: string | null,
  importData?: string | null,
  disableOnline?: boolean | null,
  activeRange?: StartReturnIntervalInput | null,
  vehiclePricingId?: string | null,
};

export type DeleteVehicleInput = {
  id: string,
};

export type CreateCategoryInput = {
  id?: string | null,
  group?: string | null,
  organizationId?: string | null,
  name: string,
  image?: string | null,
  orderNumber?: number | null,
  backgroundColor?: string | null,
  minuteBuffer?: number | null,
  reservationBlockFromNow?: number | null,
  typeId?: string | null,
  _removed?: string | null,
  oldTujausValue?: string | null,
  exampleModelName?: string | null,
  exampleModelImage?: string | null,
  categoryPricingId?: string | null,
};

export type ModelCategoryConditionInput = {
  name?: ModelStringInput | null,
  image?: ModelStringInput | null,
  orderNumber?: ModelIntInput | null,
  backgroundColor?: ModelStringInput | null,
  minuteBuffer?: ModelIntInput | null,
  reservationBlockFromNow?: ModelIntInput | null,
  typeId?: ModelIDInput | null,
  _removed?: ModelStringInput | null,
  oldTujausValue?: ModelStringInput | null,
  exampleModelName?: ModelStringInput | null,
  exampleModelImage?: ModelStringInput | null,
  and?: Array< ModelCategoryConditionInput | null > | null,
  or?: Array< ModelCategoryConditionInput | null > | null,
  not?: ModelCategoryConditionInput | null,
};

export type UpdateCategoryInput = {
  id: string,
  group?: string | null,
  organizationId?: string | null,
  name?: string | null,
  image?: string | null,
  orderNumber?: number | null,
  backgroundColor?: string | null,
  minuteBuffer?: number | null,
  reservationBlockFromNow?: number | null,
  typeId?: string | null,
  _removed?: string | null,
  oldTujausValue?: string | null,
  exampleModelName?: string | null,
  exampleModelImage?: string | null,
  categoryPricingId?: string | null,
};

export type DeleteCategoryInput = {
  id: string,
};

export type CreateAdditionalServicePricingInput = {
  id?: string | null,
  group?: string | null,
  organizationId?: string | null,
  orgBusinessId?: string | null,
  key: string,
  vehicleId?: string | null,
  categoryId?: string | null,
  pricingId: string,
  deductibleValue?: number | null,
};

export type ModelAdditionalServicePricingConditionInput = {
  key?: ModelStringInput | null,
  vehicleId?: ModelIDInput | null,
  categoryId?: ModelIDInput | null,
  pricingId?: ModelIDInput | null,
  deductibleValue?: ModelIntInput | null,
  and?: Array< ModelAdditionalServicePricingConditionInput | null > | null,
  or?: Array< ModelAdditionalServicePricingConditionInput | null > | null,
  not?: ModelAdditionalServicePricingConditionInput | null,
};

export type UpdateAdditionalServicePricingInput = {
  id: string,
  group?: string | null,
  organizationId?: string | null,
  orgBusinessId?: string | null,
  key?: string | null,
  vehicleId?: string | null,
  categoryId?: string | null,
  pricingId?: string | null,
  deductibleValue?: number | null,
};

export type DeleteAdditionalServicePricingInput = {
  id: string,
};

export type CreateUserInput = {
  id?: string | null,
  organizationId?: string | null,
  orgBusinessId?: string | null,
  orgBusinessIds?: Array< string | null > | null,
  externalBusinessId?: string | null,
  role?: UserRole | null,
  permissions?: PermissionInput | null,
  email?: string | null,
  name?: string | null,
  regionalCompanyName?: string | null,
};

export enum UserRole {
  ORGANIZATION_ADMIN = "ORGANIZATION_ADMIN",
  BUSINESS_ADMIN = "BUSINESS_ADMIN",
  EXTERNAL_BUSINESS_ADMIN = "EXTERNAL_BUSINESS_ADMIN",
}


export type PermissionInput = {
  userManagement?: boolean | null,
};

export type ModelUserConditionInput = {
  role?: ModelUserRoleInput | null,
  email?: ModelStringInput | null,
  name?: ModelStringInput | null,
  regionalCompanyName?: ModelStringInput | null,
  and?: Array< ModelUserConditionInput | null > | null,
  or?: Array< ModelUserConditionInput | null > | null,
  not?: ModelUserConditionInput | null,
};

export type ModelUserRoleInput = {
  eq?: UserRole | null,
  ne?: UserRole | null,
};

export type User = {
  __typename: "User",
  id: string,
  organizationId?: string | null,
  orgBusinessId?: string | null,
  orgBusinessIds?: Array< string | null > | null,
  externalBusinessId?: string | null,
  role?: UserRole | null,
  permissions?: Permission | null,
  email?: string | null,
  name?: string | null,
  regionalCompanyName?: string | null,
  createdAt: string,
  updatedAt: string,
  categoriesByOrg?: ModelCategoryConnection | null,
  organization?: Organization | null,
  externalBusiness?: ExternalBusiness | null,
  externalBusinessByOrg?: ModelExternalBusinessConnection | null,
  business?: Business | null,
  businessByOrg?: ModelBusinessConnection | null,
};

export type Permission = {
  __typename: "Permission",
  userManagement?: boolean | null,
};

export type Organization = {
  __typename: "Organization",
  id: string,
  name?: string | null,
  billingCustomers?:  Array<BillingCustomer | null > | null,
  features?: OrganizationFeatures | null,
  createdAt: string,
  updatedAt: string,
  businesses?: ModelBusinessConnection | null,
};

export type BillingCustomer = {
  __typename: "BillingCustomer",
  name?: string | null,
};

export type OrganizationFeatures = {
  __typename: "OrganizationFeatures",
  enableNPS?: boolean | null,
  enableBusinessWideReservation?: boolean | null,
};

export type ModelBusinessConnection = {
  __typename: "ModelBusinessConnection",
  items:  Array<Business | null >,
  nextToken?: string | null,
};

export type Business = {
  __typename: "Business",
  id: string,
  organizationId?: string | null,
  telegramChatId?: string | null,
  telegramConnections?:  Array<TelegramInformation | null > | null,
  group?: string | null,
  name?: string | null,
  address?: string | null,
  city?: string | null,
  email?: string | null,
  phone?: string | null,
  insuranceCoverageZone?: PostalCodeRange | null,
  insuranceCoverageZones?:  Array<PostalCodeRange | null > | null,
  importIdentifier?: string | null,
  categoryDynamicPricings?:  Array<CategoryDynamicPricing | null > | null,
  startLocations?:  Array<PickupLocation | null > | null,
  pickupLocations?:  Array<PickupLocation | null > | null,
  createdAt: string,
  updatedAt: string,
  companies?: ModelCompanyConnection | null,
  categories?: ModelCategoryConnection | null,
};

export type PostalCodeRange = {
  __typename: "PostalCodeRange",
  start?: string | null,
  end?: string | null,
};

export type PickupLocation = {
  __typename: "PickupLocation",
  id?: string | null,
  name?: string | null,
  locationColor?: string | null,
  companyId?: string | null,
};

export type ModelCompanyConnection = {
  __typename: "ModelCompanyConnection",
  items:  Array<Company | null >,
  nextToken?: string | null,
};

export type ExternalBusiness = {
  __typename: "ExternalBusiness",
  id: string,
  organizationId?: string | null,
  type?: ExternalBusinessType | null,
  name?: string | null,
  insuranceProducts?:  Array<InsuranceProducts | null > | null,
  insuranceRegionalCompanies?:  Array<InsuranceRegionalCompany | null > | null,
  insuranceCompensatableTimes?: Array< number | null > | null,
  services?:  Array<CustomService | null > | null,
  useHansel?: boolean | null,
  hanselId?: string | null,
  availableOrgBusinessIds?: Array< string | null > | null,
  availableCompanyIds?: Array< string | null > | null,
  availableVehicleClasses?: Array< string | null > | null,
  eBillingRef?: string | null,
  eBillingOperator?: string | null,
  eBillingAddress?: string | null,
  createdAt: string,
  updatedAt: string,
  companies?: ModelCompanyConnection | null,
  pricings?: ModelPricingConnection | null,
};

export enum ExternalBusinessType {
  NORMAL = "NORMAL",
  STANDARD = "STANDARD",
  INSURANCE = "INSURANCE",
}


export type InsuranceProducts = {
  __typename: "InsuranceProducts",
  name?: string | null,
  coveragePercentage?: number | null,
  compensatableDays?: number | null,
  billingPermitCode?: string | null,
};

export type InsuranceRegionalCompany = {
  __typename: "InsuranceRegionalCompany",
  name?: string | null,
  companyName?: string | null,
  businessId?: string | null,
  address?: string | null,
  postalCode?: string | null,
  postalArea?: string | null,
  email?: string | null,
  innovoiceId?: string | null,
};

export type CustomService = {
  __typename: "CustomService",
  description?: string | null,
  price?: number | null,
  category?: string | null,
  deductibleValue?: number | null,
};

export type ModelExternalBusinessConnection = {
  __typename: "ModelExternalBusinessConnection",
  items:  Array<ExternalBusiness | null >,
  nextToken?: string | null,
};

export type UpdateUserInput = {
  id: string,
  organizationId?: string | null,
  orgBusinessId?: string | null,
  orgBusinessIds?: Array< string | null > | null,
  externalBusinessId?: string | null,
  role?: UserRole | null,
  permissions?: PermissionInput | null,
  email?: string | null,
  name?: string | null,
  regionalCompanyName?: string | null,
};

export type DeleteUserInput = {
  id: string,
};

export type CreateOrganizationInput = {
  id?: string | null,
  name?: string | null,
  billingCustomers?: Array< BillingCustomerInput | null > | null,
  features?: OrganizationFeaturesInput | null,
};

export type BillingCustomerInput = {
  name?: string | null,
};

export type OrganizationFeaturesInput = {
  enableNPS?: boolean | null,
  enableBusinessWideReservation?: boolean | null,
};

export type ModelOrganizationConditionInput = {
  name?: ModelStringInput | null,
  and?: Array< ModelOrganizationConditionInput | null > | null,
  or?: Array< ModelOrganizationConditionInput | null > | null,
  not?: ModelOrganizationConditionInput | null,
};

export type UpdateOrganizationInput = {
  id: string,
  name?: string | null,
  billingCustomers?: Array< BillingCustomerInput | null > | null,
  features?: OrganizationFeaturesInput | null,
};

export type DeleteOrganizationInput = {
  id: string,
};

export type CreateExternalBusinessInput = {
  id?: string | null,
  organizationId?: string | null,
  type?: ExternalBusinessType | null,
  name?: string | null,
  insuranceProducts?: Array< InsuranceProductsInput | null > | null,
  insuranceRegionalCompanies?: Array< InsuranceRegionalCompanyInput | null > | null,
  insuranceCompensatableTimes?: Array< number | null > | null,
  services?: Array< CustomServiceInput | null > | null,
  useHansel?: boolean | null,
  hanselId?: string | null,
  availableOrgBusinessIds?: Array< string | null > | null,
  availableCompanyIds?: Array< string | null > | null,
  availableVehicleClasses?: Array< string | null > | null,
  eBillingRef?: string | null,
  eBillingOperator?: string | null,
  eBillingAddress?: string | null,
};

export type InsuranceProductsInput = {
  name?: string | null,
  coveragePercentage?: number | null,
  compensatableDays?: number | null,
  billingPermitCode?: string | null,
};

export type InsuranceRegionalCompanyInput = {
  name?: string | null,
  companyName?: string | null,
  businessId?: string | null,
  address?: string | null,
  postalCode?: string | null,
  postalArea?: string | null,
  email?: string | null,
  innovoiceId?: string | null,
};

export type CustomServiceInput = {
  description?: string | null,
  price?: number | null,
  category?: string | null,
  deductibleValue?: number | null,
};

export type ModelExternalBusinessConditionInput = {
  type?: ModelExternalBusinessTypeInput | null,
  name?: ModelStringInput | null,
  insuranceCompensatableTimes?: ModelIntInput | null,
  useHansel?: ModelBooleanInput | null,
  hanselId?: ModelStringInput | null,
  availableOrgBusinessIds?: ModelIDInput | null,
  availableCompanyIds?: ModelIDInput | null,
  availableVehicleClasses?: ModelStringInput | null,
  eBillingRef?: ModelStringInput | null,
  eBillingOperator?: ModelStringInput | null,
  eBillingAddress?: ModelStringInput | null,
  and?: Array< ModelExternalBusinessConditionInput | null > | null,
  or?: Array< ModelExternalBusinessConditionInput | null > | null,
  not?: ModelExternalBusinessConditionInput | null,
};

export type ModelExternalBusinessTypeInput = {
  eq?: ExternalBusinessType | null,
  ne?: ExternalBusinessType | null,
};

export type UpdateExternalBusinessInput = {
  id: string,
  organizationId?: string | null,
  type?: ExternalBusinessType | null,
  name?: string | null,
  insuranceProducts?: Array< InsuranceProductsInput | null > | null,
  insuranceRegionalCompanies?: Array< InsuranceRegionalCompanyInput | null > | null,
  insuranceCompensatableTimes?: Array< number | null > | null,
  services?: Array< CustomServiceInput | null > | null,
  useHansel?: boolean | null,
  hanselId?: string | null,
  availableOrgBusinessIds?: Array< string | null > | null,
  availableCompanyIds?: Array< string | null > | null,
  availableVehicleClasses?: Array< string | null > | null,
  eBillingRef?: string | null,
  eBillingOperator?: string | null,
  eBillingAddress?: string | null,
};

export type DeleteExternalBusinessInput = {
  id: string,
};

export type CreateBusinessInput = {
  id?: string | null,
  organizationId?: string | null,
  telegramChatId?: string | null,
  telegramConnections?: Array< TelegramInformationInput | null > | null,
  group?: string | null,
  name?: string | null,
  address?: string | null,
  city?: string | null,
  email?: string | null,
  phone?: string | null,
  insuranceCoverageZone?: PostalCodeRangeInput | null,
  insuranceCoverageZones?: Array< PostalCodeRangeInput | null > | null,
  importIdentifier?: string | null,
  categoryDynamicPricings?: Array< CategoryDynamicPricingInput | null > | null,
  startLocations?: Array< PickupLocationInput | null > | null,
  pickupLocations?: Array< PickupLocationInput | null > | null,
  organizationBusinessesId?: string | null,
};

export type TelegramInformationInput = {
  chatId?: string | null,
  name?: string | null,
};

export type PostalCodeRangeInput = {
  start?: string | null,
  end?: string | null,
};

export type PickupLocationInput = {
  id?: string | null,
  name?: string | null,
  locationColor?: string | null,
  companyId?: string | null,
};

export type ModelBusinessConditionInput = {
  telegramChatId?: ModelStringInput | null,
  group?: ModelStringInput | null,
  name?: ModelStringInput | null,
  address?: ModelStringInput | null,
  city?: ModelStringInput | null,
  email?: ModelStringInput | null,
  phone?: ModelStringInput | null,
  importIdentifier?: ModelStringInput | null,
  and?: Array< ModelBusinessConditionInput | null > | null,
  or?: Array< ModelBusinessConditionInput | null > | null,
  not?: ModelBusinessConditionInput | null,
};

export type UpdateBusinessInput = {
  id: string,
  organizationId?: string | null,
  telegramChatId?: string | null,
  telegramConnections?: Array< TelegramInformationInput | null > | null,
  group?: string | null,
  name?: string | null,
  address?: string | null,
  city?: string | null,
  email?: string | null,
  phone?: string | null,
  insuranceCoverageZone?: PostalCodeRangeInput | null,
  insuranceCoverageZones?: Array< PostalCodeRangeInput | null > | null,
  importIdentifier?: string | null,
  categoryDynamicPricings?: Array< CategoryDynamicPricingInput | null > | null,
  startLocations?: Array< PickupLocationInput | null > | null,
  pickupLocations?: Array< PickupLocationInput | null > | null,
  organizationBusinessesId?: string | null,
};

export type DeleteBusinessInput = {
  id: string,
};

export type CreateArchiveReservationInput = {
  id?: string | null,
  organizationId: string,
  orgBusinessId: string,
  reservationJSON: string,
  archiveMessage?: string | null,
  archivedAt: string,
  archivedByUserId: string,
  startTime?: string | null,
  returnTime?: string | null,
  reservationCreatedAt?: string | null,
  customerBrokerId?: string | null,
};

export type ModelArchiveReservationConditionInput = {
  reservationJSON?: ModelStringInput | null,
  archiveMessage?: ModelStringInput | null,
  archivedAt?: ModelStringInput | null,
  archivedByUserId?: ModelIDInput | null,
  startTime?: ModelStringInput | null,
  returnTime?: ModelStringInput | null,
  reservationCreatedAt?: ModelStringInput | null,
  customerBrokerId?: ModelIDInput | null,
  and?: Array< ModelArchiveReservationConditionInput | null > | null,
  or?: Array< ModelArchiveReservationConditionInput | null > | null,
  not?: ModelArchiveReservationConditionInput | null,
};

export type ArchiveReservation = {
  __typename: "ArchiveReservation",
  id: string,
  organizationId: string,
  orgBusinessId: string,
  reservationJSON: string,
  archiveMessage?: string | null,
  archivedAt: string,
  archivedByUserId: string,
  startTime?: string | null,
  returnTime?: string | null,
  reservationCreatedAt?: string | null,
  customerBrokerId?: string | null,
  createdAt: string,
  updatedAt: string,
};

export type UpdateArchiveReservationInput = {
  id: string,
  organizationId?: string | null,
  orgBusinessId?: string | null,
  reservationJSON?: string | null,
  archiveMessage?: string | null,
  archivedAt?: string | null,
  archivedByUserId?: string | null,
  startTime?: string | null,
  returnTime?: string | null,
  reservationCreatedAt?: string | null,
  customerBrokerId?: string | null,
};

export type DeleteArchiveReservationInput = {
  id: string,
};

export type CreateReservationInput = {
  id?: string | null,
  shortId?: string | null,
  language?: Language | null,
  group?: string | null,
  organizationId?: string | null,
  orgBusinessId?: string | null,
  orgExternalBusinessId?: string | null,
  companyId?: string | null,
  returnCompanyId?: string | null,
  startLocation?: string | null,
  returnLocation?: string | null,
  startLocationID?: string | null,
  returnLocationID?: string | null,
  reviewId?: string | null,
  customerBrokerId?: string | null,
  customerCompanyId?: string | null,
  customerPersonalId?: string | null,
  reservationVehicleId?: string | null,
  reservationVehicles?: Array< ReservationVehicleInput | null > | null,
  wildCardVehicles?: Array< WildCardVehicleInput | null > | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  startTime?: string | null,
  returnTime?: string | null,
  endTime?: string | null,
  name?: string | null,
  address?: string | null,
  postalCode?: string | null,
  city?: string | null,
  phone?: string | null,
  email?: string | null,
  billingRef?: string | null,
  customerNotes?: string | null,
  ssn?: string | null,
  rentalNotes?: string | null,
  internalNotes?: string | null,
  externalNotes?: string | null,
  outOfCommission?: boolean | null,
  vehiclePrice?: number | null,
  additionalServices?: Array< AdditionalServiceReservationInput > | null,
  driverSSN?: string | null,
  differentDriver?: boolean | null,
  driverName?: string | null,
  driverAddress?: string | null,
  driverPostalCode?: string | null,
  driverCity?: string | null,
  driverPhone?: string | null,
  driverEmail?: string | null,
  additionalDriver?: boolean | null,
  additionalDriverName?: string | null,
  additionalDriverPhone?: string | null,
  additionalDriverSSN?: string | null,
  billPayment?: boolean | null,
  isCompany?: boolean | null,
  carStatus?: RentalStatus | null,
  rentalStatus?: RentalStatus | null,
  innovoiceStatus?: ReservationInnovoiceStatus | null,
  companyName?: string | null,
  companyBusinessId?: string | null,
  companyCity?: string | null,
  companyPostalCode?: string | null,
  companyBillingRef?: string | null,
  companyBillingAddress?: string | null,
  companyEBillingOperator?: string | null,
  companyEBillingAddress?: string | null,
  paymentToken?: string | null,
  npsStatus?: NpsStatus | null,
  paymentStatus?: PaymentStatus | null,
  paymentData?: PaymentDataInput | null,
  signStatus?: SignStatus | null,
  signDocumentId?: string | null,
  signerName?: string | null,
  reviewStatus?: ReviewStatus | null,
  authStatus?: AuthStatus | null,
  authTime?: string | null,
  completeBefore?: string | null,
  mustSign?: boolean | null,
  livionData?: Array< LivionDataInput | null > | null,
  tilakoneData?: TilakoneDataInput | null,
  voucherData?: VoucherDataInput | null,
  customerCarRegistrationPlate?: string | null,
  insuranceData?: InsuranceDataInput | null,
  externalData?: ExternalDataInput | null,
  enableRegistrationPlate?: boolean | null,
  disablePricesInPrintouts?: boolean | null,
  hanselData?: HanselDataInput | null,
  invoiceIds?: Array< string > | null,
  consolidatedInvoiceIds?: Array< string > | null,
  channel?: ReservationChannel | null,
  originURL?: string | null,
  type?: ReservationType | null,
  status?: string | null,
  reservationStatusInfo?: ReservationStatusInfoInput | null,
  initialCategory?: CategoryInfoInput | null,
  initialClassInfo?: InitialClassInfoInput | null,
  orgInvoiceCustomer?: OrganizationInvoiceCustomerInput | null,
  pricingCategoryId?: string | null,
  customImportDate?: string | null,
};

export type ReservationVehicleInput = {
  id?: string | null,
  price?: number | null,
  tax?: number | null,
  name?: string | null,
  registrationPlate?: string | null,
  deductible?: number | null,
  includedDistance?: string | null,
  powerType?: string | null,
  mileage?: string | null,
  startMileage?: string | null,
  endMileage?: string | null,
  extraInfo?: string | null,
  damages?: string | null,
  hideExport?: boolean | null,
  createdAt?: string | null,
  addedAt?: string | null,
  categoryName?: string | null,
};

export type PaymentDataInput = {
  paymentTotal?: number | null,
  paymentTodo?: number | null,
  depositPending?: boolean | null,
  deposit?: number | null,
};

export type LivionDataInput = {
  id?: string | null,
  pincode?: string | null,
  keyId?: string | null,
  name?: string | null,
  registrationPlate?: string | null,
};

export type TilakoneDataInput = {
  access_id?: string | null,
  begin_timestamp?: string | null,
  end_timestamp?: string | null,
  pincode?: string | null,
};

export type VoucherDataInput = {
  name: string,
  code: string,
  amount: number,
};

export type InsuranceDataInput = {
  customerContact?: boolean | null,
  urgent?: boolean | null,
  customerCarModel?: string | null,
  customerCarMaker?: string | null,
  product?: string | null,
  coveragePercentage?: number | null,
  compensatableDays?: string | null,
  billingPermitCode?: string | null,
  coverageDuration?: number | null,
  providerCompany?: string | null,
  providerBusinessId?: string | null,
  claimAdjusterName?: string | null,
  claimAdjusterEmail?: string | null,
  receipt?: boolean | null,
  replacementVehicleClass?: string | null,
  claimId?: string | null,
  vatDeductible?: boolean | null,
  deliveryCosts?: boolean | null,
  returnCosts?: boolean | null,
};

export type ExternalDataInput = {
  orderId?: string | null,
  orderReference?: string | null,
  serviceAgreementName?: string | null,
  operatorName?: string | null,
  orderInfo?: string | null,
  billingPayer?: string | null,
  billingDescription?: string | null,
  customerVehicle?: CustomerVehicleInput | null,
  incidentLocation?: LocationInput | null,
  repairShop?: LocationInput | null,
};

export type CustomerVehicleInput = {
  brand?: string | null,
  model?: string | null,
  registrationPlate?: string | null,
  owner?: OwnerInput | null,
};

export type OwnerInput = {
  name?: string | null,
  address?: string | null,
  city?: string | null,
  zipCode?: string | null,
  businessId?: string | null,
};

export type LocationInput = {
  description?: string | null,
  address?: string | null,
  city?: string | null,
  zipCode?: string | null,
  coords?: CoordinatesInput | null,
};

export type CoordinatesInput = {
  long?: string | null,
  lat?: string | null,
};

export type ReservationStatusInfoInput = {
  deliveryTimestamp?: string | null,
  deliveryAddress?: string | null,
  deliveryZipcode?: string | null,
  deliveryCity?: string | null,
  deliveryCountry?: string | null,
  deliveryDescription?: string | null,
  returnTimestamp?: string | null,
  returnAddress?: string | null,
  returnZipcode?: string | null,
  returnCity?: string | null,
  returnCountry?: string | null,
  returnDescription?: string | null,
  cancelReason?: string | null,
};

export type OrganizationInvoiceCustomerInput = {
  name?: string | null,
  businessId?: string | null,
};

export type ModelReservationConditionInput = {
  shortId?: ModelStringInput | null,
  language?: ModelLanguageInput | null,
  companyId?: ModelIDInput | null,
  returnCompanyId?: ModelIDInput | null,
  startLocation?: ModelStringInput | null,
  returnLocation?: ModelStringInput | null,
  startLocationID?: ModelIDInput | null,
  returnLocationID?: ModelIDInput | null,
  reviewId?: ModelIDInput | null,
  customerBrokerId?: ModelIDInput | null,
  customerCompanyId?: ModelIDInput | null,
  customerPersonalId?: ModelIDInput | null,
  reservationVehicleId?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  startTime?: ModelStringInput | null,
  returnTime?: ModelStringInput | null,
  endTime?: ModelStringInput | null,
  name?: ModelStringInput | null,
  address?: ModelStringInput | null,
  postalCode?: ModelStringInput | null,
  city?: ModelStringInput | null,
  phone?: ModelStringInput | null,
  email?: ModelStringInput | null,
  billingRef?: ModelStringInput | null,
  customerNotes?: ModelStringInput | null,
  ssn?: ModelStringInput | null,
  rentalNotes?: ModelStringInput | null,
  internalNotes?: ModelStringInput | null,
  externalNotes?: ModelStringInput | null,
  outOfCommission?: ModelBooleanInput | null,
  vehiclePrice?: ModelIntInput | null,
  driverSSN?: ModelStringInput | null,
  differentDriver?: ModelBooleanInput | null,
  driverName?: ModelStringInput | null,
  driverAddress?: ModelStringInput | null,
  driverPostalCode?: ModelStringInput | null,
  driverCity?: ModelStringInput | null,
  driverPhone?: ModelStringInput | null,
  driverEmail?: ModelStringInput | null,
  additionalDriver?: ModelBooleanInput | null,
  additionalDriverName?: ModelStringInput | null,
  additionalDriverPhone?: ModelStringInput | null,
  additionalDriverSSN?: ModelStringInput | null,
  billPayment?: ModelBooleanInput | null,
  isCompany?: ModelBooleanInput | null,
  carStatus?: ModelRentalStatusInput | null,
  rentalStatus?: ModelRentalStatusInput | null,
  innovoiceStatus?: ModelReservationInnovoiceStatusInput | null,
  companyName?: ModelStringInput | null,
  companyBusinessId?: ModelStringInput | null,
  companyCity?: ModelStringInput | null,
  companyPostalCode?: ModelStringInput | null,
  companyBillingRef?: ModelStringInput | null,
  companyBillingAddress?: ModelStringInput | null,
  companyEBillingOperator?: ModelStringInput | null,
  companyEBillingAddress?: ModelStringInput | null,
  paymentToken?: ModelStringInput | null,
  npsStatus?: ModelNpsStatusInput | null,
  paymentStatus?: ModelPaymentStatusInput | null,
  signStatus?: ModelSignStatusInput | null,
  signDocumentId?: ModelStringInput | null,
  signerName?: ModelStringInput | null,
  reviewStatus?: ModelReviewStatusInput | null,
  authStatus?: ModelAuthStatusInput | null,
  authTime?: ModelStringInput | null,
  completeBefore?: ModelStringInput | null,
  mustSign?: ModelBooleanInput | null,
  customerCarRegistrationPlate?: ModelStringInput | null,
  enableRegistrationPlate?: ModelBooleanInput | null,
  disablePricesInPrintouts?: ModelBooleanInput | null,
  invoiceIds?: ModelIDInput | null,
  consolidatedInvoiceIds?: ModelIDInput | null,
  channel?: ModelReservationChannelInput | null,
  originURL?: ModelStringInput | null,
  type?: ModelReservationTypeInput | null,
  status?: ModelStringInput | null,
  pricingCategoryId?: ModelIDInput | null,
  customImportDate?: ModelStringInput | null,
  and?: Array< ModelReservationConditionInput | null > | null,
  or?: Array< ModelReservationConditionInput | null > | null,
  not?: ModelReservationConditionInput | null,
};

export type ModelLanguageInput = {
  eq?: Language | null,
  ne?: Language | null,
};

export type ModelRentalStatusInput = {
  eq?: RentalStatus | null,
  ne?: RentalStatus | null,
};

export type ModelReservationInnovoiceStatusInput = {
  eq?: ReservationInnovoiceStatus | null,
  ne?: ReservationInnovoiceStatus | null,
};

export type ModelNpsStatusInput = {
  eq?: NpsStatus | null,
  ne?: NpsStatus | null,
};

export type ModelPaymentStatusInput = {
  eq?: PaymentStatus | null,
  ne?: PaymentStatus | null,
};

export type ModelSignStatusInput = {
  eq?: SignStatus | null,
  ne?: SignStatus | null,
};

export type ModelReviewStatusInput = {
  eq?: ReviewStatus | null,
  ne?: ReviewStatus | null,
};

export type ModelAuthStatusInput = {
  eq?: AuthStatus | null,
  ne?: AuthStatus | null,
};

export type ModelReservationChannelInput = {
  eq?: ReservationChannel | null,
  ne?: ReservationChannel | null,
};

export type ModelReservationTypeInput = {
  eq?: ReservationType | null,
  ne?: ReservationType | null,
};

export type UpdateReservationInput = {
  id: string,
  shortId?: string | null,
  language?: Language | null,
  group?: string | null,
  organizationId?: string | null,
  orgBusinessId?: string | null,
  orgExternalBusinessId?: string | null,
  companyId?: string | null,
  returnCompanyId?: string | null,
  startLocation?: string | null,
  returnLocation?: string | null,
  startLocationID?: string | null,
  returnLocationID?: string | null,
  reviewId?: string | null,
  customerBrokerId?: string | null,
  customerCompanyId?: string | null,
  customerPersonalId?: string | null,
  reservationVehicleId?: string | null,
  reservationVehicles?: Array< ReservationVehicleInput | null > | null,
  wildCardVehicles?: Array< WildCardVehicleInput | null > | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  startTime?: string | null,
  returnTime?: string | null,
  endTime?: string | null,
  name?: string | null,
  address?: string | null,
  postalCode?: string | null,
  city?: string | null,
  phone?: string | null,
  email?: string | null,
  billingRef?: string | null,
  customerNotes?: string | null,
  ssn?: string | null,
  rentalNotes?: string | null,
  internalNotes?: string | null,
  externalNotes?: string | null,
  outOfCommission?: boolean | null,
  vehiclePrice?: number | null,
  additionalServices?: Array< AdditionalServiceReservationInput > | null,
  driverSSN?: string | null,
  differentDriver?: boolean | null,
  driverName?: string | null,
  driverAddress?: string | null,
  driverPostalCode?: string | null,
  driverCity?: string | null,
  driverPhone?: string | null,
  driverEmail?: string | null,
  additionalDriver?: boolean | null,
  additionalDriverName?: string | null,
  additionalDriverPhone?: string | null,
  additionalDriverSSN?: string | null,
  billPayment?: boolean | null,
  isCompany?: boolean | null,
  carStatus?: RentalStatus | null,
  rentalStatus?: RentalStatus | null,
  innovoiceStatus?: ReservationInnovoiceStatus | null,
  companyName?: string | null,
  companyBusinessId?: string | null,
  companyCity?: string | null,
  companyPostalCode?: string | null,
  companyBillingRef?: string | null,
  companyBillingAddress?: string | null,
  companyEBillingOperator?: string | null,
  companyEBillingAddress?: string | null,
  paymentToken?: string | null,
  npsStatus?: NpsStatus | null,
  paymentStatus?: PaymentStatus | null,
  paymentData?: PaymentDataInput | null,
  signStatus?: SignStatus | null,
  signDocumentId?: string | null,
  signerName?: string | null,
  reviewStatus?: ReviewStatus | null,
  authStatus?: AuthStatus | null,
  authTime?: string | null,
  completeBefore?: string | null,
  mustSign?: boolean | null,
  livionData?: Array< LivionDataInput | null > | null,
  tilakoneData?: TilakoneDataInput | null,
  voucherData?: VoucherDataInput | null,
  customerCarRegistrationPlate?: string | null,
  insuranceData?: InsuranceDataInput | null,
  externalData?: ExternalDataInput | null,
  enableRegistrationPlate?: boolean | null,
  disablePricesInPrintouts?: boolean | null,
  hanselData?: HanselDataInput | null,
  invoiceIds?: Array< string > | null,
  consolidatedInvoiceIds?: Array< string > | null,
  channel?: ReservationChannel | null,
  originURL?: string | null,
  type?: ReservationType | null,
  status?: string | null,
  reservationStatusInfo?: ReservationStatusInfoInput | null,
  initialCategory?: CategoryInfoInput | null,
  initialClassInfo?: InitialClassInfoInput | null,
  orgInvoiceCustomer?: OrganizationInvoiceCustomerInput | null,
  pricingCategoryId?: string | null,
  customImportDate?: string | null,
};

export type DeleteReservationInput = {
  id: string,
};

export type CreateReservationCountInput = {
  tenant: string,
  date: string,
  count: number,
};

export type ModelReservationCountConditionInput = {
  count?: ModelIntInput | null,
  and?: Array< ModelReservationCountConditionInput | null > | null,
  or?: Array< ModelReservationCountConditionInput | null > | null,
  not?: ModelReservationCountConditionInput | null,
};

export type ReservationCount = {
  __typename: "ReservationCount",
  tenant: string,
  date: string,
  count: number,
  createdAt: string,
  updatedAt: string,
};

export type UpdateReservationCountInput = {
  tenant: string,
  date: string,
  count?: number | null,
};

export type DeleteReservationCountInput = {
  tenant: string,
  date: string,
};

export type CreateInvoiceInput = {
  id?: string | null,
  createdAt?: string | null,
  innovoiceId?: string | null,
  reservationId?: string | null,
  reservationIds?: Array< string > | null,
  companyId?: string | null,
  innovoiceCompanyId?: string | null,
  innovoiceuRentCarRentalId?: string | null,
  innovoiceOrderId?: string | null,
  group?: string | null,
  organizationId?: string | null,
  orgBusinessId?: string | null,
  type?: InvoicePaymentType | null,
  creditNoteInvoiceId?: string | null,
  payment_terms?: number | null,
  vat_included?: number | null,
  delivery_by: InvoiceDeliveryBy,
  invoice_type: InvoiceType,
  delivery_date?: string | null,
  seller_reference?: string | null,
  buyer_reference?: string | null,
  items: Array< InvoiceItemInput >,
  customer: InvoiceCustomerInput,
  insurance?: InvoiceInsuranceInput | null,
};

export type InvoiceInsuranceInput = {
  insurance_company_id?: number | null,
  vehicle_class?: string | null,
  vehicle_regno?: string | null,
  claim_no?: string | null,
  rental_start_date?: string | null,
  rental_end_date?: string | null,
  rental_days?: number | null,
  amount_cost_per_day?: number | null,
  amount_to_pay_customer?: number | null,
  amount_to_pay_insurance?: number | null,
  coveragePercentage?: number | null,
};

export type ModelInvoiceConditionInput = {
  createdAt?: ModelStringInput | null,
  innovoiceId?: ModelStringInput | null,
  reservationId?: ModelIDInput | null,
  reservationIds?: ModelIDInput | null,
  companyId?: ModelIDInput | null,
  innovoiceCompanyId?: ModelStringInput | null,
  innovoiceuRentCarRentalId?: ModelStringInput | null,
  innovoiceOrderId?: ModelIDInput | null,
  type?: ModelInvoicePaymentTypeInput | null,
  creditNoteInvoiceId?: ModelIDInput | null,
  payment_terms?: ModelIntInput | null,
  vat_included?: ModelIntInput | null,
  delivery_by?: ModelInvoiceDeliveryByInput | null,
  invoice_type?: ModelInvoiceTypeInput | null,
  delivery_date?: ModelStringInput | null,
  seller_reference?: ModelStringInput | null,
  buyer_reference?: ModelStringInput | null,
  and?: Array< ModelInvoiceConditionInput | null > | null,
  or?: Array< ModelInvoiceConditionInput | null > | null,
  not?: ModelInvoiceConditionInput | null,
};

export type ModelInvoicePaymentTypeInput = {
  eq?: InvoicePaymentType | null,
  ne?: InvoicePaymentType | null,
};

export type ModelInvoiceDeliveryByInput = {
  eq?: InvoiceDeliveryBy | null,
  ne?: InvoiceDeliveryBy | null,
};

export type ModelInvoiceTypeInput = {
  eq?: InvoiceType | null,
  ne?: InvoiceType | null,
};

export type UpdateInvoiceInput = {
  id: string,
  createdAt?: string | null,
  innovoiceId?: string | null,
  reservationId?: string | null,
  reservationIds?: Array< string > | null,
  companyId?: string | null,
  innovoiceCompanyId?: string | null,
  innovoiceuRentCarRentalId?: string | null,
  innovoiceOrderId?: string | null,
  group?: string | null,
  organizationId?: string | null,
  orgBusinessId?: string | null,
  type?: InvoicePaymentType | null,
  creditNoteInvoiceId?: string | null,
  payment_terms?: number | null,
  vat_included?: number | null,
  delivery_by?: InvoiceDeliveryBy | null,
  invoice_type?: InvoiceType | null,
  delivery_date?: string | null,
  seller_reference?: string | null,
  buyer_reference?: string | null,
  items?: Array< InvoiceItemInput > | null,
  customer?: InvoiceCustomerInput | null,
  insurance?: InvoiceInsuranceInput | null,
};

export type DeleteInvoiceInput = {
  id: string,
};

export type CreateSignEventInput = {
  id?: string | null,
  reservationId: string,
  company: string,
  organizationId?: string | null,
  orgBusinessId?: string | null,
  group?: string | null,
  status: SignStatus,
  documentId?: string | null,
  source?: string | null,
};

export type ModelSignEventConditionInput = {
  reservationId?: ModelIDInput | null,
  company?: ModelIDInput | null,
  organizationId?: ModelIDInput | null,
  orgBusinessId?: ModelIDInput | null,
  status?: ModelSignStatusInput | null,
  documentId?: ModelStringInput | null,
  source?: ModelStringInput | null,
  and?: Array< ModelSignEventConditionInput | null > | null,
  or?: Array< ModelSignEventConditionInput | null > | null,
  not?: ModelSignEventConditionInput | null,
};

export type SignEvent = {
  __typename: "SignEvent",
  id: string,
  reservationId: string,
  company: string,
  organizationId?: string | null,
  orgBusinessId?: string | null,
  group?: string | null,
  status: SignStatus,
  documentId?: string | null,
  source?: string | null,
  createdAt: string,
  updatedAt: string,
};

export type UpdateSignEventInput = {
  id: string,
  reservationId?: string | null,
  company?: string | null,
  organizationId?: string | null,
  orgBusinessId?: string | null,
  group?: string | null,
  status?: SignStatus | null,
  documentId?: string | null,
  source?: string | null,
};

export type DeleteSignEventInput = {
  id: string,
};

export type CreatePaymentEventInput = {
  id?: string | null,
  channel?: string | null,
  paymentMethod?: string | null,
  paymentTotal?: number | null,
  isDeleted?: boolean | null,
  deletedBy?: string | null,
  deletedAt?: string | null,
  createdByUserID?: string | null,
  createdByUserName?: string | null,
  reservationId?: string | null,
  organizationId?: string | null,
  orgBusinessId?: string | null,
  group?: string | null,
  msg?: string | null,
  state?: PaymentEventState | null,
  returnCode?: number | null,
  incidentId?: string | null,
  parameters?: string | null,
  apiPath?: string | null,
  paymentStatus?: string | null,
  orderNumber?: string | null,
};

export type ModelPaymentEventConditionInput = {
  channel?: ModelStringInput | null,
  paymentMethod?: ModelStringInput | null,
  paymentTotal?: ModelIntInput | null,
  isDeleted?: ModelBooleanInput | null,
  deletedBy?: ModelStringInput | null,
  deletedAt?: ModelStringInput | null,
  createdByUserID?: ModelIDInput | null,
  createdByUserName?: ModelStringInput | null,
  reservationId?: ModelIDInput | null,
  msg?: ModelStringInput | null,
  state?: ModelPaymentEventStateInput | null,
  returnCode?: ModelIntInput | null,
  incidentId?: ModelStringInput | null,
  parameters?: ModelStringInput | null,
  apiPath?: ModelStringInput | null,
  paymentStatus?: ModelStringInput | null,
  orderNumber?: ModelStringInput | null,
  and?: Array< ModelPaymentEventConditionInput | null > | null,
  or?: Array< ModelPaymentEventConditionInput | null > | null,
  not?: ModelPaymentEventConditionInput | null,
};

export type ModelPaymentEventStateInput = {
  eq?: PaymentEventState | null,
  ne?: PaymentEventState | null,
};

export type UpdatePaymentEventInput = {
  id: string,
  channel?: string | null,
  paymentMethod?: string | null,
  paymentTotal?: number | null,
  isDeleted?: boolean | null,
  deletedBy?: string | null,
  deletedAt?: string | null,
  createdByUserID?: string | null,
  createdByUserName?: string | null,
  reservationId?: string | null,
  organizationId?: string | null,
  orgBusinessId?: string | null,
  group?: string | null,
  msg?: string | null,
  state?: PaymentEventState | null,
  returnCode?: number | null,
  incidentId?: string | null,
  parameters?: string | null,
  apiPath?: string | null,
  paymentStatus?: string | null,
  orderNumber?: string | null,
};

export type DeletePaymentEventInput = {
  id: string,
};

export type CreateAdditionalServiceInput = {
  id?: string | null,
  organizationId?: string | null,
  orgBusinessId?: string | null,
  group?: string | null,
  description?: string | null,
  descriptionT?: TranslationInput | null,
  description2?: string | null,
  category: string,
  type?: ServiceType | null,
  enableTextInput?: boolean | null,
  enableQuantity?: boolean | null,
  enableNotification?: boolean | null,
  maxQuantity?: number | null,
  inputLabel?: string | null,
  _removed?: string | null,
  isGroupParent?: boolean | null,
  groupFields?: Array< string | null > | null,
  groupParentId?: string | null,
  groupOrderNumber?: number | null,
  image?: string | null,
};

export type ModelAdditionalServiceConditionInput = {
  description?: ModelStringInput | null,
  description2?: ModelStringInput | null,
  category?: ModelStringInput | null,
  type?: ModelServiceTypeInput | null,
  enableTextInput?: ModelBooleanInput | null,
  enableQuantity?: ModelBooleanInput | null,
  enableNotification?: ModelBooleanInput | null,
  maxQuantity?: ModelIntInput | null,
  inputLabel?: ModelStringInput | null,
  _removed?: ModelStringInput | null,
  isGroupParent?: ModelBooleanInput | null,
  groupFields?: ModelStringInput | null,
  groupParentId?: ModelStringInput | null,
  groupOrderNumber?: ModelIntInput | null,
  image?: ModelStringInput | null,
  and?: Array< ModelAdditionalServiceConditionInput | null > | null,
  or?: Array< ModelAdditionalServiceConditionInput | null > | null,
  not?: ModelAdditionalServiceConditionInput | null,
};

export type ModelServiceTypeInput = {
  eq?: ServiceType | null,
  ne?: ServiceType | null,
};

export type AdditionalService = {
  __typename: "AdditionalService",
  id: string,
  organizationId?: string | null,
  orgBusinessId?: string | null,
  group?: string | null,
  description?: string | null,
  descriptionT?: Translation | null,
  description2?: string | null,
  category: string,
  type?: ServiceType | null,
  enableTextInput?: boolean | null,
  enableQuantity?: boolean | null,
  enableNotification?: boolean | null,
  maxQuantity?: number | null,
  inputLabel?: string | null,
  _removed?: string | null,
  isGroupParent?: boolean | null,
  groupFields?: Array< string | null > | null,
  groupParentId?: string | null,
  groupOrderNumber?: number | null,
  image?: string | null,
  createdAt: string,
  updatedAt: string,
};

export type UpdateAdditionalServiceInput = {
  id: string,
  organizationId?: string | null,
  orgBusinessId?: string | null,
  group?: string | null,
  description?: string | null,
  descriptionT?: TranslationInput | null,
  description2?: string | null,
  category?: string | null,
  type?: ServiceType | null,
  enableTextInput?: boolean | null,
  enableQuantity?: boolean | null,
  enableNotification?: boolean | null,
  maxQuantity?: number | null,
  inputLabel?: string | null,
  _removed?: string | null,
  isGroupParent?: boolean | null,
  groupFields?: Array< string | null > | null,
  groupParentId?: string | null,
  groupOrderNumber?: number | null,
  image?: string | null,
};

export type DeleteAdditionalServiceInput = {
  id: string,
};

export type CreatePricingInput = {
  id?: string | null,
  name: string,
  group?: string | null,
  organizationId?: string | null,
  orgBusinessId?: string | null,
  orgExternalBusinessId?: string | null,
  externalBusinessCategoryAssociation?: string | null,
  businessCategoryAssociation?: string | null,
  elements: Array< PricingElementInput >,
  useNextDayPrice?: boolean | null,
  useLastDayPrice?: boolean | null,
  tax?: number | null,
  _removed?: string | null,
  deductible?: number | null,
  reducedDeductible?: number | null,
  reducedDeductiblePrice?: number | null,
};

export type PricingElementInput = {
  duration: string,
  price: number,
};

export type ModelPricingConditionInput = {
  name?: ModelStringInput | null,
  orgExternalBusinessId?: ModelIDInput | null,
  externalBusinessCategoryAssociation?: ModelIDInput | null,
  businessCategoryAssociation?: ModelIDInput | null,
  useNextDayPrice?: ModelBooleanInput | null,
  useLastDayPrice?: ModelBooleanInput | null,
  tax?: ModelFloatInput | null,
  _removed?: ModelStringInput | null,
  deductible?: ModelIntInput | null,
  reducedDeductible?: ModelIntInput | null,
  reducedDeductiblePrice?: ModelIntInput | null,
  and?: Array< ModelPricingConditionInput | null > | null,
  or?: Array< ModelPricingConditionInput | null > | null,
  not?: ModelPricingConditionInput | null,
};

export type ModelFloatInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type UpdatePricingInput = {
  id: string,
  name?: string | null,
  group?: string | null,
  organizationId?: string | null,
  orgBusinessId?: string | null,
  orgExternalBusinessId?: string | null,
  externalBusinessCategoryAssociation?: string | null,
  businessCategoryAssociation?: string | null,
  elements?: Array< PricingElementInput > | null,
  useNextDayPrice?: boolean | null,
  useLastDayPrice?: boolean | null,
  tax?: number | null,
  _removed?: string | null,
  deductible?: number | null,
  reducedDeductible?: number | null,
  reducedDeductiblePrice?: number | null,
};

export type DeletePricingInput = {
  id: string,
};

export type CreatePricingExceptionInput = {
  id?: string | null,
  name: string,
  group?: string | null,
  organizationId?: string | null,
  orgBusinessId?: string | null,
  type: PricingExceptionType,
  amountType: PricingExceptionAmountType,
  weeklyAmounts?: Array< number > | null,
  monthlyAmountPerDay?: number | null,
  daysBeforeMonthChange?: number | null,
  daysAfterMonthChange?: number | null,
  startDate?: string | null,
  endDate?: string | null,
  pricingIds?: Array< string > | null,
};

export enum PricingExceptionType {
  WEEK = "WEEK",
  MONTH = "MONTH",
}


export enum PricingExceptionAmountType {
  FIXED = "FIXED",
  PERCENT = "PERCENT",
}


export type ModelPricingExceptionConditionInput = {
  name?: ModelStringInput | null,
  type?: ModelPricingExceptionTypeInput | null,
  amountType?: ModelPricingExceptionAmountTypeInput | null,
  weeklyAmounts?: ModelFloatInput | null,
  monthlyAmountPerDay?: ModelIntInput | null,
  daysBeforeMonthChange?: ModelIntInput | null,
  daysAfterMonthChange?: ModelIntInput | null,
  startDate?: ModelStringInput | null,
  endDate?: ModelStringInput | null,
  pricingIds?: ModelIDInput | null,
  and?: Array< ModelPricingExceptionConditionInput | null > | null,
  or?: Array< ModelPricingExceptionConditionInput | null > | null,
  not?: ModelPricingExceptionConditionInput | null,
};

export type ModelPricingExceptionTypeInput = {
  eq?: PricingExceptionType | null,
  ne?: PricingExceptionType | null,
};

export type ModelPricingExceptionAmountTypeInput = {
  eq?: PricingExceptionAmountType | null,
  ne?: PricingExceptionAmountType | null,
};

export type PricingException = {
  __typename: "PricingException",
  id: string,
  name: string,
  group?: string | null,
  organizationId?: string | null,
  orgBusinessId?: string | null,
  type: PricingExceptionType,
  amountType: PricingExceptionAmountType,
  weeklyAmounts?: Array< number > | null,
  monthlyAmountPerDay?: number | null,
  daysBeforeMonthChange?: number | null,
  daysAfterMonthChange?: number | null,
  startDate?: string | null,
  endDate?: string | null,
  pricingIds?: Array< string > | null,
  createdAt: string,
  updatedAt: string,
};

export type UpdatePricingExceptionInput = {
  id: string,
  name?: string | null,
  group?: string | null,
  organizationId?: string | null,
  orgBusinessId?: string | null,
  type?: PricingExceptionType | null,
  amountType?: PricingExceptionAmountType | null,
  weeklyAmounts?: Array< number > | null,
  monthlyAmountPerDay?: number | null,
  daysBeforeMonthChange?: number | null,
  daysAfterMonthChange?: number | null,
  startDate?: string | null,
  endDate?: string | null,
  pricingIds?: Array< string > | null,
};

export type DeletePricingExceptionInput = {
  id: string,
};

export type CreateVoucherInput = {
  id?: string | null,
  name: string,
  group?: string | null,
  organizationId?: string | null,
  code: string,
  amount: number,
  startDate?: string | null,
  endDate?: string | null,
  _removed?: string | null,
};

export type ModelVoucherConditionInput = {
  name?: ModelStringInput | null,
  code?: ModelStringInput | null,
  amount?: ModelIntInput | null,
  startDate?: ModelStringInput | null,
  endDate?: ModelStringInput | null,
  _removed?: ModelStringInput | null,
  and?: Array< ModelVoucherConditionInput | null > | null,
  or?: Array< ModelVoucherConditionInput | null > | null,
  not?: ModelVoucherConditionInput | null,
};

export type Voucher = {
  __typename: "Voucher",
  id: string,
  name: string,
  group?: string | null,
  organizationId?: string | null,
  code: string,
  amount: number,
  startDate?: string | null,
  endDate?: string | null,
  _removed?: string | null,
  createdAt: string,
  updatedAt: string,
};

export type UpdateVoucherInput = {
  id: string,
  name?: string | null,
  group?: string | null,
  organizationId?: string | null,
  code?: string | null,
  amount?: number | null,
  startDate?: string | null,
  endDate?: string | null,
  _removed?: string | null,
};

export type DeleteVoucherInput = {
  id: string,
};

export type CreateReviewInput = {
  id?: string | null,
  group?: string | null,
  organizationId?: string | null,
  orgBusinessId?: string | null,
  orgExternalBusinessId?: string | null,
  rating: number,
  comment?: string | null,
  explanation?: string | null,
  reservationId: string,
  companyId?: string | null,
  startTime: string,
  returnTime: string,
};

export type ModelReviewConditionInput = {
  orgExternalBusinessId?: ModelIDInput | null,
  rating?: ModelIntInput | null,
  comment?: ModelStringInput | null,
  explanation?: ModelStringInput | null,
  reservationId?: ModelStringInput | null,
  companyId?: ModelStringInput | null,
  startTime?: ModelStringInput | null,
  returnTime?: ModelStringInput | null,
  and?: Array< ModelReviewConditionInput | null > | null,
  or?: Array< ModelReviewConditionInput | null > | null,
  not?: ModelReviewConditionInput | null,
};

export type Review = {
  __typename: "Review",
  id: string,
  group?: string | null,
  organizationId?: string | null,
  orgBusinessId?: string | null,
  orgExternalBusinessId?: string | null,
  rating: number,
  comment?: string | null,
  explanation?: string | null,
  reservationId: string,
  companyId?: string | null,
  startTime: string,
  returnTime: string,
  createdAt: string,
  updatedAt: string,
};

export type UpdateReviewInput = {
  id: string,
  group?: string | null,
  organizationId?: string | null,
  orgBusinessId?: string | null,
  orgExternalBusinessId?: string | null,
  rating?: number | null,
  comment?: string | null,
  explanation?: string | null,
  reservationId?: string | null,
  companyId?: string | null,
  startTime?: string | null,
  returnTime?: string | null,
};

export type DeleteReviewInput = {
  id: string,
};

export type CreateNotificationInput = {
  id?: string | null,
  type: NotificationType,
  status?: NotificationStatus | null,
  name?: string | null,
  message?: string | null,
  organizationId?: string | null,
  orgBusinessId?: string | null,
  group?: string | null,
  reservationId?: string | null,
  createdAt?: string | null,
};

export enum NotificationType {
  EXTERNAL_NOTE = "EXTERNAL_NOTE",
  LIVION_KEY_FAILURE = "LIVION_KEY_FAILURE",
  TILAKONE_FAILURE = "TILAKONE_FAILURE",
  ADDITIONAL_SERVICE = "ADDITIONAL_SERVICE",
  EXPLANATION_REQUEST = "EXPLANATION_REQUEST",
  PAYMENT_DEFAULTS = "PAYMENT_DEFAULTS",
  NEWS_CREATED = "NEWS_CREATED",
  EXTERNAL_ORDER = "EXTERNAL_ORDER",
  INSURANCE_EDIT = "INSURANCE_EDIT",
  CUSTOMER_AUTH_BYPASS = "CUSTOMER_AUTH_BYPASS",
}


export enum NotificationStatus {
  UNREAD = "UNREAD",
  READ = "READ",
}


export type ModelNotificationConditionInput = {
  type?: ModelNotificationTypeInput | null,
  status?: ModelNotificationStatusInput | null,
  name?: ModelStringInput | null,
  message?: ModelStringInput | null,
  organizationId?: ModelIDInput | null,
  reservationId?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelNotificationConditionInput | null > | null,
  or?: Array< ModelNotificationConditionInput | null > | null,
  not?: ModelNotificationConditionInput | null,
};

export type ModelNotificationTypeInput = {
  eq?: NotificationType | null,
  ne?: NotificationType | null,
};

export type ModelNotificationStatusInput = {
  eq?: NotificationStatus | null,
  ne?: NotificationStatus | null,
};

export type Notification = {
  __typename: "Notification",
  id: string,
  type: NotificationType,
  status?: NotificationStatus | null,
  name?: string | null,
  message?: string | null,
  organizationId?: string | null,
  orgBusinessId?: string | null,
  group?: string | null,
  reservationId?: string | null,
  createdAt: string,
  updatedAt: string,
};

export type UpdateNotificationInput = {
  id: string,
  type?: NotificationType | null,
  status?: NotificationStatus | null,
  name?: string | null,
  message?: string | null,
  organizationId?: string | null,
  orgBusinessId?: string | null,
  group?: string | null,
  reservationId?: string | null,
  createdAt?: string | null,
};

export type DeleteNotificationInput = {
  id: string,
};

export type CreateCustomerInput = {
  id?: string | null,
  organizationId?: string | null,
  orgBusinessId?: string | null,
  orgExternalBusinessId?: string | null,
  group?: string | null,
  companyName?: string | null,
  brokerName?: string | null,
  address?: string | null,
  postalCode?: string | null,
  city?: string | null,
  phone?: string | null,
  email?: string | null,
  companyBillingAddress?: string | null,
  companyBusinessId?: string | null,
  companyCity?: string | null,
  companyPostalCode?: string | null,
  companyBillingRef?: string | null,
  companyEBillingOperator?: string | null,
  companyEBillingAddress?: string | null,
  clientType?: string | null,
  isDeleted?: boolean | null,
};

export type ModelCustomerConditionInput = {
  orgExternalBusinessId?: ModelIDInput | null,
  companyName?: ModelStringInput | null,
  brokerName?: ModelStringInput | null,
  address?: ModelStringInput | null,
  postalCode?: ModelStringInput | null,
  city?: ModelStringInput | null,
  phone?: ModelStringInput | null,
  email?: ModelStringInput | null,
  companyBillingAddress?: ModelStringInput | null,
  companyBusinessId?: ModelStringInput | null,
  companyCity?: ModelStringInput | null,
  companyPostalCode?: ModelStringInput | null,
  companyBillingRef?: ModelStringInput | null,
  companyEBillingOperator?: ModelStringInput | null,
  companyEBillingAddress?: ModelStringInput | null,
  clientType?: ModelStringInput | null,
  isDeleted?: ModelBooleanInput | null,
  and?: Array< ModelCustomerConditionInput | null > | null,
  or?: Array< ModelCustomerConditionInput | null > | null,
  not?: ModelCustomerConditionInput | null,
};

export type Customer = {
  __typename: "Customer",
  id: string,
  organizationId?: string | null,
  orgBusinessId?: string | null,
  orgExternalBusinessId?: string | null,
  group?: string | null,
  companyName?: string | null,
  brokerName?: string | null,
  address?: string | null,
  postalCode?: string | null,
  city?: string | null,
  phone?: string | null,
  email?: string | null,
  companyBillingAddress?: string | null,
  companyBusinessId?: string | null,
  companyCity?: string | null,
  companyPostalCode?: string | null,
  companyBillingRef?: string | null,
  companyEBillingOperator?: string | null,
  companyEBillingAddress?: string | null,
  clientType?: string | null,
  isDeleted?: boolean | null,
  createdAt: string,
  updatedAt: string,
};

export type UpdateCustomerInput = {
  id: string,
  organizationId?: string | null,
  orgBusinessId?: string | null,
  orgExternalBusinessId?: string | null,
  group?: string | null,
  companyName?: string | null,
  brokerName?: string | null,
  address?: string | null,
  postalCode?: string | null,
  city?: string | null,
  phone?: string | null,
  email?: string | null,
  companyBillingAddress?: string | null,
  companyBusinessId?: string | null,
  companyCity?: string | null,
  companyPostalCode?: string | null,
  companyBillingRef?: string | null,
  companyEBillingOperator?: string | null,
  companyEBillingAddress?: string | null,
  clientType?: string | null,
  isDeleted?: boolean | null,
};

export type DeleteCustomerInput = {
  id: string,
};

export type ReservationOffer = {
  __typename: "ReservationOffer",
  startTime: string,
  returnTime: string,
  vehicleOfferPrice: number,
  tax?: number | null,
  additionalServiceOffers:  Array<AdditionalServiceOffer >,
  freeTimes:  Array<FreeTime >,
  reservedTimes?:  Array<DateRange | null > | null,
  availableInCategory?: number | null,
  useVehicleTimes?: boolean | null,
  disableOffHourReturn?: boolean | null,
  vehicle: Vehicle,
};

export type AdditionalServiceOffer = {
  __typename: "AdditionalServiceOffer",
  offerPrice: number,
  tax?: number | null,
  deductibleValue?: number | null,
  description?: string | null,
  descriptionT?: Translation | null,
  category?: string | null,
  adHocService?: boolean | null,
  image?: string | null,
  key: string,
};

export type FreeTime = {
  __typename: "FreeTime",
  lastStartTime: string,
  lastReturnTime: string,
  returnTimes?:  Array<DateRange | null > | null,
  firstStartTime: string,
};

export type DateRange = {
  __typename: "DateRange",
  end: string,
  start: string,
};

export type CategoryPrices = {
  __typename: "CategoryPrices",
  categoryOfferPrices:  Array<ReservationVehiclePrice >,
  additionalServiceOffers:  Array<AdditionalServicePrice >,
};

export type ReservationVehiclePrice = {
  __typename: "ReservationVehiclePrice",
  price: number,
  tax?: number | null,
  deductible?: number | null,
  id: string,
};

export type AdditionalServicePrice = {
  __typename: "AdditionalServicePrice",
  description?: string | null,
  category?: string | null,
  offerPrice: number,
  deductibleValue?: number | null,
  tax?: number | null,
  image?: string | null,
  enableQuantity?: boolean | null,
  maxQuantity?: number | null,
  key: string,
};

export type CreditResult = {
  __typename: "CreditResult",
  granted?: boolean | null,
  amount?: number | null,
  creditDecisionText: string,
  message: string,
};

export type ModelNewsItemFilterInput = {
  id?: ModelIDInput | null,
  header?: ModelStringInput | null,
  link?: ModelStringInput | null,
  content?: ModelStringInput | null,
  and?: Array< ModelNewsItemFilterInput | null > | null,
  or?: Array< ModelNewsItemFilterInput | null > | null,
  not?: ModelNewsItemFilterInput | null,
};

export type ModelNewsItemConnection = {
  __typename: "ModelNewsItemConnection",
  items:  Array<NewsItem | null >,
  nextToken?: string | null,
};

export type ReservationsByVehicle = {
  __typename: "ReservationsByVehicle",
  reservedTimes?:  Array<DateRange | null > | null,
  useVehicleTimes?: boolean | null,
  vehicle?: Vehicle | null,
};

export type VehiclePrices = {
  __typename: "VehiclePrices",
  vehicleOfferPrices:  Array<ReservationVehiclePrice >,
  additionalServiceOffers:  Array<AdditionalServicePrice >,
};

export type VehiclePrice = {
  __typename: "VehiclePrice",
  vehicleOfferPrice: number,
  additionalServiceOffers:  Array<AdditionalServicePrice >,
};

export type PaymentMethod = {
  __typename: "PaymentMethod",
  selected_value: string,
  group: string,
  min_amount: number,
  max_amount: number,
  img: string,
  img_timestamp: number,
  currency: Array< string >,
  name: string,
};

export type PaymentMethodInput = {
  name: string,
  selected_value: string,
  group: string,
  min_amount: number,
  max_amount: number,
  img: string,
  img_timestamp: number,
  currency: Array< string >,
};

export type AuthPaymentResult = {
  __typename: "AuthPaymentResult",
  token: string,
  type: string,
  result: number,
};

export type ReviewStatePublic = {
  __typename: "ReviewStatePublic",
  reservationInfo?: ReservationInfo | null,
  state?: ReviewState | null,
};

export enum ReviewState {
  REVIEW_PENDING = "REVIEW_PENDING",
  REVIEW_DONE = "REVIEW_DONE",
  PENDING_EXPLANATION = "PENDING_EXPLANATION",
  REVIEW_EXPIRED = "REVIEW_EXPIRED",
}


export enum ModelSortDirection {
  ASC = "ASC",
  DESC = "DESC",
}


export type ModelCompanyFilterInput = {
  id?: ModelIDInput | null,
  organizationId?: ModelIDInput | null,
  orgBusinessId?: ModelIDInput | null,
  group?: ModelStringInput | null,
  name?: ModelStringInput | null,
  displayName?: ModelStringInput | null,
  email?: ModelStringInput | null,
  address?: ModelStringInput | null,
  postalCode?: ModelStringInput | null,
  city?: ModelStringInput | null,
  orderNumber?: ModelIntInput | null,
  orderNumberDealer?: ModelIntInput | null,
  phone?: ModelStringInput | null,
  businessId?: ModelStringInput | null,
  termsUrl?: ModelStringInput | null,
  timezone?: ModelStringInput | null,
  signConfirmationEmail?: ModelStringInput | null,
  nameInEmail?: ModelStringInput | null,
  senderNameSMS?: ModelStringInput | null,
  confirmationMsg?: ModelStringInput | null,
  feedbackMsg?: ModelStringInput | null,
  statusLinkMsg?: ModelStringInput | null,
  livionDeviceId?: ModelStringInput | null,
  livionDeviceType?: ModelLivionDeviceInput | null,
  enableReservationDeposit?: ModelBooleanInput | null,
  reservationLinkExpirationHours?: ModelIntInput | null,
  disableOffHourReturn?: ModelBooleanInput | null,
  disableMultiday?: ModelBooleanInput | null,
  disableSameDay?: ModelBooleanInput | null,
  disableStartDaysFromNow?: ModelIntInput | null,
  enableCart?: ModelBooleanInput | null,
  enableMonthlyView?: ModelBooleanInput | null,
  enableRegistrationPlate?: ModelBooleanInput | null,
  enableVehicleStartReturnTimes?: ModelBooleanInput | null,
  enableMustSign?: ModelBooleanInput | null,
  enablePaymentOnlineOnly?: ModelBooleanInput | null,
  enableGTM?: ModelBooleanInput | null,
  enableNetPromoterScore?: ModelBooleanInput | null,
  enableNPS?: ModelBooleanInput | null,
  limitReservationOncePerDay?: ModelBooleanInput | null,
  hidden?: ModelBooleanInput | null,
  locationType?: ModelStringInput | null,
  offHourAvailability?: ModelBooleanInput | null,
  offHourAvailabilityFee?: ModelIntInput | null,
  deliveryFee?: ModelIntInput | null,
  deliveryBufferHours?: ModelIntInput | null,
  notAllowedCategoryIds?: ModelIDInput | null,
  importIdentifier?: ModelStringInput | null,
  contractSettingsJSON?: ModelStringInput | null,
  disableReservationCategoryIds?: ModelIDInput | null,
  locationColor?: ModelStringInput | null,
  and?: Array< ModelCompanyFilterInput | null > | null,
  or?: Array< ModelCompanyFilterInput | null > | null,
  not?: ModelCompanyFilterInput | null,
};

export type ModelStringKeyConditionInput = {
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
};

export type ModelBusinessHoursExceptionFilterInput = {
  organizationId?: ModelIDInput | null,
  orgBusinessId?: ModelIDInput | null,
  group?: ModelStringInput | null,
  date?: ModelStringInput | null,
  name?: ModelStringInput | null,
  companyId?: ModelIDInput | null,
  and?: Array< ModelBusinessHoursExceptionFilterInput | null > | null,
  or?: Array< ModelBusinessHoursExceptionFilterInput | null > | null,
  not?: ModelBusinessHoursExceptionFilterInput | null,
};

export type ModelVehicleFilterInput = {
  id?: ModelIDInput | null,
  group?: ModelStringInput | null,
  organizationId?: ModelIDInput | null,
  orgBusinessId?: ModelIDInput | null,
  companyId?: ModelIDInput | null,
  name?: ModelStringInput | null,
  registrationPlate?: ModelStringInput | null,
  damages?: ModelStringInput | null,
  image?: ModelStringInput | null,
  powerType?: ModelStringInput | null,
  deductible?: ModelIntInput | null,
  includedDistance?: ModelStringInput | null,
  mileage?: ModelStringInput | null,
  orderNumber?: ModelIntInput | null,
  vehicleCategoryId?: ModelIDInput | null,
  useCategoryDefaults?: ModelBooleanInput | null,
  useStartReturnTimes?: ModelBooleanInput | null,
  hideExport?: ModelBooleanInput | null,
  extraInfo?: ModelStringInput | null,
  extraInfoT?: ModelStringInput | null,
  extraToConfirmation?: ModelStringInput | null,
  extraToConfirmationT?: ModelStringInput | null,
  livionKeyId?: ModelStringInput | null,
  phoneNumber?: ModelStringInput | null,
  importData?: ModelStringInput | null,
  disableOnline?: ModelBooleanInput | null,
  and?: Array< ModelVehicleFilterInput | null > | null,
  or?: Array< ModelVehicleFilterInput | null > | null,
  not?: ModelVehicleFilterInput | null,
};

export type SearchableVehicleFilterInput = {
  id?: SearchableIDFilterInput | null,
  group?: SearchableStringFilterInput | null,
  organizationId?: SearchableIDFilterInput | null,
  orgBusinessId?: SearchableIDFilterInput | null,
  companyId?: SearchableIDFilterInput | null,
  name?: SearchableStringFilterInput | null,
  registrationPlate?: SearchableStringFilterInput | null,
  damages?: SearchableStringFilterInput | null,
  image?: SearchableStringFilterInput | null,
  powerType?: SearchableStringFilterInput | null,
  deductible?: SearchableIntFilterInput | null,
  includedDistance?: SearchableStringFilterInput | null,
  mileage?: SearchableStringFilterInput | null,
  orderNumber?: SearchableIntFilterInput | null,
  vehicleCategoryId?: SearchableIDFilterInput | null,
  useCategoryDefaults?: SearchableBooleanFilterInput | null,
  useStartReturnTimes?: SearchableBooleanFilterInput | null,
  hideExport?: SearchableBooleanFilterInput | null,
  extraInfo?: SearchableStringFilterInput | null,
  extraInfoT?: SearchableStringFilterInput | null,
  extraToConfirmation?: SearchableStringFilterInput | null,
  extraToConfirmationT?: SearchableStringFilterInput | null,
  livionKeyId?: SearchableStringFilterInput | null,
  phoneNumber?: SearchableStringFilterInput | null,
  importData?: SearchableStringFilterInput | null,
  disableOnline?: SearchableBooleanFilterInput | null,
  and?: Array< SearchableVehicleFilterInput | null > | null,
  or?: Array< SearchableVehicleFilterInput | null > | null,
  not?: SearchableVehicleFilterInput | null,
};

export type SearchableIDFilterInput = {
  ne?: string | null,
  gt?: string | null,
  lt?: string | null,
  gte?: string | null,
  lte?: string | null,
  eq?: string | null,
  match?: string | null,
  matchPhrase?: string | null,
  matchPhrasePrefix?: string | null,
  multiMatch?: string | null,
  exists?: boolean | null,
  wildcard?: string | null,
  regexp?: string | null,
  range?: Array< string | null > | null,
};

export type SearchableStringFilterInput = {
  ne?: string | null,
  gt?: string | null,
  lt?: string | null,
  gte?: string | null,
  lte?: string | null,
  eq?: string | null,
  match?: string | null,
  matchPhrase?: string | null,
  matchPhrasePrefix?: string | null,
  multiMatch?: string | null,
  exists?: boolean | null,
  wildcard?: string | null,
  regexp?: string | null,
  range?: Array< string | null > | null,
};

export type SearchableIntFilterInput = {
  ne?: number | null,
  gt?: number | null,
  lt?: number | null,
  gte?: number | null,
  lte?: number | null,
  eq?: number | null,
  range?: Array< number | null > | null,
};

export type SearchableBooleanFilterInput = {
  eq?: boolean | null,
  ne?: boolean | null,
};

export type SearchableVehicleSortInput = {
  field?: SearchableVehicleSortableFields | null,
  direction?: SearchableSortDirection | null,
};

export enum SearchableVehicleSortableFields {
  id = "id",
  group = "group",
  organizationId = "organizationId",
  orgBusinessId = "orgBusinessId",
  companyId = "companyId",
  name = "name",
  registrationPlate = "registrationPlate",
  damages = "damages",
  image = "image",
  powerType = "powerType",
  deductible = "deductible",
  includedDistance = "includedDistance",
  mileage = "mileage",
  orderNumber = "orderNumber",
  vehicleCategoryId = "vehicleCategoryId",
  useCategoryDefaults = "useCategoryDefaults",
  useStartReturnTimes = "useStartReturnTimes",
  hideExport = "hideExport",
  extraInfo = "extraInfo",
  extraInfoT = "extraInfoT",
  extraToConfirmation = "extraToConfirmation",
  extraToConfirmationT = "extraToConfirmationT",
  livionKeyId = "livionKeyId",
  phoneNumber = "phoneNumber",
  importData = "importData",
  disableOnline = "disableOnline",
}


export enum SearchableSortDirection {
  asc = "asc",
  desc = "desc",
}


export type SearchableVehicleConnection = {
  __typename: "SearchableVehicleConnection",
  items:  Array<Vehicle | null >,
  nextToken?: string | null,
  total?: number | null,
};

export type ModelCategoryFilterInput = {
  id?: ModelIDInput | null,
  group?: ModelStringInput | null,
  organizationId?: ModelIDInput | null,
  name?: ModelStringInput | null,
  image?: ModelStringInput | null,
  orderNumber?: ModelIntInput | null,
  backgroundColor?: ModelStringInput | null,
  minuteBuffer?: ModelIntInput | null,
  reservationBlockFromNow?: ModelIntInput | null,
  typeId?: ModelIDInput | null,
  _removed?: ModelStringInput | null,
  oldTujausValue?: ModelStringInput | null,
  exampleModelName?: ModelStringInput | null,
  exampleModelImage?: ModelStringInput | null,
  and?: Array< ModelCategoryFilterInput | null > | null,
  or?: Array< ModelCategoryFilterInput | null > | null,
  not?: ModelCategoryFilterInput | null,
};

export type ModelAdditionalServicePricingFilterInput = {
  id?: ModelIDInput | null,
  group?: ModelStringInput | null,
  organizationId?: ModelIDInput | null,
  orgBusinessId?: ModelIDInput | null,
  key?: ModelStringInput | null,
  vehicleId?: ModelIDInput | null,
  categoryId?: ModelIDInput | null,
  pricingId?: ModelIDInput | null,
  deductibleValue?: ModelIntInput | null,
  and?: Array< ModelAdditionalServicePricingFilterInput | null > | null,
  or?: Array< ModelAdditionalServicePricingFilterInput | null > | null,
  not?: ModelAdditionalServicePricingFilterInput | null,
};

export type ModelUserFilterInput = {
  id?: ModelIDInput | null,
  organizationId?: ModelIDInput | null,
  orgBusinessId?: ModelIDInput | null,
  orgBusinessIds?: ModelIDInput | null,
  externalBusinessId?: ModelIDInput | null,
  role?: ModelUserRoleInput | null,
  email?: ModelStringInput | null,
  name?: ModelStringInput | null,
  regionalCompanyName?: ModelStringInput | null,
  and?: Array< ModelUserFilterInput | null > | null,
  or?: Array< ModelUserFilterInput | null > | null,
  not?: ModelUserFilterInput | null,
};

export type ModelUserConnection = {
  __typename: "ModelUserConnection",
  items:  Array<User | null >,
  nextToken?: string | null,
};

export type ModelOrganizationFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  and?: Array< ModelOrganizationFilterInput | null > | null,
  or?: Array< ModelOrganizationFilterInput | null > | null,
  not?: ModelOrganizationFilterInput | null,
};

export type ModelOrganizationConnection = {
  __typename: "ModelOrganizationConnection",
  items:  Array<Organization | null >,
  nextToken?: string | null,
};

export type ModelExternalBusinessFilterInput = {
  id?: ModelIDInput | null,
  organizationId?: ModelIDInput | null,
  type?: ModelExternalBusinessTypeInput | null,
  name?: ModelStringInput | null,
  insuranceCompensatableTimes?: ModelIntInput | null,
  useHansel?: ModelBooleanInput | null,
  hanselId?: ModelStringInput | null,
  availableOrgBusinessIds?: ModelIDInput | null,
  availableCompanyIds?: ModelIDInput | null,
  availableVehicleClasses?: ModelStringInput | null,
  eBillingRef?: ModelStringInput | null,
  eBillingOperator?: ModelStringInput | null,
  eBillingAddress?: ModelStringInput | null,
  and?: Array< ModelExternalBusinessFilterInput | null > | null,
  or?: Array< ModelExternalBusinessFilterInput | null > | null,
  not?: ModelExternalBusinessFilterInput | null,
};

export type ModelBusinessFilterInput = {
  id?: ModelIDInput | null,
  organizationId?: ModelIDInput | null,
  telegramChatId?: ModelStringInput | null,
  group?: ModelStringInput | null,
  name?: ModelStringInput | null,
  address?: ModelStringInput | null,
  city?: ModelStringInput | null,
  email?: ModelStringInput | null,
  phone?: ModelStringInput | null,
  importIdentifier?: ModelStringInput | null,
  and?: Array< ModelBusinessFilterInput | null > | null,
  or?: Array< ModelBusinessFilterInput | null > | null,
  not?: ModelBusinessFilterInput | null,
};

export type ModelArchiveReservationFilterInput = {
  id?: ModelIDInput | null,
  organizationId?: ModelIDInput | null,
  orgBusinessId?: ModelIDInput | null,
  reservationJSON?: ModelStringInput | null,
  archiveMessage?: ModelStringInput | null,
  archivedAt?: ModelStringInput | null,
  archivedByUserId?: ModelIDInput | null,
  startTime?: ModelStringInput | null,
  returnTime?: ModelStringInput | null,
  reservationCreatedAt?: ModelStringInput | null,
  customerBrokerId?: ModelIDInput | null,
  and?: Array< ModelArchiveReservationFilterInput | null > | null,
  or?: Array< ModelArchiveReservationFilterInput | null > | null,
  not?: ModelArchiveReservationFilterInput | null,
};

export type ModelArchiveReservationConnection = {
  __typename: "ModelArchiveReservationConnection",
  items:  Array<ArchiveReservation | null >,
  nextToken?: string | null,
};

export type SearchableArchiveReservationFilterInput = {
  id?: SearchableIDFilterInput | null,
  organizationId?: SearchableIDFilterInput | null,
  orgBusinessId?: SearchableIDFilterInput | null,
  reservationJSON?: SearchableStringFilterInput | null,
  archiveMessage?: SearchableStringFilterInput | null,
  archivedAt?: SearchableStringFilterInput | null,
  archivedByUserId?: SearchableIDFilterInput | null,
  startTime?: SearchableStringFilterInput | null,
  returnTime?: SearchableStringFilterInput | null,
  reservationCreatedAt?: SearchableStringFilterInput | null,
  customerBrokerId?: SearchableIDFilterInput | null,
  and?: Array< SearchableArchiveReservationFilterInput | null > | null,
  or?: Array< SearchableArchiveReservationFilterInput | null > | null,
  not?: SearchableArchiveReservationFilterInput | null,
};

export type SearchableArchiveReservationSortInput = {
  field?: SearchableArchiveReservationSortableFields | null,
  direction?: SearchableSortDirection | null,
};

export enum SearchableArchiveReservationSortableFields {
  id = "id",
  organizationId = "organizationId",
  orgBusinessId = "orgBusinessId",
  reservationJSON = "reservationJSON",
  archiveMessage = "archiveMessage",
  archivedAt = "archivedAt",
  archivedByUserId = "archivedByUserId",
  startTime = "startTime",
  returnTime = "returnTime",
  reservationCreatedAt = "reservationCreatedAt",
  customerBrokerId = "customerBrokerId",
}


export type SearchableArchiveReservationConnection = {
  __typename: "SearchableArchiveReservationConnection",
  items:  Array<ArchiveReservation | null >,
  nextToken?: string | null,
  total?: number | null,
};

export type ModelReservationFilterInput = {
  id?: ModelIDInput | null,
  shortId?: ModelStringInput | null,
  language?: ModelLanguageInput | null,
  group?: ModelStringInput | null,
  organizationId?: ModelIDInput | null,
  orgBusinessId?: ModelIDInput | null,
  orgExternalBusinessId?: ModelIDInput | null,
  companyId?: ModelIDInput | null,
  returnCompanyId?: ModelIDInput | null,
  startLocation?: ModelStringInput | null,
  returnLocation?: ModelStringInput | null,
  startLocationID?: ModelIDInput | null,
  returnLocationID?: ModelIDInput | null,
  reviewId?: ModelIDInput | null,
  customerBrokerId?: ModelIDInput | null,
  customerCompanyId?: ModelIDInput | null,
  customerPersonalId?: ModelIDInput | null,
  reservationVehicleId?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  startTime?: ModelStringInput | null,
  returnTime?: ModelStringInput | null,
  endTime?: ModelStringInput | null,
  name?: ModelStringInput | null,
  address?: ModelStringInput | null,
  postalCode?: ModelStringInput | null,
  city?: ModelStringInput | null,
  phone?: ModelStringInput | null,
  email?: ModelStringInput | null,
  billingRef?: ModelStringInput | null,
  customerNotes?: ModelStringInput | null,
  ssn?: ModelStringInput | null,
  rentalNotes?: ModelStringInput | null,
  internalNotes?: ModelStringInput | null,
  externalNotes?: ModelStringInput | null,
  outOfCommission?: ModelBooleanInput | null,
  vehiclePrice?: ModelIntInput | null,
  driverSSN?: ModelStringInput | null,
  differentDriver?: ModelBooleanInput | null,
  driverName?: ModelStringInput | null,
  driverAddress?: ModelStringInput | null,
  driverPostalCode?: ModelStringInput | null,
  driverCity?: ModelStringInput | null,
  driverPhone?: ModelStringInput | null,
  driverEmail?: ModelStringInput | null,
  additionalDriver?: ModelBooleanInput | null,
  additionalDriverName?: ModelStringInput | null,
  additionalDriverPhone?: ModelStringInput | null,
  additionalDriverSSN?: ModelStringInput | null,
  billPayment?: ModelBooleanInput | null,
  isCompany?: ModelBooleanInput | null,
  carStatus?: ModelRentalStatusInput | null,
  rentalStatus?: ModelRentalStatusInput | null,
  innovoiceStatus?: ModelReservationInnovoiceStatusInput | null,
  companyName?: ModelStringInput | null,
  companyBusinessId?: ModelStringInput | null,
  companyCity?: ModelStringInput | null,
  companyPostalCode?: ModelStringInput | null,
  companyBillingRef?: ModelStringInput | null,
  companyBillingAddress?: ModelStringInput | null,
  companyEBillingOperator?: ModelStringInput | null,
  companyEBillingAddress?: ModelStringInput | null,
  paymentToken?: ModelStringInput | null,
  npsStatus?: ModelNpsStatusInput | null,
  paymentStatus?: ModelPaymentStatusInput | null,
  signStatus?: ModelSignStatusInput | null,
  signDocumentId?: ModelStringInput | null,
  signerName?: ModelStringInput | null,
  reviewStatus?: ModelReviewStatusInput | null,
  authStatus?: ModelAuthStatusInput | null,
  authTime?: ModelStringInput | null,
  completeBefore?: ModelStringInput | null,
  mustSign?: ModelBooleanInput | null,
  customerCarRegistrationPlate?: ModelStringInput | null,
  enableRegistrationPlate?: ModelBooleanInput | null,
  disablePricesInPrintouts?: ModelBooleanInput | null,
  invoiceIds?: ModelIDInput | null,
  consolidatedInvoiceIds?: ModelIDInput | null,
  channel?: ModelReservationChannelInput | null,
  originURL?: ModelStringInput | null,
  type?: ModelReservationTypeInput | null,
  status?: ModelStringInput | null,
  pricingCategoryId?: ModelIDInput | null,
  customImportDate?: ModelStringInput | null,
  and?: Array< ModelReservationFilterInput | null > | null,
  or?: Array< ModelReservationFilterInput | null > | null,
  not?: ModelReservationFilterInput | null,
};

export type ModelIDKeyConditionInput = {
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
};

export type SearchableReservationFilterInput = {
  id?: SearchableIDFilterInput | null,
  shortId?: SearchableStringFilterInput | null,
  group?: SearchableStringFilterInput | null,
  organizationId?: SearchableIDFilterInput | null,
  orgBusinessId?: SearchableIDFilterInput | null,
  orgExternalBusinessId?: SearchableIDFilterInput | null,
  companyId?: SearchableIDFilterInput | null,
  returnCompanyId?: SearchableIDFilterInput | null,
  startLocation?: SearchableStringFilterInput | null,
  returnLocation?: SearchableStringFilterInput | null,
  startLocationID?: SearchableIDFilterInput | null,
  returnLocationID?: SearchableIDFilterInput | null,
  reviewId?: SearchableIDFilterInput | null,
  customerBrokerId?: SearchableIDFilterInput | null,
  customerCompanyId?: SearchableIDFilterInput | null,
  customerPersonalId?: SearchableIDFilterInput | null,
  reservationVehicleId?: SearchableIDFilterInput | null,
  createdAt?: SearchableStringFilterInput | null,
  updatedAt?: SearchableStringFilterInput | null,
  startTime?: SearchableStringFilterInput | null,
  returnTime?: SearchableStringFilterInput | null,
  endTime?: SearchableStringFilterInput | null,
  name?: SearchableStringFilterInput | null,
  address?: SearchableStringFilterInput | null,
  postalCode?: SearchableStringFilterInput | null,
  city?: SearchableStringFilterInput | null,
  phone?: SearchableStringFilterInput | null,
  email?: SearchableStringFilterInput | null,
  billingRef?: SearchableStringFilterInput | null,
  customerNotes?: SearchableStringFilterInput | null,
  ssn?: SearchableStringFilterInput | null,
  rentalNotes?: SearchableStringFilterInput | null,
  internalNotes?: SearchableStringFilterInput | null,
  externalNotes?: SearchableStringFilterInput | null,
  outOfCommission?: SearchableBooleanFilterInput | null,
  vehiclePrice?: SearchableIntFilterInput | null,
  driverSSN?: SearchableStringFilterInput | null,
  differentDriver?: SearchableBooleanFilterInput | null,
  driverName?: SearchableStringFilterInput | null,
  driverAddress?: SearchableStringFilterInput | null,
  driverPostalCode?: SearchableStringFilterInput | null,
  driverCity?: SearchableStringFilterInput | null,
  driverPhone?: SearchableStringFilterInput | null,
  driverEmail?: SearchableStringFilterInput | null,
  additionalDriver?: SearchableBooleanFilterInput | null,
  additionalDriverName?: SearchableStringFilterInput | null,
  additionalDriverPhone?: SearchableStringFilterInput | null,
  additionalDriverSSN?: SearchableStringFilterInput | null,
  billPayment?: SearchableBooleanFilterInput | null,
  isCompany?: SearchableBooleanFilterInput | null,
  companyName?: SearchableStringFilterInput | null,
  companyBusinessId?: SearchableStringFilterInput | null,
  companyCity?: SearchableStringFilterInput | null,
  companyPostalCode?: SearchableStringFilterInput | null,
  companyBillingRef?: SearchableStringFilterInput | null,
  companyBillingAddress?: SearchableStringFilterInput | null,
  companyEBillingOperator?: SearchableStringFilterInput | null,
  companyEBillingAddress?: SearchableStringFilterInput | null,
  paymentToken?: SearchableStringFilterInput | null,
  signDocumentId?: SearchableStringFilterInput | null,
  signerName?: SearchableStringFilterInput | null,
  authTime?: SearchableStringFilterInput | null,
  completeBefore?: SearchableStringFilterInput | null,
  mustSign?: SearchableBooleanFilterInput | null,
  customerCarRegistrationPlate?: SearchableStringFilterInput | null,
  enableRegistrationPlate?: SearchableBooleanFilterInput | null,
  disablePricesInPrintouts?: SearchableBooleanFilterInput | null,
  invoiceIds?: SearchableIDFilterInput | null,
  consolidatedInvoiceIds?: SearchableIDFilterInput | null,
  originURL?: SearchableStringFilterInput | null,
  status?: SearchableStringFilterInput | null,
  pricingCategoryId?: SearchableIDFilterInput | null,
  customImportDate?: SearchableStringFilterInput | null,
  and?: Array< SearchableReservationFilterInput | null > | null,
  or?: Array< SearchableReservationFilterInput | null > | null,
  not?: SearchableReservationFilterInput | null,
};

export type SearchableReservationSortInput = {
  field?: SearchableReservationSortableFields | null,
  direction?: SearchableSortDirection | null,
};

export enum SearchableReservationSortableFields {
  id = "id",
  shortId = "shortId",
  group = "group",
  organizationId = "organizationId",
  orgBusinessId = "orgBusinessId",
  orgExternalBusinessId = "orgExternalBusinessId",
  companyId = "companyId",
  returnCompanyId = "returnCompanyId",
  startLocation = "startLocation",
  returnLocation = "returnLocation",
  startLocationID = "startLocationID",
  returnLocationID = "returnLocationID",
  reviewId = "reviewId",
  customerBrokerId = "customerBrokerId",
  customerCompanyId = "customerCompanyId",
  customerPersonalId = "customerPersonalId",
  reservationVehicleId = "reservationVehicleId",
  createdAt = "createdAt",
  updatedAt = "updatedAt",
  startTime = "startTime",
  returnTime = "returnTime",
  endTime = "endTime",
  name = "name",
  address = "address",
  postalCode = "postalCode",
  city = "city",
  phone = "phone",
  email = "email",
  billingRef = "billingRef",
  customerNotes = "customerNotes",
  ssn = "ssn",
  rentalNotes = "rentalNotes",
  internalNotes = "internalNotes",
  externalNotes = "externalNotes",
  outOfCommission = "outOfCommission",
  vehiclePrice = "vehiclePrice",
  driverSSN = "driverSSN",
  differentDriver = "differentDriver",
  driverName = "driverName",
  driverAddress = "driverAddress",
  driverPostalCode = "driverPostalCode",
  driverCity = "driverCity",
  driverPhone = "driverPhone",
  driverEmail = "driverEmail",
  additionalDriver = "additionalDriver",
  additionalDriverName = "additionalDriverName",
  additionalDriverPhone = "additionalDriverPhone",
  additionalDriverSSN = "additionalDriverSSN",
  billPayment = "billPayment",
  isCompany = "isCompany",
  companyName = "companyName",
  companyBusinessId = "companyBusinessId",
  companyCity = "companyCity",
  companyPostalCode = "companyPostalCode",
  companyBillingRef = "companyBillingRef",
  companyBillingAddress = "companyBillingAddress",
  companyEBillingOperator = "companyEBillingOperator",
  companyEBillingAddress = "companyEBillingAddress",
  paymentToken = "paymentToken",
  signDocumentId = "signDocumentId",
  signerName = "signerName",
  authTime = "authTime",
  completeBefore = "completeBefore",
  mustSign = "mustSign",
  customerCarRegistrationPlate = "customerCarRegistrationPlate",
  enableRegistrationPlate = "enableRegistrationPlate",
  disablePricesInPrintouts = "disablePricesInPrintouts",
  invoiceIds = "invoiceIds",
  consolidatedInvoiceIds = "consolidatedInvoiceIds",
  originURL = "originURL",
  status = "status",
  pricingCategoryId = "pricingCategoryId",
  customImportDate = "customImportDate",
}


export type SearchableReservationConnection = {
  __typename: "SearchableReservationConnection",
  items:  Array<Reservation | null >,
  nextToken?: string | null,
  total?: number | null,
};

export type ModelReservationCountFilterInput = {
  tenant?: ModelStringInput | null,
  date?: ModelStringInput | null,
  count?: ModelIntInput | null,
  and?: Array< ModelReservationCountFilterInput | null > | null,
  or?: Array< ModelReservationCountFilterInput | null > | null,
  not?: ModelReservationCountFilterInput | null,
};

export type ModelReservationCountConnection = {
  __typename: "ModelReservationCountConnection",
  items:  Array<ReservationCount | null >,
  nextToken?: string | null,
};

export type ModelInvoiceFilterInput = {
  id?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  innovoiceId?: ModelStringInput | null,
  reservationId?: ModelIDInput | null,
  reservationIds?: ModelIDInput | null,
  companyId?: ModelIDInput | null,
  innovoiceCompanyId?: ModelStringInput | null,
  innovoiceuRentCarRentalId?: ModelStringInput | null,
  innovoiceOrderId?: ModelIDInput | null,
  group?: ModelStringInput | null,
  organizationId?: ModelIDInput | null,
  orgBusinessId?: ModelIDInput | null,
  type?: ModelInvoicePaymentTypeInput | null,
  creditNoteInvoiceId?: ModelIDInput | null,
  payment_terms?: ModelIntInput | null,
  vat_included?: ModelIntInput | null,
  delivery_by?: ModelInvoiceDeliveryByInput | null,
  invoice_type?: ModelInvoiceTypeInput | null,
  delivery_date?: ModelStringInput | null,
  seller_reference?: ModelStringInput | null,
  buyer_reference?: ModelStringInput | null,
  and?: Array< ModelInvoiceFilterInput | null > | null,
  or?: Array< ModelInvoiceFilterInput | null > | null,
  not?: ModelInvoiceFilterInput | null,
};

export type ModelSignEventFilterInput = {
  id?: ModelIDInput | null,
  reservationId?: ModelIDInput | null,
  company?: ModelIDInput | null,
  organizationId?: ModelIDInput | null,
  orgBusinessId?: ModelIDInput | null,
  group?: ModelStringInput | null,
  status?: ModelSignStatusInput | null,
  documentId?: ModelStringInput | null,
  source?: ModelStringInput | null,
  and?: Array< ModelSignEventFilterInput | null > | null,
  or?: Array< ModelSignEventFilterInput | null > | null,
  not?: ModelSignEventFilterInput | null,
};

export type ModelSignEventConnection = {
  __typename: "ModelSignEventConnection",
  items:  Array<SignEvent | null >,
  nextToken?: string | null,
};

export type ModelPaymentEventFilterInput = {
  id?: ModelIDInput | null,
  channel?: ModelStringInput | null,
  paymentMethod?: ModelStringInput | null,
  paymentTotal?: ModelIntInput | null,
  isDeleted?: ModelBooleanInput | null,
  deletedBy?: ModelStringInput | null,
  deletedAt?: ModelStringInput | null,
  createdByUserID?: ModelIDInput | null,
  createdByUserName?: ModelStringInput | null,
  reservationId?: ModelIDInput | null,
  organizationId?: ModelIDInput | null,
  orgBusinessId?: ModelIDInput | null,
  group?: ModelStringInput | null,
  msg?: ModelStringInput | null,
  state?: ModelPaymentEventStateInput | null,
  returnCode?: ModelIntInput | null,
  incidentId?: ModelStringInput | null,
  parameters?: ModelStringInput | null,
  apiPath?: ModelStringInput | null,
  paymentStatus?: ModelStringInput | null,
  orderNumber?: ModelStringInput | null,
  and?: Array< ModelPaymentEventFilterInput | null > | null,
  or?: Array< ModelPaymentEventFilterInput | null > | null,
  not?: ModelPaymentEventFilterInput | null,
};

export type ModelAdditionalServiceFilterInput = {
  id?: ModelIDInput | null,
  organizationId?: ModelIDInput | null,
  orgBusinessId?: ModelIDInput | null,
  group?: ModelStringInput | null,
  description?: ModelStringInput | null,
  description2?: ModelStringInput | null,
  category?: ModelStringInput | null,
  type?: ModelServiceTypeInput | null,
  enableTextInput?: ModelBooleanInput | null,
  enableQuantity?: ModelBooleanInput | null,
  enableNotification?: ModelBooleanInput | null,
  maxQuantity?: ModelIntInput | null,
  inputLabel?: ModelStringInput | null,
  _removed?: ModelStringInput | null,
  isGroupParent?: ModelBooleanInput | null,
  groupFields?: ModelStringInput | null,
  groupParentId?: ModelStringInput | null,
  groupOrderNumber?: ModelIntInput | null,
  image?: ModelStringInput | null,
  and?: Array< ModelAdditionalServiceFilterInput | null > | null,
  or?: Array< ModelAdditionalServiceFilterInput | null > | null,
  not?: ModelAdditionalServiceFilterInput | null,
};

export type ModelAdditionalServiceConnection = {
  __typename: "ModelAdditionalServiceConnection",
  items:  Array<AdditionalService | null >,
  nextToken?: string | null,
};

export type ModelPricingFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  group?: ModelStringInput | null,
  organizationId?: ModelIDInput | null,
  orgBusinessId?: ModelIDInput | null,
  orgExternalBusinessId?: ModelIDInput | null,
  externalBusinessCategoryAssociation?: ModelIDInput | null,
  businessCategoryAssociation?: ModelIDInput | null,
  useNextDayPrice?: ModelBooleanInput | null,
  useLastDayPrice?: ModelBooleanInput | null,
  tax?: ModelFloatInput | null,
  _removed?: ModelStringInput | null,
  deductible?: ModelIntInput | null,
  reducedDeductible?: ModelIntInput | null,
  reducedDeductiblePrice?: ModelIntInput | null,
  and?: Array< ModelPricingFilterInput | null > | null,
  or?: Array< ModelPricingFilterInput | null > | null,
  not?: ModelPricingFilterInput | null,
};

export type ModelPricingExceptionFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  group?: ModelStringInput | null,
  organizationId?: ModelIDInput | null,
  orgBusinessId?: ModelIDInput | null,
  type?: ModelPricingExceptionTypeInput | null,
  amountType?: ModelPricingExceptionAmountTypeInput | null,
  weeklyAmounts?: ModelFloatInput | null,
  monthlyAmountPerDay?: ModelIntInput | null,
  daysBeforeMonthChange?: ModelIntInput | null,
  daysAfterMonthChange?: ModelIntInput | null,
  startDate?: ModelStringInput | null,
  endDate?: ModelStringInput | null,
  pricingIds?: ModelIDInput | null,
  and?: Array< ModelPricingExceptionFilterInput | null > | null,
  or?: Array< ModelPricingExceptionFilterInput | null > | null,
  not?: ModelPricingExceptionFilterInput | null,
};

export type ModelPricingExceptionConnection = {
  __typename: "ModelPricingExceptionConnection",
  items:  Array<PricingException | null >,
  nextToken?: string | null,
};

export type ModelVoucherFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  group?: ModelStringInput | null,
  organizationId?: ModelIDInput | null,
  code?: ModelStringInput | null,
  amount?: ModelIntInput | null,
  startDate?: ModelStringInput | null,
  endDate?: ModelStringInput | null,
  _removed?: ModelStringInput | null,
  and?: Array< ModelVoucherFilterInput | null > | null,
  or?: Array< ModelVoucherFilterInput | null > | null,
  not?: ModelVoucherFilterInput | null,
};

export type ModelVoucherConnection = {
  __typename: "ModelVoucherConnection",
  items:  Array<Voucher | null >,
  nextToken?: string | null,
};

export type ModelReviewFilterInput = {
  id?: ModelIDInput | null,
  group?: ModelStringInput | null,
  organizationId?: ModelIDInput | null,
  orgBusinessId?: ModelIDInput | null,
  orgExternalBusinessId?: ModelIDInput | null,
  rating?: ModelIntInput | null,
  comment?: ModelStringInput | null,
  explanation?: ModelStringInput | null,
  reservationId?: ModelStringInput | null,
  companyId?: ModelStringInput | null,
  startTime?: ModelStringInput | null,
  returnTime?: ModelStringInput | null,
  and?: Array< ModelReviewFilterInput | null > | null,
  or?: Array< ModelReviewFilterInput | null > | null,
  not?: ModelReviewFilterInput | null,
};

export type ModelReviewConnection = {
  __typename: "ModelReviewConnection",
  items:  Array<Review | null >,
  nextToken?: string | null,
};

export type ModelNotificationFilterInput = {
  id?: ModelIDInput | null,
  type?: ModelNotificationTypeInput | null,
  status?: ModelNotificationStatusInput | null,
  name?: ModelStringInput | null,
  message?: ModelStringInput | null,
  organizationId?: ModelIDInput | null,
  orgBusinessId?: ModelIDInput | null,
  group?: ModelStringInput | null,
  reservationId?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelNotificationFilterInput | null > | null,
  or?: Array< ModelNotificationFilterInput | null > | null,
  not?: ModelNotificationFilterInput | null,
};

export type ModelNotificationConnection = {
  __typename: "ModelNotificationConnection",
  items:  Array<Notification | null >,
  nextToken?: string | null,
};

export type ModelNotificationByGroupByStatusByCreatedAtCompositeKeyConditionInput = {
  eq?: ModelNotificationByGroupByStatusByCreatedAtCompositeKeyInput | null,
  le?: ModelNotificationByGroupByStatusByCreatedAtCompositeKeyInput | null,
  lt?: ModelNotificationByGroupByStatusByCreatedAtCompositeKeyInput | null,
  ge?: ModelNotificationByGroupByStatusByCreatedAtCompositeKeyInput | null,
  gt?: ModelNotificationByGroupByStatusByCreatedAtCompositeKeyInput | null,
  between?: Array< ModelNotificationByGroupByStatusByCreatedAtCompositeKeyInput | null > | null,
  beginsWith?: ModelNotificationByGroupByStatusByCreatedAtCompositeKeyInput | null,
};

export type ModelNotificationByGroupByStatusByCreatedAtCompositeKeyInput = {
  status?: NotificationStatus | null,
  createdAt?: string | null,
};

export type ModelNotificationByBusinessByStatusByCreatedAtCompositeKeyConditionInput = {
  eq?: ModelNotificationByBusinessByStatusByCreatedAtCompositeKeyInput | null,
  le?: ModelNotificationByBusinessByStatusByCreatedAtCompositeKeyInput | null,
  lt?: ModelNotificationByBusinessByStatusByCreatedAtCompositeKeyInput | null,
  ge?: ModelNotificationByBusinessByStatusByCreatedAtCompositeKeyInput | null,
  gt?: ModelNotificationByBusinessByStatusByCreatedAtCompositeKeyInput | null,
  between?: Array< ModelNotificationByBusinessByStatusByCreatedAtCompositeKeyInput | null > | null,
  beginsWith?: ModelNotificationByBusinessByStatusByCreatedAtCompositeKeyInput | null,
};

export type ModelNotificationByBusinessByStatusByCreatedAtCompositeKeyInput = {
  status?: NotificationStatus | null,
  createdAt?: string | null,
};

export type ModelCustomerFilterInput = {
  id?: ModelIDInput | null,
  organizationId?: ModelIDInput | null,
  orgBusinessId?: ModelIDInput | null,
  orgExternalBusinessId?: ModelIDInput | null,
  group?: ModelStringInput | null,
  companyName?: ModelStringInput | null,
  brokerName?: ModelStringInput | null,
  address?: ModelStringInput | null,
  postalCode?: ModelStringInput | null,
  city?: ModelStringInput | null,
  phone?: ModelStringInput | null,
  email?: ModelStringInput | null,
  companyBillingAddress?: ModelStringInput | null,
  companyBusinessId?: ModelStringInput | null,
  companyCity?: ModelStringInput | null,
  companyPostalCode?: ModelStringInput | null,
  companyBillingRef?: ModelStringInput | null,
  companyEBillingOperator?: ModelStringInput | null,
  companyEBillingAddress?: ModelStringInput | null,
  clientType?: ModelStringInput | null,
  isDeleted?: ModelBooleanInput | null,
  and?: Array< ModelCustomerFilterInput | null > | null,
  or?: Array< ModelCustomerFilterInput | null > | null,
  not?: ModelCustomerFilterInput | null,
};

export type ModelCustomerConnection = {
  __typename: "ModelCustomerConnection",
  items:  Array<Customer | null >,
  nextToken?: string | null,
};

export type SearchableCustomerFilterInput = {
  id?: SearchableIDFilterInput | null,
  organizationId?: SearchableIDFilterInput | null,
  orgBusinessId?: SearchableIDFilterInput | null,
  orgExternalBusinessId?: SearchableIDFilterInput | null,
  group?: SearchableStringFilterInput | null,
  companyName?: SearchableStringFilterInput | null,
  brokerName?: SearchableStringFilterInput | null,
  address?: SearchableStringFilterInput | null,
  postalCode?: SearchableStringFilterInput | null,
  city?: SearchableStringFilterInput | null,
  phone?: SearchableStringFilterInput | null,
  email?: SearchableStringFilterInput | null,
  companyBillingAddress?: SearchableStringFilterInput | null,
  companyBusinessId?: SearchableStringFilterInput | null,
  companyCity?: SearchableStringFilterInput | null,
  companyPostalCode?: SearchableStringFilterInput | null,
  companyBillingRef?: SearchableStringFilterInput | null,
  companyEBillingOperator?: SearchableStringFilterInput | null,
  companyEBillingAddress?: SearchableStringFilterInput | null,
  clientType?: SearchableStringFilterInput | null,
  isDeleted?: SearchableBooleanFilterInput | null,
  and?: Array< SearchableCustomerFilterInput | null > | null,
  or?: Array< SearchableCustomerFilterInput | null > | null,
  not?: SearchableCustomerFilterInput | null,
};

export type SearchableCustomerSortInput = {
  field?: SearchableCustomerSortableFields | null,
  direction?: SearchableSortDirection | null,
};

export enum SearchableCustomerSortableFields {
  id = "id",
  organizationId = "organizationId",
  orgBusinessId = "orgBusinessId",
  orgExternalBusinessId = "orgExternalBusinessId",
  group = "group",
  companyName = "companyName",
  brokerName = "brokerName",
  address = "address",
  postalCode = "postalCode",
  city = "city",
  phone = "phone",
  email = "email",
  companyBillingAddress = "companyBillingAddress",
  companyBusinessId = "companyBusinessId",
  companyCity = "companyCity",
  companyPostalCode = "companyPostalCode",
  companyBillingRef = "companyBillingRef",
  companyEBillingOperator = "companyEBillingOperator",
  companyEBillingAddress = "companyEBillingAddress",
  clientType = "clientType",
  isDeleted = "isDeleted",
}


export type SearchableCustomerConnection = {
  __typename: "SearchableCustomerConnection",
  items:  Array<Customer | null >,
  nextToken?: string | null,
  total?: number | null,
};

export type UpdateInnovoiceStatusMutationVariables = {
  reservationId: string,
  innovoiceStatus: string,
  payload?: UpdateStatusInput | null,
};

export type UpdateInnovoiceStatusMutation = {
  updateInnovoiceStatus?:  {
    __typename: "ExecutionResult",
    success: boolean,
    message?: string | null,
  } | null,
};

export type SetReservationVehiclesMutationVariables = {
  reservationId: string,
  reservationVehicles: Array< ReservationVehicleAdminInput | null >,
  wildCardVehicles: Array< WildCardVehicleInput | null >,
};

export type SetReservationVehiclesMutation = {
  setReservationVehicles?:  {
    __typename: "ReservationResponse",
    success: boolean,
    message?: string | null,
    reservation?:  {
      __typename: "Reservation",
      id: string,
      shortId?: string | null,
      language?: Language | null,
      group?: string | null,
      organizationId?: string | null,
      orgBusinessId?: string | null,
      orgExternalBusinessId?: string | null,
      companyId?: string | null,
      returnCompanyId?: string | null,
      startLocation?: string | null,
      returnLocation?: string | null,
      startLocationID?: string | null,
      returnLocationID?: string | null,
      reviewId?: string | null,
      customerBrokerId?: string | null,
      customerCompanyId?: string | null,
      customerPersonalId?: string | null,
      reservationVehicleId?: string | null,
      reservationVehicles?:  Array< {
        __typename: "ReservationVehicle",
        id?: string | null,
        price?: number | null,
        tax?: number | null,
        name?: string | null,
        registrationPlate?: string | null,
        deductible?: number | null,
        includedDistance?: string | null,
        powerType?: string | null,
        mileage?: string | null,
        startMileage?: string | null,
        endMileage?: string | null,
        extraInfo?: string | null,
        damages?: string | null,
        hideExport?: boolean | null,
        createdAt?: string | null,
        addedAt?: string | null,
        categoryName?: string | null,
      } | null > | null,
      wildCardVehicles?:  Array< {
        __typename: "WildCardVehicle",
        name?: string | null,
        price?: number | null,
        tax?: number | null,
        categoryId?: string | null,
        quantity?: number | null,
        deductible?: number | null,
      } | null > | null,
      createdAt: string,
      updatedAt: string,
      startTime?: string | null,
      returnTime?: string | null,
      endTime?: string | null,
      name?: string | null,
      address?: string | null,
      postalCode?: string | null,
      city?: string | null,
      phone?: string | null,
      email?: string | null,
      billingRef?: string | null,
      customerNotes?: string | null,
      ssn?: string | null,
      rentalNotes?: string | null,
      internalNotes?: string | null,
      externalNotes?: string | null,
      outOfCommission?: boolean | null,
      vehiclePrice?: number | null,
      additionalServices?:  Array< {
        __typename: "AdditionalServiceReservation",
        key: string,
        price: number,
        details?: string | null,
        quantity?: number | null,
        type?: ServiceType | null,
        deductibleValue?: number | null,
        groupFields?: Array< string | null > | null,
        tax?: number | null,
        enableNotification?: boolean | null,
        description?: string | null,
        category?: string | null,
      } > | null,
      driverSSN?: string | null,
      differentDriver?: boolean | null,
      driverName?: string | null,
      driverAddress?: string | null,
      driverPostalCode?: string | null,
      driverCity?: string | null,
      driverPhone?: string | null,
      driverEmail?: string | null,
      additionalDriver?: boolean | null,
      additionalDriverName?: string | null,
      additionalDriverPhone?: string | null,
      additionalDriverSSN?: string | null,
      billPayment?: boolean | null,
      isCompany?: boolean | null,
      carStatus?: RentalStatus | null,
      rentalStatus?: RentalStatus | null,
      innovoiceStatus?: ReservationInnovoiceStatus | null,
      companyName?: string | null,
      companyBusinessId?: string | null,
      companyCity?: string | null,
      companyPostalCode?: string | null,
      companyBillingRef?: string | null,
      companyBillingAddress?: string | null,
      companyEBillingOperator?: string | null,
      companyEBillingAddress?: string | null,
      paymentToken?: string | null,
      npsStatus?: NpsStatus | null,
      paymentStatus?: PaymentStatus | null,
      paymentData?:  {
        __typename: "PaymentData",
        paymentTotal?: number | null,
        paymentTodo?: number | null,
        depositPending?: boolean | null,
        deposit?: number | null,
      } | null,
      signStatus?: SignStatus | null,
      signDocumentId?: string | null,
      signerName?: string | null,
      reviewStatus?: ReviewStatus | null,
      authStatus?: AuthStatus | null,
      authTime?: string | null,
      completeBefore?: string | null,
      mustSign?: boolean | null,
      livionData?:  Array< {
        __typename: "LivionData",
        id?: string | null,
        pincode?: string | null,
        keyId?: string | null,
        name?: string | null,
        registrationPlate?: string | null,
      } | null > | null,
      tilakoneData?:  {
        __typename: "TilakoneData",
        access_id?: string | null,
        begin_timestamp?: string | null,
        end_timestamp?: string | null,
        pincode?: string | null,
      } | null,
      voucherData?:  {
        __typename: "VoucherData",
        name: string,
        code: string,
        amount: number,
      } | null,
      customerCarRegistrationPlate?: string | null,
      insuranceData?:  {
        __typename: "InsuranceData",
        customerContact?: boolean | null,
        urgent?: boolean | null,
        customerCarModel?: string | null,
        customerCarMaker?: string | null,
        product?: string | null,
        coveragePercentage?: number | null,
        compensatableDays?: string | null,
        billingPermitCode?: string | null,
        coverageDuration?: number | null,
        providerCompany?: string | null,
        providerBusinessId?: string | null,
        claimAdjusterName?: string | null,
        claimAdjusterEmail?: string | null,
        receipt?: boolean | null,
        replacementVehicleClass?: string | null,
        claimId?: string | null,
        vatDeductible?: boolean | null,
        deliveryCosts?: boolean | null,
        returnCosts?: boolean | null,
      } | null,
      externalData?:  {
        __typename: "ExternalData",
        orderId?: string | null,
        orderReference?: string | null,
        serviceAgreementName?: string | null,
        operatorName?: string | null,
        orderInfo?: string | null,
        billingPayer?: string | null,
        billingDescription?: string | null,
      } | null,
      enableRegistrationPlate?: boolean | null,
      disablePricesInPrintouts?: boolean | null,
      hanselData?:  {
        __typename: "HanselData",
        contractNro?: string | null,
        customerInvoiceId?: string | null,
        customerInvoiceDate?: string | null,
        hanselAgent?: string | null,
        hanselClass?: string | null,
        customerId?: string | null,
        subContractorNumber?: string | null,
        subContractorName?: string | null,
        suplierProductId?: string | null,
        suplierProductName?: string | null,
        quantity?: string | null,
        price?: string | null,
        totalPrice?: string | null,
        priceNoVAT?: string | null,
        additionalInfo1?: string | null,
        additionalInfo2?: string | null,
        additionalInfo3?: string | null,
        desc?: string | null,
      } | null,
      invoiceIds?: Array< string > | null,
      consolidatedInvoiceIds?: Array< string > | null,
      channel?: ReservationChannel | null,
      originURL?: string | null,
      type?: ReservationType | null,
      status?: string | null,
      reservationStatusInfo?:  {
        __typename: "ReservationStatusInfo",
        deliveryTimestamp?: string | null,
        deliveryAddress?: string | null,
        deliveryZipcode?: string | null,
        deliveryCity?: string | null,
        deliveryCountry?: string | null,
        deliveryDescription?: string | null,
        returnTimestamp?: string | null,
        returnAddress?: string | null,
        returnZipcode?: string | null,
        returnCity?: string | null,
        returnCountry?: string | null,
        returnDescription?: string | null,
        cancelReason?: string | null,
      } | null,
      initialCategory?:  {
        __typename: "CategoryInfo",
        id?: string | null,
        name?: string | null,
        hanselClass?: string | null,
      } | null,
      initialClassInfo?:  {
        __typename: "InitialClassInfo",
        categoryId?: string | null,
        categoryName?: string | null,
        hanselClass?: string | null,
      } | null,
      orgInvoiceCustomer?:  {
        __typename: "OrganizationInvoiceCustomer",
        name?: string | null,
        businessId?: string | null,
      } | null,
      pricingCategoryId?: string | null,
      customImportDate?: string | null,
      vehicle?:  {
        __typename: "Vehicle",
        id: string,
        group?: string | null,
        organizationId?: string | null,
        orgBusinessId?: string | null,
        companyId: string,
        name: string,
        registrationPlate?: string | null,
        damages?: string | null,
        image?: string | null,
        powerType?: string | null,
        deductible?: number | null,
        includedDistance?: string | null,
        mileage?: string | null,
        orderNumber?: number | null,
        vehicleCategoryId?: string | null,
        useCategoryDefaults?: boolean | null,
        useStartReturnTimes?: boolean | null,
        hideExport?: boolean | null,
        extraInfo?: string | null,
        extraInfoT?: string | null,
        extraToConfirmation?: string | null,
        extraToConfirmationT?: string | null,
        livionKeyId?: string | null,
        phoneNumber?: string | null,
        importData?: string | null,
        disableOnline?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      invoices?:  {
        __typename: "ModelInvoiceConnection",
        nextToken?: string | null,
      } | null,
      paymentEvents?:  {
        __typename: "ModelPaymentEventConnection",
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type UpsertReservationAdminMutationVariables = {
  reservation: UpsertReservationInputAdmin,
  sendStatusToCustomer?: boolean | null,
  disableConfirmationEmail?: boolean | null,
};

export type UpsertReservationAdminMutation = {
  upsertReservationAdmin?:  {
    __typename: "UpsertReservationResultAdmin",
    reservationId?: string | null,
    reservation?:  {
      __typename: "Reservation",
      id: string,
      shortId?: string | null,
      language?: Language | null,
      group?: string | null,
      organizationId?: string | null,
      orgBusinessId?: string | null,
      orgExternalBusinessId?: string | null,
      companyId?: string | null,
      returnCompanyId?: string | null,
      startLocation?: string | null,
      returnLocation?: string | null,
      startLocationID?: string | null,
      returnLocationID?: string | null,
      reviewId?: string | null,
      customerBrokerId?: string | null,
      customerCompanyId?: string | null,
      customerPersonalId?: string | null,
      reservationVehicleId?: string | null,
      reservationVehicles?:  Array< {
        __typename: "ReservationVehicle",
        id?: string | null,
        price?: number | null,
        tax?: number | null,
        name?: string | null,
        registrationPlate?: string | null,
        deductible?: number | null,
        includedDistance?: string | null,
        powerType?: string | null,
        mileage?: string | null,
        startMileage?: string | null,
        endMileage?: string | null,
        extraInfo?: string | null,
        damages?: string | null,
        hideExport?: boolean | null,
        createdAt?: string | null,
        addedAt?: string | null,
        categoryName?: string | null,
      } | null > | null,
      wildCardVehicles?:  Array< {
        __typename: "WildCardVehicle",
        name?: string | null,
        price?: number | null,
        tax?: number | null,
        categoryId?: string | null,
        quantity?: number | null,
        deductible?: number | null,
      } | null > | null,
      createdAt: string,
      updatedAt: string,
      startTime?: string | null,
      returnTime?: string | null,
      endTime?: string | null,
      name?: string | null,
      address?: string | null,
      postalCode?: string | null,
      city?: string | null,
      phone?: string | null,
      email?: string | null,
      billingRef?: string | null,
      customerNotes?: string | null,
      ssn?: string | null,
      rentalNotes?: string | null,
      internalNotes?: string | null,
      externalNotes?: string | null,
      outOfCommission?: boolean | null,
      vehiclePrice?: number | null,
      additionalServices?:  Array< {
        __typename: "AdditionalServiceReservation",
        key: string,
        price: number,
        details?: string | null,
        quantity?: number | null,
        type?: ServiceType | null,
        deductibleValue?: number | null,
        groupFields?: Array< string | null > | null,
        tax?: number | null,
        enableNotification?: boolean | null,
        description?: string | null,
        category?: string | null,
      } > | null,
      driverSSN?: string | null,
      differentDriver?: boolean | null,
      driverName?: string | null,
      driverAddress?: string | null,
      driverPostalCode?: string | null,
      driverCity?: string | null,
      driverPhone?: string | null,
      driverEmail?: string | null,
      additionalDriver?: boolean | null,
      additionalDriverName?: string | null,
      additionalDriverPhone?: string | null,
      additionalDriverSSN?: string | null,
      billPayment?: boolean | null,
      isCompany?: boolean | null,
      carStatus?: RentalStatus | null,
      rentalStatus?: RentalStatus | null,
      innovoiceStatus?: ReservationInnovoiceStatus | null,
      companyName?: string | null,
      companyBusinessId?: string | null,
      companyCity?: string | null,
      companyPostalCode?: string | null,
      companyBillingRef?: string | null,
      companyBillingAddress?: string | null,
      companyEBillingOperator?: string | null,
      companyEBillingAddress?: string | null,
      paymentToken?: string | null,
      npsStatus?: NpsStatus | null,
      paymentStatus?: PaymentStatus | null,
      paymentData?:  {
        __typename: "PaymentData",
        paymentTotal?: number | null,
        paymentTodo?: number | null,
        depositPending?: boolean | null,
        deposit?: number | null,
      } | null,
      signStatus?: SignStatus | null,
      signDocumentId?: string | null,
      signerName?: string | null,
      reviewStatus?: ReviewStatus | null,
      authStatus?: AuthStatus | null,
      authTime?: string | null,
      completeBefore?: string | null,
      mustSign?: boolean | null,
      livionData?:  Array< {
        __typename: "LivionData",
        id?: string | null,
        pincode?: string | null,
        keyId?: string | null,
        name?: string | null,
        registrationPlate?: string | null,
      } | null > | null,
      tilakoneData?:  {
        __typename: "TilakoneData",
        access_id?: string | null,
        begin_timestamp?: string | null,
        end_timestamp?: string | null,
        pincode?: string | null,
      } | null,
      voucherData?:  {
        __typename: "VoucherData",
        name: string,
        code: string,
        amount: number,
      } | null,
      customerCarRegistrationPlate?: string | null,
      insuranceData?:  {
        __typename: "InsuranceData",
        customerContact?: boolean | null,
        urgent?: boolean | null,
        customerCarModel?: string | null,
        customerCarMaker?: string | null,
        product?: string | null,
        coveragePercentage?: number | null,
        compensatableDays?: string | null,
        billingPermitCode?: string | null,
        coverageDuration?: number | null,
        providerCompany?: string | null,
        providerBusinessId?: string | null,
        claimAdjusterName?: string | null,
        claimAdjusterEmail?: string | null,
        receipt?: boolean | null,
        replacementVehicleClass?: string | null,
        claimId?: string | null,
        vatDeductible?: boolean | null,
        deliveryCosts?: boolean | null,
        returnCosts?: boolean | null,
      } | null,
      externalData?:  {
        __typename: "ExternalData",
        orderId?: string | null,
        orderReference?: string | null,
        serviceAgreementName?: string | null,
        operatorName?: string | null,
        orderInfo?: string | null,
        billingPayer?: string | null,
        billingDescription?: string | null,
      } | null,
      enableRegistrationPlate?: boolean | null,
      disablePricesInPrintouts?: boolean | null,
      hanselData?:  {
        __typename: "HanselData",
        contractNro?: string | null,
        customerInvoiceId?: string | null,
        customerInvoiceDate?: string | null,
        hanselAgent?: string | null,
        hanselClass?: string | null,
        customerId?: string | null,
        subContractorNumber?: string | null,
        subContractorName?: string | null,
        suplierProductId?: string | null,
        suplierProductName?: string | null,
        quantity?: string | null,
        price?: string | null,
        totalPrice?: string | null,
        priceNoVAT?: string | null,
        additionalInfo1?: string | null,
        additionalInfo2?: string | null,
        additionalInfo3?: string | null,
        desc?: string | null,
      } | null,
      invoiceIds?: Array< string > | null,
      consolidatedInvoiceIds?: Array< string > | null,
      channel?: ReservationChannel | null,
      originURL?: string | null,
      type?: ReservationType | null,
      status?: string | null,
      reservationStatusInfo?:  {
        __typename: "ReservationStatusInfo",
        deliveryTimestamp?: string | null,
        deliveryAddress?: string | null,
        deliveryZipcode?: string | null,
        deliveryCity?: string | null,
        deliveryCountry?: string | null,
        deliveryDescription?: string | null,
        returnTimestamp?: string | null,
        returnAddress?: string | null,
        returnZipcode?: string | null,
        returnCity?: string | null,
        returnCountry?: string | null,
        returnDescription?: string | null,
        cancelReason?: string | null,
      } | null,
      initialCategory?:  {
        __typename: "CategoryInfo",
        id?: string | null,
        name?: string | null,
        hanselClass?: string | null,
      } | null,
      initialClassInfo?:  {
        __typename: "InitialClassInfo",
        categoryId?: string | null,
        categoryName?: string | null,
        hanselClass?: string | null,
      } | null,
      orgInvoiceCustomer?:  {
        __typename: "OrganizationInvoiceCustomer",
        name?: string | null,
        businessId?: string | null,
      } | null,
      pricingCategoryId?: string | null,
      customImportDate?: string | null,
      vehicle?:  {
        __typename: "Vehicle",
        id: string,
        group?: string | null,
        organizationId?: string | null,
        orgBusinessId?: string | null,
        companyId: string,
        name: string,
        registrationPlate?: string | null,
        damages?: string | null,
        image?: string | null,
        powerType?: string | null,
        deductible?: number | null,
        includedDistance?: string | null,
        mileage?: string | null,
        orderNumber?: number | null,
        vehicleCategoryId?: string | null,
        useCategoryDefaults?: boolean | null,
        useStartReturnTimes?: boolean | null,
        hideExport?: boolean | null,
        extraInfo?: string | null,
        extraInfoT?: string | null,
        extraToConfirmation?: string | null,
        extraToConfirmationT?: string | null,
        livionKeyId?: string | null,
        phoneNumber?: string | null,
        importData?: string | null,
        disableOnline?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      invoices?:  {
        __typename: "ModelInvoiceConnection",
        nextToken?: string | null,
      } | null,
      paymentEvents?:  {
        __typename: "ModelPaymentEventConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    emailConfirmationSuccess?: boolean | null,
    code?: string | null,
    success: boolean,
    destinationEmail?: string | null,
  } | null,
};

export type CognitoDynamoDeleteUserMutationVariables = {
  userId: string,
  adminId?: string | null,
};

export type CognitoDynamoDeleteUserMutation = {
  cognitoDynamoDeleteUser?: boolean | null,
};

export type ResetAndInitSignMutationVariables = {
  reservationId: string,
};

export type ResetAndInitSignMutation = {
  resetAndInitSign?:  {
    __typename: "ExecutionResult",
    success: boolean,
    message?: string | null,
  } | null,
};

export type SendEmailVerificationMutationVariables = {
  reservationId: string,
};

export type SendEmailVerificationMutation = {
  sendEmailVerification?: boolean | null,
};

export type CreateLivionContractMutationVariables = {
  reservationId: string,
};

export type CreateLivionContractMutation = {
  createLivionContract?:  {
    __typename: "CreateLivionResult",
    success: boolean,
    pincode?: string | null,
    registrationPlate?: string | null,
  } | null,
};

export type LinkBusinessToChatIDMutationVariables = {
  businessId: string,
};

export type LinkBusinessToChatIDMutation = {
  linkBusinessToChatID?:  {
    __typename: "ExecutionResult",
    success: boolean,
    message?: string | null,
  } | null,
};

export type FetchTelegramConnectionsMutationVariables = {
  businessId: string,
};

export type FetchTelegramConnectionsMutation = {
  fetchTelegramConnections?:  {
    __typename: "TelegramConnectionResult",
    message?: string | null,
    newConnectionsCount?: number | null,
    connections?:  Array< {
      __typename: "TelegramInformation",
      chatId?: string | null,
      name?: string | null,
    } | null > | null,
  } | null,
};

export type RemoveTelegramConnectionMutationVariables = {
  businessId: string,
  chatId: string,
};

export type RemoveTelegramConnectionMutation = {
  removeTelegramConnection?: boolean | null,
};

export type UnlinkBusinessWithChatIDMutationVariables = {
  businessId: string,
};

export type UnlinkBusinessWithChatIDMutation = {
  unlinkBusinessWithChatID?: boolean | null,
};

export type SendTelegramMessageMutationVariables = {
  chatId: string,
  message: string,
};

export type SendTelegramMessageMutation = {
  sendTelegramMessage?:  {
    __typename: "ExecutionResult",
    success: boolean,
    message?: string | null,
  } | null,
};

export type SendTelegramMessagesMutationVariables = {
  chatIds: Array< string >,
  message: string,
};

export type SendTelegramMessagesMutation = {
  sendTelegramMessages?:  {
    __typename: "ExecutionResult",
    success: boolean,
    message?: string | null,
  } | null,
};

export type CognitoDynamoCreateUserMutationVariables = {
  name: string,
  email: string,
  role: string,
  organizationId?: string | null,
  businessId?: string | null,
  externalBusinessId?: string | null,
};

export type CognitoDynamoCreateUserMutation = {
  cognitoDynamoCreateUser?: boolean | null,
};

export type MigrateReservationsMutationVariables = {
  ids: Array< string >,
};

export type MigrateReservationsMutation = {
  migrateReservations:  Array< {
    __typename: "Reservation",
    id: string,
    shortId?: string | null,
    language?: Language | null,
    group?: string | null,
    organizationId?: string | null,
    orgBusinessId?: string | null,
    orgExternalBusinessId?: string | null,
    companyId?: string | null,
    returnCompanyId?: string | null,
    startLocation?: string | null,
    returnLocation?: string | null,
    startLocationID?: string | null,
    returnLocationID?: string | null,
    reviewId?: string | null,
    customerBrokerId?: string | null,
    customerCompanyId?: string | null,
    customerPersonalId?: string | null,
    reservationVehicleId?: string | null,
    reservationVehicles?:  Array< {
      __typename: "ReservationVehicle",
      id?: string | null,
      price?: number | null,
      tax?: number | null,
      name?: string | null,
      registrationPlate?: string | null,
      deductible?: number | null,
      includedDistance?: string | null,
      powerType?: string | null,
      mileage?: string | null,
      startMileage?: string | null,
      endMileage?: string | null,
      extraInfo?: string | null,
      damages?: string | null,
      hideExport?: boolean | null,
      createdAt?: string | null,
      addedAt?: string | null,
      categoryName?: string | null,
    } | null > | null,
    wildCardVehicles?:  Array< {
      __typename: "WildCardVehicle",
      name?: string | null,
      price?: number | null,
      tax?: number | null,
      categoryId?: string | null,
      quantity?: number | null,
      deductible?: number | null,
    } | null > | null,
    createdAt: string,
    updatedAt: string,
    startTime?: string | null,
    returnTime?: string | null,
    endTime?: string | null,
    name?: string | null,
    address?: string | null,
    postalCode?: string | null,
    city?: string | null,
    phone?: string | null,
    email?: string | null,
    billingRef?: string | null,
    customerNotes?: string | null,
    ssn?: string | null,
    rentalNotes?: string | null,
    internalNotes?: string | null,
    externalNotes?: string | null,
    outOfCommission?: boolean | null,
    vehiclePrice?: number | null,
    additionalServices?:  Array< {
      __typename: "AdditionalServiceReservation",
      key: string,
      price: number,
      details?: string | null,
      quantity?: number | null,
      type?: ServiceType | null,
      deductibleValue?: number | null,
      groupFields?: Array< string | null > | null,
      tax?: number | null,
      enableNotification?: boolean | null,
      description?: string | null,
      category?: string | null,
    } > | null,
    driverSSN?: string | null,
    differentDriver?: boolean | null,
    driverName?: string | null,
    driverAddress?: string | null,
    driverPostalCode?: string | null,
    driverCity?: string | null,
    driverPhone?: string | null,
    driverEmail?: string | null,
    additionalDriver?: boolean | null,
    additionalDriverName?: string | null,
    additionalDriverPhone?: string | null,
    additionalDriverSSN?: string | null,
    billPayment?: boolean | null,
    isCompany?: boolean | null,
    carStatus?: RentalStatus | null,
    rentalStatus?: RentalStatus | null,
    innovoiceStatus?: ReservationInnovoiceStatus | null,
    companyName?: string | null,
    companyBusinessId?: string | null,
    companyCity?: string | null,
    companyPostalCode?: string | null,
    companyBillingRef?: string | null,
    companyBillingAddress?: string | null,
    companyEBillingOperator?: string | null,
    companyEBillingAddress?: string | null,
    paymentToken?: string | null,
    npsStatus?: NpsStatus | null,
    paymentStatus?: PaymentStatus | null,
    paymentData?:  {
      __typename: "PaymentData",
      paymentTotal?: number | null,
      paymentTodo?: number | null,
      depositPending?: boolean | null,
      deposit?: number | null,
    } | null,
    signStatus?: SignStatus | null,
    signDocumentId?: string | null,
    signerName?: string | null,
    reviewStatus?: ReviewStatus | null,
    authStatus?: AuthStatus | null,
    authTime?: string | null,
    completeBefore?: string | null,
    mustSign?: boolean | null,
    livionData?:  Array< {
      __typename: "LivionData",
      id?: string | null,
      pincode?: string | null,
      keyId?: string | null,
      name?: string | null,
      registrationPlate?: string | null,
    } | null > | null,
    tilakoneData?:  {
      __typename: "TilakoneData",
      access_id?: string | null,
      begin_timestamp?: string | null,
      end_timestamp?: string | null,
      pincode?: string | null,
    } | null,
    voucherData?:  {
      __typename: "VoucherData",
      name: string,
      code: string,
      amount: number,
    } | null,
    customerCarRegistrationPlate?: string | null,
    insuranceData?:  {
      __typename: "InsuranceData",
      customerContact?: boolean | null,
      urgent?: boolean | null,
      customerCarModel?: string | null,
      customerCarMaker?: string | null,
      product?: string | null,
      coveragePercentage?: number | null,
      compensatableDays?: string | null,
      billingPermitCode?: string | null,
      coverageDuration?: number | null,
      providerCompany?: string | null,
      providerBusinessId?: string | null,
      claimAdjusterName?: string | null,
      claimAdjusterEmail?: string | null,
      receipt?: boolean | null,
      replacementVehicleClass?: string | null,
      claimId?: string | null,
      vatDeductible?: boolean | null,
      deliveryCosts?: boolean | null,
      returnCosts?: boolean | null,
    } | null,
    externalData?:  {
      __typename: "ExternalData",
      orderId?: string | null,
      orderReference?: string | null,
      serviceAgreementName?: string | null,
      operatorName?: string | null,
      orderInfo?: string | null,
      billingPayer?: string | null,
      billingDescription?: string | null,
      customerVehicle?:  {
        __typename: "CustomerVehicle",
        brand?: string | null,
        model?: string | null,
        registrationPlate?: string | null,
      } | null,
      incidentLocation?:  {
        __typename: "Location",
        description?: string | null,
        address?: string | null,
        city?: string | null,
        zipCode?: string | null,
      } | null,
      repairShop?:  {
        __typename: "Location",
        description?: string | null,
        address?: string | null,
        city?: string | null,
        zipCode?: string | null,
      } | null,
    } | null,
    enableRegistrationPlate?: boolean | null,
    disablePricesInPrintouts?: boolean | null,
    hanselData?:  {
      __typename: "HanselData",
      contractNro?: string | null,
      customerInvoiceId?: string | null,
      customerInvoiceDate?: string | null,
      hanselAgent?: string | null,
      hanselClass?: string | null,
      customerId?: string | null,
      subContractorNumber?: string | null,
      subContractorName?: string | null,
      suplierProductId?: string | null,
      suplierProductName?: string | null,
      quantity?: string | null,
      price?: string | null,
      totalPrice?: string | null,
      priceNoVAT?: string | null,
      additionalInfo1?: string | null,
      additionalInfo2?: string | null,
      additionalInfo3?: string | null,
      desc?: string | null,
    } | null,
    invoiceIds?: Array< string > | null,
    consolidatedInvoiceIds?: Array< string > | null,
    channel?: ReservationChannel | null,
    originURL?: string | null,
    type?: ReservationType | null,
    status?: string | null,
    reservationStatusInfo?:  {
      __typename: "ReservationStatusInfo",
      deliveryTimestamp?: string | null,
      deliveryAddress?: string | null,
      deliveryZipcode?: string | null,
      deliveryCity?: string | null,
      deliveryCountry?: string | null,
      deliveryDescription?: string | null,
      returnTimestamp?: string | null,
      returnAddress?: string | null,
      returnZipcode?: string | null,
      returnCity?: string | null,
      returnCountry?: string | null,
      returnDescription?: string | null,
      cancelReason?: string | null,
    } | null,
    initialCategory?:  {
      __typename: "CategoryInfo",
      id?: string | null,
      name?: string | null,
      hanselClass?: string | null,
    } | null,
    initialClassInfo?:  {
      __typename: "InitialClassInfo",
      categoryId?: string | null,
      categoryName?: string | null,
      hanselClass?: string | null,
    } | null,
    orgInvoiceCustomer?:  {
      __typename: "OrganizationInvoiceCustomer",
      name?: string | null,
      businessId?: string | null,
    } | null,
    pricingCategoryId?: string | null,
    customImportDate?: string | null,
    vehicle?:  {
      __typename: "Vehicle",
      id: string,
      group?: string | null,
      organizationId?: string | null,
      orgBusinessId?: string | null,
      companyId: string,
      name: string,
      nameT?:  {
        __typename: "Translation",
        fi?: string | null,
        en?: string | null,
        sv?: string | null,
      } | null,
      registrationPlate?: string | null,
      damages?: string | null,
      damageRecords?:  Array< {
        __typename: "DamageRecord",
        normalizedX?: number | null,
        normalizedY?: number | null,
        label?: string | null,
        description?: string | null,
        createdAt?: string | null,
      } > | null,
      additionalFields:  Array< {
        __typename: "AdditionalField",
        key: string,
        value?: string | null,
      } >,
      image?: string | null,
      powerType?: string | null,
      deductible?: number | null,
      includedDistance?: string | null,
      mileage?: string | null,
      orderNumber?: number | null,
      vehicleCategoryId?: string | null,
      useCategoryDefaults?: boolean | null,
      useStartReturnTimes?: boolean | null,
      customStartReturns?:  Array< {
        __typename: "CustomStartReturn",
        priority?: number | null,
      } | null > | null,
      startReturnInterval?:  {
        __typename: "StartReturnInterval",
        start?: string | null,
        end?: string | null,
      } | null,
      startReturnTimes?:  Array< {
        __typename: "StartReturnTimes",
        start?: Array< string | null > | null,
        return?: Array< string | null > | null,
      } | null > | null,
      hideExport?: boolean | null,
      extraInfo?: string | null,
      extraInfoT?: string | null,
      extraToConfirmation?: string | null,
      extraToConfirmationT?: string | null,
      livionKeyId?: string | null,
      phoneNumber?: string | null,
      importData?: string | null,
      disableOnline?: boolean | null,
      activeRange?:  {
        __typename: "StartReturnInterval",
        start?: string | null,
        end?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      category?:  {
        __typename: "Category",
        id: string,
        group?: string | null,
        organizationId?: string | null,
        name: string,
        image?: string | null,
        orderNumber?: number | null,
        backgroundColor?: string | null,
        minuteBuffer?: number | null,
        reservationBlockFromNow?: number | null,
        typeId?: string | null,
        _removed?: string | null,
        oldTujausValue?: string | null,
        exampleModelName?: string | null,
        exampleModelImage?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      additionalServices?:  {
        __typename: "ModelAdditionalServicePricingConnection",
        nextToken?: string | null,
      } | null,
      reservations?:  {
        __typename: "ModelReservationConnection",
        nextToken?: string | null,
      } | null,
      pricing?:  {
        __typename: "Pricing",
        id: string,
        name: string,
        group?: string | null,
        organizationId?: string | null,
        orgBusinessId?: string | null,
        orgExternalBusinessId?: string | null,
        externalBusinessCategoryAssociation?: string | null,
        businessCategoryAssociation?: string | null,
        useNextDayPrice?: boolean | null,
        useLastDayPrice?: boolean | null,
        tax?: number | null,
        _removed?: string | null,
        deductible?: number | null,
        reducedDeductible?: number | null,
        reducedDeductiblePrice?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null,
    } | null,
    invoices?:  {
      __typename: "ModelInvoiceConnection",
      items:  Array< {
        __typename: "Invoice",
        id: string,
        createdAt: string,
        innovoiceId?: string | null,
        reservationId?: string | null,
        reservationIds?: Array< string > | null,
        companyId?: string | null,
        innovoiceCompanyId?: string | null,
        innovoiceuRentCarRentalId?: string | null,
        innovoiceOrderId?: string | null,
        group?: string | null,
        organizationId?: string | null,
        orgBusinessId?: string | null,
        type?: InvoicePaymentType | null,
        creditNoteInvoiceId?: string | null,
        payment_terms?: number | null,
        vat_included?: number | null,
        delivery_by: InvoiceDeliveryBy,
        invoice_type: InvoiceType,
        delivery_date?: string | null,
        seller_reference?: string | null,
        buyer_reference?: string | null,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    paymentEvents?:  {
      __typename: "ModelPaymentEventConnection",
      items:  Array< {
        __typename: "PaymentEvent",
        id: string,
        channel?: string | null,
        paymentMethod?: string | null,
        paymentTotal?: number | null,
        isDeleted?: boolean | null,
        deletedBy?: string | null,
        deletedAt?: string | null,
        createdByUserID?: string | null,
        createdByUserName?: string | null,
        reservationId?: string | null,
        organizationId?: string | null,
        orgBusinessId?: string | null,
        group?: string | null,
        msg?: string | null,
        state?: PaymentEventState | null,
        returnCode?: number | null,
        incidentId?: string | null,
        parameters?: string | null,
        apiPath?: string | null,
        paymentStatus?: string | null,
        orderNumber?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
  } >,
};

export type CreateInnovoiceInvoiceMutationVariables = {
  invoice?: CreateInnvoiceInput | null,
  reservationId?: string | null,
  paymentType?: InvoicePaymentType | null,
};

export type CreateInnovoiceInvoiceMutation = {
  createInnovoiceInvoice?:  {
    __typename: "ExecutionResult",
    success: boolean,
    message?: string | null,
  } | null,
};

export type CreateInnovoiceConsolidatedInvoiceMutationVariables = {
  invoice: CreateConsolidatedInnvoiceInput,
};

export type CreateInnovoiceConsolidatedInvoiceMutation = {
  createInnovoiceConsolidatedInvoice?:  {
    __typename: "ExecutionResult",
    success: boolean,
    message?: string | null,
  } | null,
};

export type CreateInnovoiceCreditNoteMutationVariables = {
  reservationId?: string | null,
  invoiceId?: string | null,
};

export type CreateInnovoiceCreditNoteMutation = {
  createInnovoiceCreditNote?:  {
    __typename: "ExecutionResult",
    success: boolean,
    message?: string | null,
  } | null,
};

export type TransferReservationToBusinessMutationVariables = {
  reservationId?: string | null,
  toBusinessId?: string | null,
};

export type TransferReservationToBusinessMutation = {
  transferReservationToBusiness?:  {
    __typename: "ExecutionResult",
    success: boolean,
    message?: string | null,
  } | null,
};

export type ArchiveReservationMutationVariables = {
  reservationId?: string | null,
  archiveMessage?: string | null,
};

export type ArchiveReservationMutation = {
  archiveReservation?:  {
    __typename: "ExecutionResult",
    success: boolean,
    message?: string | null,
  } | null,
};

export type CreateNewsItemMutationVariables = {
  input: CreateNewsItemInput,
  condition?: ModelNewsItemConditionInput | null,
};

export type CreateNewsItemMutation = {
  createNewsItem?:  {
    __typename: "NewsItem",
    id: string,
    header: string,
    link?: string | null,
    content?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateNewsItemMutationVariables = {
  input: UpdateNewsItemInput,
  condition?: ModelNewsItemConditionInput | null,
};

export type UpdateNewsItemMutation = {
  updateNewsItem?:  {
    __typename: "NewsItem",
    id: string,
    header: string,
    link?: string | null,
    content?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteNewsItemMutationVariables = {
  input: DeleteNewsItemInput,
  condition?: ModelNewsItemConditionInput | null,
};

export type DeleteNewsItemMutation = {
  deleteNewsItem?:  {
    __typename: "NewsItem",
    id: string,
    header: string,
    link?: string | null,
    content?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpsertReviewMutationVariables = {
  review: UpsertReviewInput,
};

export type UpsertReviewMutation = {
  upsertReview?:  {
    __typename: "UpsertReviewResult",
    reviewId?: string | null,
    success: boolean,
  } | null,
};

export type CreatePricingsFromCsvMutationVariables = {
  csvData: string,
  orgExternalBusinessId: string,
};

export type CreatePricingsFromCsvMutation = {
  createPricingsFromCsv?:  {
    __typename: "ExecutionResult",
    success: boolean,
    message?: string | null,
  } | null,
};

export type UpsertReservationCustomerMutationVariables = {
  reservation: UpsertReservationInputCustomer,
  paymentMode?: string | null,
  bypassAuthentication?: boolean | null,
};

export type UpsertReservationCustomerMutation = {
  upsertReservationCustomer?:  {
    __typename: "UpsertReservationResult",
    emailConfirmationSuccess?: boolean | null,
    code?: string | null,
    success: boolean,
    destinationEmail?: string | null,
    reservationId?: string | null,
  } | null,
};

export type CancelReservationCustomerMutationVariables = {
  reservationId: string,
};

export type CancelReservationCustomerMutation = {
  cancelReservationCustomer?:  {
    __typename: "ExecutionResult",
    success: boolean,
    message?: string | null,
  } | null,
};

export type SetReservationInfoMutationVariables = {
  reservation: ReservationInfoInput,
  paymentMode?: string | null,
  bypassAuthentication?: boolean | null,
};

export type SetReservationInfoMutation = {
  setReservationInfo?:  {
    __typename: "ReservationInfo",
    id?: string | null,
    shortId?: string | null,
    language?: Language | null,
    startTime?: string | null,
    returnTime?: string | null,
    paymentPending?: boolean | null,
    paymentStatus?: string | null,
    signStatus?: string | null,
    enableSign?: boolean | null,
    disablePricesInPrintouts?: boolean | null,
    mustSign?: boolean | null,
    paymentData?:  {
      __typename: "PaymentDataPublic",
      paymentTodo?: number | null,
      depositPending?: boolean | null,
      deposit?: number | null,
      paymentTotal?: number | null,
    } | null,
    askSsn?: boolean | null,
    ssn?: string | null,
    group?: string | null,
    companyTermsUrl?: string | null,
    companyId?: string | null,
    name?: string | null,
    address?: string | null,
    postalCode?: string | null,
    city?: string | null,
    phone?: string | null,
    billingRef?: string | null,
    email?: string | null,
    externalNotes?: string | null,
    differentDriver?: boolean | null,
    driverName?: string | null,
    driverAddress?: string | null,
    driverPostalCode?: string | null,
    driverCity?: string | null,
    driverPhone?: string | null,
    driverEmail?: string | null,
    driverSSN?: string | null,
    additionalDriver?: boolean | null,
    additionalDriverName?: string | null,
    additionalDriverPhone?: string | null,
    allowCancellation?: boolean | null,
    billPayment?: boolean | null,
    isCompany?: boolean | null,
    companyName?: string | null,
    companyBusinessId?: string | null,
    companyBillingRef?: string | null,
    companyBillingAddress?: string | null,
    companyEBillingOperator?: string | null,
    companyEBillingAddress?: string | null,
    authStatus?: string | null,
    vehicles?:  Array< {
      __typename: "VehiclePublic",
      group?: string | null,
      organizationId?: string | null,
      companyId?: string | null,
      name?: string | null,
      nameT?:  {
        __typename: "Translation",
        fi?: string | null,
        en?: string | null,
        sv?: string | null,
      } | null,
      registrationPlate?: string | null,
      additionalFields?:  Array< {
        __typename: "AdditionalField",
        key: string,
        value?: string | null,
      } > | null,
      image?: string | null,
      powerType?: string | null,
      deductible?: number | null,
      includedDistance?: string | null,
      orderNumber?: number | null,
      price?: number | null,
      tax?: number | null,
      category?:  {
        __typename: "VehicleCategoryPublic",
        name?: string | null,
        typeId?: string | null,
      } | null,
      id?: string | null,
    } | null > | null,
    services?:  Array< {
      __typename: "AdditionalServicesPublic",
      quantity?: number | null,
      description?: string | null,
      descriptionT?:  {
        __typename: "Translation",
        fi?: string | null,
        en?: string | null,
        sv?: string | null,
      } | null,
      price?: number | null,
      tax?: number | null,
      key?: string | null,
    } | null > | null,
    expiryTime?: string | null,
    voucherData?:  {
      __typename: "VoucherDataPublic",
      amount?: number | null,
      code?: string | null,
    } | null,
    enableGTM?: string | null,
    errorMsg?: ReservationInfoError | null,
  } | null,
};

export type SetReservationPaymentStatusMutationVariables = {
  reservationId: string,
};

export type SetReservationPaymentStatusMutation = {
  setReservationPaymentStatus?:  {
    __typename: "UpsertReservationResult",
    emailConfirmationSuccess?: boolean | null,
    code?: string | null,
    success: boolean,
    destinationEmail?: string | null,
    reservationId?: string | null,
  } | null,
};

export type CancelReservationMutationVariables = {
  reservationId: string,
};

export type CancelReservationMutation = {
  cancelReservation?: boolean | null,
};

export type InitVismaSignMutationVariables = {
  reservationId: string,
};

export type InitVismaSignMutation = {
  initVismaSign?:  {
    __typename: "ExecutionResult",
    success: boolean,
    message?: string | null,
  } | null,
};

export type CreateCompanyMutationVariables = {
  input: CreateCompanyInput,
  condition?: ModelCompanyConditionInput | null,
};

export type CreateCompanyMutation = {
  createCompany?:  {
    __typename: "Company",
    id: string,
    organizationId?: string | null,
    orgBusinessId?: string | null,
    group?: string | null,
    name: string,
    displayName?: string | null,
    email?: string | null,
    address?: string | null,
    postalCode?: string | null,
    city?: string | null,
    orderNumber?: number | null,
    orderNumberDealer?: number | null,
    phone?: string | null,
    businessId?: string | null,
    termsUrl?: string | null,
    timezone: string,
    weeklyBusinessHours:  Array< {
      __typename: "ClockRange",
      openAt: string,
      closeAt: string,
    } | null >,
    signConfirmationEmail?: string | null,
    nameInEmail?: string | null,
    senderNameSMS?: string | null,
    confirmationMsg?: string | null,
    confirmationMsgT?:  {
      __typename: "Translation",
      fi?: string | null,
      en?: string | null,
      sv?: string | null,
    } | null,
    feedbackMsg?: string | null,
    feedbackMsgT?:  {
      __typename: "Translation",
      fi?: string | null,
      en?: string | null,
      sv?: string | null,
    } | null,
    statusLinkMsg?: string | null,
    statusLinkMsgT?:  {
      __typename: "Translation",
      fi?: string | null,
      en?: string | null,
      sv?: string | null,
    } | null,
    livionDeviceId?: string | null,
    livionDeviceType?: LivionDevice | null,
    enableReservationDeposit?: boolean | null,
    reservationDeposit?:  {
      __typename: "ReservationDeposit",
      amount?: number | null,
      reminderBefore?: number | null,
      paymentBefore?: number | null,
    } | null,
    reservationLinkExpirationHours?: number | null,
    disableOffHourReturn?: boolean | null,
    disableMultiday?: boolean | null,
    disableSameDay?: boolean | null,
    disableStartDaysFromNow?: number | null,
    enableCart?: boolean | null,
    enableMonthlyView?: boolean | null,
    enableRegistrationPlate?: boolean | null,
    enableVehicleStartReturnTimes?: boolean | null,
    enableMustSign?: boolean | null,
    enablePaymentOnlineOnly?: boolean | null,
    enableGTM?: boolean | null,
    enableNetPromoterScore?: boolean | null,
    enableNPS?: boolean | null,
    reservationCancellation?:  {
      __typename: "ReservationCancellationSettings",
      cancellationEnabled?: boolean | null,
      daysBeforeStart?: number | null,
    } | null,
    creditCheck?:  {
      __typename: "AsiakastietoCreditCheck",
      enabled?: boolean | null,
      keyLookup?: boolean | null,
      userId?: string | null,
      passwordId?: string | null,
    } | null,
    limitReservationOncePerDay?: boolean | null,
    hidden?: boolean | null,
    receiptInfo?:  {
      __typename: "ReceiptInfo",
      enabled?: boolean | null,
      name?: string | null,
      address?: string | null,
      city?: string | null,
      postalCode?: string | null,
    } | null,
    innovoice?:  {
      __typename: "InnovoiceData",
      enabled?: boolean | null,
      companyId?: string | null,
      uRentCarRentalId?: string | null,
    } | null,
    locationType?: string | null,
    offHourAvailability?: boolean | null,
    offHourAvailabilityFee?: number | null,
    deliveryFee?: number | null,
    deliveryBufferHours?: number | null,
    notAllowedCategoryIds?: Array< string | null > | null,
    importIdentifier?: string | null,
    features?:  {
      __typename: "Features",
      enableNPS?: boolean | null,
      enableVismaPay?: boolean | null,
      enableReservationDialogV2?: boolean | null,
      enableTilakone?: boolean | null,
      enableGTM?: GTMVersion | null,
      enableSMSConfirmation?: boolean | null,
      enableMustSignBypass?: boolean | null,
    } | null,
    contractSettings?:  Array< {
      __typename: "FeatureSetting",
      name?: string | null,
      enabled?: boolean | null,
      input?: string | null,
    } | null > | null,
    contractSettingsJSON?: string | null,
    categoryDynamicPricings?:  Array< {
      __typename: "CategoryDynamicPricing",
      categoryId?: string | null,
      pricingId?: string | null,
      percentage?: number | null,
    } | null > | null,
    disableReservationCategoryIds?: Array< string | null > | null,
    locationColor?: string | null,
    createdAt: string,
    updatedAt: string,
    merchantDetails?:  {
      __typename: "MerchantDetails",
      api?: VismaPayApi | null,
      merchant_id?: string | null,
      cp?: string | null,
    } | null,
    businessHoursExceptions?:  {
      __typename: "ModelBusinessHoursExceptionConnection",
      items:  Array< {
        __typename: "BusinessHoursException",
        organizationId?: string | null,
        orgBusinessId?: string | null,
        group?: string | null,
        date: string,
        name?: string | null,
        companyId: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    vehicles?:  {
      __typename: "ModelVehicleConnection",
      items:  Array< {
        __typename: "Vehicle",
        id: string,
        group?: string | null,
        organizationId?: string | null,
        orgBusinessId?: string | null,
        companyId: string,
        name: string,
        registrationPlate?: string | null,
        damages?: string | null,
        image?: string | null,
        powerType?: string | null,
        deductible?: number | null,
        includedDistance?: string | null,
        mileage?: string | null,
        orderNumber?: number | null,
        vehicleCategoryId?: string | null,
        useCategoryDefaults?: boolean | null,
        useStartReturnTimes?: boolean | null,
        hideExport?: boolean | null,
        extraInfo?: string | null,
        extraInfoT?: string | null,
        extraToConfirmation?: string | null,
        extraToConfirmationT?: string | null,
        livionKeyId?: string | null,
        phoneNumber?: string | null,
        importData?: string | null,
        disableOnline?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    categories?:  {
      __typename: "ModelCategoryConnection",
      items:  Array< {
        __typename: "Category",
        id: string,
        group?: string | null,
        organizationId?: string | null,
        name: string,
        image?: string | null,
        orderNumber?: number | null,
        backgroundColor?: string | null,
        minuteBuffer?: number | null,
        reservationBlockFromNow?: number | null,
        typeId?: string | null,
        _removed?: string | null,
        oldTujausValue?: string | null,
        exampleModelName?: string | null,
        exampleModelImage?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    categoriesByOrg?:  {
      __typename: "ModelCategoryConnection",
      items:  Array< {
        __typename: "Category",
        id: string,
        group?: string | null,
        organizationId?: string | null,
        name: string,
        image?: string | null,
        orderNumber?: number | null,
        backgroundColor?: string | null,
        minuteBuffer?: number | null,
        reservationBlockFromNow?: number | null,
        typeId?: string | null,
        _removed?: string | null,
        oldTujausValue?: string | null,
        exampleModelName?: string | null,
        exampleModelImage?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type UpdateCompanyMutationVariables = {
  input: UpdateCompanyInput,
  condition?: ModelCompanyConditionInput | null,
};

export type UpdateCompanyMutation = {
  updateCompany?:  {
    __typename: "Company",
    id: string,
    organizationId?: string | null,
    orgBusinessId?: string | null,
    group?: string | null,
    name: string,
    displayName?: string | null,
    email?: string | null,
    address?: string | null,
    postalCode?: string | null,
    city?: string | null,
    orderNumber?: number | null,
    orderNumberDealer?: number | null,
    phone?: string | null,
    businessId?: string | null,
    termsUrl?: string | null,
    timezone: string,
    weeklyBusinessHours:  Array< {
      __typename: "ClockRange",
      openAt: string,
      closeAt: string,
    } | null >,
    signConfirmationEmail?: string | null,
    nameInEmail?: string | null,
    senderNameSMS?: string | null,
    confirmationMsg?: string | null,
    confirmationMsgT?:  {
      __typename: "Translation",
      fi?: string | null,
      en?: string | null,
      sv?: string | null,
    } | null,
    feedbackMsg?: string | null,
    feedbackMsgT?:  {
      __typename: "Translation",
      fi?: string | null,
      en?: string | null,
      sv?: string | null,
    } | null,
    statusLinkMsg?: string | null,
    statusLinkMsgT?:  {
      __typename: "Translation",
      fi?: string | null,
      en?: string | null,
      sv?: string | null,
    } | null,
    livionDeviceId?: string | null,
    livionDeviceType?: LivionDevice | null,
    enableReservationDeposit?: boolean | null,
    reservationDeposit?:  {
      __typename: "ReservationDeposit",
      amount?: number | null,
      reminderBefore?: number | null,
      paymentBefore?: number | null,
    } | null,
    reservationLinkExpirationHours?: number | null,
    disableOffHourReturn?: boolean | null,
    disableMultiday?: boolean | null,
    disableSameDay?: boolean | null,
    disableStartDaysFromNow?: number | null,
    enableCart?: boolean | null,
    enableMonthlyView?: boolean | null,
    enableRegistrationPlate?: boolean | null,
    enableVehicleStartReturnTimes?: boolean | null,
    enableMustSign?: boolean | null,
    enablePaymentOnlineOnly?: boolean | null,
    enableGTM?: boolean | null,
    enableNetPromoterScore?: boolean | null,
    enableNPS?: boolean | null,
    reservationCancellation?:  {
      __typename: "ReservationCancellationSettings",
      cancellationEnabled?: boolean | null,
      daysBeforeStart?: number | null,
    } | null,
    creditCheck?:  {
      __typename: "AsiakastietoCreditCheck",
      enabled?: boolean | null,
      keyLookup?: boolean | null,
      userId?: string | null,
      passwordId?: string | null,
    } | null,
    limitReservationOncePerDay?: boolean | null,
    hidden?: boolean | null,
    receiptInfo?:  {
      __typename: "ReceiptInfo",
      enabled?: boolean | null,
      name?: string | null,
      address?: string | null,
      city?: string | null,
      postalCode?: string | null,
    } | null,
    innovoice?:  {
      __typename: "InnovoiceData",
      enabled?: boolean | null,
      companyId?: string | null,
      uRentCarRentalId?: string | null,
    } | null,
    locationType?: string | null,
    offHourAvailability?: boolean | null,
    offHourAvailabilityFee?: number | null,
    deliveryFee?: number | null,
    deliveryBufferHours?: number | null,
    notAllowedCategoryIds?: Array< string | null > | null,
    importIdentifier?: string | null,
    features?:  {
      __typename: "Features",
      enableNPS?: boolean | null,
      enableVismaPay?: boolean | null,
      enableReservationDialogV2?: boolean | null,
      enableTilakone?: boolean | null,
      enableGTM?: GTMVersion | null,
      enableSMSConfirmation?: boolean | null,
      enableMustSignBypass?: boolean | null,
    } | null,
    contractSettings?:  Array< {
      __typename: "FeatureSetting",
      name?: string | null,
      enabled?: boolean | null,
      input?: string | null,
    } | null > | null,
    contractSettingsJSON?: string | null,
    categoryDynamicPricings?:  Array< {
      __typename: "CategoryDynamicPricing",
      categoryId?: string | null,
      pricingId?: string | null,
      percentage?: number | null,
    } | null > | null,
    disableReservationCategoryIds?: Array< string | null > | null,
    locationColor?: string | null,
    createdAt: string,
    updatedAt: string,
    merchantDetails?:  {
      __typename: "MerchantDetails",
      api?: VismaPayApi | null,
      merchant_id?: string | null,
      cp?: string | null,
    } | null,
    businessHoursExceptions?:  {
      __typename: "ModelBusinessHoursExceptionConnection",
      items:  Array< {
        __typename: "BusinessHoursException",
        organizationId?: string | null,
        orgBusinessId?: string | null,
        group?: string | null,
        date: string,
        name?: string | null,
        companyId: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    vehicles?:  {
      __typename: "ModelVehicleConnection",
      items:  Array< {
        __typename: "Vehicle",
        id: string,
        group?: string | null,
        organizationId?: string | null,
        orgBusinessId?: string | null,
        companyId: string,
        name: string,
        registrationPlate?: string | null,
        damages?: string | null,
        image?: string | null,
        powerType?: string | null,
        deductible?: number | null,
        includedDistance?: string | null,
        mileage?: string | null,
        orderNumber?: number | null,
        vehicleCategoryId?: string | null,
        useCategoryDefaults?: boolean | null,
        useStartReturnTimes?: boolean | null,
        hideExport?: boolean | null,
        extraInfo?: string | null,
        extraInfoT?: string | null,
        extraToConfirmation?: string | null,
        extraToConfirmationT?: string | null,
        livionKeyId?: string | null,
        phoneNumber?: string | null,
        importData?: string | null,
        disableOnline?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    categories?:  {
      __typename: "ModelCategoryConnection",
      items:  Array< {
        __typename: "Category",
        id: string,
        group?: string | null,
        organizationId?: string | null,
        name: string,
        image?: string | null,
        orderNumber?: number | null,
        backgroundColor?: string | null,
        minuteBuffer?: number | null,
        reservationBlockFromNow?: number | null,
        typeId?: string | null,
        _removed?: string | null,
        oldTujausValue?: string | null,
        exampleModelName?: string | null,
        exampleModelImage?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    categoriesByOrg?:  {
      __typename: "ModelCategoryConnection",
      items:  Array< {
        __typename: "Category",
        id: string,
        group?: string | null,
        organizationId?: string | null,
        name: string,
        image?: string | null,
        orderNumber?: number | null,
        backgroundColor?: string | null,
        minuteBuffer?: number | null,
        reservationBlockFromNow?: number | null,
        typeId?: string | null,
        _removed?: string | null,
        oldTujausValue?: string | null,
        exampleModelName?: string | null,
        exampleModelImage?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type DeleteCompanyMutationVariables = {
  input: DeleteCompanyInput,
  condition?: ModelCompanyConditionInput | null,
};

export type DeleteCompanyMutation = {
  deleteCompany?:  {
    __typename: "Company",
    id: string,
    organizationId?: string | null,
    orgBusinessId?: string | null,
    group?: string | null,
    name: string,
    displayName?: string | null,
    email?: string | null,
    address?: string | null,
    postalCode?: string | null,
    city?: string | null,
    orderNumber?: number | null,
    orderNumberDealer?: number | null,
    phone?: string | null,
    businessId?: string | null,
    termsUrl?: string | null,
    timezone: string,
    weeklyBusinessHours:  Array< {
      __typename: "ClockRange",
      openAt: string,
      closeAt: string,
    } | null >,
    signConfirmationEmail?: string | null,
    nameInEmail?: string | null,
    senderNameSMS?: string | null,
    confirmationMsg?: string | null,
    confirmationMsgT?:  {
      __typename: "Translation",
      fi?: string | null,
      en?: string | null,
      sv?: string | null,
    } | null,
    feedbackMsg?: string | null,
    feedbackMsgT?:  {
      __typename: "Translation",
      fi?: string | null,
      en?: string | null,
      sv?: string | null,
    } | null,
    statusLinkMsg?: string | null,
    statusLinkMsgT?:  {
      __typename: "Translation",
      fi?: string | null,
      en?: string | null,
      sv?: string | null,
    } | null,
    livionDeviceId?: string | null,
    livionDeviceType?: LivionDevice | null,
    enableReservationDeposit?: boolean | null,
    reservationDeposit?:  {
      __typename: "ReservationDeposit",
      amount?: number | null,
      reminderBefore?: number | null,
      paymentBefore?: number | null,
    } | null,
    reservationLinkExpirationHours?: number | null,
    disableOffHourReturn?: boolean | null,
    disableMultiday?: boolean | null,
    disableSameDay?: boolean | null,
    disableStartDaysFromNow?: number | null,
    enableCart?: boolean | null,
    enableMonthlyView?: boolean | null,
    enableRegistrationPlate?: boolean | null,
    enableVehicleStartReturnTimes?: boolean | null,
    enableMustSign?: boolean | null,
    enablePaymentOnlineOnly?: boolean | null,
    enableGTM?: boolean | null,
    enableNetPromoterScore?: boolean | null,
    enableNPS?: boolean | null,
    reservationCancellation?:  {
      __typename: "ReservationCancellationSettings",
      cancellationEnabled?: boolean | null,
      daysBeforeStart?: number | null,
    } | null,
    creditCheck?:  {
      __typename: "AsiakastietoCreditCheck",
      enabled?: boolean | null,
      keyLookup?: boolean | null,
      userId?: string | null,
      passwordId?: string | null,
    } | null,
    limitReservationOncePerDay?: boolean | null,
    hidden?: boolean | null,
    receiptInfo?:  {
      __typename: "ReceiptInfo",
      enabled?: boolean | null,
      name?: string | null,
      address?: string | null,
      city?: string | null,
      postalCode?: string | null,
    } | null,
    innovoice?:  {
      __typename: "InnovoiceData",
      enabled?: boolean | null,
      companyId?: string | null,
      uRentCarRentalId?: string | null,
    } | null,
    locationType?: string | null,
    offHourAvailability?: boolean | null,
    offHourAvailabilityFee?: number | null,
    deliveryFee?: number | null,
    deliveryBufferHours?: number | null,
    notAllowedCategoryIds?: Array< string | null > | null,
    importIdentifier?: string | null,
    features?:  {
      __typename: "Features",
      enableNPS?: boolean | null,
      enableVismaPay?: boolean | null,
      enableReservationDialogV2?: boolean | null,
      enableTilakone?: boolean | null,
      enableGTM?: GTMVersion | null,
      enableSMSConfirmation?: boolean | null,
      enableMustSignBypass?: boolean | null,
    } | null,
    contractSettings?:  Array< {
      __typename: "FeatureSetting",
      name?: string | null,
      enabled?: boolean | null,
      input?: string | null,
    } | null > | null,
    contractSettingsJSON?: string | null,
    categoryDynamicPricings?:  Array< {
      __typename: "CategoryDynamicPricing",
      categoryId?: string | null,
      pricingId?: string | null,
      percentage?: number | null,
    } | null > | null,
    disableReservationCategoryIds?: Array< string | null > | null,
    locationColor?: string | null,
    createdAt: string,
    updatedAt: string,
    merchantDetails?:  {
      __typename: "MerchantDetails",
      api?: VismaPayApi | null,
      merchant_id?: string | null,
      cp?: string | null,
    } | null,
    businessHoursExceptions?:  {
      __typename: "ModelBusinessHoursExceptionConnection",
      items:  Array< {
        __typename: "BusinessHoursException",
        organizationId?: string | null,
        orgBusinessId?: string | null,
        group?: string | null,
        date: string,
        name?: string | null,
        companyId: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    vehicles?:  {
      __typename: "ModelVehicleConnection",
      items:  Array< {
        __typename: "Vehicle",
        id: string,
        group?: string | null,
        organizationId?: string | null,
        orgBusinessId?: string | null,
        companyId: string,
        name: string,
        registrationPlate?: string | null,
        damages?: string | null,
        image?: string | null,
        powerType?: string | null,
        deductible?: number | null,
        includedDistance?: string | null,
        mileage?: string | null,
        orderNumber?: number | null,
        vehicleCategoryId?: string | null,
        useCategoryDefaults?: boolean | null,
        useStartReturnTimes?: boolean | null,
        hideExport?: boolean | null,
        extraInfo?: string | null,
        extraInfoT?: string | null,
        extraToConfirmation?: string | null,
        extraToConfirmationT?: string | null,
        livionKeyId?: string | null,
        phoneNumber?: string | null,
        importData?: string | null,
        disableOnline?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    categories?:  {
      __typename: "ModelCategoryConnection",
      items:  Array< {
        __typename: "Category",
        id: string,
        group?: string | null,
        organizationId?: string | null,
        name: string,
        image?: string | null,
        orderNumber?: number | null,
        backgroundColor?: string | null,
        minuteBuffer?: number | null,
        reservationBlockFromNow?: number | null,
        typeId?: string | null,
        _removed?: string | null,
        oldTujausValue?: string | null,
        exampleModelName?: string | null,
        exampleModelImage?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    categoriesByOrg?:  {
      __typename: "ModelCategoryConnection",
      items:  Array< {
        __typename: "Category",
        id: string,
        group?: string | null,
        organizationId?: string | null,
        name: string,
        image?: string | null,
        orderNumber?: number | null,
        backgroundColor?: string | null,
        minuteBuffer?: number | null,
        reservationBlockFromNow?: number | null,
        typeId?: string | null,
        _removed?: string | null,
        oldTujausValue?: string | null,
        exampleModelName?: string | null,
        exampleModelImage?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type CreateBusinessHoursExceptionMutationVariables = {
  input: CreateBusinessHoursExceptionInput,
  condition?: ModelBusinessHoursExceptionConditionInput | null,
};

export type CreateBusinessHoursExceptionMutation = {
  createBusinessHoursException?:  {
    __typename: "BusinessHoursException",
    organizationId?: string | null,
    orgBusinessId?: string | null,
    group?: string | null,
    date: string,
    name?: string | null,
    businessHours?:  {
      __typename: "ClockRange",
      openAt: string,
      closeAt: string,
    } | null,
    companyId: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateBusinessHoursExceptionMutationVariables = {
  input: UpdateBusinessHoursExceptionInput,
  condition?: ModelBusinessHoursExceptionConditionInput | null,
};

export type UpdateBusinessHoursExceptionMutation = {
  updateBusinessHoursException?:  {
    __typename: "BusinessHoursException",
    organizationId?: string | null,
    orgBusinessId?: string | null,
    group?: string | null,
    date: string,
    name?: string | null,
    businessHours?:  {
      __typename: "ClockRange",
      openAt: string,
      closeAt: string,
    } | null,
    companyId: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteBusinessHoursExceptionMutationVariables = {
  input: DeleteBusinessHoursExceptionInput,
  condition?: ModelBusinessHoursExceptionConditionInput | null,
};

export type DeleteBusinessHoursExceptionMutation = {
  deleteBusinessHoursException?:  {
    __typename: "BusinessHoursException",
    organizationId?: string | null,
    orgBusinessId?: string | null,
    group?: string | null,
    date: string,
    name?: string | null,
    businessHours?:  {
      __typename: "ClockRange",
      openAt: string,
      closeAt: string,
    } | null,
    companyId: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateVehicleMutationVariables = {
  input: CreateVehicleInput,
  condition?: ModelVehicleConditionInput | null,
};

export type CreateVehicleMutation = {
  createVehicle?:  {
    __typename: "Vehicle",
    id: string,
    group?: string | null,
    organizationId?: string | null,
    orgBusinessId?: string | null,
    companyId: string,
    name: string,
    nameT?:  {
      __typename: "Translation",
      fi?: string | null,
      en?: string | null,
      sv?: string | null,
    } | null,
    registrationPlate?: string | null,
    damages?: string | null,
    damageRecords?:  Array< {
      __typename: "DamageRecord",
      normalizedX?: number | null,
      normalizedY?: number | null,
      label?: string | null,
      description?: string | null,
      createdAt?: string | null,
    } > | null,
    additionalFields:  Array< {
      __typename: "AdditionalField",
      key: string,
      value?: string | null,
      valueT?:  {
        __typename: "Translation",
        fi?: string | null,
        en?: string | null,
        sv?: string | null,
      } | null,
    } >,
    image?: string | null,
    powerType?: string | null,
    deductible?: number | null,
    includedDistance?: string | null,
    mileage?: string | null,
    orderNumber?: number | null,
    vehicleCategoryId?: string | null,
    useCategoryDefaults?: boolean | null,
    useStartReturnTimes?: boolean | null,
    customStartReturns?:  Array< {
      __typename: "CustomStartReturn",
      priority?: number | null,
      startReturnInterval?:  {
        __typename: "StartReturnInterval",
        start?: string | null,
        end?: string | null,
      } | null,
      startReturnTimes?:  Array< {
        __typename: "StartReturnTimes",
        start?: Array< string | null > | null,
        return?: Array< string | null > | null,
      } | null > | null,
    } | null > | null,
    startReturnInterval?:  {
      __typename: "StartReturnInterval",
      start?: string | null,
      end?: string | null,
    } | null,
    startReturnTimes?:  Array< {
      __typename: "StartReturnTimes",
      start?: Array< string | null > | null,
      return?: Array< string | null > | null,
    } | null > | null,
    hideExport?: boolean | null,
    extraInfo?: string | null,
    extraInfoT?: string | null,
    extraToConfirmation?: string | null,
    extraToConfirmationT?: string | null,
    livionKeyId?: string | null,
    phoneNumber?: string | null,
    importData?: string | null,
    disableOnline?: boolean | null,
    activeRange?:  {
      __typename: "StartReturnInterval",
      start?: string | null,
      end?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    category?:  {
      __typename: "Category",
      id: string,
      group?: string | null,
      organizationId?: string | null,
      name: string,
      image?: string | null,
      orderNumber?: number | null,
      backgroundColor?: string | null,
      minuteBuffer?: number | null,
      reservationBlockFromNow?: number | null,
      typeId?: string | null,
      _removed?: string | null,
      oldTujausValue?: string | null,
      exampleModelName?: string | null,
      exampleModelImage?: string | null,
      createdAt: string,
      updatedAt: string,
      additionalServices?:  {
        __typename: "ModelAdditionalServicePricingConnection",
        nextToken?: string | null,
      } | null,
      pricing?:  {
        __typename: "Pricing",
        id: string,
        name: string,
        group?: string | null,
        organizationId?: string | null,
        orgBusinessId?: string | null,
        orgExternalBusinessId?: string | null,
        externalBusinessCategoryAssociation?: string | null,
        businessCategoryAssociation?: string | null,
        useNextDayPrice?: boolean | null,
        useLastDayPrice?: boolean | null,
        tax?: number | null,
        _removed?: string | null,
        deductible?: number | null,
        reducedDeductible?: number | null,
        reducedDeductiblePrice?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      externalBusinessPricings?:  {
        __typename: "ModelPricingConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    additionalServices?:  {
      __typename: "ModelAdditionalServicePricingConnection",
      items:  Array< {
        __typename: "AdditionalServicePricing",
        id: string,
        group?: string | null,
        organizationId?: string | null,
        orgBusinessId?: string | null,
        key: string,
        vehicleId?: string | null,
        categoryId?: string | null,
        pricingId: string,
        deductibleValue?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    reservations?:  {
      __typename: "ModelReservationConnection",
      items:  Array< {
        __typename: "Reservation",
        id: string,
        shortId?: string | null,
        language?: Language | null,
        group?: string | null,
        organizationId?: string | null,
        orgBusinessId?: string | null,
        orgExternalBusinessId?: string | null,
        companyId?: string | null,
        returnCompanyId?: string | null,
        startLocation?: string | null,
        returnLocation?: string | null,
        startLocationID?: string | null,
        returnLocationID?: string | null,
        reviewId?: string | null,
        customerBrokerId?: string | null,
        customerCompanyId?: string | null,
        customerPersonalId?: string | null,
        reservationVehicleId?: string | null,
        createdAt: string,
        updatedAt: string,
        startTime?: string | null,
        returnTime?: string | null,
        endTime?: string | null,
        name?: string | null,
        address?: string | null,
        postalCode?: string | null,
        city?: string | null,
        phone?: string | null,
        email?: string | null,
        billingRef?: string | null,
        customerNotes?: string | null,
        ssn?: string | null,
        rentalNotes?: string | null,
        internalNotes?: string | null,
        externalNotes?: string | null,
        outOfCommission?: boolean | null,
        vehiclePrice?: number | null,
        driverSSN?: string | null,
        differentDriver?: boolean | null,
        driverName?: string | null,
        driverAddress?: string | null,
        driverPostalCode?: string | null,
        driverCity?: string | null,
        driverPhone?: string | null,
        driverEmail?: string | null,
        additionalDriver?: boolean | null,
        additionalDriverName?: string | null,
        additionalDriverPhone?: string | null,
        additionalDriverSSN?: string | null,
        billPayment?: boolean | null,
        isCompany?: boolean | null,
        carStatus?: RentalStatus | null,
        rentalStatus?: RentalStatus | null,
        innovoiceStatus?: ReservationInnovoiceStatus | null,
        companyName?: string | null,
        companyBusinessId?: string | null,
        companyCity?: string | null,
        companyPostalCode?: string | null,
        companyBillingRef?: string | null,
        companyBillingAddress?: string | null,
        companyEBillingOperator?: string | null,
        companyEBillingAddress?: string | null,
        paymentToken?: string | null,
        npsStatus?: NpsStatus | null,
        paymentStatus?: PaymentStatus | null,
        signStatus?: SignStatus | null,
        signDocumentId?: string | null,
        signerName?: string | null,
        reviewStatus?: ReviewStatus | null,
        authStatus?: AuthStatus | null,
        authTime?: string | null,
        completeBefore?: string | null,
        mustSign?: boolean | null,
        customerCarRegistrationPlate?: string | null,
        enableRegistrationPlate?: boolean | null,
        disablePricesInPrintouts?: boolean | null,
        invoiceIds?: Array< string > | null,
        consolidatedInvoiceIds?: Array< string > | null,
        channel?: ReservationChannel | null,
        originURL?: string | null,
        type?: ReservationType | null,
        status?: string | null,
        pricingCategoryId?: string | null,
        customImportDate?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    pricing?:  {
      __typename: "Pricing",
      id: string,
      name: string,
      group?: string | null,
      organizationId?: string | null,
      orgBusinessId?: string | null,
      orgExternalBusinessId?: string | null,
      externalBusinessCategoryAssociation?: string | null,
      businessCategoryAssociation?: string | null,
      elements:  Array< {
        __typename: "PricingElement",
        duration: string,
        price: number,
      } >,
      useNextDayPrice?: boolean | null,
      useLastDayPrice?: boolean | null,
      tax?: number | null,
      _removed?: string | null,
      deductible?: number | null,
      reducedDeductible?: number | null,
      reducedDeductiblePrice?: number | null,
      createdAt: string,
      updatedAt: string,
    } | null,
  } | null,
};

export type UpdateVehicleMutationVariables = {
  input: UpdateVehicleInput,
  condition?: ModelVehicleConditionInput | null,
};

export type UpdateVehicleMutation = {
  updateVehicle?:  {
    __typename: "Vehicle",
    id: string,
    group?: string | null,
    organizationId?: string | null,
    orgBusinessId?: string | null,
    companyId: string,
    name: string,
    nameT?:  {
      __typename: "Translation",
      fi?: string | null,
      en?: string | null,
      sv?: string | null,
    } | null,
    registrationPlate?: string | null,
    damages?: string | null,
    damageRecords?:  Array< {
      __typename: "DamageRecord",
      normalizedX?: number | null,
      normalizedY?: number | null,
      label?: string | null,
      description?: string | null,
      createdAt?: string | null,
    } > | null,
    additionalFields:  Array< {
      __typename: "AdditionalField",
      key: string,
      value?: string | null,
      valueT?:  {
        __typename: "Translation",
        fi?: string | null,
        en?: string | null,
        sv?: string | null,
      } | null,
    } >,
    image?: string | null,
    powerType?: string | null,
    deductible?: number | null,
    includedDistance?: string | null,
    mileage?: string | null,
    orderNumber?: number | null,
    vehicleCategoryId?: string | null,
    useCategoryDefaults?: boolean | null,
    useStartReturnTimes?: boolean | null,
    customStartReturns?:  Array< {
      __typename: "CustomStartReturn",
      priority?: number | null,
      startReturnInterval?:  {
        __typename: "StartReturnInterval",
        start?: string | null,
        end?: string | null,
      } | null,
      startReturnTimes?:  Array< {
        __typename: "StartReturnTimes",
        start?: Array< string | null > | null,
        return?: Array< string | null > | null,
      } | null > | null,
    } | null > | null,
    startReturnInterval?:  {
      __typename: "StartReturnInterval",
      start?: string | null,
      end?: string | null,
    } | null,
    startReturnTimes?:  Array< {
      __typename: "StartReturnTimes",
      start?: Array< string | null > | null,
      return?: Array< string | null > | null,
    } | null > | null,
    hideExport?: boolean | null,
    extraInfo?: string | null,
    extraInfoT?: string | null,
    extraToConfirmation?: string | null,
    extraToConfirmationT?: string | null,
    livionKeyId?: string | null,
    phoneNumber?: string | null,
    importData?: string | null,
    disableOnline?: boolean | null,
    activeRange?:  {
      __typename: "StartReturnInterval",
      start?: string | null,
      end?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    category?:  {
      __typename: "Category",
      id: string,
      group?: string | null,
      organizationId?: string | null,
      name: string,
      image?: string | null,
      orderNumber?: number | null,
      backgroundColor?: string | null,
      minuteBuffer?: number | null,
      reservationBlockFromNow?: number | null,
      typeId?: string | null,
      _removed?: string | null,
      oldTujausValue?: string | null,
      exampleModelName?: string | null,
      exampleModelImage?: string | null,
      createdAt: string,
      updatedAt: string,
      additionalServices?:  {
        __typename: "ModelAdditionalServicePricingConnection",
        nextToken?: string | null,
      } | null,
      pricing?:  {
        __typename: "Pricing",
        id: string,
        name: string,
        group?: string | null,
        organizationId?: string | null,
        orgBusinessId?: string | null,
        orgExternalBusinessId?: string | null,
        externalBusinessCategoryAssociation?: string | null,
        businessCategoryAssociation?: string | null,
        useNextDayPrice?: boolean | null,
        useLastDayPrice?: boolean | null,
        tax?: number | null,
        _removed?: string | null,
        deductible?: number | null,
        reducedDeductible?: number | null,
        reducedDeductiblePrice?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      externalBusinessPricings?:  {
        __typename: "ModelPricingConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    additionalServices?:  {
      __typename: "ModelAdditionalServicePricingConnection",
      items:  Array< {
        __typename: "AdditionalServicePricing",
        id: string,
        group?: string | null,
        organizationId?: string | null,
        orgBusinessId?: string | null,
        key: string,
        vehicleId?: string | null,
        categoryId?: string | null,
        pricingId: string,
        deductibleValue?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    reservations?:  {
      __typename: "ModelReservationConnection",
      items:  Array< {
        __typename: "Reservation",
        id: string,
        shortId?: string | null,
        language?: Language | null,
        group?: string | null,
        organizationId?: string | null,
        orgBusinessId?: string | null,
        orgExternalBusinessId?: string | null,
        companyId?: string | null,
        returnCompanyId?: string | null,
        startLocation?: string | null,
        returnLocation?: string | null,
        startLocationID?: string | null,
        returnLocationID?: string | null,
        reviewId?: string | null,
        customerBrokerId?: string | null,
        customerCompanyId?: string | null,
        customerPersonalId?: string | null,
        reservationVehicleId?: string | null,
        createdAt: string,
        updatedAt: string,
        startTime?: string | null,
        returnTime?: string | null,
        endTime?: string | null,
        name?: string | null,
        address?: string | null,
        postalCode?: string | null,
        city?: string | null,
        phone?: string | null,
        email?: string | null,
        billingRef?: string | null,
        customerNotes?: string | null,
        ssn?: string | null,
        rentalNotes?: string | null,
        internalNotes?: string | null,
        externalNotes?: string | null,
        outOfCommission?: boolean | null,
        vehiclePrice?: number | null,
        driverSSN?: string | null,
        differentDriver?: boolean | null,
        driverName?: string | null,
        driverAddress?: string | null,
        driverPostalCode?: string | null,
        driverCity?: string | null,
        driverPhone?: string | null,
        driverEmail?: string | null,
        additionalDriver?: boolean | null,
        additionalDriverName?: string | null,
        additionalDriverPhone?: string | null,
        additionalDriverSSN?: string | null,
        billPayment?: boolean | null,
        isCompany?: boolean | null,
        carStatus?: RentalStatus | null,
        rentalStatus?: RentalStatus | null,
        innovoiceStatus?: ReservationInnovoiceStatus | null,
        companyName?: string | null,
        companyBusinessId?: string | null,
        companyCity?: string | null,
        companyPostalCode?: string | null,
        companyBillingRef?: string | null,
        companyBillingAddress?: string | null,
        companyEBillingOperator?: string | null,
        companyEBillingAddress?: string | null,
        paymentToken?: string | null,
        npsStatus?: NpsStatus | null,
        paymentStatus?: PaymentStatus | null,
        signStatus?: SignStatus | null,
        signDocumentId?: string | null,
        signerName?: string | null,
        reviewStatus?: ReviewStatus | null,
        authStatus?: AuthStatus | null,
        authTime?: string | null,
        completeBefore?: string | null,
        mustSign?: boolean | null,
        customerCarRegistrationPlate?: string | null,
        enableRegistrationPlate?: boolean | null,
        disablePricesInPrintouts?: boolean | null,
        invoiceIds?: Array< string > | null,
        consolidatedInvoiceIds?: Array< string > | null,
        channel?: ReservationChannel | null,
        originURL?: string | null,
        type?: ReservationType | null,
        status?: string | null,
        pricingCategoryId?: string | null,
        customImportDate?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    pricing?:  {
      __typename: "Pricing",
      id: string,
      name: string,
      group?: string | null,
      organizationId?: string | null,
      orgBusinessId?: string | null,
      orgExternalBusinessId?: string | null,
      externalBusinessCategoryAssociation?: string | null,
      businessCategoryAssociation?: string | null,
      elements:  Array< {
        __typename: "PricingElement",
        duration: string,
        price: number,
      } >,
      useNextDayPrice?: boolean | null,
      useLastDayPrice?: boolean | null,
      tax?: number | null,
      _removed?: string | null,
      deductible?: number | null,
      reducedDeductible?: number | null,
      reducedDeductiblePrice?: number | null,
      createdAt: string,
      updatedAt: string,
    } | null,
  } | null,
};

export type DeleteVehicleMutationVariables = {
  input: DeleteVehicleInput,
  condition?: ModelVehicleConditionInput | null,
};

export type DeleteVehicleMutation = {
  deleteVehicle?:  {
    __typename: "Vehicle",
    id: string,
    group?: string | null,
    organizationId?: string | null,
    orgBusinessId?: string | null,
    companyId: string,
    name: string,
    nameT?:  {
      __typename: "Translation",
      fi?: string | null,
      en?: string | null,
      sv?: string | null,
    } | null,
    registrationPlate?: string | null,
    damages?: string | null,
    damageRecords?:  Array< {
      __typename: "DamageRecord",
      normalizedX?: number | null,
      normalizedY?: number | null,
      label?: string | null,
      description?: string | null,
      createdAt?: string | null,
    } > | null,
    additionalFields:  Array< {
      __typename: "AdditionalField",
      key: string,
      value?: string | null,
      valueT?:  {
        __typename: "Translation",
        fi?: string | null,
        en?: string | null,
        sv?: string | null,
      } | null,
    } >,
    image?: string | null,
    powerType?: string | null,
    deductible?: number | null,
    includedDistance?: string | null,
    mileage?: string | null,
    orderNumber?: number | null,
    vehicleCategoryId?: string | null,
    useCategoryDefaults?: boolean | null,
    useStartReturnTimes?: boolean | null,
    customStartReturns?:  Array< {
      __typename: "CustomStartReturn",
      priority?: number | null,
      startReturnInterval?:  {
        __typename: "StartReturnInterval",
        start?: string | null,
        end?: string | null,
      } | null,
      startReturnTimes?:  Array< {
        __typename: "StartReturnTimes",
        start?: Array< string | null > | null,
        return?: Array< string | null > | null,
      } | null > | null,
    } | null > | null,
    startReturnInterval?:  {
      __typename: "StartReturnInterval",
      start?: string | null,
      end?: string | null,
    } | null,
    startReturnTimes?:  Array< {
      __typename: "StartReturnTimes",
      start?: Array< string | null > | null,
      return?: Array< string | null > | null,
    } | null > | null,
    hideExport?: boolean | null,
    extraInfo?: string | null,
    extraInfoT?: string | null,
    extraToConfirmation?: string | null,
    extraToConfirmationT?: string | null,
    livionKeyId?: string | null,
    phoneNumber?: string | null,
    importData?: string | null,
    disableOnline?: boolean | null,
    activeRange?:  {
      __typename: "StartReturnInterval",
      start?: string | null,
      end?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    category?:  {
      __typename: "Category",
      id: string,
      group?: string | null,
      organizationId?: string | null,
      name: string,
      image?: string | null,
      orderNumber?: number | null,
      backgroundColor?: string | null,
      minuteBuffer?: number | null,
      reservationBlockFromNow?: number | null,
      typeId?: string | null,
      _removed?: string | null,
      oldTujausValue?: string | null,
      exampleModelName?: string | null,
      exampleModelImage?: string | null,
      createdAt: string,
      updatedAt: string,
      additionalServices?:  {
        __typename: "ModelAdditionalServicePricingConnection",
        nextToken?: string | null,
      } | null,
      pricing?:  {
        __typename: "Pricing",
        id: string,
        name: string,
        group?: string | null,
        organizationId?: string | null,
        orgBusinessId?: string | null,
        orgExternalBusinessId?: string | null,
        externalBusinessCategoryAssociation?: string | null,
        businessCategoryAssociation?: string | null,
        useNextDayPrice?: boolean | null,
        useLastDayPrice?: boolean | null,
        tax?: number | null,
        _removed?: string | null,
        deductible?: number | null,
        reducedDeductible?: number | null,
        reducedDeductiblePrice?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      externalBusinessPricings?:  {
        __typename: "ModelPricingConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    additionalServices?:  {
      __typename: "ModelAdditionalServicePricingConnection",
      items:  Array< {
        __typename: "AdditionalServicePricing",
        id: string,
        group?: string | null,
        organizationId?: string | null,
        orgBusinessId?: string | null,
        key: string,
        vehicleId?: string | null,
        categoryId?: string | null,
        pricingId: string,
        deductibleValue?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    reservations?:  {
      __typename: "ModelReservationConnection",
      items:  Array< {
        __typename: "Reservation",
        id: string,
        shortId?: string | null,
        language?: Language | null,
        group?: string | null,
        organizationId?: string | null,
        orgBusinessId?: string | null,
        orgExternalBusinessId?: string | null,
        companyId?: string | null,
        returnCompanyId?: string | null,
        startLocation?: string | null,
        returnLocation?: string | null,
        startLocationID?: string | null,
        returnLocationID?: string | null,
        reviewId?: string | null,
        customerBrokerId?: string | null,
        customerCompanyId?: string | null,
        customerPersonalId?: string | null,
        reservationVehicleId?: string | null,
        createdAt: string,
        updatedAt: string,
        startTime?: string | null,
        returnTime?: string | null,
        endTime?: string | null,
        name?: string | null,
        address?: string | null,
        postalCode?: string | null,
        city?: string | null,
        phone?: string | null,
        email?: string | null,
        billingRef?: string | null,
        customerNotes?: string | null,
        ssn?: string | null,
        rentalNotes?: string | null,
        internalNotes?: string | null,
        externalNotes?: string | null,
        outOfCommission?: boolean | null,
        vehiclePrice?: number | null,
        driverSSN?: string | null,
        differentDriver?: boolean | null,
        driverName?: string | null,
        driverAddress?: string | null,
        driverPostalCode?: string | null,
        driverCity?: string | null,
        driverPhone?: string | null,
        driverEmail?: string | null,
        additionalDriver?: boolean | null,
        additionalDriverName?: string | null,
        additionalDriverPhone?: string | null,
        additionalDriverSSN?: string | null,
        billPayment?: boolean | null,
        isCompany?: boolean | null,
        carStatus?: RentalStatus | null,
        rentalStatus?: RentalStatus | null,
        innovoiceStatus?: ReservationInnovoiceStatus | null,
        companyName?: string | null,
        companyBusinessId?: string | null,
        companyCity?: string | null,
        companyPostalCode?: string | null,
        companyBillingRef?: string | null,
        companyBillingAddress?: string | null,
        companyEBillingOperator?: string | null,
        companyEBillingAddress?: string | null,
        paymentToken?: string | null,
        npsStatus?: NpsStatus | null,
        paymentStatus?: PaymentStatus | null,
        signStatus?: SignStatus | null,
        signDocumentId?: string | null,
        signerName?: string | null,
        reviewStatus?: ReviewStatus | null,
        authStatus?: AuthStatus | null,
        authTime?: string | null,
        completeBefore?: string | null,
        mustSign?: boolean | null,
        customerCarRegistrationPlate?: string | null,
        enableRegistrationPlate?: boolean | null,
        disablePricesInPrintouts?: boolean | null,
        invoiceIds?: Array< string > | null,
        consolidatedInvoiceIds?: Array< string > | null,
        channel?: ReservationChannel | null,
        originURL?: string | null,
        type?: ReservationType | null,
        status?: string | null,
        pricingCategoryId?: string | null,
        customImportDate?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    pricing?:  {
      __typename: "Pricing",
      id: string,
      name: string,
      group?: string | null,
      organizationId?: string | null,
      orgBusinessId?: string | null,
      orgExternalBusinessId?: string | null,
      externalBusinessCategoryAssociation?: string | null,
      businessCategoryAssociation?: string | null,
      elements:  Array< {
        __typename: "PricingElement",
        duration: string,
        price: number,
      } >,
      useNextDayPrice?: boolean | null,
      useLastDayPrice?: boolean | null,
      tax?: number | null,
      _removed?: string | null,
      deductible?: number | null,
      reducedDeductible?: number | null,
      reducedDeductiblePrice?: number | null,
      createdAt: string,
      updatedAt: string,
    } | null,
  } | null,
};

export type CreateCategoryMutationVariables = {
  input: CreateCategoryInput,
  condition?: ModelCategoryConditionInput | null,
};

export type CreateCategoryMutation = {
  createCategory?:  {
    __typename: "Category",
    id: string,
    group?: string | null,
    organizationId?: string | null,
    name: string,
    image?: string | null,
    orderNumber?: number | null,
    backgroundColor?: string | null,
    minuteBuffer?: number | null,
    reservationBlockFromNow?: number | null,
    typeId?: string | null,
    _removed?: string | null,
    oldTujausValue?: string | null,
    exampleModelName?: string | null,
    exampleModelImage?: string | null,
    createdAt: string,
    updatedAt: string,
    additionalServices?:  {
      __typename: "ModelAdditionalServicePricingConnection",
      items:  Array< {
        __typename: "AdditionalServicePricing",
        id: string,
        group?: string | null,
        organizationId?: string | null,
        orgBusinessId?: string | null,
        key: string,
        vehicleId?: string | null,
        categoryId?: string | null,
        pricingId: string,
        deductibleValue?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    pricing?:  {
      __typename: "Pricing",
      id: string,
      name: string,
      group?: string | null,
      organizationId?: string | null,
      orgBusinessId?: string | null,
      orgExternalBusinessId?: string | null,
      externalBusinessCategoryAssociation?: string | null,
      businessCategoryAssociation?: string | null,
      elements:  Array< {
        __typename: "PricingElement",
        duration: string,
        price: number,
      } >,
      useNextDayPrice?: boolean | null,
      useLastDayPrice?: boolean | null,
      tax?: number | null,
      _removed?: string | null,
      deductible?: number | null,
      reducedDeductible?: number | null,
      reducedDeductiblePrice?: number | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    externalBusinessPricings?:  {
      __typename: "ModelPricingConnection",
      items:  Array< {
        __typename: "Pricing",
        id: string,
        name: string,
        group?: string | null,
        organizationId?: string | null,
        orgBusinessId?: string | null,
        orgExternalBusinessId?: string | null,
        externalBusinessCategoryAssociation?: string | null,
        businessCategoryAssociation?: string | null,
        useNextDayPrice?: boolean | null,
        useLastDayPrice?: boolean | null,
        tax?: number | null,
        _removed?: string | null,
        deductible?: number | null,
        reducedDeductible?: number | null,
        reducedDeductiblePrice?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type UpdateCategoryMutationVariables = {
  input: UpdateCategoryInput,
  condition?: ModelCategoryConditionInput | null,
};

export type UpdateCategoryMutation = {
  updateCategory?:  {
    __typename: "Category",
    id: string,
    group?: string | null,
    organizationId?: string | null,
    name: string,
    image?: string | null,
    orderNumber?: number | null,
    backgroundColor?: string | null,
    minuteBuffer?: number | null,
    reservationBlockFromNow?: number | null,
    typeId?: string | null,
    _removed?: string | null,
    oldTujausValue?: string | null,
    exampleModelName?: string | null,
    exampleModelImage?: string | null,
    createdAt: string,
    updatedAt: string,
    additionalServices?:  {
      __typename: "ModelAdditionalServicePricingConnection",
      items:  Array< {
        __typename: "AdditionalServicePricing",
        id: string,
        group?: string | null,
        organizationId?: string | null,
        orgBusinessId?: string | null,
        key: string,
        vehicleId?: string | null,
        categoryId?: string | null,
        pricingId: string,
        deductibleValue?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    pricing?:  {
      __typename: "Pricing",
      id: string,
      name: string,
      group?: string | null,
      organizationId?: string | null,
      orgBusinessId?: string | null,
      orgExternalBusinessId?: string | null,
      externalBusinessCategoryAssociation?: string | null,
      businessCategoryAssociation?: string | null,
      elements:  Array< {
        __typename: "PricingElement",
        duration: string,
        price: number,
      } >,
      useNextDayPrice?: boolean | null,
      useLastDayPrice?: boolean | null,
      tax?: number | null,
      _removed?: string | null,
      deductible?: number | null,
      reducedDeductible?: number | null,
      reducedDeductiblePrice?: number | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    externalBusinessPricings?:  {
      __typename: "ModelPricingConnection",
      items:  Array< {
        __typename: "Pricing",
        id: string,
        name: string,
        group?: string | null,
        organizationId?: string | null,
        orgBusinessId?: string | null,
        orgExternalBusinessId?: string | null,
        externalBusinessCategoryAssociation?: string | null,
        businessCategoryAssociation?: string | null,
        useNextDayPrice?: boolean | null,
        useLastDayPrice?: boolean | null,
        tax?: number | null,
        _removed?: string | null,
        deductible?: number | null,
        reducedDeductible?: number | null,
        reducedDeductiblePrice?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type DeleteCategoryMutationVariables = {
  input: DeleteCategoryInput,
  condition?: ModelCategoryConditionInput | null,
};

export type DeleteCategoryMutation = {
  deleteCategory?:  {
    __typename: "Category",
    id: string,
    group?: string | null,
    organizationId?: string | null,
    name: string,
    image?: string | null,
    orderNumber?: number | null,
    backgroundColor?: string | null,
    minuteBuffer?: number | null,
    reservationBlockFromNow?: number | null,
    typeId?: string | null,
    _removed?: string | null,
    oldTujausValue?: string | null,
    exampleModelName?: string | null,
    exampleModelImage?: string | null,
    createdAt: string,
    updatedAt: string,
    additionalServices?:  {
      __typename: "ModelAdditionalServicePricingConnection",
      items:  Array< {
        __typename: "AdditionalServicePricing",
        id: string,
        group?: string | null,
        organizationId?: string | null,
        orgBusinessId?: string | null,
        key: string,
        vehicleId?: string | null,
        categoryId?: string | null,
        pricingId: string,
        deductibleValue?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    pricing?:  {
      __typename: "Pricing",
      id: string,
      name: string,
      group?: string | null,
      organizationId?: string | null,
      orgBusinessId?: string | null,
      orgExternalBusinessId?: string | null,
      externalBusinessCategoryAssociation?: string | null,
      businessCategoryAssociation?: string | null,
      elements:  Array< {
        __typename: "PricingElement",
        duration: string,
        price: number,
      } >,
      useNextDayPrice?: boolean | null,
      useLastDayPrice?: boolean | null,
      tax?: number | null,
      _removed?: string | null,
      deductible?: number | null,
      reducedDeductible?: number | null,
      reducedDeductiblePrice?: number | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    externalBusinessPricings?:  {
      __typename: "ModelPricingConnection",
      items:  Array< {
        __typename: "Pricing",
        id: string,
        name: string,
        group?: string | null,
        organizationId?: string | null,
        orgBusinessId?: string | null,
        orgExternalBusinessId?: string | null,
        externalBusinessCategoryAssociation?: string | null,
        businessCategoryAssociation?: string | null,
        useNextDayPrice?: boolean | null,
        useLastDayPrice?: boolean | null,
        tax?: number | null,
        _removed?: string | null,
        deductible?: number | null,
        reducedDeductible?: number | null,
        reducedDeductiblePrice?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type CreateAdditionalServicePricingMutationVariables = {
  input: CreateAdditionalServicePricingInput,
  condition?: ModelAdditionalServicePricingConditionInput | null,
};

export type CreateAdditionalServicePricingMutation = {
  createAdditionalServicePricing?:  {
    __typename: "AdditionalServicePricing",
    id: string,
    group?: string | null,
    organizationId?: string | null,
    orgBusinessId?: string | null,
    key: string,
    vehicleId?: string | null,
    categoryId?: string | null,
    pricingId: string,
    deductibleValue?: number | null,
    createdAt: string,
    updatedAt: string,
    pricing?:  {
      __typename: "Pricing",
      id: string,
      name: string,
      group?: string | null,
      organizationId?: string | null,
      orgBusinessId?: string | null,
      orgExternalBusinessId?: string | null,
      externalBusinessCategoryAssociation?: string | null,
      businessCategoryAssociation?: string | null,
      elements:  Array< {
        __typename: "PricingElement",
        duration: string,
        price: number,
      } >,
      useNextDayPrice?: boolean | null,
      useLastDayPrice?: boolean | null,
      tax?: number | null,
      _removed?: string | null,
      deductible?: number | null,
      reducedDeductible?: number | null,
      reducedDeductiblePrice?: number | null,
      createdAt: string,
      updatedAt: string,
    } | null,
  } | null,
};

export type UpdateAdditionalServicePricingMutationVariables = {
  input: UpdateAdditionalServicePricingInput,
  condition?: ModelAdditionalServicePricingConditionInput | null,
};

export type UpdateAdditionalServicePricingMutation = {
  updateAdditionalServicePricing?:  {
    __typename: "AdditionalServicePricing",
    id: string,
    group?: string | null,
    organizationId?: string | null,
    orgBusinessId?: string | null,
    key: string,
    vehicleId?: string | null,
    categoryId?: string | null,
    pricingId: string,
    deductibleValue?: number | null,
    createdAt: string,
    updatedAt: string,
    pricing?:  {
      __typename: "Pricing",
      id: string,
      name: string,
      group?: string | null,
      organizationId?: string | null,
      orgBusinessId?: string | null,
      orgExternalBusinessId?: string | null,
      externalBusinessCategoryAssociation?: string | null,
      businessCategoryAssociation?: string | null,
      elements:  Array< {
        __typename: "PricingElement",
        duration: string,
        price: number,
      } >,
      useNextDayPrice?: boolean | null,
      useLastDayPrice?: boolean | null,
      tax?: number | null,
      _removed?: string | null,
      deductible?: number | null,
      reducedDeductible?: number | null,
      reducedDeductiblePrice?: number | null,
      createdAt: string,
      updatedAt: string,
    } | null,
  } | null,
};

export type DeleteAdditionalServicePricingMutationVariables = {
  input: DeleteAdditionalServicePricingInput,
  condition?: ModelAdditionalServicePricingConditionInput | null,
};

export type DeleteAdditionalServicePricingMutation = {
  deleteAdditionalServicePricing?:  {
    __typename: "AdditionalServicePricing",
    id: string,
    group?: string | null,
    organizationId?: string | null,
    orgBusinessId?: string | null,
    key: string,
    vehicleId?: string | null,
    categoryId?: string | null,
    pricingId: string,
    deductibleValue?: number | null,
    createdAt: string,
    updatedAt: string,
    pricing?:  {
      __typename: "Pricing",
      id: string,
      name: string,
      group?: string | null,
      organizationId?: string | null,
      orgBusinessId?: string | null,
      orgExternalBusinessId?: string | null,
      externalBusinessCategoryAssociation?: string | null,
      businessCategoryAssociation?: string | null,
      elements:  Array< {
        __typename: "PricingElement",
        duration: string,
        price: number,
      } >,
      useNextDayPrice?: boolean | null,
      useLastDayPrice?: boolean | null,
      tax?: number | null,
      _removed?: string | null,
      deductible?: number | null,
      reducedDeductible?: number | null,
      reducedDeductiblePrice?: number | null,
      createdAt: string,
      updatedAt: string,
    } | null,
  } | null,
};

export type CreateUserMutationVariables = {
  input: CreateUserInput,
  condition?: ModelUserConditionInput | null,
};

export type CreateUserMutation = {
  createUser?:  {
    __typename: "User",
    id: string,
    organizationId?: string | null,
    orgBusinessId?: string | null,
    orgBusinessIds?: Array< string | null > | null,
    externalBusinessId?: string | null,
    role?: UserRole | null,
    permissions?:  {
      __typename: "Permission",
      userManagement?: boolean | null,
    } | null,
    email?: string | null,
    name?: string | null,
    regionalCompanyName?: string | null,
    createdAt: string,
    updatedAt: string,
    categoriesByOrg?:  {
      __typename: "ModelCategoryConnection",
      items:  Array< {
        __typename: "Category",
        id: string,
        group?: string | null,
        organizationId?: string | null,
        name: string,
        image?: string | null,
        orderNumber?: number | null,
        backgroundColor?: string | null,
        minuteBuffer?: number | null,
        reservationBlockFromNow?: number | null,
        typeId?: string | null,
        _removed?: string | null,
        oldTujausValue?: string | null,
        exampleModelName?: string | null,
        exampleModelImage?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    organization?:  {
      __typename: "Organization",
      id: string,
      name?: string | null,
      billingCustomers?:  Array< {
        __typename: "BillingCustomer",
        name?: string | null,
      } | null > | null,
      features?:  {
        __typename: "OrganizationFeatures",
        enableNPS?: boolean | null,
        enableBusinessWideReservation?: boolean | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      businesses?:  {
        __typename: "ModelBusinessConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    externalBusiness?:  {
      __typename: "ExternalBusiness",
      id: string,
      organizationId?: string | null,
      type?: ExternalBusinessType | null,
      name?: string | null,
      insuranceProducts?:  Array< {
        __typename: "InsuranceProducts",
        name?: string | null,
        coveragePercentage?: number | null,
        compensatableDays?: number | null,
        billingPermitCode?: string | null,
      } | null > | null,
      insuranceRegionalCompanies?:  Array< {
        __typename: "InsuranceRegionalCompany",
        name?: string | null,
        companyName?: string | null,
        businessId?: string | null,
        address?: string | null,
        postalCode?: string | null,
        postalArea?: string | null,
        email?: string | null,
        innovoiceId?: string | null,
      } | null > | null,
      insuranceCompensatableTimes?: Array< number | null > | null,
      services?:  Array< {
        __typename: "CustomService",
        description?: string | null,
        price?: number | null,
        category?: string | null,
        deductibleValue?: number | null,
      } | null > | null,
      useHansel?: boolean | null,
      hanselId?: string | null,
      availableOrgBusinessIds?: Array< string | null > | null,
      availableCompanyIds?: Array< string | null > | null,
      availableVehicleClasses?: Array< string | null > | null,
      eBillingRef?: string | null,
      eBillingOperator?: string | null,
      eBillingAddress?: string | null,
      createdAt: string,
      updatedAt: string,
      companies?:  {
        __typename: "ModelCompanyConnection",
        nextToken?: string | null,
      } | null,
      pricings?:  {
        __typename: "ModelPricingConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    externalBusinessByOrg?:  {
      __typename: "ModelExternalBusinessConnection",
      items:  Array< {
        __typename: "ExternalBusiness",
        id: string,
        organizationId?: string | null,
        type?: ExternalBusinessType | null,
        name?: string | null,
        insuranceCompensatableTimes?: Array< number | null > | null,
        useHansel?: boolean | null,
        hanselId?: string | null,
        availableOrgBusinessIds?: Array< string | null > | null,
        availableCompanyIds?: Array< string | null > | null,
        availableVehicleClasses?: Array< string | null > | null,
        eBillingRef?: string | null,
        eBillingOperator?: string | null,
        eBillingAddress?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    business?:  {
      __typename: "Business",
      id: string,
      organizationId?: string | null,
      telegramChatId?: string | null,
      telegramConnections?:  Array< {
        __typename: "TelegramInformation",
        chatId?: string | null,
        name?: string | null,
      } | null > | null,
      group?: string | null,
      name?: string | null,
      address?: string | null,
      city?: string | null,
      email?: string | null,
      phone?: string | null,
      insuranceCoverageZone?:  {
        __typename: "PostalCodeRange",
        start?: string | null,
        end?: string | null,
      } | null,
      insuranceCoverageZones?:  Array< {
        __typename: "PostalCodeRange",
        start?: string | null,
        end?: string | null,
      } | null > | null,
      importIdentifier?: string | null,
      categoryDynamicPricings?:  Array< {
        __typename: "CategoryDynamicPricing",
        categoryId?: string | null,
        pricingId?: string | null,
        percentage?: number | null,
      } | null > | null,
      startLocations?:  Array< {
        __typename: "PickupLocation",
        id?: string | null,
        name?: string | null,
        locationColor?: string | null,
        companyId?: string | null,
      } | null > | null,
      pickupLocations?:  Array< {
        __typename: "PickupLocation",
        id?: string | null,
        name?: string | null,
        locationColor?: string | null,
        companyId?: string | null,
      } | null > | null,
      createdAt: string,
      updatedAt: string,
      companies?:  {
        __typename: "ModelCompanyConnection",
        nextToken?: string | null,
      } | null,
      categories?:  {
        __typename: "ModelCategoryConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    businessByOrg?:  {
      __typename: "ModelBusinessConnection",
      items:  Array< {
        __typename: "Business",
        id: string,
        organizationId?: string | null,
        telegramChatId?: string | null,
        group?: string | null,
        name?: string | null,
        address?: string | null,
        city?: string | null,
        email?: string | null,
        phone?: string | null,
        importIdentifier?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type UpdateUserMutationVariables = {
  input: UpdateUserInput,
  condition?: ModelUserConditionInput | null,
};

export type UpdateUserMutation = {
  updateUser?:  {
    __typename: "User",
    id: string,
    organizationId?: string | null,
    orgBusinessId?: string | null,
    orgBusinessIds?: Array< string | null > | null,
    externalBusinessId?: string | null,
    role?: UserRole | null,
    permissions?:  {
      __typename: "Permission",
      userManagement?: boolean | null,
    } | null,
    email?: string | null,
    name?: string | null,
    regionalCompanyName?: string | null,
    createdAt: string,
    updatedAt: string,
    categoriesByOrg?:  {
      __typename: "ModelCategoryConnection",
      items:  Array< {
        __typename: "Category",
        id: string,
        group?: string | null,
        organizationId?: string | null,
        name: string,
        image?: string | null,
        orderNumber?: number | null,
        backgroundColor?: string | null,
        minuteBuffer?: number | null,
        reservationBlockFromNow?: number | null,
        typeId?: string | null,
        _removed?: string | null,
        oldTujausValue?: string | null,
        exampleModelName?: string | null,
        exampleModelImage?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    organization?:  {
      __typename: "Organization",
      id: string,
      name?: string | null,
      billingCustomers?:  Array< {
        __typename: "BillingCustomer",
        name?: string | null,
      } | null > | null,
      features?:  {
        __typename: "OrganizationFeatures",
        enableNPS?: boolean | null,
        enableBusinessWideReservation?: boolean | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      businesses?:  {
        __typename: "ModelBusinessConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    externalBusiness?:  {
      __typename: "ExternalBusiness",
      id: string,
      organizationId?: string | null,
      type?: ExternalBusinessType | null,
      name?: string | null,
      insuranceProducts?:  Array< {
        __typename: "InsuranceProducts",
        name?: string | null,
        coveragePercentage?: number | null,
        compensatableDays?: number | null,
        billingPermitCode?: string | null,
      } | null > | null,
      insuranceRegionalCompanies?:  Array< {
        __typename: "InsuranceRegionalCompany",
        name?: string | null,
        companyName?: string | null,
        businessId?: string | null,
        address?: string | null,
        postalCode?: string | null,
        postalArea?: string | null,
        email?: string | null,
        innovoiceId?: string | null,
      } | null > | null,
      insuranceCompensatableTimes?: Array< number | null > | null,
      services?:  Array< {
        __typename: "CustomService",
        description?: string | null,
        price?: number | null,
        category?: string | null,
        deductibleValue?: number | null,
      } | null > | null,
      useHansel?: boolean | null,
      hanselId?: string | null,
      availableOrgBusinessIds?: Array< string | null > | null,
      availableCompanyIds?: Array< string | null > | null,
      availableVehicleClasses?: Array< string | null > | null,
      eBillingRef?: string | null,
      eBillingOperator?: string | null,
      eBillingAddress?: string | null,
      createdAt: string,
      updatedAt: string,
      companies?:  {
        __typename: "ModelCompanyConnection",
        nextToken?: string | null,
      } | null,
      pricings?:  {
        __typename: "ModelPricingConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    externalBusinessByOrg?:  {
      __typename: "ModelExternalBusinessConnection",
      items:  Array< {
        __typename: "ExternalBusiness",
        id: string,
        organizationId?: string | null,
        type?: ExternalBusinessType | null,
        name?: string | null,
        insuranceCompensatableTimes?: Array< number | null > | null,
        useHansel?: boolean | null,
        hanselId?: string | null,
        availableOrgBusinessIds?: Array< string | null > | null,
        availableCompanyIds?: Array< string | null > | null,
        availableVehicleClasses?: Array< string | null > | null,
        eBillingRef?: string | null,
        eBillingOperator?: string | null,
        eBillingAddress?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    business?:  {
      __typename: "Business",
      id: string,
      organizationId?: string | null,
      telegramChatId?: string | null,
      telegramConnections?:  Array< {
        __typename: "TelegramInformation",
        chatId?: string | null,
        name?: string | null,
      } | null > | null,
      group?: string | null,
      name?: string | null,
      address?: string | null,
      city?: string | null,
      email?: string | null,
      phone?: string | null,
      insuranceCoverageZone?:  {
        __typename: "PostalCodeRange",
        start?: string | null,
        end?: string | null,
      } | null,
      insuranceCoverageZones?:  Array< {
        __typename: "PostalCodeRange",
        start?: string | null,
        end?: string | null,
      } | null > | null,
      importIdentifier?: string | null,
      categoryDynamicPricings?:  Array< {
        __typename: "CategoryDynamicPricing",
        categoryId?: string | null,
        pricingId?: string | null,
        percentage?: number | null,
      } | null > | null,
      startLocations?:  Array< {
        __typename: "PickupLocation",
        id?: string | null,
        name?: string | null,
        locationColor?: string | null,
        companyId?: string | null,
      } | null > | null,
      pickupLocations?:  Array< {
        __typename: "PickupLocation",
        id?: string | null,
        name?: string | null,
        locationColor?: string | null,
        companyId?: string | null,
      } | null > | null,
      createdAt: string,
      updatedAt: string,
      companies?:  {
        __typename: "ModelCompanyConnection",
        nextToken?: string | null,
      } | null,
      categories?:  {
        __typename: "ModelCategoryConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    businessByOrg?:  {
      __typename: "ModelBusinessConnection",
      items:  Array< {
        __typename: "Business",
        id: string,
        organizationId?: string | null,
        telegramChatId?: string | null,
        group?: string | null,
        name?: string | null,
        address?: string | null,
        city?: string | null,
        email?: string | null,
        phone?: string | null,
        importIdentifier?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type DeleteUserMutationVariables = {
  input: DeleteUserInput,
  condition?: ModelUserConditionInput | null,
};

export type DeleteUserMutation = {
  deleteUser?:  {
    __typename: "User",
    id: string,
    organizationId?: string | null,
    orgBusinessId?: string | null,
    orgBusinessIds?: Array< string | null > | null,
    externalBusinessId?: string | null,
    role?: UserRole | null,
    permissions?:  {
      __typename: "Permission",
      userManagement?: boolean | null,
    } | null,
    email?: string | null,
    name?: string | null,
    regionalCompanyName?: string | null,
    createdAt: string,
    updatedAt: string,
    categoriesByOrg?:  {
      __typename: "ModelCategoryConnection",
      items:  Array< {
        __typename: "Category",
        id: string,
        group?: string | null,
        organizationId?: string | null,
        name: string,
        image?: string | null,
        orderNumber?: number | null,
        backgroundColor?: string | null,
        minuteBuffer?: number | null,
        reservationBlockFromNow?: number | null,
        typeId?: string | null,
        _removed?: string | null,
        oldTujausValue?: string | null,
        exampleModelName?: string | null,
        exampleModelImage?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    organization?:  {
      __typename: "Organization",
      id: string,
      name?: string | null,
      billingCustomers?:  Array< {
        __typename: "BillingCustomer",
        name?: string | null,
      } | null > | null,
      features?:  {
        __typename: "OrganizationFeatures",
        enableNPS?: boolean | null,
        enableBusinessWideReservation?: boolean | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      businesses?:  {
        __typename: "ModelBusinessConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    externalBusiness?:  {
      __typename: "ExternalBusiness",
      id: string,
      organizationId?: string | null,
      type?: ExternalBusinessType | null,
      name?: string | null,
      insuranceProducts?:  Array< {
        __typename: "InsuranceProducts",
        name?: string | null,
        coveragePercentage?: number | null,
        compensatableDays?: number | null,
        billingPermitCode?: string | null,
      } | null > | null,
      insuranceRegionalCompanies?:  Array< {
        __typename: "InsuranceRegionalCompany",
        name?: string | null,
        companyName?: string | null,
        businessId?: string | null,
        address?: string | null,
        postalCode?: string | null,
        postalArea?: string | null,
        email?: string | null,
        innovoiceId?: string | null,
      } | null > | null,
      insuranceCompensatableTimes?: Array< number | null > | null,
      services?:  Array< {
        __typename: "CustomService",
        description?: string | null,
        price?: number | null,
        category?: string | null,
        deductibleValue?: number | null,
      } | null > | null,
      useHansel?: boolean | null,
      hanselId?: string | null,
      availableOrgBusinessIds?: Array< string | null > | null,
      availableCompanyIds?: Array< string | null > | null,
      availableVehicleClasses?: Array< string | null > | null,
      eBillingRef?: string | null,
      eBillingOperator?: string | null,
      eBillingAddress?: string | null,
      createdAt: string,
      updatedAt: string,
      companies?:  {
        __typename: "ModelCompanyConnection",
        nextToken?: string | null,
      } | null,
      pricings?:  {
        __typename: "ModelPricingConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    externalBusinessByOrg?:  {
      __typename: "ModelExternalBusinessConnection",
      items:  Array< {
        __typename: "ExternalBusiness",
        id: string,
        organizationId?: string | null,
        type?: ExternalBusinessType | null,
        name?: string | null,
        insuranceCompensatableTimes?: Array< number | null > | null,
        useHansel?: boolean | null,
        hanselId?: string | null,
        availableOrgBusinessIds?: Array< string | null > | null,
        availableCompanyIds?: Array< string | null > | null,
        availableVehicleClasses?: Array< string | null > | null,
        eBillingRef?: string | null,
        eBillingOperator?: string | null,
        eBillingAddress?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    business?:  {
      __typename: "Business",
      id: string,
      organizationId?: string | null,
      telegramChatId?: string | null,
      telegramConnections?:  Array< {
        __typename: "TelegramInformation",
        chatId?: string | null,
        name?: string | null,
      } | null > | null,
      group?: string | null,
      name?: string | null,
      address?: string | null,
      city?: string | null,
      email?: string | null,
      phone?: string | null,
      insuranceCoverageZone?:  {
        __typename: "PostalCodeRange",
        start?: string | null,
        end?: string | null,
      } | null,
      insuranceCoverageZones?:  Array< {
        __typename: "PostalCodeRange",
        start?: string | null,
        end?: string | null,
      } | null > | null,
      importIdentifier?: string | null,
      categoryDynamicPricings?:  Array< {
        __typename: "CategoryDynamicPricing",
        categoryId?: string | null,
        pricingId?: string | null,
        percentage?: number | null,
      } | null > | null,
      startLocations?:  Array< {
        __typename: "PickupLocation",
        id?: string | null,
        name?: string | null,
        locationColor?: string | null,
        companyId?: string | null,
      } | null > | null,
      pickupLocations?:  Array< {
        __typename: "PickupLocation",
        id?: string | null,
        name?: string | null,
        locationColor?: string | null,
        companyId?: string | null,
      } | null > | null,
      createdAt: string,
      updatedAt: string,
      companies?:  {
        __typename: "ModelCompanyConnection",
        nextToken?: string | null,
      } | null,
      categories?:  {
        __typename: "ModelCategoryConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    businessByOrg?:  {
      __typename: "ModelBusinessConnection",
      items:  Array< {
        __typename: "Business",
        id: string,
        organizationId?: string | null,
        telegramChatId?: string | null,
        group?: string | null,
        name?: string | null,
        address?: string | null,
        city?: string | null,
        email?: string | null,
        phone?: string | null,
        importIdentifier?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type CreateOrganizationMutationVariables = {
  input: CreateOrganizationInput,
  condition?: ModelOrganizationConditionInput | null,
};

export type CreateOrganizationMutation = {
  createOrganization?:  {
    __typename: "Organization",
    id: string,
    name?: string | null,
    billingCustomers?:  Array< {
      __typename: "BillingCustomer",
      name?: string | null,
    } | null > | null,
    features?:  {
      __typename: "OrganizationFeatures",
      enableNPS?: boolean | null,
      enableBusinessWideReservation?: boolean | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    businesses?:  {
      __typename: "ModelBusinessConnection",
      items:  Array< {
        __typename: "Business",
        id: string,
        organizationId?: string | null,
        telegramChatId?: string | null,
        group?: string | null,
        name?: string | null,
        address?: string | null,
        city?: string | null,
        email?: string | null,
        phone?: string | null,
        importIdentifier?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type UpdateOrganizationMutationVariables = {
  input: UpdateOrganizationInput,
  condition?: ModelOrganizationConditionInput | null,
};

export type UpdateOrganizationMutation = {
  updateOrganization?:  {
    __typename: "Organization",
    id: string,
    name?: string | null,
    billingCustomers?:  Array< {
      __typename: "BillingCustomer",
      name?: string | null,
    } | null > | null,
    features?:  {
      __typename: "OrganizationFeatures",
      enableNPS?: boolean | null,
      enableBusinessWideReservation?: boolean | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    businesses?:  {
      __typename: "ModelBusinessConnection",
      items:  Array< {
        __typename: "Business",
        id: string,
        organizationId?: string | null,
        telegramChatId?: string | null,
        group?: string | null,
        name?: string | null,
        address?: string | null,
        city?: string | null,
        email?: string | null,
        phone?: string | null,
        importIdentifier?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type DeleteOrganizationMutationVariables = {
  input: DeleteOrganizationInput,
  condition?: ModelOrganizationConditionInput | null,
};

export type DeleteOrganizationMutation = {
  deleteOrganization?:  {
    __typename: "Organization",
    id: string,
    name?: string | null,
    billingCustomers?:  Array< {
      __typename: "BillingCustomer",
      name?: string | null,
    } | null > | null,
    features?:  {
      __typename: "OrganizationFeatures",
      enableNPS?: boolean | null,
      enableBusinessWideReservation?: boolean | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    businesses?:  {
      __typename: "ModelBusinessConnection",
      items:  Array< {
        __typename: "Business",
        id: string,
        organizationId?: string | null,
        telegramChatId?: string | null,
        group?: string | null,
        name?: string | null,
        address?: string | null,
        city?: string | null,
        email?: string | null,
        phone?: string | null,
        importIdentifier?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type CreateExternalBusinessMutationVariables = {
  input: CreateExternalBusinessInput,
  condition?: ModelExternalBusinessConditionInput | null,
};

export type CreateExternalBusinessMutation = {
  createExternalBusiness?:  {
    __typename: "ExternalBusiness",
    id: string,
    organizationId?: string | null,
    type?: ExternalBusinessType | null,
    name?: string | null,
    insuranceProducts?:  Array< {
      __typename: "InsuranceProducts",
      name?: string | null,
      coveragePercentage?: number | null,
      compensatableDays?: number | null,
      billingPermitCode?: string | null,
    } | null > | null,
    insuranceRegionalCompanies?:  Array< {
      __typename: "InsuranceRegionalCompany",
      name?: string | null,
      companyName?: string | null,
      businessId?: string | null,
      address?: string | null,
      postalCode?: string | null,
      postalArea?: string | null,
      email?: string | null,
      innovoiceId?: string | null,
    } | null > | null,
    insuranceCompensatableTimes?: Array< number | null > | null,
    services?:  Array< {
      __typename: "CustomService",
      description?: string | null,
      price?: number | null,
      category?: string | null,
      deductibleValue?: number | null,
    } | null > | null,
    useHansel?: boolean | null,
    hanselId?: string | null,
    availableOrgBusinessIds?: Array< string | null > | null,
    availableCompanyIds?: Array< string | null > | null,
    availableVehicleClasses?: Array< string | null > | null,
    eBillingRef?: string | null,
    eBillingOperator?: string | null,
    eBillingAddress?: string | null,
    createdAt: string,
    updatedAt: string,
    companies?:  {
      __typename: "ModelCompanyConnection",
      items:  Array< {
        __typename: "Company",
        id: string,
        organizationId?: string | null,
        orgBusinessId?: string | null,
        group?: string | null,
        name: string,
        displayName?: string | null,
        email?: string | null,
        address?: string | null,
        postalCode?: string | null,
        city?: string | null,
        orderNumber?: number | null,
        orderNumberDealer?: number | null,
        phone?: string | null,
        businessId?: string | null,
        termsUrl?: string | null,
        timezone: string,
        signConfirmationEmail?: string | null,
        nameInEmail?: string | null,
        senderNameSMS?: string | null,
        confirmationMsg?: string | null,
        feedbackMsg?: string | null,
        statusLinkMsg?: string | null,
        livionDeviceId?: string | null,
        livionDeviceType?: LivionDevice | null,
        enableReservationDeposit?: boolean | null,
        reservationLinkExpirationHours?: number | null,
        disableOffHourReturn?: boolean | null,
        disableMultiday?: boolean | null,
        disableSameDay?: boolean | null,
        disableStartDaysFromNow?: number | null,
        enableCart?: boolean | null,
        enableMonthlyView?: boolean | null,
        enableRegistrationPlate?: boolean | null,
        enableVehicleStartReturnTimes?: boolean | null,
        enableMustSign?: boolean | null,
        enablePaymentOnlineOnly?: boolean | null,
        enableGTM?: boolean | null,
        enableNetPromoterScore?: boolean | null,
        enableNPS?: boolean | null,
        limitReservationOncePerDay?: boolean | null,
        hidden?: boolean | null,
        locationType?: string | null,
        offHourAvailability?: boolean | null,
        offHourAvailabilityFee?: number | null,
        deliveryFee?: number | null,
        deliveryBufferHours?: number | null,
        notAllowedCategoryIds?: Array< string | null > | null,
        importIdentifier?: string | null,
        contractSettingsJSON?: string | null,
        disableReservationCategoryIds?: Array< string | null > | null,
        locationColor?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    pricings?:  {
      __typename: "ModelPricingConnection",
      items:  Array< {
        __typename: "Pricing",
        id: string,
        name: string,
        group?: string | null,
        organizationId?: string | null,
        orgBusinessId?: string | null,
        orgExternalBusinessId?: string | null,
        externalBusinessCategoryAssociation?: string | null,
        businessCategoryAssociation?: string | null,
        useNextDayPrice?: boolean | null,
        useLastDayPrice?: boolean | null,
        tax?: number | null,
        _removed?: string | null,
        deductible?: number | null,
        reducedDeductible?: number | null,
        reducedDeductiblePrice?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type UpdateExternalBusinessMutationVariables = {
  input: UpdateExternalBusinessInput,
  condition?: ModelExternalBusinessConditionInput | null,
};

export type UpdateExternalBusinessMutation = {
  updateExternalBusiness?:  {
    __typename: "ExternalBusiness",
    id: string,
    organizationId?: string | null,
    type?: ExternalBusinessType | null,
    name?: string | null,
    insuranceProducts?:  Array< {
      __typename: "InsuranceProducts",
      name?: string | null,
      coveragePercentage?: number | null,
      compensatableDays?: number | null,
      billingPermitCode?: string | null,
    } | null > | null,
    insuranceRegionalCompanies?:  Array< {
      __typename: "InsuranceRegionalCompany",
      name?: string | null,
      companyName?: string | null,
      businessId?: string | null,
      address?: string | null,
      postalCode?: string | null,
      postalArea?: string | null,
      email?: string | null,
      innovoiceId?: string | null,
    } | null > | null,
    insuranceCompensatableTimes?: Array< number | null > | null,
    services?:  Array< {
      __typename: "CustomService",
      description?: string | null,
      price?: number | null,
      category?: string | null,
      deductibleValue?: number | null,
    } | null > | null,
    useHansel?: boolean | null,
    hanselId?: string | null,
    availableOrgBusinessIds?: Array< string | null > | null,
    availableCompanyIds?: Array< string | null > | null,
    availableVehicleClasses?: Array< string | null > | null,
    eBillingRef?: string | null,
    eBillingOperator?: string | null,
    eBillingAddress?: string | null,
    createdAt: string,
    updatedAt: string,
    companies?:  {
      __typename: "ModelCompanyConnection",
      items:  Array< {
        __typename: "Company",
        id: string,
        organizationId?: string | null,
        orgBusinessId?: string | null,
        group?: string | null,
        name: string,
        displayName?: string | null,
        email?: string | null,
        address?: string | null,
        postalCode?: string | null,
        city?: string | null,
        orderNumber?: number | null,
        orderNumberDealer?: number | null,
        phone?: string | null,
        businessId?: string | null,
        termsUrl?: string | null,
        timezone: string,
        signConfirmationEmail?: string | null,
        nameInEmail?: string | null,
        senderNameSMS?: string | null,
        confirmationMsg?: string | null,
        feedbackMsg?: string | null,
        statusLinkMsg?: string | null,
        livionDeviceId?: string | null,
        livionDeviceType?: LivionDevice | null,
        enableReservationDeposit?: boolean | null,
        reservationLinkExpirationHours?: number | null,
        disableOffHourReturn?: boolean | null,
        disableMultiday?: boolean | null,
        disableSameDay?: boolean | null,
        disableStartDaysFromNow?: number | null,
        enableCart?: boolean | null,
        enableMonthlyView?: boolean | null,
        enableRegistrationPlate?: boolean | null,
        enableVehicleStartReturnTimes?: boolean | null,
        enableMustSign?: boolean | null,
        enablePaymentOnlineOnly?: boolean | null,
        enableGTM?: boolean | null,
        enableNetPromoterScore?: boolean | null,
        enableNPS?: boolean | null,
        limitReservationOncePerDay?: boolean | null,
        hidden?: boolean | null,
        locationType?: string | null,
        offHourAvailability?: boolean | null,
        offHourAvailabilityFee?: number | null,
        deliveryFee?: number | null,
        deliveryBufferHours?: number | null,
        notAllowedCategoryIds?: Array< string | null > | null,
        importIdentifier?: string | null,
        contractSettingsJSON?: string | null,
        disableReservationCategoryIds?: Array< string | null > | null,
        locationColor?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    pricings?:  {
      __typename: "ModelPricingConnection",
      items:  Array< {
        __typename: "Pricing",
        id: string,
        name: string,
        group?: string | null,
        organizationId?: string | null,
        orgBusinessId?: string | null,
        orgExternalBusinessId?: string | null,
        externalBusinessCategoryAssociation?: string | null,
        businessCategoryAssociation?: string | null,
        useNextDayPrice?: boolean | null,
        useLastDayPrice?: boolean | null,
        tax?: number | null,
        _removed?: string | null,
        deductible?: number | null,
        reducedDeductible?: number | null,
        reducedDeductiblePrice?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type DeleteExternalBusinessMutationVariables = {
  input: DeleteExternalBusinessInput,
  condition?: ModelExternalBusinessConditionInput | null,
};

export type DeleteExternalBusinessMutation = {
  deleteExternalBusiness?:  {
    __typename: "ExternalBusiness",
    id: string,
    organizationId?: string | null,
    type?: ExternalBusinessType | null,
    name?: string | null,
    insuranceProducts?:  Array< {
      __typename: "InsuranceProducts",
      name?: string | null,
      coveragePercentage?: number | null,
      compensatableDays?: number | null,
      billingPermitCode?: string | null,
    } | null > | null,
    insuranceRegionalCompanies?:  Array< {
      __typename: "InsuranceRegionalCompany",
      name?: string | null,
      companyName?: string | null,
      businessId?: string | null,
      address?: string | null,
      postalCode?: string | null,
      postalArea?: string | null,
      email?: string | null,
      innovoiceId?: string | null,
    } | null > | null,
    insuranceCompensatableTimes?: Array< number | null > | null,
    services?:  Array< {
      __typename: "CustomService",
      description?: string | null,
      price?: number | null,
      category?: string | null,
      deductibleValue?: number | null,
    } | null > | null,
    useHansel?: boolean | null,
    hanselId?: string | null,
    availableOrgBusinessIds?: Array< string | null > | null,
    availableCompanyIds?: Array< string | null > | null,
    availableVehicleClasses?: Array< string | null > | null,
    eBillingRef?: string | null,
    eBillingOperator?: string | null,
    eBillingAddress?: string | null,
    createdAt: string,
    updatedAt: string,
    companies?:  {
      __typename: "ModelCompanyConnection",
      items:  Array< {
        __typename: "Company",
        id: string,
        organizationId?: string | null,
        orgBusinessId?: string | null,
        group?: string | null,
        name: string,
        displayName?: string | null,
        email?: string | null,
        address?: string | null,
        postalCode?: string | null,
        city?: string | null,
        orderNumber?: number | null,
        orderNumberDealer?: number | null,
        phone?: string | null,
        businessId?: string | null,
        termsUrl?: string | null,
        timezone: string,
        signConfirmationEmail?: string | null,
        nameInEmail?: string | null,
        senderNameSMS?: string | null,
        confirmationMsg?: string | null,
        feedbackMsg?: string | null,
        statusLinkMsg?: string | null,
        livionDeviceId?: string | null,
        livionDeviceType?: LivionDevice | null,
        enableReservationDeposit?: boolean | null,
        reservationLinkExpirationHours?: number | null,
        disableOffHourReturn?: boolean | null,
        disableMultiday?: boolean | null,
        disableSameDay?: boolean | null,
        disableStartDaysFromNow?: number | null,
        enableCart?: boolean | null,
        enableMonthlyView?: boolean | null,
        enableRegistrationPlate?: boolean | null,
        enableVehicleStartReturnTimes?: boolean | null,
        enableMustSign?: boolean | null,
        enablePaymentOnlineOnly?: boolean | null,
        enableGTM?: boolean | null,
        enableNetPromoterScore?: boolean | null,
        enableNPS?: boolean | null,
        limitReservationOncePerDay?: boolean | null,
        hidden?: boolean | null,
        locationType?: string | null,
        offHourAvailability?: boolean | null,
        offHourAvailabilityFee?: number | null,
        deliveryFee?: number | null,
        deliveryBufferHours?: number | null,
        notAllowedCategoryIds?: Array< string | null > | null,
        importIdentifier?: string | null,
        contractSettingsJSON?: string | null,
        disableReservationCategoryIds?: Array< string | null > | null,
        locationColor?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    pricings?:  {
      __typename: "ModelPricingConnection",
      items:  Array< {
        __typename: "Pricing",
        id: string,
        name: string,
        group?: string | null,
        organizationId?: string | null,
        orgBusinessId?: string | null,
        orgExternalBusinessId?: string | null,
        externalBusinessCategoryAssociation?: string | null,
        businessCategoryAssociation?: string | null,
        useNextDayPrice?: boolean | null,
        useLastDayPrice?: boolean | null,
        tax?: number | null,
        _removed?: string | null,
        deductible?: number | null,
        reducedDeductible?: number | null,
        reducedDeductiblePrice?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type CreateBusinessMutationVariables = {
  input: CreateBusinessInput,
  condition?: ModelBusinessConditionInput | null,
};

export type CreateBusinessMutation = {
  createBusiness?:  {
    __typename: "Business",
    id: string,
    organizationId?: string | null,
    telegramChatId?: string | null,
    telegramConnections?:  Array< {
      __typename: "TelegramInformation",
      chatId?: string | null,
      name?: string | null,
    } | null > | null,
    group?: string | null,
    name?: string | null,
    address?: string | null,
    city?: string | null,
    email?: string | null,
    phone?: string | null,
    insuranceCoverageZone?:  {
      __typename: "PostalCodeRange",
      start?: string | null,
      end?: string | null,
    } | null,
    insuranceCoverageZones?:  Array< {
      __typename: "PostalCodeRange",
      start?: string | null,
      end?: string | null,
    } | null > | null,
    importIdentifier?: string | null,
    categoryDynamicPricings?:  Array< {
      __typename: "CategoryDynamicPricing",
      categoryId?: string | null,
      pricingId?: string | null,
      percentage?: number | null,
    } | null > | null,
    startLocations?:  Array< {
      __typename: "PickupLocation",
      id?: string | null,
      name?: string | null,
      locationColor?: string | null,
      companyId?: string | null,
    } | null > | null,
    pickupLocations?:  Array< {
      __typename: "PickupLocation",
      id?: string | null,
      name?: string | null,
      locationColor?: string | null,
      companyId?: string | null,
    } | null > | null,
    createdAt: string,
    updatedAt: string,
    companies?:  {
      __typename: "ModelCompanyConnection",
      items:  Array< {
        __typename: "Company",
        id: string,
        organizationId?: string | null,
        orgBusinessId?: string | null,
        group?: string | null,
        name: string,
        displayName?: string | null,
        email?: string | null,
        address?: string | null,
        postalCode?: string | null,
        city?: string | null,
        orderNumber?: number | null,
        orderNumberDealer?: number | null,
        phone?: string | null,
        businessId?: string | null,
        termsUrl?: string | null,
        timezone: string,
        signConfirmationEmail?: string | null,
        nameInEmail?: string | null,
        senderNameSMS?: string | null,
        confirmationMsg?: string | null,
        feedbackMsg?: string | null,
        statusLinkMsg?: string | null,
        livionDeviceId?: string | null,
        livionDeviceType?: LivionDevice | null,
        enableReservationDeposit?: boolean | null,
        reservationLinkExpirationHours?: number | null,
        disableOffHourReturn?: boolean | null,
        disableMultiday?: boolean | null,
        disableSameDay?: boolean | null,
        disableStartDaysFromNow?: number | null,
        enableCart?: boolean | null,
        enableMonthlyView?: boolean | null,
        enableRegistrationPlate?: boolean | null,
        enableVehicleStartReturnTimes?: boolean | null,
        enableMustSign?: boolean | null,
        enablePaymentOnlineOnly?: boolean | null,
        enableGTM?: boolean | null,
        enableNetPromoterScore?: boolean | null,
        enableNPS?: boolean | null,
        limitReservationOncePerDay?: boolean | null,
        hidden?: boolean | null,
        locationType?: string | null,
        offHourAvailability?: boolean | null,
        offHourAvailabilityFee?: number | null,
        deliveryFee?: number | null,
        deliveryBufferHours?: number | null,
        notAllowedCategoryIds?: Array< string | null > | null,
        importIdentifier?: string | null,
        contractSettingsJSON?: string | null,
        disableReservationCategoryIds?: Array< string | null > | null,
        locationColor?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    categories?:  {
      __typename: "ModelCategoryConnection",
      items:  Array< {
        __typename: "Category",
        id: string,
        group?: string | null,
        organizationId?: string | null,
        name: string,
        image?: string | null,
        orderNumber?: number | null,
        backgroundColor?: string | null,
        minuteBuffer?: number | null,
        reservationBlockFromNow?: number | null,
        typeId?: string | null,
        _removed?: string | null,
        oldTujausValue?: string | null,
        exampleModelName?: string | null,
        exampleModelImage?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type UpdateBusinessMutationVariables = {
  input: UpdateBusinessInput,
  condition?: ModelBusinessConditionInput | null,
};

export type UpdateBusinessMutation = {
  updateBusiness?:  {
    __typename: "Business",
    id: string,
    organizationId?: string | null,
    telegramChatId?: string | null,
    telegramConnections?:  Array< {
      __typename: "TelegramInformation",
      chatId?: string | null,
      name?: string | null,
    } | null > | null,
    group?: string | null,
    name?: string | null,
    address?: string | null,
    city?: string | null,
    email?: string | null,
    phone?: string | null,
    insuranceCoverageZone?:  {
      __typename: "PostalCodeRange",
      start?: string | null,
      end?: string | null,
    } | null,
    insuranceCoverageZones?:  Array< {
      __typename: "PostalCodeRange",
      start?: string | null,
      end?: string | null,
    } | null > | null,
    importIdentifier?: string | null,
    categoryDynamicPricings?:  Array< {
      __typename: "CategoryDynamicPricing",
      categoryId?: string | null,
      pricingId?: string | null,
      percentage?: number | null,
    } | null > | null,
    startLocations?:  Array< {
      __typename: "PickupLocation",
      id?: string | null,
      name?: string | null,
      locationColor?: string | null,
      companyId?: string | null,
    } | null > | null,
    pickupLocations?:  Array< {
      __typename: "PickupLocation",
      id?: string | null,
      name?: string | null,
      locationColor?: string | null,
      companyId?: string | null,
    } | null > | null,
    createdAt: string,
    updatedAt: string,
    companies?:  {
      __typename: "ModelCompanyConnection",
      items:  Array< {
        __typename: "Company",
        id: string,
        organizationId?: string | null,
        orgBusinessId?: string | null,
        group?: string | null,
        name: string,
        displayName?: string | null,
        email?: string | null,
        address?: string | null,
        postalCode?: string | null,
        city?: string | null,
        orderNumber?: number | null,
        orderNumberDealer?: number | null,
        phone?: string | null,
        businessId?: string | null,
        termsUrl?: string | null,
        timezone: string,
        signConfirmationEmail?: string | null,
        nameInEmail?: string | null,
        senderNameSMS?: string | null,
        confirmationMsg?: string | null,
        feedbackMsg?: string | null,
        statusLinkMsg?: string | null,
        livionDeviceId?: string | null,
        livionDeviceType?: LivionDevice | null,
        enableReservationDeposit?: boolean | null,
        reservationLinkExpirationHours?: number | null,
        disableOffHourReturn?: boolean | null,
        disableMultiday?: boolean | null,
        disableSameDay?: boolean | null,
        disableStartDaysFromNow?: number | null,
        enableCart?: boolean | null,
        enableMonthlyView?: boolean | null,
        enableRegistrationPlate?: boolean | null,
        enableVehicleStartReturnTimes?: boolean | null,
        enableMustSign?: boolean | null,
        enablePaymentOnlineOnly?: boolean | null,
        enableGTM?: boolean | null,
        enableNetPromoterScore?: boolean | null,
        enableNPS?: boolean | null,
        limitReservationOncePerDay?: boolean | null,
        hidden?: boolean | null,
        locationType?: string | null,
        offHourAvailability?: boolean | null,
        offHourAvailabilityFee?: number | null,
        deliveryFee?: number | null,
        deliveryBufferHours?: number | null,
        notAllowedCategoryIds?: Array< string | null > | null,
        importIdentifier?: string | null,
        contractSettingsJSON?: string | null,
        disableReservationCategoryIds?: Array< string | null > | null,
        locationColor?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    categories?:  {
      __typename: "ModelCategoryConnection",
      items:  Array< {
        __typename: "Category",
        id: string,
        group?: string | null,
        organizationId?: string | null,
        name: string,
        image?: string | null,
        orderNumber?: number | null,
        backgroundColor?: string | null,
        minuteBuffer?: number | null,
        reservationBlockFromNow?: number | null,
        typeId?: string | null,
        _removed?: string | null,
        oldTujausValue?: string | null,
        exampleModelName?: string | null,
        exampleModelImage?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type DeleteBusinessMutationVariables = {
  input: DeleteBusinessInput,
  condition?: ModelBusinessConditionInput | null,
};

export type DeleteBusinessMutation = {
  deleteBusiness?:  {
    __typename: "Business",
    id: string,
    organizationId?: string | null,
    telegramChatId?: string | null,
    telegramConnections?:  Array< {
      __typename: "TelegramInformation",
      chatId?: string | null,
      name?: string | null,
    } | null > | null,
    group?: string | null,
    name?: string | null,
    address?: string | null,
    city?: string | null,
    email?: string | null,
    phone?: string | null,
    insuranceCoverageZone?:  {
      __typename: "PostalCodeRange",
      start?: string | null,
      end?: string | null,
    } | null,
    insuranceCoverageZones?:  Array< {
      __typename: "PostalCodeRange",
      start?: string | null,
      end?: string | null,
    } | null > | null,
    importIdentifier?: string | null,
    categoryDynamicPricings?:  Array< {
      __typename: "CategoryDynamicPricing",
      categoryId?: string | null,
      pricingId?: string | null,
      percentage?: number | null,
    } | null > | null,
    startLocations?:  Array< {
      __typename: "PickupLocation",
      id?: string | null,
      name?: string | null,
      locationColor?: string | null,
      companyId?: string | null,
    } | null > | null,
    pickupLocations?:  Array< {
      __typename: "PickupLocation",
      id?: string | null,
      name?: string | null,
      locationColor?: string | null,
      companyId?: string | null,
    } | null > | null,
    createdAt: string,
    updatedAt: string,
    companies?:  {
      __typename: "ModelCompanyConnection",
      items:  Array< {
        __typename: "Company",
        id: string,
        organizationId?: string | null,
        orgBusinessId?: string | null,
        group?: string | null,
        name: string,
        displayName?: string | null,
        email?: string | null,
        address?: string | null,
        postalCode?: string | null,
        city?: string | null,
        orderNumber?: number | null,
        orderNumberDealer?: number | null,
        phone?: string | null,
        businessId?: string | null,
        termsUrl?: string | null,
        timezone: string,
        signConfirmationEmail?: string | null,
        nameInEmail?: string | null,
        senderNameSMS?: string | null,
        confirmationMsg?: string | null,
        feedbackMsg?: string | null,
        statusLinkMsg?: string | null,
        livionDeviceId?: string | null,
        livionDeviceType?: LivionDevice | null,
        enableReservationDeposit?: boolean | null,
        reservationLinkExpirationHours?: number | null,
        disableOffHourReturn?: boolean | null,
        disableMultiday?: boolean | null,
        disableSameDay?: boolean | null,
        disableStartDaysFromNow?: number | null,
        enableCart?: boolean | null,
        enableMonthlyView?: boolean | null,
        enableRegistrationPlate?: boolean | null,
        enableVehicleStartReturnTimes?: boolean | null,
        enableMustSign?: boolean | null,
        enablePaymentOnlineOnly?: boolean | null,
        enableGTM?: boolean | null,
        enableNetPromoterScore?: boolean | null,
        enableNPS?: boolean | null,
        limitReservationOncePerDay?: boolean | null,
        hidden?: boolean | null,
        locationType?: string | null,
        offHourAvailability?: boolean | null,
        offHourAvailabilityFee?: number | null,
        deliveryFee?: number | null,
        deliveryBufferHours?: number | null,
        notAllowedCategoryIds?: Array< string | null > | null,
        importIdentifier?: string | null,
        contractSettingsJSON?: string | null,
        disableReservationCategoryIds?: Array< string | null > | null,
        locationColor?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    categories?:  {
      __typename: "ModelCategoryConnection",
      items:  Array< {
        __typename: "Category",
        id: string,
        group?: string | null,
        organizationId?: string | null,
        name: string,
        image?: string | null,
        orderNumber?: number | null,
        backgroundColor?: string | null,
        minuteBuffer?: number | null,
        reservationBlockFromNow?: number | null,
        typeId?: string | null,
        _removed?: string | null,
        oldTujausValue?: string | null,
        exampleModelName?: string | null,
        exampleModelImage?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type CreateArchiveReservationMutationVariables = {
  input: CreateArchiveReservationInput,
  condition?: ModelArchiveReservationConditionInput | null,
};

export type CreateArchiveReservationMutation = {
  createArchiveReservation?:  {
    __typename: "ArchiveReservation",
    id: string,
    organizationId: string,
    orgBusinessId: string,
    reservationJSON: string,
    archiveMessage?: string | null,
    archivedAt: string,
    archivedByUserId: string,
    startTime?: string | null,
    returnTime?: string | null,
    reservationCreatedAt?: string | null,
    customerBrokerId?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateArchiveReservationMutationVariables = {
  input: UpdateArchiveReservationInput,
  condition?: ModelArchiveReservationConditionInput | null,
};

export type UpdateArchiveReservationMutation = {
  updateArchiveReservation?:  {
    __typename: "ArchiveReservation",
    id: string,
    organizationId: string,
    orgBusinessId: string,
    reservationJSON: string,
    archiveMessage?: string | null,
    archivedAt: string,
    archivedByUserId: string,
    startTime?: string | null,
    returnTime?: string | null,
    reservationCreatedAt?: string | null,
    customerBrokerId?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteArchiveReservationMutationVariables = {
  input: DeleteArchiveReservationInput,
  condition?: ModelArchiveReservationConditionInput | null,
};

export type DeleteArchiveReservationMutation = {
  deleteArchiveReservation?:  {
    __typename: "ArchiveReservation",
    id: string,
    organizationId: string,
    orgBusinessId: string,
    reservationJSON: string,
    archiveMessage?: string | null,
    archivedAt: string,
    archivedByUserId: string,
    startTime?: string | null,
    returnTime?: string | null,
    reservationCreatedAt?: string | null,
    customerBrokerId?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateReservationMutationVariables = {
  input: CreateReservationInput,
  condition?: ModelReservationConditionInput | null,
};

export type CreateReservationMutation = {
  createReservation?:  {
    __typename: "Reservation",
    id: string,
    shortId?: string | null,
    language?: Language | null,
    group?: string | null,
    organizationId?: string | null,
    orgBusinessId?: string | null,
    orgExternalBusinessId?: string | null,
    companyId?: string | null,
    returnCompanyId?: string | null,
    startLocation?: string | null,
    returnLocation?: string | null,
    startLocationID?: string | null,
    returnLocationID?: string | null,
    reviewId?: string | null,
    customerBrokerId?: string | null,
    customerCompanyId?: string | null,
    customerPersonalId?: string | null,
    reservationVehicleId?: string | null,
    reservationVehicles?:  Array< {
      __typename: "ReservationVehicle",
      id?: string | null,
      price?: number | null,
      tax?: number | null,
      name?: string | null,
      registrationPlate?: string | null,
      deductible?: number | null,
      includedDistance?: string | null,
      powerType?: string | null,
      mileage?: string | null,
      startMileage?: string | null,
      endMileage?: string | null,
      extraInfo?: string | null,
      damages?: string | null,
      hideExport?: boolean | null,
      createdAt?: string | null,
      addedAt?: string | null,
      categoryName?: string | null,
    } | null > | null,
    wildCardVehicles?:  Array< {
      __typename: "WildCardVehicle",
      name?: string | null,
      price?: number | null,
      tax?: number | null,
      categoryId?: string | null,
      quantity?: number | null,
      deductible?: number | null,
    } | null > | null,
    createdAt: string,
    updatedAt: string,
    startTime?: string | null,
    returnTime?: string | null,
    endTime?: string | null,
    name?: string | null,
    address?: string | null,
    postalCode?: string | null,
    city?: string | null,
    phone?: string | null,
    email?: string | null,
    billingRef?: string | null,
    customerNotes?: string | null,
    ssn?: string | null,
    rentalNotes?: string | null,
    internalNotes?: string | null,
    externalNotes?: string | null,
    outOfCommission?: boolean | null,
    vehiclePrice?: number | null,
    additionalServices?:  Array< {
      __typename: "AdditionalServiceReservation",
      key: string,
      price: number,
      details?: string | null,
      quantity?: number | null,
      type?: ServiceType | null,
      deductibleValue?: number | null,
      groupFields?: Array< string | null > | null,
      tax?: number | null,
      enableNotification?: boolean | null,
      description?: string | null,
      category?: string | null,
    } > | null,
    driverSSN?: string | null,
    differentDriver?: boolean | null,
    driverName?: string | null,
    driverAddress?: string | null,
    driverPostalCode?: string | null,
    driverCity?: string | null,
    driverPhone?: string | null,
    driverEmail?: string | null,
    additionalDriver?: boolean | null,
    additionalDriverName?: string | null,
    additionalDriverPhone?: string | null,
    additionalDriverSSN?: string | null,
    billPayment?: boolean | null,
    isCompany?: boolean | null,
    carStatus?: RentalStatus | null,
    rentalStatus?: RentalStatus | null,
    innovoiceStatus?: ReservationInnovoiceStatus | null,
    companyName?: string | null,
    companyBusinessId?: string | null,
    companyCity?: string | null,
    companyPostalCode?: string | null,
    companyBillingRef?: string | null,
    companyBillingAddress?: string | null,
    companyEBillingOperator?: string | null,
    companyEBillingAddress?: string | null,
    paymentToken?: string | null,
    npsStatus?: NpsStatus | null,
    paymentStatus?: PaymentStatus | null,
    paymentData?:  {
      __typename: "PaymentData",
      paymentTotal?: number | null,
      paymentTodo?: number | null,
      depositPending?: boolean | null,
      deposit?: number | null,
    } | null,
    signStatus?: SignStatus | null,
    signDocumentId?: string | null,
    signerName?: string | null,
    reviewStatus?: ReviewStatus | null,
    authStatus?: AuthStatus | null,
    authTime?: string | null,
    completeBefore?: string | null,
    mustSign?: boolean | null,
    livionData?:  Array< {
      __typename: "LivionData",
      id?: string | null,
      pincode?: string | null,
      keyId?: string | null,
      name?: string | null,
      registrationPlate?: string | null,
    } | null > | null,
    tilakoneData?:  {
      __typename: "TilakoneData",
      access_id?: string | null,
      begin_timestamp?: string | null,
      end_timestamp?: string | null,
      pincode?: string | null,
    } | null,
    voucherData?:  {
      __typename: "VoucherData",
      name: string,
      code: string,
      amount: number,
    } | null,
    customerCarRegistrationPlate?: string | null,
    insuranceData?:  {
      __typename: "InsuranceData",
      customerContact?: boolean | null,
      urgent?: boolean | null,
      customerCarModel?: string | null,
      customerCarMaker?: string | null,
      product?: string | null,
      coveragePercentage?: number | null,
      compensatableDays?: string | null,
      billingPermitCode?: string | null,
      coverageDuration?: number | null,
      providerCompany?: string | null,
      providerBusinessId?: string | null,
      claimAdjusterName?: string | null,
      claimAdjusterEmail?: string | null,
      receipt?: boolean | null,
      replacementVehicleClass?: string | null,
      claimId?: string | null,
      vatDeductible?: boolean | null,
      deliveryCosts?: boolean | null,
      returnCosts?: boolean | null,
    } | null,
    externalData?:  {
      __typename: "ExternalData",
      orderId?: string | null,
      orderReference?: string | null,
      serviceAgreementName?: string | null,
      operatorName?: string | null,
      orderInfo?: string | null,
      billingPayer?: string | null,
      billingDescription?: string | null,
      customerVehicle?:  {
        __typename: "CustomerVehicle",
        brand?: string | null,
        model?: string | null,
        registrationPlate?: string | null,
      } | null,
      incidentLocation?:  {
        __typename: "Location",
        description?: string | null,
        address?: string | null,
        city?: string | null,
        zipCode?: string | null,
      } | null,
      repairShop?:  {
        __typename: "Location",
        description?: string | null,
        address?: string | null,
        city?: string | null,
        zipCode?: string | null,
      } | null,
    } | null,
    enableRegistrationPlate?: boolean | null,
    disablePricesInPrintouts?: boolean | null,
    hanselData?:  {
      __typename: "HanselData",
      contractNro?: string | null,
      customerInvoiceId?: string | null,
      customerInvoiceDate?: string | null,
      hanselAgent?: string | null,
      hanselClass?: string | null,
      customerId?: string | null,
      subContractorNumber?: string | null,
      subContractorName?: string | null,
      suplierProductId?: string | null,
      suplierProductName?: string | null,
      quantity?: string | null,
      price?: string | null,
      totalPrice?: string | null,
      priceNoVAT?: string | null,
      additionalInfo1?: string | null,
      additionalInfo2?: string | null,
      additionalInfo3?: string | null,
      desc?: string | null,
    } | null,
    invoiceIds?: Array< string > | null,
    consolidatedInvoiceIds?: Array< string > | null,
    channel?: ReservationChannel | null,
    originURL?: string | null,
    type?: ReservationType | null,
    status?: string | null,
    reservationStatusInfo?:  {
      __typename: "ReservationStatusInfo",
      deliveryTimestamp?: string | null,
      deliveryAddress?: string | null,
      deliveryZipcode?: string | null,
      deliveryCity?: string | null,
      deliveryCountry?: string | null,
      deliveryDescription?: string | null,
      returnTimestamp?: string | null,
      returnAddress?: string | null,
      returnZipcode?: string | null,
      returnCity?: string | null,
      returnCountry?: string | null,
      returnDescription?: string | null,
      cancelReason?: string | null,
    } | null,
    initialCategory?:  {
      __typename: "CategoryInfo",
      id?: string | null,
      name?: string | null,
      hanselClass?: string | null,
    } | null,
    initialClassInfo?:  {
      __typename: "InitialClassInfo",
      categoryId?: string | null,
      categoryName?: string | null,
      hanselClass?: string | null,
    } | null,
    orgInvoiceCustomer?:  {
      __typename: "OrganizationInvoiceCustomer",
      name?: string | null,
      businessId?: string | null,
    } | null,
    pricingCategoryId?: string | null,
    customImportDate?: string | null,
    vehicle?:  {
      __typename: "Vehicle",
      id: string,
      group?: string | null,
      organizationId?: string | null,
      orgBusinessId?: string | null,
      companyId: string,
      name: string,
      nameT?:  {
        __typename: "Translation",
        fi?: string | null,
        en?: string | null,
        sv?: string | null,
      } | null,
      registrationPlate?: string | null,
      damages?: string | null,
      damageRecords?:  Array< {
        __typename: "DamageRecord",
        normalizedX?: number | null,
        normalizedY?: number | null,
        label?: string | null,
        description?: string | null,
        createdAt?: string | null,
      } > | null,
      additionalFields:  Array< {
        __typename: "AdditionalField",
        key: string,
        value?: string | null,
      } >,
      image?: string | null,
      powerType?: string | null,
      deductible?: number | null,
      includedDistance?: string | null,
      mileage?: string | null,
      orderNumber?: number | null,
      vehicleCategoryId?: string | null,
      useCategoryDefaults?: boolean | null,
      useStartReturnTimes?: boolean | null,
      customStartReturns?:  Array< {
        __typename: "CustomStartReturn",
        priority?: number | null,
      } | null > | null,
      startReturnInterval?:  {
        __typename: "StartReturnInterval",
        start?: string | null,
        end?: string | null,
      } | null,
      startReturnTimes?:  Array< {
        __typename: "StartReturnTimes",
        start?: Array< string | null > | null,
        return?: Array< string | null > | null,
      } | null > | null,
      hideExport?: boolean | null,
      extraInfo?: string | null,
      extraInfoT?: string | null,
      extraToConfirmation?: string | null,
      extraToConfirmationT?: string | null,
      livionKeyId?: string | null,
      phoneNumber?: string | null,
      importData?: string | null,
      disableOnline?: boolean | null,
      activeRange?:  {
        __typename: "StartReturnInterval",
        start?: string | null,
        end?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      category?:  {
        __typename: "Category",
        id: string,
        group?: string | null,
        organizationId?: string | null,
        name: string,
        image?: string | null,
        orderNumber?: number | null,
        backgroundColor?: string | null,
        minuteBuffer?: number | null,
        reservationBlockFromNow?: number | null,
        typeId?: string | null,
        _removed?: string | null,
        oldTujausValue?: string | null,
        exampleModelName?: string | null,
        exampleModelImage?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      additionalServices?:  {
        __typename: "ModelAdditionalServicePricingConnection",
        nextToken?: string | null,
      } | null,
      reservations?:  {
        __typename: "ModelReservationConnection",
        nextToken?: string | null,
      } | null,
      pricing?:  {
        __typename: "Pricing",
        id: string,
        name: string,
        group?: string | null,
        organizationId?: string | null,
        orgBusinessId?: string | null,
        orgExternalBusinessId?: string | null,
        externalBusinessCategoryAssociation?: string | null,
        businessCategoryAssociation?: string | null,
        useNextDayPrice?: boolean | null,
        useLastDayPrice?: boolean | null,
        tax?: number | null,
        _removed?: string | null,
        deductible?: number | null,
        reducedDeductible?: number | null,
        reducedDeductiblePrice?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null,
    } | null,
    invoices?:  {
      __typename: "ModelInvoiceConnection",
      items:  Array< {
        __typename: "Invoice",
        id: string,
        createdAt: string,
        innovoiceId?: string | null,
        reservationId?: string | null,
        reservationIds?: Array< string > | null,
        companyId?: string | null,
        innovoiceCompanyId?: string | null,
        innovoiceuRentCarRentalId?: string | null,
        innovoiceOrderId?: string | null,
        group?: string | null,
        organizationId?: string | null,
        orgBusinessId?: string | null,
        type?: InvoicePaymentType | null,
        creditNoteInvoiceId?: string | null,
        payment_terms?: number | null,
        vat_included?: number | null,
        delivery_by: InvoiceDeliveryBy,
        invoice_type: InvoiceType,
        delivery_date?: string | null,
        seller_reference?: string | null,
        buyer_reference?: string | null,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    paymentEvents?:  {
      __typename: "ModelPaymentEventConnection",
      items:  Array< {
        __typename: "PaymentEvent",
        id: string,
        channel?: string | null,
        paymentMethod?: string | null,
        paymentTotal?: number | null,
        isDeleted?: boolean | null,
        deletedBy?: string | null,
        deletedAt?: string | null,
        createdByUserID?: string | null,
        createdByUserName?: string | null,
        reservationId?: string | null,
        organizationId?: string | null,
        orgBusinessId?: string | null,
        group?: string | null,
        msg?: string | null,
        state?: PaymentEventState | null,
        returnCode?: number | null,
        incidentId?: string | null,
        parameters?: string | null,
        apiPath?: string | null,
        paymentStatus?: string | null,
        orderNumber?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type UpdateReservationMutationVariables = {
  input: UpdateReservationInput,
  condition?: ModelReservationConditionInput | null,
};

export type UpdateReservationMutation = {
  updateReservation?:  {
    __typename: "Reservation",
    id: string,
    shortId?: string | null,
    language?: Language | null,
    group?: string | null,
    organizationId?: string | null,
    orgBusinessId?: string | null,
    orgExternalBusinessId?: string | null,
    companyId?: string | null,
    returnCompanyId?: string | null,
    startLocation?: string | null,
    returnLocation?: string | null,
    startLocationID?: string | null,
    returnLocationID?: string | null,
    reviewId?: string | null,
    customerBrokerId?: string | null,
    customerCompanyId?: string | null,
    customerPersonalId?: string | null,
    reservationVehicleId?: string | null,
    reservationVehicles?:  Array< {
      __typename: "ReservationVehicle",
      id?: string | null,
      price?: number | null,
      tax?: number | null,
      name?: string | null,
      registrationPlate?: string | null,
      deductible?: number | null,
      includedDistance?: string | null,
      powerType?: string | null,
      mileage?: string | null,
      startMileage?: string | null,
      endMileage?: string | null,
      extraInfo?: string | null,
      damages?: string | null,
      hideExport?: boolean | null,
      createdAt?: string | null,
      addedAt?: string | null,
      categoryName?: string | null,
    } | null > | null,
    wildCardVehicles?:  Array< {
      __typename: "WildCardVehicle",
      name?: string | null,
      price?: number | null,
      tax?: number | null,
      categoryId?: string | null,
      quantity?: number | null,
      deductible?: number | null,
    } | null > | null,
    createdAt: string,
    updatedAt: string,
    startTime?: string | null,
    returnTime?: string | null,
    endTime?: string | null,
    name?: string | null,
    address?: string | null,
    postalCode?: string | null,
    city?: string | null,
    phone?: string | null,
    email?: string | null,
    billingRef?: string | null,
    customerNotes?: string | null,
    ssn?: string | null,
    rentalNotes?: string | null,
    internalNotes?: string | null,
    externalNotes?: string | null,
    outOfCommission?: boolean | null,
    vehiclePrice?: number | null,
    additionalServices?:  Array< {
      __typename: "AdditionalServiceReservation",
      key: string,
      price: number,
      details?: string | null,
      quantity?: number | null,
      type?: ServiceType | null,
      deductibleValue?: number | null,
      groupFields?: Array< string | null > | null,
      tax?: number | null,
      enableNotification?: boolean | null,
      description?: string | null,
      category?: string | null,
    } > | null,
    driverSSN?: string | null,
    differentDriver?: boolean | null,
    driverName?: string | null,
    driverAddress?: string | null,
    driverPostalCode?: string | null,
    driverCity?: string | null,
    driverPhone?: string | null,
    driverEmail?: string | null,
    additionalDriver?: boolean | null,
    additionalDriverName?: string | null,
    additionalDriverPhone?: string | null,
    additionalDriverSSN?: string | null,
    billPayment?: boolean | null,
    isCompany?: boolean | null,
    carStatus?: RentalStatus | null,
    rentalStatus?: RentalStatus | null,
    innovoiceStatus?: ReservationInnovoiceStatus | null,
    companyName?: string | null,
    companyBusinessId?: string | null,
    companyCity?: string | null,
    companyPostalCode?: string | null,
    companyBillingRef?: string | null,
    companyBillingAddress?: string | null,
    companyEBillingOperator?: string | null,
    companyEBillingAddress?: string | null,
    paymentToken?: string | null,
    npsStatus?: NpsStatus | null,
    paymentStatus?: PaymentStatus | null,
    paymentData?:  {
      __typename: "PaymentData",
      paymentTotal?: number | null,
      paymentTodo?: number | null,
      depositPending?: boolean | null,
      deposit?: number | null,
    } | null,
    signStatus?: SignStatus | null,
    signDocumentId?: string | null,
    signerName?: string | null,
    reviewStatus?: ReviewStatus | null,
    authStatus?: AuthStatus | null,
    authTime?: string | null,
    completeBefore?: string | null,
    mustSign?: boolean | null,
    livionData?:  Array< {
      __typename: "LivionData",
      id?: string | null,
      pincode?: string | null,
      keyId?: string | null,
      name?: string | null,
      registrationPlate?: string | null,
    } | null > | null,
    tilakoneData?:  {
      __typename: "TilakoneData",
      access_id?: string | null,
      begin_timestamp?: string | null,
      end_timestamp?: string | null,
      pincode?: string | null,
    } | null,
    voucherData?:  {
      __typename: "VoucherData",
      name: string,
      code: string,
      amount: number,
    } | null,
    customerCarRegistrationPlate?: string | null,
    insuranceData?:  {
      __typename: "InsuranceData",
      customerContact?: boolean | null,
      urgent?: boolean | null,
      customerCarModel?: string | null,
      customerCarMaker?: string | null,
      product?: string | null,
      coveragePercentage?: number | null,
      compensatableDays?: string | null,
      billingPermitCode?: string | null,
      coverageDuration?: number | null,
      providerCompany?: string | null,
      providerBusinessId?: string | null,
      claimAdjusterName?: string | null,
      claimAdjusterEmail?: string | null,
      receipt?: boolean | null,
      replacementVehicleClass?: string | null,
      claimId?: string | null,
      vatDeductible?: boolean | null,
      deliveryCosts?: boolean | null,
      returnCosts?: boolean | null,
    } | null,
    externalData?:  {
      __typename: "ExternalData",
      orderId?: string | null,
      orderReference?: string | null,
      serviceAgreementName?: string | null,
      operatorName?: string | null,
      orderInfo?: string | null,
      billingPayer?: string | null,
      billingDescription?: string | null,
      customerVehicle?:  {
        __typename: "CustomerVehicle",
        brand?: string | null,
        model?: string | null,
        registrationPlate?: string | null,
      } | null,
      incidentLocation?:  {
        __typename: "Location",
        description?: string | null,
        address?: string | null,
        city?: string | null,
        zipCode?: string | null,
      } | null,
      repairShop?:  {
        __typename: "Location",
        description?: string | null,
        address?: string | null,
        city?: string | null,
        zipCode?: string | null,
      } | null,
    } | null,
    enableRegistrationPlate?: boolean | null,
    disablePricesInPrintouts?: boolean | null,
    hanselData?:  {
      __typename: "HanselData",
      contractNro?: string | null,
      customerInvoiceId?: string | null,
      customerInvoiceDate?: string | null,
      hanselAgent?: string | null,
      hanselClass?: string | null,
      customerId?: string | null,
      subContractorNumber?: string | null,
      subContractorName?: string | null,
      suplierProductId?: string | null,
      suplierProductName?: string | null,
      quantity?: string | null,
      price?: string | null,
      totalPrice?: string | null,
      priceNoVAT?: string | null,
      additionalInfo1?: string | null,
      additionalInfo2?: string | null,
      additionalInfo3?: string | null,
      desc?: string | null,
    } | null,
    invoiceIds?: Array< string > | null,
    consolidatedInvoiceIds?: Array< string > | null,
    channel?: ReservationChannel | null,
    originURL?: string | null,
    type?: ReservationType | null,
    status?: string | null,
    reservationStatusInfo?:  {
      __typename: "ReservationStatusInfo",
      deliveryTimestamp?: string | null,
      deliveryAddress?: string | null,
      deliveryZipcode?: string | null,
      deliveryCity?: string | null,
      deliveryCountry?: string | null,
      deliveryDescription?: string | null,
      returnTimestamp?: string | null,
      returnAddress?: string | null,
      returnZipcode?: string | null,
      returnCity?: string | null,
      returnCountry?: string | null,
      returnDescription?: string | null,
      cancelReason?: string | null,
    } | null,
    initialCategory?:  {
      __typename: "CategoryInfo",
      id?: string | null,
      name?: string | null,
      hanselClass?: string | null,
    } | null,
    initialClassInfo?:  {
      __typename: "InitialClassInfo",
      categoryId?: string | null,
      categoryName?: string | null,
      hanselClass?: string | null,
    } | null,
    orgInvoiceCustomer?:  {
      __typename: "OrganizationInvoiceCustomer",
      name?: string | null,
      businessId?: string | null,
    } | null,
    pricingCategoryId?: string | null,
    customImportDate?: string | null,
    vehicle?:  {
      __typename: "Vehicle",
      id: string,
      group?: string | null,
      organizationId?: string | null,
      orgBusinessId?: string | null,
      companyId: string,
      name: string,
      nameT?:  {
        __typename: "Translation",
        fi?: string | null,
        en?: string | null,
        sv?: string | null,
      } | null,
      registrationPlate?: string | null,
      damages?: string | null,
      damageRecords?:  Array< {
        __typename: "DamageRecord",
        normalizedX?: number | null,
        normalizedY?: number | null,
        label?: string | null,
        description?: string | null,
        createdAt?: string | null,
      } > | null,
      additionalFields:  Array< {
        __typename: "AdditionalField",
        key: string,
        value?: string | null,
      } >,
      image?: string | null,
      powerType?: string | null,
      deductible?: number | null,
      includedDistance?: string | null,
      mileage?: string | null,
      orderNumber?: number | null,
      vehicleCategoryId?: string | null,
      useCategoryDefaults?: boolean | null,
      useStartReturnTimes?: boolean | null,
      customStartReturns?:  Array< {
        __typename: "CustomStartReturn",
        priority?: number | null,
      } | null > | null,
      startReturnInterval?:  {
        __typename: "StartReturnInterval",
        start?: string | null,
        end?: string | null,
      } | null,
      startReturnTimes?:  Array< {
        __typename: "StartReturnTimes",
        start?: Array< string | null > | null,
        return?: Array< string | null > | null,
      } | null > | null,
      hideExport?: boolean | null,
      extraInfo?: string | null,
      extraInfoT?: string | null,
      extraToConfirmation?: string | null,
      extraToConfirmationT?: string | null,
      livionKeyId?: string | null,
      phoneNumber?: string | null,
      importData?: string | null,
      disableOnline?: boolean | null,
      activeRange?:  {
        __typename: "StartReturnInterval",
        start?: string | null,
        end?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      category?:  {
        __typename: "Category",
        id: string,
        group?: string | null,
        organizationId?: string | null,
        name: string,
        image?: string | null,
        orderNumber?: number | null,
        backgroundColor?: string | null,
        minuteBuffer?: number | null,
        reservationBlockFromNow?: number | null,
        typeId?: string | null,
        _removed?: string | null,
        oldTujausValue?: string | null,
        exampleModelName?: string | null,
        exampleModelImage?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      additionalServices?:  {
        __typename: "ModelAdditionalServicePricingConnection",
        nextToken?: string | null,
      } | null,
      reservations?:  {
        __typename: "ModelReservationConnection",
        nextToken?: string | null,
      } | null,
      pricing?:  {
        __typename: "Pricing",
        id: string,
        name: string,
        group?: string | null,
        organizationId?: string | null,
        orgBusinessId?: string | null,
        orgExternalBusinessId?: string | null,
        externalBusinessCategoryAssociation?: string | null,
        businessCategoryAssociation?: string | null,
        useNextDayPrice?: boolean | null,
        useLastDayPrice?: boolean | null,
        tax?: number | null,
        _removed?: string | null,
        deductible?: number | null,
        reducedDeductible?: number | null,
        reducedDeductiblePrice?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null,
    } | null,
    invoices?:  {
      __typename: "ModelInvoiceConnection",
      items:  Array< {
        __typename: "Invoice",
        id: string,
        createdAt: string,
        innovoiceId?: string | null,
        reservationId?: string | null,
        reservationIds?: Array< string > | null,
        companyId?: string | null,
        innovoiceCompanyId?: string | null,
        innovoiceuRentCarRentalId?: string | null,
        innovoiceOrderId?: string | null,
        group?: string | null,
        organizationId?: string | null,
        orgBusinessId?: string | null,
        type?: InvoicePaymentType | null,
        creditNoteInvoiceId?: string | null,
        payment_terms?: number | null,
        vat_included?: number | null,
        delivery_by: InvoiceDeliveryBy,
        invoice_type: InvoiceType,
        delivery_date?: string | null,
        seller_reference?: string | null,
        buyer_reference?: string | null,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    paymentEvents?:  {
      __typename: "ModelPaymentEventConnection",
      items:  Array< {
        __typename: "PaymentEvent",
        id: string,
        channel?: string | null,
        paymentMethod?: string | null,
        paymentTotal?: number | null,
        isDeleted?: boolean | null,
        deletedBy?: string | null,
        deletedAt?: string | null,
        createdByUserID?: string | null,
        createdByUserName?: string | null,
        reservationId?: string | null,
        organizationId?: string | null,
        orgBusinessId?: string | null,
        group?: string | null,
        msg?: string | null,
        state?: PaymentEventState | null,
        returnCode?: number | null,
        incidentId?: string | null,
        parameters?: string | null,
        apiPath?: string | null,
        paymentStatus?: string | null,
        orderNumber?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type DeleteReservationMutationVariables = {
  input: DeleteReservationInput,
  condition?: ModelReservationConditionInput | null,
};

export type DeleteReservationMutation = {
  deleteReservation?:  {
    __typename: "Reservation",
    id: string,
    shortId?: string | null,
    language?: Language | null,
    group?: string | null,
    organizationId?: string | null,
    orgBusinessId?: string | null,
    orgExternalBusinessId?: string | null,
    companyId?: string | null,
    returnCompanyId?: string | null,
    startLocation?: string | null,
    returnLocation?: string | null,
    startLocationID?: string | null,
    returnLocationID?: string | null,
    reviewId?: string | null,
    customerBrokerId?: string | null,
    customerCompanyId?: string | null,
    customerPersonalId?: string | null,
    reservationVehicleId?: string | null,
    reservationVehicles?:  Array< {
      __typename: "ReservationVehicle",
      id?: string | null,
      price?: number | null,
      tax?: number | null,
      name?: string | null,
      registrationPlate?: string | null,
      deductible?: number | null,
      includedDistance?: string | null,
      powerType?: string | null,
      mileage?: string | null,
      startMileage?: string | null,
      endMileage?: string | null,
      extraInfo?: string | null,
      damages?: string | null,
      hideExport?: boolean | null,
      createdAt?: string | null,
      addedAt?: string | null,
      categoryName?: string | null,
    } | null > | null,
    wildCardVehicles?:  Array< {
      __typename: "WildCardVehicle",
      name?: string | null,
      price?: number | null,
      tax?: number | null,
      categoryId?: string | null,
      quantity?: number | null,
      deductible?: number | null,
    } | null > | null,
    createdAt: string,
    updatedAt: string,
    startTime?: string | null,
    returnTime?: string | null,
    endTime?: string | null,
    name?: string | null,
    address?: string | null,
    postalCode?: string | null,
    city?: string | null,
    phone?: string | null,
    email?: string | null,
    billingRef?: string | null,
    customerNotes?: string | null,
    ssn?: string | null,
    rentalNotes?: string | null,
    internalNotes?: string | null,
    externalNotes?: string | null,
    outOfCommission?: boolean | null,
    vehiclePrice?: number | null,
    additionalServices?:  Array< {
      __typename: "AdditionalServiceReservation",
      key: string,
      price: number,
      details?: string | null,
      quantity?: number | null,
      type?: ServiceType | null,
      deductibleValue?: number | null,
      groupFields?: Array< string | null > | null,
      tax?: number | null,
      enableNotification?: boolean | null,
      description?: string | null,
      category?: string | null,
    } > | null,
    driverSSN?: string | null,
    differentDriver?: boolean | null,
    driverName?: string | null,
    driverAddress?: string | null,
    driverPostalCode?: string | null,
    driverCity?: string | null,
    driverPhone?: string | null,
    driverEmail?: string | null,
    additionalDriver?: boolean | null,
    additionalDriverName?: string | null,
    additionalDriverPhone?: string | null,
    additionalDriverSSN?: string | null,
    billPayment?: boolean | null,
    isCompany?: boolean | null,
    carStatus?: RentalStatus | null,
    rentalStatus?: RentalStatus | null,
    innovoiceStatus?: ReservationInnovoiceStatus | null,
    companyName?: string | null,
    companyBusinessId?: string | null,
    companyCity?: string | null,
    companyPostalCode?: string | null,
    companyBillingRef?: string | null,
    companyBillingAddress?: string | null,
    companyEBillingOperator?: string | null,
    companyEBillingAddress?: string | null,
    paymentToken?: string | null,
    npsStatus?: NpsStatus | null,
    paymentStatus?: PaymentStatus | null,
    paymentData?:  {
      __typename: "PaymentData",
      paymentTotal?: number | null,
      paymentTodo?: number | null,
      depositPending?: boolean | null,
      deposit?: number | null,
    } | null,
    signStatus?: SignStatus | null,
    signDocumentId?: string | null,
    signerName?: string | null,
    reviewStatus?: ReviewStatus | null,
    authStatus?: AuthStatus | null,
    authTime?: string | null,
    completeBefore?: string | null,
    mustSign?: boolean | null,
    livionData?:  Array< {
      __typename: "LivionData",
      id?: string | null,
      pincode?: string | null,
      keyId?: string | null,
      name?: string | null,
      registrationPlate?: string | null,
    } | null > | null,
    tilakoneData?:  {
      __typename: "TilakoneData",
      access_id?: string | null,
      begin_timestamp?: string | null,
      end_timestamp?: string | null,
      pincode?: string | null,
    } | null,
    voucherData?:  {
      __typename: "VoucherData",
      name: string,
      code: string,
      amount: number,
    } | null,
    customerCarRegistrationPlate?: string | null,
    insuranceData?:  {
      __typename: "InsuranceData",
      customerContact?: boolean | null,
      urgent?: boolean | null,
      customerCarModel?: string | null,
      customerCarMaker?: string | null,
      product?: string | null,
      coveragePercentage?: number | null,
      compensatableDays?: string | null,
      billingPermitCode?: string | null,
      coverageDuration?: number | null,
      providerCompany?: string | null,
      providerBusinessId?: string | null,
      claimAdjusterName?: string | null,
      claimAdjusterEmail?: string | null,
      receipt?: boolean | null,
      replacementVehicleClass?: string | null,
      claimId?: string | null,
      vatDeductible?: boolean | null,
      deliveryCosts?: boolean | null,
      returnCosts?: boolean | null,
    } | null,
    externalData?:  {
      __typename: "ExternalData",
      orderId?: string | null,
      orderReference?: string | null,
      serviceAgreementName?: string | null,
      operatorName?: string | null,
      orderInfo?: string | null,
      billingPayer?: string | null,
      billingDescription?: string | null,
      customerVehicle?:  {
        __typename: "CustomerVehicle",
        brand?: string | null,
        model?: string | null,
        registrationPlate?: string | null,
      } | null,
      incidentLocation?:  {
        __typename: "Location",
        description?: string | null,
        address?: string | null,
        city?: string | null,
        zipCode?: string | null,
      } | null,
      repairShop?:  {
        __typename: "Location",
        description?: string | null,
        address?: string | null,
        city?: string | null,
        zipCode?: string | null,
      } | null,
    } | null,
    enableRegistrationPlate?: boolean | null,
    disablePricesInPrintouts?: boolean | null,
    hanselData?:  {
      __typename: "HanselData",
      contractNro?: string | null,
      customerInvoiceId?: string | null,
      customerInvoiceDate?: string | null,
      hanselAgent?: string | null,
      hanselClass?: string | null,
      customerId?: string | null,
      subContractorNumber?: string | null,
      subContractorName?: string | null,
      suplierProductId?: string | null,
      suplierProductName?: string | null,
      quantity?: string | null,
      price?: string | null,
      totalPrice?: string | null,
      priceNoVAT?: string | null,
      additionalInfo1?: string | null,
      additionalInfo2?: string | null,
      additionalInfo3?: string | null,
      desc?: string | null,
    } | null,
    invoiceIds?: Array< string > | null,
    consolidatedInvoiceIds?: Array< string > | null,
    channel?: ReservationChannel | null,
    originURL?: string | null,
    type?: ReservationType | null,
    status?: string | null,
    reservationStatusInfo?:  {
      __typename: "ReservationStatusInfo",
      deliveryTimestamp?: string | null,
      deliveryAddress?: string | null,
      deliveryZipcode?: string | null,
      deliveryCity?: string | null,
      deliveryCountry?: string | null,
      deliveryDescription?: string | null,
      returnTimestamp?: string | null,
      returnAddress?: string | null,
      returnZipcode?: string | null,
      returnCity?: string | null,
      returnCountry?: string | null,
      returnDescription?: string | null,
      cancelReason?: string | null,
    } | null,
    initialCategory?:  {
      __typename: "CategoryInfo",
      id?: string | null,
      name?: string | null,
      hanselClass?: string | null,
    } | null,
    initialClassInfo?:  {
      __typename: "InitialClassInfo",
      categoryId?: string | null,
      categoryName?: string | null,
      hanselClass?: string | null,
    } | null,
    orgInvoiceCustomer?:  {
      __typename: "OrganizationInvoiceCustomer",
      name?: string | null,
      businessId?: string | null,
    } | null,
    pricingCategoryId?: string | null,
    customImportDate?: string | null,
    vehicle?:  {
      __typename: "Vehicle",
      id: string,
      group?: string | null,
      organizationId?: string | null,
      orgBusinessId?: string | null,
      companyId: string,
      name: string,
      nameT?:  {
        __typename: "Translation",
        fi?: string | null,
        en?: string | null,
        sv?: string | null,
      } | null,
      registrationPlate?: string | null,
      damages?: string | null,
      damageRecords?:  Array< {
        __typename: "DamageRecord",
        normalizedX?: number | null,
        normalizedY?: number | null,
        label?: string | null,
        description?: string | null,
        createdAt?: string | null,
      } > | null,
      additionalFields:  Array< {
        __typename: "AdditionalField",
        key: string,
        value?: string | null,
      } >,
      image?: string | null,
      powerType?: string | null,
      deductible?: number | null,
      includedDistance?: string | null,
      mileage?: string | null,
      orderNumber?: number | null,
      vehicleCategoryId?: string | null,
      useCategoryDefaults?: boolean | null,
      useStartReturnTimes?: boolean | null,
      customStartReturns?:  Array< {
        __typename: "CustomStartReturn",
        priority?: number | null,
      } | null > | null,
      startReturnInterval?:  {
        __typename: "StartReturnInterval",
        start?: string | null,
        end?: string | null,
      } | null,
      startReturnTimes?:  Array< {
        __typename: "StartReturnTimes",
        start?: Array< string | null > | null,
        return?: Array< string | null > | null,
      } | null > | null,
      hideExport?: boolean | null,
      extraInfo?: string | null,
      extraInfoT?: string | null,
      extraToConfirmation?: string | null,
      extraToConfirmationT?: string | null,
      livionKeyId?: string | null,
      phoneNumber?: string | null,
      importData?: string | null,
      disableOnline?: boolean | null,
      activeRange?:  {
        __typename: "StartReturnInterval",
        start?: string | null,
        end?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      category?:  {
        __typename: "Category",
        id: string,
        group?: string | null,
        organizationId?: string | null,
        name: string,
        image?: string | null,
        orderNumber?: number | null,
        backgroundColor?: string | null,
        minuteBuffer?: number | null,
        reservationBlockFromNow?: number | null,
        typeId?: string | null,
        _removed?: string | null,
        oldTujausValue?: string | null,
        exampleModelName?: string | null,
        exampleModelImage?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      additionalServices?:  {
        __typename: "ModelAdditionalServicePricingConnection",
        nextToken?: string | null,
      } | null,
      reservations?:  {
        __typename: "ModelReservationConnection",
        nextToken?: string | null,
      } | null,
      pricing?:  {
        __typename: "Pricing",
        id: string,
        name: string,
        group?: string | null,
        organizationId?: string | null,
        orgBusinessId?: string | null,
        orgExternalBusinessId?: string | null,
        externalBusinessCategoryAssociation?: string | null,
        businessCategoryAssociation?: string | null,
        useNextDayPrice?: boolean | null,
        useLastDayPrice?: boolean | null,
        tax?: number | null,
        _removed?: string | null,
        deductible?: number | null,
        reducedDeductible?: number | null,
        reducedDeductiblePrice?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null,
    } | null,
    invoices?:  {
      __typename: "ModelInvoiceConnection",
      items:  Array< {
        __typename: "Invoice",
        id: string,
        createdAt: string,
        innovoiceId?: string | null,
        reservationId?: string | null,
        reservationIds?: Array< string > | null,
        companyId?: string | null,
        innovoiceCompanyId?: string | null,
        innovoiceuRentCarRentalId?: string | null,
        innovoiceOrderId?: string | null,
        group?: string | null,
        organizationId?: string | null,
        orgBusinessId?: string | null,
        type?: InvoicePaymentType | null,
        creditNoteInvoiceId?: string | null,
        payment_terms?: number | null,
        vat_included?: number | null,
        delivery_by: InvoiceDeliveryBy,
        invoice_type: InvoiceType,
        delivery_date?: string | null,
        seller_reference?: string | null,
        buyer_reference?: string | null,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    paymentEvents?:  {
      __typename: "ModelPaymentEventConnection",
      items:  Array< {
        __typename: "PaymentEvent",
        id: string,
        channel?: string | null,
        paymentMethod?: string | null,
        paymentTotal?: number | null,
        isDeleted?: boolean | null,
        deletedBy?: string | null,
        deletedAt?: string | null,
        createdByUserID?: string | null,
        createdByUserName?: string | null,
        reservationId?: string | null,
        organizationId?: string | null,
        orgBusinessId?: string | null,
        group?: string | null,
        msg?: string | null,
        state?: PaymentEventState | null,
        returnCode?: number | null,
        incidentId?: string | null,
        parameters?: string | null,
        apiPath?: string | null,
        paymentStatus?: string | null,
        orderNumber?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type CreateReservationCountMutationVariables = {
  input: CreateReservationCountInput,
  condition?: ModelReservationCountConditionInput | null,
};

export type CreateReservationCountMutation = {
  createReservationCount?:  {
    __typename: "ReservationCount",
    tenant: string,
    date: string,
    count: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateReservationCountMutationVariables = {
  input: UpdateReservationCountInput,
  condition?: ModelReservationCountConditionInput | null,
};

export type UpdateReservationCountMutation = {
  updateReservationCount?:  {
    __typename: "ReservationCount",
    tenant: string,
    date: string,
    count: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteReservationCountMutationVariables = {
  input: DeleteReservationCountInput,
  condition?: ModelReservationCountConditionInput | null,
};

export type DeleteReservationCountMutation = {
  deleteReservationCount?:  {
    __typename: "ReservationCount",
    tenant: string,
    date: string,
    count: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateInvoiceMutationVariables = {
  input: CreateInvoiceInput,
  condition?: ModelInvoiceConditionInput | null,
};

export type CreateInvoiceMutation = {
  createInvoice?:  {
    __typename: "Invoice",
    id: string,
    createdAt: string,
    innovoiceId?: string | null,
    reservationId?: string | null,
    reservationIds?: Array< string > | null,
    companyId?: string | null,
    innovoiceCompanyId?: string | null,
    innovoiceuRentCarRentalId?: string | null,
    innovoiceOrderId?: string | null,
    group?: string | null,
    organizationId?: string | null,
    orgBusinessId?: string | null,
    type?: InvoicePaymentType | null,
    creditNoteInvoiceId?: string | null,
    payment_terms?: number | null,
    vat_included?: number | null,
    delivery_by: InvoiceDeliveryBy,
    invoice_type: InvoiceType,
    delivery_date?: string | null,
    seller_reference?: string | null,
    buyer_reference?: string | null,
    items:  Array< {
      __typename: "InvoiceItem",
      name: string,
      description?: string | null,
      quantity: number,
      unit: string,
      unitprice: number,
      discount?: number | null,
      vat: number,
      type?: string | null,
      insuranceItemType?: InsuranceItemType | null,
      reservationId?: string | null,
    } >,
    customer:  {
      __typename: "InvoiceCustomer",
      customer_type?: InvoiceCustomerType | null,
      firstname?: string | null,
      lastname?: string | null,
      ssn?: string | null,
      address?: string | null,
      zipcode?: string | null,
      city?: string | null,
      email?: string | null,
      phone?: string | null,
      company_name?: string | null,
      contact_person?: string | null,
      e_invoice_operator?: string | null,
      e_invoice_address?: string | null,
    },
    insurance?:  {
      __typename: "InvoiceInsurance",
      insurance_company_id?: number | null,
      vehicle_class?: string | null,
      vehicle_regno?: string | null,
      claim_no?: string | null,
      rental_start_date?: string | null,
      rental_end_date?: string | null,
      rental_days?: number | null,
      amount_cost_per_day?: number | null,
      amount_to_pay_customer?: number | null,
      amount_to_pay_insurance?: number | null,
      coveragePercentage?: number | null,
    } | null,
    updatedAt: string,
  } | null,
};

export type UpdateInvoiceMutationVariables = {
  input: UpdateInvoiceInput,
  condition?: ModelInvoiceConditionInput | null,
};

export type UpdateInvoiceMutation = {
  updateInvoice?:  {
    __typename: "Invoice",
    id: string,
    createdAt: string,
    innovoiceId?: string | null,
    reservationId?: string | null,
    reservationIds?: Array< string > | null,
    companyId?: string | null,
    innovoiceCompanyId?: string | null,
    innovoiceuRentCarRentalId?: string | null,
    innovoiceOrderId?: string | null,
    group?: string | null,
    organizationId?: string | null,
    orgBusinessId?: string | null,
    type?: InvoicePaymentType | null,
    creditNoteInvoiceId?: string | null,
    payment_terms?: number | null,
    vat_included?: number | null,
    delivery_by: InvoiceDeliveryBy,
    invoice_type: InvoiceType,
    delivery_date?: string | null,
    seller_reference?: string | null,
    buyer_reference?: string | null,
    items:  Array< {
      __typename: "InvoiceItem",
      name: string,
      description?: string | null,
      quantity: number,
      unit: string,
      unitprice: number,
      discount?: number | null,
      vat: number,
      type?: string | null,
      insuranceItemType?: InsuranceItemType | null,
      reservationId?: string | null,
    } >,
    customer:  {
      __typename: "InvoiceCustomer",
      customer_type?: InvoiceCustomerType | null,
      firstname?: string | null,
      lastname?: string | null,
      ssn?: string | null,
      address?: string | null,
      zipcode?: string | null,
      city?: string | null,
      email?: string | null,
      phone?: string | null,
      company_name?: string | null,
      contact_person?: string | null,
      e_invoice_operator?: string | null,
      e_invoice_address?: string | null,
    },
    insurance?:  {
      __typename: "InvoiceInsurance",
      insurance_company_id?: number | null,
      vehicle_class?: string | null,
      vehicle_regno?: string | null,
      claim_no?: string | null,
      rental_start_date?: string | null,
      rental_end_date?: string | null,
      rental_days?: number | null,
      amount_cost_per_day?: number | null,
      amount_to_pay_customer?: number | null,
      amount_to_pay_insurance?: number | null,
      coveragePercentage?: number | null,
    } | null,
    updatedAt: string,
  } | null,
};

export type DeleteInvoiceMutationVariables = {
  input: DeleteInvoiceInput,
  condition?: ModelInvoiceConditionInput | null,
};

export type DeleteInvoiceMutation = {
  deleteInvoice?:  {
    __typename: "Invoice",
    id: string,
    createdAt: string,
    innovoiceId?: string | null,
    reservationId?: string | null,
    reservationIds?: Array< string > | null,
    companyId?: string | null,
    innovoiceCompanyId?: string | null,
    innovoiceuRentCarRentalId?: string | null,
    innovoiceOrderId?: string | null,
    group?: string | null,
    organizationId?: string | null,
    orgBusinessId?: string | null,
    type?: InvoicePaymentType | null,
    creditNoteInvoiceId?: string | null,
    payment_terms?: number | null,
    vat_included?: number | null,
    delivery_by: InvoiceDeliveryBy,
    invoice_type: InvoiceType,
    delivery_date?: string | null,
    seller_reference?: string | null,
    buyer_reference?: string | null,
    items:  Array< {
      __typename: "InvoiceItem",
      name: string,
      description?: string | null,
      quantity: number,
      unit: string,
      unitprice: number,
      discount?: number | null,
      vat: number,
      type?: string | null,
      insuranceItemType?: InsuranceItemType | null,
      reservationId?: string | null,
    } >,
    customer:  {
      __typename: "InvoiceCustomer",
      customer_type?: InvoiceCustomerType | null,
      firstname?: string | null,
      lastname?: string | null,
      ssn?: string | null,
      address?: string | null,
      zipcode?: string | null,
      city?: string | null,
      email?: string | null,
      phone?: string | null,
      company_name?: string | null,
      contact_person?: string | null,
      e_invoice_operator?: string | null,
      e_invoice_address?: string | null,
    },
    insurance?:  {
      __typename: "InvoiceInsurance",
      insurance_company_id?: number | null,
      vehicle_class?: string | null,
      vehicle_regno?: string | null,
      claim_no?: string | null,
      rental_start_date?: string | null,
      rental_end_date?: string | null,
      rental_days?: number | null,
      amount_cost_per_day?: number | null,
      amount_to_pay_customer?: number | null,
      amount_to_pay_insurance?: number | null,
      coveragePercentage?: number | null,
    } | null,
    updatedAt: string,
  } | null,
};

export type CreateSignEventMutationVariables = {
  input: CreateSignEventInput,
  condition?: ModelSignEventConditionInput | null,
};

export type CreateSignEventMutation = {
  createSignEvent?:  {
    __typename: "SignEvent",
    id: string,
    reservationId: string,
    company: string,
    organizationId?: string | null,
    orgBusinessId?: string | null,
    group?: string | null,
    status: SignStatus,
    documentId?: string | null,
    source?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateSignEventMutationVariables = {
  input: UpdateSignEventInput,
  condition?: ModelSignEventConditionInput | null,
};

export type UpdateSignEventMutation = {
  updateSignEvent?:  {
    __typename: "SignEvent",
    id: string,
    reservationId: string,
    company: string,
    organizationId?: string | null,
    orgBusinessId?: string | null,
    group?: string | null,
    status: SignStatus,
    documentId?: string | null,
    source?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteSignEventMutationVariables = {
  input: DeleteSignEventInput,
  condition?: ModelSignEventConditionInput | null,
};

export type DeleteSignEventMutation = {
  deleteSignEvent?:  {
    __typename: "SignEvent",
    id: string,
    reservationId: string,
    company: string,
    organizationId?: string | null,
    orgBusinessId?: string | null,
    group?: string | null,
    status: SignStatus,
    documentId?: string | null,
    source?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreatePaymentEventMutationVariables = {
  input: CreatePaymentEventInput,
  condition?: ModelPaymentEventConditionInput | null,
};

export type CreatePaymentEventMutation = {
  createPaymentEvent?:  {
    __typename: "PaymentEvent",
    id: string,
    channel?: string | null,
    paymentMethod?: string | null,
    paymentTotal?: number | null,
    isDeleted?: boolean | null,
    deletedBy?: string | null,
    deletedAt?: string | null,
    createdByUserID?: string | null,
    createdByUserName?: string | null,
    reservationId?: string | null,
    organizationId?: string | null,
    orgBusinessId?: string | null,
    group?: string | null,
    msg?: string | null,
    state?: PaymentEventState | null,
    returnCode?: number | null,
    incidentId?: string | null,
    parameters?: string | null,
    apiPath?: string | null,
    paymentStatus?: string | null,
    orderNumber?: string | null,
    createdAt: string,
    updatedAt: string,
    reservation?:  {
      __typename: "Reservation",
      id: string,
      shortId?: string | null,
      language?: Language | null,
      group?: string | null,
      organizationId?: string | null,
      orgBusinessId?: string | null,
      orgExternalBusinessId?: string | null,
      companyId?: string | null,
      returnCompanyId?: string | null,
      startLocation?: string | null,
      returnLocation?: string | null,
      startLocationID?: string | null,
      returnLocationID?: string | null,
      reviewId?: string | null,
      customerBrokerId?: string | null,
      customerCompanyId?: string | null,
      customerPersonalId?: string | null,
      reservationVehicleId?: string | null,
      reservationVehicles?:  Array< {
        __typename: "ReservationVehicle",
        id?: string | null,
        price?: number | null,
        tax?: number | null,
        name?: string | null,
        registrationPlate?: string | null,
        deductible?: number | null,
        includedDistance?: string | null,
        powerType?: string | null,
        mileage?: string | null,
        startMileage?: string | null,
        endMileage?: string | null,
        extraInfo?: string | null,
        damages?: string | null,
        hideExport?: boolean | null,
        createdAt?: string | null,
        addedAt?: string | null,
        categoryName?: string | null,
      } | null > | null,
      wildCardVehicles?:  Array< {
        __typename: "WildCardVehicle",
        name?: string | null,
        price?: number | null,
        tax?: number | null,
        categoryId?: string | null,
        quantity?: number | null,
        deductible?: number | null,
      } | null > | null,
      createdAt: string,
      updatedAt: string,
      startTime?: string | null,
      returnTime?: string | null,
      endTime?: string | null,
      name?: string | null,
      address?: string | null,
      postalCode?: string | null,
      city?: string | null,
      phone?: string | null,
      email?: string | null,
      billingRef?: string | null,
      customerNotes?: string | null,
      ssn?: string | null,
      rentalNotes?: string | null,
      internalNotes?: string | null,
      externalNotes?: string | null,
      outOfCommission?: boolean | null,
      vehiclePrice?: number | null,
      additionalServices?:  Array< {
        __typename: "AdditionalServiceReservation",
        key: string,
        price: number,
        details?: string | null,
        quantity?: number | null,
        type?: ServiceType | null,
        deductibleValue?: number | null,
        groupFields?: Array< string | null > | null,
        tax?: number | null,
        enableNotification?: boolean | null,
        description?: string | null,
        category?: string | null,
      } > | null,
      driverSSN?: string | null,
      differentDriver?: boolean | null,
      driverName?: string | null,
      driverAddress?: string | null,
      driverPostalCode?: string | null,
      driverCity?: string | null,
      driverPhone?: string | null,
      driverEmail?: string | null,
      additionalDriver?: boolean | null,
      additionalDriverName?: string | null,
      additionalDriverPhone?: string | null,
      additionalDriverSSN?: string | null,
      billPayment?: boolean | null,
      isCompany?: boolean | null,
      carStatus?: RentalStatus | null,
      rentalStatus?: RentalStatus | null,
      innovoiceStatus?: ReservationInnovoiceStatus | null,
      companyName?: string | null,
      companyBusinessId?: string | null,
      companyCity?: string | null,
      companyPostalCode?: string | null,
      companyBillingRef?: string | null,
      companyBillingAddress?: string | null,
      companyEBillingOperator?: string | null,
      companyEBillingAddress?: string | null,
      paymentToken?: string | null,
      npsStatus?: NpsStatus | null,
      paymentStatus?: PaymentStatus | null,
      paymentData?:  {
        __typename: "PaymentData",
        paymentTotal?: number | null,
        paymentTodo?: number | null,
        depositPending?: boolean | null,
        deposit?: number | null,
      } | null,
      signStatus?: SignStatus | null,
      signDocumentId?: string | null,
      signerName?: string | null,
      reviewStatus?: ReviewStatus | null,
      authStatus?: AuthStatus | null,
      authTime?: string | null,
      completeBefore?: string | null,
      mustSign?: boolean | null,
      livionData?:  Array< {
        __typename: "LivionData",
        id?: string | null,
        pincode?: string | null,
        keyId?: string | null,
        name?: string | null,
        registrationPlate?: string | null,
      } | null > | null,
      tilakoneData?:  {
        __typename: "TilakoneData",
        access_id?: string | null,
        begin_timestamp?: string | null,
        end_timestamp?: string | null,
        pincode?: string | null,
      } | null,
      voucherData?:  {
        __typename: "VoucherData",
        name: string,
        code: string,
        amount: number,
      } | null,
      customerCarRegistrationPlate?: string | null,
      insuranceData?:  {
        __typename: "InsuranceData",
        customerContact?: boolean | null,
        urgent?: boolean | null,
        customerCarModel?: string | null,
        customerCarMaker?: string | null,
        product?: string | null,
        coveragePercentage?: number | null,
        compensatableDays?: string | null,
        billingPermitCode?: string | null,
        coverageDuration?: number | null,
        providerCompany?: string | null,
        providerBusinessId?: string | null,
        claimAdjusterName?: string | null,
        claimAdjusterEmail?: string | null,
        receipt?: boolean | null,
        replacementVehicleClass?: string | null,
        claimId?: string | null,
        vatDeductible?: boolean | null,
        deliveryCosts?: boolean | null,
        returnCosts?: boolean | null,
      } | null,
      externalData?:  {
        __typename: "ExternalData",
        orderId?: string | null,
        orderReference?: string | null,
        serviceAgreementName?: string | null,
        operatorName?: string | null,
        orderInfo?: string | null,
        billingPayer?: string | null,
        billingDescription?: string | null,
      } | null,
      enableRegistrationPlate?: boolean | null,
      disablePricesInPrintouts?: boolean | null,
      hanselData?:  {
        __typename: "HanselData",
        contractNro?: string | null,
        customerInvoiceId?: string | null,
        customerInvoiceDate?: string | null,
        hanselAgent?: string | null,
        hanselClass?: string | null,
        customerId?: string | null,
        subContractorNumber?: string | null,
        subContractorName?: string | null,
        suplierProductId?: string | null,
        suplierProductName?: string | null,
        quantity?: string | null,
        price?: string | null,
        totalPrice?: string | null,
        priceNoVAT?: string | null,
        additionalInfo1?: string | null,
        additionalInfo2?: string | null,
        additionalInfo3?: string | null,
        desc?: string | null,
      } | null,
      invoiceIds?: Array< string > | null,
      consolidatedInvoiceIds?: Array< string > | null,
      channel?: ReservationChannel | null,
      originURL?: string | null,
      type?: ReservationType | null,
      status?: string | null,
      reservationStatusInfo?:  {
        __typename: "ReservationStatusInfo",
        deliveryTimestamp?: string | null,
        deliveryAddress?: string | null,
        deliveryZipcode?: string | null,
        deliveryCity?: string | null,
        deliveryCountry?: string | null,
        deliveryDescription?: string | null,
        returnTimestamp?: string | null,
        returnAddress?: string | null,
        returnZipcode?: string | null,
        returnCity?: string | null,
        returnCountry?: string | null,
        returnDescription?: string | null,
        cancelReason?: string | null,
      } | null,
      initialCategory?:  {
        __typename: "CategoryInfo",
        id?: string | null,
        name?: string | null,
        hanselClass?: string | null,
      } | null,
      initialClassInfo?:  {
        __typename: "InitialClassInfo",
        categoryId?: string | null,
        categoryName?: string | null,
        hanselClass?: string | null,
      } | null,
      orgInvoiceCustomer?:  {
        __typename: "OrganizationInvoiceCustomer",
        name?: string | null,
        businessId?: string | null,
      } | null,
      pricingCategoryId?: string | null,
      customImportDate?: string | null,
      vehicle?:  {
        __typename: "Vehicle",
        id: string,
        group?: string | null,
        organizationId?: string | null,
        orgBusinessId?: string | null,
        companyId: string,
        name: string,
        registrationPlate?: string | null,
        damages?: string | null,
        image?: string | null,
        powerType?: string | null,
        deductible?: number | null,
        includedDistance?: string | null,
        mileage?: string | null,
        orderNumber?: number | null,
        vehicleCategoryId?: string | null,
        useCategoryDefaults?: boolean | null,
        useStartReturnTimes?: boolean | null,
        hideExport?: boolean | null,
        extraInfo?: string | null,
        extraInfoT?: string | null,
        extraToConfirmation?: string | null,
        extraToConfirmationT?: string | null,
        livionKeyId?: string | null,
        phoneNumber?: string | null,
        importData?: string | null,
        disableOnline?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      invoices?:  {
        __typename: "ModelInvoiceConnection",
        nextToken?: string | null,
      } | null,
      paymentEvents?:  {
        __typename: "ModelPaymentEventConnection",
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type UpdatePaymentEventMutationVariables = {
  input: UpdatePaymentEventInput,
  condition?: ModelPaymentEventConditionInput | null,
};

export type UpdatePaymentEventMutation = {
  updatePaymentEvent?:  {
    __typename: "PaymentEvent",
    id: string,
    channel?: string | null,
    paymentMethod?: string | null,
    paymentTotal?: number | null,
    isDeleted?: boolean | null,
    deletedBy?: string | null,
    deletedAt?: string | null,
    createdByUserID?: string | null,
    createdByUserName?: string | null,
    reservationId?: string | null,
    organizationId?: string | null,
    orgBusinessId?: string | null,
    group?: string | null,
    msg?: string | null,
    state?: PaymentEventState | null,
    returnCode?: number | null,
    incidentId?: string | null,
    parameters?: string | null,
    apiPath?: string | null,
    paymentStatus?: string | null,
    orderNumber?: string | null,
    createdAt: string,
    updatedAt: string,
    reservation?:  {
      __typename: "Reservation",
      id: string,
      shortId?: string | null,
      language?: Language | null,
      group?: string | null,
      organizationId?: string | null,
      orgBusinessId?: string | null,
      orgExternalBusinessId?: string | null,
      companyId?: string | null,
      returnCompanyId?: string | null,
      startLocation?: string | null,
      returnLocation?: string | null,
      startLocationID?: string | null,
      returnLocationID?: string | null,
      reviewId?: string | null,
      customerBrokerId?: string | null,
      customerCompanyId?: string | null,
      customerPersonalId?: string | null,
      reservationVehicleId?: string | null,
      reservationVehicles?:  Array< {
        __typename: "ReservationVehicle",
        id?: string | null,
        price?: number | null,
        tax?: number | null,
        name?: string | null,
        registrationPlate?: string | null,
        deductible?: number | null,
        includedDistance?: string | null,
        powerType?: string | null,
        mileage?: string | null,
        startMileage?: string | null,
        endMileage?: string | null,
        extraInfo?: string | null,
        damages?: string | null,
        hideExport?: boolean | null,
        createdAt?: string | null,
        addedAt?: string | null,
        categoryName?: string | null,
      } | null > | null,
      wildCardVehicles?:  Array< {
        __typename: "WildCardVehicle",
        name?: string | null,
        price?: number | null,
        tax?: number | null,
        categoryId?: string | null,
        quantity?: number | null,
        deductible?: number | null,
      } | null > | null,
      createdAt: string,
      updatedAt: string,
      startTime?: string | null,
      returnTime?: string | null,
      endTime?: string | null,
      name?: string | null,
      address?: string | null,
      postalCode?: string | null,
      city?: string | null,
      phone?: string | null,
      email?: string | null,
      billingRef?: string | null,
      customerNotes?: string | null,
      ssn?: string | null,
      rentalNotes?: string | null,
      internalNotes?: string | null,
      externalNotes?: string | null,
      outOfCommission?: boolean | null,
      vehiclePrice?: number | null,
      additionalServices?:  Array< {
        __typename: "AdditionalServiceReservation",
        key: string,
        price: number,
        details?: string | null,
        quantity?: number | null,
        type?: ServiceType | null,
        deductibleValue?: number | null,
        groupFields?: Array< string | null > | null,
        tax?: number | null,
        enableNotification?: boolean | null,
        description?: string | null,
        category?: string | null,
      } > | null,
      driverSSN?: string | null,
      differentDriver?: boolean | null,
      driverName?: string | null,
      driverAddress?: string | null,
      driverPostalCode?: string | null,
      driverCity?: string | null,
      driverPhone?: string | null,
      driverEmail?: string | null,
      additionalDriver?: boolean | null,
      additionalDriverName?: string | null,
      additionalDriverPhone?: string | null,
      additionalDriverSSN?: string | null,
      billPayment?: boolean | null,
      isCompany?: boolean | null,
      carStatus?: RentalStatus | null,
      rentalStatus?: RentalStatus | null,
      innovoiceStatus?: ReservationInnovoiceStatus | null,
      companyName?: string | null,
      companyBusinessId?: string | null,
      companyCity?: string | null,
      companyPostalCode?: string | null,
      companyBillingRef?: string | null,
      companyBillingAddress?: string | null,
      companyEBillingOperator?: string | null,
      companyEBillingAddress?: string | null,
      paymentToken?: string | null,
      npsStatus?: NpsStatus | null,
      paymentStatus?: PaymentStatus | null,
      paymentData?:  {
        __typename: "PaymentData",
        paymentTotal?: number | null,
        paymentTodo?: number | null,
        depositPending?: boolean | null,
        deposit?: number | null,
      } | null,
      signStatus?: SignStatus | null,
      signDocumentId?: string | null,
      signerName?: string | null,
      reviewStatus?: ReviewStatus | null,
      authStatus?: AuthStatus | null,
      authTime?: string | null,
      completeBefore?: string | null,
      mustSign?: boolean | null,
      livionData?:  Array< {
        __typename: "LivionData",
        id?: string | null,
        pincode?: string | null,
        keyId?: string | null,
        name?: string | null,
        registrationPlate?: string | null,
      } | null > | null,
      tilakoneData?:  {
        __typename: "TilakoneData",
        access_id?: string | null,
        begin_timestamp?: string | null,
        end_timestamp?: string | null,
        pincode?: string | null,
      } | null,
      voucherData?:  {
        __typename: "VoucherData",
        name: string,
        code: string,
        amount: number,
      } | null,
      customerCarRegistrationPlate?: string | null,
      insuranceData?:  {
        __typename: "InsuranceData",
        customerContact?: boolean | null,
        urgent?: boolean | null,
        customerCarModel?: string | null,
        customerCarMaker?: string | null,
        product?: string | null,
        coveragePercentage?: number | null,
        compensatableDays?: string | null,
        billingPermitCode?: string | null,
        coverageDuration?: number | null,
        providerCompany?: string | null,
        providerBusinessId?: string | null,
        claimAdjusterName?: string | null,
        claimAdjusterEmail?: string | null,
        receipt?: boolean | null,
        replacementVehicleClass?: string | null,
        claimId?: string | null,
        vatDeductible?: boolean | null,
        deliveryCosts?: boolean | null,
        returnCosts?: boolean | null,
      } | null,
      externalData?:  {
        __typename: "ExternalData",
        orderId?: string | null,
        orderReference?: string | null,
        serviceAgreementName?: string | null,
        operatorName?: string | null,
        orderInfo?: string | null,
        billingPayer?: string | null,
        billingDescription?: string | null,
      } | null,
      enableRegistrationPlate?: boolean | null,
      disablePricesInPrintouts?: boolean | null,
      hanselData?:  {
        __typename: "HanselData",
        contractNro?: string | null,
        customerInvoiceId?: string | null,
        customerInvoiceDate?: string | null,
        hanselAgent?: string | null,
        hanselClass?: string | null,
        customerId?: string | null,
        subContractorNumber?: string | null,
        subContractorName?: string | null,
        suplierProductId?: string | null,
        suplierProductName?: string | null,
        quantity?: string | null,
        price?: string | null,
        totalPrice?: string | null,
        priceNoVAT?: string | null,
        additionalInfo1?: string | null,
        additionalInfo2?: string | null,
        additionalInfo3?: string | null,
        desc?: string | null,
      } | null,
      invoiceIds?: Array< string > | null,
      consolidatedInvoiceIds?: Array< string > | null,
      channel?: ReservationChannel | null,
      originURL?: string | null,
      type?: ReservationType | null,
      status?: string | null,
      reservationStatusInfo?:  {
        __typename: "ReservationStatusInfo",
        deliveryTimestamp?: string | null,
        deliveryAddress?: string | null,
        deliveryZipcode?: string | null,
        deliveryCity?: string | null,
        deliveryCountry?: string | null,
        deliveryDescription?: string | null,
        returnTimestamp?: string | null,
        returnAddress?: string | null,
        returnZipcode?: string | null,
        returnCity?: string | null,
        returnCountry?: string | null,
        returnDescription?: string | null,
        cancelReason?: string | null,
      } | null,
      initialCategory?:  {
        __typename: "CategoryInfo",
        id?: string | null,
        name?: string | null,
        hanselClass?: string | null,
      } | null,
      initialClassInfo?:  {
        __typename: "InitialClassInfo",
        categoryId?: string | null,
        categoryName?: string | null,
        hanselClass?: string | null,
      } | null,
      orgInvoiceCustomer?:  {
        __typename: "OrganizationInvoiceCustomer",
        name?: string | null,
        businessId?: string | null,
      } | null,
      pricingCategoryId?: string | null,
      customImportDate?: string | null,
      vehicle?:  {
        __typename: "Vehicle",
        id: string,
        group?: string | null,
        organizationId?: string | null,
        orgBusinessId?: string | null,
        companyId: string,
        name: string,
        registrationPlate?: string | null,
        damages?: string | null,
        image?: string | null,
        powerType?: string | null,
        deductible?: number | null,
        includedDistance?: string | null,
        mileage?: string | null,
        orderNumber?: number | null,
        vehicleCategoryId?: string | null,
        useCategoryDefaults?: boolean | null,
        useStartReturnTimes?: boolean | null,
        hideExport?: boolean | null,
        extraInfo?: string | null,
        extraInfoT?: string | null,
        extraToConfirmation?: string | null,
        extraToConfirmationT?: string | null,
        livionKeyId?: string | null,
        phoneNumber?: string | null,
        importData?: string | null,
        disableOnline?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      invoices?:  {
        __typename: "ModelInvoiceConnection",
        nextToken?: string | null,
      } | null,
      paymentEvents?:  {
        __typename: "ModelPaymentEventConnection",
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type DeletePaymentEventMutationVariables = {
  input: DeletePaymentEventInput,
  condition?: ModelPaymentEventConditionInput | null,
};

export type DeletePaymentEventMutation = {
  deletePaymentEvent?:  {
    __typename: "PaymentEvent",
    id: string,
    channel?: string | null,
    paymentMethod?: string | null,
    paymentTotal?: number | null,
    isDeleted?: boolean | null,
    deletedBy?: string | null,
    deletedAt?: string | null,
    createdByUserID?: string | null,
    createdByUserName?: string | null,
    reservationId?: string | null,
    organizationId?: string | null,
    orgBusinessId?: string | null,
    group?: string | null,
    msg?: string | null,
    state?: PaymentEventState | null,
    returnCode?: number | null,
    incidentId?: string | null,
    parameters?: string | null,
    apiPath?: string | null,
    paymentStatus?: string | null,
    orderNumber?: string | null,
    createdAt: string,
    updatedAt: string,
    reservation?:  {
      __typename: "Reservation",
      id: string,
      shortId?: string | null,
      language?: Language | null,
      group?: string | null,
      organizationId?: string | null,
      orgBusinessId?: string | null,
      orgExternalBusinessId?: string | null,
      companyId?: string | null,
      returnCompanyId?: string | null,
      startLocation?: string | null,
      returnLocation?: string | null,
      startLocationID?: string | null,
      returnLocationID?: string | null,
      reviewId?: string | null,
      customerBrokerId?: string | null,
      customerCompanyId?: string | null,
      customerPersonalId?: string | null,
      reservationVehicleId?: string | null,
      reservationVehicles?:  Array< {
        __typename: "ReservationVehicle",
        id?: string | null,
        price?: number | null,
        tax?: number | null,
        name?: string | null,
        registrationPlate?: string | null,
        deductible?: number | null,
        includedDistance?: string | null,
        powerType?: string | null,
        mileage?: string | null,
        startMileage?: string | null,
        endMileage?: string | null,
        extraInfo?: string | null,
        damages?: string | null,
        hideExport?: boolean | null,
        createdAt?: string | null,
        addedAt?: string | null,
        categoryName?: string | null,
      } | null > | null,
      wildCardVehicles?:  Array< {
        __typename: "WildCardVehicle",
        name?: string | null,
        price?: number | null,
        tax?: number | null,
        categoryId?: string | null,
        quantity?: number | null,
        deductible?: number | null,
      } | null > | null,
      createdAt: string,
      updatedAt: string,
      startTime?: string | null,
      returnTime?: string | null,
      endTime?: string | null,
      name?: string | null,
      address?: string | null,
      postalCode?: string | null,
      city?: string | null,
      phone?: string | null,
      email?: string | null,
      billingRef?: string | null,
      customerNotes?: string | null,
      ssn?: string | null,
      rentalNotes?: string | null,
      internalNotes?: string | null,
      externalNotes?: string | null,
      outOfCommission?: boolean | null,
      vehiclePrice?: number | null,
      additionalServices?:  Array< {
        __typename: "AdditionalServiceReservation",
        key: string,
        price: number,
        details?: string | null,
        quantity?: number | null,
        type?: ServiceType | null,
        deductibleValue?: number | null,
        groupFields?: Array< string | null > | null,
        tax?: number | null,
        enableNotification?: boolean | null,
        description?: string | null,
        category?: string | null,
      } > | null,
      driverSSN?: string | null,
      differentDriver?: boolean | null,
      driverName?: string | null,
      driverAddress?: string | null,
      driverPostalCode?: string | null,
      driverCity?: string | null,
      driverPhone?: string | null,
      driverEmail?: string | null,
      additionalDriver?: boolean | null,
      additionalDriverName?: string | null,
      additionalDriverPhone?: string | null,
      additionalDriverSSN?: string | null,
      billPayment?: boolean | null,
      isCompany?: boolean | null,
      carStatus?: RentalStatus | null,
      rentalStatus?: RentalStatus | null,
      innovoiceStatus?: ReservationInnovoiceStatus | null,
      companyName?: string | null,
      companyBusinessId?: string | null,
      companyCity?: string | null,
      companyPostalCode?: string | null,
      companyBillingRef?: string | null,
      companyBillingAddress?: string | null,
      companyEBillingOperator?: string | null,
      companyEBillingAddress?: string | null,
      paymentToken?: string | null,
      npsStatus?: NpsStatus | null,
      paymentStatus?: PaymentStatus | null,
      paymentData?:  {
        __typename: "PaymentData",
        paymentTotal?: number | null,
        paymentTodo?: number | null,
        depositPending?: boolean | null,
        deposit?: number | null,
      } | null,
      signStatus?: SignStatus | null,
      signDocumentId?: string | null,
      signerName?: string | null,
      reviewStatus?: ReviewStatus | null,
      authStatus?: AuthStatus | null,
      authTime?: string | null,
      completeBefore?: string | null,
      mustSign?: boolean | null,
      livionData?:  Array< {
        __typename: "LivionData",
        id?: string | null,
        pincode?: string | null,
        keyId?: string | null,
        name?: string | null,
        registrationPlate?: string | null,
      } | null > | null,
      tilakoneData?:  {
        __typename: "TilakoneData",
        access_id?: string | null,
        begin_timestamp?: string | null,
        end_timestamp?: string | null,
        pincode?: string | null,
      } | null,
      voucherData?:  {
        __typename: "VoucherData",
        name: string,
        code: string,
        amount: number,
      } | null,
      customerCarRegistrationPlate?: string | null,
      insuranceData?:  {
        __typename: "InsuranceData",
        customerContact?: boolean | null,
        urgent?: boolean | null,
        customerCarModel?: string | null,
        customerCarMaker?: string | null,
        product?: string | null,
        coveragePercentage?: number | null,
        compensatableDays?: string | null,
        billingPermitCode?: string | null,
        coverageDuration?: number | null,
        providerCompany?: string | null,
        providerBusinessId?: string | null,
        claimAdjusterName?: string | null,
        claimAdjusterEmail?: string | null,
        receipt?: boolean | null,
        replacementVehicleClass?: string | null,
        claimId?: string | null,
        vatDeductible?: boolean | null,
        deliveryCosts?: boolean | null,
        returnCosts?: boolean | null,
      } | null,
      externalData?:  {
        __typename: "ExternalData",
        orderId?: string | null,
        orderReference?: string | null,
        serviceAgreementName?: string | null,
        operatorName?: string | null,
        orderInfo?: string | null,
        billingPayer?: string | null,
        billingDescription?: string | null,
      } | null,
      enableRegistrationPlate?: boolean | null,
      disablePricesInPrintouts?: boolean | null,
      hanselData?:  {
        __typename: "HanselData",
        contractNro?: string | null,
        customerInvoiceId?: string | null,
        customerInvoiceDate?: string | null,
        hanselAgent?: string | null,
        hanselClass?: string | null,
        customerId?: string | null,
        subContractorNumber?: string | null,
        subContractorName?: string | null,
        suplierProductId?: string | null,
        suplierProductName?: string | null,
        quantity?: string | null,
        price?: string | null,
        totalPrice?: string | null,
        priceNoVAT?: string | null,
        additionalInfo1?: string | null,
        additionalInfo2?: string | null,
        additionalInfo3?: string | null,
        desc?: string | null,
      } | null,
      invoiceIds?: Array< string > | null,
      consolidatedInvoiceIds?: Array< string > | null,
      channel?: ReservationChannel | null,
      originURL?: string | null,
      type?: ReservationType | null,
      status?: string | null,
      reservationStatusInfo?:  {
        __typename: "ReservationStatusInfo",
        deliveryTimestamp?: string | null,
        deliveryAddress?: string | null,
        deliveryZipcode?: string | null,
        deliveryCity?: string | null,
        deliveryCountry?: string | null,
        deliveryDescription?: string | null,
        returnTimestamp?: string | null,
        returnAddress?: string | null,
        returnZipcode?: string | null,
        returnCity?: string | null,
        returnCountry?: string | null,
        returnDescription?: string | null,
        cancelReason?: string | null,
      } | null,
      initialCategory?:  {
        __typename: "CategoryInfo",
        id?: string | null,
        name?: string | null,
        hanselClass?: string | null,
      } | null,
      initialClassInfo?:  {
        __typename: "InitialClassInfo",
        categoryId?: string | null,
        categoryName?: string | null,
        hanselClass?: string | null,
      } | null,
      orgInvoiceCustomer?:  {
        __typename: "OrganizationInvoiceCustomer",
        name?: string | null,
        businessId?: string | null,
      } | null,
      pricingCategoryId?: string | null,
      customImportDate?: string | null,
      vehicle?:  {
        __typename: "Vehicle",
        id: string,
        group?: string | null,
        organizationId?: string | null,
        orgBusinessId?: string | null,
        companyId: string,
        name: string,
        registrationPlate?: string | null,
        damages?: string | null,
        image?: string | null,
        powerType?: string | null,
        deductible?: number | null,
        includedDistance?: string | null,
        mileage?: string | null,
        orderNumber?: number | null,
        vehicleCategoryId?: string | null,
        useCategoryDefaults?: boolean | null,
        useStartReturnTimes?: boolean | null,
        hideExport?: boolean | null,
        extraInfo?: string | null,
        extraInfoT?: string | null,
        extraToConfirmation?: string | null,
        extraToConfirmationT?: string | null,
        livionKeyId?: string | null,
        phoneNumber?: string | null,
        importData?: string | null,
        disableOnline?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      invoices?:  {
        __typename: "ModelInvoiceConnection",
        nextToken?: string | null,
      } | null,
      paymentEvents?:  {
        __typename: "ModelPaymentEventConnection",
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type CreateAdditionalServiceMutationVariables = {
  input: CreateAdditionalServiceInput,
  condition?: ModelAdditionalServiceConditionInput | null,
};

export type CreateAdditionalServiceMutation = {
  createAdditionalService?:  {
    __typename: "AdditionalService",
    id: string,
    organizationId?: string | null,
    orgBusinessId?: string | null,
    group?: string | null,
    description?: string | null,
    descriptionT?:  {
      __typename: "Translation",
      fi?: string | null,
      en?: string | null,
      sv?: string | null,
    } | null,
    description2?: string | null,
    category: string,
    type?: ServiceType | null,
    enableTextInput?: boolean | null,
    enableQuantity?: boolean | null,
    enableNotification?: boolean | null,
    maxQuantity?: number | null,
    inputLabel?: string | null,
    _removed?: string | null,
    isGroupParent?: boolean | null,
    groupFields?: Array< string | null > | null,
    groupParentId?: string | null,
    groupOrderNumber?: number | null,
    image?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateAdditionalServiceMutationVariables = {
  input: UpdateAdditionalServiceInput,
  condition?: ModelAdditionalServiceConditionInput | null,
};

export type UpdateAdditionalServiceMutation = {
  updateAdditionalService?:  {
    __typename: "AdditionalService",
    id: string,
    organizationId?: string | null,
    orgBusinessId?: string | null,
    group?: string | null,
    description?: string | null,
    descriptionT?:  {
      __typename: "Translation",
      fi?: string | null,
      en?: string | null,
      sv?: string | null,
    } | null,
    description2?: string | null,
    category: string,
    type?: ServiceType | null,
    enableTextInput?: boolean | null,
    enableQuantity?: boolean | null,
    enableNotification?: boolean | null,
    maxQuantity?: number | null,
    inputLabel?: string | null,
    _removed?: string | null,
    isGroupParent?: boolean | null,
    groupFields?: Array< string | null > | null,
    groupParentId?: string | null,
    groupOrderNumber?: number | null,
    image?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteAdditionalServiceMutationVariables = {
  input: DeleteAdditionalServiceInput,
  condition?: ModelAdditionalServiceConditionInput | null,
};

export type DeleteAdditionalServiceMutation = {
  deleteAdditionalService?:  {
    __typename: "AdditionalService",
    id: string,
    organizationId?: string | null,
    orgBusinessId?: string | null,
    group?: string | null,
    description?: string | null,
    descriptionT?:  {
      __typename: "Translation",
      fi?: string | null,
      en?: string | null,
      sv?: string | null,
    } | null,
    description2?: string | null,
    category: string,
    type?: ServiceType | null,
    enableTextInput?: boolean | null,
    enableQuantity?: boolean | null,
    enableNotification?: boolean | null,
    maxQuantity?: number | null,
    inputLabel?: string | null,
    _removed?: string | null,
    isGroupParent?: boolean | null,
    groupFields?: Array< string | null > | null,
    groupParentId?: string | null,
    groupOrderNumber?: number | null,
    image?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreatePricingMutationVariables = {
  input: CreatePricingInput,
  condition?: ModelPricingConditionInput | null,
};

export type CreatePricingMutation = {
  createPricing?:  {
    __typename: "Pricing",
    id: string,
    name: string,
    group?: string | null,
    organizationId?: string | null,
    orgBusinessId?: string | null,
    orgExternalBusinessId?: string | null,
    externalBusinessCategoryAssociation?: string | null,
    businessCategoryAssociation?: string | null,
    elements:  Array< {
      __typename: "PricingElement",
      duration: string,
      price: number,
    } >,
    useNextDayPrice?: boolean | null,
    useLastDayPrice?: boolean | null,
    tax?: number | null,
    _removed?: string | null,
    deductible?: number | null,
    reducedDeductible?: number | null,
    reducedDeductiblePrice?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdatePricingMutationVariables = {
  input: UpdatePricingInput,
  condition?: ModelPricingConditionInput | null,
};

export type UpdatePricingMutation = {
  updatePricing?:  {
    __typename: "Pricing",
    id: string,
    name: string,
    group?: string | null,
    organizationId?: string | null,
    orgBusinessId?: string | null,
    orgExternalBusinessId?: string | null,
    externalBusinessCategoryAssociation?: string | null,
    businessCategoryAssociation?: string | null,
    elements:  Array< {
      __typename: "PricingElement",
      duration: string,
      price: number,
    } >,
    useNextDayPrice?: boolean | null,
    useLastDayPrice?: boolean | null,
    tax?: number | null,
    _removed?: string | null,
    deductible?: number | null,
    reducedDeductible?: number | null,
    reducedDeductiblePrice?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeletePricingMutationVariables = {
  input: DeletePricingInput,
  condition?: ModelPricingConditionInput | null,
};

export type DeletePricingMutation = {
  deletePricing?:  {
    __typename: "Pricing",
    id: string,
    name: string,
    group?: string | null,
    organizationId?: string | null,
    orgBusinessId?: string | null,
    orgExternalBusinessId?: string | null,
    externalBusinessCategoryAssociation?: string | null,
    businessCategoryAssociation?: string | null,
    elements:  Array< {
      __typename: "PricingElement",
      duration: string,
      price: number,
    } >,
    useNextDayPrice?: boolean | null,
    useLastDayPrice?: boolean | null,
    tax?: number | null,
    _removed?: string | null,
    deductible?: number | null,
    reducedDeductible?: number | null,
    reducedDeductiblePrice?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreatePricingExceptionMutationVariables = {
  input: CreatePricingExceptionInput,
  condition?: ModelPricingExceptionConditionInput | null,
};

export type CreatePricingExceptionMutation = {
  createPricingException?:  {
    __typename: "PricingException",
    id: string,
    name: string,
    group?: string | null,
    organizationId?: string | null,
    orgBusinessId?: string | null,
    type: PricingExceptionType,
    amountType: PricingExceptionAmountType,
    weeklyAmounts?: Array< number > | null,
    monthlyAmountPerDay?: number | null,
    daysBeforeMonthChange?: number | null,
    daysAfterMonthChange?: number | null,
    startDate?: string | null,
    endDate?: string | null,
    pricingIds?: Array< string > | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdatePricingExceptionMutationVariables = {
  input: UpdatePricingExceptionInput,
  condition?: ModelPricingExceptionConditionInput | null,
};

export type UpdatePricingExceptionMutation = {
  updatePricingException?:  {
    __typename: "PricingException",
    id: string,
    name: string,
    group?: string | null,
    organizationId?: string | null,
    orgBusinessId?: string | null,
    type: PricingExceptionType,
    amountType: PricingExceptionAmountType,
    weeklyAmounts?: Array< number > | null,
    monthlyAmountPerDay?: number | null,
    daysBeforeMonthChange?: number | null,
    daysAfterMonthChange?: number | null,
    startDate?: string | null,
    endDate?: string | null,
    pricingIds?: Array< string > | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeletePricingExceptionMutationVariables = {
  input: DeletePricingExceptionInput,
  condition?: ModelPricingExceptionConditionInput | null,
};

export type DeletePricingExceptionMutation = {
  deletePricingException?:  {
    __typename: "PricingException",
    id: string,
    name: string,
    group?: string | null,
    organizationId?: string | null,
    orgBusinessId?: string | null,
    type: PricingExceptionType,
    amountType: PricingExceptionAmountType,
    weeklyAmounts?: Array< number > | null,
    monthlyAmountPerDay?: number | null,
    daysBeforeMonthChange?: number | null,
    daysAfterMonthChange?: number | null,
    startDate?: string | null,
    endDate?: string | null,
    pricingIds?: Array< string > | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateVoucherMutationVariables = {
  input: CreateVoucherInput,
  condition?: ModelVoucherConditionInput | null,
};

export type CreateVoucherMutation = {
  createVoucher?:  {
    __typename: "Voucher",
    id: string,
    name: string,
    group?: string | null,
    organizationId?: string | null,
    code: string,
    amount: number,
    startDate?: string | null,
    endDate?: string | null,
    _removed?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateVoucherMutationVariables = {
  input: UpdateVoucherInput,
  condition?: ModelVoucherConditionInput | null,
};

export type UpdateVoucherMutation = {
  updateVoucher?:  {
    __typename: "Voucher",
    id: string,
    name: string,
    group?: string | null,
    organizationId?: string | null,
    code: string,
    amount: number,
    startDate?: string | null,
    endDate?: string | null,
    _removed?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteVoucherMutationVariables = {
  input: DeleteVoucherInput,
  condition?: ModelVoucherConditionInput | null,
};

export type DeleteVoucherMutation = {
  deleteVoucher?:  {
    __typename: "Voucher",
    id: string,
    name: string,
    group?: string | null,
    organizationId?: string | null,
    code: string,
    amount: number,
    startDate?: string | null,
    endDate?: string | null,
    _removed?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateReviewMutationVariables = {
  input: CreateReviewInput,
  condition?: ModelReviewConditionInput | null,
};

export type CreateReviewMutation = {
  createReview?:  {
    __typename: "Review",
    id: string,
    group?: string | null,
    organizationId?: string | null,
    orgBusinessId?: string | null,
    orgExternalBusinessId?: string | null,
    rating: number,
    comment?: string | null,
    explanation?: string | null,
    reservationId: string,
    companyId?: string | null,
    startTime: string,
    returnTime: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateReviewMutationVariables = {
  input: UpdateReviewInput,
  condition?: ModelReviewConditionInput | null,
};

export type UpdateReviewMutation = {
  updateReview?:  {
    __typename: "Review",
    id: string,
    group?: string | null,
    organizationId?: string | null,
    orgBusinessId?: string | null,
    orgExternalBusinessId?: string | null,
    rating: number,
    comment?: string | null,
    explanation?: string | null,
    reservationId: string,
    companyId?: string | null,
    startTime: string,
    returnTime: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteReviewMutationVariables = {
  input: DeleteReviewInput,
  condition?: ModelReviewConditionInput | null,
};

export type DeleteReviewMutation = {
  deleteReview?:  {
    __typename: "Review",
    id: string,
    group?: string | null,
    organizationId?: string | null,
    orgBusinessId?: string | null,
    orgExternalBusinessId?: string | null,
    rating: number,
    comment?: string | null,
    explanation?: string | null,
    reservationId: string,
    companyId?: string | null,
    startTime: string,
    returnTime: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateNotificationMutationVariables = {
  input: CreateNotificationInput,
  condition?: ModelNotificationConditionInput | null,
};

export type CreateNotificationMutation = {
  createNotification?:  {
    __typename: "Notification",
    id: string,
    type: NotificationType,
    status?: NotificationStatus | null,
    name?: string | null,
    message?: string | null,
    organizationId?: string | null,
    orgBusinessId?: string | null,
    group?: string | null,
    reservationId?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateNotificationMutationVariables = {
  input: UpdateNotificationInput,
  condition?: ModelNotificationConditionInput | null,
};

export type UpdateNotificationMutation = {
  updateNotification?:  {
    __typename: "Notification",
    id: string,
    type: NotificationType,
    status?: NotificationStatus | null,
    name?: string | null,
    message?: string | null,
    organizationId?: string | null,
    orgBusinessId?: string | null,
    group?: string | null,
    reservationId?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteNotificationMutationVariables = {
  input: DeleteNotificationInput,
  condition?: ModelNotificationConditionInput | null,
};

export type DeleteNotificationMutation = {
  deleteNotification?:  {
    __typename: "Notification",
    id: string,
    type: NotificationType,
    status?: NotificationStatus | null,
    name?: string | null,
    message?: string | null,
    organizationId?: string | null,
    orgBusinessId?: string | null,
    group?: string | null,
    reservationId?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateCustomerMutationVariables = {
  input: CreateCustomerInput,
  condition?: ModelCustomerConditionInput | null,
};

export type CreateCustomerMutation = {
  createCustomer?:  {
    __typename: "Customer",
    id: string,
    organizationId?: string | null,
    orgBusinessId?: string | null,
    orgExternalBusinessId?: string | null,
    group?: string | null,
    companyName?: string | null,
    brokerName?: string | null,
    address?: string | null,
    postalCode?: string | null,
    city?: string | null,
    phone?: string | null,
    email?: string | null,
    companyBillingAddress?: string | null,
    companyBusinessId?: string | null,
    companyCity?: string | null,
    companyPostalCode?: string | null,
    companyBillingRef?: string | null,
    companyEBillingOperator?: string | null,
    companyEBillingAddress?: string | null,
    clientType?: string | null,
    isDeleted?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateCustomerMutationVariables = {
  input: UpdateCustomerInput,
  condition?: ModelCustomerConditionInput | null,
};

export type UpdateCustomerMutation = {
  updateCustomer?:  {
    __typename: "Customer",
    id: string,
    organizationId?: string | null,
    orgBusinessId?: string | null,
    orgExternalBusinessId?: string | null,
    group?: string | null,
    companyName?: string | null,
    brokerName?: string | null,
    address?: string | null,
    postalCode?: string | null,
    city?: string | null,
    phone?: string | null,
    email?: string | null,
    companyBillingAddress?: string | null,
    companyBusinessId?: string | null,
    companyCity?: string | null,
    companyPostalCode?: string | null,
    companyBillingRef?: string | null,
    companyEBillingOperator?: string | null,
    companyEBillingAddress?: string | null,
    clientType?: string | null,
    isDeleted?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteCustomerMutationVariables = {
  input: DeleteCustomerInput,
  condition?: ModelCustomerConditionInput | null,
};

export type DeleteCustomerMutation = {
  deleteCustomer?:  {
    __typename: "Customer",
    id: string,
    organizationId?: string | null,
    orgBusinessId?: string | null,
    orgExternalBusinessId?: string | null,
    group?: string | null,
    companyName?: string | null,
    brokerName?: string | null,
    address?: string | null,
    postalCode?: string | null,
    city?: string | null,
    phone?: string | null,
    email?: string | null,
    companyBillingAddress?: string | null,
    companyBusinessId?: string | null,
    companyCity?: string | null,
    companyPostalCode?: string | null,
    companyBillingRef?: string | null,
    companyEBillingOperator?: string | null,
    companyEBillingAddress?: string | null,
    clientType?: string | null,
    isDeleted?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type GetReservationOffersAdminQueryVariables = {
  companyIds: Array< string >,
  reservationCompanyId?: string | null,
  group?: string | null,
  businessId?: string | null,
  externalBusinessId?: string | null,
  startTime: string,
  returnTime: string,
  disableCategoryDefaultsStacking?: boolean | null,
};

export type GetReservationOffersAdminQuery = {
  getReservationOffersAdmin:  Array< {
    __typename: "ReservationOffer",
    startTime: string,
    returnTime: string,
    vehicleOfferPrice: number,
    tax?: number | null,
    additionalServiceOffers:  Array< {
      __typename: "AdditionalServiceOffer",
      offerPrice: number,
      tax?: number | null,
      deductibleValue?: number | null,
      description?: string | null,
      descriptionT?:  {
        __typename: "Translation",
        fi?: string | null,
        en?: string | null,
        sv?: string | null,
      } | null,
      category?: string | null,
      adHocService?: boolean | null,
      image?: string | null,
      key: string,
    } >,
    freeTimes:  Array< {
      __typename: "FreeTime",
      lastStartTime: string,
      lastReturnTime: string,
      returnTimes?:  Array< {
        __typename: "DateRange",
        end: string,
        start: string,
      } | null > | null,
      firstStartTime: string,
    } >,
    reservedTimes?:  Array< {
      __typename: "DateRange",
      end: string,
      start: string,
    } | null > | null,
    availableInCategory?: number | null,
    useVehicleTimes?: boolean | null,
    disableOffHourReturn?: boolean | null,
    vehicle:  {
      __typename: "Vehicle",
      id: string,
      group?: string | null,
      organizationId?: string | null,
      orgBusinessId?: string | null,
      companyId: string,
      name: string,
      nameT?:  {
        __typename: "Translation",
        fi?: string | null,
        en?: string | null,
        sv?: string | null,
      } | null,
      registrationPlate?: string | null,
      damages?: string | null,
      damageRecords?:  Array< {
        __typename: "DamageRecord",
        normalizedX?: number | null,
        normalizedY?: number | null,
        label?: string | null,
        description?: string | null,
        createdAt?: string | null,
      } > | null,
      additionalFields:  Array< {
        __typename: "AdditionalField",
        key: string,
        value?: string | null,
      } >,
      image?: string | null,
      powerType?: string | null,
      deductible?: number | null,
      includedDistance?: string | null,
      mileage?: string | null,
      orderNumber?: number | null,
      vehicleCategoryId?: string | null,
      useCategoryDefaults?: boolean | null,
      useStartReturnTimes?: boolean | null,
      customStartReturns?:  Array< {
        __typename: "CustomStartReturn",
        priority?: number | null,
      } | null > | null,
      startReturnInterval?:  {
        __typename: "StartReturnInterval",
        start?: string | null,
        end?: string | null,
      } | null,
      startReturnTimes?:  Array< {
        __typename: "StartReturnTimes",
        start?: Array< string | null > | null,
        return?: Array< string | null > | null,
      } | null > | null,
      hideExport?: boolean | null,
      extraInfo?: string | null,
      extraInfoT?: string | null,
      extraToConfirmation?: string | null,
      extraToConfirmationT?: string | null,
      livionKeyId?: string | null,
      phoneNumber?: string | null,
      importData?: string | null,
      disableOnline?: boolean | null,
      activeRange?:  {
        __typename: "StartReturnInterval",
        start?: string | null,
        end?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      category?:  {
        __typename: "Category",
        id: string,
        group?: string | null,
        organizationId?: string | null,
        name: string,
        image?: string | null,
        orderNumber?: number | null,
        backgroundColor?: string | null,
        minuteBuffer?: number | null,
        reservationBlockFromNow?: number | null,
        typeId?: string | null,
        _removed?: string | null,
        oldTujausValue?: string | null,
        exampleModelName?: string | null,
        exampleModelImage?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      additionalServices?:  {
        __typename: "ModelAdditionalServicePricingConnection",
        nextToken?: string | null,
      } | null,
      reservations?:  {
        __typename: "ModelReservationConnection",
        nextToken?: string | null,
      } | null,
      pricing?:  {
        __typename: "Pricing",
        id: string,
        name: string,
        group?: string | null,
        organizationId?: string | null,
        orgBusinessId?: string | null,
        orgExternalBusinessId?: string | null,
        externalBusinessCategoryAssociation?: string | null,
        businessCategoryAssociation?: string | null,
        useNextDayPrice?: boolean | null,
        useLastDayPrice?: boolean | null,
        tax?: number | null,
        _removed?: string | null,
        deductible?: number | null,
        reducedDeductible?: number | null,
        reducedDeductiblePrice?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null,
    },
  } >,
};

export type GetReservationsQueryVariables = {
  startTime: string,
  returnTime?: string | null,
  endTime?: string | null,
  group: string,
  queryMode: string,
};

export type GetReservationsQuery = {
  getReservations:  Array< {
    __typename: "Reservation",
    id: string,
    shortId?: string | null,
    language?: Language | null,
    group?: string | null,
    organizationId?: string | null,
    orgBusinessId?: string | null,
    orgExternalBusinessId?: string | null,
    companyId?: string | null,
    returnCompanyId?: string | null,
    startLocation?: string | null,
    returnLocation?: string | null,
    startLocationID?: string | null,
    returnLocationID?: string | null,
    reviewId?: string | null,
    customerBrokerId?: string | null,
    customerCompanyId?: string | null,
    customerPersonalId?: string | null,
    reservationVehicleId?: string | null,
    reservationVehicles?:  Array< {
      __typename: "ReservationVehicle",
      id?: string | null,
      price?: number | null,
      tax?: number | null,
      name?: string | null,
      registrationPlate?: string | null,
      deductible?: number | null,
      includedDistance?: string | null,
      powerType?: string | null,
      mileage?: string | null,
      startMileage?: string | null,
      endMileage?: string | null,
      extraInfo?: string | null,
      damages?: string | null,
      hideExport?: boolean | null,
      createdAt?: string | null,
      addedAt?: string | null,
      categoryName?: string | null,
    } | null > | null,
    wildCardVehicles?:  Array< {
      __typename: "WildCardVehicle",
      name?: string | null,
      price?: number | null,
      tax?: number | null,
      categoryId?: string | null,
      quantity?: number | null,
      deductible?: number | null,
    } | null > | null,
    createdAt: string,
    updatedAt: string,
    startTime?: string | null,
    returnTime?: string | null,
    endTime?: string | null,
    name?: string | null,
    address?: string | null,
    postalCode?: string | null,
    city?: string | null,
    phone?: string | null,
    email?: string | null,
    billingRef?: string | null,
    customerNotes?: string | null,
    ssn?: string | null,
    rentalNotes?: string | null,
    internalNotes?: string | null,
    externalNotes?: string | null,
    outOfCommission?: boolean | null,
    vehiclePrice?: number | null,
    additionalServices?:  Array< {
      __typename: "AdditionalServiceReservation",
      key: string,
      price: number,
      details?: string | null,
      quantity?: number | null,
      type?: ServiceType | null,
      deductibleValue?: number | null,
      groupFields?: Array< string | null > | null,
      tax?: number | null,
      enableNotification?: boolean | null,
      description?: string | null,
      category?: string | null,
    } > | null,
    driverSSN?: string | null,
    differentDriver?: boolean | null,
    driverName?: string | null,
    driverAddress?: string | null,
    driverPostalCode?: string | null,
    driverCity?: string | null,
    driverPhone?: string | null,
    driverEmail?: string | null,
    additionalDriver?: boolean | null,
    additionalDriverName?: string | null,
    additionalDriverPhone?: string | null,
    additionalDriverSSN?: string | null,
    billPayment?: boolean | null,
    isCompany?: boolean | null,
    carStatus?: RentalStatus | null,
    rentalStatus?: RentalStatus | null,
    innovoiceStatus?: ReservationInnovoiceStatus | null,
    companyName?: string | null,
    companyBusinessId?: string | null,
    companyCity?: string | null,
    companyPostalCode?: string | null,
    companyBillingRef?: string | null,
    companyBillingAddress?: string | null,
    companyEBillingOperator?: string | null,
    companyEBillingAddress?: string | null,
    paymentToken?: string | null,
    npsStatus?: NpsStatus | null,
    paymentStatus?: PaymentStatus | null,
    paymentData?:  {
      __typename: "PaymentData",
      paymentTotal?: number | null,
      paymentTodo?: number | null,
      depositPending?: boolean | null,
      deposit?: number | null,
    } | null,
    signStatus?: SignStatus | null,
    signDocumentId?: string | null,
    signerName?: string | null,
    reviewStatus?: ReviewStatus | null,
    authStatus?: AuthStatus | null,
    authTime?: string | null,
    completeBefore?: string | null,
    mustSign?: boolean | null,
    livionData?:  Array< {
      __typename: "LivionData",
      id?: string | null,
      pincode?: string | null,
      keyId?: string | null,
      name?: string | null,
      registrationPlate?: string | null,
    } | null > | null,
    tilakoneData?:  {
      __typename: "TilakoneData",
      access_id?: string | null,
      begin_timestamp?: string | null,
      end_timestamp?: string | null,
      pincode?: string | null,
    } | null,
    voucherData?:  {
      __typename: "VoucherData",
      name: string,
      code: string,
      amount: number,
    } | null,
    customerCarRegistrationPlate?: string | null,
    insuranceData?:  {
      __typename: "InsuranceData",
      customerContact?: boolean | null,
      urgent?: boolean | null,
      customerCarModel?: string | null,
      customerCarMaker?: string | null,
      product?: string | null,
      coveragePercentage?: number | null,
      compensatableDays?: string | null,
      billingPermitCode?: string | null,
      coverageDuration?: number | null,
      providerCompany?: string | null,
      providerBusinessId?: string | null,
      claimAdjusterName?: string | null,
      claimAdjusterEmail?: string | null,
      receipt?: boolean | null,
      replacementVehicleClass?: string | null,
      claimId?: string | null,
      vatDeductible?: boolean | null,
      deliveryCosts?: boolean | null,
      returnCosts?: boolean | null,
    } | null,
    externalData?:  {
      __typename: "ExternalData",
      orderId?: string | null,
      orderReference?: string | null,
      serviceAgreementName?: string | null,
      operatorName?: string | null,
      orderInfo?: string | null,
      billingPayer?: string | null,
      billingDescription?: string | null,
      customerVehicle?:  {
        __typename: "CustomerVehicle",
        brand?: string | null,
        model?: string | null,
        registrationPlate?: string | null,
      } | null,
      incidentLocation?:  {
        __typename: "Location",
        description?: string | null,
        address?: string | null,
        city?: string | null,
        zipCode?: string | null,
      } | null,
      repairShop?:  {
        __typename: "Location",
        description?: string | null,
        address?: string | null,
        city?: string | null,
        zipCode?: string | null,
      } | null,
    } | null,
    enableRegistrationPlate?: boolean | null,
    disablePricesInPrintouts?: boolean | null,
    hanselData?:  {
      __typename: "HanselData",
      contractNro?: string | null,
      customerInvoiceId?: string | null,
      customerInvoiceDate?: string | null,
      hanselAgent?: string | null,
      hanselClass?: string | null,
      customerId?: string | null,
      subContractorNumber?: string | null,
      subContractorName?: string | null,
      suplierProductId?: string | null,
      suplierProductName?: string | null,
      quantity?: string | null,
      price?: string | null,
      totalPrice?: string | null,
      priceNoVAT?: string | null,
      additionalInfo1?: string | null,
      additionalInfo2?: string | null,
      additionalInfo3?: string | null,
      desc?: string | null,
    } | null,
    invoiceIds?: Array< string > | null,
    consolidatedInvoiceIds?: Array< string > | null,
    channel?: ReservationChannel | null,
    originURL?: string | null,
    type?: ReservationType | null,
    status?: string | null,
    reservationStatusInfo?:  {
      __typename: "ReservationStatusInfo",
      deliveryTimestamp?: string | null,
      deliveryAddress?: string | null,
      deliveryZipcode?: string | null,
      deliveryCity?: string | null,
      deliveryCountry?: string | null,
      deliveryDescription?: string | null,
      returnTimestamp?: string | null,
      returnAddress?: string | null,
      returnZipcode?: string | null,
      returnCity?: string | null,
      returnCountry?: string | null,
      returnDescription?: string | null,
      cancelReason?: string | null,
    } | null,
    initialCategory?:  {
      __typename: "CategoryInfo",
      id?: string | null,
      name?: string | null,
      hanselClass?: string | null,
    } | null,
    initialClassInfo?:  {
      __typename: "InitialClassInfo",
      categoryId?: string | null,
      categoryName?: string | null,
      hanselClass?: string | null,
    } | null,
    orgInvoiceCustomer?:  {
      __typename: "OrganizationInvoiceCustomer",
      name?: string | null,
      businessId?: string | null,
    } | null,
    pricingCategoryId?: string | null,
    customImportDate?: string | null,
    vehicle?:  {
      __typename: "Vehicle",
      id: string,
      group?: string | null,
      organizationId?: string | null,
      orgBusinessId?: string | null,
      companyId: string,
      name: string,
      nameT?:  {
        __typename: "Translation",
        fi?: string | null,
        en?: string | null,
        sv?: string | null,
      } | null,
      registrationPlate?: string | null,
      damages?: string | null,
      damageRecords?:  Array< {
        __typename: "DamageRecord",
        normalizedX?: number | null,
        normalizedY?: number | null,
        label?: string | null,
        description?: string | null,
        createdAt?: string | null,
      } > | null,
      additionalFields:  Array< {
        __typename: "AdditionalField",
        key: string,
        value?: string | null,
      } >,
      image?: string | null,
      powerType?: string | null,
      deductible?: number | null,
      includedDistance?: string | null,
      mileage?: string | null,
      orderNumber?: number | null,
      vehicleCategoryId?: string | null,
      useCategoryDefaults?: boolean | null,
      useStartReturnTimes?: boolean | null,
      customStartReturns?:  Array< {
        __typename: "CustomStartReturn",
        priority?: number | null,
      } | null > | null,
      startReturnInterval?:  {
        __typename: "StartReturnInterval",
        start?: string | null,
        end?: string | null,
      } | null,
      startReturnTimes?:  Array< {
        __typename: "StartReturnTimes",
        start?: Array< string | null > | null,
        return?: Array< string | null > | null,
      } | null > | null,
      hideExport?: boolean | null,
      extraInfo?: string | null,
      extraInfoT?: string | null,
      extraToConfirmation?: string | null,
      extraToConfirmationT?: string | null,
      livionKeyId?: string | null,
      phoneNumber?: string | null,
      importData?: string | null,
      disableOnline?: boolean | null,
      activeRange?:  {
        __typename: "StartReturnInterval",
        start?: string | null,
        end?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      category?:  {
        __typename: "Category",
        id: string,
        group?: string | null,
        organizationId?: string | null,
        name: string,
        image?: string | null,
        orderNumber?: number | null,
        backgroundColor?: string | null,
        minuteBuffer?: number | null,
        reservationBlockFromNow?: number | null,
        typeId?: string | null,
        _removed?: string | null,
        oldTujausValue?: string | null,
        exampleModelName?: string | null,
        exampleModelImage?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      additionalServices?:  {
        __typename: "ModelAdditionalServicePricingConnection",
        nextToken?: string | null,
      } | null,
      reservations?:  {
        __typename: "ModelReservationConnection",
        nextToken?: string | null,
      } | null,
      pricing?:  {
        __typename: "Pricing",
        id: string,
        name: string,
        group?: string | null,
        organizationId?: string | null,
        orgBusinessId?: string | null,
        orgExternalBusinessId?: string | null,
        externalBusinessCategoryAssociation?: string | null,
        businessCategoryAssociation?: string | null,
        useNextDayPrice?: boolean | null,
        useLastDayPrice?: boolean | null,
        tax?: number | null,
        _removed?: string | null,
        deductible?: number | null,
        reducedDeductible?: number | null,
        reducedDeductiblePrice?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null,
    } | null,
    invoices?:  {
      __typename: "ModelInvoiceConnection",
      items:  Array< {
        __typename: "Invoice",
        id: string,
        createdAt: string,
        innovoiceId?: string | null,
        reservationId?: string | null,
        reservationIds?: Array< string > | null,
        companyId?: string | null,
        innovoiceCompanyId?: string | null,
        innovoiceuRentCarRentalId?: string | null,
        innovoiceOrderId?: string | null,
        group?: string | null,
        organizationId?: string | null,
        orgBusinessId?: string | null,
        type?: InvoicePaymentType | null,
        creditNoteInvoiceId?: string | null,
        payment_terms?: number | null,
        vat_included?: number | null,
        delivery_by: InvoiceDeliveryBy,
        invoice_type: InvoiceType,
        delivery_date?: string | null,
        seller_reference?: string | null,
        buyer_reference?: string | null,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    paymentEvents?:  {
      __typename: "ModelPaymentEventConnection",
      items:  Array< {
        __typename: "PaymentEvent",
        id: string,
        channel?: string | null,
        paymentMethod?: string | null,
        paymentTotal?: number | null,
        isDeleted?: boolean | null,
        deletedBy?: string | null,
        deletedAt?: string | null,
        createdByUserID?: string | null,
        createdByUserName?: string | null,
        reservationId?: string | null,
        organizationId?: string | null,
        orgBusinessId?: string | null,
        group?: string | null,
        msg?: string | null,
        state?: PaymentEventState | null,
        returnCode?: number | null,
        incidentId?: string | null,
        parameters?: string | null,
        apiPath?: string | null,
        paymentStatus?: string | null,
        orderNumber?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
  } >,
};

export type GetPriceByCategoryIdsQueryVariables = {
  categoryIds: Array< string >,
  startTime: string,
  returnTime: string,
  externalBusinessId?: string | null,
};

export type GetPriceByCategoryIdsQuery = {
  getPriceByCategoryIds:  {
    __typename: "CategoryPrices",
    categoryOfferPrices:  Array< {
      __typename: "ReservationVehiclePrice",
      price: number,
      tax?: number | null,
      deductible?: number | null,
      id: string,
    } >,
    additionalServiceOffers:  Array< {
      __typename: "AdditionalServicePrice",
      description?: string | null,
      category?: string | null,
      offerPrice: number,
      deductibleValue?: number | null,
      tax?: number | null,
      image?: string | null,
      enableQuantity?: boolean | null,
      maxQuantity?: number | null,
      key: string,
    } >,
  },
};

export type OperateCarDoorSMSQueryVariables = {
  vehicleId: string,
};

export type OperateCarDoorSMSQuery = {
  operateCarDoorSMS?: boolean | null,
};

export type InnovoiceCreditRequestQueryVariables = {
  orgBusinessId: string,
  ssn: string,
};

export type InnovoiceCreditRequestQuery = {
  innovoiceCreditRequest?:  {
    __typename: "CreditResult",
    granted?: boolean | null,
    amount?: number | null,
    creditDecisionText: string,
    message: string,
  } | null,
};

export type SearchReservationsByVehicleQueryVariables = {
  vehicleId: string,
  group?: string | null,
  orgBusinessId?: string | null,
};

export type SearchReservationsByVehicleQuery = {
  searchReservationsByVehicle:  Array< {
    __typename: "Reservation",
    id: string,
    shortId?: string | null,
    language?: Language | null,
    group?: string | null,
    organizationId?: string | null,
    orgBusinessId?: string | null,
    orgExternalBusinessId?: string | null,
    companyId?: string | null,
    returnCompanyId?: string | null,
    startLocation?: string | null,
    returnLocation?: string | null,
    startLocationID?: string | null,
    returnLocationID?: string | null,
    reviewId?: string | null,
    customerBrokerId?: string | null,
    customerCompanyId?: string | null,
    customerPersonalId?: string | null,
    reservationVehicleId?: string | null,
    reservationVehicles?:  Array< {
      __typename: "ReservationVehicle",
      id?: string | null,
      price?: number | null,
      tax?: number | null,
      name?: string | null,
      registrationPlate?: string | null,
      deductible?: number | null,
      includedDistance?: string | null,
      powerType?: string | null,
      mileage?: string | null,
      startMileage?: string | null,
      endMileage?: string | null,
      extraInfo?: string | null,
      damages?: string | null,
      hideExport?: boolean | null,
      createdAt?: string | null,
      addedAt?: string | null,
      categoryName?: string | null,
    } | null > | null,
    wildCardVehicles?:  Array< {
      __typename: "WildCardVehicle",
      name?: string | null,
      price?: number | null,
      tax?: number | null,
      categoryId?: string | null,
      quantity?: number | null,
      deductible?: number | null,
    } | null > | null,
    createdAt: string,
    updatedAt: string,
    startTime?: string | null,
    returnTime?: string | null,
    endTime?: string | null,
    name?: string | null,
    address?: string | null,
    postalCode?: string | null,
    city?: string | null,
    phone?: string | null,
    email?: string | null,
    billingRef?: string | null,
    customerNotes?: string | null,
    ssn?: string | null,
    rentalNotes?: string | null,
    internalNotes?: string | null,
    externalNotes?: string | null,
    outOfCommission?: boolean | null,
    vehiclePrice?: number | null,
    additionalServices?:  Array< {
      __typename: "AdditionalServiceReservation",
      key: string,
      price: number,
      details?: string | null,
      quantity?: number | null,
      type?: ServiceType | null,
      deductibleValue?: number | null,
      groupFields?: Array< string | null > | null,
      tax?: number | null,
      enableNotification?: boolean | null,
      description?: string | null,
      category?: string | null,
    } > | null,
    driverSSN?: string | null,
    differentDriver?: boolean | null,
    driverName?: string | null,
    driverAddress?: string | null,
    driverPostalCode?: string | null,
    driverCity?: string | null,
    driverPhone?: string | null,
    driverEmail?: string | null,
    additionalDriver?: boolean | null,
    additionalDriverName?: string | null,
    additionalDriverPhone?: string | null,
    additionalDriverSSN?: string | null,
    billPayment?: boolean | null,
    isCompany?: boolean | null,
    carStatus?: RentalStatus | null,
    rentalStatus?: RentalStatus | null,
    innovoiceStatus?: ReservationInnovoiceStatus | null,
    companyName?: string | null,
    companyBusinessId?: string | null,
    companyCity?: string | null,
    companyPostalCode?: string | null,
    companyBillingRef?: string | null,
    companyBillingAddress?: string | null,
    companyEBillingOperator?: string | null,
    companyEBillingAddress?: string | null,
    paymentToken?: string | null,
    npsStatus?: NpsStatus | null,
    paymentStatus?: PaymentStatus | null,
    paymentData?:  {
      __typename: "PaymentData",
      paymentTotal?: number | null,
      paymentTodo?: number | null,
      depositPending?: boolean | null,
      deposit?: number | null,
    } | null,
    signStatus?: SignStatus | null,
    signDocumentId?: string | null,
    signerName?: string | null,
    reviewStatus?: ReviewStatus | null,
    authStatus?: AuthStatus | null,
    authTime?: string | null,
    completeBefore?: string | null,
    mustSign?: boolean | null,
    livionData?:  Array< {
      __typename: "LivionData",
      id?: string | null,
      pincode?: string | null,
      keyId?: string | null,
      name?: string | null,
      registrationPlate?: string | null,
    } | null > | null,
    tilakoneData?:  {
      __typename: "TilakoneData",
      access_id?: string | null,
      begin_timestamp?: string | null,
      end_timestamp?: string | null,
      pincode?: string | null,
    } | null,
    voucherData?:  {
      __typename: "VoucherData",
      name: string,
      code: string,
      amount: number,
    } | null,
    customerCarRegistrationPlate?: string | null,
    insuranceData?:  {
      __typename: "InsuranceData",
      customerContact?: boolean | null,
      urgent?: boolean | null,
      customerCarModel?: string | null,
      customerCarMaker?: string | null,
      product?: string | null,
      coveragePercentage?: number | null,
      compensatableDays?: string | null,
      billingPermitCode?: string | null,
      coverageDuration?: number | null,
      providerCompany?: string | null,
      providerBusinessId?: string | null,
      claimAdjusterName?: string | null,
      claimAdjusterEmail?: string | null,
      receipt?: boolean | null,
      replacementVehicleClass?: string | null,
      claimId?: string | null,
      vatDeductible?: boolean | null,
      deliveryCosts?: boolean | null,
      returnCosts?: boolean | null,
    } | null,
    externalData?:  {
      __typename: "ExternalData",
      orderId?: string | null,
      orderReference?: string | null,
      serviceAgreementName?: string | null,
      operatorName?: string | null,
      orderInfo?: string | null,
      billingPayer?: string | null,
      billingDescription?: string | null,
      customerVehicle?:  {
        __typename: "CustomerVehicle",
        brand?: string | null,
        model?: string | null,
        registrationPlate?: string | null,
      } | null,
      incidentLocation?:  {
        __typename: "Location",
        description?: string | null,
        address?: string | null,
        city?: string | null,
        zipCode?: string | null,
      } | null,
      repairShop?:  {
        __typename: "Location",
        description?: string | null,
        address?: string | null,
        city?: string | null,
        zipCode?: string | null,
      } | null,
    } | null,
    enableRegistrationPlate?: boolean | null,
    disablePricesInPrintouts?: boolean | null,
    hanselData?:  {
      __typename: "HanselData",
      contractNro?: string | null,
      customerInvoiceId?: string | null,
      customerInvoiceDate?: string | null,
      hanselAgent?: string | null,
      hanselClass?: string | null,
      customerId?: string | null,
      subContractorNumber?: string | null,
      subContractorName?: string | null,
      suplierProductId?: string | null,
      suplierProductName?: string | null,
      quantity?: string | null,
      price?: string | null,
      totalPrice?: string | null,
      priceNoVAT?: string | null,
      additionalInfo1?: string | null,
      additionalInfo2?: string | null,
      additionalInfo3?: string | null,
      desc?: string | null,
    } | null,
    invoiceIds?: Array< string > | null,
    consolidatedInvoiceIds?: Array< string > | null,
    channel?: ReservationChannel | null,
    originURL?: string | null,
    type?: ReservationType | null,
    status?: string | null,
    reservationStatusInfo?:  {
      __typename: "ReservationStatusInfo",
      deliveryTimestamp?: string | null,
      deliveryAddress?: string | null,
      deliveryZipcode?: string | null,
      deliveryCity?: string | null,
      deliveryCountry?: string | null,
      deliveryDescription?: string | null,
      returnTimestamp?: string | null,
      returnAddress?: string | null,
      returnZipcode?: string | null,
      returnCity?: string | null,
      returnCountry?: string | null,
      returnDescription?: string | null,
      cancelReason?: string | null,
    } | null,
    initialCategory?:  {
      __typename: "CategoryInfo",
      id?: string | null,
      name?: string | null,
      hanselClass?: string | null,
    } | null,
    initialClassInfo?:  {
      __typename: "InitialClassInfo",
      categoryId?: string | null,
      categoryName?: string | null,
      hanselClass?: string | null,
    } | null,
    orgInvoiceCustomer?:  {
      __typename: "OrganizationInvoiceCustomer",
      name?: string | null,
      businessId?: string | null,
    } | null,
    pricingCategoryId?: string | null,
    customImportDate?: string | null,
    vehicle?:  {
      __typename: "Vehicle",
      id: string,
      group?: string | null,
      organizationId?: string | null,
      orgBusinessId?: string | null,
      companyId: string,
      name: string,
      nameT?:  {
        __typename: "Translation",
        fi?: string | null,
        en?: string | null,
        sv?: string | null,
      } | null,
      registrationPlate?: string | null,
      damages?: string | null,
      damageRecords?:  Array< {
        __typename: "DamageRecord",
        normalizedX?: number | null,
        normalizedY?: number | null,
        label?: string | null,
        description?: string | null,
        createdAt?: string | null,
      } > | null,
      additionalFields:  Array< {
        __typename: "AdditionalField",
        key: string,
        value?: string | null,
      } >,
      image?: string | null,
      powerType?: string | null,
      deductible?: number | null,
      includedDistance?: string | null,
      mileage?: string | null,
      orderNumber?: number | null,
      vehicleCategoryId?: string | null,
      useCategoryDefaults?: boolean | null,
      useStartReturnTimes?: boolean | null,
      customStartReturns?:  Array< {
        __typename: "CustomStartReturn",
        priority?: number | null,
      } | null > | null,
      startReturnInterval?:  {
        __typename: "StartReturnInterval",
        start?: string | null,
        end?: string | null,
      } | null,
      startReturnTimes?:  Array< {
        __typename: "StartReturnTimes",
        start?: Array< string | null > | null,
        return?: Array< string | null > | null,
      } | null > | null,
      hideExport?: boolean | null,
      extraInfo?: string | null,
      extraInfoT?: string | null,
      extraToConfirmation?: string | null,
      extraToConfirmationT?: string | null,
      livionKeyId?: string | null,
      phoneNumber?: string | null,
      importData?: string | null,
      disableOnline?: boolean | null,
      activeRange?:  {
        __typename: "StartReturnInterval",
        start?: string | null,
        end?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      category?:  {
        __typename: "Category",
        id: string,
        group?: string | null,
        organizationId?: string | null,
        name: string,
        image?: string | null,
        orderNumber?: number | null,
        backgroundColor?: string | null,
        minuteBuffer?: number | null,
        reservationBlockFromNow?: number | null,
        typeId?: string | null,
        _removed?: string | null,
        oldTujausValue?: string | null,
        exampleModelName?: string | null,
        exampleModelImage?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      additionalServices?:  {
        __typename: "ModelAdditionalServicePricingConnection",
        nextToken?: string | null,
      } | null,
      reservations?:  {
        __typename: "ModelReservationConnection",
        nextToken?: string | null,
      } | null,
      pricing?:  {
        __typename: "Pricing",
        id: string,
        name: string,
        group?: string | null,
        organizationId?: string | null,
        orgBusinessId?: string | null,
        orgExternalBusinessId?: string | null,
        externalBusinessCategoryAssociation?: string | null,
        businessCategoryAssociation?: string | null,
        useNextDayPrice?: boolean | null,
        useLastDayPrice?: boolean | null,
        tax?: number | null,
        _removed?: string | null,
        deductible?: number | null,
        reducedDeductible?: number | null,
        reducedDeductiblePrice?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null,
    } | null,
    invoices?:  {
      __typename: "ModelInvoiceConnection",
      items:  Array< {
        __typename: "Invoice",
        id: string,
        createdAt: string,
        innovoiceId?: string | null,
        reservationId?: string | null,
        reservationIds?: Array< string > | null,
        companyId?: string | null,
        innovoiceCompanyId?: string | null,
        innovoiceuRentCarRentalId?: string | null,
        innovoiceOrderId?: string | null,
        group?: string | null,
        organizationId?: string | null,
        orgBusinessId?: string | null,
        type?: InvoicePaymentType | null,
        creditNoteInvoiceId?: string | null,
        payment_terms?: number | null,
        vat_included?: number | null,
        delivery_by: InvoiceDeliveryBy,
        invoice_type: InvoiceType,
        delivery_date?: string | null,
        seller_reference?: string | null,
        buyer_reference?: string | null,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    paymentEvents?:  {
      __typename: "ModelPaymentEventConnection",
      items:  Array< {
        __typename: "PaymentEvent",
        id: string,
        channel?: string | null,
        paymentMethod?: string | null,
        paymentTotal?: number | null,
        isDeleted?: boolean | null,
        deletedBy?: string | null,
        deletedAt?: string | null,
        createdByUserID?: string | null,
        createdByUserName?: string | null,
        reservationId?: string | null,
        organizationId?: string | null,
        orgBusinessId?: string | null,
        group?: string | null,
        msg?: string | null,
        state?: PaymentEventState | null,
        returnCode?: number | null,
        incidentId?: string | null,
        parameters?: string | null,
        apiPath?: string | null,
        paymentStatus?: string | null,
        orderNumber?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null >,
};

export type GetNewsItemQueryVariables = {
  id: string,
};

export type GetNewsItemQuery = {
  getNewsItem?:  {
    __typename: "NewsItem",
    id: string,
    header: string,
    link?: string | null,
    content?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListNewsItemsQueryVariables = {
  filter?: ModelNewsItemFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListNewsItemsQuery = {
  listNewsItems?:  {
    __typename: "ModelNewsItemConnection",
    items:  Array< {
      __typename: "NewsItem",
      id: string,
      header: string,
      link?: string | null,
      content?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetReservationOffersQueryVariables = {
  companyIds?: Array< string > | null,
  startCompanyId?: string | null,
  group?: string | null,
  orgBusinessId?: string | null,
  startTime: string,
  returnTime: string,
  timeFlexibility: number,
  disableCategoryDefaultsStacking?: boolean | null,
};

export type GetReservationOffersQuery = {
  getReservationOffers:  Array< {
    __typename: "ReservationOffer",
    startTime: string,
    returnTime: string,
    vehicleOfferPrice: number,
    tax?: number | null,
    additionalServiceOffers:  Array< {
      __typename: "AdditionalServiceOffer",
      offerPrice: number,
      tax?: number | null,
      deductibleValue?: number | null,
      description?: string | null,
      descriptionT?:  {
        __typename: "Translation",
        fi?: string | null,
        en?: string | null,
        sv?: string | null,
      } | null,
      category?: string | null,
      adHocService?: boolean | null,
      image?: string | null,
      key: string,
    } >,
    freeTimes:  Array< {
      __typename: "FreeTime",
      lastStartTime: string,
      lastReturnTime: string,
      returnTimes?:  Array< {
        __typename: "DateRange",
        end: string,
        start: string,
      } | null > | null,
      firstStartTime: string,
    } >,
    reservedTimes?:  Array< {
      __typename: "DateRange",
      end: string,
      start: string,
    } | null > | null,
    availableInCategory?: number | null,
    useVehicleTimes?: boolean | null,
    disableOffHourReturn?: boolean | null,
    vehicle:  {
      __typename: "Vehicle",
      id: string,
      group?: string | null,
      organizationId?: string | null,
      orgBusinessId?: string | null,
      companyId: string,
      name: string,
      nameT?:  {
        __typename: "Translation",
        fi?: string | null,
        en?: string | null,
        sv?: string | null,
      } | null,
      registrationPlate?: string | null,
      damages?: string | null,
      damageRecords?:  Array< {
        __typename: "DamageRecord",
        normalizedX?: number | null,
        normalizedY?: number | null,
        label?: string | null,
        description?: string | null,
        createdAt?: string | null,
      } > | null,
      additionalFields:  Array< {
        __typename: "AdditionalField",
        key: string,
        value?: string | null,
      } >,
      image?: string | null,
      powerType?: string | null,
      deductible?: number | null,
      includedDistance?: string | null,
      mileage?: string | null,
      orderNumber?: number | null,
      vehicleCategoryId?: string | null,
      useCategoryDefaults?: boolean | null,
      useStartReturnTimes?: boolean | null,
      customStartReturns?:  Array< {
        __typename: "CustomStartReturn",
        priority?: number | null,
      } | null > | null,
      startReturnInterval?:  {
        __typename: "StartReturnInterval",
        start?: string | null,
        end?: string | null,
      } | null,
      startReturnTimes?:  Array< {
        __typename: "StartReturnTimes",
        start?: Array< string | null > | null,
        return?: Array< string | null > | null,
      } | null > | null,
      hideExport?: boolean | null,
      extraInfo?: string | null,
      extraInfoT?: string | null,
      extraToConfirmation?: string | null,
      extraToConfirmationT?: string | null,
      livionKeyId?: string | null,
      phoneNumber?: string | null,
      importData?: string | null,
      disableOnline?: boolean | null,
      activeRange?:  {
        __typename: "StartReturnInterval",
        start?: string | null,
        end?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      category?:  {
        __typename: "Category",
        id: string,
        group?: string | null,
        organizationId?: string | null,
        name: string,
        image?: string | null,
        orderNumber?: number | null,
        backgroundColor?: string | null,
        minuteBuffer?: number | null,
        reservationBlockFromNow?: number | null,
        typeId?: string | null,
        _removed?: string | null,
        oldTujausValue?: string | null,
        exampleModelName?: string | null,
        exampleModelImage?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      additionalServices?:  {
        __typename: "ModelAdditionalServicePricingConnection",
        nextToken?: string | null,
      } | null,
      reservations?:  {
        __typename: "ModelReservationConnection",
        nextToken?: string | null,
      } | null,
      pricing?:  {
        __typename: "Pricing",
        id: string,
        name: string,
        group?: string | null,
        organizationId?: string | null,
        orgBusinessId?: string | null,
        orgExternalBusinessId?: string | null,
        externalBusinessCategoryAssociation?: string | null,
        businessCategoryAssociation?: string | null,
        useNextDayPrice?: boolean | null,
        useLastDayPrice?: boolean | null,
        tax?: number | null,
        _removed?: string | null,
        deductible?: number | null,
        reducedDeductible?: number | null,
        reducedDeductiblePrice?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null,
    },
  } >,
};

export type GetReservationsMonthlyQueryVariables = {
  companyId: string,
  dateInMonth: string,
};

export type GetReservationsMonthlyQuery = {
  getReservationsMonthly:  Array< {
    __typename: "ReservationsByVehicle",
    reservedTimes?:  Array< {
      __typename: "DateRange",
      end: string,
      start: string,
    } | null > | null,
    useVehicleTimes?: boolean | null,
    vehicle?:  {
      __typename: "Vehicle",
      id: string,
      group?: string | null,
      organizationId?: string | null,
      orgBusinessId?: string | null,
      companyId: string,
      name: string,
      nameT?:  {
        __typename: "Translation",
        fi?: string | null,
        en?: string | null,
        sv?: string | null,
      } | null,
      registrationPlate?: string | null,
      damages?: string | null,
      damageRecords?:  Array< {
        __typename: "DamageRecord",
        normalizedX?: number | null,
        normalizedY?: number | null,
        label?: string | null,
        description?: string | null,
        createdAt?: string | null,
      } > | null,
      additionalFields:  Array< {
        __typename: "AdditionalField",
        key: string,
        value?: string | null,
      } >,
      image?: string | null,
      powerType?: string | null,
      deductible?: number | null,
      includedDistance?: string | null,
      mileage?: string | null,
      orderNumber?: number | null,
      vehicleCategoryId?: string | null,
      useCategoryDefaults?: boolean | null,
      useStartReturnTimes?: boolean | null,
      customStartReturns?:  Array< {
        __typename: "CustomStartReturn",
        priority?: number | null,
      } | null > | null,
      startReturnInterval?:  {
        __typename: "StartReturnInterval",
        start?: string | null,
        end?: string | null,
      } | null,
      startReturnTimes?:  Array< {
        __typename: "StartReturnTimes",
        start?: Array< string | null > | null,
        return?: Array< string | null > | null,
      } | null > | null,
      hideExport?: boolean | null,
      extraInfo?: string | null,
      extraInfoT?: string | null,
      extraToConfirmation?: string | null,
      extraToConfirmationT?: string | null,
      livionKeyId?: string | null,
      phoneNumber?: string | null,
      importData?: string | null,
      disableOnline?: boolean | null,
      activeRange?:  {
        __typename: "StartReturnInterval",
        start?: string | null,
        end?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      category?:  {
        __typename: "Category",
        id: string,
        group?: string | null,
        organizationId?: string | null,
        name: string,
        image?: string | null,
        orderNumber?: number | null,
        backgroundColor?: string | null,
        minuteBuffer?: number | null,
        reservationBlockFromNow?: number | null,
        typeId?: string | null,
        _removed?: string | null,
        oldTujausValue?: string | null,
        exampleModelName?: string | null,
        exampleModelImage?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      additionalServices?:  {
        __typename: "ModelAdditionalServicePricingConnection",
        nextToken?: string | null,
      } | null,
      reservations?:  {
        __typename: "ModelReservationConnection",
        nextToken?: string | null,
      } | null,
      pricing?:  {
        __typename: "Pricing",
        id: string,
        name: string,
        group?: string | null,
        organizationId?: string | null,
        orgBusinessId?: string | null,
        orgExternalBusinessId?: string | null,
        externalBusinessCategoryAssociation?: string | null,
        businessCategoryAssociation?: string | null,
        useNextDayPrice?: boolean | null,
        useLastDayPrice?: boolean | null,
        tax?: number | null,
        _removed?: string | null,
        deductible?: number | null,
        reducedDeductible?: number | null,
        reducedDeductiblePrice?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null,
    } | null,
  } >,
};

export type GetReservationsByVehicleQueryVariables = {
  vehicleId: string,
  startTime: string,
  endTime: string,
};

export type GetReservationsByVehicleQuery = {
  getReservationsByVehicle:  {
    __typename: "ReservationsByVehicle",
    reservedTimes?:  Array< {
      __typename: "DateRange",
      end: string,
      start: string,
    } | null > | null,
    useVehicleTimes?: boolean | null,
    vehicle?:  {
      __typename: "Vehicle",
      id: string,
      group?: string | null,
      organizationId?: string | null,
      orgBusinessId?: string | null,
      companyId: string,
      name: string,
      nameT?:  {
        __typename: "Translation",
        fi?: string | null,
        en?: string | null,
        sv?: string | null,
      } | null,
      registrationPlate?: string | null,
      damages?: string | null,
      damageRecords?:  Array< {
        __typename: "DamageRecord",
        normalizedX?: number | null,
        normalizedY?: number | null,
        label?: string | null,
        description?: string | null,
        createdAt?: string | null,
      } > | null,
      additionalFields:  Array< {
        __typename: "AdditionalField",
        key: string,
        value?: string | null,
      } >,
      image?: string | null,
      powerType?: string | null,
      deductible?: number | null,
      includedDistance?: string | null,
      mileage?: string | null,
      orderNumber?: number | null,
      vehicleCategoryId?: string | null,
      useCategoryDefaults?: boolean | null,
      useStartReturnTimes?: boolean | null,
      customStartReturns?:  Array< {
        __typename: "CustomStartReturn",
        priority?: number | null,
      } | null > | null,
      startReturnInterval?:  {
        __typename: "StartReturnInterval",
        start?: string | null,
        end?: string | null,
      } | null,
      startReturnTimes?:  Array< {
        __typename: "StartReturnTimes",
        start?: Array< string | null > | null,
        return?: Array< string | null > | null,
      } | null > | null,
      hideExport?: boolean | null,
      extraInfo?: string | null,
      extraInfoT?: string | null,
      extraToConfirmation?: string | null,
      extraToConfirmationT?: string | null,
      livionKeyId?: string | null,
      phoneNumber?: string | null,
      importData?: string | null,
      disableOnline?: boolean | null,
      activeRange?:  {
        __typename: "StartReturnInterval",
        start?: string | null,
        end?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      category?:  {
        __typename: "Category",
        id: string,
        group?: string | null,
        organizationId?: string | null,
        name: string,
        image?: string | null,
        orderNumber?: number | null,
        backgroundColor?: string | null,
        minuteBuffer?: number | null,
        reservationBlockFromNow?: number | null,
        typeId?: string | null,
        _removed?: string | null,
        oldTujausValue?: string | null,
        exampleModelName?: string | null,
        exampleModelImage?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      additionalServices?:  {
        __typename: "ModelAdditionalServicePricingConnection",
        nextToken?: string | null,
      } | null,
      reservations?:  {
        __typename: "ModelReservationConnection",
        nextToken?: string | null,
      } | null,
      pricing?:  {
        __typename: "Pricing",
        id: string,
        name: string,
        group?: string | null,
        organizationId?: string | null,
        orgBusinessId?: string | null,
        orgExternalBusinessId?: string | null,
        externalBusinessCategoryAssociation?: string | null,
        businessCategoryAssociation?: string | null,
        useNextDayPrice?: boolean | null,
        useLastDayPrice?: boolean | null,
        tax?: number | null,
        _removed?: string | null,
        deductible?: number | null,
        reducedDeductible?: number | null,
        reducedDeductiblePrice?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null,
    } | null,
  },
};

export type GetPriceByVehicleIdsQueryVariables = {
  vehicleIds: Array< string >,
  startTime: string,
  returnTime: string,
  companyId?: string | null,
  externalBusinessId?: string | null,
};

export type GetPriceByVehicleIdsQuery = {
  getPriceByVehicleIds:  {
    __typename: "VehiclePrices",
    vehicleOfferPrices:  Array< {
      __typename: "ReservationVehiclePrice",
      price: number,
      tax?: number | null,
      deductible?: number | null,
      id: string,
    } >,
    additionalServiceOffers:  Array< {
      __typename: "AdditionalServicePrice",
      description?: string | null,
      category?: string | null,
      offerPrice: number,
      deductibleValue?: number | null,
      tax?: number | null,
      image?: string | null,
      enableQuantity?: boolean | null,
      maxQuantity?: number | null,
      key: string,
    } >,
  },
};

export type GetPriceByVehicleIdQueryVariables = {
  vehicleId: string,
  startTime: string,
  returnTime: string,
};

export type GetPriceByVehicleIdQuery = {
  getPriceByVehicleId:  {
    __typename: "VehiclePrice",
    vehicleOfferPrice: number,
    additionalServiceOffers:  Array< {
      __typename: "AdditionalServicePrice",
      description?: string | null,
      category?: string | null,
      offerPrice: number,
      deductibleValue?: number | null,
      tax?: number | null,
      image?: string | null,
      enableQuantity?: boolean | null,
      maxQuantity?: number | null,
      key: string,
    } >,
  },
};

export type ValidateVoucherQueryVariables = {
  code: string,
  group?: string | null,
  organizationId?: string | null,
};

export type ValidateVoucherQuery = {
  validateVoucher?:  {
    __typename: "Voucher",
    id: string,
    name: string,
    group?: string | null,
    organizationId?: string | null,
    code: string,
    amount: number,
    startDate?: string | null,
    endDate?: string | null,
    _removed?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type GetPaymentMethodsQueryVariables = {
};

export type GetPaymentMethodsQuery = {
  getPaymentMethods?:  Array< {
    __typename: "PaymentMethod",
    selected_value: string,
    group: string,
    min_amount: number,
    max_amount: number,
    img: string,
    img_timestamp: number,
    currency: Array< string >,
    name: string,
  } | null > | null,
};

export type AuthPaymentQueryVariables = {
  reservationId: string,
  paymentMethod?: PaymentMethodInput | null,
  origin?: string | null,
  originPath?: string | null,
};

export type AuthPaymentQuery = {
  authPayment?:  {
    __typename: "AuthPaymentResult",
    token: string,
    type: string,
    result: number,
  } | null,
};

export type GetReservationInfoQueryVariables = {
  reservationId: string,
};

export type GetReservationInfoQuery = {
  getReservationInfo?:  {
    __typename: "ReservationInfo",
    id?: string | null,
    shortId?: string | null,
    language?: Language | null,
    startTime?: string | null,
    returnTime?: string | null,
    paymentPending?: boolean | null,
    paymentStatus?: string | null,
    signStatus?: string | null,
    enableSign?: boolean | null,
    disablePricesInPrintouts?: boolean | null,
    mustSign?: boolean | null,
    paymentData?:  {
      __typename: "PaymentDataPublic",
      paymentTodo?: number | null,
      depositPending?: boolean | null,
      deposit?: number | null,
      paymentTotal?: number | null,
    } | null,
    askSsn?: boolean | null,
    ssn?: string | null,
    group?: string | null,
    companyTermsUrl?: string | null,
    companyId?: string | null,
    name?: string | null,
    address?: string | null,
    postalCode?: string | null,
    city?: string | null,
    phone?: string | null,
    billingRef?: string | null,
    email?: string | null,
    externalNotes?: string | null,
    differentDriver?: boolean | null,
    driverName?: string | null,
    driverAddress?: string | null,
    driverPostalCode?: string | null,
    driverCity?: string | null,
    driverPhone?: string | null,
    driverEmail?: string | null,
    driverSSN?: string | null,
    additionalDriver?: boolean | null,
    additionalDriverName?: string | null,
    additionalDriverPhone?: string | null,
    allowCancellation?: boolean | null,
    billPayment?: boolean | null,
    isCompany?: boolean | null,
    companyName?: string | null,
    companyBusinessId?: string | null,
    companyBillingRef?: string | null,
    companyBillingAddress?: string | null,
    companyEBillingOperator?: string | null,
    companyEBillingAddress?: string | null,
    authStatus?: string | null,
    vehicles?:  Array< {
      __typename: "VehiclePublic",
      group?: string | null,
      organizationId?: string | null,
      companyId?: string | null,
      name?: string | null,
      nameT?:  {
        __typename: "Translation",
        fi?: string | null,
        en?: string | null,
        sv?: string | null,
      } | null,
      registrationPlate?: string | null,
      additionalFields?:  Array< {
        __typename: "AdditionalField",
        key: string,
        value?: string | null,
      } > | null,
      image?: string | null,
      powerType?: string | null,
      deductible?: number | null,
      includedDistance?: string | null,
      orderNumber?: number | null,
      price?: number | null,
      tax?: number | null,
      category?:  {
        __typename: "VehicleCategoryPublic",
        name?: string | null,
        typeId?: string | null,
      } | null,
      id?: string | null,
    } | null > | null,
    services?:  Array< {
      __typename: "AdditionalServicesPublic",
      quantity?: number | null,
      description?: string | null,
      descriptionT?:  {
        __typename: "Translation",
        fi?: string | null,
        en?: string | null,
        sv?: string | null,
      } | null,
      price?: number | null,
      tax?: number | null,
      key?: string | null,
    } | null > | null,
    expiryTime?: string | null,
    voucherData?:  {
      __typename: "VoucherDataPublic",
      amount?: number | null,
      code?: string | null,
    } | null,
    enableGTM?: string | null,
    errorMsg?: ReservationInfoError | null,
  } | null,
};

export type GetReviewStateQueryVariables = {
  reservationId: string,
};

export type GetReviewStateQuery = {
  getReviewState?:  {
    __typename: "ReviewStatePublic",
    reservationInfo?:  {
      __typename: "ReservationInfo",
      id?: string | null,
      shortId?: string | null,
      language?: Language | null,
      startTime?: string | null,
      returnTime?: string | null,
      paymentPending?: boolean | null,
      paymentStatus?: string | null,
      signStatus?: string | null,
      enableSign?: boolean | null,
      disablePricesInPrintouts?: boolean | null,
      mustSign?: boolean | null,
      paymentData?:  {
        __typename: "PaymentDataPublic",
        paymentTodo?: number | null,
        depositPending?: boolean | null,
        deposit?: number | null,
        paymentTotal?: number | null,
      } | null,
      askSsn?: boolean | null,
      ssn?: string | null,
      group?: string | null,
      companyTermsUrl?: string | null,
      companyId?: string | null,
      name?: string | null,
      address?: string | null,
      postalCode?: string | null,
      city?: string | null,
      phone?: string | null,
      billingRef?: string | null,
      email?: string | null,
      externalNotes?: string | null,
      differentDriver?: boolean | null,
      driverName?: string | null,
      driverAddress?: string | null,
      driverPostalCode?: string | null,
      driverCity?: string | null,
      driverPhone?: string | null,
      driverEmail?: string | null,
      driverSSN?: string | null,
      additionalDriver?: boolean | null,
      additionalDriverName?: string | null,
      additionalDriverPhone?: string | null,
      allowCancellation?: boolean | null,
      billPayment?: boolean | null,
      isCompany?: boolean | null,
      companyName?: string | null,
      companyBusinessId?: string | null,
      companyBillingRef?: string | null,
      companyBillingAddress?: string | null,
      companyEBillingOperator?: string | null,
      companyEBillingAddress?: string | null,
      authStatus?: string | null,
      vehicles?:  Array< {
        __typename: "VehiclePublic",
        group?: string | null,
        organizationId?: string | null,
        companyId?: string | null,
        name?: string | null,
        registrationPlate?: string | null,
        image?: string | null,
        powerType?: string | null,
        deductible?: number | null,
        includedDistance?: string | null,
        orderNumber?: number | null,
        price?: number | null,
        tax?: number | null,
        id?: string | null,
      } | null > | null,
      services?:  Array< {
        __typename: "AdditionalServicesPublic",
        quantity?: number | null,
        description?: string | null,
        price?: number | null,
        tax?: number | null,
        key?: string | null,
      } | null > | null,
      expiryTime?: string | null,
      voucherData?:  {
        __typename: "VoucherDataPublic",
        amount?: number | null,
        code?: string | null,
      } | null,
      enableGTM?: string | null,
      errorMsg?: ReservationInfoError | null,
    } | null,
    state?: ReviewState | null,
  } | null,
};

export type CompanyByOrganizationQueryVariables = {
  organizationId?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCompanyFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type CompanyByOrganizationQuery = {
  companyByOrganization?:  {
    __typename: "ModelCompanyConnection",
    items:  Array< {
      __typename: "Company",
      id: string,
      organizationId?: string | null,
      orgBusinessId?: string | null,
      group?: string | null,
      name: string,
      displayName?: string | null,
      email?: string | null,
      address?: string | null,
      postalCode?: string | null,
      city?: string | null,
      orderNumber?: number | null,
      orderNumberDealer?: number | null,
      phone?: string | null,
      businessId?: string | null,
      termsUrl?: string | null,
      timezone: string,
      weeklyBusinessHours:  Array< {
        __typename: "ClockRange",
        openAt: string,
        closeAt: string,
      } | null >,
      signConfirmationEmail?: string | null,
      nameInEmail?: string | null,
      senderNameSMS?: string | null,
      confirmationMsg?: string | null,
      confirmationMsgT?:  {
        __typename: "Translation",
        fi?: string | null,
        en?: string | null,
        sv?: string | null,
      } | null,
      feedbackMsg?: string | null,
      feedbackMsgT?:  {
        __typename: "Translation",
        fi?: string | null,
        en?: string | null,
        sv?: string | null,
      } | null,
      statusLinkMsg?: string | null,
      statusLinkMsgT?:  {
        __typename: "Translation",
        fi?: string | null,
        en?: string | null,
        sv?: string | null,
      } | null,
      livionDeviceId?: string | null,
      livionDeviceType?: LivionDevice | null,
      enableReservationDeposit?: boolean | null,
      reservationDeposit?:  {
        __typename: "ReservationDeposit",
        amount?: number | null,
        reminderBefore?: number | null,
        paymentBefore?: number | null,
      } | null,
      reservationLinkExpirationHours?: number | null,
      disableOffHourReturn?: boolean | null,
      disableMultiday?: boolean | null,
      disableSameDay?: boolean | null,
      disableStartDaysFromNow?: number | null,
      enableCart?: boolean | null,
      enableMonthlyView?: boolean | null,
      enableRegistrationPlate?: boolean | null,
      enableVehicleStartReturnTimes?: boolean | null,
      enableMustSign?: boolean | null,
      enablePaymentOnlineOnly?: boolean | null,
      enableGTM?: boolean | null,
      enableNetPromoterScore?: boolean | null,
      enableNPS?: boolean | null,
      reservationCancellation?:  {
        __typename: "ReservationCancellationSettings",
        cancellationEnabled?: boolean | null,
        daysBeforeStart?: number | null,
      } | null,
      creditCheck?:  {
        __typename: "AsiakastietoCreditCheck",
        enabled?: boolean | null,
        keyLookup?: boolean | null,
        userId?: string | null,
        passwordId?: string | null,
      } | null,
      limitReservationOncePerDay?: boolean | null,
      hidden?: boolean | null,
      receiptInfo?:  {
        __typename: "ReceiptInfo",
        enabled?: boolean | null,
        name?: string | null,
        address?: string | null,
        city?: string | null,
        postalCode?: string | null,
      } | null,
      innovoice?:  {
        __typename: "InnovoiceData",
        enabled?: boolean | null,
        companyId?: string | null,
        uRentCarRentalId?: string | null,
      } | null,
      locationType?: string | null,
      offHourAvailability?: boolean | null,
      offHourAvailabilityFee?: number | null,
      deliveryFee?: number | null,
      deliveryBufferHours?: number | null,
      notAllowedCategoryIds?: Array< string | null > | null,
      importIdentifier?: string | null,
      features?:  {
        __typename: "Features",
        enableNPS?: boolean | null,
        enableVismaPay?: boolean | null,
        enableReservationDialogV2?: boolean | null,
        enableTilakone?: boolean | null,
        enableGTM?: GTMVersion | null,
        enableSMSConfirmation?: boolean | null,
        enableMustSignBypass?: boolean | null,
      } | null,
      contractSettings?:  Array< {
        __typename: "FeatureSetting",
        name?: string | null,
        enabled?: boolean | null,
        input?: string | null,
      } | null > | null,
      contractSettingsJSON?: string | null,
      categoryDynamicPricings?:  Array< {
        __typename: "CategoryDynamicPricing",
        categoryId?: string | null,
        pricingId?: string | null,
        percentage?: number | null,
      } | null > | null,
      disableReservationCategoryIds?: Array< string | null > | null,
      locationColor?: string | null,
      createdAt: string,
      updatedAt: string,
      merchantDetails?:  {
        __typename: "MerchantDetails",
        api?: VismaPayApi | null,
        merchant_id?: string | null,
        cp?: string | null,
      } | null,
      businessHoursExceptions?:  {
        __typename: "ModelBusinessHoursExceptionConnection",
        nextToken?: string | null,
      } | null,
      vehicles?:  {
        __typename: "ModelVehicleConnection",
        nextToken?: string | null,
      } | null,
      categories?:  {
        __typename: "ModelCategoryConnection",
        nextToken?: string | null,
      } | null,
      categoriesByOrg?:  {
        __typename: "ModelCategoryConnection",
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type CompanyByGroupQueryVariables = {
  group?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCompanyFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type CompanyByGroupQuery = {
  companyByGroup?:  {
    __typename: "ModelCompanyConnection",
    items:  Array< {
      __typename: "Company",
      id: string,
      organizationId?: string | null,
      orgBusinessId?: string | null,
      group?: string | null,
      name: string,
      displayName?: string | null,
      email?: string | null,
      address?: string | null,
      postalCode?: string | null,
      city?: string | null,
      orderNumber?: number | null,
      orderNumberDealer?: number | null,
      phone?: string | null,
      businessId?: string | null,
      termsUrl?: string | null,
      timezone: string,
      weeklyBusinessHours:  Array< {
        __typename: "ClockRange",
        openAt: string,
        closeAt: string,
      } | null >,
      signConfirmationEmail?: string | null,
      nameInEmail?: string | null,
      senderNameSMS?: string | null,
      confirmationMsg?: string | null,
      confirmationMsgT?:  {
        __typename: "Translation",
        fi?: string | null,
        en?: string | null,
        sv?: string | null,
      } | null,
      feedbackMsg?: string | null,
      feedbackMsgT?:  {
        __typename: "Translation",
        fi?: string | null,
        en?: string | null,
        sv?: string | null,
      } | null,
      statusLinkMsg?: string | null,
      statusLinkMsgT?:  {
        __typename: "Translation",
        fi?: string | null,
        en?: string | null,
        sv?: string | null,
      } | null,
      livionDeviceId?: string | null,
      livionDeviceType?: LivionDevice | null,
      enableReservationDeposit?: boolean | null,
      reservationDeposit?:  {
        __typename: "ReservationDeposit",
        amount?: number | null,
        reminderBefore?: number | null,
        paymentBefore?: number | null,
      } | null,
      reservationLinkExpirationHours?: number | null,
      disableOffHourReturn?: boolean | null,
      disableMultiday?: boolean | null,
      disableSameDay?: boolean | null,
      disableStartDaysFromNow?: number | null,
      enableCart?: boolean | null,
      enableMonthlyView?: boolean | null,
      enableRegistrationPlate?: boolean | null,
      enableVehicleStartReturnTimes?: boolean | null,
      enableMustSign?: boolean | null,
      enablePaymentOnlineOnly?: boolean | null,
      enableGTM?: boolean | null,
      enableNetPromoterScore?: boolean | null,
      enableNPS?: boolean | null,
      reservationCancellation?:  {
        __typename: "ReservationCancellationSettings",
        cancellationEnabled?: boolean | null,
        daysBeforeStart?: number | null,
      } | null,
      creditCheck?:  {
        __typename: "AsiakastietoCreditCheck",
        enabled?: boolean | null,
        keyLookup?: boolean | null,
        userId?: string | null,
        passwordId?: string | null,
      } | null,
      limitReservationOncePerDay?: boolean | null,
      hidden?: boolean | null,
      receiptInfo?:  {
        __typename: "ReceiptInfo",
        enabled?: boolean | null,
        name?: string | null,
        address?: string | null,
        city?: string | null,
        postalCode?: string | null,
      } | null,
      innovoice?:  {
        __typename: "InnovoiceData",
        enabled?: boolean | null,
        companyId?: string | null,
        uRentCarRentalId?: string | null,
      } | null,
      locationType?: string | null,
      offHourAvailability?: boolean | null,
      offHourAvailabilityFee?: number | null,
      deliveryFee?: number | null,
      deliveryBufferHours?: number | null,
      notAllowedCategoryIds?: Array< string | null > | null,
      importIdentifier?: string | null,
      features?:  {
        __typename: "Features",
        enableNPS?: boolean | null,
        enableVismaPay?: boolean | null,
        enableReservationDialogV2?: boolean | null,
        enableTilakone?: boolean | null,
        enableGTM?: GTMVersion | null,
        enableSMSConfirmation?: boolean | null,
        enableMustSignBypass?: boolean | null,
      } | null,
      contractSettings?:  Array< {
        __typename: "FeatureSetting",
        name?: string | null,
        enabled?: boolean | null,
        input?: string | null,
      } | null > | null,
      contractSettingsJSON?: string | null,
      categoryDynamicPricings?:  Array< {
        __typename: "CategoryDynamicPricing",
        categoryId?: string | null,
        pricingId?: string | null,
        percentage?: number | null,
      } | null > | null,
      disableReservationCategoryIds?: Array< string | null > | null,
      locationColor?: string | null,
      createdAt: string,
      updatedAt: string,
      merchantDetails?:  {
        __typename: "MerchantDetails",
        api?: VismaPayApi | null,
        merchant_id?: string | null,
        cp?: string | null,
      } | null,
      businessHoursExceptions?:  {
        __typename: "ModelBusinessHoursExceptionConnection",
        nextToken?: string | null,
      } | null,
      vehicles?:  {
        __typename: "ModelVehicleConnection",
        nextToken?: string | null,
      } | null,
      categories?:  {
        __typename: "ModelCategoryConnection",
        nextToken?: string | null,
      } | null,
      categoriesByOrg?:  {
        __typename: "ModelCategoryConnection",
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type CompanyByBusinessQueryVariables = {
  orgBusinessId?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCompanyFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type CompanyByBusinessQuery = {
  companyByBusiness?:  {
    __typename: "ModelCompanyConnection",
    items:  Array< {
      __typename: "Company",
      id: string,
      organizationId?: string | null,
      orgBusinessId?: string | null,
      group?: string | null,
      name: string,
      displayName?: string | null,
      email?: string | null,
      address?: string | null,
      postalCode?: string | null,
      city?: string | null,
      orderNumber?: number | null,
      orderNumberDealer?: number | null,
      phone?: string | null,
      businessId?: string | null,
      termsUrl?: string | null,
      timezone: string,
      weeklyBusinessHours:  Array< {
        __typename: "ClockRange",
        openAt: string,
        closeAt: string,
      } | null >,
      signConfirmationEmail?: string | null,
      nameInEmail?: string | null,
      senderNameSMS?: string | null,
      confirmationMsg?: string | null,
      confirmationMsgT?:  {
        __typename: "Translation",
        fi?: string | null,
        en?: string | null,
        sv?: string | null,
      } | null,
      feedbackMsg?: string | null,
      feedbackMsgT?:  {
        __typename: "Translation",
        fi?: string | null,
        en?: string | null,
        sv?: string | null,
      } | null,
      statusLinkMsg?: string | null,
      statusLinkMsgT?:  {
        __typename: "Translation",
        fi?: string | null,
        en?: string | null,
        sv?: string | null,
      } | null,
      livionDeviceId?: string | null,
      livionDeviceType?: LivionDevice | null,
      enableReservationDeposit?: boolean | null,
      reservationDeposit?:  {
        __typename: "ReservationDeposit",
        amount?: number | null,
        reminderBefore?: number | null,
        paymentBefore?: number | null,
      } | null,
      reservationLinkExpirationHours?: number | null,
      disableOffHourReturn?: boolean | null,
      disableMultiday?: boolean | null,
      disableSameDay?: boolean | null,
      disableStartDaysFromNow?: number | null,
      enableCart?: boolean | null,
      enableMonthlyView?: boolean | null,
      enableRegistrationPlate?: boolean | null,
      enableVehicleStartReturnTimes?: boolean | null,
      enableMustSign?: boolean | null,
      enablePaymentOnlineOnly?: boolean | null,
      enableGTM?: boolean | null,
      enableNetPromoterScore?: boolean | null,
      enableNPS?: boolean | null,
      reservationCancellation?:  {
        __typename: "ReservationCancellationSettings",
        cancellationEnabled?: boolean | null,
        daysBeforeStart?: number | null,
      } | null,
      creditCheck?:  {
        __typename: "AsiakastietoCreditCheck",
        enabled?: boolean | null,
        keyLookup?: boolean | null,
        userId?: string | null,
        passwordId?: string | null,
      } | null,
      limitReservationOncePerDay?: boolean | null,
      hidden?: boolean | null,
      receiptInfo?:  {
        __typename: "ReceiptInfo",
        enabled?: boolean | null,
        name?: string | null,
        address?: string | null,
        city?: string | null,
        postalCode?: string | null,
      } | null,
      innovoice?:  {
        __typename: "InnovoiceData",
        enabled?: boolean | null,
        companyId?: string | null,
        uRentCarRentalId?: string | null,
      } | null,
      locationType?: string | null,
      offHourAvailability?: boolean | null,
      offHourAvailabilityFee?: number | null,
      deliveryFee?: number | null,
      deliveryBufferHours?: number | null,
      notAllowedCategoryIds?: Array< string | null > | null,
      importIdentifier?: string | null,
      features?:  {
        __typename: "Features",
        enableNPS?: boolean | null,
        enableVismaPay?: boolean | null,
        enableReservationDialogV2?: boolean | null,
        enableTilakone?: boolean | null,
        enableGTM?: GTMVersion | null,
        enableSMSConfirmation?: boolean | null,
        enableMustSignBypass?: boolean | null,
      } | null,
      contractSettings?:  Array< {
        __typename: "FeatureSetting",
        name?: string | null,
        enabled?: boolean | null,
        input?: string | null,
      } | null > | null,
      contractSettingsJSON?: string | null,
      categoryDynamicPricings?:  Array< {
        __typename: "CategoryDynamicPricing",
        categoryId?: string | null,
        pricingId?: string | null,
        percentage?: number | null,
      } | null > | null,
      disableReservationCategoryIds?: Array< string | null > | null,
      locationColor?: string | null,
      createdAt: string,
      updatedAt: string,
      merchantDetails?:  {
        __typename: "MerchantDetails",
        api?: VismaPayApi | null,
        merchant_id?: string | null,
        cp?: string | null,
      } | null,
      businessHoursExceptions?:  {
        __typename: "ModelBusinessHoursExceptionConnection",
        nextToken?: string | null,
      } | null,
      vehicles?:  {
        __typename: "ModelVehicleConnection",
        nextToken?: string | null,
      } | null,
      categories?:  {
        __typename: "ModelCategoryConnection",
        nextToken?: string | null,
      } | null,
      categoriesByOrg?:  {
        __typename: "ModelCategoryConnection",
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetCompanyQueryVariables = {
  id: string,
};

export type GetCompanyQuery = {
  getCompany?:  {
    __typename: "Company",
    id: string,
    organizationId?: string | null,
    orgBusinessId?: string | null,
    group?: string | null,
    name: string,
    displayName?: string | null,
    email?: string | null,
    address?: string | null,
    postalCode?: string | null,
    city?: string | null,
    orderNumber?: number | null,
    orderNumberDealer?: number | null,
    phone?: string | null,
    businessId?: string | null,
    termsUrl?: string | null,
    timezone: string,
    weeklyBusinessHours:  Array< {
      __typename: "ClockRange",
      openAt: string,
      closeAt: string,
    } | null >,
    signConfirmationEmail?: string | null,
    nameInEmail?: string | null,
    senderNameSMS?: string | null,
    confirmationMsg?: string | null,
    confirmationMsgT?:  {
      __typename: "Translation",
      fi?: string | null,
      en?: string | null,
      sv?: string | null,
    } | null,
    feedbackMsg?: string | null,
    feedbackMsgT?:  {
      __typename: "Translation",
      fi?: string | null,
      en?: string | null,
      sv?: string | null,
    } | null,
    statusLinkMsg?: string | null,
    statusLinkMsgT?:  {
      __typename: "Translation",
      fi?: string | null,
      en?: string | null,
      sv?: string | null,
    } | null,
    livionDeviceId?: string | null,
    livionDeviceType?: LivionDevice | null,
    enableReservationDeposit?: boolean | null,
    reservationDeposit?:  {
      __typename: "ReservationDeposit",
      amount?: number | null,
      reminderBefore?: number | null,
      paymentBefore?: number | null,
    } | null,
    reservationLinkExpirationHours?: number | null,
    disableOffHourReturn?: boolean | null,
    disableMultiday?: boolean | null,
    disableSameDay?: boolean | null,
    disableStartDaysFromNow?: number | null,
    enableCart?: boolean | null,
    enableMonthlyView?: boolean | null,
    enableRegistrationPlate?: boolean | null,
    enableVehicleStartReturnTimes?: boolean | null,
    enableMustSign?: boolean | null,
    enablePaymentOnlineOnly?: boolean | null,
    enableGTM?: boolean | null,
    enableNetPromoterScore?: boolean | null,
    enableNPS?: boolean | null,
    reservationCancellation?:  {
      __typename: "ReservationCancellationSettings",
      cancellationEnabled?: boolean | null,
      daysBeforeStart?: number | null,
    } | null,
    creditCheck?:  {
      __typename: "AsiakastietoCreditCheck",
      enabled?: boolean | null,
      keyLookup?: boolean | null,
      userId?: string | null,
      passwordId?: string | null,
    } | null,
    limitReservationOncePerDay?: boolean | null,
    hidden?: boolean | null,
    receiptInfo?:  {
      __typename: "ReceiptInfo",
      enabled?: boolean | null,
      name?: string | null,
      address?: string | null,
      city?: string | null,
      postalCode?: string | null,
    } | null,
    innovoice?:  {
      __typename: "InnovoiceData",
      enabled?: boolean | null,
      companyId?: string | null,
      uRentCarRentalId?: string | null,
    } | null,
    locationType?: string | null,
    offHourAvailability?: boolean | null,
    offHourAvailabilityFee?: number | null,
    deliveryFee?: number | null,
    deliveryBufferHours?: number | null,
    notAllowedCategoryIds?: Array< string | null > | null,
    importIdentifier?: string | null,
    features?:  {
      __typename: "Features",
      enableNPS?: boolean | null,
      enableVismaPay?: boolean | null,
      enableReservationDialogV2?: boolean | null,
      enableTilakone?: boolean | null,
      enableGTM?: GTMVersion | null,
      enableSMSConfirmation?: boolean | null,
      enableMustSignBypass?: boolean | null,
    } | null,
    contractSettings?:  Array< {
      __typename: "FeatureSetting",
      name?: string | null,
      enabled?: boolean | null,
      input?: string | null,
    } | null > | null,
    contractSettingsJSON?: string | null,
    categoryDynamicPricings?:  Array< {
      __typename: "CategoryDynamicPricing",
      categoryId?: string | null,
      pricingId?: string | null,
      percentage?: number | null,
    } | null > | null,
    disableReservationCategoryIds?: Array< string | null > | null,
    locationColor?: string | null,
    createdAt: string,
    updatedAt: string,
    merchantDetails?:  {
      __typename: "MerchantDetails",
      api?: VismaPayApi | null,
      merchant_id?: string | null,
      cp?: string | null,
    } | null,
    businessHoursExceptions?:  {
      __typename: "ModelBusinessHoursExceptionConnection",
      items:  Array< {
        __typename: "BusinessHoursException",
        organizationId?: string | null,
        orgBusinessId?: string | null,
        group?: string | null,
        date: string,
        name?: string | null,
        companyId: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    vehicles?:  {
      __typename: "ModelVehicleConnection",
      items:  Array< {
        __typename: "Vehicle",
        id: string,
        group?: string | null,
        organizationId?: string | null,
        orgBusinessId?: string | null,
        companyId: string,
        name: string,
        registrationPlate?: string | null,
        damages?: string | null,
        image?: string | null,
        powerType?: string | null,
        deductible?: number | null,
        includedDistance?: string | null,
        mileage?: string | null,
        orderNumber?: number | null,
        vehicleCategoryId?: string | null,
        useCategoryDefaults?: boolean | null,
        useStartReturnTimes?: boolean | null,
        hideExport?: boolean | null,
        extraInfo?: string | null,
        extraInfoT?: string | null,
        extraToConfirmation?: string | null,
        extraToConfirmationT?: string | null,
        livionKeyId?: string | null,
        phoneNumber?: string | null,
        importData?: string | null,
        disableOnline?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    categories?:  {
      __typename: "ModelCategoryConnection",
      items:  Array< {
        __typename: "Category",
        id: string,
        group?: string | null,
        organizationId?: string | null,
        name: string,
        image?: string | null,
        orderNumber?: number | null,
        backgroundColor?: string | null,
        minuteBuffer?: number | null,
        reservationBlockFromNow?: number | null,
        typeId?: string | null,
        _removed?: string | null,
        oldTujausValue?: string | null,
        exampleModelName?: string | null,
        exampleModelImage?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    categoriesByOrg?:  {
      __typename: "ModelCategoryConnection",
      items:  Array< {
        __typename: "Category",
        id: string,
        group?: string | null,
        organizationId?: string | null,
        name: string,
        image?: string | null,
        orderNumber?: number | null,
        backgroundColor?: string | null,
        minuteBuffer?: number | null,
        reservationBlockFromNow?: number | null,
        typeId?: string | null,
        _removed?: string | null,
        oldTujausValue?: string | null,
        exampleModelName?: string | null,
        exampleModelImage?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type ListCompanysQueryVariables = {
  filter?: ModelCompanyFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListCompanysQuery = {
  listCompanys?:  {
    __typename: "ModelCompanyConnection",
    items:  Array< {
      __typename: "Company",
      id: string,
      organizationId?: string | null,
      orgBusinessId?: string | null,
      group?: string | null,
      name: string,
      displayName?: string | null,
      email?: string | null,
      address?: string | null,
      postalCode?: string | null,
      city?: string | null,
      orderNumber?: number | null,
      orderNumberDealer?: number | null,
      phone?: string | null,
      businessId?: string | null,
      termsUrl?: string | null,
      timezone: string,
      weeklyBusinessHours:  Array< {
        __typename: "ClockRange",
        openAt: string,
        closeAt: string,
      } | null >,
      signConfirmationEmail?: string | null,
      nameInEmail?: string | null,
      senderNameSMS?: string | null,
      confirmationMsg?: string | null,
      confirmationMsgT?:  {
        __typename: "Translation",
        fi?: string | null,
        en?: string | null,
        sv?: string | null,
      } | null,
      feedbackMsg?: string | null,
      feedbackMsgT?:  {
        __typename: "Translation",
        fi?: string | null,
        en?: string | null,
        sv?: string | null,
      } | null,
      statusLinkMsg?: string | null,
      statusLinkMsgT?:  {
        __typename: "Translation",
        fi?: string | null,
        en?: string | null,
        sv?: string | null,
      } | null,
      livionDeviceId?: string | null,
      livionDeviceType?: LivionDevice | null,
      enableReservationDeposit?: boolean | null,
      reservationDeposit?:  {
        __typename: "ReservationDeposit",
        amount?: number | null,
        reminderBefore?: number | null,
        paymentBefore?: number | null,
      } | null,
      reservationLinkExpirationHours?: number | null,
      disableOffHourReturn?: boolean | null,
      disableMultiday?: boolean | null,
      disableSameDay?: boolean | null,
      disableStartDaysFromNow?: number | null,
      enableCart?: boolean | null,
      enableMonthlyView?: boolean | null,
      enableRegistrationPlate?: boolean | null,
      enableVehicleStartReturnTimes?: boolean | null,
      enableMustSign?: boolean | null,
      enablePaymentOnlineOnly?: boolean | null,
      enableGTM?: boolean | null,
      enableNetPromoterScore?: boolean | null,
      enableNPS?: boolean | null,
      reservationCancellation?:  {
        __typename: "ReservationCancellationSettings",
        cancellationEnabled?: boolean | null,
        daysBeforeStart?: number | null,
      } | null,
      creditCheck?:  {
        __typename: "AsiakastietoCreditCheck",
        enabled?: boolean | null,
        keyLookup?: boolean | null,
        userId?: string | null,
        passwordId?: string | null,
      } | null,
      limitReservationOncePerDay?: boolean | null,
      hidden?: boolean | null,
      receiptInfo?:  {
        __typename: "ReceiptInfo",
        enabled?: boolean | null,
        name?: string | null,
        address?: string | null,
        city?: string | null,
        postalCode?: string | null,
      } | null,
      innovoice?:  {
        __typename: "InnovoiceData",
        enabled?: boolean | null,
        companyId?: string | null,
        uRentCarRentalId?: string | null,
      } | null,
      locationType?: string | null,
      offHourAvailability?: boolean | null,
      offHourAvailabilityFee?: number | null,
      deliveryFee?: number | null,
      deliveryBufferHours?: number | null,
      notAllowedCategoryIds?: Array< string | null > | null,
      importIdentifier?: string | null,
      features?:  {
        __typename: "Features",
        enableNPS?: boolean | null,
        enableVismaPay?: boolean | null,
        enableReservationDialogV2?: boolean | null,
        enableTilakone?: boolean | null,
        enableGTM?: GTMVersion | null,
        enableSMSConfirmation?: boolean | null,
        enableMustSignBypass?: boolean | null,
      } | null,
      contractSettings?:  Array< {
        __typename: "FeatureSetting",
        name?: string | null,
        enabled?: boolean | null,
        input?: string | null,
      } | null > | null,
      contractSettingsJSON?: string | null,
      categoryDynamicPricings?:  Array< {
        __typename: "CategoryDynamicPricing",
        categoryId?: string | null,
        pricingId?: string | null,
        percentage?: number | null,
      } | null > | null,
      disableReservationCategoryIds?: Array< string | null > | null,
      locationColor?: string | null,
      createdAt: string,
      updatedAt: string,
      merchantDetails?:  {
        __typename: "MerchantDetails",
        api?: VismaPayApi | null,
        merchant_id?: string | null,
        cp?: string | null,
      } | null,
      businessHoursExceptions?:  {
        __typename: "ModelBusinessHoursExceptionConnection",
        nextToken?: string | null,
      } | null,
      vehicles?:  {
        __typename: "ModelVehicleConnection",
        nextToken?: string | null,
      } | null,
      categories?:  {
        __typename: "ModelCategoryConnection",
        nextToken?: string | null,
      } | null,
      categoriesByOrg?:  {
        __typename: "ModelCategoryConnection",
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetBusinessHoursExceptionQueryVariables = {
  companyId: string,
  date: string,
};

export type GetBusinessHoursExceptionQuery = {
  getBusinessHoursException?:  {
    __typename: "BusinessHoursException",
    organizationId?: string | null,
    orgBusinessId?: string | null,
    group?: string | null,
    date: string,
    name?: string | null,
    businessHours?:  {
      __typename: "ClockRange",
      openAt: string,
      closeAt: string,
    } | null,
    companyId: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListBusinessHoursExceptionsQueryVariables = {
  companyId?: string | null,
  date?: ModelStringKeyConditionInput | null,
  filter?: ModelBusinessHoursExceptionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListBusinessHoursExceptionsQuery = {
  listBusinessHoursExceptions?:  {
    __typename: "ModelBusinessHoursExceptionConnection",
    items:  Array< {
      __typename: "BusinessHoursException",
      organizationId?: string | null,
      orgBusinessId?: string | null,
      group?: string | null,
      date: string,
      name?: string | null,
      businessHours?:  {
        __typename: "ClockRange",
        openAt: string,
        closeAt: string,
      } | null,
      companyId: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetVehicleQueryVariables = {
  id: string,
};

export type GetVehicleQuery = {
  getVehicle?:  {
    __typename: "Vehicle",
    id: string,
    group?: string | null,
    organizationId?: string | null,
    orgBusinessId?: string | null,
    companyId: string,
    name: string,
    nameT?:  {
      __typename: "Translation",
      fi?: string | null,
      en?: string | null,
      sv?: string | null,
    } | null,
    registrationPlate?: string | null,
    damages?: string | null,
    damageRecords?:  Array< {
      __typename: "DamageRecord",
      normalizedX?: number | null,
      normalizedY?: number | null,
      label?: string | null,
      description?: string | null,
      createdAt?: string | null,
    } > | null,
    additionalFields:  Array< {
      __typename: "AdditionalField",
      key: string,
      value?: string | null,
      valueT?:  {
        __typename: "Translation",
        fi?: string | null,
        en?: string | null,
        sv?: string | null,
      } | null,
    } >,
    image?: string | null,
    powerType?: string | null,
    deductible?: number | null,
    includedDistance?: string | null,
    mileage?: string | null,
    orderNumber?: number | null,
    vehicleCategoryId?: string | null,
    useCategoryDefaults?: boolean | null,
    useStartReturnTimes?: boolean | null,
    customStartReturns?:  Array< {
      __typename: "CustomStartReturn",
      priority?: number | null,
      startReturnInterval?:  {
        __typename: "StartReturnInterval",
        start?: string | null,
        end?: string | null,
      } | null,
      startReturnTimes?:  Array< {
        __typename: "StartReturnTimes",
        start?: Array< string | null > | null,
        return?: Array< string | null > | null,
      } | null > | null,
    } | null > | null,
    startReturnInterval?:  {
      __typename: "StartReturnInterval",
      start?: string | null,
      end?: string | null,
    } | null,
    startReturnTimes?:  Array< {
      __typename: "StartReturnTimes",
      start?: Array< string | null > | null,
      return?: Array< string | null > | null,
    } | null > | null,
    hideExport?: boolean | null,
    extraInfo?: string | null,
    extraInfoT?: string | null,
    extraToConfirmation?: string | null,
    extraToConfirmationT?: string | null,
    livionKeyId?: string | null,
    phoneNumber?: string | null,
    importData?: string | null,
    disableOnline?: boolean | null,
    activeRange?:  {
      __typename: "StartReturnInterval",
      start?: string | null,
      end?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    category?:  {
      __typename: "Category",
      id: string,
      group?: string | null,
      organizationId?: string | null,
      name: string,
      image?: string | null,
      orderNumber?: number | null,
      backgroundColor?: string | null,
      minuteBuffer?: number | null,
      reservationBlockFromNow?: number | null,
      typeId?: string | null,
      _removed?: string | null,
      oldTujausValue?: string | null,
      exampleModelName?: string | null,
      exampleModelImage?: string | null,
      createdAt: string,
      updatedAt: string,
      additionalServices?:  {
        __typename: "ModelAdditionalServicePricingConnection",
        nextToken?: string | null,
      } | null,
      pricing?:  {
        __typename: "Pricing",
        id: string,
        name: string,
        group?: string | null,
        organizationId?: string | null,
        orgBusinessId?: string | null,
        orgExternalBusinessId?: string | null,
        externalBusinessCategoryAssociation?: string | null,
        businessCategoryAssociation?: string | null,
        useNextDayPrice?: boolean | null,
        useLastDayPrice?: boolean | null,
        tax?: number | null,
        _removed?: string | null,
        deductible?: number | null,
        reducedDeductible?: number | null,
        reducedDeductiblePrice?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      externalBusinessPricings?:  {
        __typename: "ModelPricingConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    additionalServices?:  {
      __typename: "ModelAdditionalServicePricingConnection",
      items:  Array< {
        __typename: "AdditionalServicePricing",
        id: string,
        group?: string | null,
        organizationId?: string | null,
        orgBusinessId?: string | null,
        key: string,
        vehicleId?: string | null,
        categoryId?: string | null,
        pricingId: string,
        deductibleValue?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    reservations?:  {
      __typename: "ModelReservationConnection",
      items:  Array< {
        __typename: "Reservation",
        id: string,
        shortId?: string | null,
        language?: Language | null,
        group?: string | null,
        organizationId?: string | null,
        orgBusinessId?: string | null,
        orgExternalBusinessId?: string | null,
        companyId?: string | null,
        returnCompanyId?: string | null,
        startLocation?: string | null,
        returnLocation?: string | null,
        startLocationID?: string | null,
        returnLocationID?: string | null,
        reviewId?: string | null,
        customerBrokerId?: string | null,
        customerCompanyId?: string | null,
        customerPersonalId?: string | null,
        reservationVehicleId?: string | null,
        createdAt: string,
        updatedAt: string,
        startTime?: string | null,
        returnTime?: string | null,
        endTime?: string | null,
        name?: string | null,
        address?: string | null,
        postalCode?: string | null,
        city?: string | null,
        phone?: string | null,
        email?: string | null,
        billingRef?: string | null,
        customerNotes?: string | null,
        ssn?: string | null,
        rentalNotes?: string | null,
        internalNotes?: string | null,
        externalNotes?: string | null,
        outOfCommission?: boolean | null,
        vehiclePrice?: number | null,
        driverSSN?: string | null,
        differentDriver?: boolean | null,
        driverName?: string | null,
        driverAddress?: string | null,
        driverPostalCode?: string | null,
        driverCity?: string | null,
        driverPhone?: string | null,
        driverEmail?: string | null,
        additionalDriver?: boolean | null,
        additionalDriverName?: string | null,
        additionalDriverPhone?: string | null,
        additionalDriverSSN?: string | null,
        billPayment?: boolean | null,
        isCompany?: boolean | null,
        carStatus?: RentalStatus | null,
        rentalStatus?: RentalStatus | null,
        innovoiceStatus?: ReservationInnovoiceStatus | null,
        companyName?: string | null,
        companyBusinessId?: string | null,
        companyCity?: string | null,
        companyPostalCode?: string | null,
        companyBillingRef?: string | null,
        companyBillingAddress?: string | null,
        companyEBillingOperator?: string | null,
        companyEBillingAddress?: string | null,
        paymentToken?: string | null,
        npsStatus?: NpsStatus | null,
        paymentStatus?: PaymentStatus | null,
        signStatus?: SignStatus | null,
        signDocumentId?: string | null,
        signerName?: string | null,
        reviewStatus?: ReviewStatus | null,
        authStatus?: AuthStatus | null,
        authTime?: string | null,
        completeBefore?: string | null,
        mustSign?: boolean | null,
        customerCarRegistrationPlate?: string | null,
        enableRegistrationPlate?: boolean | null,
        disablePricesInPrintouts?: boolean | null,
        invoiceIds?: Array< string > | null,
        consolidatedInvoiceIds?: Array< string > | null,
        channel?: ReservationChannel | null,
        originURL?: string | null,
        type?: ReservationType | null,
        status?: string | null,
        pricingCategoryId?: string | null,
        customImportDate?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    pricing?:  {
      __typename: "Pricing",
      id: string,
      name: string,
      group?: string | null,
      organizationId?: string | null,
      orgBusinessId?: string | null,
      orgExternalBusinessId?: string | null,
      externalBusinessCategoryAssociation?: string | null,
      businessCategoryAssociation?: string | null,
      elements:  Array< {
        __typename: "PricingElement",
        duration: string,
        price: number,
      } >,
      useNextDayPrice?: boolean | null,
      useLastDayPrice?: boolean | null,
      tax?: number | null,
      _removed?: string | null,
      deductible?: number | null,
      reducedDeductible?: number | null,
      reducedDeductiblePrice?: number | null,
      createdAt: string,
      updatedAt: string,
    } | null,
  } | null,
};

export type ListVehiclesQueryVariables = {
  filter?: ModelVehicleFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListVehiclesQuery = {
  listVehicles?:  {
    __typename: "ModelVehicleConnection",
    items:  Array< {
      __typename: "Vehicle",
      id: string,
      group?: string | null,
      organizationId?: string | null,
      orgBusinessId?: string | null,
      companyId: string,
      name: string,
      nameT?:  {
        __typename: "Translation",
        fi?: string | null,
        en?: string | null,
        sv?: string | null,
      } | null,
      registrationPlate?: string | null,
      damages?: string | null,
      damageRecords?:  Array< {
        __typename: "DamageRecord",
        normalizedX?: number | null,
        normalizedY?: number | null,
        label?: string | null,
        description?: string | null,
        createdAt?: string | null,
      } > | null,
      additionalFields:  Array< {
        __typename: "AdditionalField",
        key: string,
        value?: string | null,
      } >,
      image?: string | null,
      powerType?: string | null,
      deductible?: number | null,
      includedDistance?: string | null,
      mileage?: string | null,
      orderNumber?: number | null,
      vehicleCategoryId?: string | null,
      useCategoryDefaults?: boolean | null,
      useStartReturnTimes?: boolean | null,
      customStartReturns?:  Array< {
        __typename: "CustomStartReturn",
        priority?: number | null,
      } | null > | null,
      startReturnInterval?:  {
        __typename: "StartReturnInterval",
        start?: string | null,
        end?: string | null,
      } | null,
      startReturnTimes?:  Array< {
        __typename: "StartReturnTimes",
        start?: Array< string | null > | null,
        return?: Array< string | null > | null,
      } | null > | null,
      hideExport?: boolean | null,
      extraInfo?: string | null,
      extraInfoT?: string | null,
      extraToConfirmation?: string | null,
      extraToConfirmationT?: string | null,
      livionKeyId?: string | null,
      phoneNumber?: string | null,
      importData?: string | null,
      disableOnline?: boolean | null,
      activeRange?:  {
        __typename: "StartReturnInterval",
        start?: string | null,
        end?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      category?:  {
        __typename: "Category",
        id: string,
        group?: string | null,
        organizationId?: string | null,
        name: string,
        image?: string | null,
        orderNumber?: number | null,
        backgroundColor?: string | null,
        minuteBuffer?: number | null,
        reservationBlockFromNow?: number | null,
        typeId?: string | null,
        _removed?: string | null,
        oldTujausValue?: string | null,
        exampleModelName?: string | null,
        exampleModelImage?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      additionalServices?:  {
        __typename: "ModelAdditionalServicePricingConnection",
        nextToken?: string | null,
      } | null,
      reservations?:  {
        __typename: "ModelReservationConnection",
        nextToken?: string | null,
      } | null,
      pricing?:  {
        __typename: "Pricing",
        id: string,
        name: string,
        group?: string | null,
        organizationId?: string | null,
        orgBusinessId?: string | null,
        orgExternalBusinessId?: string | null,
        externalBusinessCategoryAssociation?: string | null,
        businessCategoryAssociation?: string | null,
        useNextDayPrice?: boolean | null,
        useLastDayPrice?: boolean | null,
        tax?: number | null,
        _removed?: string | null,
        deductible?: number | null,
        reducedDeductible?: number | null,
        reducedDeductiblePrice?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type VehicleByCompanyQueryVariables = {
  companyId?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelVehicleFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type VehicleByCompanyQuery = {
  vehicleByCompany?:  {
    __typename: "ModelVehicleConnection",
    items:  Array< {
      __typename: "Vehicle",
      id: string,
      group?: string | null,
      organizationId?: string | null,
      orgBusinessId?: string | null,
      companyId: string,
      name: string,
      nameT?:  {
        __typename: "Translation",
        fi?: string | null,
        en?: string | null,
        sv?: string | null,
      } | null,
      registrationPlate?: string | null,
      damages?: string | null,
      damageRecords?:  Array< {
        __typename: "DamageRecord",
        normalizedX?: number | null,
        normalizedY?: number | null,
        label?: string | null,
        description?: string | null,
        createdAt?: string | null,
      } > | null,
      additionalFields:  Array< {
        __typename: "AdditionalField",
        key: string,
        value?: string | null,
      } >,
      image?: string | null,
      powerType?: string | null,
      deductible?: number | null,
      includedDistance?: string | null,
      mileage?: string | null,
      orderNumber?: number | null,
      vehicleCategoryId?: string | null,
      useCategoryDefaults?: boolean | null,
      useStartReturnTimes?: boolean | null,
      customStartReturns?:  Array< {
        __typename: "CustomStartReturn",
        priority?: number | null,
      } | null > | null,
      startReturnInterval?:  {
        __typename: "StartReturnInterval",
        start?: string | null,
        end?: string | null,
      } | null,
      startReturnTimes?:  Array< {
        __typename: "StartReturnTimes",
        start?: Array< string | null > | null,
        return?: Array< string | null > | null,
      } | null > | null,
      hideExport?: boolean | null,
      extraInfo?: string | null,
      extraInfoT?: string | null,
      extraToConfirmation?: string | null,
      extraToConfirmationT?: string | null,
      livionKeyId?: string | null,
      phoneNumber?: string | null,
      importData?: string | null,
      disableOnline?: boolean | null,
      activeRange?:  {
        __typename: "StartReturnInterval",
        start?: string | null,
        end?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      category?:  {
        __typename: "Category",
        id: string,
        group?: string | null,
        organizationId?: string | null,
        name: string,
        image?: string | null,
        orderNumber?: number | null,
        backgroundColor?: string | null,
        minuteBuffer?: number | null,
        reservationBlockFromNow?: number | null,
        typeId?: string | null,
        _removed?: string | null,
        oldTujausValue?: string | null,
        exampleModelName?: string | null,
        exampleModelImage?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      additionalServices?:  {
        __typename: "ModelAdditionalServicePricingConnection",
        nextToken?: string | null,
      } | null,
      reservations?:  {
        __typename: "ModelReservationConnection",
        nextToken?: string | null,
      } | null,
      pricing?:  {
        __typename: "Pricing",
        id: string,
        name: string,
        group?: string | null,
        organizationId?: string | null,
        orgBusinessId?: string | null,
        orgExternalBusinessId?: string | null,
        externalBusinessCategoryAssociation?: string | null,
        businessCategoryAssociation?: string | null,
        useNextDayPrice?: boolean | null,
        useLastDayPrice?: boolean | null,
        tax?: number | null,
        _removed?: string | null,
        deductible?: number | null,
        reducedDeductible?: number | null,
        reducedDeductiblePrice?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type VehicleByGroupQueryVariables = {
  group?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelVehicleFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type VehicleByGroupQuery = {
  vehicleByGroup?:  {
    __typename: "ModelVehicleConnection",
    items:  Array< {
      __typename: "Vehicle",
      id: string,
      group?: string | null,
      organizationId?: string | null,
      orgBusinessId?: string | null,
      companyId: string,
      name: string,
      nameT?:  {
        __typename: "Translation",
        fi?: string | null,
        en?: string | null,
        sv?: string | null,
      } | null,
      registrationPlate?: string | null,
      damages?: string | null,
      damageRecords?:  Array< {
        __typename: "DamageRecord",
        normalizedX?: number | null,
        normalizedY?: number | null,
        label?: string | null,
        description?: string | null,
        createdAt?: string | null,
      } > | null,
      additionalFields:  Array< {
        __typename: "AdditionalField",
        key: string,
        value?: string | null,
      } >,
      image?: string | null,
      powerType?: string | null,
      deductible?: number | null,
      includedDistance?: string | null,
      mileage?: string | null,
      orderNumber?: number | null,
      vehicleCategoryId?: string | null,
      useCategoryDefaults?: boolean | null,
      useStartReturnTimes?: boolean | null,
      customStartReturns?:  Array< {
        __typename: "CustomStartReturn",
        priority?: number | null,
      } | null > | null,
      startReturnInterval?:  {
        __typename: "StartReturnInterval",
        start?: string | null,
        end?: string | null,
      } | null,
      startReturnTimes?:  Array< {
        __typename: "StartReturnTimes",
        start?: Array< string | null > | null,
        return?: Array< string | null > | null,
      } | null > | null,
      hideExport?: boolean | null,
      extraInfo?: string | null,
      extraInfoT?: string | null,
      extraToConfirmation?: string | null,
      extraToConfirmationT?: string | null,
      livionKeyId?: string | null,
      phoneNumber?: string | null,
      importData?: string | null,
      disableOnline?: boolean | null,
      activeRange?:  {
        __typename: "StartReturnInterval",
        start?: string | null,
        end?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      category?:  {
        __typename: "Category",
        id: string,
        group?: string | null,
        organizationId?: string | null,
        name: string,
        image?: string | null,
        orderNumber?: number | null,
        backgroundColor?: string | null,
        minuteBuffer?: number | null,
        reservationBlockFromNow?: number | null,
        typeId?: string | null,
        _removed?: string | null,
        oldTujausValue?: string | null,
        exampleModelName?: string | null,
        exampleModelImage?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      additionalServices?:  {
        __typename: "ModelAdditionalServicePricingConnection",
        nextToken?: string | null,
      } | null,
      reservations?:  {
        __typename: "ModelReservationConnection",
        nextToken?: string | null,
      } | null,
      pricing?:  {
        __typename: "Pricing",
        id: string,
        name: string,
        group?: string | null,
        organizationId?: string | null,
        orgBusinessId?: string | null,
        orgExternalBusinessId?: string | null,
        externalBusinessCategoryAssociation?: string | null,
        businessCategoryAssociation?: string | null,
        useNextDayPrice?: boolean | null,
        useLastDayPrice?: boolean | null,
        tax?: number | null,
        _removed?: string | null,
        deductible?: number | null,
        reducedDeductible?: number | null,
        reducedDeductiblePrice?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type VehicleByOrganizaionQueryVariables = {
  organizationId?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelVehicleFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type VehicleByOrganizaionQuery = {
  vehicleByOrganizaion?:  {
    __typename: "ModelVehicleConnection",
    items:  Array< {
      __typename: "Vehicle",
      id: string,
      group?: string | null,
      organizationId?: string | null,
      orgBusinessId?: string | null,
      companyId: string,
      name: string,
      nameT?:  {
        __typename: "Translation",
        fi?: string | null,
        en?: string | null,
        sv?: string | null,
      } | null,
      registrationPlate?: string | null,
      damages?: string | null,
      damageRecords?:  Array< {
        __typename: "DamageRecord",
        normalizedX?: number | null,
        normalizedY?: number | null,
        label?: string | null,
        description?: string | null,
        createdAt?: string | null,
      } > | null,
      additionalFields:  Array< {
        __typename: "AdditionalField",
        key: string,
        value?: string | null,
      } >,
      image?: string | null,
      powerType?: string | null,
      deductible?: number | null,
      includedDistance?: string | null,
      mileage?: string | null,
      orderNumber?: number | null,
      vehicleCategoryId?: string | null,
      useCategoryDefaults?: boolean | null,
      useStartReturnTimes?: boolean | null,
      customStartReturns?:  Array< {
        __typename: "CustomStartReturn",
        priority?: number | null,
      } | null > | null,
      startReturnInterval?:  {
        __typename: "StartReturnInterval",
        start?: string | null,
        end?: string | null,
      } | null,
      startReturnTimes?:  Array< {
        __typename: "StartReturnTimes",
        start?: Array< string | null > | null,
        return?: Array< string | null > | null,
      } | null > | null,
      hideExport?: boolean | null,
      extraInfo?: string | null,
      extraInfoT?: string | null,
      extraToConfirmation?: string | null,
      extraToConfirmationT?: string | null,
      livionKeyId?: string | null,
      phoneNumber?: string | null,
      importData?: string | null,
      disableOnline?: boolean | null,
      activeRange?:  {
        __typename: "StartReturnInterval",
        start?: string | null,
        end?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      category?:  {
        __typename: "Category",
        id: string,
        group?: string | null,
        organizationId?: string | null,
        name: string,
        image?: string | null,
        orderNumber?: number | null,
        backgroundColor?: string | null,
        minuteBuffer?: number | null,
        reservationBlockFromNow?: number | null,
        typeId?: string | null,
        _removed?: string | null,
        oldTujausValue?: string | null,
        exampleModelName?: string | null,
        exampleModelImage?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      additionalServices?:  {
        __typename: "ModelAdditionalServicePricingConnection",
        nextToken?: string | null,
      } | null,
      reservations?:  {
        __typename: "ModelReservationConnection",
        nextToken?: string | null,
      } | null,
      pricing?:  {
        __typename: "Pricing",
        id: string,
        name: string,
        group?: string | null,
        organizationId?: string | null,
        orgBusinessId?: string | null,
        orgExternalBusinessId?: string | null,
        externalBusinessCategoryAssociation?: string | null,
        businessCategoryAssociation?: string | null,
        useNextDayPrice?: boolean | null,
        useLastDayPrice?: boolean | null,
        tax?: number | null,
        _removed?: string | null,
        deductible?: number | null,
        reducedDeductible?: number | null,
        reducedDeductiblePrice?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type VehicleByOrgBusinessIdQueryVariables = {
  orgBusinessId?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelVehicleFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type VehicleByOrgBusinessIdQuery = {
  vehicleByOrgBusinessId?:  {
    __typename: "ModelVehicleConnection",
    items:  Array< {
      __typename: "Vehicle",
      id: string,
      group?: string | null,
      organizationId?: string | null,
      orgBusinessId?: string | null,
      companyId: string,
      name: string,
      nameT?:  {
        __typename: "Translation",
        fi?: string | null,
        en?: string | null,
        sv?: string | null,
      } | null,
      registrationPlate?: string | null,
      damages?: string | null,
      damageRecords?:  Array< {
        __typename: "DamageRecord",
        normalizedX?: number | null,
        normalizedY?: number | null,
        label?: string | null,
        description?: string | null,
        createdAt?: string | null,
      } > | null,
      additionalFields:  Array< {
        __typename: "AdditionalField",
        key: string,
        value?: string | null,
      } >,
      image?: string | null,
      powerType?: string | null,
      deductible?: number | null,
      includedDistance?: string | null,
      mileage?: string | null,
      orderNumber?: number | null,
      vehicleCategoryId?: string | null,
      useCategoryDefaults?: boolean | null,
      useStartReturnTimes?: boolean | null,
      customStartReturns?:  Array< {
        __typename: "CustomStartReturn",
        priority?: number | null,
      } | null > | null,
      startReturnInterval?:  {
        __typename: "StartReturnInterval",
        start?: string | null,
        end?: string | null,
      } | null,
      startReturnTimes?:  Array< {
        __typename: "StartReturnTimes",
        start?: Array< string | null > | null,
        return?: Array< string | null > | null,
      } | null > | null,
      hideExport?: boolean | null,
      extraInfo?: string | null,
      extraInfoT?: string | null,
      extraToConfirmation?: string | null,
      extraToConfirmationT?: string | null,
      livionKeyId?: string | null,
      phoneNumber?: string | null,
      importData?: string | null,
      disableOnline?: boolean | null,
      activeRange?:  {
        __typename: "StartReturnInterval",
        start?: string | null,
        end?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      category?:  {
        __typename: "Category",
        id: string,
        group?: string | null,
        organizationId?: string | null,
        name: string,
        image?: string | null,
        orderNumber?: number | null,
        backgroundColor?: string | null,
        minuteBuffer?: number | null,
        reservationBlockFromNow?: number | null,
        typeId?: string | null,
        _removed?: string | null,
        oldTujausValue?: string | null,
        exampleModelName?: string | null,
        exampleModelImage?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      additionalServices?:  {
        __typename: "ModelAdditionalServicePricingConnection",
        nextToken?: string | null,
      } | null,
      reservations?:  {
        __typename: "ModelReservationConnection",
        nextToken?: string | null,
      } | null,
      pricing?:  {
        __typename: "Pricing",
        id: string,
        name: string,
        group?: string | null,
        organizationId?: string | null,
        orgBusinessId?: string | null,
        orgExternalBusinessId?: string | null,
        externalBusinessCategoryAssociation?: string | null,
        businessCategoryAssociation?: string | null,
        useNextDayPrice?: boolean | null,
        useLastDayPrice?: boolean | null,
        tax?: number | null,
        _removed?: string | null,
        deductible?: number | null,
        reducedDeductible?: number | null,
        reducedDeductiblePrice?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type VehicleByCategoryQueryVariables = {
  vehicleCategoryId?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelVehicleFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type VehicleByCategoryQuery = {
  vehicleByCategory?:  {
    __typename: "ModelVehicleConnection",
    items:  Array< {
      __typename: "Vehicle",
      id: string,
      group?: string | null,
      organizationId?: string | null,
      orgBusinessId?: string | null,
      companyId: string,
      name: string,
      nameT?:  {
        __typename: "Translation",
        fi?: string | null,
        en?: string | null,
        sv?: string | null,
      } | null,
      registrationPlate?: string | null,
      damages?: string | null,
      damageRecords?:  Array< {
        __typename: "DamageRecord",
        normalizedX?: number | null,
        normalizedY?: number | null,
        label?: string | null,
        description?: string | null,
        createdAt?: string | null,
      } > | null,
      additionalFields:  Array< {
        __typename: "AdditionalField",
        key: string,
        value?: string | null,
      } >,
      image?: string | null,
      powerType?: string | null,
      deductible?: number | null,
      includedDistance?: string | null,
      mileage?: string | null,
      orderNumber?: number | null,
      vehicleCategoryId?: string | null,
      useCategoryDefaults?: boolean | null,
      useStartReturnTimes?: boolean | null,
      customStartReturns?:  Array< {
        __typename: "CustomStartReturn",
        priority?: number | null,
      } | null > | null,
      startReturnInterval?:  {
        __typename: "StartReturnInterval",
        start?: string | null,
        end?: string | null,
      } | null,
      startReturnTimes?:  Array< {
        __typename: "StartReturnTimes",
        start?: Array< string | null > | null,
        return?: Array< string | null > | null,
      } | null > | null,
      hideExport?: boolean | null,
      extraInfo?: string | null,
      extraInfoT?: string | null,
      extraToConfirmation?: string | null,
      extraToConfirmationT?: string | null,
      livionKeyId?: string | null,
      phoneNumber?: string | null,
      importData?: string | null,
      disableOnline?: boolean | null,
      activeRange?:  {
        __typename: "StartReturnInterval",
        start?: string | null,
        end?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      category?:  {
        __typename: "Category",
        id: string,
        group?: string | null,
        organizationId?: string | null,
        name: string,
        image?: string | null,
        orderNumber?: number | null,
        backgroundColor?: string | null,
        minuteBuffer?: number | null,
        reservationBlockFromNow?: number | null,
        typeId?: string | null,
        _removed?: string | null,
        oldTujausValue?: string | null,
        exampleModelName?: string | null,
        exampleModelImage?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      additionalServices?:  {
        __typename: "ModelAdditionalServicePricingConnection",
        nextToken?: string | null,
      } | null,
      reservations?:  {
        __typename: "ModelReservationConnection",
        nextToken?: string | null,
      } | null,
      pricing?:  {
        __typename: "Pricing",
        id: string,
        name: string,
        group?: string | null,
        organizationId?: string | null,
        orgBusinessId?: string | null,
        orgExternalBusinessId?: string | null,
        externalBusinessCategoryAssociation?: string | null,
        businessCategoryAssociation?: string | null,
        useNextDayPrice?: boolean | null,
        useLastDayPrice?: boolean | null,
        tax?: number | null,
        _removed?: string | null,
        deductible?: number | null,
        reducedDeductible?: number | null,
        reducedDeductiblePrice?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type SearchVehiclesQueryVariables = {
  filter?: SearchableVehicleFilterInput | null,
  sort?: SearchableVehicleSortInput | null,
  limit?: number | null,
  nextToken?: string | null,
  from?: number | null,
};

export type SearchVehiclesQuery = {
  searchVehicles?:  {
    __typename: "SearchableVehicleConnection",
    items:  Array< {
      __typename: "Vehicle",
      id: string,
      group?: string | null,
      organizationId?: string | null,
      orgBusinessId?: string | null,
      companyId: string,
      name: string,
      nameT?:  {
        __typename: "Translation",
        fi?: string | null,
        en?: string | null,
        sv?: string | null,
      } | null,
      registrationPlate?: string | null,
      damages?: string | null,
      damageRecords?:  Array< {
        __typename: "DamageRecord",
        normalizedX?: number | null,
        normalizedY?: number | null,
        label?: string | null,
        description?: string | null,
        createdAt?: string | null,
      } > | null,
      additionalFields:  Array< {
        __typename: "AdditionalField",
        key: string,
        value?: string | null,
      } >,
      image?: string | null,
      powerType?: string | null,
      deductible?: number | null,
      includedDistance?: string | null,
      mileage?: string | null,
      orderNumber?: number | null,
      vehicleCategoryId?: string | null,
      useCategoryDefaults?: boolean | null,
      useStartReturnTimes?: boolean | null,
      customStartReturns?:  Array< {
        __typename: "CustomStartReturn",
        priority?: number | null,
      } | null > | null,
      startReturnInterval?:  {
        __typename: "StartReturnInterval",
        start?: string | null,
        end?: string | null,
      } | null,
      startReturnTimes?:  Array< {
        __typename: "StartReturnTimes",
        start?: Array< string | null > | null,
        return?: Array< string | null > | null,
      } | null > | null,
      hideExport?: boolean | null,
      extraInfo?: string | null,
      extraInfoT?: string | null,
      extraToConfirmation?: string | null,
      extraToConfirmationT?: string | null,
      livionKeyId?: string | null,
      phoneNumber?: string | null,
      importData?: string | null,
      disableOnline?: boolean | null,
      activeRange?:  {
        __typename: "StartReturnInterval",
        start?: string | null,
        end?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      category?:  {
        __typename: "Category",
        id: string,
        group?: string | null,
        organizationId?: string | null,
        name: string,
        image?: string | null,
        orderNumber?: number | null,
        backgroundColor?: string | null,
        minuteBuffer?: number | null,
        reservationBlockFromNow?: number | null,
        typeId?: string | null,
        _removed?: string | null,
        oldTujausValue?: string | null,
        exampleModelName?: string | null,
        exampleModelImage?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      additionalServices?:  {
        __typename: "ModelAdditionalServicePricingConnection",
        nextToken?: string | null,
      } | null,
      reservations?:  {
        __typename: "ModelReservationConnection",
        nextToken?: string | null,
      } | null,
      pricing?:  {
        __typename: "Pricing",
        id: string,
        name: string,
        group?: string | null,
        organizationId?: string | null,
        orgBusinessId?: string | null,
        orgExternalBusinessId?: string | null,
        externalBusinessCategoryAssociation?: string | null,
        businessCategoryAssociation?: string | null,
        useNextDayPrice?: boolean | null,
        useLastDayPrice?: boolean | null,
        tax?: number | null,
        _removed?: string | null,
        deductible?: number | null,
        reducedDeductible?: number | null,
        reducedDeductiblePrice?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null,
    } | null >,
    nextToken?: string | null,
    total?: number | null,
  } | null,
};

export type GetCategoryQueryVariables = {
  id: string,
};

export type GetCategoryQuery = {
  getCategory?:  {
    __typename: "Category",
    id: string,
    group?: string | null,
    organizationId?: string | null,
    name: string,
    image?: string | null,
    orderNumber?: number | null,
    backgroundColor?: string | null,
    minuteBuffer?: number | null,
    reservationBlockFromNow?: number | null,
    typeId?: string | null,
    _removed?: string | null,
    oldTujausValue?: string | null,
    exampleModelName?: string | null,
    exampleModelImage?: string | null,
    createdAt: string,
    updatedAt: string,
    additionalServices?:  {
      __typename: "ModelAdditionalServicePricingConnection",
      items:  Array< {
        __typename: "AdditionalServicePricing",
        id: string,
        group?: string | null,
        organizationId?: string | null,
        orgBusinessId?: string | null,
        key: string,
        vehicleId?: string | null,
        categoryId?: string | null,
        pricingId: string,
        deductibleValue?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    pricing?:  {
      __typename: "Pricing",
      id: string,
      name: string,
      group?: string | null,
      organizationId?: string | null,
      orgBusinessId?: string | null,
      orgExternalBusinessId?: string | null,
      externalBusinessCategoryAssociation?: string | null,
      businessCategoryAssociation?: string | null,
      elements:  Array< {
        __typename: "PricingElement",
        duration: string,
        price: number,
      } >,
      useNextDayPrice?: boolean | null,
      useLastDayPrice?: boolean | null,
      tax?: number | null,
      _removed?: string | null,
      deductible?: number | null,
      reducedDeductible?: number | null,
      reducedDeductiblePrice?: number | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    externalBusinessPricings?:  {
      __typename: "ModelPricingConnection",
      items:  Array< {
        __typename: "Pricing",
        id: string,
        name: string,
        group?: string | null,
        organizationId?: string | null,
        orgBusinessId?: string | null,
        orgExternalBusinessId?: string | null,
        externalBusinessCategoryAssociation?: string | null,
        businessCategoryAssociation?: string | null,
        useNextDayPrice?: boolean | null,
        useLastDayPrice?: boolean | null,
        tax?: number | null,
        _removed?: string | null,
        deductible?: number | null,
        reducedDeductible?: number | null,
        reducedDeductiblePrice?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type ListCategorysQueryVariables = {
  filter?: ModelCategoryFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListCategorysQuery = {
  listCategorys?:  {
    __typename: "ModelCategoryConnection",
    items:  Array< {
      __typename: "Category",
      id: string,
      group?: string | null,
      organizationId?: string | null,
      name: string,
      image?: string | null,
      orderNumber?: number | null,
      backgroundColor?: string | null,
      minuteBuffer?: number | null,
      reservationBlockFromNow?: number | null,
      typeId?: string | null,
      _removed?: string | null,
      oldTujausValue?: string | null,
      exampleModelName?: string | null,
      exampleModelImage?: string | null,
      createdAt: string,
      updatedAt: string,
      additionalServices?:  {
        __typename: "ModelAdditionalServicePricingConnection",
        nextToken?: string | null,
      } | null,
      pricing?:  {
        __typename: "Pricing",
        id: string,
        name: string,
        group?: string | null,
        organizationId?: string | null,
        orgBusinessId?: string | null,
        orgExternalBusinessId?: string | null,
        externalBusinessCategoryAssociation?: string | null,
        businessCategoryAssociation?: string | null,
        useNextDayPrice?: boolean | null,
        useLastDayPrice?: boolean | null,
        tax?: number | null,
        _removed?: string | null,
        deductible?: number | null,
        reducedDeductible?: number | null,
        reducedDeductiblePrice?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      externalBusinessPricings?:  {
        __typename: "ModelPricingConnection",
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type CategoryByOrganizationQueryVariables = {
  organizationId?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCategoryFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type CategoryByOrganizationQuery = {
  categoryByOrganization?:  {
    __typename: "ModelCategoryConnection",
    items:  Array< {
      __typename: "Category",
      id: string,
      group?: string | null,
      organizationId?: string | null,
      name: string,
      image?: string | null,
      orderNumber?: number | null,
      backgroundColor?: string | null,
      minuteBuffer?: number | null,
      reservationBlockFromNow?: number | null,
      typeId?: string | null,
      _removed?: string | null,
      oldTujausValue?: string | null,
      exampleModelName?: string | null,
      exampleModelImage?: string | null,
      createdAt: string,
      updatedAt: string,
      additionalServices?:  {
        __typename: "ModelAdditionalServicePricingConnection",
        nextToken?: string | null,
      } | null,
      pricing?:  {
        __typename: "Pricing",
        id: string,
        name: string,
        group?: string | null,
        organizationId?: string | null,
        orgBusinessId?: string | null,
        orgExternalBusinessId?: string | null,
        externalBusinessCategoryAssociation?: string | null,
        businessCategoryAssociation?: string | null,
        useNextDayPrice?: boolean | null,
        useLastDayPrice?: boolean | null,
        tax?: number | null,
        _removed?: string | null,
        deductible?: number | null,
        reducedDeductible?: number | null,
        reducedDeductiblePrice?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      externalBusinessPricings?:  {
        __typename: "ModelPricingConnection",
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type CategoryByGroupQueryVariables = {
  group?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCategoryFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type CategoryByGroupQuery = {
  categoryByGroup?:  {
    __typename: "ModelCategoryConnection",
    items:  Array< {
      __typename: "Category",
      id: string,
      group?: string | null,
      organizationId?: string | null,
      name: string,
      image?: string | null,
      orderNumber?: number | null,
      backgroundColor?: string | null,
      minuteBuffer?: number | null,
      reservationBlockFromNow?: number | null,
      typeId?: string | null,
      _removed?: string | null,
      oldTujausValue?: string | null,
      exampleModelName?: string | null,
      exampleModelImage?: string | null,
      createdAt: string,
      updatedAt: string,
      additionalServices?:  {
        __typename: "ModelAdditionalServicePricingConnection",
        nextToken?: string | null,
      } | null,
      pricing?:  {
        __typename: "Pricing",
        id: string,
        name: string,
        group?: string | null,
        organizationId?: string | null,
        orgBusinessId?: string | null,
        orgExternalBusinessId?: string | null,
        externalBusinessCategoryAssociation?: string | null,
        businessCategoryAssociation?: string | null,
        useNextDayPrice?: boolean | null,
        useLastDayPrice?: boolean | null,
        tax?: number | null,
        _removed?: string | null,
        deductible?: number | null,
        reducedDeductible?: number | null,
        reducedDeductiblePrice?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      externalBusinessPricings?:  {
        __typename: "ModelPricingConnection",
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetAdditionalServicePricingQueryVariables = {
  id: string,
};

export type GetAdditionalServicePricingQuery = {
  getAdditionalServicePricing?:  {
    __typename: "AdditionalServicePricing",
    id: string,
    group?: string | null,
    organizationId?: string | null,
    orgBusinessId?: string | null,
    key: string,
    vehicleId?: string | null,
    categoryId?: string | null,
    pricingId: string,
    deductibleValue?: number | null,
    createdAt: string,
    updatedAt: string,
    pricing?:  {
      __typename: "Pricing",
      id: string,
      name: string,
      group?: string | null,
      organizationId?: string | null,
      orgBusinessId?: string | null,
      orgExternalBusinessId?: string | null,
      externalBusinessCategoryAssociation?: string | null,
      businessCategoryAssociation?: string | null,
      elements:  Array< {
        __typename: "PricingElement",
        duration: string,
        price: number,
      } >,
      useNextDayPrice?: boolean | null,
      useLastDayPrice?: boolean | null,
      tax?: number | null,
      _removed?: string | null,
      deductible?: number | null,
      reducedDeductible?: number | null,
      reducedDeductiblePrice?: number | null,
      createdAt: string,
      updatedAt: string,
    } | null,
  } | null,
};

export type ListAdditionalServicePricingsQueryVariables = {
  filter?: ModelAdditionalServicePricingFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListAdditionalServicePricingsQuery = {
  listAdditionalServicePricings?:  {
    __typename: "ModelAdditionalServicePricingConnection",
    items:  Array< {
      __typename: "AdditionalServicePricing",
      id: string,
      group?: string | null,
      organizationId?: string | null,
      orgBusinessId?: string | null,
      key: string,
      vehicleId?: string | null,
      categoryId?: string | null,
      pricingId: string,
      deductibleValue?: number | null,
      createdAt: string,
      updatedAt: string,
      pricing?:  {
        __typename: "Pricing",
        id: string,
        name: string,
        group?: string | null,
        organizationId?: string | null,
        orgBusinessId?: string | null,
        orgExternalBusinessId?: string | null,
        externalBusinessCategoryAssociation?: string | null,
        businessCategoryAssociation?: string | null,
        useNextDayPrice?: boolean | null,
        useLastDayPrice?: boolean | null,
        tax?: number | null,
        _removed?: string | null,
        deductible?: number | null,
        reducedDeductible?: number | null,
        reducedDeductiblePrice?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetUserQueryVariables = {
  id: string,
};

export type GetUserQuery = {
  getUser?:  {
    __typename: "User",
    id: string,
    organizationId?: string | null,
    orgBusinessId?: string | null,
    orgBusinessIds?: Array< string | null > | null,
    externalBusinessId?: string | null,
    role?: UserRole | null,
    permissions?:  {
      __typename: "Permission",
      userManagement?: boolean | null,
    } | null,
    email?: string | null,
    name?: string | null,
    regionalCompanyName?: string | null,
    createdAt: string,
    updatedAt: string,
    categoriesByOrg?:  {
      __typename: "ModelCategoryConnection",
      items:  Array< {
        __typename: "Category",
        id: string,
        group?: string | null,
        organizationId?: string | null,
        name: string,
        image?: string | null,
        orderNumber?: number | null,
        backgroundColor?: string | null,
        minuteBuffer?: number | null,
        reservationBlockFromNow?: number | null,
        typeId?: string | null,
        _removed?: string | null,
        oldTujausValue?: string | null,
        exampleModelName?: string | null,
        exampleModelImage?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    organization?:  {
      __typename: "Organization",
      id: string,
      name?: string | null,
      billingCustomers?:  Array< {
        __typename: "BillingCustomer",
        name?: string | null,
      } | null > | null,
      features?:  {
        __typename: "OrganizationFeatures",
        enableNPS?: boolean | null,
        enableBusinessWideReservation?: boolean | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      businesses?:  {
        __typename: "ModelBusinessConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    externalBusiness?:  {
      __typename: "ExternalBusiness",
      id: string,
      organizationId?: string | null,
      type?: ExternalBusinessType | null,
      name?: string | null,
      insuranceProducts?:  Array< {
        __typename: "InsuranceProducts",
        name?: string | null,
        coveragePercentage?: number | null,
        compensatableDays?: number | null,
        billingPermitCode?: string | null,
      } | null > | null,
      insuranceRegionalCompanies?:  Array< {
        __typename: "InsuranceRegionalCompany",
        name?: string | null,
        companyName?: string | null,
        businessId?: string | null,
        address?: string | null,
        postalCode?: string | null,
        postalArea?: string | null,
        email?: string | null,
        innovoiceId?: string | null,
      } | null > | null,
      insuranceCompensatableTimes?: Array< number | null > | null,
      services?:  Array< {
        __typename: "CustomService",
        description?: string | null,
        price?: number | null,
        category?: string | null,
        deductibleValue?: number | null,
      } | null > | null,
      useHansel?: boolean | null,
      hanselId?: string | null,
      availableOrgBusinessIds?: Array< string | null > | null,
      availableCompanyIds?: Array< string | null > | null,
      availableVehicleClasses?: Array< string | null > | null,
      eBillingRef?: string | null,
      eBillingOperator?: string | null,
      eBillingAddress?: string | null,
      createdAt: string,
      updatedAt: string,
      companies?:  {
        __typename: "ModelCompanyConnection",
        nextToken?: string | null,
      } | null,
      pricings?:  {
        __typename: "ModelPricingConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    externalBusinessByOrg?:  {
      __typename: "ModelExternalBusinessConnection",
      items:  Array< {
        __typename: "ExternalBusiness",
        id: string,
        organizationId?: string | null,
        type?: ExternalBusinessType | null,
        name?: string | null,
        insuranceCompensatableTimes?: Array< number | null > | null,
        useHansel?: boolean | null,
        hanselId?: string | null,
        availableOrgBusinessIds?: Array< string | null > | null,
        availableCompanyIds?: Array< string | null > | null,
        availableVehicleClasses?: Array< string | null > | null,
        eBillingRef?: string | null,
        eBillingOperator?: string | null,
        eBillingAddress?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    business?:  {
      __typename: "Business",
      id: string,
      organizationId?: string | null,
      telegramChatId?: string | null,
      telegramConnections?:  Array< {
        __typename: "TelegramInformation",
        chatId?: string | null,
        name?: string | null,
      } | null > | null,
      group?: string | null,
      name?: string | null,
      address?: string | null,
      city?: string | null,
      email?: string | null,
      phone?: string | null,
      insuranceCoverageZone?:  {
        __typename: "PostalCodeRange",
        start?: string | null,
        end?: string | null,
      } | null,
      insuranceCoverageZones?:  Array< {
        __typename: "PostalCodeRange",
        start?: string | null,
        end?: string | null,
      } | null > | null,
      importIdentifier?: string | null,
      categoryDynamicPricings?:  Array< {
        __typename: "CategoryDynamicPricing",
        categoryId?: string | null,
        pricingId?: string | null,
        percentage?: number | null,
      } | null > | null,
      startLocations?:  Array< {
        __typename: "PickupLocation",
        id?: string | null,
        name?: string | null,
        locationColor?: string | null,
        companyId?: string | null,
      } | null > | null,
      pickupLocations?:  Array< {
        __typename: "PickupLocation",
        id?: string | null,
        name?: string | null,
        locationColor?: string | null,
        companyId?: string | null,
      } | null > | null,
      createdAt: string,
      updatedAt: string,
      companies?:  {
        __typename: "ModelCompanyConnection",
        nextToken?: string | null,
      } | null,
      categories?:  {
        __typename: "ModelCategoryConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    businessByOrg?:  {
      __typename: "ModelBusinessConnection",
      items:  Array< {
        __typename: "Business",
        id: string,
        organizationId?: string | null,
        telegramChatId?: string | null,
        group?: string | null,
        name?: string | null,
        address?: string | null,
        city?: string | null,
        email?: string | null,
        phone?: string | null,
        importIdentifier?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type ListUsersQueryVariables = {
  filter?: ModelUserFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListUsersQuery = {
  listUsers?:  {
    __typename: "ModelUserConnection",
    items:  Array< {
      __typename: "User",
      id: string,
      organizationId?: string | null,
      orgBusinessId?: string | null,
      orgBusinessIds?: Array< string | null > | null,
      externalBusinessId?: string | null,
      role?: UserRole | null,
      permissions?:  {
        __typename: "Permission",
        userManagement?: boolean | null,
      } | null,
      email?: string | null,
      name?: string | null,
      regionalCompanyName?: string | null,
      createdAt: string,
      updatedAt: string,
      categoriesByOrg?:  {
        __typename: "ModelCategoryConnection",
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      externalBusiness?:  {
        __typename: "ExternalBusiness",
        id: string,
        organizationId?: string | null,
        type?: ExternalBusinessType | null,
        name?: string | null,
        insuranceCompensatableTimes?: Array< number | null > | null,
        useHansel?: boolean | null,
        hanselId?: string | null,
        availableOrgBusinessIds?: Array< string | null > | null,
        availableCompanyIds?: Array< string | null > | null,
        availableVehicleClasses?: Array< string | null > | null,
        eBillingRef?: string | null,
        eBillingOperator?: string | null,
        eBillingAddress?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      externalBusinessByOrg?:  {
        __typename: "ModelExternalBusinessConnection",
        nextToken?: string | null,
      } | null,
      business?:  {
        __typename: "Business",
        id: string,
        organizationId?: string | null,
        telegramChatId?: string | null,
        group?: string | null,
        name?: string | null,
        address?: string | null,
        city?: string | null,
        email?: string | null,
        phone?: string | null,
        importIdentifier?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      businessByOrg?:  {
        __typename: "ModelBusinessConnection",
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type UserByOrganizationQueryVariables = {
  organizationId?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelUserFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type UserByOrganizationQuery = {
  userByOrganization?:  {
    __typename: "ModelUserConnection",
    items:  Array< {
      __typename: "User",
      id: string,
      organizationId?: string | null,
      orgBusinessId?: string | null,
      orgBusinessIds?: Array< string | null > | null,
      externalBusinessId?: string | null,
      role?: UserRole | null,
      permissions?:  {
        __typename: "Permission",
        userManagement?: boolean | null,
      } | null,
      email?: string | null,
      name?: string | null,
      regionalCompanyName?: string | null,
      createdAt: string,
      updatedAt: string,
      categoriesByOrg?:  {
        __typename: "ModelCategoryConnection",
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      externalBusiness?:  {
        __typename: "ExternalBusiness",
        id: string,
        organizationId?: string | null,
        type?: ExternalBusinessType | null,
        name?: string | null,
        insuranceCompensatableTimes?: Array< number | null > | null,
        useHansel?: boolean | null,
        hanselId?: string | null,
        availableOrgBusinessIds?: Array< string | null > | null,
        availableCompanyIds?: Array< string | null > | null,
        availableVehicleClasses?: Array< string | null > | null,
        eBillingRef?: string | null,
        eBillingOperator?: string | null,
        eBillingAddress?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      externalBusinessByOrg?:  {
        __typename: "ModelExternalBusinessConnection",
        nextToken?: string | null,
      } | null,
      business?:  {
        __typename: "Business",
        id: string,
        organizationId?: string | null,
        telegramChatId?: string | null,
        group?: string | null,
        name?: string | null,
        address?: string | null,
        city?: string | null,
        email?: string | null,
        phone?: string | null,
        importIdentifier?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      businessByOrg?:  {
        __typename: "ModelBusinessConnection",
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type UserByOrgBusinessIdQueryVariables = {
  orgBusinessId?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelUserFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type UserByOrgBusinessIdQuery = {
  userByOrgBusinessId?:  {
    __typename: "ModelUserConnection",
    items:  Array< {
      __typename: "User",
      id: string,
      organizationId?: string | null,
      orgBusinessId?: string | null,
      orgBusinessIds?: Array< string | null > | null,
      externalBusinessId?: string | null,
      role?: UserRole | null,
      permissions?:  {
        __typename: "Permission",
        userManagement?: boolean | null,
      } | null,
      email?: string | null,
      name?: string | null,
      regionalCompanyName?: string | null,
      createdAt: string,
      updatedAt: string,
      categoriesByOrg?:  {
        __typename: "ModelCategoryConnection",
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      externalBusiness?:  {
        __typename: "ExternalBusiness",
        id: string,
        organizationId?: string | null,
        type?: ExternalBusinessType | null,
        name?: string | null,
        insuranceCompensatableTimes?: Array< number | null > | null,
        useHansel?: boolean | null,
        hanselId?: string | null,
        availableOrgBusinessIds?: Array< string | null > | null,
        availableCompanyIds?: Array< string | null > | null,
        availableVehicleClasses?: Array< string | null > | null,
        eBillingRef?: string | null,
        eBillingOperator?: string | null,
        eBillingAddress?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      externalBusinessByOrg?:  {
        __typename: "ModelExternalBusinessConnection",
        nextToken?: string | null,
      } | null,
      business?:  {
        __typename: "Business",
        id: string,
        organizationId?: string | null,
        telegramChatId?: string | null,
        group?: string | null,
        name?: string | null,
        address?: string | null,
        city?: string | null,
        email?: string | null,
        phone?: string | null,
        importIdentifier?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      businessByOrg?:  {
        __typename: "ModelBusinessConnection",
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type UserByExternalBusinessQueryVariables = {
  externalBusinessId?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelUserFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type UserByExternalBusinessQuery = {
  userByExternalBusiness?:  {
    __typename: "ModelUserConnection",
    items:  Array< {
      __typename: "User",
      id: string,
      organizationId?: string | null,
      orgBusinessId?: string | null,
      orgBusinessIds?: Array< string | null > | null,
      externalBusinessId?: string | null,
      role?: UserRole | null,
      permissions?:  {
        __typename: "Permission",
        userManagement?: boolean | null,
      } | null,
      email?: string | null,
      name?: string | null,
      regionalCompanyName?: string | null,
      createdAt: string,
      updatedAt: string,
      categoriesByOrg?:  {
        __typename: "ModelCategoryConnection",
        nextToken?: string | null,
      } | null,
      organization?:  {
        __typename: "Organization",
        id: string,
        name?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      externalBusiness?:  {
        __typename: "ExternalBusiness",
        id: string,
        organizationId?: string | null,
        type?: ExternalBusinessType | null,
        name?: string | null,
        insuranceCompensatableTimes?: Array< number | null > | null,
        useHansel?: boolean | null,
        hanselId?: string | null,
        availableOrgBusinessIds?: Array< string | null > | null,
        availableCompanyIds?: Array< string | null > | null,
        availableVehicleClasses?: Array< string | null > | null,
        eBillingRef?: string | null,
        eBillingOperator?: string | null,
        eBillingAddress?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      externalBusinessByOrg?:  {
        __typename: "ModelExternalBusinessConnection",
        nextToken?: string | null,
      } | null,
      business?:  {
        __typename: "Business",
        id: string,
        organizationId?: string | null,
        telegramChatId?: string | null,
        group?: string | null,
        name?: string | null,
        address?: string | null,
        city?: string | null,
        email?: string | null,
        phone?: string | null,
        importIdentifier?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      businessByOrg?:  {
        __typename: "ModelBusinessConnection",
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetOrganizationQueryVariables = {
  id: string,
};

export type GetOrganizationQuery = {
  getOrganization?:  {
    __typename: "Organization",
    id: string,
    name?: string | null,
    billingCustomers?:  Array< {
      __typename: "BillingCustomer",
      name?: string | null,
    } | null > | null,
    features?:  {
      __typename: "OrganizationFeatures",
      enableNPS?: boolean | null,
      enableBusinessWideReservation?: boolean | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    businesses?:  {
      __typename: "ModelBusinessConnection",
      items:  Array< {
        __typename: "Business",
        id: string,
        organizationId?: string | null,
        telegramChatId?: string | null,
        group?: string | null,
        name?: string | null,
        address?: string | null,
        city?: string | null,
        email?: string | null,
        phone?: string | null,
        importIdentifier?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type ListOrganizationsQueryVariables = {
  filter?: ModelOrganizationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListOrganizationsQuery = {
  listOrganizations?:  {
    __typename: "ModelOrganizationConnection",
    items:  Array< {
      __typename: "Organization",
      id: string,
      name?: string | null,
      billingCustomers?:  Array< {
        __typename: "BillingCustomer",
        name?: string | null,
      } | null > | null,
      features?:  {
        __typename: "OrganizationFeatures",
        enableNPS?: boolean | null,
        enableBusinessWideReservation?: boolean | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      businesses?:  {
        __typename: "ModelBusinessConnection",
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetExternalBusinessQueryVariables = {
  id: string,
};

export type GetExternalBusinessQuery = {
  getExternalBusiness?:  {
    __typename: "ExternalBusiness",
    id: string,
    organizationId?: string | null,
    type?: ExternalBusinessType | null,
    name?: string | null,
    insuranceProducts?:  Array< {
      __typename: "InsuranceProducts",
      name?: string | null,
      coveragePercentage?: number | null,
      compensatableDays?: number | null,
      billingPermitCode?: string | null,
    } | null > | null,
    insuranceRegionalCompanies?:  Array< {
      __typename: "InsuranceRegionalCompany",
      name?: string | null,
      companyName?: string | null,
      businessId?: string | null,
      address?: string | null,
      postalCode?: string | null,
      postalArea?: string | null,
      email?: string | null,
      innovoiceId?: string | null,
    } | null > | null,
    insuranceCompensatableTimes?: Array< number | null > | null,
    services?:  Array< {
      __typename: "CustomService",
      description?: string | null,
      price?: number | null,
      category?: string | null,
      deductibleValue?: number | null,
    } | null > | null,
    useHansel?: boolean | null,
    hanselId?: string | null,
    availableOrgBusinessIds?: Array< string | null > | null,
    availableCompanyIds?: Array< string | null > | null,
    availableVehicleClasses?: Array< string | null > | null,
    eBillingRef?: string | null,
    eBillingOperator?: string | null,
    eBillingAddress?: string | null,
    createdAt: string,
    updatedAt: string,
    companies?:  {
      __typename: "ModelCompanyConnection",
      items:  Array< {
        __typename: "Company",
        id: string,
        organizationId?: string | null,
        orgBusinessId?: string | null,
        group?: string | null,
        name: string,
        displayName?: string | null,
        email?: string | null,
        address?: string | null,
        postalCode?: string | null,
        city?: string | null,
        orderNumber?: number | null,
        orderNumberDealer?: number | null,
        phone?: string | null,
        businessId?: string | null,
        termsUrl?: string | null,
        timezone: string,
        signConfirmationEmail?: string | null,
        nameInEmail?: string | null,
        senderNameSMS?: string | null,
        confirmationMsg?: string | null,
        feedbackMsg?: string | null,
        statusLinkMsg?: string | null,
        livionDeviceId?: string | null,
        livionDeviceType?: LivionDevice | null,
        enableReservationDeposit?: boolean | null,
        reservationLinkExpirationHours?: number | null,
        disableOffHourReturn?: boolean | null,
        disableMultiday?: boolean | null,
        disableSameDay?: boolean | null,
        disableStartDaysFromNow?: number | null,
        enableCart?: boolean | null,
        enableMonthlyView?: boolean | null,
        enableRegistrationPlate?: boolean | null,
        enableVehicleStartReturnTimes?: boolean | null,
        enableMustSign?: boolean | null,
        enablePaymentOnlineOnly?: boolean | null,
        enableGTM?: boolean | null,
        enableNetPromoterScore?: boolean | null,
        enableNPS?: boolean | null,
        limitReservationOncePerDay?: boolean | null,
        hidden?: boolean | null,
        locationType?: string | null,
        offHourAvailability?: boolean | null,
        offHourAvailabilityFee?: number | null,
        deliveryFee?: number | null,
        deliveryBufferHours?: number | null,
        notAllowedCategoryIds?: Array< string | null > | null,
        importIdentifier?: string | null,
        contractSettingsJSON?: string | null,
        disableReservationCategoryIds?: Array< string | null > | null,
        locationColor?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    pricings?:  {
      __typename: "ModelPricingConnection",
      items:  Array< {
        __typename: "Pricing",
        id: string,
        name: string,
        group?: string | null,
        organizationId?: string | null,
        orgBusinessId?: string | null,
        orgExternalBusinessId?: string | null,
        externalBusinessCategoryAssociation?: string | null,
        businessCategoryAssociation?: string | null,
        useNextDayPrice?: boolean | null,
        useLastDayPrice?: boolean | null,
        tax?: number | null,
        _removed?: string | null,
        deductible?: number | null,
        reducedDeductible?: number | null,
        reducedDeductiblePrice?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type ListExternalBusinesssQueryVariables = {
  filter?: ModelExternalBusinessFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListExternalBusinesssQuery = {
  listExternalBusinesss?:  {
    __typename: "ModelExternalBusinessConnection",
    items:  Array< {
      __typename: "ExternalBusiness",
      id: string,
      organizationId?: string | null,
      type?: ExternalBusinessType | null,
      name?: string | null,
      insuranceProducts?:  Array< {
        __typename: "InsuranceProducts",
        name?: string | null,
        coveragePercentage?: number | null,
        compensatableDays?: number | null,
        billingPermitCode?: string | null,
      } | null > | null,
      insuranceRegionalCompanies?:  Array< {
        __typename: "InsuranceRegionalCompany",
        name?: string | null,
        companyName?: string | null,
        businessId?: string | null,
        address?: string | null,
        postalCode?: string | null,
        postalArea?: string | null,
        email?: string | null,
        innovoiceId?: string | null,
      } | null > | null,
      insuranceCompensatableTimes?: Array< number | null > | null,
      services?:  Array< {
        __typename: "CustomService",
        description?: string | null,
        price?: number | null,
        category?: string | null,
        deductibleValue?: number | null,
      } | null > | null,
      useHansel?: boolean | null,
      hanselId?: string | null,
      availableOrgBusinessIds?: Array< string | null > | null,
      availableCompanyIds?: Array< string | null > | null,
      availableVehicleClasses?: Array< string | null > | null,
      eBillingRef?: string | null,
      eBillingOperator?: string | null,
      eBillingAddress?: string | null,
      createdAt: string,
      updatedAt: string,
      companies?:  {
        __typename: "ModelCompanyConnection",
        nextToken?: string | null,
      } | null,
      pricings?:  {
        __typename: "ModelPricingConnection",
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ExternalBusinessByOrganizationQueryVariables = {
  organizationId?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelExternalBusinessFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ExternalBusinessByOrganizationQuery = {
  externalBusinessByOrganization?:  {
    __typename: "ModelExternalBusinessConnection",
    items:  Array< {
      __typename: "ExternalBusiness",
      id: string,
      organizationId?: string | null,
      type?: ExternalBusinessType | null,
      name?: string | null,
      insuranceProducts?:  Array< {
        __typename: "InsuranceProducts",
        name?: string | null,
        coveragePercentage?: number | null,
        compensatableDays?: number | null,
        billingPermitCode?: string | null,
      } | null > | null,
      insuranceRegionalCompanies?:  Array< {
        __typename: "InsuranceRegionalCompany",
        name?: string | null,
        companyName?: string | null,
        businessId?: string | null,
        address?: string | null,
        postalCode?: string | null,
        postalArea?: string | null,
        email?: string | null,
        innovoiceId?: string | null,
      } | null > | null,
      insuranceCompensatableTimes?: Array< number | null > | null,
      services?:  Array< {
        __typename: "CustomService",
        description?: string | null,
        price?: number | null,
        category?: string | null,
        deductibleValue?: number | null,
      } | null > | null,
      useHansel?: boolean | null,
      hanselId?: string | null,
      availableOrgBusinessIds?: Array< string | null > | null,
      availableCompanyIds?: Array< string | null > | null,
      availableVehicleClasses?: Array< string | null > | null,
      eBillingRef?: string | null,
      eBillingOperator?: string | null,
      eBillingAddress?: string | null,
      createdAt: string,
      updatedAt: string,
      companies?:  {
        __typename: "ModelCompanyConnection",
        nextToken?: string | null,
      } | null,
      pricings?:  {
        __typename: "ModelPricingConnection",
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetBusinessQueryVariables = {
  id: string,
};

export type GetBusinessQuery = {
  getBusiness?:  {
    __typename: "Business",
    id: string,
    organizationId?: string | null,
    telegramChatId?: string | null,
    telegramConnections?:  Array< {
      __typename: "TelegramInformation",
      chatId?: string | null,
      name?: string | null,
    } | null > | null,
    group?: string | null,
    name?: string | null,
    address?: string | null,
    city?: string | null,
    email?: string | null,
    phone?: string | null,
    insuranceCoverageZone?:  {
      __typename: "PostalCodeRange",
      start?: string | null,
      end?: string | null,
    } | null,
    insuranceCoverageZones?:  Array< {
      __typename: "PostalCodeRange",
      start?: string | null,
      end?: string | null,
    } | null > | null,
    importIdentifier?: string | null,
    categoryDynamicPricings?:  Array< {
      __typename: "CategoryDynamicPricing",
      categoryId?: string | null,
      pricingId?: string | null,
      percentage?: number | null,
    } | null > | null,
    startLocations?:  Array< {
      __typename: "PickupLocation",
      id?: string | null,
      name?: string | null,
      locationColor?: string | null,
      companyId?: string | null,
    } | null > | null,
    pickupLocations?:  Array< {
      __typename: "PickupLocation",
      id?: string | null,
      name?: string | null,
      locationColor?: string | null,
      companyId?: string | null,
    } | null > | null,
    createdAt: string,
    updatedAt: string,
    companies?:  {
      __typename: "ModelCompanyConnection",
      items:  Array< {
        __typename: "Company",
        id: string,
        organizationId?: string | null,
        orgBusinessId?: string | null,
        group?: string | null,
        name: string,
        displayName?: string | null,
        email?: string | null,
        address?: string | null,
        postalCode?: string | null,
        city?: string | null,
        orderNumber?: number | null,
        orderNumberDealer?: number | null,
        phone?: string | null,
        businessId?: string | null,
        termsUrl?: string | null,
        timezone: string,
        signConfirmationEmail?: string | null,
        nameInEmail?: string | null,
        senderNameSMS?: string | null,
        confirmationMsg?: string | null,
        feedbackMsg?: string | null,
        statusLinkMsg?: string | null,
        livionDeviceId?: string | null,
        livionDeviceType?: LivionDevice | null,
        enableReservationDeposit?: boolean | null,
        reservationLinkExpirationHours?: number | null,
        disableOffHourReturn?: boolean | null,
        disableMultiday?: boolean | null,
        disableSameDay?: boolean | null,
        disableStartDaysFromNow?: number | null,
        enableCart?: boolean | null,
        enableMonthlyView?: boolean | null,
        enableRegistrationPlate?: boolean | null,
        enableVehicleStartReturnTimes?: boolean | null,
        enableMustSign?: boolean | null,
        enablePaymentOnlineOnly?: boolean | null,
        enableGTM?: boolean | null,
        enableNetPromoterScore?: boolean | null,
        enableNPS?: boolean | null,
        limitReservationOncePerDay?: boolean | null,
        hidden?: boolean | null,
        locationType?: string | null,
        offHourAvailability?: boolean | null,
        offHourAvailabilityFee?: number | null,
        deliveryFee?: number | null,
        deliveryBufferHours?: number | null,
        notAllowedCategoryIds?: Array< string | null > | null,
        importIdentifier?: string | null,
        contractSettingsJSON?: string | null,
        disableReservationCategoryIds?: Array< string | null > | null,
        locationColor?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    categories?:  {
      __typename: "ModelCategoryConnection",
      items:  Array< {
        __typename: "Category",
        id: string,
        group?: string | null,
        organizationId?: string | null,
        name: string,
        image?: string | null,
        orderNumber?: number | null,
        backgroundColor?: string | null,
        minuteBuffer?: number | null,
        reservationBlockFromNow?: number | null,
        typeId?: string | null,
        _removed?: string | null,
        oldTujausValue?: string | null,
        exampleModelName?: string | null,
        exampleModelImage?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type ListBusinesssQueryVariables = {
  filter?: ModelBusinessFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListBusinesssQuery = {
  listBusinesss?:  {
    __typename: "ModelBusinessConnection",
    items:  Array< {
      __typename: "Business",
      id: string,
      organizationId?: string | null,
      telegramChatId?: string | null,
      telegramConnections?:  Array< {
        __typename: "TelegramInformation",
        chatId?: string | null,
        name?: string | null,
      } | null > | null,
      group?: string | null,
      name?: string | null,
      address?: string | null,
      city?: string | null,
      email?: string | null,
      phone?: string | null,
      insuranceCoverageZone?:  {
        __typename: "PostalCodeRange",
        start?: string | null,
        end?: string | null,
      } | null,
      insuranceCoverageZones?:  Array< {
        __typename: "PostalCodeRange",
        start?: string | null,
        end?: string | null,
      } | null > | null,
      importIdentifier?: string | null,
      categoryDynamicPricings?:  Array< {
        __typename: "CategoryDynamicPricing",
        categoryId?: string | null,
        pricingId?: string | null,
        percentage?: number | null,
      } | null > | null,
      startLocations?:  Array< {
        __typename: "PickupLocation",
        id?: string | null,
        name?: string | null,
        locationColor?: string | null,
        companyId?: string | null,
      } | null > | null,
      pickupLocations?:  Array< {
        __typename: "PickupLocation",
        id?: string | null,
        name?: string | null,
        locationColor?: string | null,
        companyId?: string | null,
      } | null > | null,
      createdAt: string,
      updatedAt: string,
      companies?:  {
        __typename: "ModelCompanyConnection",
        nextToken?: string | null,
      } | null,
      categories?:  {
        __typename: "ModelCategoryConnection",
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type BusinessByOrganizationQueryVariables = {
  organizationId?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelBusinessFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type BusinessByOrganizationQuery = {
  businessByOrganization?:  {
    __typename: "ModelBusinessConnection",
    items:  Array< {
      __typename: "Business",
      id: string,
      organizationId?: string | null,
      telegramChatId?: string | null,
      telegramConnections?:  Array< {
        __typename: "TelegramInformation",
        chatId?: string | null,
        name?: string | null,
      } | null > | null,
      group?: string | null,
      name?: string | null,
      address?: string | null,
      city?: string | null,
      email?: string | null,
      phone?: string | null,
      insuranceCoverageZone?:  {
        __typename: "PostalCodeRange",
        start?: string | null,
        end?: string | null,
      } | null,
      insuranceCoverageZones?:  Array< {
        __typename: "PostalCodeRange",
        start?: string | null,
        end?: string | null,
      } | null > | null,
      importIdentifier?: string | null,
      categoryDynamicPricings?:  Array< {
        __typename: "CategoryDynamicPricing",
        categoryId?: string | null,
        pricingId?: string | null,
        percentage?: number | null,
      } | null > | null,
      startLocations?:  Array< {
        __typename: "PickupLocation",
        id?: string | null,
        name?: string | null,
        locationColor?: string | null,
        companyId?: string | null,
      } | null > | null,
      pickupLocations?:  Array< {
        __typename: "PickupLocation",
        id?: string | null,
        name?: string | null,
        locationColor?: string | null,
        companyId?: string | null,
      } | null > | null,
      createdAt: string,
      updatedAt: string,
      companies?:  {
        __typename: "ModelCompanyConnection",
        nextToken?: string | null,
      } | null,
      categories?:  {
        __typename: "ModelCategoryConnection",
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetArchiveReservationQueryVariables = {
  id: string,
};

export type GetArchiveReservationQuery = {
  getArchiveReservation?:  {
    __typename: "ArchiveReservation",
    id: string,
    organizationId: string,
    orgBusinessId: string,
    reservationJSON: string,
    archiveMessage?: string | null,
    archivedAt: string,
    archivedByUserId: string,
    startTime?: string | null,
    returnTime?: string | null,
    reservationCreatedAt?: string | null,
    customerBrokerId?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListArchiveReservationsQueryVariables = {
  filter?: ModelArchiveReservationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListArchiveReservationsQuery = {
  listArchiveReservations?:  {
    __typename: "ModelArchiveReservationConnection",
    items:  Array< {
      __typename: "ArchiveReservation",
      id: string,
      organizationId: string,
      orgBusinessId: string,
      reservationJSON: string,
      archiveMessage?: string | null,
      archivedAt: string,
      archivedByUserId: string,
      startTime?: string | null,
      returnTime?: string | null,
      reservationCreatedAt?: string | null,
      customerBrokerId?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type SearchArchiveReservationsQueryVariables = {
  filter?: SearchableArchiveReservationFilterInput | null,
  sort?: SearchableArchiveReservationSortInput | null,
  limit?: number | null,
  nextToken?: string | null,
  from?: number | null,
};

export type SearchArchiveReservationsQuery = {
  searchArchiveReservations?:  {
    __typename: "SearchableArchiveReservationConnection",
    items:  Array< {
      __typename: "ArchiveReservation",
      id: string,
      organizationId: string,
      orgBusinessId: string,
      reservationJSON: string,
      archiveMessage?: string | null,
      archivedAt: string,
      archivedByUserId: string,
      startTime?: string | null,
      returnTime?: string | null,
      reservationCreatedAt?: string | null,
      customerBrokerId?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
    total?: number | null,
  } | null,
};

export type GetReservationQueryVariables = {
  id: string,
};

export type GetReservationQuery = {
  getReservation?:  {
    __typename: "Reservation",
    id: string,
    shortId?: string | null,
    language?: Language | null,
    group?: string | null,
    organizationId?: string | null,
    orgBusinessId?: string | null,
    orgExternalBusinessId?: string | null,
    companyId?: string | null,
    returnCompanyId?: string | null,
    startLocation?: string | null,
    returnLocation?: string | null,
    startLocationID?: string | null,
    returnLocationID?: string | null,
    reviewId?: string | null,
    customerBrokerId?: string | null,
    customerCompanyId?: string | null,
    customerPersonalId?: string | null,
    reservationVehicleId?: string | null,
    reservationVehicles?:  Array< {
      __typename: "ReservationVehicle",
      id?: string | null,
      price?: number | null,
      tax?: number | null,
      name?: string | null,
      registrationPlate?: string | null,
      deductible?: number | null,
      includedDistance?: string | null,
      powerType?: string | null,
      mileage?: string | null,
      startMileage?: string | null,
      endMileage?: string | null,
      extraInfo?: string | null,
      damages?: string | null,
      hideExport?: boolean | null,
      createdAt?: string | null,
      addedAt?: string | null,
      categoryName?: string | null,
    } | null > | null,
    wildCardVehicles?:  Array< {
      __typename: "WildCardVehicle",
      name?: string | null,
      price?: number | null,
      tax?: number | null,
      categoryId?: string | null,
      quantity?: number | null,
      deductible?: number | null,
    } | null > | null,
    createdAt: string,
    updatedAt: string,
    startTime?: string | null,
    returnTime?: string | null,
    endTime?: string | null,
    name?: string | null,
    address?: string | null,
    postalCode?: string | null,
    city?: string | null,
    phone?: string | null,
    email?: string | null,
    billingRef?: string | null,
    customerNotes?: string | null,
    ssn?: string | null,
    rentalNotes?: string | null,
    internalNotes?: string | null,
    externalNotes?: string | null,
    outOfCommission?: boolean | null,
    vehiclePrice?: number | null,
    additionalServices?:  Array< {
      __typename: "AdditionalServiceReservation",
      key: string,
      price: number,
      details?: string | null,
      quantity?: number | null,
      type?: ServiceType | null,
      deductibleValue?: number | null,
      groupFields?: Array< string | null > | null,
      tax?: number | null,
      enableNotification?: boolean | null,
      description?: string | null,
      category?: string | null,
    } > | null,
    driverSSN?: string | null,
    differentDriver?: boolean | null,
    driverName?: string | null,
    driverAddress?: string | null,
    driverPostalCode?: string | null,
    driverCity?: string | null,
    driverPhone?: string | null,
    driverEmail?: string | null,
    additionalDriver?: boolean | null,
    additionalDriverName?: string | null,
    additionalDriverPhone?: string | null,
    additionalDriverSSN?: string | null,
    billPayment?: boolean | null,
    isCompany?: boolean | null,
    carStatus?: RentalStatus | null,
    rentalStatus?: RentalStatus | null,
    innovoiceStatus?: ReservationInnovoiceStatus | null,
    companyName?: string | null,
    companyBusinessId?: string | null,
    companyCity?: string | null,
    companyPostalCode?: string | null,
    companyBillingRef?: string | null,
    companyBillingAddress?: string | null,
    companyEBillingOperator?: string | null,
    companyEBillingAddress?: string | null,
    paymentToken?: string | null,
    npsStatus?: NpsStatus | null,
    paymentStatus?: PaymentStatus | null,
    paymentData?:  {
      __typename: "PaymentData",
      paymentTotal?: number | null,
      paymentTodo?: number | null,
      depositPending?: boolean | null,
      deposit?: number | null,
    } | null,
    signStatus?: SignStatus | null,
    signDocumentId?: string | null,
    signerName?: string | null,
    reviewStatus?: ReviewStatus | null,
    authStatus?: AuthStatus | null,
    authTime?: string | null,
    completeBefore?: string | null,
    mustSign?: boolean | null,
    livionData?:  Array< {
      __typename: "LivionData",
      id?: string | null,
      pincode?: string | null,
      keyId?: string | null,
      name?: string | null,
      registrationPlate?: string | null,
    } | null > | null,
    tilakoneData?:  {
      __typename: "TilakoneData",
      access_id?: string | null,
      begin_timestamp?: string | null,
      end_timestamp?: string | null,
      pincode?: string | null,
    } | null,
    voucherData?:  {
      __typename: "VoucherData",
      name: string,
      code: string,
      amount: number,
    } | null,
    customerCarRegistrationPlate?: string | null,
    insuranceData?:  {
      __typename: "InsuranceData",
      customerContact?: boolean | null,
      urgent?: boolean | null,
      customerCarModel?: string | null,
      customerCarMaker?: string | null,
      product?: string | null,
      coveragePercentage?: number | null,
      compensatableDays?: string | null,
      billingPermitCode?: string | null,
      coverageDuration?: number | null,
      providerCompany?: string | null,
      providerBusinessId?: string | null,
      claimAdjusterName?: string | null,
      claimAdjusterEmail?: string | null,
      receipt?: boolean | null,
      replacementVehicleClass?: string | null,
      claimId?: string | null,
      vatDeductible?: boolean | null,
      deliveryCosts?: boolean | null,
      returnCosts?: boolean | null,
    } | null,
    externalData?:  {
      __typename: "ExternalData",
      orderId?: string | null,
      orderReference?: string | null,
      serviceAgreementName?: string | null,
      operatorName?: string | null,
      orderInfo?: string | null,
      billingPayer?: string | null,
      billingDescription?: string | null,
      customerVehicle?:  {
        __typename: "CustomerVehicle",
        brand?: string | null,
        model?: string | null,
        registrationPlate?: string | null,
      } | null,
      incidentLocation?:  {
        __typename: "Location",
        description?: string | null,
        address?: string | null,
        city?: string | null,
        zipCode?: string | null,
      } | null,
      repairShop?:  {
        __typename: "Location",
        description?: string | null,
        address?: string | null,
        city?: string | null,
        zipCode?: string | null,
      } | null,
    } | null,
    enableRegistrationPlate?: boolean | null,
    disablePricesInPrintouts?: boolean | null,
    hanselData?:  {
      __typename: "HanselData",
      contractNro?: string | null,
      customerInvoiceId?: string | null,
      customerInvoiceDate?: string | null,
      hanselAgent?: string | null,
      hanselClass?: string | null,
      customerId?: string | null,
      subContractorNumber?: string | null,
      subContractorName?: string | null,
      suplierProductId?: string | null,
      suplierProductName?: string | null,
      quantity?: string | null,
      price?: string | null,
      totalPrice?: string | null,
      priceNoVAT?: string | null,
      additionalInfo1?: string | null,
      additionalInfo2?: string | null,
      additionalInfo3?: string | null,
      desc?: string | null,
    } | null,
    invoiceIds?: Array< string > | null,
    consolidatedInvoiceIds?: Array< string > | null,
    channel?: ReservationChannel | null,
    originURL?: string | null,
    type?: ReservationType | null,
    status?: string | null,
    reservationStatusInfo?:  {
      __typename: "ReservationStatusInfo",
      deliveryTimestamp?: string | null,
      deliveryAddress?: string | null,
      deliveryZipcode?: string | null,
      deliveryCity?: string | null,
      deliveryCountry?: string | null,
      deliveryDescription?: string | null,
      returnTimestamp?: string | null,
      returnAddress?: string | null,
      returnZipcode?: string | null,
      returnCity?: string | null,
      returnCountry?: string | null,
      returnDescription?: string | null,
      cancelReason?: string | null,
    } | null,
    initialCategory?:  {
      __typename: "CategoryInfo",
      id?: string | null,
      name?: string | null,
      hanselClass?: string | null,
    } | null,
    initialClassInfo?:  {
      __typename: "InitialClassInfo",
      categoryId?: string | null,
      categoryName?: string | null,
      hanselClass?: string | null,
    } | null,
    orgInvoiceCustomer?:  {
      __typename: "OrganizationInvoiceCustomer",
      name?: string | null,
      businessId?: string | null,
    } | null,
    pricingCategoryId?: string | null,
    customImportDate?: string | null,
    vehicle?:  {
      __typename: "Vehicle",
      id: string,
      group?: string | null,
      organizationId?: string | null,
      orgBusinessId?: string | null,
      companyId: string,
      name: string,
      nameT?:  {
        __typename: "Translation",
        fi?: string | null,
        en?: string | null,
        sv?: string | null,
      } | null,
      registrationPlate?: string | null,
      damages?: string | null,
      damageRecords?:  Array< {
        __typename: "DamageRecord",
        normalizedX?: number | null,
        normalizedY?: number | null,
        label?: string | null,
        description?: string | null,
        createdAt?: string | null,
      } > | null,
      additionalFields:  Array< {
        __typename: "AdditionalField",
        key: string,
        value?: string | null,
      } >,
      image?: string | null,
      powerType?: string | null,
      deductible?: number | null,
      includedDistance?: string | null,
      mileage?: string | null,
      orderNumber?: number | null,
      vehicleCategoryId?: string | null,
      useCategoryDefaults?: boolean | null,
      useStartReturnTimes?: boolean | null,
      customStartReturns?:  Array< {
        __typename: "CustomStartReturn",
        priority?: number | null,
      } | null > | null,
      startReturnInterval?:  {
        __typename: "StartReturnInterval",
        start?: string | null,
        end?: string | null,
      } | null,
      startReturnTimes?:  Array< {
        __typename: "StartReturnTimes",
        start?: Array< string | null > | null,
        return?: Array< string | null > | null,
      } | null > | null,
      hideExport?: boolean | null,
      extraInfo?: string | null,
      extraInfoT?: string | null,
      extraToConfirmation?: string | null,
      extraToConfirmationT?: string | null,
      livionKeyId?: string | null,
      phoneNumber?: string | null,
      importData?: string | null,
      disableOnline?: boolean | null,
      activeRange?:  {
        __typename: "StartReturnInterval",
        start?: string | null,
        end?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      category?:  {
        __typename: "Category",
        id: string,
        group?: string | null,
        organizationId?: string | null,
        name: string,
        image?: string | null,
        orderNumber?: number | null,
        backgroundColor?: string | null,
        minuteBuffer?: number | null,
        reservationBlockFromNow?: number | null,
        typeId?: string | null,
        _removed?: string | null,
        oldTujausValue?: string | null,
        exampleModelName?: string | null,
        exampleModelImage?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      additionalServices?:  {
        __typename: "ModelAdditionalServicePricingConnection",
        nextToken?: string | null,
      } | null,
      reservations?:  {
        __typename: "ModelReservationConnection",
        nextToken?: string | null,
      } | null,
      pricing?:  {
        __typename: "Pricing",
        id: string,
        name: string,
        group?: string | null,
        organizationId?: string | null,
        orgBusinessId?: string | null,
        orgExternalBusinessId?: string | null,
        externalBusinessCategoryAssociation?: string | null,
        businessCategoryAssociation?: string | null,
        useNextDayPrice?: boolean | null,
        useLastDayPrice?: boolean | null,
        tax?: number | null,
        _removed?: string | null,
        deductible?: number | null,
        reducedDeductible?: number | null,
        reducedDeductiblePrice?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null,
    } | null,
    invoices?:  {
      __typename: "ModelInvoiceConnection",
      items:  Array< {
        __typename: "Invoice",
        id: string,
        createdAt: string,
        innovoiceId?: string | null,
        reservationId?: string | null,
        reservationIds?: Array< string > | null,
        companyId?: string | null,
        innovoiceCompanyId?: string | null,
        innovoiceuRentCarRentalId?: string | null,
        innovoiceOrderId?: string | null,
        group?: string | null,
        organizationId?: string | null,
        orgBusinessId?: string | null,
        type?: InvoicePaymentType | null,
        creditNoteInvoiceId?: string | null,
        payment_terms?: number | null,
        vat_included?: number | null,
        delivery_by: InvoiceDeliveryBy,
        invoice_type: InvoiceType,
        delivery_date?: string | null,
        seller_reference?: string | null,
        buyer_reference?: string | null,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    paymentEvents?:  {
      __typename: "ModelPaymentEventConnection",
      items:  Array< {
        __typename: "PaymentEvent",
        id: string,
        channel?: string | null,
        paymentMethod?: string | null,
        paymentTotal?: number | null,
        isDeleted?: boolean | null,
        deletedBy?: string | null,
        deletedAt?: string | null,
        createdByUserID?: string | null,
        createdByUserName?: string | null,
        reservationId?: string | null,
        organizationId?: string | null,
        orgBusinessId?: string | null,
        group?: string | null,
        msg?: string | null,
        state?: PaymentEventState | null,
        returnCode?: number | null,
        incidentId?: string | null,
        parameters?: string | null,
        apiPath?: string | null,
        paymentStatus?: string | null,
        orderNumber?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type ListReservationsQueryVariables = {
  filter?: ModelReservationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListReservationsQuery = {
  listReservations?:  {
    __typename: "ModelReservationConnection",
    items:  Array< {
      __typename: "Reservation",
      id: string,
      shortId?: string | null,
      language?: Language | null,
      group?: string | null,
      organizationId?: string | null,
      orgBusinessId?: string | null,
      orgExternalBusinessId?: string | null,
      companyId?: string | null,
      returnCompanyId?: string | null,
      startLocation?: string | null,
      returnLocation?: string | null,
      startLocationID?: string | null,
      returnLocationID?: string | null,
      reviewId?: string | null,
      customerBrokerId?: string | null,
      customerCompanyId?: string | null,
      customerPersonalId?: string | null,
      reservationVehicleId?: string | null,
      reservationVehicles?:  Array< {
        __typename: "ReservationVehicle",
        id?: string | null,
        price?: number | null,
        tax?: number | null,
        name?: string | null,
        registrationPlate?: string | null,
        deductible?: number | null,
        includedDistance?: string | null,
        powerType?: string | null,
        mileage?: string | null,
        startMileage?: string | null,
        endMileage?: string | null,
        extraInfo?: string | null,
        damages?: string | null,
        hideExport?: boolean | null,
        createdAt?: string | null,
        addedAt?: string | null,
        categoryName?: string | null,
      } | null > | null,
      wildCardVehicles?:  Array< {
        __typename: "WildCardVehicle",
        name?: string | null,
        price?: number | null,
        tax?: number | null,
        categoryId?: string | null,
        quantity?: number | null,
        deductible?: number | null,
      } | null > | null,
      createdAt: string,
      updatedAt: string,
      startTime?: string | null,
      returnTime?: string | null,
      endTime?: string | null,
      name?: string | null,
      address?: string | null,
      postalCode?: string | null,
      city?: string | null,
      phone?: string | null,
      email?: string | null,
      billingRef?: string | null,
      customerNotes?: string | null,
      ssn?: string | null,
      rentalNotes?: string | null,
      internalNotes?: string | null,
      externalNotes?: string | null,
      outOfCommission?: boolean | null,
      vehiclePrice?: number | null,
      additionalServices?:  Array< {
        __typename: "AdditionalServiceReservation",
        key: string,
        price: number,
        details?: string | null,
        quantity?: number | null,
        type?: ServiceType | null,
        deductibleValue?: number | null,
        groupFields?: Array< string | null > | null,
        tax?: number | null,
        enableNotification?: boolean | null,
        description?: string | null,
        category?: string | null,
      } > | null,
      driverSSN?: string | null,
      differentDriver?: boolean | null,
      driverName?: string | null,
      driverAddress?: string | null,
      driverPostalCode?: string | null,
      driverCity?: string | null,
      driverPhone?: string | null,
      driverEmail?: string | null,
      additionalDriver?: boolean | null,
      additionalDriverName?: string | null,
      additionalDriverPhone?: string | null,
      additionalDriverSSN?: string | null,
      billPayment?: boolean | null,
      isCompany?: boolean | null,
      carStatus?: RentalStatus | null,
      rentalStatus?: RentalStatus | null,
      innovoiceStatus?: ReservationInnovoiceStatus | null,
      companyName?: string | null,
      companyBusinessId?: string | null,
      companyCity?: string | null,
      companyPostalCode?: string | null,
      companyBillingRef?: string | null,
      companyBillingAddress?: string | null,
      companyEBillingOperator?: string | null,
      companyEBillingAddress?: string | null,
      paymentToken?: string | null,
      npsStatus?: NpsStatus | null,
      paymentStatus?: PaymentStatus | null,
      paymentData?:  {
        __typename: "PaymentData",
        paymentTotal?: number | null,
        paymentTodo?: number | null,
        depositPending?: boolean | null,
        deposit?: number | null,
      } | null,
      signStatus?: SignStatus | null,
      signDocumentId?: string | null,
      signerName?: string | null,
      reviewStatus?: ReviewStatus | null,
      authStatus?: AuthStatus | null,
      authTime?: string | null,
      completeBefore?: string | null,
      mustSign?: boolean | null,
      livionData?:  Array< {
        __typename: "LivionData",
        id?: string | null,
        pincode?: string | null,
        keyId?: string | null,
        name?: string | null,
        registrationPlate?: string | null,
      } | null > | null,
      tilakoneData?:  {
        __typename: "TilakoneData",
        access_id?: string | null,
        begin_timestamp?: string | null,
        end_timestamp?: string | null,
        pincode?: string | null,
      } | null,
      voucherData?:  {
        __typename: "VoucherData",
        name: string,
        code: string,
        amount: number,
      } | null,
      customerCarRegistrationPlate?: string | null,
      insuranceData?:  {
        __typename: "InsuranceData",
        customerContact?: boolean | null,
        urgent?: boolean | null,
        customerCarModel?: string | null,
        customerCarMaker?: string | null,
        product?: string | null,
        coveragePercentage?: number | null,
        compensatableDays?: string | null,
        billingPermitCode?: string | null,
        coverageDuration?: number | null,
        providerCompany?: string | null,
        providerBusinessId?: string | null,
        claimAdjusterName?: string | null,
        claimAdjusterEmail?: string | null,
        receipt?: boolean | null,
        replacementVehicleClass?: string | null,
        claimId?: string | null,
        vatDeductible?: boolean | null,
        deliveryCosts?: boolean | null,
        returnCosts?: boolean | null,
      } | null,
      externalData?:  {
        __typename: "ExternalData",
        orderId?: string | null,
        orderReference?: string | null,
        serviceAgreementName?: string | null,
        operatorName?: string | null,
        orderInfo?: string | null,
        billingPayer?: string | null,
        billingDescription?: string | null,
      } | null,
      enableRegistrationPlate?: boolean | null,
      disablePricesInPrintouts?: boolean | null,
      hanselData?:  {
        __typename: "HanselData",
        contractNro?: string | null,
        customerInvoiceId?: string | null,
        customerInvoiceDate?: string | null,
        hanselAgent?: string | null,
        hanselClass?: string | null,
        customerId?: string | null,
        subContractorNumber?: string | null,
        subContractorName?: string | null,
        suplierProductId?: string | null,
        suplierProductName?: string | null,
        quantity?: string | null,
        price?: string | null,
        totalPrice?: string | null,
        priceNoVAT?: string | null,
        additionalInfo1?: string | null,
        additionalInfo2?: string | null,
        additionalInfo3?: string | null,
        desc?: string | null,
      } | null,
      invoiceIds?: Array< string > | null,
      consolidatedInvoiceIds?: Array< string > | null,
      channel?: ReservationChannel | null,
      originURL?: string | null,
      type?: ReservationType | null,
      status?: string | null,
      reservationStatusInfo?:  {
        __typename: "ReservationStatusInfo",
        deliveryTimestamp?: string | null,
        deliveryAddress?: string | null,
        deliveryZipcode?: string | null,
        deliveryCity?: string | null,
        deliveryCountry?: string | null,
        deliveryDescription?: string | null,
        returnTimestamp?: string | null,
        returnAddress?: string | null,
        returnZipcode?: string | null,
        returnCity?: string | null,
        returnCountry?: string | null,
        returnDescription?: string | null,
        cancelReason?: string | null,
      } | null,
      initialCategory?:  {
        __typename: "CategoryInfo",
        id?: string | null,
        name?: string | null,
        hanselClass?: string | null,
      } | null,
      initialClassInfo?:  {
        __typename: "InitialClassInfo",
        categoryId?: string | null,
        categoryName?: string | null,
        hanselClass?: string | null,
      } | null,
      orgInvoiceCustomer?:  {
        __typename: "OrganizationInvoiceCustomer",
        name?: string | null,
        businessId?: string | null,
      } | null,
      pricingCategoryId?: string | null,
      customImportDate?: string | null,
      vehicle?:  {
        __typename: "Vehicle",
        id: string,
        group?: string | null,
        organizationId?: string | null,
        orgBusinessId?: string | null,
        companyId: string,
        name: string,
        registrationPlate?: string | null,
        damages?: string | null,
        image?: string | null,
        powerType?: string | null,
        deductible?: number | null,
        includedDistance?: string | null,
        mileage?: string | null,
        orderNumber?: number | null,
        vehicleCategoryId?: string | null,
        useCategoryDefaults?: boolean | null,
        useStartReturnTimes?: boolean | null,
        hideExport?: boolean | null,
        extraInfo?: string | null,
        extraInfoT?: string | null,
        extraToConfirmation?: string | null,
        extraToConfirmationT?: string | null,
        livionKeyId?: string | null,
        phoneNumber?: string | null,
        importData?: string | null,
        disableOnline?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      invoices?:  {
        __typename: "ModelInvoiceConnection",
        nextToken?: string | null,
      } | null,
      paymentEvents?:  {
        __typename: "ModelPaymentEventConnection",
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListReservationsByGroupQueryVariables = {
  group?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelReservationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListReservationsByGroupQuery = {
  listReservationsByGroup?:  {
    __typename: "ModelReservationConnection",
    items:  Array< {
      __typename: "Reservation",
      id: string,
      shortId?: string | null,
      language?: Language | null,
      group?: string | null,
      organizationId?: string | null,
      orgBusinessId?: string | null,
      orgExternalBusinessId?: string | null,
      companyId?: string | null,
      returnCompanyId?: string | null,
      startLocation?: string | null,
      returnLocation?: string | null,
      startLocationID?: string | null,
      returnLocationID?: string | null,
      reviewId?: string | null,
      customerBrokerId?: string | null,
      customerCompanyId?: string | null,
      customerPersonalId?: string | null,
      reservationVehicleId?: string | null,
      reservationVehicles?:  Array< {
        __typename: "ReservationVehicle",
        id?: string | null,
        price?: number | null,
        tax?: number | null,
        name?: string | null,
        registrationPlate?: string | null,
        deductible?: number | null,
        includedDistance?: string | null,
        powerType?: string | null,
        mileage?: string | null,
        startMileage?: string | null,
        endMileage?: string | null,
        extraInfo?: string | null,
        damages?: string | null,
        hideExport?: boolean | null,
        createdAt?: string | null,
        addedAt?: string | null,
        categoryName?: string | null,
      } | null > | null,
      wildCardVehicles?:  Array< {
        __typename: "WildCardVehicle",
        name?: string | null,
        price?: number | null,
        tax?: number | null,
        categoryId?: string | null,
        quantity?: number | null,
        deductible?: number | null,
      } | null > | null,
      createdAt: string,
      updatedAt: string,
      startTime?: string | null,
      returnTime?: string | null,
      endTime?: string | null,
      name?: string | null,
      address?: string | null,
      postalCode?: string | null,
      city?: string | null,
      phone?: string | null,
      email?: string | null,
      billingRef?: string | null,
      customerNotes?: string | null,
      ssn?: string | null,
      rentalNotes?: string | null,
      internalNotes?: string | null,
      externalNotes?: string | null,
      outOfCommission?: boolean | null,
      vehiclePrice?: number | null,
      additionalServices?:  Array< {
        __typename: "AdditionalServiceReservation",
        key: string,
        price: number,
        details?: string | null,
        quantity?: number | null,
        type?: ServiceType | null,
        deductibleValue?: number | null,
        groupFields?: Array< string | null > | null,
        tax?: number | null,
        enableNotification?: boolean | null,
        description?: string | null,
        category?: string | null,
      } > | null,
      driverSSN?: string | null,
      differentDriver?: boolean | null,
      driverName?: string | null,
      driverAddress?: string | null,
      driverPostalCode?: string | null,
      driverCity?: string | null,
      driverPhone?: string | null,
      driverEmail?: string | null,
      additionalDriver?: boolean | null,
      additionalDriverName?: string | null,
      additionalDriverPhone?: string | null,
      additionalDriverSSN?: string | null,
      billPayment?: boolean | null,
      isCompany?: boolean | null,
      carStatus?: RentalStatus | null,
      rentalStatus?: RentalStatus | null,
      innovoiceStatus?: ReservationInnovoiceStatus | null,
      companyName?: string | null,
      companyBusinessId?: string | null,
      companyCity?: string | null,
      companyPostalCode?: string | null,
      companyBillingRef?: string | null,
      companyBillingAddress?: string | null,
      companyEBillingOperator?: string | null,
      companyEBillingAddress?: string | null,
      paymentToken?: string | null,
      npsStatus?: NpsStatus | null,
      paymentStatus?: PaymentStatus | null,
      paymentData?:  {
        __typename: "PaymentData",
        paymentTotal?: number | null,
        paymentTodo?: number | null,
        depositPending?: boolean | null,
        deposit?: number | null,
      } | null,
      signStatus?: SignStatus | null,
      signDocumentId?: string | null,
      signerName?: string | null,
      reviewStatus?: ReviewStatus | null,
      authStatus?: AuthStatus | null,
      authTime?: string | null,
      completeBefore?: string | null,
      mustSign?: boolean | null,
      livionData?:  Array< {
        __typename: "LivionData",
        id?: string | null,
        pincode?: string | null,
        keyId?: string | null,
        name?: string | null,
        registrationPlate?: string | null,
      } | null > | null,
      tilakoneData?:  {
        __typename: "TilakoneData",
        access_id?: string | null,
        begin_timestamp?: string | null,
        end_timestamp?: string | null,
        pincode?: string | null,
      } | null,
      voucherData?:  {
        __typename: "VoucherData",
        name: string,
        code: string,
        amount: number,
      } | null,
      customerCarRegistrationPlate?: string | null,
      insuranceData?:  {
        __typename: "InsuranceData",
        customerContact?: boolean | null,
        urgent?: boolean | null,
        customerCarModel?: string | null,
        customerCarMaker?: string | null,
        product?: string | null,
        coveragePercentage?: number | null,
        compensatableDays?: string | null,
        billingPermitCode?: string | null,
        coverageDuration?: number | null,
        providerCompany?: string | null,
        providerBusinessId?: string | null,
        claimAdjusterName?: string | null,
        claimAdjusterEmail?: string | null,
        receipt?: boolean | null,
        replacementVehicleClass?: string | null,
        claimId?: string | null,
        vatDeductible?: boolean | null,
        deliveryCosts?: boolean | null,
        returnCosts?: boolean | null,
      } | null,
      externalData?:  {
        __typename: "ExternalData",
        orderId?: string | null,
        orderReference?: string | null,
        serviceAgreementName?: string | null,
        operatorName?: string | null,
        orderInfo?: string | null,
        billingPayer?: string | null,
        billingDescription?: string | null,
      } | null,
      enableRegistrationPlate?: boolean | null,
      disablePricesInPrintouts?: boolean | null,
      hanselData?:  {
        __typename: "HanselData",
        contractNro?: string | null,
        customerInvoiceId?: string | null,
        customerInvoiceDate?: string | null,
        hanselAgent?: string | null,
        hanselClass?: string | null,
        customerId?: string | null,
        subContractorNumber?: string | null,
        subContractorName?: string | null,
        suplierProductId?: string | null,
        suplierProductName?: string | null,
        quantity?: string | null,
        price?: string | null,
        totalPrice?: string | null,
        priceNoVAT?: string | null,
        additionalInfo1?: string | null,
        additionalInfo2?: string | null,
        additionalInfo3?: string | null,
        desc?: string | null,
      } | null,
      invoiceIds?: Array< string > | null,
      consolidatedInvoiceIds?: Array< string > | null,
      channel?: ReservationChannel | null,
      originURL?: string | null,
      type?: ReservationType | null,
      status?: string | null,
      reservationStatusInfo?:  {
        __typename: "ReservationStatusInfo",
        deliveryTimestamp?: string | null,
        deliveryAddress?: string | null,
        deliveryZipcode?: string | null,
        deliveryCity?: string | null,
        deliveryCountry?: string | null,
        deliveryDescription?: string | null,
        returnTimestamp?: string | null,
        returnAddress?: string | null,
        returnZipcode?: string | null,
        returnCity?: string | null,
        returnCountry?: string | null,
        returnDescription?: string | null,
        cancelReason?: string | null,
      } | null,
      initialCategory?:  {
        __typename: "CategoryInfo",
        id?: string | null,
        name?: string | null,
        hanselClass?: string | null,
      } | null,
      initialClassInfo?:  {
        __typename: "InitialClassInfo",
        categoryId?: string | null,
        categoryName?: string | null,
        hanselClass?: string | null,
      } | null,
      orgInvoiceCustomer?:  {
        __typename: "OrganizationInvoiceCustomer",
        name?: string | null,
        businessId?: string | null,
      } | null,
      pricingCategoryId?: string | null,
      customImportDate?: string | null,
      vehicle?:  {
        __typename: "Vehicle",
        id: string,
        group?: string | null,
        organizationId?: string | null,
        orgBusinessId?: string | null,
        companyId: string,
        name: string,
        registrationPlate?: string | null,
        damages?: string | null,
        image?: string | null,
        powerType?: string | null,
        deductible?: number | null,
        includedDistance?: string | null,
        mileage?: string | null,
        orderNumber?: number | null,
        vehicleCategoryId?: string | null,
        useCategoryDefaults?: boolean | null,
        useStartReturnTimes?: boolean | null,
        hideExport?: boolean | null,
        extraInfo?: string | null,
        extraInfoT?: string | null,
        extraToConfirmation?: string | null,
        extraToConfirmationT?: string | null,
        livionKeyId?: string | null,
        phoneNumber?: string | null,
        importData?: string | null,
        disableOnline?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      invoices?:  {
        __typename: "ModelInvoiceConnection",
        nextToken?: string | null,
      } | null,
      paymentEvents?:  {
        __typename: "ModelPaymentEventConnection",
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListReservationsByOrgBusinessIdByEndTimeQueryVariables = {
  orgBusinessId?: string | null,
  endTime?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelReservationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListReservationsByOrgBusinessIdByEndTimeQuery = {
  listReservationsByOrgBusinessIdByEndTime?:  {
    __typename: "ModelReservationConnection",
    items:  Array< {
      __typename: "Reservation",
      id: string,
      shortId?: string | null,
      language?: Language | null,
      group?: string | null,
      organizationId?: string | null,
      orgBusinessId?: string | null,
      orgExternalBusinessId?: string | null,
      companyId?: string | null,
      returnCompanyId?: string | null,
      startLocation?: string | null,
      returnLocation?: string | null,
      startLocationID?: string | null,
      returnLocationID?: string | null,
      reviewId?: string | null,
      customerBrokerId?: string | null,
      customerCompanyId?: string | null,
      customerPersonalId?: string | null,
      reservationVehicleId?: string | null,
      reservationVehicles?:  Array< {
        __typename: "ReservationVehicle",
        id?: string | null,
        price?: number | null,
        tax?: number | null,
        name?: string | null,
        registrationPlate?: string | null,
        deductible?: number | null,
        includedDistance?: string | null,
        powerType?: string | null,
        mileage?: string | null,
        startMileage?: string | null,
        endMileage?: string | null,
        extraInfo?: string | null,
        damages?: string | null,
        hideExport?: boolean | null,
        createdAt?: string | null,
        addedAt?: string | null,
        categoryName?: string | null,
      } | null > | null,
      wildCardVehicles?:  Array< {
        __typename: "WildCardVehicle",
        name?: string | null,
        price?: number | null,
        tax?: number | null,
        categoryId?: string | null,
        quantity?: number | null,
        deductible?: number | null,
      } | null > | null,
      createdAt: string,
      updatedAt: string,
      startTime?: string | null,
      returnTime?: string | null,
      endTime?: string | null,
      name?: string | null,
      address?: string | null,
      postalCode?: string | null,
      city?: string | null,
      phone?: string | null,
      email?: string | null,
      billingRef?: string | null,
      customerNotes?: string | null,
      ssn?: string | null,
      rentalNotes?: string | null,
      internalNotes?: string | null,
      externalNotes?: string | null,
      outOfCommission?: boolean | null,
      vehiclePrice?: number | null,
      additionalServices?:  Array< {
        __typename: "AdditionalServiceReservation",
        key: string,
        price: number,
        details?: string | null,
        quantity?: number | null,
        type?: ServiceType | null,
        deductibleValue?: number | null,
        groupFields?: Array< string | null > | null,
        tax?: number | null,
        enableNotification?: boolean | null,
        description?: string | null,
        category?: string | null,
      } > | null,
      driverSSN?: string | null,
      differentDriver?: boolean | null,
      driverName?: string | null,
      driverAddress?: string | null,
      driverPostalCode?: string | null,
      driverCity?: string | null,
      driverPhone?: string | null,
      driverEmail?: string | null,
      additionalDriver?: boolean | null,
      additionalDriverName?: string | null,
      additionalDriverPhone?: string | null,
      additionalDriverSSN?: string | null,
      billPayment?: boolean | null,
      isCompany?: boolean | null,
      carStatus?: RentalStatus | null,
      rentalStatus?: RentalStatus | null,
      innovoiceStatus?: ReservationInnovoiceStatus | null,
      companyName?: string | null,
      companyBusinessId?: string | null,
      companyCity?: string | null,
      companyPostalCode?: string | null,
      companyBillingRef?: string | null,
      companyBillingAddress?: string | null,
      companyEBillingOperator?: string | null,
      companyEBillingAddress?: string | null,
      paymentToken?: string | null,
      npsStatus?: NpsStatus | null,
      paymentStatus?: PaymentStatus | null,
      paymentData?:  {
        __typename: "PaymentData",
        paymentTotal?: number | null,
        paymentTodo?: number | null,
        depositPending?: boolean | null,
        deposit?: number | null,
      } | null,
      signStatus?: SignStatus | null,
      signDocumentId?: string | null,
      signerName?: string | null,
      reviewStatus?: ReviewStatus | null,
      authStatus?: AuthStatus | null,
      authTime?: string | null,
      completeBefore?: string | null,
      mustSign?: boolean | null,
      livionData?:  Array< {
        __typename: "LivionData",
        id?: string | null,
        pincode?: string | null,
        keyId?: string | null,
        name?: string | null,
        registrationPlate?: string | null,
      } | null > | null,
      tilakoneData?:  {
        __typename: "TilakoneData",
        access_id?: string | null,
        begin_timestamp?: string | null,
        end_timestamp?: string | null,
        pincode?: string | null,
      } | null,
      voucherData?:  {
        __typename: "VoucherData",
        name: string,
        code: string,
        amount: number,
      } | null,
      customerCarRegistrationPlate?: string | null,
      insuranceData?:  {
        __typename: "InsuranceData",
        customerContact?: boolean | null,
        urgent?: boolean | null,
        customerCarModel?: string | null,
        customerCarMaker?: string | null,
        product?: string | null,
        coveragePercentage?: number | null,
        compensatableDays?: string | null,
        billingPermitCode?: string | null,
        coverageDuration?: number | null,
        providerCompany?: string | null,
        providerBusinessId?: string | null,
        claimAdjusterName?: string | null,
        claimAdjusterEmail?: string | null,
        receipt?: boolean | null,
        replacementVehicleClass?: string | null,
        claimId?: string | null,
        vatDeductible?: boolean | null,
        deliveryCosts?: boolean | null,
        returnCosts?: boolean | null,
      } | null,
      externalData?:  {
        __typename: "ExternalData",
        orderId?: string | null,
        orderReference?: string | null,
        serviceAgreementName?: string | null,
        operatorName?: string | null,
        orderInfo?: string | null,
        billingPayer?: string | null,
        billingDescription?: string | null,
      } | null,
      enableRegistrationPlate?: boolean | null,
      disablePricesInPrintouts?: boolean | null,
      hanselData?:  {
        __typename: "HanselData",
        contractNro?: string | null,
        customerInvoiceId?: string | null,
        customerInvoiceDate?: string | null,
        hanselAgent?: string | null,
        hanselClass?: string | null,
        customerId?: string | null,
        subContractorNumber?: string | null,
        subContractorName?: string | null,
        suplierProductId?: string | null,
        suplierProductName?: string | null,
        quantity?: string | null,
        price?: string | null,
        totalPrice?: string | null,
        priceNoVAT?: string | null,
        additionalInfo1?: string | null,
        additionalInfo2?: string | null,
        additionalInfo3?: string | null,
        desc?: string | null,
      } | null,
      invoiceIds?: Array< string > | null,
      consolidatedInvoiceIds?: Array< string > | null,
      channel?: ReservationChannel | null,
      originURL?: string | null,
      type?: ReservationType | null,
      status?: string | null,
      reservationStatusInfo?:  {
        __typename: "ReservationStatusInfo",
        deliveryTimestamp?: string | null,
        deliveryAddress?: string | null,
        deliveryZipcode?: string | null,
        deliveryCity?: string | null,
        deliveryCountry?: string | null,
        deliveryDescription?: string | null,
        returnTimestamp?: string | null,
        returnAddress?: string | null,
        returnZipcode?: string | null,
        returnCity?: string | null,
        returnCountry?: string | null,
        returnDescription?: string | null,
        cancelReason?: string | null,
      } | null,
      initialCategory?:  {
        __typename: "CategoryInfo",
        id?: string | null,
        name?: string | null,
        hanselClass?: string | null,
      } | null,
      initialClassInfo?:  {
        __typename: "InitialClassInfo",
        categoryId?: string | null,
        categoryName?: string | null,
        hanselClass?: string | null,
      } | null,
      orgInvoiceCustomer?:  {
        __typename: "OrganizationInvoiceCustomer",
        name?: string | null,
        businessId?: string | null,
      } | null,
      pricingCategoryId?: string | null,
      customImportDate?: string | null,
      vehicle?:  {
        __typename: "Vehicle",
        id: string,
        group?: string | null,
        organizationId?: string | null,
        orgBusinessId?: string | null,
        companyId: string,
        name: string,
        registrationPlate?: string | null,
        damages?: string | null,
        image?: string | null,
        powerType?: string | null,
        deductible?: number | null,
        includedDistance?: string | null,
        mileage?: string | null,
        orderNumber?: number | null,
        vehicleCategoryId?: string | null,
        useCategoryDefaults?: boolean | null,
        useStartReturnTimes?: boolean | null,
        hideExport?: boolean | null,
        extraInfo?: string | null,
        extraInfoT?: string | null,
        extraToConfirmation?: string | null,
        extraToConfirmationT?: string | null,
        livionKeyId?: string | null,
        phoneNumber?: string | null,
        importData?: string | null,
        disableOnline?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      invoices?:  {
        __typename: "ModelInvoiceConnection",
        nextToken?: string | null,
      } | null,
      paymentEvents?:  {
        __typename: "ModelPaymentEventConnection",
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListReservationsByGroupByEndTimeQueryVariables = {
  group?: string | null,
  endTime?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelReservationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListReservationsByGroupByEndTimeQuery = {
  listReservationsByGroupByEndTime?:  {
    __typename: "ModelReservationConnection",
    items:  Array< {
      __typename: "Reservation",
      id: string,
      shortId?: string | null,
      language?: Language | null,
      group?: string | null,
      organizationId?: string | null,
      orgBusinessId?: string | null,
      orgExternalBusinessId?: string | null,
      companyId?: string | null,
      returnCompanyId?: string | null,
      startLocation?: string | null,
      returnLocation?: string | null,
      startLocationID?: string | null,
      returnLocationID?: string | null,
      reviewId?: string | null,
      customerBrokerId?: string | null,
      customerCompanyId?: string | null,
      customerPersonalId?: string | null,
      reservationVehicleId?: string | null,
      reservationVehicles?:  Array< {
        __typename: "ReservationVehicle",
        id?: string | null,
        price?: number | null,
        tax?: number | null,
        name?: string | null,
        registrationPlate?: string | null,
        deductible?: number | null,
        includedDistance?: string | null,
        powerType?: string | null,
        mileage?: string | null,
        startMileage?: string | null,
        endMileage?: string | null,
        extraInfo?: string | null,
        damages?: string | null,
        hideExport?: boolean | null,
        createdAt?: string | null,
        addedAt?: string | null,
        categoryName?: string | null,
      } | null > | null,
      wildCardVehicles?:  Array< {
        __typename: "WildCardVehicle",
        name?: string | null,
        price?: number | null,
        tax?: number | null,
        categoryId?: string | null,
        quantity?: number | null,
        deductible?: number | null,
      } | null > | null,
      createdAt: string,
      updatedAt: string,
      startTime?: string | null,
      returnTime?: string | null,
      endTime?: string | null,
      name?: string | null,
      address?: string | null,
      postalCode?: string | null,
      city?: string | null,
      phone?: string | null,
      email?: string | null,
      billingRef?: string | null,
      customerNotes?: string | null,
      ssn?: string | null,
      rentalNotes?: string | null,
      internalNotes?: string | null,
      externalNotes?: string | null,
      outOfCommission?: boolean | null,
      vehiclePrice?: number | null,
      additionalServices?:  Array< {
        __typename: "AdditionalServiceReservation",
        key: string,
        price: number,
        details?: string | null,
        quantity?: number | null,
        type?: ServiceType | null,
        deductibleValue?: number | null,
        groupFields?: Array< string | null > | null,
        tax?: number | null,
        enableNotification?: boolean | null,
        description?: string | null,
        category?: string | null,
      } > | null,
      driverSSN?: string | null,
      differentDriver?: boolean | null,
      driverName?: string | null,
      driverAddress?: string | null,
      driverPostalCode?: string | null,
      driverCity?: string | null,
      driverPhone?: string | null,
      driverEmail?: string | null,
      additionalDriver?: boolean | null,
      additionalDriverName?: string | null,
      additionalDriverPhone?: string | null,
      additionalDriverSSN?: string | null,
      billPayment?: boolean | null,
      isCompany?: boolean | null,
      carStatus?: RentalStatus | null,
      rentalStatus?: RentalStatus | null,
      innovoiceStatus?: ReservationInnovoiceStatus | null,
      companyName?: string | null,
      companyBusinessId?: string | null,
      companyCity?: string | null,
      companyPostalCode?: string | null,
      companyBillingRef?: string | null,
      companyBillingAddress?: string | null,
      companyEBillingOperator?: string | null,
      companyEBillingAddress?: string | null,
      paymentToken?: string | null,
      npsStatus?: NpsStatus | null,
      paymentStatus?: PaymentStatus | null,
      paymentData?:  {
        __typename: "PaymentData",
        paymentTotal?: number | null,
        paymentTodo?: number | null,
        depositPending?: boolean | null,
        deposit?: number | null,
      } | null,
      signStatus?: SignStatus | null,
      signDocumentId?: string | null,
      signerName?: string | null,
      reviewStatus?: ReviewStatus | null,
      authStatus?: AuthStatus | null,
      authTime?: string | null,
      completeBefore?: string | null,
      mustSign?: boolean | null,
      livionData?:  Array< {
        __typename: "LivionData",
        id?: string | null,
        pincode?: string | null,
        keyId?: string | null,
        name?: string | null,
        registrationPlate?: string | null,
      } | null > | null,
      tilakoneData?:  {
        __typename: "TilakoneData",
        access_id?: string | null,
        begin_timestamp?: string | null,
        end_timestamp?: string | null,
        pincode?: string | null,
      } | null,
      voucherData?:  {
        __typename: "VoucherData",
        name: string,
        code: string,
        amount: number,
      } | null,
      customerCarRegistrationPlate?: string | null,
      insuranceData?:  {
        __typename: "InsuranceData",
        customerContact?: boolean | null,
        urgent?: boolean | null,
        customerCarModel?: string | null,
        customerCarMaker?: string | null,
        product?: string | null,
        coveragePercentage?: number | null,
        compensatableDays?: string | null,
        billingPermitCode?: string | null,
        coverageDuration?: number | null,
        providerCompany?: string | null,
        providerBusinessId?: string | null,
        claimAdjusterName?: string | null,
        claimAdjusterEmail?: string | null,
        receipt?: boolean | null,
        replacementVehicleClass?: string | null,
        claimId?: string | null,
        vatDeductible?: boolean | null,
        deliveryCosts?: boolean | null,
        returnCosts?: boolean | null,
      } | null,
      externalData?:  {
        __typename: "ExternalData",
        orderId?: string | null,
        orderReference?: string | null,
        serviceAgreementName?: string | null,
        operatorName?: string | null,
        orderInfo?: string | null,
        billingPayer?: string | null,
        billingDescription?: string | null,
      } | null,
      enableRegistrationPlate?: boolean | null,
      disablePricesInPrintouts?: boolean | null,
      hanselData?:  {
        __typename: "HanselData",
        contractNro?: string | null,
        customerInvoiceId?: string | null,
        customerInvoiceDate?: string | null,
        hanselAgent?: string | null,
        hanselClass?: string | null,
        customerId?: string | null,
        subContractorNumber?: string | null,
        subContractorName?: string | null,
        suplierProductId?: string | null,
        suplierProductName?: string | null,
        quantity?: string | null,
        price?: string | null,
        totalPrice?: string | null,
        priceNoVAT?: string | null,
        additionalInfo1?: string | null,
        additionalInfo2?: string | null,
        additionalInfo3?: string | null,
        desc?: string | null,
      } | null,
      invoiceIds?: Array< string > | null,
      consolidatedInvoiceIds?: Array< string > | null,
      channel?: ReservationChannel | null,
      originURL?: string | null,
      type?: ReservationType | null,
      status?: string | null,
      reservationStatusInfo?:  {
        __typename: "ReservationStatusInfo",
        deliveryTimestamp?: string | null,
        deliveryAddress?: string | null,
        deliveryZipcode?: string | null,
        deliveryCity?: string | null,
        deliveryCountry?: string | null,
        deliveryDescription?: string | null,
        returnTimestamp?: string | null,
        returnAddress?: string | null,
        returnZipcode?: string | null,
        returnCity?: string | null,
        returnCountry?: string | null,
        returnDescription?: string | null,
        cancelReason?: string | null,
      } | null,
      initialCategory?:  {
        __typename: "CategoryInfo",
        id?: string | null,
        name?: string | null,
        hanselClass?: string | null,
      } | null,
      initialClassInfo?:  {
        __typename: "InitialClassInfo",
        categoryId?: string | null,
        categoryName?: string | null,
        hanselClass?: string | null,
      } | null,
      orgInvoiceCustomer?:  {
        __typename: "OrganizationInvoiceCustomer",
        name?: string | null,
        businessId?: string | null,
      } | null,
      pricingCategoryId?: string | null,
      customImportDate?: string | null,
      vehicle?:  {
        __typename: "Vehicle",
        id: string,
        group?: string | null,
        organizationId?: string | null,
        orgBusinessId?: string | null,
        companyId: string,
        name: string,
        registrationPlate?: string | null,
        damages?: string | null,
        image?: string | null,
        powerType?: string | null,
        deductible?: number | null,
        includedDistance?: string | null,
        mileage?: string | null,
        orderNumber?: number | null,
        vehicleCategoryId?: string | null,
        useCategoryDefaults?: boolean | null,
        useStartReturnTimes?: boolean | null,
        hideExport?: boolean | null,
        extraInfo?: string | null,
        extraInfoT?: string | null,
        extraToConfirmation?: string | null,
        extraToConfirmationT?: string | null,
        livionKeyId?: string | null,
        phoneNumber?: string | null,
        importData?: string | null,
        disableOnline?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      invoices?:  {
        __typename: "ModelInvoiceConnection",
        nextToken?: string | null,
      } | null,
      paymentEvents?:  {
        __typename: "ModelPaymentEventConnection",
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListReservationsByPaymentStatusQueryVariables = {
  paymentStatus?: PaymentStatus | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelReservationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListReservationsByPaymentStatusQuery = {
  listReservationsByPaymentStatus?:  {
    __typename: "ModelReservationConnection",
    items:  Array< {
      __typename: "Reservation",
      id: string,
      shortId?: string | null,
      language?: Language | null,
      group?: string | null,
      organizationId?: string | null,
      orgBusinessId?: string | null,
      orgExternalBusinessId?: string | null,
      companyId?: string | null,
      returnCompanyId?: string | null,
      startLocation?: string | null,
      returnLocation?: string | null,
      startLocationID?: string | null,
      returnLocationID?: string | null,
      reviewId?: string | null,
      customerBrokerId?: string | null,
      customerCompanyId?: string | null,
      customerPersonalId?: string | null,
      reservationVehicleId?: string | null,
      reservationVehicles?:  Array< {
        __typename: "ReservationVehicle",
        id?: string | null,
        price?: number | null,
        tax?: number | null,
        name?: string | null,
        registrationPlate?: string | null,
        deductible?: number | null,
        includedDistance?: string | null,
        powerType?: string | null,
        mileage?: string | null,
        startMileage?: string | null,
        endMileage?: string | null,
        extraInfo?: string | null,
        damages?: string | null,
        hideExport?: boolean | null,
        createdAt?: string | null,
        addedAt?: string | null,
        categoryName?: string | null,
      } | null > | null,
      wildCardVehicles?:  Array< {
        __typename: "WildCardVehicle",
        name?: string | null,
        price?: number | null,
        tax?: number | null,
        categoryId?: string | null,
        quantity?: number | null,
        deductible?: number | null,
      } | null > | null,
      createdAt: string,
      updatedAt: string,
      startTime?: string | null,
      returnTime?: string | null,
      endTime?: string | null,
      name?: string | null,
      address?: string | null,
      postalCode?: string | null,
      city?: string | null,
      phone?: string | null,
      email?: string | null,
      billingRef?: string | null,
      customerNotes?: string | null,
      ssn?: string | null,
      rentalNotes?: string | null,
      internalNotes?: string | null,
      externalNotes?: string | null,
      outOfCommission?: boolean | null,
      vehiclePrice?: number | null,
      additionalServices?:  Array< {
        __typename: "AdditionalServiceReservation",
        key: string,
        price: number,
        details?: string | null,
        quantity?: number | null,
        type?: ServiceType | null,
        deductibleValue?: number | null,
        groupFields?: Array< string | null > | null,
        tax?: number | null,
        enableNotification?: boolean | null,
        description?: string | null,
        category?: string | null,
      } > | null,
      driverSSN?: string | null,
      differentDriver?: boolean | null,
      driverName?: string | null,
      driverAddress?: string | null,
      driverPostalCode?: string | null,
      driverCity?: string | null,
      driverPhone?: string | null,
      driverEmail?: string | null,
      additionalDriver?: boolean | null,
      additionalDriverName?: string | null,
      additionalDriverPhone?: string | null,
      additionalDriverSSN?: string | null,
      billPayment?: boolean | null,
      isCompany?: boolean | null,
      carStatus?: RentalStatus | null,
      rentalStatus?: RentalStatus | null,
      innovoiceStatus?: ReservationInnovoiceStatus | null,
      companyName?: string | null,
      companyBusinessId?: string | null,
      companyCity?: string | null,
      companyPostalCode?: string | null,
      companyBillingRef?: string | null,
      companyBillingAddress?: string | null,
      companyEBillingOperator?: string | null,
      companyEBillingAddress?: string | null,
      paymentToken?: string | null,
      npsStatus?: NpsStatus | null,
      paymentStatus?: PaymentStatus | null,
      paymentData?:  {
        __typename: "PaymentData",
        paymentTotal?: number | null,
        paymentTodo?: number | null,
        depositPending?: boolean | null,
        deposit?: number | null,
      } | null,
      signStatus?: SignStatus | null,
      signDocumentId?: string | null,
      signerName?: string | null,
      reviewStatus?: ReviewStatus | null,
      authStatus?: AuthStatus | null,
      authTime?: string | null,
      completeBefore?: string | null,
      mustSign?: boolean | null,
      livionData?:  Array< {
        __typename: "LivionData",
        id?: string | null,
        pincode?: string | null,
        keyId?: string | null,
        name?: string | null,
        registrationPlate?: string | null,
      } | null > | null,
      tilakoneData?:  {
        __typename: "TilakoneData",
        access_id?: string | null,
        begin_timestamp?: string | null,
        end_timestamp?: string | null,
        pincode?: string | null,
      } | null,
      voucherData?:  {
        __typename: "VoucherData",
        name: string,
        code: string,
        amount: number,
      } | null,
      customerCarRegistrationPlate?: string | null,
      insuranceData?:  {
        __typename: "InsuranceData",
        customerContact?: boolean | null,
        urgent?: boolean | null,
        customerCarModel?: string | null,
        customerCarMaker?: string | null,
        product?: string | null,
        coveragePercentage?: number | null,
        compensatableDays?: string | null,
        billingPermitCode?: string | null,
        coverageDuration?: number | null,
        providerCompany?: string | null,
        providerBusinessId?: string | null,
        claimAdjusterName?: string | null,
        claimAdjusterEmail?: string | null,
        receipt?: boolean | null,
        replacementVehicleClass?: string | null,
        claimId?: string | null,
        vatDeductible?: boolean | null,
        deliveryCosts?: boolean | null,
        returnCosts?: boolean | null,
      } | null,
      externalData?:  {
        __typename: "ExternalData",
        orderId?: string | null,
        orderReference?: string | null,
        serviceAgreementName?: string | null,
        operatorName?: string | null,
        orderInfo?: string | null,
        billingPayer?: string | null,
        billingDescription?: string | null,
      } | null,
      enableRegistrationPlate?: boolean | null,
      disablePricesInPrintouts?: boolean | null,
      hanselData?:  {
        __typename: "HanselData",
        contractNro?: string | null,
        customerInvoiceId?: string | null,
        customerInvoiceDate?: string | null,
        hanselAgent?: string | null,
        hanselClass?: string | null,
        customerId?: string | null,
        subContractorNumber?: string | null,
        subContractorName?: string | null,
        suplierProductId?: string | null,
        suplierProductName?: string | null,
        quantity?: string | null,
        price?: string | null,
        totalPrice?: string | null,
        priceNoVAT?: string | null,
        additionalInfo1?: string | null,
        additionalInfo2?: string | null,
        additionalInfo3?: string | null,
        desc?: string | null,
      } | null,
      invoiceIds?: Array< string > | null,
      consolidatedInvoiceIds?: Array< string > | null,
      channel?: ReservationChannel | null,
      originURL?: string | null,
      type?: ReservationType | null,
      status?: string | null,
      reservationStatusInfo?:  {
        __typename: "ReservationStatusInfo",
        deliveryTimestamp?: string | null,
        deliveryAddress?: string | null,
        deliveryZipcode?: string | null,
        deliveryCity?: string | null,
        deliveryCountry?: string | null,
        deliveryDescription?: string | null,
        returnTimestamp?: string | null,
        returnAddress?: string | null,
        returnZipcode?: string | null,
        returnCity?: string | null,
        returnCountry?: string | null,
        returnDescription?: string | null,
        cancelReason?: string | null,
      } | null,
      initialCategory?:  {
        __typename: "CategoryInfo",
        id?: string | null,
        name?: string | null,
        hanselClass?: string | null,
      } | null,
      initialClassInfo?:  {
        __typename: "InitialClassInfo",
        categoryId?: string | null,
        categoryName?: string | null,
        hanselClass?: string | null,
      } | null,
      orgInvoiceCustomer?:  {
        __typename: "OrganizationInvoiceCustomer",
        name?: string | null,
        businessId?: string | null,
      } | null,
      pricingCategoryId?: string | null,
      customImportDate?: string | null,
      vehicle?:  {
        __typename: "Vehicle",
        id: string,
        group?: string | null,
        organizationId?: string | null,
        orgBusinessId?: string | null,
        companyId: string,
        name: string,
        registrationPlate?: string | null,
        damages?: string | null,
        image?: string | null,
        powerType?: string | null,
        deductible?: number | null,
        includedDistance?: string | null,
        mileage?: string | null,
        orderNumber?: number | null,
        vehicleCategoryId?: string | null,
        useCategoryDefaults?: boolean | null,
        useStartReturnTimes?: boolean | null,
        hideExport?: boolean | null,
        extraInfo?: string | null,
        extraInfoT?: string | null,
        extraToConfirmation?: string | null,
        extraToConfirmationT?: string | null,
        livionKeyId?: string | null,
        phoneNumber?: string | null,
        importData?: string | null,
        disableOnline?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      invoices?:  {
        __typename: "ModelInvoiceConnection",
        nextToken?: string | null,
      } | null,
      paymentEvents?:  {
        __typename: "ModelPaymentEventConnection",
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListReservationsByPaymentStatusByOrgBusinessIdQueryVariables = {
  paymentStatus?: PaymentStatus | null,
  orgBusinessId?: ModelIDKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelReservationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListReservationsByPaymentStatusByOrgBusinessIdQuery = {
  listReservationsByPaymentStatusByOrgBusinessId?:  {
    __typename: "ModelReservationConnection",
    items:  Array< {
      __typename: "Reservation",
      id: string,
      shortId?: string | null,
      language?: Language | null,
      group?: string | null,
      organizationId?: string | null,
      orgBusinessId?: string | null,
      orgExternalBusinessId?: string | null,
      companyId?: string | null,
      returnCompanyId?: string | null,
      startLocation?: string | null,
      returnLocation?: string | null,
      startLocationID?: string | null,
      returnLocationID?: string | null,
      reviewId?: string | null,
      customerBrokerId?: string | null,
      customerCompanyId?: string | null,
      customerPersonalId?: string | null,
      reservationVehicleId?: string | null,
      reservationVehicles?:  Array< {
        __typename: "ReservationVehicle",
        id?: string | null,
        price?: number | null,
        tax?: number | null,
        name?: string | null,
        registrationPlate?: string | null,
        deductible?: number | null,
        includedDistance?: string | null,
        powerType?: string | null,
        mileage?: string | null,
        startMileage?: string | null,
        endMileage?: string | null,
        extraInfo?: string | null,
        damages?: string | null,
        hideExport?: boolean | null,
        createdAt?: string | null,
        addedAt?: string | null,
        categoryName?: string | null,
      } | null > | null,
      wildCardVehicles?:  Array< {
        __typename: "WildCardVehicle",
        name?: string | null,
        price?: number | null,
        tax?: number | null,
        categoryId?: string | null,
        quantity?: number | null,
        deductible?: number | null,
      } | null > | null,
      createdAt: string,
      updatedAt: string,
      startTime?: string | null,
      returnTime?: string | null,
      endTime?: string | null,
      name?: string | null,
      address?: string | null,
      postalCode?: string | null,
      city?: string | null,
      phone?: string | null,
      email?: string | null,
      billingRef?: string | null,
      customerNotes?: string | null,
      ssn?: string | null,
      rentalNotes?: string | null,
      internalNotes?: string | null,
      externalNotes?: string | null,
      outOfCommission?: boolean | null,
      vehiclePrice?: number | null,
      additionalServices?:  Array< {
        __typename: "AdditionalServiceReservation",
        key: string,
        price: number,
        details?: string | null,
        quantity?: number | null,
        type?: ServiceType | null,
        deductibleValue?: number | null,
        groupFields?: Array< string | null > | null,
        tax?: number | null,
        enableNotification?: boolean | null,
        description?: string | null,
        category?: string | null,
      } > | null,
      driverSSN?: string | null,
      differentDriver?: boolean | null,
      driverName?: string | null,
      driverAddress?: string | null,
      driverPostalCode?: string | null,
      driverCity?: string | null,
      driverPhone?: string | null,
      driverEmail?: string | null,
      additionalDriver?: boolean | null,
      additionalDriverName?: string | null,
      additionalDriverPhone?: string | null,
      additionalDriverSSN?: string | null,
      billPayment?: boolean | null,
      isCompany?: boolean | null,
      carStatus?: RentalStatus | null,
      rentalStatus?: RentalStatus | null,
      innovoiceStatus?: ReservationInnovoiceStatus | null,
      companyName?: string | null,
      companyBusinessId?: string | null,
      companyCity?: string | null,
      companyPostalCode?: string | null,
      companyBillingRef?: string | null,
      companyBillingAddress?: string | null,
      companyEBillingOperator?: string | null,
      companyEBillingAddress?: string | null,
      paymentToken?: string | null,
      npsStatus?: NpsStatus | null,
      paymentStatus?: PaymentStatus | null,
      paymentData?:  {
        __typename: "PaymentData",
        paymentTotal?: number | null,
        paymentTodo?: number | null,
        depositPending?: boolean | null,
        deposit?: number | null,
      } | null,
      signStatus?: SignStatus | null,
      signDocumentId?: string | null,
      signerName?: string | null,
      reviewStatus?: ReviewStatus | null,
      authStatus?: AuthStatus | null,
      authTime?: string | null,
      completeBefore?: string | null,
      mustSign?: boolean | null,
      livionData?:  Array< {
        __typename: "LivionData",
        id?: string | null,
        pincode?: string | null,
        keyId?: string | null,
        name?: string | null,
        registrationPlate?: string | null,
      } | null > | null,
      tilakoneData?:  {
        __typename: "TilakoneData",
        access_id?: string | null,
        begin_timestamp?: string | null,
        end_timestamp?: string | null,
        pincode?: string | null,
      } | null,
      voucherData?:  {
        __typename: "VoucherData",
        name: string,
        code: string,
        amount: number,
      } | null,
      customerCarRegistrationPlate?: string | null,
      insuranceData?:  {
        __typename: "InsuranceData",
        customerContact?: boolean | null,
        urgent?: boolean | null,
        customerCarModel?: string | null,
        customerCarMaker?: string | null,
        product?: string | null,
        coveragePercentage?: number | null,
        compensatableDays?: string | null,
        billingPermitCode?: string | null,
        coverageDuration?: number | null,
        providerCompany?: string | null,
        providerBusinessId?: string | null,
        claimAdjusterName?: string | null,
        claimAdjusterEmail?: string | null,
        receipt?: boolean | null,
        replacementVehicleClass?: string | null,
        claimId?: string | null,
        vatDeductible?: boolean | null,
        deliveryCosts?: boolean | null,
        returnCosts?: boolean | null,
      } | null,
      externalData?:  {
        __typename: "ExternalData",
        orderId?: string | null,
        orderReference?: string | null,
        serviceAgreementName?: string | null,
        operatorName?: string | null,
        orderInfo?: string | null,
        billingPayer?: string | null,
        billingDescription?: string | null,
      } | null,
      enableRegistrationPlate?: boolean | null,
      disablePricesInPrintouts?: boolean | null,
      hanselData?:  {
        __typename: "HanselData",
        contractNro?: string | null,
        customerInvoiceId?: string | null,
        customerInvoiceDate?: string | null,
        hanselAgent?: string | null,
        hanselClass?: string | null,
        customerId?: string | null,
        subContractorNumber?: string | null,
        subContractorName?: string | null,
        suplierProductId?: string | null,
        suplierProductName?: string | null,
        quantity?: string | null,
        price?: string | null,
        totalPrice?: string | null,
        priceNoVAT?: string | null,
        additionalInfo1?: string | null,
        additionalInfo2?: string | null,
        additionalInfo3?: string | null,
        desc?: string | null,
      } | null,
      invoiceIds?: Array< string > | null,
      consolidatedInvoiceIds?: Array< string > | null,
      channel?: ReservationChannel | null,
      originURL?: string | null,
      type?: ReservationType | null,
      status?: string | null,
      reservationStatusInfo?:  {
        __typename: "ReservationStatusInfo",
        deliveryTimestamp?: string | null,
        deliveryAddress?: string | null,
        deliveryZipcode?: string | null,
        deliveryCity?: string | null,
        deliveryCountry?: string | null,
        deliveryDescription?: string | null,
        returnTimestamp?: string | null,
        returnAddress?: string | null,
        returnZipcode?: string | null,
        returnCity?: string | null,
        returnCountry?: string | null,
        returnDescription?: string | null,
        cancelReason?: string | null,
      } | null,
      initialCategory?:  {
        __typename: "CategoryInfo",
        id?: string | null,
        name?: string | null,
        hanselClass?: string | null,
      } | null,
      initialClassInfo?:  {
        __typename: "InitialClassInfo",
        categoryId?: string | null,
        categoryName?: string | null,
        hanselClass?: string | null,
      } | null,
      orgInvoiceCustomer?:  {
        __typename: "OrganizationInvoiceCustomer",
        name?: string | null,
        businessId?: string | null,
      } | null,
      pricingCategoryId?: string | null,
      customImportDate?: string | null,
      vehicle?:  {
        __typename: "Vehicle",
        id: string,
        group?: string | null,
        organizationId?: string | null,
        orgBusinessId?: string | null,
        companyId: string,
        name: string,
        registrationPlate?: string | null,
        damages?: string | null,
        image?: string | null,
        powerType?: string | null,
        deductible?: number | null,
        includedDistance?: string | null,
        mileage?: string | null,
        orderNumber?: number | null,
        vehicleCategoryId?: string | null,
        useCategoryDefaults?: boolean | null,
        useStartReturnTimes?: boolean | null,
        hideExport?: boolean | null,
        extraInfo?: string | null,
        extraInfoT?: string | null,
        extraToConfirmation?: string | null,
        extraToConfirmationT?: string | null,
        livionKeyId?: string | null,
        phoneNumber?: string | null,
        importData?: string | null,
        disableOnline?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      invoices?:  {
        __typename: "ModelInvoiceConnection",
        nextToken?: string | null,
      } | null,
      paymentEvents?:  {
        __typename: "ModelPaymentEventConnection",
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListReservationsBySignStatusQueryVariables = {
  signStatus?: SignStatus | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelReservationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListReservationsBySignStatusQuery = {
  listReservationsBySignStatus?:  {
    __typename: "ModelReservationConnection",
    items:  Array< {
      __typename: "Reservation",
      id: string,
      shortId?: string | null,
      language?: Language | null,
      group?: string | null,
      organizationId?: string | null,
      orgBusinessId?: string | null,
      orgExternalBusinessId?: string | null,
      companyId?: string | null,
      returnCompanyId?: string | null,
      startLocation?: string | null,
      returnLocation?: string | null,
      startLocationID?: string | null,
      returnLocationID?: string | null,
      reviewId?: string | null,
      customerBrokerId?: string | null,
      customerCompanyId?: string | null,
      customerPersonalId?: string | null,
      reservationVehicleId?: string | null,
      reservationVehicles?:  Array< {
        __typename: "ReservationVehicle",
        id?: string | null,
        price?: number | null,
        tax?: number | null,
        name?: string | null,
        registrationPlate?: string | null,
        deductible?: number | null,
        includedDistance?: string | null,
        powerType?: string | null,
        mileage?: string | null,
        startMileage?: string | null,
        endMileage?: string | null,
        extraInfo?: string | null,
        damages?: string | null,
        hideExport?: boolean | null,
        createdAt?: string | null,
        addedAt?: string | null,
        categoryName?: string | null,
      } | null > | null,
      wildCardVehicles?:  Array< {
        __typename: "WildCardVehicle",
        name?: string | null,
        price?: number | null,
        tax?: number | null,
        categoryId?: string | null,
        quantity?: number | null,
        deductible?: number | null,
      } | null > | null,
      createdAt: string,
      updatedAt: string,
      startTime?: string | null,
      returnTime?: string | null,
      endTime?: string | null,
      name?: string | null,
      address?: string | null,
      postalCode?: string | null,
      city?: string | null,
      phone?: string | null,
      email?: string | null,
      billingRef?: string | null,
      customerNotes?: string | null,
      ssn?: string | null,
      rentalNotes?: string | null,
      internalNotes?: string | null,
      externalNotes?: string | null,
      outOfCommission?: boolean | null,
      vehiclePrice?: number | null,
      additionalServices?:  Array< {
        __typename: "AdditionalServiceReservation",
        key: string,
        price: number,
        details?: string | null,
        quantity?: number | null,
        type?: ServiceType | null,
        deductibleValue?: number | null,
        groupFields?: Array< string | null > | null,
        tax?: number | null,
        enableNotification?: boolean | null,
        description?: string | null,
        category?: string | null,
      } > | null,
      driverSSN?: string | null,
      differentDriver?: boolean | null,
      driverName?: string | null,
      driverAddress?: string | null,
      driverPostalCode?: string | null,
      driverCity?: string | null,
      driverPhone?: string | null,
      driverEmail?: string | null,
      additionalDriver?: boolean | null,
      additionalDriverName?: string | null,
      additionalDriverPhone?: string | null,
      additionalDriverSSN?: string | null,
      billPayment?: boolean | null,
      isCompany?: boolean | null,
      carStatus?: RentalStatus | null,
      rentalStatus?: RentalStatus | null,
      innovoiceStatus?: ReservationInnovoiceStatus | null,
      companyName?: string | null,
      companyBusinessId?: string | null,
      companyCity?: string | null,
      companyPostalCode?: string | null,
      companyBillingRef?: string | null,
      companyBillingAddress?: string | null,
      companyEBillingOperator?: string | null,
      companyEBillingAddress?: string | null,
      paymentToken?: string | null,
      npsStatus?: NpsStatus | null,
      paymentStatus?: PaymentStatus | null,
      paymentData?:  {
        __typename: "PaymentData",
        paymentTotal?: number | null,
        paymentTodo?: number | null,
        depositPending?: boolean | null,
        deposit?: number | null,
      } | null,
      signStatus?: SignStatus | null,
      signDocumentId?: string | null,
      signerName?: string | null,
      reviewStatus?: ReviewStatus | null,
      authStatus?: AuthStatus | null,
      authTime?: string | null,
      completeBefore?: string | null,
      mustSign?: boolean | null,
      livionData?:  Array< {
        __typename: "LivionData",
        id?: string | null,
        pincode?: string | null,
        keyId?: string | null,
        name?: string | null,
        registrationPlate?: string | null,
      } | null > | null,
      tilakoneData?:  {
        __typename: "TilakoneData",
        access_id?: string | null,
        begin_timestamp?: string | null,
        end_timestamp?: string | null,
        pincode?: string | null,
      } | null,
      voucherData?:  {
        __typename: "VoucherData",
        name: string,
        code: string,
        amount: number,
      } | null,
      customerCarRegistrationPlate?: string | null,
      insuranceData?:  {
        __typename: "InsuranceData",
        customerContact?: boolean | null,
        urgent?: boolean | null,
        customerCarModel?: string | null,
        customerCarMaker?: string | null,
        product?: string | null,
        coveragePercentage?: number | null,
        compensatableDays?: string | null,
        billingPermitCode?: string | null,
        coverageDuration?: number | null,
        providerCompany?: string | null,
        providerBusinessId?: string | null,
        claimAdjusterName?: string | null,
        claimAdjusterEmail?: string | null,
        receipt?: boolean | null,
        replacementVehicleClass?: string | null,
        claimId?: string | null,
        vatDeductible?: boolean | null,
        deliveryCosts?: boolean | null,
        returnCosts?: boolean | null,
      } | null,
      externalData?:  {
        __typename: "ExternalData",
        orderId?: string | null,
        orderReference?: string | null,
        serviceAgreementName?: string | null,
        operatorName?: string | null,
        orderInfo?: string | null,
        billingPayer?: string | null,
        billingDescription?: string | null,
      } | null,
      enableRegistrationPlate?: boolean | null,
      disablePricesInPrintouts?: boolean | null,
      hanselData?:  {
        __typename: "HanselData",
        contractNro?: string | null,
        customerInvoiceId?: string | null,
        customerInvoiceDate?: string | null,
        hanselAgent?: string | null,
        hanselClass?: string | null,
        customerId?: string | null,
        subContractorNumber?: string | null,
        subContractorName?: string | null,
        suplierProductId?: string | null,
        suplierProductName?: string | null,
        quantity?: string | null,
        price?: string | null,
        totalPrice?: string | null,
        priceNoVAT?: string | null,
        additionalInfo1?: string | null,
        additionalInfo2?: string | null,
        additionalInfo3?: string | null,
        desc?: string | null,
      } | null,
      invoiceIds?: Array< string > | null,
      consolidatedInvoiceIds?: Array< string > | null,
      channel?: ReservationChannel | null,
      originURL?: string | null,
      type?: ReservationType | null,
      status?: string | null,
      reservationStatusInfo?:  {
        __typename: "ReservationStatusInfo",
        deliveryTimestamp?: string | null,
        deliveryAddress?: string | null,
        deliveryZipcode?: string | null,
        deliveryCity?: string | null,
        deliveryCountry?: string | null,
        deliveryDescription?: string | null,
        returnTimestamp?: string | null,
        returnAddress?: string | null,
        returnZipcode?: string | null,
        returnCity?: string | null,
        returnCountry?: string | null,
        returnDescription?: string | null,
        cancelReason?: string | null,
      } | null,
      initialCategory?:  {
        __typename: "CategoryInfo",
        id?: string | null,
        name?: string | null,
        hanselClass?: string | null,
      } | null,
      initialClassInfo?:  {
        __typename: "InitialClassInfo",
        categoryId?: string | null,
        categoryName?: string | null,
        hanselClass?: string | null,
      } | null,
      orgInvoiceCustomer?:  {
        __typename: "OrganizationInvoiceCustomer",
        name?: string | null,
        businessId?: string | null,
      } | null,
      pricingCategoryId?: string | null,
      customImportDate?: string | null,
      vehicle?:  {
        __typename: "Vehicle",
        id: string,
        group?: string | null,
        organizationId?: string | null,
        orgBusinessId?: string | null,
        companyId: string,
        name: string,
        registrationPlate?: string | null,
        damages?: string | null,
        image?: string | null,
        powerType?: string | null,
        deductible?: number | null,
        includedDistance?: string | null,
        mileage?: string | null,
        orderNumber?: number | null,
        vehicleCategoryId?: string | null,
        useCategoryDefaults?: boolean | null,
        useStartReturnTimes?: boolean | null,
        hideExport?: boolean | null,
        extraInfo?: string | null,
        extraInfoT?: string | null,
        extraToConfirmation?: string | null,
        extraToConfirmationT?: string | null,
        livionKeyId?: string | null,
        phoneNumber?: string | null,
        importData?: string | null,
        disableOnline?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      invoices?:  {
        __typename: "ModelInvoiceConnection",
        nextToken?: string | null,
      } | null,
      paymentEvents?:  {
        __typename: "ModelPaymentEventConnection",
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListReservationsByAuthStatusQueryVariables = {
  authStatus?: AuthStatus | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelReservationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListReservationsByAuthStatusQuery = {
  listReservationsByAuthStatus?:  {
    __typename: "ModelReservationConnection",
    items:  Array< {
      __typename: "Reservation",
      id: string,
      shortId?: string | null,
      language?: Language | null,
      group?: string | null,
      organizationId?: string | null,
      orgBusinessId?: string | null,
      orgExternalBusinessId?: string | null,
      companyId?: string | null,
      returnCompanyId?: string | null,
      startLocation?: string | null,
      returnLocation?: string | null,
      startLocationID?: string | null,
      returnLocationID?: string | null,
      reviewId?: string | null,
      customerBrokerId?: string | null,
      customerCompanyId?: string | null,
      customerPersonalId?: string | null,
      reservationVehicleId?: string | null,
      reservationVehicles?:  Array< {
        __typename: "ReservationVehicle",
        id?: string | null,
        price?: number | null,
        tax?: number | null,
        name?: string | null,
        registrationPlate?: string | null,
        deductible?: number | null,
        includedDistance?: string | null,
        powerType?: string | null,
        mileage?: string | null,
        startMileage?: string | null,
        endMileage?: string | null,
        extraInfo?: string | null,
        damages?: string | null,
        hideExport?: boolean | null,
        createdAt?: string | null,
        addedAt?: string | null,
        categoryName?: string | null,
      } | null > | null,
      wildCardVehicles?:  Array< {
        __typename: "WildCardVehicle",
        name?: string | null,
        price?: number | null,
        tax?: number | null,
        categoryId?: string | null,
        quantity?: number | null,
        deductible?: number | null,
      } | null > | null,
      createdAt: string,
      updatedAt: string,
      startTime?: string | null,
      returnTime?: string | null,
      endTime?: string | null,
      name?: string | null,
      address?: string | null,
      postalCode?: string | null,
      city?: string | null,
      phone?: string | null,
      email?: string | null,
      billingRef?: string | null,
      customerNotes?: string | null,
      ssn?: string | null,
      rentalNotes?: string | null,
      internalNotes?: string | null,
      externalNotes?: string | null,
      outOfCommission?: boolean | null,
      vehiclePrice?: number | null,
      additionalServices?:  Array< {
        __typename: "AdditionalServiceReservation",
        key: string,
        price: number,
        details?: string | null,
        quantity?: number | null,
        type?: ServiceType | null,
        deductibleValue?: number | null,
        groupFields?: Array< string | null > | null,
        tax?: number | null,
        enableNotification?: boolean | null,
        description?: string | null,
        category?: string | null,
      } > | null,
      driverSSN?: string | null,
      differentDriver?: boolean | null,
      driverName?: string | null,
      driverAddress?: string | null,
      driverPostalCode?: string | null,
      driverCity?: string | null,
      driverPhone?: string | null,
      driverEmail?: string | null,
      additionalDriver?: boolean | null,
      additionalDriverName?: string | null,
      additionalDriverPhone?: string | null,
      additionalDriverSSN?: string | null,
      billPayment?: boolean | null,
      isCompany?: boolean | null,
      carStatus?: RentalStatus | null,
      rentalStatus?: RentalStatus | null,
      innovoiceStatus?: ReservationInnovoiceStatus | null,
      companyName?: string | null,
      companyBusinessId?: string | null,
      companyCity?: string | null,
      companyPostalCode?: string | null,
      companyBillingRef?: string | null,
      companyBillingAddress?: string | null,
      companyEBillingOperator?: string | null,
      companyEBillingAddress?: string | null,
      paymentToken?: string | null,
      npsStatus?: NpsStatus | null,
      paymentStatus?: PaymentStatus | null,
      paymentData?:  {
        __typename: "PaymentData",
        paymentTotal?: number | null,
        paymentTodo?: number | null,
        depositPending?: boolean | null,
        deposit?: number | null,
      } | null,
      signStatus?: SignStatus | null,
      signDocumentId?: string | null,
      signerName?: string | null,
      reviewStatus?: ReviewStatus | null,
      authStatus?: AuthStatus | null,
      authTime?: string | null,
      completeBefore?: string | null,
      mustSign?: boolean | null,
      livionData?:  Array< {
        __typename: "LivionData",
        id?: string | null,
        pincode?: string | null,
        keyId?: string | null,
        name?: string | null,
        registrationPlate?: string | null,
      } | null > | null,
      tilakoneData?:  {
        __typename: "TilakoneData",
        access_id?: string | null,
        begin_timestamp?: string | null,
        end_timestamp?: string | null,
        pincode?: string | null,
      } | null,
      voucherData?:  {
        __typename: "VoucherData",
        name: string,
        code: string,
        amount: number,
      } | null,
      customerCarRegistrationPlate?: string | null,
      insuranceData?:  {
        __typename: "InsuranceData",
        customerContact?: boolean | null,
        urgent?: boolean | null,
        customerCarModel?: string | null,
        customerCarMaker?: string | null,
        product?: string | null,
        coveragePercentage?: number | null,
        compensatableDays?: string | null,
        billingPermitCode?: string | null,
        coverageDuration?: number | null,
        providerCompany?: string | null,
        providerBusinessId?: string | null,
        claimAdjusterName?: string | null,
        claimAdjusterEmail?: string | null,
        receipt?: boolean | null,
        replacementVehicleClass?: string | null,
        claimId?: string | null,
        vatDeductible?: boolean | null,
        deliveryCosts?: boolean | null,
        returnCosts?: boolean | null,
      } | null,
      externalData?:  {
        __typename: "ExternalData",
        orderId?: string | null,
        orderReference?: string | null,
        serviceAgreementName?: string | null,
        operatorName?: string | null,
        orderInfo?: string | null,
        billingPayer?: string | null,
        billingDescription?: string | null,
      } | null,
      enableRegistrationPlate?: boolean | null,
      disablePricesInPrintouts?: boolean | null,
      hanselData?:  {
        __typename: "HanselData",
        contractNro?: string | null,
        customerInvoiceId?: string | null,
        customerInvoiceDate?: string | null,
        hanselAgent?: string | null,
        hanselClass?: string | null,
        customerId?: string | null,
        subContractorNumber?: string | null,
        subContractorName?: string | null,
        suplierProductId?: string | null,
        suplierProductName?: string | null,
        quantity?: string | null,
        price?: string | null,
        totalPrice?: string | null,
        priceNoVAT?: string | null,
        additionalInfo1?: string | null,
        additionalInfo2?: string | null,
        additionalInfo3?: string | null,
        desc?: string | null,
      } | null,
      invoiceIds?: Array< string > | null,
      consolidatedInvoiceIds?: Array< string > | null,
      channel?: ReservationChannel | null,
      originURL?: string | null,
      type?: ReservationType | null,
      status?: string | null,
      reservationStatusInfo?:  {
        __typename: "ReservationStatusInfo",
        deliveryTimestamp?: string | null,
        deliveryAddress?: string | null,
        deliveryZipcode?: string | null,
        deliveryCity?: string | null,
        deliveryCountry?: string | null,
        deliveryDescription?: string | null,
        returnTimestamp?: string | null,
        returnAddress?: string | null,
        returnZipcode?: string | null,
        returnCity?: string | null,
        returnCountry?: string | null,
        returnDescription?: string | null,
        cancelReason?: string | null,
      } | null,
      initialCategory?:  {
        __typename: "CategoryInfo",
        id?: string | null,
        name?: string | null,
        hanselClass?: string | null,
      } | null,
      initialClassInfo?:  {
        __typename: "InitialClassInfo",
        categoryId?: string | null,
        categoryName?: string | null,
        hanselClass?: string | null,
      } | null,
      orgInvoiceCustomer?:  {
        __typename: "OrganizationInvoiceCustomer",
        name?: string | null,
        businessId?: string | null,
      } | null,
      pricingCategoryId?: string | null,
      customImportDate?: string | null,
      vehicle?:  {
        __typename: "Vehicle",
        id: string,
        group?: string | null,
        organizationId?: string | null,
        orgBusinessId?: string | null,
        companyId: string,
        name: string,
        registrationPlate?: string | null,
        damages?: string | null,
        image?: string | null,
        powerType?: string | null,
        deductible?: number | null,
        includedDistance?: string | null,
        mileage?: string | null,
        orderNumber?: number | null,
        vehicleCategoryId?: string | null,
        useCategoryDefaults?: boolean | null,
        useStartReturnTimes?: boolean | null,
        hideExport?: boolean | null,
        extraInfo?: string | null,
        extraInfoT?: string | null,
        extraToConfirmation?: string | null,
        extraToConfirmationT?: string | null,
        livionKeyId?: string | null,
        phoneNumber?: string | null,
        importData?: string | null,
        disableOnline?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      invoices?:  {
        __typename: "ModelInvoiceConnection",
        nextToken?: string | null,
      } | null,
      paymentEvents?:  {
        __typename: "ModelPaymentEventConnection",
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListReservationsByReviewStatusQueryVariables = {
  reviewStatus?: ReviewStatus | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelReservationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListReservationsByReviewStatusQuery = {
  listReservationsByReviewStatus?:  {
    __typename: "ModelReservationConnection",
    items:  Array< {
      __typename: "Reservation",
      id: string,
      shortId?: string | null,
      language?: Language | null,
      group?: string | null,
      organizationId?: string | null,
      orgBusinessId?: string | null,
      orgExternalBusinessId?: string | null,
      companyId?: string | null,
      returnCompanyId?: string | null,
      startLocation?: string | null,
      returnLocation?: string | null,
      startLocationID?: string | null,
      returnLocationID?: string | null,
      reviewId?: string | null,
      customerBrokerId?: string | null,
      customerCompanyId?: string | null,
      customerPersonalId?: string | null,
      reservationVehicleId?: string | null,
      reservationVehicles?:  Array< {
        __typename: "ReservationVehicle",
        id?: string | null,
        price?: number | null,
        tax?: number | null,
        name?: string | null,
        registrationPlate?: string | null,
        deductible?: number | null,
        includedDistance?: string | null,
        powerType?: string | null,
        mileage?: string | null,
        startMileage?: string | null,
        endMileage?: string | null,
        extraInfo?: string | null,
        damages?: string | null,
        hideExport?: boolean | null,
        createdAt?: string | null,
        addedAt?: string | null,
        categoryName?: string | null,
      } | null > | null,
      wildCardVehicles?:  Array< {
        __typename: "WildCardVehicle",
        name?: string | null,
        price?: number | null,
        tax?: number | null,
        categoryId?: string | null,
        quantity?: number | null,
        deductible?: number | null,
      } | null > | null,
      createdAt: string,
      updatedAt: string,
      startTime?: string | null,
      returnTime?: string | null,
      endTime?: string | null,
      name?: string | null,
      address?: string | null,
      postalCode?: string | null,
      city?: string | null,
      phone?: string | null,
      email?: string | null,
      billingRef?: string | null,
      customerNotes?: string | null,
      ssn?: string | null,
      rentalNotes?: string | null,
      internalNotes?: string | null,
      externalNotes?: string | null,
      outOfCommission?: boolean | null,
      vehiclePrice?: number | null,
      additionalServices?:  Array< {
        __typename: "AdditionalServiceReservation",
        key: string,
        price: number,
        details?: string | null,
        quantity?: number | null,
        type?: ServiceType | null,
        deductibleValue?: number | null,
        groupFields?: Array< string | null > | null,
        tax?: number | null,
        enableNotification?: boolean | null,
        description?: string | null,
        category?: string | null,
      } > | null,
      driverSSN?: string | null,
      differentDriver?: boolean | null,
      driverName?: string | null,
      driverAddress?: string | null,
      driverPostalCode?: string | null,
      driverCity?: string | null,
      driverPhone?: string | null,
      driverEmail?: string | null,
      additionalDriver?: boolean | null,
      additionalDriverName?: string | null,
      additionalDriverPhone?: string | null,
      additionalDriverSSN?: string | null,
      billPayment?: boolean | null,
      isCompany?: boolean | null,
      carStatus?: RentalStatus | null,
      rentalStatus?: RentalStatus | null,
      innovoiceStatus?: ReservationInnovoiceStatus | null,
      companyName?: string | null,
      companyBusinessId?: string | null,
      companyCity?: string | null,
      companyPostalCode?: string | null,
      companyBillingRef?: string | null,
      companyBillingAddress?: string | null,
      companyEBillingOperator?: string | null,
      companyEBillingAddress?: string | null,
      paymentToken?: string | null,
      npsStatus?: NpsStatus | null,
      paymentStatus?: PaymentStatus | null,
      paymentData?:  {
        __typename: "PaymentData",
        paymentTotal?: number | null,
        paymentTodo?: number | null,
        depositPending?: boolean | null,
        deposit?: number | null,
      } | null,
      signStatus?: SignStatus | null,
      signDocumentId?: string | null,
      signerName?: string | null,
      reviewStatus?: ReviewStatus | null,
      authStatus?: AuthStatus | null,
      authTime?: string | null,
      completeBefore?: string | null,
      mustSign?: boolean | null,
      livionData?:  Array< {
        __typename: "LivionData",
        id?: string | null,
        pincode?: string | null,
        keyId?: string | null,
        name?: string | null,
        registrationPlate?: string | null,
      } | null > | null,
      tilakoneData?:  {
        __typename: "TilakoneData",
        access_id?: string | null,
        begin_timestamp?: string | null,
        end_timestamp?: string | null,
        pincode?: string | null,
      } | null,
      voucherData?:  {
        __typename: "VoucherData",
        name: string,
        code: string,
        amount: number,
      } | null,
      customerCarRegistrationPlate?: string | null,
      insuranceData?:  {
        __typename: "InsuranceData",
        customerContact?: boolean | null,
        urgent?: boolean | null,
        customerCarModel?: string | null,
        customerCarMaker?: string | null,
        product?: string | null,
        coveragePercentage?: number | null,
        compensatableDays?: string | null,
        billingPermitCode?: string | null,
        coverageDuration?: number | null,
        providerCompany?: string | null,
        providerBusinessId?: string | null,
        claimAdjusterName?: string | null,
        claimAdjusterEmail?: string | null,
        receipt?: boolean | null,
        replacementVehicleClass?: string | null,
        claimId?: string | null,
        vatDeductible?: boolean | null,
        deliveryCosts?: boolean | null,
        returnCosts?: boolean | null,
      } | null,
      externalData?:  {
        __typename: "ExternalData",
        orderId?: string | null,
        orderReference?: string | null,
        serviceAgreementName?: string | null,
        operatorName?: string | null,
        orderInfo?: string | null,
        billingPayer?: string | null,
        billingDescription?: string | null,
      } | null,
      enableRegistrationPlate?: boolean | null,
      disablePricesInPrintouts?: boolean | null,
      hanselData?:  {
        __typename: "HanselData",
        contractNro?: string | null,
        customerInvoiceId?: string | null,
        customerInvoiceDate?: string | null,
        hanselAgent?: string | null,
        hanselClass?: string | null,
        customerId?: string | null,
        subContractorNumber?: string | null,
        subContractorName?: string | null,
        suplierProductId?: string | null,
        suplierProductName?: string | null,
        quantity?: string | null,
        price?: string | null,
        totalPrice?: string | null,
        priceNoVAT?: string | null,
        additionalInfo1?: string | null,
        additionalInfo2?: string | null,
        additionalInfo3?: string | null,
        desc?: string | null,
      } | null,
      invoiceIds?: Array< string > | null,
      consolidatedInvoiceIds?: Array< string > | null,
      channel?: ReservationChannel | null,
      originURL?: string | null,
      type?: ReservationType | null,
      status?: string | null,
      reservationStatusInfo?:  {
        __typename: "ReservationStatusInfo",
        deliveryTimestamp?: string | null,
        deliveryAddress?: string | null,
        deliveryZipcode?: string | null,
        deliveryCity?: string | null,
        deliveryCountry?: string | null,
        deliveryDescription?: string | null,
        returnTimestamp?: string | null,
        returnAddress?: string | null,
        returnZipcode?: string | null,
        returnCity?: string | null,
        returnCountry?: string | null,
        returnDescription?: string | null,
        cancelReason?: string | null,
      } | null,
      initialCategory?:  {
        __typename: "CategoryInfo",
        id?: string | null,
        name?: string | null,
        hanselClass?: string | null,
      } | null,
      initialClassInfo?:  {
        __typename: "InitialClassInfo",
        categoryId?: string | null,
        categoryName?: string | null,
        hanselClass?: string | null,
      } | null,
      orgInvoiceCustomer?:  {
        __typename: "OrganizationInvoiceCustomer",
        name?: string | null,
        businessId?: string | null,
      } | null,
      pricingCategoryId?: string | null,
      customImportDate?: string | null,
      vehicle?:  {
        __typename: "Vehicle",
        id: string,
        group?: string | null,
        organizationId?: string | null,
        orgBusinessId?: string | null,
        companyId: string,
        name: string,
        registrationPlate?: string | null,
        damages?: string | null,
        image?: string | null,
        powerType?: string | null,
        deductible?: number | null,
        includedDistance?: string | null,
        mileage?: string | null,
        orderNumber?: number | null,
        vehicleCategoryId?: string | null,
        useCategoryDefaults?: boolean | null,
        useStartReturnTimes?: boolean | null,
        hideExport?: boolean | null,
        extraInfo?: string | null,
        extraInfoT?: string | null,
        extraToConfirmation?: string | null,
        extraToConfirmationT?: string | null,
        livionKeyId?: string | null,
        phoneNumber?: string | null,
        importData?: string | null,
        disableOnline?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      invoices?:  {
        __typename: "ModelInvoiceConnection",
        nextToken?: string | null,
      } | null,
      paymentEvents?:  {
        __typename: "ModelPaymentEventConnection",
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListReservationsByNpsStatusQueryVariables = {
  npsStatus?: NpsStatus | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelReservationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListReservationsByNpsStatusQuery = {
  listReservationsByNpsStatus?:  {
    __typename: "ModelReservationConnection",
    items:  Array< {
      __typename: "Reservation",
      id: string,
      shortId?: string | null,
      language?: Language | null,
      group?: string | null,
      organizationId?: string | null,
      orgBusinessId?: string | null,
      orgExternalBusinessId?: string | null,
      companyId?: string | null,
      returnCompanyId?: string | null,
      startLocation?: string | null,
      returnLocation?: string | null,
      startLocationID?: string | null,
      returnLocationID?: string | null,
      reviewId?: string | null,
      customerBrokerId?: string | null,
      customerCompanyId?: string | null,
      customerPersonalId?: string | null,
      reservationVehicleId?: string | null,
      reservationVehicles?:  Array< {
        __typename: "ReservationVehicle",
        id?: string | null,
        price?: number | null,
        tax?: number | null,
        name?: string | null,
        registrationPlate?: string | null,
        deductible?: number | null,
        includedDistance?: string | null,
        powerType?: string | null,
        mileage?: string | null,
        startMileage?: string | null,
        endMileage?: string | null,
        extraInfo?: string | null,
        damages?: string | null,
        hideExport?: boolean | null,
        createdAt?: string | null,
        addedAt?: string | null,
        categoryName?: string | null,
      } | null > | null,
      wildCardVehicles?:  Array< {
        __typename: "WildCardVehicle",
        name?: string | null,
        price?: number | null,
        tax?: number | null,
        categoryId?: string | null,
        quantity?: number | null,
        deductible?: number | null,
      } | null > | null,
      createdAt: string,
      updatedAt: string,
      startTime?: string | null,
      returnTime?: string | null,
      endTime?: string | null,
      name?: string | null,
      address?: string | null,
      postalCode?: string | null,
      city?: string | null,
      phone?: string | null,
      email?: string | null,
      billingRef?: string | null,
      customerNotes?: string | null,
      ssn?: string | null,
      rentalNotes?: string | null,
      internalNotes?: string | null,
      externalNotes?: string | null,
      outOfCommission?: boolean | null,
      vehiclePrice?: number | null,
      additionalServices?:  Array< {
        __typename: "AdditionalServiceReservation",
        key: string,
        price: number,
        details?: string | null,
        quantity?: number | null,
        type?: ServiceType | null,
        deductibleValue?: number | null,
        groupFields?: Array< string | null > | null,
        tax?: number | null,
        enableNotification?: boolean | null,
        description?: string | null,
        category?: string | null,
      } > | null,
      driverSSN?: string | null,
      differentDriver?: boolean | null,
      driverName?: string | null,
      driverAddress?: string | null,
      driverPostalCode?: string | null,
      driverCity?: string | null,
      driverPhone?: string | null,
      driverEmail?: string | null,
      additionalDriver?: boolean | null,
      additionalDriverName?: string | null,
      additionalDriverPhone?: string | null,
      additionalDriverSSN?: string | null,
      billPayment?: boolean | null,
      isCompany?: boolean | null,
      carStatus?: RentalStatus | null,
      rentalStatus?: RentalStatus | null,
      innovoiceStatus?: ReservationInnovoiceStatus | null,
      companyName?: string | null,
      companyBusinessId?: string | null,
      companyCity?: string | null,
      companyPostalCode?: string | null,
      companyBillingRef?: string | null,
      companyBillingAddress?: string | null,
      companyEBillingOperator?: string | null,
      companyEBillingAddress?: string | null,
      paymentToken?: string | null,
      npsStatus?: NpsStatus | null,
      paymentStatus?: PaymentStatus | null,
      paymentData?:  {
        __typename: "PaymentData",
        paymentTotal?: number | null,
        paymentTodo?: number | null,
        depositPending?: boolean | null,
        deposit?: number | null,
      } | null,
      signStatus?: SignStatus | null,
      signDocumentId?: string | null,
      signerName?: string | null,
      reviewStatus?: ReviewStatus | null,
      authStatus?: AuthStatus | null,
      authTime?: string | null,
      completeBefore?: string | null,
      mustSign?: boolean | null,
      livionData?:  Array< {
        __typename: "LivionData",
        id?: string | null,
        pincode?: string | null,
        keyId?: string | null,
        name?: string | null,
        registrationPlate?: string | null,
      } | null > | null,
      tilakoneData?:  {
        __typename: "TilakoneData",
        access_id?: string | null,
        begin_timestamp?: string | null,
        end_timestamp?: string | null,
        pincode?: string | null,
      } | null,
      voucherData?:  {
        __typename: "VoucherData",
        name: string,
        code: string,
        amount: number,
      } | null,
      customerCarRegistrationPlate?: string | null,
      insuranceData?:  {
        __typename: "InsuranceData",
        customerContact?: boolean | null,
        urgent?: boolean | null,
        customerCarModel?: string | null,
        customerCarMaker?: string | null,
        product?: string | null,
        coveragePercentage?: number | null,
        compensatableDays?: string | null,
        billingPermitCode?: string | null,
        coverageDuration?: number | null,
        providerCompany?: string | null,
        providerBusinessId?: string | null,
        claimAdjusterName?: string | null,
        claimAdjusterEmail?: string | null,
        receipt?: boolean | null,
        replacementVehicleClass?: string | null,
        claimId?: string | null,
        vatDeductible?: boolean | null,
        deliveryCosts?: boolean | null,
        returnCosts?: boolean | null,
      } | null,
      externalData?:  {
        __typename: "ExternalData",
        orderId?: string | null,
        orderReference?: string | null,
        serviceAgreementName?: string | null,
        operatorName?: string | null,
        orderInfo?: string | null,
        billingPayer?: string | null,
        billingDescription?: string | null,
      } | null,
      enableRegistrationPlate?: boolean | null,
      disablePricesInPrintouts?: boolean | null,
      hanselData?:  {
        __typename: "HanselData",
        contractNro?: string | null,
        customerInvoiceId?: string | null,
        customerInvoiceDate?: string | null,
        hanselAgent?: string | null,
        hanselClass?: string | null,
        customerId?: string | null,
        subContractorNumber?: string | null,
        subContractorName?: string | null,
        suplierProductId?: string | null,
        suplierProductName?: string | null,
        quantity?: string | null,
        price?: string | null,
        totalPrice?: string | null,
        priceNoVAT?: string | null,
        additionalInfo1?: string | null,
        additionalInfo2?: string | null,
        additionalInfo3?: string | null,
        desc?: string | null,
      } | null,
      invoiceIds?: Array< string > | null,
      consolidatedInvoiceIds?: Array< string > | null,
      channel?: ReservationChannel | null,
      originURL?: string | null,
      type?: ReservationType | null,
      status?: string | null,
      reservationStatusInfo?:  {
        __typename: "ReservationStatusInfo",
        deliveryTimestamp?: string | null,
        deliveryAddress?: string | null,
        deliveryZipcode?: string | null,
        deliveryCity?: string | null,
        deliveryCountry?: string | null,
        deliveryDescription?: string | null,
        returnTimestamp?: string | null,
        returnAddress?: string | null,
        returnZipcode?: string | null,
        returnCity?: string | null,
        returnCountry?: string | null,
        returnDescription?: string | null,
        cancelReason?: string | null,
      } | null,
      initialCategory?:  {
        __typename: "CategoryInfo",
        id?: string | null,
        name?: string | null,
        hanselClass?: string | null,
      } | null,
      initialClassInfo?:  {
        __typename: "InitialClassInfo",
        categoryId?: string | null,
        categoryName?: string | null,
        hanselClass?: string | null,
      } | null,
      orgInvoiceCustomer?:  {
        __typename: "OrganizationInvoiceCustomer",
        name?: string | null,
        businessId?: string | null,
      } | null,
      pricingCategoryId?: string | null,
      customImportDate?: string | null,
      vehicle?:  {
        __typename: "Vehicle",
        id: string,
        group?: string | null,
        organizationId?: string | null,
        orgBusinessId?: string | null,
        companyId: string,
        name: string,
        registrationPlate?: string | null,
        damages?: string | null,
        image?: string | null,
        powerType?: string | null,
        deductible?: number | null,
        includedDistance?: string | null,
        mileage?: string | null,
        orderNumber?: number | null,
        vehicleCategoryId?: string | null,
        useCategoryDefaults?: boolean | null,
        useStartReturnTimes?: boolean | null,
        hideExport?: boolean | null,
        extraInfo?: string | null,
        extraInfoT?: string | null,
        extraToConfirmation?: string | null,
        extraToConfirmationT?: string | null,
        livionKeyId?: string | null,
        phoneNumber?: string | null,
        importData?: string | null,
        disableOnline?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      invoices?:  {
        __typename: "ModelInvoiceConnection",
        nextToken?: string | null,
      } | null,
      paymentEvents?:  {
        __typename: "ModelPaymentEventConnection",
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListReservationsByStatusQueryVariables = {
  status?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelReservationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListReservationsByStatusQuery = {
  listReservationsByStatus?:  {
    __typename: "ModelReservationConnection",
    items:  Array< {
      __typename: "Reservation",
      id: string,
      shortId?: string | null,
      language?: Language | null,
      group?: string | null,
      organizationId?: string | null,
      orgBusinessId?: string | null,
      orgExternalBusinessId?: string | null,
      companyId?: string | null,
      returnCompanyId?: string | null,
      startLocation?: string | null,
      returnLocation?: string | null,
      startLocationID?: string | null,
      returnLocationID?: string | null,
      reviewId?: string | null,
      customerBrokerId?: string | null,
      customerCompanyId?: string | null,
      customerPersonalId?: string | null,
      reservationVehicleId?: string | null,
      reservationVehicles?:  Array< {
        __typename: "ReservationVehicle",
        id?: string | null,
        price?: number | null,
        tax?: number | null,
        name?: string | null,
        registrationPlate?: string | null,
        deductible?: number | null,
        includedDistance?: string | null,
        powerType?: string | null,
        mileage?: string | null,
        startMileage?: string | null,
        endMileage?: string | null,
        extraInfo?: string | null,
        damages?: string | null,
        hideExport?: boolean | null,
        createdAt?: string | null,
        addedAt?: string | null,
        categoryName?: string | null,
      } | null > | null,
      wildCardVehicles?:  Array< {
        __typename: "WildCardVehicle",
        name?: string | null,
        price?: number | null,
        tax?: number | null,
        categoryId?: string | null,
        quantity?: number | null,
        deductible?: number | null,
      } | null > | null,
      createdAt: string,
      updatedAt: string,
      startTime?: string | null,
      returnTime?: string | null,
      endTime?: string | null,
      name?: string | null,
      address?: string | null,
      postalCode?: string | null,
      city?: string | null,
      phone?: string | null,
      email?: string | null,
      billingRef?: string | null,
      customerNotes?: string | null,
      ssn?: string | null,
      rentalNotes?: string | null,
      internalNotes?: string | null,
      externalNotes?: string | null,
      outOfCommission?: boolean | null,
      vehiclePrice?: number | null,
      additionalServices?:  Array< {
        __typename: "AdditionalServiceReservation",
        key: string,
        price: number,
        details?: string | null,
        quantity?: number | null,
        type?: ServiceType | null,
        deductibleValue?: number | null,
        groupFields?: Array< string | null > | null,
        tax?: number | null,
        enableNotification?: boolean | null,
        description?: string | null,
        category?: string | null,
      } > | null,
      driverSSN?: string | null,
      differentDriver?: boolean | null,
      driverName?: string | null,
      driverAddress?: string | null,
      driverPostalCode?: string | null,
      driverCity?: string | null,
      driverPhone?: string | null,
      driverEmail?: string | null,
      additionalDriver?: boolean | null,
      additionalDriverName?: string | null,
      additionalDriverPhone?: string | null,
      additionalDriverSSN?: string | null,
      billPayment?: boolean | null,
      isCompany?: boolean | null,
      carStatus?: RentalStatus | null,
      rentalStatus?: RentalStatus | null,
      innovoiceStatus?: ReservationInnovoiceStatus | null,
      companyName?: string | null,
      companyBusinessId?: string | null,
      companyCity?: string | null,
      companyPostalCode?: string | null,
      companyBillingRef?: string | null,
      companyBillingAddress?: string | null,
      companyEBillingOperator?: string | null,
      companyEBillingAddress?: string | null,
      paymentToken?: string | null,
      npsStatus?: NpsStatus | null,
      paymentStatus?: PaymentStatus | null,
      paymentData?:  {
        __typename: "PaymentData",
        paymentTotal?: number | null,
        paymentTodo?: number | null,
        depositPending?: boolean | null,
        deposit?: number | null,
      } | null,
      signStatus?: SignStatus | null,
      signDocumentId?: string | null,
      signerName?: string | null,
      reviewStatus?: ReviewStatus | null,
      authStatus?: AuthStatus | null,
      authTime?: string | null,
      completeBefore?: string | null,
      mustSign?: boolean | null,
      livionData?:  Array< {
        __typename: "LivionData",
        id?: string | null,
        pincode?: string | null,
        keyId?: string | null,
        name?: string | null,
        registrationPlate?: string | null,
      } | null > | null,
      tilakoneData?:  {
        __typename: "TilakoneData",
        access_id?: string | null,
        begin_timestamp?: string | null,
        end_timestamp?: string | null,
        pincode?: string | null,
      } | null,
      voucherData?:  {
        __typename: "VoucherData",
        name: string,
        code: string,
        amount: number,
      } | null,
      customerCarRegistrationPlate?: string | null,
      insuranceData?:  {
        __typename: "InsuranceData",
        customerContact?: boolean | null,
        urgent?: boolean | null,
        customerCarModel?: string | null,
        customerCarMaker?: string | null,
        product?: string | null,
        coveragePercentage?: number | null,
        compensatableDays?: string | null,
        billingPermitCode?: string | null,
        coverageDuration?: number | null,
        providerCompany?: string | null,
        providerBusinessId?: string | null,
        claimAdjusterName?: string | null,
        claimAdjusterEmail?: string | null,
        receipt?: boolean | null,
        replacementVehicleClass?: string | null,
        claimId?: string | null,
        vatDeductible?: boolean | null,
        deliveryCosts?: boolean | null,
        returnCosts?: boolean | null,
      } | null,
      externalData?:  {
        __typename: "ExternalData",
        orderId?: string | null,
        orderReference?: string | null,
        serviceAgreementName?: string | null,
        operatorName?: string | null,
        orderInfo?: string | null,
        billingPayer?: string | null,
        billingDescription?: string | null,
      } | null,
      enableRegistrationPlate?: boolean | null,
      disablePricesInPrintouts?: boolean | null,
      hanselData?:  {
        __typename: "HanselData",
        contractNro?: string | null,
        customerInvoiceId?: string | null,
        customerInvoiceDate?: string | null,
        hanselAgent?: string | null,
        hanselClass?: string | null,
        customerId?: string | null,
        subContractorNumber?: string | null,
        subContractorName?: string | null,
        suplierProductId?: string | null,
        suplierProductName?: string | null,
        quantity?: string | null,
        price?: string | null,
        totalPrice?: string | null,
        priceNoVAT?: string | null,
        additionalInfo1?: string | null,
        additionalInfo2?: string | null,
        additionalInfo3?: string | null,
        desc?: string | null,
      } | null,
      invoiceIds?: Array< string > | null,
      consolidatedInvoiceIds?: Array< string > | null,
      channel?: ReservationChannel | null,
      originURL?: string | null,
      type?: ReservationType | null,
      status?: string | null,
      reservationStatusInfo?:  {
        __typename: "ReservationStatusInfo",
        deliveryTimestamp?: string | null,
        deliveryAddress?: string | null,
        deliveryZipcode?: string | null,
        deliveryCity?: string | null,
        deliveryCountry?: string | null,
        deliveryDescription?: string | null,
        returnTimestamp?: string | null,
        returnAddress?: string | null,
        returnZipcode?: string | null,
        returnCity?: string | null,
        returnCountry?: string | null,
        returnDescription?: string | null,
        cancelReason?: string | null,
      } | null,
      initialCategory?:  {
        __typename: "CategoryInfo",
        id?: string | null,
        name?: string | null,
        hanselClass?: string | null,
      } | null,
      initialClassInfo?:  {
        __typename: "InitialClassInfo",
        categoryId?: string | null,
        categoryName?: string | null,
        hanselClass?: string | null,
      } | null,
      orgInvoiceCustomer?:  {
        __typename: "OrganizationInvoiceCustomer",
        name?: string | null,
        businessId?: string | null,
      } | null,
      pricingCategoryId?: string | null,
      customImportDate?: string | null,
      vehicle?:  {
        __typename: "Vehicle",
        id: string,
        group?: string | null,
        organizationId?: string | null,
        orgBusinessId?: string | null,
        companyId: string,
        name: string,
        registrationPlate?: string | null,
        damages?: string | null,
        image?: string | null,
        powerType?: string | null,
        deductible?: number | null,
        includedDistance?: string | null,
        mileage?: string | null,
        orderNumber?: number | null,
        vehicleCategoryId?: string | null,
        useCategoryDefaults?: boolean | null,
        useStartReturnTimes?: boolean | null,
        hideExport?: boolean | null,
        extraInfo?: string | null,
        extraInfoT?: string | null,
        extraToConfirmation?: string | null,
        extraToConfirmationT?: string | null,
        livionKeyId?: string | null,
        phoneNumber?: string | null,
        importData?: string | null,
        disableOnline?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      invoices?:  {
        __typename: "ModelInvoiceConnection",
        nextToken?: string | null,
      } | null,
      paymentEvents?:  {
        __typename: "ModelPaymentEventConnection",
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListReservationsByStatusByOrgBusinessIdQueryVariables = {
  status?: string | null,
  orgBusinessId?: ModelIDKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelReservationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListReservationsByStatusByOrgBusinessIdQuery = {
  listReservationsByStatusByOrgBusinessId?:  {
    __typename: "ModelReservationConnection",
    items:  Array< {
      __typename: "Reservation",
      id: string,
      shortId?: string | null,
      language?: Language | null,
      group?: string | null,
      organizationId?: string | null,
      orgBusinessId?: string | null,
      orgExternalBusinessId?: string | null,
      companyId?: string | null,
      returnCompanyId?: string | null,
      startLocation?: string | null,
      returnLocation?: string | null,
      startLocationID?: string | null,
      returnLocationID?: string | null,
      reviewId?: string | null,
      customerBrokerId?: string | null,
      customerCompanyId?: string | null,
      customerPersonalId?: string | null,
      reservationVehicleId?: string | null,
      reservationVehicles?:  Array< {
        __typename: "ReservationVehicle",
        id?: string | null,
        price?: number | null,
        tax?: number | null,
        name?: string | null,
        registrationPlate?: string | null,
        deductible?: number | null,
        includedDistance?: string | null,
        powerType?: string | null,
        mileage?: string | null,
        startMileage?: string | null,
        endMileage?: string | null,
        extraInfo?: string | null,
        damages?: string | null,
        hideExport?: boolean | null,
        createdAt?: string | null,
        addedAt?: string | null,
        categoryName?: string | null,
      } | null > | null,
      wildCardVehicles?:  Array< {
        __typename: "WildCardVehicle",
        name?: string | null,
        price?: number | null,
        tax?: number | null,
        categoryId?: string | null,
        quantity?: number | null,
        deductible?: number | null,
      } | null > | null,
      createdAt: string,
      updatedAt: string,
      startTime?: string | null,
      returnTime?: string | null,
      endTime?: string | null,
      name?: string | null,
      address?: string | null,
      postalCode?: string | null,
      city?: string | null,
      phone?: string | null,
      email?: string | null,
      billingRef?: string | null,
      customerNotes?: string | null,
      ssn?: string | null,
      rentalNotes?: string | null,
      internalNotes?: string | null,
      externalNotes?: string | null,
      outOfCommission?: boolean | null,
      vehiclePrice?: number | null,
      additionalServices?:  Array< {
        __typename: "AdditionalServiceReservation",
        key: string,
        price: number,
        details?: string | null,
        quantity?: number | null,
        type?: ServiceType | null,
        deductibleValue?: number | null,
        groupFields?: Array< string | null > | null,
        tax?: number | null,
        enableNotification?: boolean | null,
        description?: string | null,
        category?: string | null,
      } > | null,
      driverSSN?: string | null,
      differentDriver?: boolean | null,
      driverName?: string | null,
      driverAddress?: string | null,
      driverPostalCode?: string | null,
      driverCity?: string | null,
      driverPhone?: string | null,
      driverEmail?: string | null,
      additionalDriver?: boolean | null,
      additionalDriverName?: string | null,
      additionalDriverPhone?: string | null,
      additionalDriverSSN?: string | null,
      billPayment?: boolean | null,
      isCompany?: boolean | null,
      carStatus?: RentalStatus | null,
      rentalStatus?: RentalStatus | null,
      innovoiceStatus?: ReservationInnovoiceStatus | null,
      companyName?: string | null,
      companyBusinessId?: string | null,
      companyCity?: string | null,
      companyPostalCode?: string | null,
      companyBillingRef?: string | null,
      companyBillingAddress?: string | null,
      companyEBillingOperator?: string | null,
      companyEBillingAddress?: string | null,
      paymentToken?: string | null,
      npsStatus?: NpsStatus | null,
      paymentStatus?: PaymentStatus | null,
      paymentData?:  {
        __typename: "PaymentData",
        paymentTotal?: number | null,
        paymentTodo?: number | null,
        depositPending?: boolean | null,
        deposit?: number | null,
      } | null,
      signStatus?: SignStatus | null,
      signDocumentId?: string | null,
      signerName?: string | null,
      reviewStatus?: ReviewStatus | null,
      authStatus?: AuthStatus | null,
      authTime?: string | null,
      completeBefore?: string | null,
      mustSign?: boolean | null,
      livionData?:  Array< {
        __typename: "LivionData",
        id?: string | null,
        pincode?: string | null,
        keyId?: string | null,
        name?: string | null,
        registrationPlate?: string | null,
      } | null > | null,
      tilakoneData?:  {
        __typename: "TilakoneData",
        access_id?: string | null,
        begin_timestamp?: string | null,
        end_timestamp?: string | null,
        pincode?: string | null,
      } | null,
      voucherData?:  {
        __typename: "VoucherData",
        name: string,
        code: string,
        amount: number,
      } | null,
      customerCarRegistrationPlate?: string | null,
      insuranceData?:  {
        __typename: "InsuranceData",
        customerContact?: boolean | null,
        urgent?: boolean | null,
        customerCarModel?: string | null,
        customerCarMaker?: string | null,
        product?: string | null,
        coveragePercentage?: number | null,
        compensatableDays?: string | null,
        billingPermitCode?: string | null,
        coverageDuration?: number | null,
        providerCompany?: string | null,
        providerBusinessId?: string | null,
        claimAdjusterName?: string | null,
        claimAdjusterEmail?: string | null,
        receipt?: boolean | null,
        replacementVehicleClass?: string | null,
        claimId?: string | null,
        vatDeductible?: boolean | null,
        deliveryCosts?: boolean | null,
        returnCosts?: boolean | null,
      } | null,
      externalData?:  {
        __typename: "ExternalData",
        orderId?: string | null,
        orderReference?: string | null,
        serviceAgreementName?: string | null,
        operatorName?: string | null,
        orderInfo?: string | null,
        billingPayer?: string | null,
        billingDescription?: string | null,
      } | null,
      enableRegistrationPlate?: boolean | null,
      disablePricesInPrintouts?: boolean | null,
      hanselData?:  {
        __typename: "HanselData",
        contractNro?: string | null,
        customerInvoiceId?: string | null,
        customerInvoiceDate?: string | null,
        hanselAgent?: string | null,
        hanselClass?: string | null,
        customerId?: string | null,
        subContractorNumber?: string | null,
        subContractorName?: string | null,
        suplierProductId?: string | null,
        suplierProductName?: string | null,
        quantity?: string | null,
        price?: string | null,
        totalPrice?: string | null,
        priceNoVAT?: string | null,
        additionalInfo1?: string | null,
        additionalInfo2?: string | null,
        additionalInfo3?: string | null,
        desc?: string | null,
      } | null,
      invoiceIds?: Array< string > | null,
      consolidatedInvoiceIds?: Array< string > | null,
      channel?: ReservationChannel | null,
      originURL?: string | null,
      type?: ReservationType | null,
      status?: string | null,
      reservationStatusInfo?:  {
        __typename: "ReservationStatusInfo",
        deliveryTimestamp?: string | null,
        deliveryAddress?: string | null,
        deliveryZipcode?: string | null,
        deliveryCity?: string | null,
        deliveryCountry?: string | null,
        deliveryDescription?: string | null,
        returnTimestamp?: string | null,
        returnAddress?: string | null,
        returnZipcode?: string | null,
        returnCity?: string | null,
        returnCountry?: string | null,
        returnDescription?: string | null,
        cancelReason?: string | null,
      } | null,
      initialCategory?:  {
        __typename: "CategoryInfo",
        id?: string | null,
        name?: string | null,
        hanselClass?: string | null,
      } | null,
      initialClassInfo?:  {
        __typename: "InitialClassInfo",
        categoryId?: string | null,
        categoryName?: string | null,
        hanselClass?: string | null,
      } | null,
      orgInvoiceCustomer?:  {
        __typename: "OrganizationInvoiceCustomer",
        name?: string | null,
        businessId?: string | null,
      } | null,
      pricingCategoryId?: string | null,
      customImportDate?: string | null,
      vehicle?:  {
        __typename: "Vehicle",
        id: string,
        group?: string | null,
        organizationId?: string | null,
        orgBusinessId?: string | null,
        companyId: string,
        name: string,
        registrationPlate?: string | null,
        damages?: string | null,
        image?: string | null,
        powerType?: string | null,
        deductible?: number | null,
        includedDistance?: string | null,
        mileage?: string | null,
        orderNumber?: number | null,
        vehicleCategoryId?: string | null,
        useCategoryDefaults?: boolean | null,
        useStartReturnTimes?: boolean | null,
        hideExport?: boolean | null,
        extraInfo?: string | null,
        extraInfoT?: string | null,
        extraToConfirmation?: string | null,
        extraToConfirmationT?: string | null,
        livionKeyId?: string | null,
        phoneNumber?: string | null,
        importData?: string | null,
        disableOnline?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      invoices?:  {
        __typename: "ModelInvoiceConnection",
        nextToken?: string | null,
      } | null,
      paymentEvents?:  {
        __typename: "ModelPaymentEventConnection",
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListReservationsByStatusByOrgExternalBusinessIdQueryVariables = {
  status?: string | null,
  orgExternalBusinessId?: ModelIDKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelReservationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListReservationsByStatusByOrgExternalBusinessIdQuery = {
  listReservationsByStatusByOrgExternalBusinessId?:  {
    __typename: "ModelReservationConnection",
    items:  Array< {
      __typename: "Reservation",
      id: string,
      shortId?: string | null,
      language?: Language | null,
      group?: string | null,
      organizationId?: string | null,
      orgBusinessId?: string | null,
      orgExternalBusinessId?: string | null,
      companyId?: string | null,
      returnCompanyId?: string | null,
      startLocation?: string | null,
      returnLocation?: string | null,
      startLocationID?: string | null,
      returnLocationID?: string | null,
      reviewId?: string | null,
      customerBrokerId?: string | null,
      customerCompanyId?: string | null,
      customerPersonalId?: string | null,
      reservationVehicleId?: string | null,
      reservationVehicles?:  Array< {
        __typename: "ReservationVehicle",
        id?: string | null,
        price?: number | null,
        tax?: number | null,
        name?: string | null,
        registrationPlate?: string | null,
        deductible?: number | null,
        includedDistance?: string | null,
        powerType?: string | null,
        mileage?: string | null,
        startMileage?: string | null,
        endMileage?: string | null,
        extraInfo?: string | null,
        damages?: string | null,
        hideExport?: boolean | null,
        createdAt?: string | null,
        addedAt?: string | null,
        categoryName?: string | null,
      } | null > | null,
      wildCardVehicles?:  Array< {
        __typename: "WildCardVehicle",
        name?: string | null,
        price?: number | null,
        tax?: number | null,
        categoryId?: string | null,
        quantity?: number | null,
        deductible?: number | null,
      } | null > | null,
      createdAt: string,
      updatedAt: string,
      startTime?: string | null,
      returnTime?: string | null,
      endTime?: string | null,
      name?: string | null,
      address?: string | null,
      postalCode?: string | null,
      city?: string | null,
      phone?: string | null,
      email?: string | null,
      billingRef?: string | null,
      customerNotes?: string | null,
      ssn?: string | null,
      rentalNotes?: string | null,
      internalNotes?: string | null,
      externalNotes?: string | null,
      outOfCommission?: boolean | null,
      vehiclePrice?: number | null,
      additionalServices?:  Array< {
        __typename: "AdditionalServiceReservation",
        key: string,
        price: number,
        details?: string | null,
        quantity?: number | null,
        type?: ServiceType | null,
        deductibleValue?: number | null,
        groupFields?: Array< string | null > | null,
        tax?: number | null,
        enableNotification?: boolean | null,
        description?: string | null,
        category?: string | null,
      } > | null,
      driverSSN?: string | null,
      differentDriver?: boolean | null,
      driverName?: string | null,
      driverAddress?: string | null,
      driverPostalCode?: string | null,
      driverCity?: string | null,
      driverPhone?: string | null,
      driverEmail?: string | null,
      additionalDriver?: boolean | null,
      additionalDriverName?: string | null,
      additionalDriverPhone?: string | null,
      additionalDriverSSN?: string | null,
      billPayment?: boolean | null,
      isCompany?: boolean | null,
      carStatus?: RentalStatus | null,
      rentalStatus?: RentalStatus | null,
      innovoiceStatus?: ReservationInnovoiceStatus | null,
      companyName?: string | null,
      companyBusinessId?: string | null,
      companyCity?: string | null,
      companyPostalCode?: string | null,
      companyBillingRef?: string | null,
      companyBillingAddress?: string | null,
      companyEBillingOperator?: string | null,
      companyEBillingAddress?: string | null,
      paymentToken?: string | null,
      npsStatus?: NpsStatus | null,
      paymentStatus?: PaymentStatus | null,
      paymentData?:  {
        __typename: "PaymentData",
        paymentTotal?: number | null,
        paymentTodo?: number | null,
        depositPending?: boolean | null,
        deposit?: number | null,
      } | null,
      signStatus?: SignStatus | null,
      signDocumentId?: string | null,
      signerName?: string | null,
      reviewStatus?: ReviewStatus | null,
      authStatus?: AuthStatus | null,
      authTime?: string | null,
      completeBefore?: string | null,
      mustSign?: boolean | null,
      livionData?:  Array< {
        __typename: "LivionData",
        id?: string | null,
        pincode?: string | null,
        keyId?: string | null,
        name?: string | null,
        registrationPlate?: string | null,
      } | null > | null,
      tilakoneData?:  {
        __typename: "TilakoneData",
        access_id?: string | null,
        begin_timestamp?: string | null,
        end_timestamp?: string | null,
        pincode?: string | null,
      } | null,
      voucherData?:  {
        __typename: "VoucherData",
        name: string,
        code: string,
        amount: number,
      } | null,
      customerCarRegistrationPlate?: string | null,
      insuranceData?:  {
        __typename: "InsuranceData",
        customerContact?: boolean | null,
        urgent?: boolean | null,
        customerCarModel?: string | null,
        customerCarMaker?: string | null,
        product?: string | null,
        coveragePercentage?: number | null,
        compensatableDays?: string | null,
        billingPermitCode?: string | null,
        coverageDuration?: number | null,
        providerCompany?: string | null,
        providerBusinessId?: string | null,
        claimAdjusterName?: string | null,
        claimAdjusterEmail?: string | null,
        receipt?: boolean | null,
        replacementVehicleClass?: string | null,
        claimId?: string | null,
        vatDeductible?: boolean | null,
        deliveryCosts?: boolean | null,
        returnCosts?: boolean | null,
      } | null,
      externalData?:  {
        __typename: "ExternalData",
        orderId?: string | null,
        orderReference?: string | null,
        serviceAgreementName?: string | null,
        operatorName?: string | null,
        orderInfo?: string | null,
        billingPayer?: string | null,
        billingDescription?: string | null,
      } | null,
      enableRegistrationPlate?: boolean | null,
      disablePricesInPrintouts?: boolean | null,
      hanselData?:  {
        __typename: "HanselData",
        contractNro?: string | null,
        customerInvoiceId?: string | null,
        customerInvoiceDate?: string | null,
        hanselAgent?: string | null,
        hanselClass?: string | null,
        customerId?: string | null,
        subContractorNumber?: string | null,
        subContractorName?: string | null,
        suplierProductId?: string | null,
        suplierProductName?: string | null,
        quantity?: string | null,
        price?: string | null,
        totalPrice?: string | null,
        priceNoVAT?: string | null,
        additionalInfo1?: string | null,
        additionalInfo2?: string | null,
        additionalInfo3?: string | null,
        desc?: string | null,
      } | null,
      invoiceIds?: Array< string > | null,
      consolidatedInvoiceIds?: Array< string > | null,
      channel?: ReservationChannel | null,
      originURL?: string | null,
      type?: ReservationType | null,
      status?: string | null,
      reservationStatusInfo?:  {
        __typename: "ReservationStatusInfo",
        deliveryTimestamp?: string | null,
        deliveryAddress?: string | null,
        deliveryZipcode?: string | null,
        deliveryCity?: string | null,
        deliveryCountry?: string | null,
        deliveryDescription?: string | null,
        returnTimestamp?: string | null,
        returnAddress?: string | null,
        returnZipcode?: string | null,
        returnCity?: string | null,
        returnCountry?: string | null,
        returnDescription?: string | null,
        cancelReason?: string | null,
      } | null,
      initialCategory?:  {
        __typename: "CategoryInfo",
        id?: string | null,
        name?: string | null,
        hanselClass?: string | null,
      } | null,
      initialClassInfo?:  {
        __typename: "InitialClassInfo",
        categoryId?: string | null,
        categoryName?: string | null,
        hanselClass?: string | null,
      } | null,
      orgInvoiceCustomer?:  {
        __typename: "OrganizationInvoiceCustomer",
        name?: string | null,
        businessId?: string | null,
      } | null,
      pricingCategoryId?: string | null,
      customImportDate?: string | null,
      vehicle?:  {
        __typename: "Vehicle",
        id: string,
        group?: string | null,
        organizationId?: string | null,
        orgBusinessId?: string | null,
        companyId: string,
        name: string,
        registrationPlate?: string | null,
        damages?: string | null,
        image?: string | null,
        powerType?: string | null,
        deductible?: number | null,
        includedDistance?: string | null,
        mileage?: string | null,
        orderNumber?: number | null,
        vehicleCategoryId?: string | null,
        useCategoryDefaults?: boolean | null,
        useStartReturnTimes?: boolean | null,
        hideExport?: boolean | null,
        extraInfo?: string | null,
        extraInfoT?: string | null,
        extraToConfirmation?: string | null,
        extraToConfirmationT?: string | null,
        livionKeyId?: string | null,
        phoneNumber?: string | null,
        importData?: string | null,
        disableOnline?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      invoices?:  {
        __typename: "ModelInvoiceConnection",
        nextToken?: string | null,
      } | null,
      paymentEvents?:  {
        __typename: "ModelPaymentEventConnection",
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type SearchReservationsQueryVariables = {
  filter?: SearchableReservationFilterInput | null,
  sort?: SearchableReservationSortInput | null,
  limit?: number | null,
  nextToken?: string | null,
  from?: number | null,
};

export type SearchReservationsQuery = {
  searchReservations?:  {
    __typename: "SearchableReservationConnection",
    items:  Array< {
      __typename: "Reservation",
      id: string,
      shortId?: string | null,
      language?: Language | null,
      group?: string | null,
      organizationId?: string | null,
      orgBusinessId?: string | null,
      orgExternalBusinessId?: string | null,
      companyId?: string | null,
      returnCompanyId?: string | null,
      startLocation?: string | null,
      returnLocation?: string | null,
      startLocationID?: string | null,
      returnLocationID?: string | null,
      reviewId?: string | null,
      customerBrokerId?: string | null,
      customerCompanyId?: string | null,
      customerPersonalId?: string | null,
      reservationVehicleId?: string | null,
      reservationVehicles?:  Array< {
        __typename: "ReservationVehicle",
        id?: string | null,
        price?: number | null,
        tax?: number | null,
        name?: string | null,
        registrationPlate?: string | null,
        deductible?: number | null,
        includedDistance?: string | null,
        powerType?: string | null,
        mileage?: string | null,
        startMileage?: string | null,
        endMileage?: string | null,
        extraInfo?: string | null,
        damages?: string | null,
        hideExport?: boolean | null,
        createdAt?: string | null,
        addedAt?: string | null,
        categoryName?: string | null,
      } | null > | null,
      wildCardVehicles?:  Array< {
        __typename: "WildCardVehicle",
        name?: string | null,
        price?: number | null,
        tax?: number | null,
        categoryId?: string | null,
        quantity?: number | null,
        deductible?: number | null,
      } | null > | null,
      createdAt: string,
      updatedAt: string,
      startTime?: string | null,
      returnTime?: string | null,
      endTime?: string | null,
      name?: string | null,
      address?: string | null,
      postalCode?: string | null,
      city?: string | null,
      phone?: string | null,
      email?: string | null,
      billingRef?: string | null,
      customerNotes?: string | null,
      ssn?: string | null,
      rentalNotes?: string | null,
      internalNotes?: string | null,
      externalNotes?: string | null,
      outOfCommission?: boolean | null,
      vehiclePrice?: number | null,
      additionalServices?:  Array< {
        __typename: "AdditionalServiceReservation",
        key: string,
        price: number,
        details?: string | null,
        quantity?: number | null,
        type?: ServiceType | null,
        deductibleValue?: number | null,
        groupFields?: Array< string | null > | null,
        tax?: number | null,
        enableNotification?: boolean | null,
        description?: string | null,
        category?: string | null,
      } > | null,
      driverSSN?: string | null,
      differentDriver?: boolean | null,
      driverName?: string | null,
      driverAddress?: string | null,
      driverPostalCode?: string | null,
      driverCity?: string | null,
      driverPhone?: string | null,
      driverEmail?: string | null,
      additionalDriver?: boolean | null,
      additionalDriverName?: string | null,
      additionalDriverPhone?: string | null,
      additionalDriverSSN?: string | null,
      billPayment?: boolean | null,
      isCompany?: boolean | null,
      carStatus?: RentalStatus | null,
      rentalStatus?: RentalStatus | null,
      innovoiceStatus?: ReservationInnovoiceStatus | null,
      companyName?: string | null,
      companyBusinessId?: string | null,
      companyCity?: string | null,
      companyPostalCode?: string | null,
      companyBillingRef?: string | null,
      companyBillingAddress?: string | null,
      companyEBillingOperator?: string | null,
      companyEBillingAddress?: string | null,
      paymentToken?: string | null,
      npsStatus?: NpsStatus | null,
      paymentStatus?: PaymentStatus | null,
      paymentData?:  {
        __typename: "PaymentData",
        paymentTotal?: number | null,
        paymentTodo?: number | null,
        depositPending?: boolean | null,
        deposit?: number | null,
      } | null,
      signStatus?: SignStatus | null,
      signDocumentId?: string | null,
      signerName?: string | null,
      reviewStatus?: ReviewStatus | null,
      authStatus?: AuthStatus | null,
      authTime?: string | null,
      completeBefore?: string | null,
      mustSign?: boolean | null,
      livionData?:  Array< {
        __typename: "LivionData",
        id?: string | null,
        pincode?: string | null,
        keyId?: string | null,
        name?: string | null,
        registrationPlate?: string | null,
      } | null > | null,
      tilakoneData?:  {
        __typename: "TilakoneData",
        access_id?: string | null,
        begin_timestamp?: string | null,
        end_timestamp?: string | null,
        pincode?: string | null,
      } | null,
      voucherData?:  {
        __typename: "VoucherData",
        name: string,
        code: string,
        amount: number,
      } | null,
      customerCarRegistrationPlate?: string | null,
      insuranceData?:  {
        __typename: "InsuranceData",
        customerContact?: boolean | null,
        urgent?: boolean | null,
        customerCarModel?: string | null,
        customerCarMaker?: string | null,
        product?: string | null,
        coveragePercentage?: number | null,
        compensatableDays?: string | null,
        billingPermitCode?: string | null,
        coverageDuration?: number | null,
        providerCompany?: string | null,
        providerBusinessId?: string | null,
        claimAdjusterName?: string | null,
        claimAdjusterEmail?: string | null,
        receipt?: boolean | null,
        replacementVehicleClass?: string | null,
        claimId?: string | null,
        vatDeductible?: boolean | null,
        deliveryCosts?: boolean | null,
        returnCosts?: boolean | null,
      } | null,
      externalData?:  {
        __typename: "ExternalData",
        orderId?: string | null,
        orderReference?: string | null,
        serviceAgreementName?: string | null,
        operatorName?: string | null,
        orderInfo?: string | null,
        billingPayer?: string | null,
        billingDescription?: string | null,
      } | null,
      enableRegistrationPlate?: boolean | null,
      disablePricesInPrintouts?: boolean | null,
      hanselData?:  {
        __typename: "HanselData",
        contractNro?: string | null,
        customerInvoiceId?: string | null,
        customerInvoiceDate?: string | null,
        hanselAgent?: string | null,
        hanselClass?: string | null,
        customerId?: string | null,
        subContractorNumber?: string | null,
        subContractorName?: string | null,
        suplierProductId?: string | null,
        suplierProductName?: string | null,
        quantity?: string | null,
        price?: string | null,
        totalPrice?: string | null,
        priceNoVAT?: string | null,
        additionalInfo1?: string | null,
        additionalInfo2?: string | null,
        additionalInfo3?: string | null,
        desc?: string | null,
      } | null,
      invoiceIds?: Array< string > | null,
      consolidatedInvoiceIds?: Array< string > | null,
      channel?: ReservationChannel | null,
      originURL?: string | null,
      type?: ReservationType | null,
      status?: string | null,
      reservationStatusInfo?:  {
        __typename: "ReservationStatusInfo",
        deliveryTimestamp?: string | null,
        deliveryAddress?: string | null,
        deliveryZipcode?: string | null,
        deliveryCity?: string | null,
        deliveryCountry?: string | null,
        deliveryDescription?: string | null,
        returnTimestamp?: string | null,
        returnAddress?: string | null,
        returnZipcode?: string | null,
        returnCity?: string | null,
        returnCountry?: string | null,
        returnDescription?: string | null,
        cancelReason?: string | null,
      } | null,
      initialCategory?:  {
        __typename: "CategoryInfo",
        id?: string | null,
        name?: string | null,
        hanselClass?: string | null,
      } | null,
      initialClassInfo?:  {
        __typename: "InitialClassInfo",
        categoryId?: string | null,
        categoryName?: string | null,
        hanselClass?: string | null,
      } | null,
      orgInvoiceCustomer?:  {
        __typename: "OrganizationInvoiceCustomer",
        name?: string | null,
        businessId?: string | null,
      } | null,
      pricingCategoryId?: string | null,
      customImportDate?: string | null,
      vehicle?:  {
        __typename: "Vehicle",
        id: string,
        group?: string | null,
        organizationId?: string | null,
        orgBusinessId?: string | null,
        companyId: string,
        name: string,
        registrationPlate?: string | null,
        damages?: string | null,
        image?: string | null,
        powerType?: string | null,
        deductible?: number | null,
        includedDistance?: string | null,
        mileage?: string | null,
        orderNumber?: number | null,
        vehicleCategoryId?: string | null,
        useCategoryDefaults?: boolean | null,
        useStartReturnTimes?: boolean | null,
        hideExport?: boolean | null,
        extraInfo?: string | null,
        extraInfoT?: string | null,
        extraToConfirmation?: string | null,
        extraToConfirmationT?: string | null,
        livionKeyId?: string | null,
        phoneNumber?: string | null,
        importData?: string | null,
        disableOnline?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      invoices?:  {
        __typename: "ModelInvoiceConnection",
        nextToken?: string | null,
      } | null,
      paymentEvents?:  {
        __typename: "ModelPaymentEventConnection",
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
    total?: number | null,
  } | null,
};

export type GetReservationCountQueryVariables = {
  tenant: string,
  date: string,
};

export type GetReservationCountQuery = {
  getReservationCount?:  {
    __typename: "ReservationCount",
    tenant: string,
    date: string,
    count: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListReservationCountsQueryVariables = {
  tenant?: string | null,
  date?: ModelStringKeyConditionInput | null,
  filter?: ModelReservationCountFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListReservationCountsQuery = {
  listReservationCounts?:  {
    __typename: "ModelReservationCountConnection",
    items:  Array< {
      __typename: "ReservationCount",
      tenant: string,
      date: string,
      count: number,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetInvoiceQueryVariables = {
  id: string,
};

export type GetInvoiceQuery = {
  getInvoice?:  {
    __typename: "Invoice",
    id: string,
    createdAt: string,
    innovoiceId?: string | null,
    reservationId?: string | null,
    reservationIds?: Array< string > | null,
    companyId?: string | null,
    innovoiceCompanyId?: string | null,
    innovoiceuRentCarRentalId?: string | null,
    innovoiceOrderId?: string | null,
    group?: string | null,
    organizationId?: string | null,
    orgBusinessId?: string | null,
    type?: InvoicePaymentType | null,
    creditNoteInvoiceId?: string | null,
    payment_terms?: number | null,
    vat_included?: number | null,
    delivery_by: InvoiceDeliveryBy,
    invoice_type: InvoiceType,
    delivery_date?: string | null,
    seller_reference?: string | null,
    buyer_reference?: string | null,
    items:  Array< {
      __typename: "InvoiceItem",
      name: string,
      description?: string | null,
      quantity: number,
      unit: string,
      unitprice: number,
      discount?: number | null,
      vat: number,
      type?: string | null,
      insuranceItemType?: InsuranceItemType | null,
      reservationId?: string | null,
    } >,
    customer:  {
      __typename: "InvoiceCustomer",
      customer_type?: InvoiceCustomerType | null,
      firstname?: string | null,
      lastname?: string | null,
      ssn?: string | null,
      address?: string | null,
      zipcode?: string | null,
      city?: string | null,
      email?: string | null,
      phone?: string | null,
      company_name?: string | null,
      contact_person?: string | null,
      e_invoice_operator?: string | null,
      e_invoice_address?: string | null,
    },
    insurance?:  {
      __typename: "InvoiceInsurance",
      insurance_company_id?: number | null,
      vehicle_class?: string | null,
      vehicle_regno?: string | null,
      claim_no?: string | null,
      rental_start_date?: string | null,
      rental_end_date?: string | null,
      rental_days?: number | null,
      amount_cost_per_day?: number | null,
      amount_to_pay_customer?: number | null,
      amount_to_pay_insurance?: number | null,
      coveragePercentage?: number | null,
    } | null,
    updatedAt: string,
  } | null,
};

export type ListInvoicesQueryVariables = {
  filter?: ModelInvoiceFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListInvoicesQuery = {
  listInvoices?:  {
    __typename: "ModelInvoiceConnection",
    items:  Array< {
      __typename: "Invoice",
      id: string,
      createdAt: string,
      innovoiceId?: string | null,
      reservationId?: string | null,
      reservationIds?: Array< string > | null,
      companyId?: string | null,
      innovoiceCompanyId?: string | null,
      innovoiceuRentCarRentalId?: string | null,
      innovoiceOrderId?: string | null,
      group?: string | null,
      organizationId?: string | null,
      orgBusinessId?: string | null,
      type?: InvoicePaymentType | null,
      creditNoteInvoiceId?: string | null,
      payment_terms?: number | null,
      vat_included?: number | null,
      delivery_by: InvoiceDeliveryBy,
      invoice_type: InvoiceType,
      delivery_date?: string | null,
      seller_reference?: string | null,
      buyer_reference?: string | null,
      items:  Array< {
        __typename: "InvoiceItem",
        name: string,
        description?: string | null,
        quantity: number,
        unit: string,
        unitprice: number,
        discount?: number | null,
        vat: number,
        type?: string | null,
        insuranceItemType?: InsuranceItemType | null,
        reservationId?: string | null,
      } >,
      customer:  {
        __typename: "InvoiceCustomer",
        customer_type?: InvoiceCustomerType | null,
        firstname?: string | null,
        lastname?: string | null,
        ssn?: string | null,
        address?: string | null,
        zipcode?: string | null,
        city?: string | null,
        email?: string | null,
        phone?: string | null,
        company_name?: string | null,
        contact_person?: string | null,
        e_invoice_operator?: string | null,
        e_invoice_address?: string | null,
      },
      insurance?:  {
        __typename: "InvoiceInsurance",
        insurance_company_id?: number | null,
        vehicle_class?: string | null,
        vehicle_regno?: string | null,
        claim_no?: string | null,
        rental_start_date?: string | null,
        rental_end_date?: string | null,
        rental_days?: number | null,
        amount_cost_per_day?: number | null,
        amount_to_pay_customer?: number | null,
        amount_to_pay_insurance?: number | null,
        coveragePercentage?: number | null,
      } | null,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type InvoiceByReservationQueryVariables = {
  reservationId?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelInvoiceFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type InvoiceByReservationQuery = {
  invoiceByReservation?:  {
    __typename: "ModelInvoiceConnection",
    items:  Array< {
      __typename: "Invoice",
      id: string,
      createdAt: string,
      innovoiceId?: string | null,
      reservationId?: string | null,
      reservationIds?: Array< string > | null,
      companyId?: string | null,
      innovoiceCompanyId?: string | null,
      innovoiceuRentCarRentalId?: string | null,
      innovoiceOrderId?: string | null,
      group?: string | null,
      organizationId?: string | null,
      orgBusinessId?: string | null,
      type?: InvoicePaymentType | null,
      creditNoteInvoiceId?: string | null,
      payment_terms?: number | null,
      vat_included?: number | null,
      delivery_by: InvoiceDeliveryBy,
      invoice_type: InvoiceType,
      delivery_date?: string | null,
      seller_reference?: string | null,
      buyer_reference?: string | null,
      items:  Array< {
        __typename: "InvoiceItem",
        name: string,
        description?: string | null,
        quantity: number,
        unit: string,
        unitprice: number,
        discount?: number | null,
        vat: number,
        type?: string | null,
        insuranceItemType?: InsuranceItemType | null,
        reservationId?: string | null,
      } >,
      customer:  {
        __typename: "InvoiceCustomer",
        customer_type?: InvoiceCustomerType | null,
        firstname?: string | null,
        lastname?: string | null,
        ssn?: string | null,
        address?: string | null,
        zipcode?: string | null,
        city?: string | null,
        email?: string | null,
        phone?: string | null,
        company_name?: string | null,
        contact_person?: string | null,
        e_invoice_operator?: string | null,
        e_invoice_address?: string | null,
      },
      insurance?:  {
        __typename: "InvoiceInsurance",
        insurance_company_id?: number | null,
        vehicle_class?: string | null,
        vehicle_regno?: string | null,
        claim_no?: string | null,
        rental_start_date?: string | null,
        rental_end_date?: string | null,
        rental_days?: number | null,
        amount_cost_per_day?: number | null,
        amount_to_pay_customer?: number | null,
        amount_to_pay_insurance?: number | null,
        coveragePercentage?: number | null,
      } | null,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type InvoiceByOrgBusinessIdQueryVariables = {
  orgBusinessId?: string | null,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelInvoiceFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type InvoiceByOrgBusinessIdQuery = {
  invoiceByOrgBusinessId?:  {
    __typename: "ModelInvoiceConnection",
    items:  Array< {
      __typename: "Invoice",
      id: string,
      createdAt: string,
      innovoiceId?: string | null,
      reservationId?: string | null,
      reservationIds?: Array< string > | null,
      companyId?: string | null,
      innovoiceCompanyId?: string | null,
      innovoiceuRentCarRentalId?: string | null,
      innovoiceOrderId?: string | null,
      group?: string | null,
      organizationId?: string | null,
      orgBusinessId?: string | null,
      type?: InvoicePaymentType | null,
      creditNoteInvoiceId?: string | null,
      payment_terms?: number | null,
      vat_included?: number | null,
      delivery_by: InvoiceDeliveryBy,
      invoice_type: InvoiceType,
      delivery_date?: string | null,
      seller_reference?: string | null,
      buyer_reference?: string | null,
      items:  Array< {
        __typename: "InvoiceItem",
        name: string,
        description?: string | null,
        quantity: number,
        unit: string,
        unitprice: number,
        discount?: number | null,
        vat: number,
        type?: string | null,
        insuranceItemType?: InsuranceItemType | null,
        reservationId?: string | null,
      } >,
      customer:  {
        __typename: "InvoiceCustomer",
        customer_type?: InvoiceCustomerType | null,
        firstname?: string | null,
        lastname?: string | null,
        ssn?: string | null,
        address?: string | null,
        zipcode?: string | null,
        city?: string | null,
        email?: string | null,
        phone?: string | null,
        company_name?: string | null,
        contact_person?: string | null,
        e_invoice_operator?: string | null,
        e_invoice_address?: string | null,
      },
      insurance?:  {
        __typename: "InvoiceInsurance",
        insurance_company_id?: number | null,
        vehicle_class?: string | null,
        vehicle_regno?: string | null,
        claim_no?: string | null,
        rental_start_date?: string | null,
        rental_end_date?: string | null,
        rental_days?: number | null,
        amount_cost_per_day?: number | null,
        amount_to_pay_customer?: number | null,
        amount_to_pay_insurance?: number | null,
        coveragePercentage?: number | null,
      } | null,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetSignEventQueryVariables = {
  id: string,
};

export type GetSignEventQuery = {
  getSignEvent?:  {
    __typename: "SignEvent",
    id: string,
    reservationId: string,
    company: string,
    organizationId?: string | null,
    orgBusinessId?: string | null,
    group?: string | null,
    status: SignStatus,
    documentId?: string | null,
    source?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListSignEventsQueryVariables = {
  filter?: ModelSignEventFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListSignEventsQuery = {
  listSignEvents?:  {
    __typename: "ModelSignEventConnection",
    items:  Array< {
      __typename: "SignEvent",
      id: string,
      reservationId: string,
      company: string,
      organizationId?: string | null,
      orgBusinessId?: string | null,
      group?: string | null,
      status: SignStatus,
      documentId?: string | null,
      source?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetPaymentEventQueryVariables = {
  id: string,
};

export type GetPaymentEventQuery = {
  getPaymentEvent?:  {
    __typename: "PaymentEvent",
    id: string,
    channel?: string | null,
    paymentMethod?: string | null,
    paymentTotal?: number | null,
    isDeleted?: boolean | null,
    deletedBy?: string | null,
    deletedAt?: string | null,
    createdByUserID?: string | null,
    createdByUserName?: string | null,
    reservationId?: string | null,
    organizationId?: string | null,
    orgBusinessId?: string | null,
    group?: string | null,
    msg?: string | null,
    state?: PaymentEventState | null,
    returnCode?: number | null,
    incidentId?: string | null,
    parameters?: string | null,
    apiPath?: string | null,
    paymentStatus?: string | null,
    orderNumber?: string | null,
    createdAt: string,
    updatedAt: string,
    reservation?:  {
      __typename: "Reservation",
      id: string,
      shortId?: string | null,
      language?: Language | null,
      group?: string | null,
      organizationId?: string | null,
      orgBusinessId?: string | null,
      orgExternalBusinessId?: string | null,
      companyId?: string | null,
      returnCompanyId?: string | null,
      startLocation?: string | null,
      returnLocation?: string | null,
      startLocationID?: string | null,
      returnLocationID?: string | null,
      reviewId?: string | null,
      customerBrokerId?: string | null,
      customerCompanyId?: string | null,
      customerPersonalId?: string | null,
      reservationVehicleId?: string | null,
      reservationVehicles?:  Array< {
        __typename: "ReservationVehicle",
        id?: string | null,
        price?: number | null,
        tax?: number | null,
        name?: string | null,
        registrationPlate?: string | null,
        deductible?: number | null,
        includedDistance?: string | null,
        powerType?: string | null,
        mileage?: string | null,
        startMileage?: string | null,
        endMileage?: string | null,
        extraInfo?: string | null,
        damages?: string | null,
        hideExport?: boolean | null,
        createdAt?: string | null,
        addedAt?: string | null,
        categoryName?: string | null,
      } | null > | null,
      wildCardVehicles?:  Array< {
        __typename: "WildCardVehicle",
        name?: string | null,
        price?: number | null,
        tax?: number | null,
        categoryId?: string | null,
        quantity?: number | null,
        deductible?: number | null,
      } | null > | null,
      createdAt: string,
      updatedAt: string,
      startTime?: string | null,
      returnTime?: string | null,
      endTime?: string | null,
      name?: string | null,
      address?: string | null,
      postalCode?: string | null,
      city?: string | null,
      phone?: string | null,
      email?: string | null,
      billingRef?: string | null,
      customerNotes?: string | null,
      ssn?: string | null,
      rentalNotes?: string | null,
      internalNotes?: string | null,
      externalNotes?: string | null,
      outOfCommission?: boolean | null,
      vehiclePrice?: number | null,
      additionalServices?:  Array< {
        __typename: "AdditionalServiceReservation",
        key: string,
        price: number,
        details?: string | null,
        quantity?: number | null,
        type?: ServiceType | null,
        deductibleValue?: number | null,
        groupFields?: Array< string | null > | null,
        tax?: number | null,
        enableNotification?: boolean | null,
        description?: string | null,
        category?: string | null,
      } > | null,
      driverSSN?: string | null,
      differentDriver?: boolean | null,
      driverName?: string | null,
      driverAddress?: string | null,
      driverPostalCode?: string | null,
      driverCity?: string | null,
      driverPhone?: string | null,
      driverEmail?: string | null,
      additionalDriver?: boolean | null,
      additionalDriverName?: string | null,
      additionalDriverPhone?: string | null,
      additionalDriverSSN?: string | null,
      billPayment?: boolean | null,
      isCompany?: boolean | null,
      carStatus?: RentalStatus | null,
      rentalStatus?: RentalStatus | null,
      innovoiceStatus?: ReservationInnovoiceStatus | null,
      companyName?: string | null,
      companyBusinessId?: string | null,
      companyCity?: string | null,
      companyPostalCode?: string | null,
      companyBillingRef?: string | null,
      companyBillingAddress?: string | null,
      companyEBillingOperator?: string | null,
      companyEBillingAddress?: string | null,
      paymentToken?: string | null,
      npsStatus?: NpsStatus | null,
      paymentStatus?: PaymentStatus | null,
      paymentData?:  {
        __typename: "PaymentData",
        paymentTotal?: number | null,
        paymentTodo?: number | null,
        depositPending?: boolean | null,
        deposit?: number | null,
      } | null,
      signStatus?: SignStatus | null,
      signDocumentId?: string | null,
      signerName?: string | null,
      reviewStatus?: ReviewStatus | null,
      authStatus?: AuthStatus | null,
      authTime?: string | null,
      completeBefore?: string | null,
      mustSign?: boolean | null,
      livionData?:  Array< {
        __typename: "LivionData",
        id?: string | null,
        pincode?: string | null,
        keyId?: string | null,
        name?: string | null,
        registrationPlate?: string | null,
      } | null > | null,
      tilakoneData?:  {
        __typename: "TilakoneData",
        access_id?: string | null,
        begin_timestamp?: string | null,
        end_timestamp?: string | null,
        pincode?: string | null,
      } | null,
      voucherData?:  {
        __typename: "VoucherData",
        name: string,
        code: string,
        amount: number,
      } | null,
      customerCarRegistrationPlate?: string | null,
      insuranceData?:  {
        __typename: "InsuranceData",
        customerContact?: boolean | null,
        urgent?: boolean | null,
        customerCarModel?: string | null,
        customerCarMaker?: string | null,
        product?: string | null,
        coveragePercentage?: number | null,
        compensatableDays?: string | null,
        billingPermitCode?: string | null,
        coverageDuration?: number | null,
        providerCompany?: string | null,
        providerBusinessId?: string | null,
        claimAdjusterName?: string | null,
        claimAdjusterEmail?: string | null,
        receipt?: boolean | null,
        replacementVehicleClass?: string | null,
        claimId?: string | null,
        vatDeductible?: boolean | null,
        deliveryCosts?: boolean | null,
        returnCosts?: boolean | null,
      } | null,
      externalData?:  {
        __typename: "ExternalData",
        orderId?: string | null,
        orderReference?: string | null,
        serviceAgreementName?: string | null,
        operatorName?: string | null,
        orderInfo?: string | null,
        billingPayer?: string | null,
        billingDescription?: string | null,
      } | null,
      enableRegistrationPlate?: boolean | null,
      disablePricesInPrintouts?: boolean | null,
      hanselData?:  {
        __typename: "HanselData",
        contractNro?: string | null,
        customerInvoiceId?: string | null,
        customerInvoiceDate?: string | null,
        hanselAgent?: string | null,
        hanselClass?: string | null,
        customerId?: string | null,
        subContractorNumber?: string | null,
        subContractorName?: string | null,
        suplierProductId?: string | null,
        suplierProductName?: string | null,
        quantity?: string | null,
        price?: string | null,
        totalPrice?: string | null,
        priceNoVAT?: string | null,
        additionalInfo1?: string | null,
        additionalInfo2?: string | null,
        additionalInfo3?: string | null,
        desc?: string | null,
      } | null,
      invoiceIds?: Array< string > | null,
      consolidatedInvoiceIds?: Array< string > | null,
      channel?: ReservationChannel | null,
      originURL?: string | null,
      type?: ReservationType | null,
      status?: string | null,
      reservationStatusInfo?:  {
        __typename: "ReservationStatusInfo",
        deliveryTimestamp?: string | null,
        deliveryAddress?: string | null,
        deliveryZipcode?: string | null,
        deliveryCity?: string | null,
        deliveryCountry?: string | null,
        deliveryDescription?: string | null,
        returnTimestamp?: string | null,
        returnAddress?: string | null,
        returnZipcode?: string | null,
        returnCity?: string | null,
        returnCountry?: string | null,
        returnDescription?: string | null,
        cancelReason?: string | null,
      } | null,
      initialCategory?:  {
        __typename: "CategoryInfo",
        id?: string | null,
        name?: string | null,
        hanselClass?: string | null,
      } | null,
      initialClassInfo?:  {
        __typename: "InitialClassInfo",
        categoryId?: string | null,
        categoryName?: string | null,
        hanselClass?: string | null,
      } | null,
      orgInvoiceCustomer?:  {
        __typename: "OrganizationInvoiceCustomer",
        name?: string | null,
        businessId?: string | null,
      } | null,
      pricingCategoryId?: string | null,
      customImportDate?: string | null,
      vehicle?:  {
        __typename: "Vehicle",
        id: string,
        group?: string | null,
        organizationId?: string | null,
        orgBusinessId?: string | null,
        companyId: string,
        name: string,
        registrationPlate?: string | null,
        damages?: string | null,
        image?: string | null,
        powerType?: string | null,
        deductible?: number | null,
        includedDistance?: string | null,
        mileage?: string | null,
        orderNumber?: number | null,
        vehicleCategoryId?: string | null,
        useCategoryDefaults?: boolean | null,
        useStartReturnTimes?: boolean | null,
        hideExport?: boolean | null,
        extraInfo?: string | null,
        extraInfoT?: string | null,
        extraToConfirmation?: string | null,
        extraToConfirmationT?: string | null,
        livionKeyId?: string | null,
        phoneNumber?: string | null,
        importData?: string | null,
        disableOnline?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      invoices?:  {
        __typename: "ModelInvoiceConnection",
        nextToken?: string | null,
      } | null,
      paymentEvents?:  {
        __typename: "ModelPaymentEventConnection",
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type ListPaymentEventsQueryVariables = {
  filter?: ModelPaymentEventFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListPaymentEventsQuery = {
  listPaymentEvents?:  {
    __typename: "ModelPaymentEventConnection",
    items:  Array< {
      __typename: "PaymentEvent",
      id: string,
      channel?: string | null,
      paymentMethod?: string | null,
      paymentTotal?: number | null,
      isDeleted?: boolean | null,
      deletedBy?: string | null,
      deletedAt?: string | null,
      createdByUserID?: string | null,
      createdByUserName?: string | null,
      reservationId?: string | null,
      organizationId?: string | null,
      orgBusinessId?: string | null,
      group?: string | null,
      msg?: string | null,
      state?: PaymentEventState | null,
      returnCode?: number | null,
      incidentId?: string | null,
      parameters?: string | null,
      apiPath?: string | null,
      paymentStatus?: string | null,
      orderNumber?: string | null,
      createdAt: string,
      updatedAt: string,
      reservation?:  {
        __typename: "Reservation",
        id: string,
        shortId?: string | null,
        language?: Language | null,
        group?: string | null,
        organizationId?: string | null,
        orgBusinessId?: string | null,
        orgExternalBusinessId?: string | null,
        companyId?: string | null,
        returnCompanyId?: string | null,
        startLocation?: string | null,
        returnLocation?: string | null,
        startLocationID?: string | null,
        returnLocationID?: string | null,
        reviewId?: string | null,
        customerBrokerId?: string | null,
        customerCompanyId?: string | null,
        customerPersonalId?: string | null,
        reservationVehicleId?: string | null,
        createdAt: string,
        updatedAt: string,
        startTime?: string | null,
        returnTime?: string | null,
        endTime?: string | null,
        name?: string | null,
        address?: string | null,
        postalCode?: string | null,
        city?: string | null,
        phone?: string | null,
        email?: string | null,
        billingRef?: string | null,
        customerNotes?: string | null,
        ssn?: string | null,
        rentalNotes?: string | null,
        internalNotes?: string | null,
        externalNotes?: string | null,
        outOfCommission?: boolean | null,
        vehiclePrice?: number | null,
        driverSSN?: string | null,
        differentDriver?: boolean | null,
        driverName?: string | null,
        driverAddress?: string | null,
        driverPostalCode?: string | null,
        driverCity?: string | null,
        driverPhone?: string | null,
        driverEmail?: string | null,
        additionalDriver?: boolean | null,
        additionalDriverName?: string | null,
        additionalDriverPhone?: string | null,
        additionalDriverSSN?: string | null,
        billPayment?: boolean | null,
        isCompany?: boolean | null,
        carStatus?: RentalStatus | null,
        rentalStatus?: RentalStatus | null,
        innovoiceStatus?: ReservationInnovoiceStatus | null,
        companyName?: string | null,
        companyBusinessId?: string | null,
        companyCity?: string | null,
        companyPostalCode?: string | null,
        companyBillingRef?: string | null,
        companyBillingAddress?: string | null,
        companyEBillingOperator?: string | null,
        companyEBillingAddress?: string | null,
        paymentToken?: string | null,
        npsStatus?: NpsStatus | null,
        paymentStatus?: PaymentStatus | null,
        signStatus?: SignStatus | null,
        signDocumentId?: string | null,
        signerName?: string | null,
        reviewStatus?: ReviewStatus | null,
        authStatus?: AuthStatus | null,
        authTime?: string | null,
        completeBefore?: string | null,
        mustSign?: boolean | null,
        customerCarRegistrationPlate?: string | null,
        enableRegistrationPlate?: boolean | null,
        disablePricesInPrintouts?: boolean | null,
        invoiceIds?: Array< string > | null,
        consolidatedInvoiceIds?: Array< string > | null,
        channel?: ReservationChannel | null,
        originURL?: string | null,
        type?: ReservationType | null,
        status?: string | null,
        pricingCategoryId?: string | null,
        customImportDate?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type PaymentEventByReservationQueryVariables = {
  reservationId?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelPaymentEventFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type PaymentEventByReservationQuery = {
  paymentEventByReservation?:  {
    __typename: "ModelPaymentEventConnection",
    items:  Array< {
      __typename: "PaymentEvent",
      id: string,
      channel?: string | null,
      paymentMethod?: string | null,
      paymentTotal?: number | null,
      isDeleted?: boolean | null,
      deletedBy?: string | null,
      deletedAt?: string | null,
      createdByUserID?: string | null,
      createdByUserName?: string | null,
      reservationId?: string | null,
      organizationId?: string | null,
      orgBusinessId?: string | null,
      group?: string | null,
      msg?: string | null,
      state?: PaymentEventState | null,
      returnCode?: number | null,
      incidentId?: string | null,
      parameters?: string | null,
      apiPath?: string | null,
      paymentStatus?: string | null,
      orderNumber?: string | null,
      createdAt: string,
      updatedAt: string,
      reservation?:  {
        __typename: "Reservation",
        id: string,
        shortId?: string | null,
        language?: Language | null,
        group?: string | null,
        organizationId?: string | null,
        orgBusinessId?: string | null,
        orgExternalBusinessId?: string | null,
        companyId?: string | null,
        returnCompanyId?: string | null,
        startLocation?: string | null,
        returnLocation?: string | null,
        startLocationID?: string | null,
        returnLocationID?: string | null,
        reviewId?: string | null,
        customerBrokerId?: string | null,
        customerCompanyId?: string | null,
        customerPersonalId?: string | null,
        reservationVehicleId?: string | null,
        createdAt: string,
        updatedAt: string,
        startTime?: string | null,
        returnTime?: string | null,
        endTime?: string | null,
        name?: string | null,
        address?: string | null,
        postalCode?: string | null,
        city?: string | null,
        phone?: string | null,
        email?: string | null,
        billingRef?: string | null,
        customerNotes?: string | null,
        ssn?: string | null,
        rentalNotes?: string | null,
        internalNotes?: string | null,
        externalNotes?: string | null,
        outOfCommission?: boolean | null,
        vehiclePrice?: number | null,
        driverSSN?: string | null,
        differentDriver?: boolean | null,
        driverName?: string | null,
        driverAddress?: string | null,
        driverPostalCode?: string | null,
        driverCity?: string | null,
        driverPhone?: string | null,
        driverEmail?: string | null,
        additionalDriver?: boolean | null,
        additionalDriverName?: string | null,
        additionalDriverPhone?: string | null,
        additionalDriverSSN?: string | null,
        billPayment?: boolean | null,
        isCompany?: boolean | null,
        carStatus?: RentalStatus | null,
        rentalStatus?: RentalStatus | null,
        innovoiceStatus?: ReservationInnovoiceStatus | null,
        companyName?: string | null,
        companyBusinessId?: string | null,
        companyCity?: string | null,
        companyPostalCode?: string | null,
        companyBillingRef?: string | null,
        companyBillingAddress?: string | null,
        companyEBillingOperator?: string | null,
        companyEBillingAddress?: string | null,
        paymentToken?: string | null,
        npsStatus?: NpsStatus | null,
        paymentStatus?: PaymentStatus | null,
        signStatus?: SignStatus | null,
        signDocumentId?: string | null,
        signerName?: string | null,
        reviewStatus?: ReviewStatus | null,
        authStatus?: AuthStatus | null,
        authTime?: string | null,
        completeBefore?: string | null,
        mustSign?: boolean | null,
        customerCarRegistrationPlate?: string | null,
        enableRegistrationPlate?: boolean | null,
        disablePricesInPrintouts?: boolean | null,
        invoiceIds?: Array< string > | null,
        consolidatedInvoiceIds?: Array< string > | null,
        channel?: ReservationChannel | null,
        originURL?: string | null,
        type?: ReservationType | null,
        status?: string | null,
        pricingCategoryId?: string | null,
        customImportDate?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetAdditionalServiceQueryVariables = {
  id: string,
};

export type GetAdditionalServiceQuery = {
  getAdditionalService?:  {
    __typename: "AdditionalService",
    id: string,
    organizationId?: string | null,
    orgBusinessId?: string | null,
    group?: string | null,
    description?: string | null,
    descriptionT?:  {
      __typename: "Translation",
      fi?: string | null,
      en?: string | null,
      sv?: string | null,
    } | null,
    description2?: string | null,
    category: string,
    type?: ServiceType | null,
    enableTextInput?: boolean | null,
    enableQuantity?: boolean | null,
    enableNotification?: boolean | null,
    maxQuantity?: number | null,
    inputLabel?: string | null,
    _removed?: string | null,
    isGroupParent?: boolean | null,
    groupFields?: Array< string | null > | null,
    groupParentId?: string | null,
    groupOrderNumber?: number | null,
    image?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListAdditionalServicesQueryVariables = {
  filter?: ModelAdditionalServiceFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListAdditionalServicesQuery = {
  listAdditionalServices?:  {
    __typename: "ModelAdditionalServiceConnection",
    items:  Array< {
      __typename: "AdditionalService",
      id: string,
      organizationId?: string | null,
      orgBusinessId?: string | null,
      group?: string | null,
      description?: string | null,
      descriptionT?:  {
        __typename: "Translation",
        fi?: string | null,
        en?: string | null,
        sv?: string | null,
      } | null,
      description2?: string | null,
      category: string,
      type?: ServiceType | null,
      enableTextInput?: boolean | null,
      enableQuantity?: boolean | null,
      enableNotification?: boolean | null,
      maxQuantity?: number | null,
      inputLabel?: string | null,
      _removed?: string | null,
      isGroupParent?: boolean | null,
      groupFields?: Array< string | null > | null,
      groupParentId?: string | null,
      groupOrderNumber?: number | null,
      image?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type AdditionalServiceByOrganizationQueryVariables = {
  organizationId?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelAdditionalServiceFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type AdditionalServiceByOrganizationQuery = {
  additionalServiceByOrganization?:  {
    __typename: "ModelAdditionalServiceConnection",
    items:  Array< {
      __typename: "AdditionalService",
      id: string,
      organizationId?: string | null,
      orgBusinessId?: string | null,
      group?: string | null,
      description?: string | null,
      descriptionT?:  {
        __typename: "Translation",
        fi?: string | null,
        en?: string | null,
        sv?: string | null,
      } | null,
      description2?: string | null,
      category: string,
      type?: ServiceType | null,
      enableTextInput?: boolean | null,
      enableQuantity?: boolean | null,
      enableNotification?: boolean | null,
      maxQuantity?: number | null,
      inputLabel?: string | null,
      _removed?: string | null,
      isGroupParent?: boolean | null,
      groupFields?: Array< string | null > | null,
      groupParentId?: string | null,
      groupOrderNumber?: number | null,
      image?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type AdditionalServiceByGroupQueryVariables = {
  group?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelAdditionalServiceFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type AdditionalServiceByGroupQuery = {
  additionalServiceByGroup?:  {
    __typename: "ModelAdditionalServiceConnection",
    items:  Array< {
      __typename: "AdditionalService",
      id: string,
      organizationId?: string | null,
      orgBusinessId?: string | null,
      group?: string | null,
      description?: string | null,
      descriptionT?:  {
        __typename: "Translation",
        fi?: string | null,
        en?: string | null,
        sv?: string | null,
      } | null,
      description2?: string | null,
      category: string,
      type?: ServiceType | null,
      enableTextInput?: boolean | null,
      enableQuantity?: boolean | null,
      enableNotification?: boolean | null,
      maxQuantity?: number | null,
      inputLabel?: string | null,
      _removed?: string | null,
      isGroupParent?: boolean | null,
      groupFields?: Array< string | null > | null,
      groupParentId?: string | null,
      groupOrderNumber?: number | null,
      image?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetPricingQueryVariables = {
  id: string,
};

export type GetPricingQuery = {
  getPricing?:  {
    __typename: "Pricing",
    id: string,
    name: string,
    group?: string | null,
    organizationId?: string | null,
    orgBusinessId?: string | null,
    orgExternalBusinessId?: string | null,
    externalBusinessCategoryAssociation?: string | null,
    businessCategoryAssociation?: string | null,
    elements:  Array< {
      __typename: "PricingElement",
      duration: string,
      price: number,
    } >,
    useNextDayPrice?: boolean | null,
    useLastDayPrice?: boolean | null,
    tax?: number | null,
    _removed?: string | null,
    deductible?: number | null,
    reducedDeductible?: number | null,
    reducedDeductiblePrice?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListPricingsQueryVariables = {
  filter?: ModelPricingFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListPricingsQuery = {
  listPricings?:  {
    __typename: "ModelPricingConnection",
    items:  Array< {
      __typename: "Pricing",
      id: string,
      name: string,
      group?: string | null,
      organizationId?: string | null,
      orgBusinessId?: string | null,
      orgExternalBusinessId?: string | null,
      externalBusinessCategoryAssociation?: string | null,
      businessCategoryAssociation?: string | null,
      elements:  Array< {
        __typename: "PricingElement",
        duration: string,
        price: number,
      } >,
      useNextDayPrice?: boolean | null,
      useLastDayPrice?: boolean | null,
      tax?: number | null,
      _removed?: string | null,
      deductible?: number | null,
      reducedDeductible?: number | null,
      reducedDeductiblePrice?: number | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type PricingByOrganizationQueryVariables = {
  organizationId?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelPricingFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type PricingByOrganizationQuery = {
  pricingByOrganization?:  {
    __typename: "ModelPricingConnection",
    items:  Array< {
      __typename: "Pricing",
      id: string,
      name: string,
      group?: string | null,
      organizationId?: string | null,
      orgBusinessId?: string | null,
      orgExternalBusinessId?: string | null,
      externalBusinessCategoryAssociation?: string | null,
      businessCategoryAssociation?: string | null,
      elements:  Array< {
        __typename: "PricingElement",
        duration: string,
        price: number,
      } >,
      useNextDayPrice?: boolean | null,
      useLastDayPrice?: boolean | null,
      tax?: number | null,
      _removed?: string | null,
      deductible?: number | null,
      reducedDeductible?: number | null,
      reducedDeductiblePrice?: number | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type PricingByOrgBusinessIdQueryVariables = {
  orgBusinessId?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelPricingFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type PricingByOrgBusinessIdQuery = {
  pricingByOrgBusinessId?:  {
    __typename: "ModelPricingConnection",
    items:  Array< {
      __typename: "Pricing",
      id: string,
      name: string,
      group?: string | null,
      organizationId?: string | null,
      orgBusinessId?: string | null,
      orgExternalBusinessId?: string | null,
      externalBusinessCategoryAssociation?: string | null,
      businessCategoryAssociation?: string | null,
      elements:  Array< {
        __typename: "PricingElement",
        duration: string,
        price: number,
      } >,
      useNextDayPrice?: boolean | null,
      useLastDayPrice?: boolean | null,
      tax?: number | null,
      _removed?: string | null,
      deductible?: number | null,
      reducedDeductible?: number | null,
      reducedDeductiblePrice?: number | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type PricingByOrgExternallBusinessIdQueryVariables = {
  orgExternalBusinessId?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelPricingFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type PricingByOrgExternallBusinessIdQuery = {
  pricingByOrgExternallBusinessId?:  {
    __typename: "ModelPricingConnection",
    items:  Array< {
      __typename: "Pricing",
      id: string,
      name: string,
      group?: string | null,
      organizationId?: string | null,
      orgBusinessId?: string | null,
      orgExternalBusinessId?: string | null,
      externalBusinessCategoryAssociation?: string | null,
      businessCategoryAssociation?: string | null,
      elements:  Array< {
        __typename: "PricingElement",
        duration: string,
        price: number,
      } >,
      useNextDayPrice?: boolean | null,
      useLastDayPrice?: boolean | null,
      tax?: number | null,
      _removed?: string | null,
      deductible?: number | null,
      reducedDeductible?: number | null,
      reducedDeductiblePrice?: number | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type PricingBybyExternalBusinessCategoryAssociationQueryVariables = {
  externalBusinessCategoryAssociation?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelPricingFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type PricingBybyExternalBusinessCategoryAssociationQuery = {
  pricingBybyExternalBusinessCategoryAssociation?:  {
    __typename: "ModelPricingConnection",
    items:  Array< {
      __typename: "Pricing",
      id: string,
      name: string,
      group?: string | null,
      organizationId?: string | null,
      orgBusinessId?: string | null,
      orgExternalBusinessId?: string | null,
      externalBusinessCategoryAssociation?: string | null,
      businessCategoryAssociation?: string | null,
      elements:  Array< {
        __typename: "PricingElement",
        duration: string,
        price: number,
      } >,
      useNextDayPrice?: boolean | null,
      useLastDayPrice?: boolean | null,
      tax?: number | null,
      _removed?: string | null,
      deductible?: number | null,
      reducedDeductible?: number | null,
      reducedDeductiblePrice?: number | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type PricingByGroupQueryVariables = {
  group?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelPricingFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type PricingByGroupQuery = {
  pricingByGroup?:  {
    __typename: "ModelPricingConnection",
    items:  Array< {
      __typename: "Pricing",
      id: string,
      name: string,
      group?: string | null,
      organizationId?: string | null,
      orgBusinessId?: string | null,
      orgExternalBusinessId?: string | null,
      externalBusinessCategoryAssociation?: string | null,
      businessCategoryAssociation?: string | null,
      elements:  Array< {
        __typename: "PricingElement",
        duration: string,
        price: number,
      } >,
      useNextDayPrice?: boolean | null,
      useLastDayPrice?: boolean | null,
      tax?: number | null,
      _removed?: string | null,
      deductible?: number | null,
      reducedDeductible?: number | null,
      reducedDeductiblePrice?: number | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetPricingExceptionQueryVariables = {
  id: string,
};

export type GetPricingExceptionQuery = {
  getPricingException?:  {
    __typename: "PricingException",
    id: string,
    name: string,
    group?: string | null,
    organizationId?: string | null,
    orgBusinessId?: string | null,
    type: PricingExceptionType,
    amountType: PricingExceptionAmountType,
    weeklyAmounts?: Array< number > | null,
    monthlyAmountPerDay?: number | null,
    daysBeforeMonthChange?: number | null,
    daysAfterMonthChange?: number | null,
    startDate?: string | null,
    endDate?: string | null,
    pricingIds?: Array< string > | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListPricingExceptionsQueryVariables = {
  filter?: ModelPricingExceptionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListPricingExceptionsQuery = {
  listPricingExceptions?:  {
    __typename: "ModelPricingExceptionConnection",
    items:  Array< {
      __typename: "PricingException",
      id: string,
      name: string,
      group?: string | null,
      organizationId?: string | null,
      orgBusinessId?: string | null,
      type: PricingExceptionType,
      amountType: PricingExceptionAmountType,
      weeklyAmounts?: Array< number > | null,
      monthlyAmountPerDay?: number | null,
      daysBeforeMonthChange?: number | null,
      daysAfterMonthChange?: number | null,
      startDate?: string | null,
      endDate?: string | null,
      pricingIds?: Array< string > | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type PricingExceptionByGroupQueryVariables = {
  group?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelPricingExceptionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type PricingExceptionByGroupQuery = {
  pricingExceptionByGroup?:  {
    __typename: "ModelPricingExceptionConnection",
    items:  Array< {
      __typename: "PricingException",
      id: string,
      name: string,
      group?: string | null,
      organizationId?: string | null,
      orgBusinessId?: string | null,
      type: PricingExceptionType,
      amountType: PricingExceptionAmountType,
      weeklyAmounts?: Array< number > | null,
      monthlyAmountPerDay?: number | null,
      daysBeforeMonthChange?: number | null,
      daysAfterMonthChange?: number | null,
      startDate?: string | null,
      endDate?: string | null,
      pricingIds?: Array< string > | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type PricingExceptionByOrgBusinessIdQueryVariables = {
  orgBusinessId?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelPricingExceptionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type PricingExceptionByOrgBusinessIdQuery = {
  pricingExceptionByOrgBusinessId?:  {
    __typename: "ModelPricingExceptionConnection",
    items:  Array< {
      __typename: "PricingException",
      id: string,
      name: string,
      group?: string | null,
      organizationId?: string | null,
      orgBusinessId?: string | null,
      type: PricingExceptionType,
      amountType: PricingExceptionAmountType,
      weeklyAmounts?: Array< number > | null,
      monthlyAmountPerDay?: number | null,
      daysBeforeMonthChange?: number | null,
      daysAfterMonthChange?: number | null,
      startDate?: string | null,
      endDate?: string | null,
      pricingIds?: Array< string > | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetVoucherQueryVariables = {
  id: string,
};

export type GetVoucherQuery = {
  getVoucher?:  {
    __typename: "Voucher",
    id: string,
    name: string,
    group?: string | null,
    organizationId?: string | null,
    code: string,
    amount: number,
    startDate?: string | null,
    endDate?: string | null,
    _removed?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListVouchersQueryVariables = {
  filter?: ModelVoucherFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListVouchersQuery = {
  listVouchers?:  {
    __typename: "ModelVoucherConnection",
    items:  Array< {
      __typename: "Voucher",
      id: string,
      name: string,
      group?: string | null,
      organizationId?: string | null,
      code: string,
      amount: number,
      startDate?: string | null,
      endDate?: string | null,
      _removed?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetReviewQueryVariables = {
  id: string,
};

export type GetReviewQuery = {
  getReview?:  {
    __typename: "Review",
    id: string,
    group?: string | null,
    organizationId?: string | null,
    orgBusinessId?: string | null,
    orgExternalBusinessId?: string | null,
    rating: number,
    comment?: string | null,
    explanation?: string | null,
    reservationId: string,
    companyId?: string | null,
    startTime: string,
    returnTime: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListReviewsQueryVariables = {
  filter?: ModelReviewFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListReviewsQuery = {
  listReviews?:  {
    __typename: "ModelReviewConnection",
    items:  Array< {
      __typename: "Review",
      id: string,
      group?: string | null,
      organizationId?: string | null,
      orgBusinessId?: string | null,
      orgExternalBusinessId?: string | null,
      rating: number,
      comment?: string | null,
      explanation?: string | null,
      reservationId: string,
      companyId?: string | null,
      startTime: string,
      returnTime: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ReviewsByGroupByStartTimeQueryVariables = {
  group?: string | null,
  startTime?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelReviewFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ReviewsByGroupByStartTimeQuery = {
  reviewsByGroupByStartTime?:  {
    __typename: "ModelReviewConnection",
    items:  Array< {
      __typename: "Review",
      id: string,
      group?: string | null,
      organizationId?: string | null,
      orgBusinessId?: string | null,
      orgExternalBusinessId?: string | null,
      rating: number,
      comment?: string | null,
      explanation?: string | null,
      reservationId: string,
      companyId?: string | null,
      startTime: string,
      returnTime: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ReviewsByOrganizationByStartTimeQueryVariables = {
  organizationId?: string | null,
  startTime?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelReviewFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ReviewsByOrganizationByStartTimeQuery = {
  reviewsByOrganizationByStartTime?:  {
    __typename: "ModelReviewConnection",
    items:  Array< {
      __typename: "Review",
      id: string,
      group?: string | null,
      organizationId?: string | null,
      orgBusinessId?: string | null,
      orgExternalBusinessId?: string | null,
      rating: number,
      comment?: string | null,
      explanation?: string | null,
      reservationId: string,
      companyId?: string | null,
      startTime: string,
      returnTime: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ReviewsByBusinessByStartTimeQueryVariables = {
  orgBusinessId?: string | null,
  startTime?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelReviewFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ReviewsByBusinessByStartTimeQuery = {
  reviewsByBusinessByStartTime?:  {
    __typename: "ModelReviewConnection",
    items:  Array< {
      __typename: "Review",
      id: string,
      group?: string | null,
      organizationId?: string | null,
      orgBusinessId?: string | null,
      orgExternalBusinessId?: string | null,
      rating: number,
      comment?: string | null,
      explanation?: string | null,
      reservationId: string,
      companyId?: string | null,
      startTime: string,
      returnTime: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ReviewsByExternalBusinessByStartTimeQueryVariables = {
  orgExternalBusinessId?: string | null,
  startTime?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelReviewFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ReviewsByExternalBusinessByStartTimeQuery = {
  reviewsByExternalBusinessByStartTime?:  {
    __typename: "ModelReviewConnection",
    items:  Array< {
      __typename: "Review",
      id: string,
      group?: string | null,
      organizationId?: string | null,
      orgBusinessId?: string | null,
      orgExternalBusinessId?: string | null,
      rating: number,
      comment?: string | null,
      explanation?: string | null,
      reservationId: string,
      companyId?: string | null,
      startTime: string,
      returnTime: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListReviewsByReservationIdQueryVariables = {
  reservationId?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelReviewFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListReviewsByReservationIdQuery = {
  listReviewsByReservationId?:  {
    __typename: "ModelReviewConnection",
    items:  Array< {
      __typename: "Review",
      id: string,
      group?: string | null,
      organizationId?: string | null,
      orgBusinessId?: string | null,
      orgExternalBusinessId?: string | null,
      rating: number,
      comment?: string | null,
      explanation?: string | null,
      reservationId: string,
      companyId?: string | null,
      startTime: string,
      returnTime: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetNotificationQueryVariables = {
  id: string,
};

export type GetNotificationQuery = {
  getNotification?:  {
    __typename: "Notification",
    id: string,
    type: NotificationType,
    status?: NotificationStatus | null,
    name?: string | null,
    message?: string | null,
    organizationId?: string | null,
    orgBusinessId?: string | null,
    group?: string | null,
    reservationId?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListNotificationsQueryVariables = {
  filter?: ModelNotificationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListNotificationsQuery = {
  listNotifications?:  {
    __typename: "ModelNotificationConnection",
    items:  Array< {
      __typename: "Notification",
      id: string,
      type: NotificationType,
      status?: NotificationStatus | null,
      name?: string | null,
      message?: string | null,
      organizationId?: string | null,
      orgBusinessId?: string | null,
      group?: string | null,
      reservationId?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListNotificationsByGroupByStatusQueryVariables = {
  group?: string | null,
  status?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelNotificationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListNotificationsByGroupByStatusQuery = {
  listNotificationsByGroupByStatus?:  {
    __typename: "ModelNotificationConnection",
    items:  Array< {
      __typename: "Notification",
      id: string,
      type: NotificationType,
      status?: NotificationStatus | null,
      name?: string | null,
      message?: string | null,
      organizationId?: string | null,
      orgBusinessId?: string | null,
      group?: string | null,
      reservationId?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListNotificationsByGroupByCreatedAtQueryVariables = {
  group?: string | null,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelNotificationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListNotificationsByGroupByCreatedAtQuery = {
  listNotificationsByGroupByCreatedAt?:  {
    __typename: "ModelNotificationConnection",
    items:  Array< {
      __typename: "Notification",
      id: string,
      type: NotificationType,
      status?: NotificationStatus | null,
      name?: string | null,
      message?: string | null,
      organizationId?: string | null,
      orgBusinessId?: string | null,
      group?: string | null,
      reservationId?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListNotificationsByGroupStatusCreatedAtQueryVariables = {
  group?: string | null,
  statusCreatedAt?: ModelNotificationByGroupByStatusByCreatedAtCompositeKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelNotificationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListNotificationsByGroupStatusCreatedAtQuery = {
  listNotificationsByGroupStatusCreatedAt?:  {
    __typename: "ModelNotificationConnection",
    items:  Array< {
      __typename: "Notification",
      id: string,
      type: NotificationType,
      status?: NotificationStatus | null,
      name?: string | null,
      message?: string | null,
      organizationId?: string | null,
      orgBusinessId?: string | null,
      group?: string | null,
      reservationId?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type NotificationsByBusinessByStatusQueryVariables = {
  orgBusinessId?: string | null,
  status?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelNotificationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type NotificationsByBusinessByStatusQuery = {
  notificationsByBusinessByStatus?:  {
    __typename: "ModelNotificationConnection",
    items:  Array< {
      __typename: "Notification",
      id: string,
      type: NotificationType,
      status?: NotificationStatus | null,
      name?: string | null,
      message?: string | null,
      organizationId?: string | null,
      orgBusinessId?: string | null,
      group?: string | null,
      reservationId?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListNotificationsByBusinessStatusCreatedAtQueryVariables = {
  orgBusinessId?: string | null,
  statusCreatedAt?: ModelNotificationByBusinessByStatusByCreatedAtCompositeKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelNotificationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListNotificationsByBusinessStatusCreatedAtQuery = {
  listNotificationsByBusinessStatusCreatedAt?:  {
    __typename: "ModelNotificationConnection",
    items:  Array< {
      __typename: "Notification",
      id: string,
      type: NotificationType,
      status?: NotificationStatus | null,
      name?: string | null,
      message?: string | null,
      organizationId?: string | null,
      orgBusinessId?: string | null,
      group?: string | null,
      reservationId?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetCustomerQueryVariables = {
  id: string,
};

export type GetCustomerQuery = {
  getCustomer?:  {
    __typename: "Customer",
    id: string,
    organizationId?: string | null,
    orgBusinessId?: string | null,
    orgExternalBusinessId?: string | null,
    group?: string | null,
    companyName?: string | null,
    brokerName?: string | null,
    address?: string | null,
    postalCode?: string | null,
    city?: string | null,
    phone?: string | null,
    email?: string | null,
    companyBillingAddress?: string | null,
    companyBusinessId?: string | null,
    companyCity?: string | null,
    companyPostalCode?: string | null,
    companyBillingRef?: string | null,
    companyEBillingOperator?: string | null,
    companyEBillingAddress?: string | null,
    clientType?: string | null,
    isDeleted?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListCustomersQueryVariables = {
  filter?: ModelCustomerFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListCustomersQuery = {
  listCustomers?:  {
    __typename: "ModelCustomerConnection",
    items:  Array< {
      __typename: "Customer",
      id: string,
      organizationId?: string | null,
      orgBusinessId?: string | null,
      orgExternalBusinessId?: string | null,
      group?: string | null,
      companyName?: string | null,
      brokerName?: string | null,
      address?: string | null,
      postalCode?: string | null,
      city?: string | null,
      phone?: string | null,
      email?: string | null,
      companyBillingAddress?: string | null,
      companyBusinessId?: string | null,
      companyCity?: string | null,
      companyPostalCode?: string | null,
      companyBillingRef?: string | null,
      companyEBillingOperator?: string | null,
      companyEBillingAddress?: string | null,
      clientType?: string | null,
      isDeleted?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type SearchCustomersQueryVariables = {
  filter?: SearchableCustomerFilterInput | null,
  sort?: SearchableCustomerSortInput | null,
  limit?: number | null,
  nextToken?: string | null,
  from?: number | null,
};

export type SearchCustomersQuery = {
  searchCustomers?:  {
    __typename: "SearchableCustomerConnection",
    items:  Array< {
      __typename: "Customer",
      id: string,
      organizationId?: string | null,
      orgBusinessId?: string | null,
      orgExternalBusinessId?: string | null,
      group?: string | null,
      companyName?: string | null,
      brokerName?: string | null,
      address?: string | null,
      postalCode?: string | null,
      city?: string | null,
      phone?: string | null,
      email?: string | null,
      companyBillingAddress?: string | null,
      companyBusinessId?: string | null,
      companyCity?: string | null,
      companyPostalCode?: string | null,
      companyBillingRef?: string | null,
      companyEBillingOperator?: string | null,
      companyEBillingAddress?: string | null,
      clientType?: string | null,
      isDeleted?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
    total?: number | null,
  } | null,
};

export type OnCreateNewsItemSubscriptionVariables = {
};

export type OnCreateNewsItemSubscription = {
  onCreateNewsItem?:  {
    __typename: "NewsItem",
    id: string,
    header: string,
    link?: string | null,
    content?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateNewsItemSubscriptionVariables = {
};

export type OnUpdateNewsItemSubscription = {
  onUpdateNewsItem?:  {
    __typename: "NewsItem",
    id: string,
    header: string,
    link?: string | null,
    content?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteNewsItemSubscriptionVariables = {
};

export type OnDeleteNewsItemSubscription = {
  onDeleteNewsItem?:  {
    __typename: "NewsItem",
    id: string,
    header: string,
    link?: string | null,
    content?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateCompanySubscriptionVariables = {
};

export type OnCreateCompanySubscription = {
  onCreateCompany?:  {
    __typename: "Company",
    id: string,
    organizationId?: string | null,
    orgBusinessId?: string | null,
    group?: string | null,
    name: string,
    displayName?: string | null,
    email?: string | null,
    address?: string | null,
    postalCode?: string | null,
    city?: string | null,
    orderNumber?: number | null,
    orderNumberDealer?: number | null,
    phone?: string | null,
    businessId?: string | null,
    termsUrl?: string | null,
    timezone: string,
    weeklyBusinessHours:  Array< {
      __typename: "ClockRange",
      openAt: string,
      closeAt: string,
    } | null >,
    signConfirmationEmail?: string | null,
    nameInEmail?: string | null,
    senderNameSMS?: string | null,
    confirmationMsg?: string | null,
    confirmationMsgT?:  {
      __typename: "Translation",
      fi?: string | null,
      en?: string | null,
      sv?: string | null,
    } | null,
    feedbackMsg?: string | null,
    feedbackMsgT?:  {
      __typename: "Translation",
      fi?: string | null,
      en?: string | null,
      sv?: string | null,
    } | null,
    statusLinkMsg?: string | null,
    statusLinkMsgT?:  {
      __typename: "Translation",
      fi?: string | null,
      en?: string | null,
      sv?: string | null,
    } | null,
    livionDeviceId?: string | null,
    livionDeviceType?: LivionDevice | null,
    enableReservationDeposit?: boolean | null,
    reservationDeposit?:  {
      __typename: "ReservationDeposit",
      amount?: number | null,
      reminderBefore?: number | null,
      paymentBefore?: number | null,
    } | null,
    reservationLinkExpirationHours?: number | null,
    disableOffHourReturn?: boolean | null,
    disableMultiday?: boolean | null,
    disableSameDay?: boolean | null,
    disableStartDaysFromNow?: number | null,
    enableCart?: boolean | null,
    enableMonthlyView?: boolean | null,
    enableRegistrationPlate?: boolean | null,
    enableVehicleStartReturnTimes?: boolean | null,
    enableMustSign?: boolean | null,
    enablePaymentOnlineOnly?: boolean | null,
    enableGTM?: boolean | null,
    enableNetPromoterScore?: boolean | null,
    enableNPS?: boolean | null,
    reservationCancellation?:  {
      __typename: "ReservationCancellationSettings",
      cancellationEnabled?: boolean | null,
      daysBeforeStart?: number | null,
    } | null,
    creditCheck?:  {
      __typename: "AsiakastietoCreditCheck",
      enabled?: boolean | null,
      keyLookup?: boolean | null,
      userId?: string | null,
      passwordId?: string | null,
    } | null,
    limitReservationOncePerDay?: boolean | null,
    hidden?: boolean | null,
    receiptInfo?:  {
      __typename: "ReceiptInfo",
      enabled?: boolean | null,
      name?: string | null,
      address?: string | null,
      city?: string | null,
      postalCode?: string | null,
    } | null,
    innovoice?:  {
      __typename: "InnovoiceData",
      enabled?: boolean | null,
      companyId?: string | null,
      uRentCarRentalId?: string | null,
    } | null,
    locationType?: string | null,
    offHourAvailability?: boolean | null,
    offHourAvailabilityFee?: number | null,
    deliveryFee?: number | null,
    deliveryBufferHours?: number | null,
    notAllowedCategoryIds?: Array< string | null > | null,
    importIdentifier?: string | null,
    features?:  {
      __typename: "Features",
      enableNPS?: boolean | null,
      enableVismaPay?: boolean | null,
      enableReservationDialogV2?: boolean | null,
      enableTilakone?: boolean | null,
      enableGTM?: GTMVersion | null,
      enableSMSConfirmation?: boolean | null,
      enableMustSignBypass?: boolean | null,
    } | null,
    contractSettings?:  Array< {
      __typename: "FeatureSetting",
      name?: string | null,
      enabled?: boolean | null,
      input?: string | null,
    } | null > | null,
    contractSettingsJSON?: string | null,
    categoryDynamicPricings?:  Array< {
      __typename: "CategoryDynamicPricing",
      categoryId?: string | null,
      pricingId?: string | null,
      percentage?: number | null,
    } | null > | null,
    disableReservationCategoryIds?: Array< string | null > | null,
    locationColor?: string | null,
    createdAt: string,
    updatedAt: string,
    merchantDetails?:  {
      __typename: "MerchantDetails",
      api?: VismaPayApi | null,
      merchant_id?: string | null,
      cp?: string | null,
    } | null,
    businessHoursExceptions?:  {
      __typename: "ModelBusinessHoursExceptionConnection",
      items:  Array< {
        __typename: "BusinessHoursException",
        organizationId?: string | null,
        orgBusinessId?: string | null,
        group?: string | null,
        date: string,
        name?: string | null,
        companyId: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    vehicles?:  {
      __typename: "ModelVehicleConnection",
      items:  Array< {
        __typename: "Vehicle",
        id: string,
        group?: string | null,
        organizationId?: string | null,
        orgBusinessId?: string | null,
        companyId: string,
        name: string,
        registrationPlate?: string | null,
        damages?: string | null,
        image?: string | null,
        powerType?: string | null,
        deductible?: number | null,
        includedDistance?: string | null,
        mileage?: string | null,
        orderNumber?: number | null,
        vehicleCategoryId?: string | null,
        useCategoryDefaults?: boolean | null,
        useStartReturnTimes?: boolean | null,
        hideExport?: boolean | null,
        extraInfo?: string | null,
        extraInfoT?: string | null,
        extraToConfirmation?: string | null,
        extraToConfirmationT?: string | null,
        livionKeyId?: string | null,
        phoneNumber?: string | null,
        importData?: string | null,
        disableOnline?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    categories?:  {
      __typename: "ModelCategoryConnection",
      items:  Array< {
        __typename: "Category",
        id: string,
        group?: string | null,
        organizationId?: string | null,
        name: string,
        image?: string | null,
        orderNumber?: number | null,
        backgroundColor?: string | null,
        minuteBuffer?: number | null,
        reservationBlockFromNow?: number | null,
        typeId?: string | null,
        _removed?: string | null,
        oldTujausValue?: string | null,
        exampleModelName?: string | null,
        exampleModelImage?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    categoriesByOrg?:  {
      __typename: "ModelCategoryConnection",
      items:  Array< {
        __typename: "Category",
        id: string,
        group?: string | null,
        organizationId?: string | null,
        name: string,
        image?: string | null,
        orderNumber?: number | null,
        backgroundColor?: string | null,
        minuteBuffer?: number | null,
        reservationBlockFromNow?: number | null,
        typeId?: string | null,
        _removed?: string | null,
        oldTujausValue?: string | null,
        exampleModelName?: string | null,
        exampleModelImage?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type OnUpdateCompanySubscriptionVariables = {
};

export type OnUpdateCompanySubscription = {
  onUpdateCompany?:  {
    __typename: "Company",
    id: string,
    organizationId?: string | null,
    orgBusinessId?: string | null,
    group?: string | null,
    name: string,
    displayName?: string | null,
    email?: string | null,
    address?: string | null,
    postalCode?: string | null,
    city?: string | null,
    orderNumber?: number | null,
    orderNumberDealer?: number | null,
    phone?: string | null,
    businessId?: string | null,
    termsUrl?: string | null,
    timezone: string,
    weeklyBusinessHours:  Array< {
      __typename: "ClockRange",
      openAt: string,
      closeAt: string,
    } | null >,
    signConfirmationEmail?: string | null,
    nameInEmail?: string | null,
    senderNameSMS?: string | null,
    confirmationMsg?: string | null,
    confirmationMsgT?:  {
      __typename: "Translation",
      fi?: string | null,
      en?: string | null,
      sv?: string | null,
    } | null,
    feedbackMsg?: string | null,
    feedbackMsgT?:  {
      __typename: "Translation",
      fi?: string | null,
      en?: string | null,
      sv?: string | null,
    } | null,
    statusLinkMsg?: string | null,
    statusLinkMsgT?:  {
      __typename: "Translation",
      fi?: string | null,
      en?: string | null,
      sv?: string | null,
    } | null,
    livionDeviceId?: string | null,
    livionDeviceType?: LivionDevice | null,
    enableReservationDeposit?: boolean | null,
    reservationDeposit?:  {
      __typename: "ReservationDeposit",
      amount?: number | null,
      reminderBefore?: number | null,
      paymentBefore?: number | null,
    } | null,
    reservationLinkExpirationHours?: number | null,
    disableOffHourReturn?: boolean | null,
    disableMultiday?: boolean | null,
    disableSameDay?: boolean | null,
    disableStartDaysFromNow?: number | null,
    enableCart?: boolean | null,
    enableMonthlyView?: boolean | null,
    enableRegistrationPlate?: boolean | null,
    enableVehicleStartReturnTimes?: boolean | null,
    enableMustSign?: boolean | null,
    enablePaymentOnlineOnly?: boolean | null,
    enableGTM?: boolean | null,
    enableNetPromoterScore?: boolean | null,
    enableNPS?: boolean | null,
    reservationCancellation?:  {
      __typename: "ReservationCancellationSettings",
      cancellationEnabled?: boolean | null,
      daysBeforeStart?: number | null,
    } | null,
    creditCheck?:  {
      __typename: "AsiakastietoCreditCheck",
      enabled?: boolean | null,
      keyLookup?: boolean | null,
      userId?: string | null,
      passwordId?: string | null,
    } | null,
    limitReservationOncePerDay?: boolean | null,
    hidden?: boolean | null,
    receiptInfo?:  {
      __typename: "ReceiptInfo",
      enabled?: boolean | null,
      name?: string | null,
      address?: string | null,
      city?: string | null,
      postalCode?: string | null,
    } | null,
    innovoice?:  {
      __typename: "InnovoiceData",
      enabled?: boolean | null,
      companyId?: string | null,
      uRentCarRentalId?: string | null,
    } | null,
    locationType?: string | null,
    offHourAvailability?: boolean | null,
    offHourAvailabilityFee?: number | null,
    deliveryFee?: number | null,
    deliveryBufferHours?: number | null,
    notAllowedCategoryIds?: Array< string | null > | null,
    importIdentifier?: string | null,
    features?:  {
      __typename: "Features",
      enableNPS?: boolean | null,
      enableVismaPay?: boolean | null,
      enableReservationDialogV2?: boolean | null,
      enableTilakone?: boolean | null,
      enableGTM?: GTMVersion | null,
      enableSMSConfirmation?: boolean | null,
      enableMustSignBypass?: boolean | null,
    } | null,
    contractSettings?:  Array< {
      __typename: "FeatureSetting",
      name?: string | null,
      enabled?: boolean | null,
      input?: string | null,
    } | null > | null,
    contractSettingsJSON?: string | null,
    categoryDynamicPricings?:  Array< {
      __typename: "CategoryDynamicPricing",
      categoryId?: string | null,
      pricingId?: string | null,
      percentage?: number | null,
    } | null > | null,
    disableReservationCategoryIds?: Array< string | null > | null,
    locationColor?: string | null,
    createdAt: string,
    updatedAt: string,
    merchantDetails?:  {
      __typename: "MerchantDetails",
      api?: VismaPayApi | null,
      merchant_id?: string | null,
      cp?: string | null,
    } | null,
    businessHoursExceptions?:  {
      __typename: "ModelBusinessHoursExceptionConnection",
      items:  Array< {
        __typename: "BusinessHoursException",
        organizationId?: string | null,
        orgBusinessId?: string | null,
        group?: string | null,
        date: string,
        name?: string | null,
        companyId: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    vehicles?:  {
      __typename: "ModelVehicleConnection",
      items:  Array< {
        __typename: "Vehicle",
        id: string,
        group?: string | null,
        organizationId?: string | null,
        orgBusinessId?: string | null,
        companyId: string,
        name: string,
        registrationPlate?: string | null,
        damages?: string | null,
        image?: string | null,
        powerType?: string | null,
        deductible?: number | null,
        includedDistance?: string | null,
        mileage?: string | null,
        orderNumber?: number | null,
        vehicleCategoryId?: string | null,
        useCategoryDefaults?: boolean | null,
        useStartReturnTimes?: boolean | null,
        hideExport?: boolean | null,
        extraInfo?: string | null,
        extraInfoT?: string | null,
        extraToConfirmation?: string | null,
        extraToConfirmationT?: string | null,
        livionKeyId?: string | null,
        phoneNumber?: string | null,
        importData?: string | null,
        disableOnline?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    categories?:  {
      __typename: "ModelCategoryConnection",
      items:  Array< {
        __typename: "Category",
        id: string,
        group?: string | null,
        organizationId?: string | null,
        name: string,
        image?: string | null,
        orderNumber?: number | null,
        backgroundColor?: string | null,
        minuteBuffer?: number | null,
        reservationBlockFromNow?: number | null,
        typeId?: string | null,
        _removed?: string | null,
        oldTujausValue?: string | null,
        exampleModelName?: string | null,
        exampleModelImage?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    categoriesByOrg?:  {
      __typename: "ModelCategoryConnection",
      items:  Array< {
        __typename: "Category",
        id: string,
        group?: string | null,
        organizationId?: string | null,
        name: string,
        image?: string | null,
        orderNumber?: number | null,
        backgroundColor?: string | null,
        minuteBuffer?: number | null,
        reservationBlockFromNow?: number | null,
        typeId?: string | null,
        _removed?: string | null,
        oldTujausValue?: string | null,
        exampleModelName?: string | null,
        exampleModelImage?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type OnDeleteCompanySubscriptionVariables = {
};

export type OnDeleteCompanySubscription = {
  onDeleteCompany?:  {
    __typename: "Company",
    id: string,
    organizationId?: string | null,
    orgBusinessId?: string | null,
    group?: string | null,
    name: string,
    displayName?: string | null,
    email?: string | null,
    address?: string | null,
    postalCode?: string | null,
    city?: string | null,
    orderNumber?: number | null,
    orderNumberDealer?: number | null,
    phone?: string | null,
    businessId?: string | null,
    termsUrl?: string | null,
    timezone: string,
    weeklyBusinessHours:  Array< {
      __typename: "ClockRange",
      openAt: string,
      closeAt: string,
    } | null >,
    signConfirmationEmail?: string | null,
    nameInEmail?: string | null,
    senderNameSMS?: string | null,
    confirmationMsg?: string | null,
    confirmationMsgT?:  {
      __typename: "Translation",
      fi?: string | null,
      en?: string | null,
      sv?: string | null,
    } | null,
    feedbackMsg?: string | null,
    feedbackMsgT?:  {
      __typename: "Translation",
      fi?: string | null,
      en?: string | null,
      sv?: string | null,
    } | null,
    statusLinkMsg?: string | null,
    statusLinkMsgT?:  {
      __typename: "Translation",
      fi?: string | null,
      en?: string | null,
      sv?: string | null,
    } | null,
    livionDeviceId?: string | null,
    livionDeviceType?: LivionDevice | null,
    enableReservationDeposit?: boolean | null,
    reservationDeposit?:  {
      __typename: "ReservationDeposit",
      amount?: number | null,
      reminderBefore?: number | null,
      paymentBefore?: number | null,
    } | null,
    reservationLinkExpirationHours?: number | null,
    disableOffHourReturn?: boolean | null,
    disableMultiday?: boolean | null,
    disableSameDay?: boolean | null,
    disableStartDaysFromNow?: number | null,
    enableCart?: boolean | null,
    enableMonthlyView?: boolean | null,
    enableRegistrationPlate?: boolean | null,
    enableVehicleStartReturnTimes?: boolean | null,
    enableMustSign?: boolean | null,
    enablePaymentOnlineOnly?: boolean | null,
    enableGTM?: boolean | null,
    enableNetPromoterScore?: boolean | null,
    enableNPS?: boolean | null,
    reservationCancellation?:  {
      __typename: "ReservationCancellationSettings",
      cancellationEnabled?: boolean | null,
      daysBeforeStart?: number | null,
    } | null,
    creditCheck?:  {
      __typename: "AsiakastietoCreditCheck",
      enabled?: boolean | null,
      keyLookup?: boolean | null,
      userId?: string | null,
      passwordId?: string | null,
    } | null,
    limitReservationOncePerDay?: boolean | null,
    hidden?: boolean | null,
    receiptInfo?:  {
      __typename: "ReceiptInfo",
      enabled?: boolean | null,
      name?: string | null,
      address?: string | null,
      city?: string | null,
      postalCode?: string | null,
    } | null,
    innovoice?:  {
      __typename: "InnovoiceData",
      enabled?: boolean | null,
      companyId?: string | null,
      uRentCarRentalId?: string | null,
    } | null,
    locationType?: string | null,
    offHourAvailability?: boolean | null,
    offHourAvailabilityFee?: number | null,
    deliveryFee?: number | null,
    deliveryBufferHours?: number | null,
    notAllowedCategoryIds?: Array< string | null > | null,
    importIdentifier?: string | null,
    features?:  {
      __typename: "Features",
      enableNPS?: boolean | null,
      enableVismaPay?: boolean | null,
      enableReservationDialogV2?: boolean | null,
      enableTilakone?: boolean | null,
      enableGTM?: GTMVersion | null,
      enableSMSConfirmation?: boolean | null,
      enableMustSignBypass?: boolean | null,
    } | null,
    contractSettings?:  Array< {
      __typename: "FeatureSetting",
      name?: string | null,
      enabled?: boolean | null,
      input?: string | null,
    } | null > | null,
    contractSettingsJSON?: string | null,
    categoryDynamicPricings?:  Array< {
      __typename: "CategoryDynamicPricing",
      categoryId?: string | null,
      pricingId?: string | null,
      percentage?: number | null,
    } | null > | null,
    disableReservationCategoryIds?: Array< string | null > | null,
    locationColor?: string | null,
    createdAt: string,
    updatedAt: string,
    merchantDetails?:  {
      __typename: "MerchantDetails",
      api?: VismaPayApi | null,
      merchant_id?: string | null,
      cp?: string | null,
    } | null,
    businessHoursExceptions?:  {
      __typename: "ModelBusinessHoursExceptionConnection",
      items:  Array< {
        __typename: "BusinessHoursException",
        organizationId?: string | null,
        orgBusinessId?: string | null,
        group?: string | null,
        date: string,
        name?: string | null,
        companyId: string,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    vehicles?:  {
      __typename: "ModelVehicleConnection",
      items:  Array< {
        __typename: "Vehicle",
        id: string,
        group?: string | null,
        organizationId?: string | null,
        orgBusinessId?: string | null,
        companyId: string,
        name: string,
        registrationPlate?: string | null,
        damages?: string | null,
        image?: string | null,
        powerType?: string | null,
        deductible?: number | null,
        includedDistance?: string | null,
        mileage?: string | null,
        orderNumber?: number | null,
        vehicleCategoryId?: string | null,
        useCategoryDefaults?: boolean | null,
        useStartReturnTimes?: boolean | null,
        hideExport?: boolean | null,
        extraInfo?: string | null,
        extraInfoT?: string | null,
        extraToConfirmation?: string | null,
        extraToConfirmationT?: string | null,
        livionKeyId?: string | null,
        phoneNumber?: string | null,
        importData?: string | null,
        disableOnline?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    categories?:  {
      __typename: "ModelCategoryConnection",
      items:  Array< {
        __typename: "Category",
        id: string,
        group?: string | null,
        organizationId?: string | null,
        name: string,
        image?: string | null,
        orderNumber?: number | null,
        backgroundColor?: string | null,
        minuteBuffer?: number | null,
        reservationBlockFromNow?: number | null,
        typeId?: string | null,
        _removed?: string | null,
        oldTujausValue?: string | null,
        exampleModelName?: string | null,
        exampleModelImage?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    categoriesByOrg?:  {
      __typename: "ModelCategoryConnection",
      items:  Array< {
        __typename: "Category",
        id: string,
        group?: string | null,
        organizationId?: string | null,
        name: string,
        image?: string | null,
        orderNumber?: number | null,
        backgroundColor?: string | null,
        minuteBuffer?: number | null,
        reservationBlockFromNow?: number | null,
        typeId?: string | null,
        _removed?: string | null,
        oldTujausValue?: string | null,
        exampleModelName?: string | null,
        exampleModelImage?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type OnCreateBusinessHoursExceptionSubscriptionVariables = {
};

export type OnCreateBusinessHoursExceptionSubscription = {
  onCreateBusinessHoursException?:  {
    __typename: "BusinessHoursException",
    organizationId?: string | null,
    orgBusinessId?: string | null,
    group?: string | null,
    date: string,
    name?: string | null,
    businessHours?:  {
      __typename: "ClockRange",
      openAt: string,
      closeAt: string,
    } | null,
    companyId: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateBusinessHoursExceptionSubscriptionVariables = {
};

export type OnUpdateBusinessHoursExceptionSubscription = {
  onUpdateBusinessHoursException?:  {
    __typename: "BusinessHoursException",
    organizationId?: string | null,
    orgBusinessId?: string | null,
    group?: string | null,
    date: string,
    name?: string | null,
    businessHours?:  {
      __typename: "ClockRange",
      openAt: string,
      closeAt: string,
    } | null,
    companyId: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteBusinessHoursExceptionSubscriptionVariables = {
};

export type OnDeleteBusinessHoursExceptionSubscription = {
  onDeleteBusinessHoursException?:  {
    __typename: "BusinessHoursException",
    organizationId?: string | null,
    orgBusinessId?: string | null,
    group?: string | null,
    date: string,
    name?: string | null,
    businessHours?:  {
      __typename: "ClockRange",
      openAt: string,
      closeAt: string,
    } | null,
    companyId: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateVehicleSubscriptionVariables = {
};

export type OnCreateVehicleSubscription = {
  onCreateVehicle?:  {
    __typename: "Vehicle",
    id: string,
    group?: string | null,
    organizationId?: string | null,
    orgBusinessId?: string | null,
    companyId: string,
    name: string,
    nameT?:  {
      __typename: "Translation",
      fi?: string | null,
      en?: string | null,
      sv?: string | null,
    } | null,
    registrationPlate?: string | null,
    damages?: string | null,
    damageRecords?:  Array< {
      __typename: "DamageRecord",
      normalizedX?: number | null,
      normalizedY?: number | null,
      label?: string | null,
      description?: string | null,
      createdAt?: string | null,
    } > | null,
    additionalFields:  Array< {
      __typename: "AdditionalField",
      key: string,
      value?: string | null,
      valueT?:  {
        __typename: "Translation",
        fi?: string | null,
        en?: string | null,
        sv?: string | null,
      } | null,
    } >,
    image?: string | null,
    powerType?: string | null,
    deductible?: number | null,
    includedDistance?: string | null,
    mileage?: string | null,
    orderNumber?: number | null,
    vehicleCategoryId?: string | null,
    useCategoryDefaults?: boolean | null,
    useStartReturnTimes?: boolean | null,
    customStartReturns?:  Array< {
      __typename: "CustomStartReturn",
      priority?: number | null,
      startReturnInterval?:  {
        __typename: "StartReturnInterval",
        start?: string | null,
        end?: string | null,
      } | null,
      startReturnTimes?:  Array< {
        __typename: "StartReturnTimes",
        start?: Array< string | null > | null,
        return?: Array< string | null > | null,
      } | null > | null,
    } | null > | null,
    startReturnInterval?:  {
      __typename: "StartReturnInterval",
      start?: string | null,
      end?: string | null,
    } | null,
    startReturnTimes?:  Array< {
      __typename: "StartReturnTimes",
      start?: Array< string | null > | null,
      return?: Array< string | null > | null,
    } | null > | null,
    hideExport?: boolean | null,
    extraInfo?: string | null,
    extraInfoT?: string | null,
    extraToConfirmation?: string | null,
    extraToConfirmationT?: string | null,
    livionKeyId?: string | null,
    phoneNumber?: string | null,
    importData?: string | null,
    disableOnline?: boolean | null,
    activeRange?:  {
      __typename: "StartReturnInterval",
      start?: string | null,
      end?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    category?:  {
      __typename: "Category",
      id: string,
      group?: string | null,
      organizationId?: string | null,
      name: string,
      image?: string | null,
      orderNumber?: number | null,
      backgroundColor?: string | null,
      minuteBuffer?: number | null,
      reservationBlockFromNow?: number | null,
      typeId?: string | null,
      _removed?: string | null,
      oldTujausValue?: string | null,
      exampleModelName?: string | null,
      exampleModelImage?: string | null,
      createdAt: string,
      updatedAt: string,
      additionalServices?:  {
        __typename: "ModelAdditionalServicePricingConnection",
        nextToken?: string | null,
      } | null,
      pricing?:  {
        __typename: "Pricing",
        id: string,
        name: string,
        group?: string | null,
        organizationId?: string | null,
        orgBusinessId?: string | null,
        orgExternalBusinessId?: string | null,
        externalBusinessCategoryAssociation?: string | null,
        businessCategoryAssociation?: string | null,
        useNextDayPrice?: boolean | null,
        useLastDayPrice?: boolean | null,
        tax?: number | null,
        _removed?: string | null,
        deductible?: number | null,
        reducedDeductible?: number | null,
        reducedDeductiblePrice?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      externalBusinessPricings?:  {
        __typename: "ModelPricingConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    additionalServices?:  {
      __typename: "ModelAdditionalServicePricingConnection",
      items:  Array< {
        __typename: "AdditionalServicePricing",
        id: string,
        group?: string | null,
        organizationId?: string | null,
        orgBusinessId?: string | null,
        key: string,
        vehicleId?: string | null,
        categoryId?: string | null,
        pricingId: string,
        deductibleValue?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    reservations?:  {
      __typename: "ModelReservationConnection",
      items:  Array< {
        __typename: "Reservation",
        id: string,
        shortId?: string | null,
        language?: Language | null,
        group?: string | null,
        organizationId?: string | null,
        orgBusinessId?: string | null,
        orgExternalBusinessId?: string | null,
        companyId?: string | null,
        returnCompanyId?: string | null,
        startLocation?: string | null,
        returnLocation?: string | null,
        startLocationID?: string | null,
        returnLocationID?: string | null,
        reviewId?: string | null,
        customerBrokerId?: string | null,
        customerCompanyId?: string | null,
        customerPersonalId?: string | null,
        reservationVehicleId?: string | null,
        createdAt: string,
        updatedAt: string,
        startTime?: string | null,
        returnTime?: string | null,
        endTime?: string | null,
        name?: string | null,
        address?: string | null,
        postalCode?: string | null,
        city?: string | null,
        phone?: string | null,
        email?: string | null,
        billingRef?: string | null,
        customerNotes?: string | null,
        ssn?: string | null,
        rentalNotes?: string | null,
        internalNotes?: string | null,
        externalNotes?: string | null,
        outOfCommission?: boolean | null,
        vehiclePrice?: number | null,
        driverSSN?: string | null,
        differentDriver?: boolean | null,
        driverName?: string | null,
        driverAddress?: string | null,
        driverPostalCode?: string | null,
        driverCity?: string | null,
        driverPhone?: string | null,
        driverEmail?: string | null,
        additionalDriver?: boolean | null,
        additionalDriverName?: string | null,
        additionalDriverPhone?: string | null,
        additionalDriverSSN?: string | null,
        billPayment?: boolean | null,
        isCompany?: boolean | null,
        carStatus?: RentalStatus | null,
        rentalStatus?: RentalStatus | null,
        innovoiceStatus?: ReservationInnovoiceStatus | null,
        companyName?: string | null,
        companyBusinessId?: string | null,
        companyCity?: string | null,
        companyPostalCode?: string | null,
        companyBillingRef?: string | null,
        companyBillingAddress?: string | null,
        companyEBillingOperator?: string | null,
        companyEBillingAddress?: string | null,
        paymentToken?: string | null,
        npsStatus?: NpsStatus | null,
        paymentStatus?: PaymentStatus | null,
        signStatus?: SignStatus | null,
        signDocumentId?: string | null,
        signerName?: string | null,
        reviewStatus?: ReviewStatus | null,
        authStatus?: AuthStatus | null,
        authTime?: string | null,
        completeBefore?: string | null,
        mustSign?: boolean | null,
        customerCarRegistrationPlate?: string | null,
        enableRegistrationPlate?: boolean | null,
        disablePricesInPrintouts?: boolean | null,
        invoiceIds?: Array< string > | null,
        consolidatedInvoiceIds?: Array< string > | null,
        channel?: ReservationChannel | null,
        originURL?: string | null,
        type?: ReservationType | null,
        status?: string | null,
        pricingCategoryId?: string | null,
        customImportDate?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    pricing?:  {
      __typename: "Pricing",
      id: string,
      name: string,
      group?: string | null,
      organizationId?: string | null,
      orgBusinessId?: string | null,
      orgExternalBusinessId?: string | null,
      externalBusinessCategoryAssociation?: string | null,
      businessCategoryAssociation?: string | null,
      elements:  Array< {
        __typename: "PricingElement",
        duration: string,
        price: number,
      } >,
      useNextDayPrice?: boolean | null,
      useLastDayPrice?: boolean | null,
      tax?: number | null,
      _removed?: string | null,
      deductible?: number | null,
      reducedDeductible?: number | null,
      reducedDeductiblePrice?: number | null,
      createdAt: string,
      updatedAt: string,
    } | null,
  } | null,
};

export type OnUpdateVehicleSubscriptionVariables = {
};

export type OnUpdateVehicleSubscription = {
  onUpdateVehicle?:  {
    __typename: "Vehicle",
    id: string,
    group?: string | null,
    organizationId?: string | null,
    orgBusinessId?: string | null,
    companyId: string,
    name: string,
    nameT?:  {
      __typename: "Translation",
      fi?: string | null,
      en?: string | null,
      sv?: string | null,
    } | null,
    registrationPlate?: string | null,
    damages?: string | null,
    damageRecords?:  Array< {
      __typename: "DamageRecord",
      normalizedX?: number | null,
      normalizedY?: number | null,
      label?: string | null,
      description?: string | null,
      createdAt?: string | null,
    } > | null,
    additionalFields:  Array< {
      __typename: "AdditionalField",
      key: string,
      value?: string | null,
      valueT?:  {
        __typename: "Translation",
        fi?: string | null,
        en?: string | null,
        sv?: string | null,
      } | null,
    } >,
    image?: string | null,
    powerType?: string | null,
    deductible?: number | null,
    includedDistance?: string | null,
    mileage?: string | null,
    orderNumber?: number | null,
    vehicleCategoryId?: string | null,
    useCategoryDefaults?: boolean | null,
    useStartReturnTimes?: boolean | null,
    customStartReturns?:  Array< {
      __typename: "CustomStartReturn",
      priority?: number | null,
      startReturnInterval?:  {
        __typename: "StartReturnInterval",
        start?: string | null,
        end?: string | null,
      } | null,
      startReturnTimes?:  Array< {
        __typename: "StartReturnTimes",
        start?: Array< string | null > | null,
        return?: Array< string | null > | null,
      } | null > | null,
    } | null > | null,
    startReturnInterval?:  {
      __typename: "StartReturnInterval",
      start?: string | null,
      end?: string | null,
    } | null,
    startReturnTimes?:  Array< {
      __typename: "StartReturnTimes",
      start?: Array< string | null > | null,
      return?: Array< string | null > | null,
    } | null > | null,
    hideExport?: boolean | null,
    extraInfo?: string | null,
    extraInfoT?: string | null,
    extraToConfirmation?: string | null,
    extraToConfirmationT?: string | null,
    livionKeyId?: string | null,
    phoneNumber?: string | null,
    importData?: string | null,
    disableOnline?: boolean | null,
    activeRange?:  {
      __typename: "StartReturnInterval",
      start?: string | null,
      end?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    category?:  {
      __typename: "Category",
      id: string,
      group?: string | null,
      organizationId?: string | null,
      name: string,
      image?: string | null,
      orderNumber?: number | null,
      backgroundColor?: string | null,
      minuteBuffer?: number | null,
      reservationBlockFromNow?: number | null,
      typeId?: string | null,
      _removed?: string | null,
      oldTujausValue?: string | null,
      exampleModelName?: string | null,
      exampleModelImage?: string | null,
      createdAt: string,
      updatedAt: string,
      additionalServices?:  {
        __typename: "ModelAdditionalServicePricingConnection",
        nextToken?: string | null,
      } | null,
      pricing?:  {
        __typename: "Pricing",
        id: string,
        name: string,
        group?: string | null,
        organizationId?: string | null,
        orgBusinessId?: string | null,
        orgExternalBusinessId?: string | null,
        externalBusinessCategoryAssociation?: string | null,
        businessCategoryAssociation?: string | null,
        useNextDayPrice?: boolean | null,
        useLastDayPrice?: boolean | null,
        tax?: number | null,
        _removed?: string | null,
        deductible?: number | null,
        reducedDeductible?: number | null,
        reducedDeductiblePrice?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      externalBusinessPricings?:  {
        __typename: "ModelPricingConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    additionalServices?:  {
      __typename: "ModelAdditionalServicePricingConnection",
      items:  Array< {
        __typename: "AdditionalServicePricing",
        id: string,
        group?: string | null,
        organizationId?: string | null,
        orgBusinessId?: string | null,
        key: string,
        vehicleId?: string | null,
        categoryId?: string | null,
        pricingId: string,
        deductibleValue?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    reservations?:  {
      __typename: "ModelReservationConnection",
      items:  Array< {
        __typename: "Reservation",
        id: string,
        shortId?: string | null,
        language?: Language | null,
        group?: string | null,
        organizationId?: string | null,
        orgBusinessId?: string | null,
        orgExternalBusinessId?: string | null,
        companyId?: string | null,
        returnCompanyId?: string | null,
        startLocation?: string | null,
        returnLocation?: string | null,
        startLocationID?: string | null,
        returnLocationID?: string | null,
        reviewId?: string | null,
        customerBrokerId?: string | null,
        customerCompanyId?: string | null,
        customerPersonalId?: string | null,
        reservationVehicleId?: string | null,
        createdAt: string,
        updatedAt: string,
        startTime?: string | null,
        returnTime?: string | null,
        endTime?: string | null,
        name?: string | null,
        address?: string | null,
        postalCode?: string | null,
        city?: string | null,
        phone?: string | null,
        email?: string | null,
        billingRef?: string | null,
        customerNotes?: string | null,
        ssn?: string | null,
        rentalNotes?: string | null,
        internalNotes?: string | null,
        externalNotes?: string | null,
        outOfCommission?: boolean | null,
        vehiclePrice?: number | null,
        driverSSN?: string | null,
        differentDriver?: boolean | null,
        driverName?: string | null,
        driverAddress?: string | null,
        driverPostalCode?: string | null,
        driverCity?: string | null,
        driverPhone?: string | null,
        driverEmail?: string | null,
        additionalDriver?: boolean | null,
        additionalDriverName?: string | null,
        additionalDriverPhone?: string | null,
        additionalDriverSSN?: string | null,
        billPayment?: boolean | null,
        isCompany?: boolean | null,
        carStatus?: RentalStatus | null,
        rentalStatus?: RentalStatus | null,
        innovoiceStatus?: ReservationInnovoiceStatus | null,
        companyName?: string | null,
        companyBusinessId?: string | null,
        companyCity?: string | null,
        companyPostalCode?: string | null,
        companyBillingRef?: string | null,
        companyBillingAddress?: string | null,
        companyEBillingOperator?: string | null,
        companyEBillingAddress?: string | null,
        paymentToken?: string | null,
        npsStatus?: NpsStatus | null,
        paymentStatus?: PaymentStatus | null,
        signStatus?: SignStatus | null,
        signDocumentId?: string | null,
        signerName?: string | null,
        reviewStatus?: ReviewStatus | null,
        authStatus?: AuthStatus | null,
        authTime?: string | null,
        completeBefore?: string | null,
        mustSign?: boolean | null,
        customerCarRegistrationPlate?: string | null,
        enableRegistrationPlate?: boolean | null,
        disablePricesInPrintouts?: boolean | null,
        invoiceIds?: Array< string > | null,
        consolidatedInvoiceIds?: Array< string > | null,
        channel?: ReservationChannel | null,
        originURL?: string | null,
        type?: ReservationType | null,
        status?: string | null,
        pricingCategoryId?: string | null,
        customImportDate?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    pricing?:  {
      __typename: "Pricing",
      id: string,
      name: string,
      group?: string | null,
      organizationId?: string | null,
      orgBusinessId?: string | null,
      orgExternalBusinessId?: string | null,
      externalBusinessCategoryAssociation?: string | null,
      businessCategoryAssociation?: string | null,
      elements:  Array< {
        __typename: "PricingElement",
        duration: string,
        price: number,
      } >,
      useNextDayPrice?: boolean | null,
      useLastDayPrice?: boolean | null,
      tax?: number | null,
      _removed?: string | null,
      deductible?: number | null,
      reducedDeductible?: number | null,
      reducedDeductiblePrice?: number | null,
      createdAt: string,
      updatedAt: string,
    } | null,
  } | null,
};

export type OnDeleteVehicleSubscriptionVariables = {
};

export type OnDeleteVehicleSubscription = {
  onDeleteVehicle?:  {
    __typename: "Vehicle",
    id: string,
    group?: string | null,
    organizationId?: string | null,
    orgBusinessId?: string | null,
    companyId: string,
    name: string,
    nameT?:  {
      __typename: "Translation",
      fi?: string | null,
      en?: string | null,
      sv?: string | null,
    } | null,
    registrationPlate?: string | null,
    damages?: string | null,
    damageRecords?:  Array< {
      __typename: "DamageRecord",
      normalizedX?: number | null,
      normalizedY?: number | null,
      label?: string | null,
      description?: string | null,
      createdAt?: string | null,
    } > | null,
    additionalFields:  Array< {
      __typename: "AdditionalField",
      key: string,
      value?: string | null,
      valueT?:  {
        __typename: "Translation",
        fi?: string | null,
        en?: string | null,
        sv?: string | null,
      } | null,
    } >,
    image?: string | null,
    powerType?: string | null,
    deductible?: number | null,
    includedDistance?: string | null,
    mileage?: string | null,
    orderNumber?: number | null,
    vehicleCategoryId?: string | null,
    useCategoryDefaults?: boolean | null,
    useStartReturnTimes?: boolean | null,
    customStartReturns?:  Array< {
      __typename: "CustomStartReturn",
      priority?: number | null,
      startReturnInterval?:  {
        __typename: "StartReturnInterval",
        start?: string | null,
        end?: string | null,
      } | null,
      startReturnTimes?:  Array< {
        __typename: "StartReturnTimes",
        start?: Array< string | null > | null,
        return?: Array< string | null > | null,
      } | null > | null,
    } | null > | null,
    startReturnInterval?:  {
      __typename: "StartReturnInterval",
      start?: string | null,
      end?: string | null,
    } | null,
    startReturnTimes?:  Array< {
      __typename: "StartReturnTimes",
      start?: Array< string | null > | null,
      return?: Array< string | null > | null,
    } | null > | null,
    hideExport?: boolean | null,
    extraInfo?: string | null,
    extraInfoT?: string | null,
    extraToConfirmation?: string | null,
    extraToConfirmationT?: string | null,
    livionKeyId?: string | null,
    phoneNumber?: string | null,
    importData?: string | null,
    disableOnline?: boolean | null,
    activeRange?:  {
      __typename: "StartReturnInterval",
      start?: string | null,
      end?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    category?:  {
      __typename: "Category",
      id: string,
      group?: string | null,
      organizationId?: string | null,
      name: string,
      image?: string | null,
      orderNumber?: number | null,
      backgroundColor?: string | null,
      minuteBuffer?: number | null,
      reservationBlockFromNow?: number | null,
      typeId?: string | null,
      _removed?: string | null,
      oldTujausValue?: string | null,
      exampleModelName?: string | null,
      exampleModelImage?: string | null,
      createdAt: string,
      updatedAt: string,
      additionalServices?:  {
        __typename: "ModelAdditionalServicePricingConnection",
        nextToken?: string | null,
      } | null,
      pricing?:  {
        __typename: "Pricing",
        id: string,
        name: string,
        group?: string | null,
        organizationId?: string | null,
        orgBusinessId?: string | null,
        orgExternalBusinessId?: string | null,
        externalBusinessCategoryAssociation?: string | null,
        businessCategoryAssociation?: string | null,
        useNextDayPrice?: boolean | null,
        useLastDayPrice?: boolean | null,
        tax?: number | null,
        _removed?: string | null,
        deductible?: number | null,
        reducedDeductible?: number | null,
        reducedDeductiblePrice?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      externalBusinessPricings?:  {
        __typename: "ModelPricingConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    additionalServices?:  {
      __typename: "ModelAdditionalServicePricingConnection",
      items:  Array< {
        __typename: "AdditionalServicePricing",
        id: string,
        group?: string | null,
        organizationId?: string | null,
        orgBusinessId?: string | null,
        key: string,
        vehicleId?: string | null,
        categoryId?: string | null,
        pricingId: string,
        deductibleValue?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    reservations?:  {
      __typename: "ModelReservationConnection",
      items:  Array< {
        __typename: "Reservation",
        id: string,
        shortId?: string | null,
        language?: Language | null,
        group?: string | null,
        organizationId?: string | null,
        orgBusinessId?: string | null,
        orgExternalBusinessId?: string | null,
        companyId?: string | null,
        returnCompanyId?: string | null,
        startLocation?: string | null,
        returnLocation?: string | null,
        startLocationID?: string | null,
        returnLocationID?: string | null,
        reviewId?: string | null,
        customerBrokerId?: string | null,
        customerCompanyId?: string | null,
        customerPersonalId?: string | null,
        reservationVehicleId?: string | null,
        createdAt: string,
        updatedAt: string,
        startTime?: string | null,
        returnTime?: string | null,
        endTime?: string | null,
        name?: string | null,
        address?: string | null,
        postalCode?: string | null,
        city?: string | null,
        phone?: string | null,
        email?: string | null,
        billingRef?: string | null,
        customerNotes?: string | null,
        ssn?: string | null,
        rentalNotes?: string | null,
        internalNotes?: string | null,
        externalNotes?: string | null,
        outOfCommission?: boolean | null,
        vehiclePrice?: number | null,
        driverSSN?: string | null,
        differentDriver?: boolean | null,
        driverName?: string | null,
        driverAddress?: string | null,
        driverPostalCode?: string | null,
        driverCity?: string | null,
        driverPhone?: string | null,
        driverEmail?: string | null,
        additionalDriver?: boolean | null,
        additionalDriverName?: string | null,
        additionalDriverPhone?: string | null,
        additionalDriverSSN?: string | null,
        billPayment?: boolean | null,
        isCompany?: boolean | null,
        carStatus?: RentalStatus | null,
        rentalStatus?: RentalStatus | null,
        innovoiceStatus?: ReservationInnovoiceStatus | null,
        companyName?: string | null,
        companyBusinessId?: string | null,
        companyCity?: string | null,
        companyPostalCode?: string | null,
        companyBillingRef?: string | null,
        companyBillingAddress?: string | null,
        companyEBillingOperator?: string | null,
        companyEBillingAddress?: string | null,
        paymentToken?: string | null,
        npsStatus?: NpsStatus | null,
        paymentStatus?: PaymentStatus | null,
        signStatus?: SignStatus | null,
        signDocumentId?: string | null,
        signerName?: string | null,
        reviewStatus?: ReviewStatus | null,
        authStatus?: AuthStatus | null,
        authTime?: string | null,
        completeBefore?: string | null,
        mustSign?: boolean | null,
        customerCarRegistrationPlate?: string | null,
        enableRegistrationPlate?: boolean | null,
        disablePricesInPrintouts?: boolean | null,
        invoiceIds?: Array< string > | null,
        consolidatedInvoiceIds?: Array< string > | null,
        channel?: ReservationChannel | null,
        originURL?: string | null,
        type?: ReservationType | null,
        status?: string | null,
        pricingCategoryId?: string | null,
        customImportDate?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    pricing?:  {
      __typename: "Pricing",
      id: string,
      name: string,
      group?: string | null,
      organizationId?: string | null,
      orgBusinessId?: string | null,
      orgExternalBusinessId?: string | null,
      externalBusinessCategoryAssociation?: string | null,
      businessCategoryAssociation?: string | null,
      elements:  Array< {
        __typename: "PricingElement",
        duration: string,
        price: number,
      } >,
      useNextDayPrice?: boolean | null,
      useLastDayPrice?: boolean | null,
      tax?: number | null,
      _removed?: string | null,
      deductible?: number | null,
      reducedDeductible?: number | null,
      reducedDeductiblePrice?: number | null,
      createdAt: string,
      updatedAt: string,
    } | null,
  } | null,
};

export type OnCreateCategorySubscriptionVariables = {
};

export type OnCreateCategorySubscription = {
  onCreateCategory?:  {
    __typename: "Category",
    id: string,
    group?: string | null,
    organizationId?: string | null,
    name: string,
    image?: string | null,
    orderNumber?: number | null,
    backgroundColor?: string | null,
    minuteBuffer?: number | null,
    reservationBlockFromNow?: number | null,
    typeId?: string | null,
    _removed?: string | null,
    oldTujausValue?: string | null,
    exampleModelName?: string | null,
    exampleModelImage?: string | null,
    createdAt: string,
    updatedAt: string,
    additionalServices?:  {
      __typename: "ModelAdditionalServicePricingConnection",
      items:  Array< {
        __typename: "AdditionalServicePricing",
        id: string,
        group?: string | null,
        organizationId?: string | null,
        orgBusinessId?: string | null,
        key: string,
        vehicleId?: string | null,
        categoryId?: string | null,
        pricingId: string,
        deductibleValue?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    pricing?:  {
      __typename: "Pricing",
      id: string,
      name: string,
      group?: string | null,
      organizationId?: string | null,
      orgBusinessId?: string | null,
      orgExternalBusinessId?: string | null,
      externalBusinessCategoryAssociation?: string | null,
      businessCategoryAssociation?: string | null,
      elements:  Array< {
        __typename: "PricingElement",
        duration: string,
        price: number,
      } >,
      useNextDayPrice?: boolean | null,
      useLastDayPrice?: boolean | null,
      tax?: number | null,
      _removed?: string | null,
      deductible?: number | null,
      reducedDeductible?: number | null,
      reducedDeductiblePrice?: number | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    externalBusinessPricings?:  {
      __typename: "ModelPricingConnection",
      items:  Array< {
        __typename: "Pricing",
        id: string,
        name: string,
        group?: string | null,
        organizationId?: string | null,
        orgBusinessId?: string | null,
        orgExternalBusinessId?: string | null,
        externalBusinessCategoryAssociation?: string | null,
        businessCategoryAssociation?: string | null,
        useNextDayPrice?: boolean | null,
        useLastDayPrice?: boolean | null,
        tax?: number | null,
        _removed?: string | null,
        deductible?: number | null,
        reducedDeductible?: number | null,
        reducedDeductiblePrice?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type OnUpdateCategorySubscriptionVariables = {
};

export type OnUpdateCategorySubscription = {
  onUpdateCategory?:  {
    __typename: "Category",
    id: string,
    group?: string | null,
    organizationId?: string | null,
    name: string,
    image?: string | null,
    orderNumber?: number | null,
    backgroundColor?: string | null,
    minuteBuffer?: number | null,
    reservationBlockFromNow?: number | null,
    typeId?: string | null,
    _removed?: string | null,
    oldTujausValue?: string | null,
    exampleModelName?: string | null,
    exampleModelImage?: string | null,
    createdAt: string,
    updatedAt: string,
    additionalServices?:  {
      __typename: "ModelAdditionalServicePricingConnection",
      items:  Array< {
        __typename: "AdditionalServicePricing",
        id: string,
        group?: string | null,
        organizationId?: string | null,
        orgBusinessId?: string | null,
        key: string,
        vehicleId?: string | null,
        categoryId?: string | null,
        pricingId: string,
        deductibleValue?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    pricing?:  {
      __typename: "Pricing",
      id: string,
      name: string,
      group?: string | null,
      organizationId?: string | null,
      orgBusinessId?: string | null,
      orgExternalBusinessId?: string | null,
      externalBusinessCategoryAssociation?: string | null,
      businessCategoryAssociation?: string | null,
      elements:  Array< {
        __typename: "PricingElement",
        duration: string,
        price: number,
      } >,
      useNextDayPrice?: boolean | null,
      useLastDayPrice?: boolean | null,
      tax?: number | null,
      _removed?: string | null,
      deductible?: number | null,
      reducedDeductible?: number | null,
      reducedDeductiblePrice?: number | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    externalBusinessPricings?:  {
      __typename: "ModelPricingConnection",
      items:  Array< {
        __typename: "Pricing",
        id: string,
        name: string,
        group?: string | null,
        organizationId?: string | null,
        orgBusinessId?: string | null,
        orgExternalBusinessId?: string | null,
        externalBusinessCategoryAssociation?: string | null,
        businessCategoryAssociation?: string | null,
        useNextDayPrice?: boolean | null,
        useLastDayPrice?: boolean | null,
        tax?: number | null,
        _removed?: string | null,
        deductible?: number | null,
        reducedDeductible?: number | null,
        reducedDeductiblePrice?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type OnDeleteCategorySubscriptionVariables = {
};

export type OnDeleteCategorySubscription = {
  onDeleteCategory?:  {
    __typename: "Category",
    id: string,
    group?: string | null,
    organizationId?: string | null,
    name: string,
    image?: string | null,
    orderNumber?: number | null,
    backgroundColor?: string | null,
    minuteBuffer?: number | null,
    reservationBlockFromNow?: number | null,
    typeId?: string | null,
    _removed?: string | null,
    oldTujausValue?: string | null,
    exampleModelName?: string | null,
    exampleModelImage?: string | null,
    createdAt: string,
    updatedAt: string,
    additionalServices?:  {
      __typename: "ModelAdditionalServicePricingConnection",
      items:  Array< {
        __typename: "AdditionalServicePricing",
        id: string,
        group?: string | null,
        organizationId?: string | null,
        orgBusinessId?: string | null,
        key: string,
        vehicleId?: string | null,
        categoryId?: string | null,
        pricingId: string,
        deductibleValue?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    pricing?:  {
      __typename: "Pricing",
      id: string,
      name: string,
      group?: string | null,
      organizationId?: string | null,
      orgBusinessId?: string | null,
      orgExternalBusinessId?: string | null,
      externalBusinessCategoryAssociation?: string | null,
      businessCategoryAssociation?: string | null,
      elements:  Array< {
        __typename: "PricingElement",
        duration: string,
        price: number,
      } >,
      useNextDayPrice?: boolean | null,
      useLastDayPrice?: boolean | null,
      tax?: number | null,
      _removed?: string | null,
      deductible?: number | null,
      reducedDeductible?: number | null,
      reducedDeductiblePrice?: number | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    externalBusinessPricings?:  {
      __typename: "ModelPricingConnection",
      items:  Array< {
        __typename: "Pricing",
        id: string,
        name: string,
        group?: string | null,
        organizationId?: string | null,
        orgBusinessId?: string | null,
        orgExternalBusinessId?: string | null,
        externalBusinessCategoryAssociation?: string | null,
        businessCategoryAssociation?: string | null,
        useNextDayPrice?: boolean | null,
        useLastDayPrice?: boolean | null,
        tax?: number | null,
        _removed?: string | null,
        deductible?: number | null,
        reducedDeductible?: number | null,
        reducedDeductiblePrice?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type OnCreateAdditionalServicePricingSubscriptionVariables = {
};

export type OnCreateAdditionalServicePricingSubscription = {
  onCreateAdditionalServicePricing?:  {
    __typename: "AdditionalServicePricing",
    id: string,
    group?: string | null,
    organizationId?: string | null,
    orgBusinessId?: string | null,
    key: string,
    vehicleId?: string | null,
    categoryId?: string | null,
    pricingId: string,
    deductibleValue?: number | null,
    createdAt: string,
    updatedAt: string,
    pricing?:  {
      __typename: "Pricing",
      id: string,
      name: string,
      group?: string | null,
      organizationId?: string | null,
      orgBusinessId?: string | null,
      orgExternalBusinessId?: string | null,
      externalBusinessCategoryAssociation?: string | null,
      businessCategoryAssociation?: string | null,
      elements:  Array< {
        __typename: "PricingElement",
        duration: string,
        price: number,
      } >,
      useNextDayPrice?: boolean | null,
      useLastDayPrice?: boolean | null,
      tax?: number | null,
      _removed?: string | null,
      deductible?: number | null,
      reducedDeductible?: number | null,
      reducedDeductiblePrice?: number | null,
      createdAt: string,
      updatedAt: string,
    } | null,
  } | null,
};

export type OnUpdateAdditionalServicePricingSubscriptionVariables = {
};

export type OnUpdateAdditionalServicePricingSubscription = {
  onUpdateAdditionalServicePricing?:  {
    __typename: "AdditionalServicePricing",
    id: string,
    group?: string | null,
    organizationId?: string | null,
    orgBusinessId?: string | null,
    key: string,
    vehicleId?: string | null,
    categoryId?: string | null,
    pricingId: string,
    deductibleValue?: number | null,
    createdAt: string,
    updatedAt: string,
    pricing?:  {
      __typename: "Pricing",
      id: string,
      name: string,
      group?: string | null,
      organizationId?: string | null,
      orgBusinessId?: string | null,
      orgExternalBusinessId?: string | null,
      externalBusinessCategoryAssociation?: string | null,
      businessCategoryAssociation?: string | null,
      elements:  Array< {
        __typename: "PricingElement",
        duration: string,
        price: number,
      } >,
      useNextDayPrice?: boolean | null,
      useLastDayPrice?: boolean | null,
      tax?: number | null,
      _removed?: string | null,
      deductible?: number | null,
      reducedDeductible?: number | null,
      reducedDeductiblePrice?: number | null,
      createdAt: string,
      updatedAt: string,
    } | null,
  } | null,
};

export type OnDeleteAdditionalServicePricingSubscriptionVariables = {
};

export type OnDeleteAdditionalServicePricingSubscription = {
  onDeleteAdditionalServicePricing?:  {
    __typename: "AdditionalServicePricing",
    id: string,
    group?: string | null,
    organizationId?: string | null,
    orgBusinessId?: string | null,
    key: string,
    vehicleId?: string | null,
    categoryId?: string | null,
    pricingId: string,
    deductibleValue?: number | null,
    createdAt: string,
    updatedAt: string,
    pricing?:  {
      __typename: "Pricing",
      id: string,
      name: string,
      group?: string | null,
      organizationId?: string | null,
      orgBusinessId?: string | null,
      orgExternalBusinessId?: string | null,
      externalBusinessCategoryAssociation?: string | null,
      businessCategoryAssociation?: string | null,
      elements:  Array< {
        __typename: "PricingElement",
        duration: string,
        price: number,
      } >,
      useNextDayPrice?: boolean | null,
      useLastDayPrice?: boolean | null,
      tax?: number | null,
      _removed?: string | null,
      deductible?: number | null,
      reducedDeductible?: number | null,
      reducedDeductiblePrice?: number | null,
      createdAt: string,
      updatedAt: string,
    } | null,
  } | null,
};

export type OnCreateUserSubscriptionVariables = {
  id?: string | null,
};

export type OnCreateUserSubscription = {
  onCreateUser?:  {
    __typename: "User",
    id: string,
    organizationId?: string | null,
    orgBusinessId?: string | null,
    orgBusinessIds?: Array< string | null > | null,
    externalBusinessId?: string | null,
    role?: UserRole | null,
    permissions?:  {
      __typename: "Permission",
      userManagement?: boolean | null,
    } | null,
    email?: string | null,
    name?: string | null,
    regionalCompanyName?: string | null,
    createdAt: string,
    updatedAt: string,
    categoriesByOrg?:  {
      __typename: "ModelCategoryConnection",
      items:  Array< {
        __typename: "Category",
        id: string,
        group?: string | null,
        organizationId?: string | null,
        name: string,
        image?: string | null,
        orderNumber?: number | null,
        backgroundColor?: string | null,
        minuteBuffer?: number | null,
        reservationBlockFromNow?: number | null,
        typeId?: string | null,
        _removed?: string | null,
        oldTujausValue?: string | null,
        exampleModelName?: string | null,
        exampleModelImage?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    organization?:  {
      __typename: "Organization",
      id: string,
      name?: string | null,
      billingCustomers?:  Array< {
        __typename: "BillingCustomer",
        name?: string | null,
      } | null > | null,
      features?:  {
        __typename: "OrganizationFeatures",
        enableNPS?: boolean | null,
        enableBusinessWideReservation?: boolean | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      businesses?:  {
        __typename: "ModelBusinessConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    externalBusiness?:  {
      __typename: "ExternalBusiness",
      id: string,
      organizationId?: string | null,
      type?: ExternalBusinessType | null,
      name?: string | null,
      insuranceProducts?:  Array< {
        __typename: "InsuranceProducts",
        name?: string | null,
        coveragePercentage?: number | null,
        compensatableDays?: number | null,
        billingPermitCode?: string | null,
      } | null > | null,
      insuranceRegionalCompanies?:  Array< {
        __typename: "InsuranceRegionalCompany",
        name?: string | null,
        companyName?: string | null,
        businessId?: string | null,
        address?: string | null,
        postalCode?: string | null,
        postalArea?: string | null,
        email?: string | null,
        innovoiceId?: string | null,
      } | null > | null,
      insuranceCompensatableTimes?: Array< number | null > | null,
      services?:  Array< {
        __typename: "CustomService",
        description?: string | null,
        price?: number | null,
        category?: string | null,
        deductibleValue?: number | null,
      } | null > | null,
      useHansel?: boolean | null,
      hanselId?: string | null,
      availableOrgBusinessIds?: Array< string | null > | null,
      availableCompanyIds?: Array< string | null > | null,
      availableVehicleClasses?: Array< string | null > | null,
      eBillingRef?: string | null,
      eBillingOperator?: string | null,
      eBillingAddress?: string | null,
      createdAt: string,
      updatedAt: string,
      companies?:  {
        __typename: "ModelCompanyConnection",
        nextToken?: string | null,
      } | null,
      pricings?:  {
        __typename: "ModelPricingConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    externalBusinessByOrg?:  {
      __typename: "ModelExternalBusinessConnection",
      items:  Array< {
        __typename: "ExternalBusiness",
        id: string,
        organizationId?: string | null,
        type?: ExternalBusinessType | null,
        name?: string | null,
        insuranceCompensatableTimes?: Array< number | null > | null,
        useHansel?: boolean | null,
        hanselId?: string | null,
        availableOrgBusinessIds?: Array< string | null > | null,
        availableCompanyIds?: Array< string | null > | null,
        availableVehicleClasses?: Array< string | null > | null,
        eBillingRef?: string | null,
        eBillingOperator?: string | null,
        eBillingAddress?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    business?:  {
      __typename: "Business",
      id: string,
      organizationId?: string | null,
      telegramChatId?: string | null,
      telegramConnections?:  Array< {
        __typename: "TelegramInformation",
        chatId?: string | null,
        name?: string | null,
      } | null > | null,
      group?: string | null,
      name?: string | null,
      address?: string | null,
      city?: string | null,
      email?: string | null,
      phone?: string | null,
      insuranceCoverageZone?:  {
        __typename: "PostalCodeRange",
        start?: string | null,
        end?: string | null,
      } | null,
      insuranceCoverageZones?:  Array< {
        __typename: "PostalCodeRange",
        start?: string | null,
        end?: string | null,
      } | null > | null,
      importIdentifier?: string | null,
      categoryDynamicPricings?:  Array< {
        __typename: "CategoryDynamicPricing",
        categoryId?: string | null,
        pricingId?: string | null,
        percentage?: number | null,
      } | null > | null,
      startLocations?:  Array< {
        __typename: "PickupLocation",
        id?: string | null,
        name?: string | null,
        locationColor?: string | null,
        companyId?: string | null,
      } | null > | null,
      pickupLocations?:  Array< {
        __typename: "PickupLocation",
        id?: string | null,
        name?: string | null,
        locationColor?: string | null,
        companyId?: string | null,
      } | null > | null,
      createdAt: string,
      updatedAt: string,
      companies?:  {
        __typename: "ModelCompanyConnection",
        nextToken?: string | null,
      } | null,
      categories?:  {
        __typename: "ModelCategoryConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    businessByOrg?:  {
      __typename: "ModelBusinessConnection",
      items:  Array< {
        __typename: "Business",
        id: string,
        organizationId?: string | null,
        telegramChatId?: string | null,
        group?: string | null,
        name?: string | null,
        address?: string | null,
        city?: string | null,
        email?: string | null,
        phone?: string | null,
        importIdentifier?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type OnUpdateUserSubscriptionVariables = {
  id?: string | null,
};

export type OnUpdateUserSubscription = {
  onUpdateUser?:  {
    __typename: "User",
    id: string,
    organizationId?: string | null,
    orgBusinessId?: string | null,
    orgBusinessIds?: Array< string | null > | null,
    externalBusinessId?: string | null,
    role?: UserRole | null,
    permissions?:  {
      __typename: "Permission",
      userManagement?: boolean | null,
    } | null,
    email?: string | null,
    name?: string | null,
    regionalCompanyName?: string | null,
    createdAt: string,
    updatedAt: string,
    categoriesByOrg?:  {
      __typename: "ModelCategoryConnection",
      items:  Array< {
        __typename: "Category",
        id: string,
        group?: string | null,
        organizationId?: string | null,
        name: string,
        image?: string | null,
        orderNumber?: number | null,
        backgroundColor?: string | null,
        minuteBuffer?: number | null,
        reservationBlockFromNow?: number | null,
        typeId?: string | null,
        _removed?: string | null,
        oldTujausValue?: string | null,
        exampleModelName?: string | null,
        exampleModelImage?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    organization?:  {
      __typename: "Organization",
      id: string,
      name?: string | null,
      billingCustomers?:  Array< {
        __typename: "BillingCustomer",
        name?: string | null,
      } | null > | null,
      features?:  {
        __typename: "OrganizationFeatures",
        enableNPS?: boolean | null,
        enableBusinessWideReservation?: boolean | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      businesses?:  {
        __typename: "ModelBusinessConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    externalBusiness?:  {
      __typename: "ExternalBusiness",
      id: string,
      organizationId?: string | null,
      type?: ExternalBusinessType | null,
      name?: string | null,
      insuranceProducts?:  Array< {
        __typename: "InsuranceProducts",
        name?: string | null,
        coveragePercentage?: number | null,
        compensatableDays?: number | null,
        billingPermitCode?: string | null,
      } | null > | null,
      insuranceRegionalCompanies?:  Array< {
        __typename: "InsuranceRegionalCompany",
        name?: string | null,
        companyName?: string | null,
        businessId?: string | null,
        address?: string | null,
        postalCode?: string | null,
        postalArea?: string | null,
        email?: string | null,
        innovoiceId?: string | null,
      } | null > | null,
      insuranceCompensatableTimes?: Array< number | null > | null,
      services?:  Array< {
        __typename: "CustomService",
        description?: string | null,
        price?: number | null,
        category?: string | null,
        deductibleValue?: number | null,
      } | null > | null,
      useHansel?: boolean | null,
      hanselId?: string | null,
      availableOrgBusinessIds?: Array< string | null > | null,
      availableCompanyIds?: Array< string | null > | null,
      availableVehicleClasses?: Array< string | null > | null,
      eBillingRef?: string | null,
      eBillingOperator?: string | null,
      eBillingAddress?: string | null,
      createdAt: string,
      updatedAt: string,
      companies?:  {
        __typename: "ModelCompanyConnection",
        nextToken?: string | null,
      } | null,
      pricings?:  {
        __typename: "ModelPricingConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    externalBusinessByOrg?:  {
      __typename: "ModelExternalBusinessConnection",
      items:  Array< {
        __typename: "ExternalBusiness",
        id: string,
        organizationId?: string | null,
        type?: ExternalBusinessType | null,
        name?: string | null,
        insuranceCompensatableTimes?: Array< number | null > | null,
        useHansel?: boolean | null,
        hanselId?: string | null,
        availableOrgBusinessIds?: Array< string | null > | null,
        availableCompanyIds?: Array< string | null > | null,
        availableVehicleClasses?: Array< string | null > | null,
        eBillingRef?: string | null,
        eBillingOperator?: string | null,
        eBillingAddress?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    business?:  {
      __typename: "Business",
      id: string,
      organizationId?: string | null,
      telegramChatId?: string | null,
      telegramConnections?:  Array< {
        __typename: "TelegramInformation",
        chatId?: string | null,
        name?: string | null,
      } | null > | null,
      group?: string | null,
      name?: string | null,
      address?: string | null,
      city?: string | null,
      email?: string | null,
      phone?: string | null,
      insuranceCoverageZone?:  {
        __typename: "PostalCodeRange",
        start?: string | null,
        end?: string | null,
      } | null,
      insuranceCoverageZones?:  Array< {
        __typename: "PostalCodeRange",
        start?: string | null,
        end?: string | null,
      } | null > | null,
      importIdentifier?: string | null,
      categoryDynamicPricings?:  Array< {
        __typename: "CategoryDynamicPricing",
        categoryId?: string | null,
        pricingId?: string | null,
        percentage?: number | null,
      } | null > | null,
      startLocations?:  Array< {
        __typename: "PickupLocation",
        id?: string | null,
        name?: string | null,
        locationColor?: string | null,
        companyId?: string | null,
      } | null > | null,
      pickupLocations?:  Array< {
        __typename: "PickupLocation",
        id?: string | null,
        name?: string | null,
        locationColor?: string | null,
        companyId?: string | null,
      } | null > | null,
      createdAt: string,
      updatedAt: string,
      companies?:  {
        __typename: "ModelCompanyConnection",
        nextToken?: string | null,
      } | null,
      categories?:  {
        __typename: "ModelCategoryConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    businessByOrg?:  {
      __typename: "ModelBusinessConnection",
      items:  Array< {
        __typename: "Business",
        id: string,
        organizationId?: string | null,
        telegramChatId?: string | null,
        group?: string | null,
        name?: string | null,
        address?: string | null,
        city?: string | null,
        email?: string | null,
        phone?: string | null,
        importIdentifier?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type OnDeleteUserSubscriptionVariables = {
  id?: string | null,
};

export type OnDeleteUserSubscription = {
  onDeleteUser?:  {
    __typename: "User",
    id: string,
    organizationId?: string | null,
    orgBusinessId?: string | null,
    orgBusinessIds?: Array< string | null > | null,
    externalBusinessId?: string | null,
    role?: UserRole | null,
    permissions?:  {
      __typename: "Permission",
      userManagement?: boolean | null,
    } | null,
    email?: string | null,
    name?: string | null,
    regionalCompanyName?: string | null,
    createdAt: string,
    updatedAt: string,
    categoriesByOrg?:  {
      __typename: "ModelCategoryConnection",
      items:  Array< {
        __typename: "Category",
        id: string,
        group?: string | null,
        organizationId?: string | null,
        name: string,
        image?: string | null,
        orderNumber?: number | null,
        backgroundColor?: string | null,
        minuteBuffer?: number | null,
        reservationBlockFromNow?: number | null,
        typeId?: string | null,
        _removed?: string | null,
        oldTujausValue?: string | null,
        exampleModelName?: string | null,
        exampleModelImage?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    organization?:  {
      __typename: "Organization",
      id: string,
      name?: string | null,
      billingCustomers?:  Array< {
        __typename: "BillingCustomer",
        name?: string | null,
      } | null > | null,
      features?:  {
        __typename: "OrganizationFeatures",
        enableNPS?: boolean | null,
        enableBusinessWideReservation?: boolean | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      businesses?:  {
        __typename: "ModelBusinessConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    externalBusiness?:  {
      __typename: "ExternalBusiness",
      id: string,
      organizationId?: string | null,
      type?: ExternalBusinessType | null,
      name?: string | null,
      insuranceProducts?:  Array< {
        __typename: "InsuranceProducts",
        name?: string | null,
        coveragePercentage?: number | null,
        compensatableDays?: number | null,
        billingPermitCode?: string | null,
      } | null > | null,
      insuranceRegionalCompanies?:  Array< {
        __typename: "InsuranceRegionalCompany",
        name?: string | null,
        companyName?: string | null,
        businessId?: string | null,
        address?: string | null,
        postalCode?: string | null,
        postalArea?: string | null,
        email?: string | null,
        innovoiceId?: string | null,
      } | null > | null,
      insuranceCompensatableTimes?: Array< number | null > | null,
      services?:  Array< {
        __typename: "CustomService",
        description?: string | null,
        price?: number | null,
        category?: string | null,
        deductibleValue?: number | null,
      } | null > | null,
      useHansel?: boolean | null,
      hanselId?: string | null,
      availableOrgBusinessIds?: Array< string | null > | null,
      availableCompanyIds?: Array< string | null > | null,
      availableVehicleClasses?: Array< string | null > | null,
      eBillingRef?: string | null,
      eBillingOperator?: string | null,
      eBillingAddress?: string | null,
      createdAt: string,
      updatedAt: string,
      companies?:  {
        __typename: "ModelCompanyConnection",
        nextToken?: string | null,
      } | null,
      pricings?:  {
        __typename: "ModelPricingConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    externalBusinessByOrg?:  {
      __typename: "ModelExternalBusinessConnection",
      items:  Array< {
        __typename: "ExternalBusiness",
        id: string,
        organizationId?: string | null,
        type?: ExternalBusinessType | null,
        name?: string | null,
        insuranceCompensatableTimes?: Array< number | null > | null,
        useHansel?: boolean | null,
        hanselId?: string | null,
        availableOrgBusinessIds?: Array< string | null > | null,
        availableCompanyIds?: Array< string | null > | null,
        availableVehicleClasses?: Array< string | null > | null,
        eBillingRef?: string | null,
        eBillingOperator?: string | null,
        eBillingAddress?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    business?:  {
      __typename: "Business",
      id: string,
      organizationId?: string | null,
      telegramChatId?: string | null,
      telegramConnections?:  Array< {
        __typename: "TelegramInformation",
        chatId?: string | null,
        name?: string | null,
      } | null > | null,
      group?: string | null,
      name?: string | null,
      address?: string | null,
      city?: string | null,
      email?: string | null,
      phone?: string | null,
      insuranceCoverageZone?:  {
        __typename: "PostalCodeRange",
        start?: string | null,
        end?: string | null,
      } | null,
      insuranceCoverageZones?:  Array< {
        __typename: "PostalCodeRange",
        start?: string | null,
        end?: string | null,
      } | null > | null,
      importIdentifier?: string | null,
      categoryDynamicPricings?:  Array< {
        __typename: "CategoryDynamicPricing",
        categoryId?: string | null,
        pricingId?: string | null,
        percentage?: number | null,
      } | null > | null,
      startLocations?:  Array< {
        __typename: "PickupLocation",
        id?: string | null,
        name?: string | null,
        locationColor?: string | null,
        companyId?: string | null,
      } | null > | null,
      pickupLocations?:  Array< {
        __typename: "PickupLocation",
        id?: string | null,
        name?: string | null,
        locationColor?: string | null,
        companyId?: string | null,
      } | null > | null,
      createdAt: string,
      updatedAt: string,
      companies?:  {
        __typename: "ModelCompanyConnection",
        nextToken?: string | null,
      } | null,
      categories?:  {
        __typename: "ModelCategoryConnection",
        nextToken?: string | null,
      } | null,
    } | null,
    businessByOrg?:  {
      __typename: "ModelBusinessConnection",
      items:  Array< {
        __typename: "Business",
        id: string,
        organizationId?: string | null,
        telegramChatId?: string | null,
        group?: string | null,
        name?: string | null,
        address?: string | null,
        city?: string | null,
        email?: string | null,
        phone?: string | null,
        importIdentifier?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type OnCreateOrganizationSubscriptionVariables = {
};

export type OnCreateOrganizationSubscription = {
  onCreateOrganization?:  {
    __typename: "Organization",
    id: string,
    name?: string | null,
    billingCustomers?:  Array< {
      __typename: "BillingCustomer",
      name?: string | null,
    } | null > | null,
    features?:  {
      __typename: "OrganizationFeatures",
      enableNPS?: boolean | null,
      enableBusinessWideReservation?: boolean | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    businesses?:  {
      __typename: "ModelBusinessConnection",
      items:  Array< {
        __typename: "Business",
        id: string,
        organizationId?: string | null,
        telegramChatId?: string | null,
        group?: string | null,
        name?: string | null,
        address?: string | null,
        city?: string | null,
        email?: string | null,
        phone?: string | null,
        importIdentifier?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type OnUpdateOrganizationSubscriptionVariables = {
};

export type OnUpdateOrganizationSubscription = {
  onUpdateOrganization?:  {
    __typename: "Organization",
    id: string,
    name?: string | null,
    billingCustomers?:  Array< {
      __typename: "BillingCustomer",
      name?: string | null,
    } | null > | null,
    features?:  {
      __typename: "OrganizationFeatures",
      enableNPS?: boolean | null,
      enableBusinessWideReservation?: boolean | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    businesses?:  {
      __typename: "ModelBusinessConnection",
      items:  Array< {
        __typename: "Business",
        id: string,
        organizationId?: string | null,
        telegramChatId?: string | null,
        group?: string | null,
        name?: string | null,
        address?: string | null,
        city?: string | null,
        email?: string | null,
        phone?: string | null,
        importIdentifier?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type OnDeleteOrganizationSubscriptionVariables = {
};

export type OnDeleteOrganizationSubscription = {
  onDeleteOrganization?:  {
    __typename: "Organization",
    id: string,
    name?: string | null,
    billingCustomers?:  Array< {
      __typename: "BillingCustomer",
      name?: string | null,
    } | null > | null,
    features?:  {
      __typename: "OrganizationFeatures",
      enableNPS?: boolean | null,
      enableBusinessWideReservation?: boolean | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    businesses?:  {
      __typename: "ModelBusinessConnection",
      items:  Array< {
        __typename: "Business",
        id: string,
        organizationId?: string | null,
        telegramChatId?: string | null,
        group?: string | null,
        name?: string | null,
        address?: string | null,
        city?: string | null,
        email?: string | null,
        phone?: string | null,
        importIdentifier?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type OnCreateExternalBusinessSubscriptionVariables = {
};

export type OnCreateExternalBusinessSubscription = {
  onCreateExternalBusiness?:  {
    __typename: "ExternalBusiness",
    id: string,
    organizationId?: string | null,
    type?: ExternalBusinessType | null,
    name?: string | null,
    insuranceProducts?:  Array< {
      __typename: "InsuranceProducts",
      name?: string | null,
      coveragePercentage?: number | null,
      compensatableDays?: number | null,
      billingPermitCode?: string | null,
    } | null > | null,
    insuranceRegionalCompanies?:  Array< {
      __typename: "InsuranceRegionalCompany",
      name?: string | null,
      companyName?: string | null,
      businessId?: string | null,
      address?: string | null,
      postalCode?: string | null,
      postalArea?: string | null,
      email?: string | null,
      innovoiceId?: string | null,
    } | null > | null,
    insuranceCompensatableTimes?: Array< number | null > | null,
    services?:  Array< {
      __typename: "CustomService",
      description?: string | null,
      price?: number | null,
      category?: string | null,
      deductibleValue?: number | null,
    } | null > | null,
    useHansel?: boolean | null,
    hanselId?: string | null,
    availableOrgBusinessIds?: Array< string | null > | null,
    availableCompanyIds?: Array< string | null > | null,
    availableVehicleClasses?: Array< string | null > | null,
    eBillingRef?: string | null,
    eBillingOperator?: string | null,
    eBillingAddress?: string | null,
    createdAt: string,
    updatedAt: string,
    companies?:  {
      __typename: "ModelCompanyConnection",
      items:  Array< {
        __typename: "Company",
        id: string,
        organizationId?: string | null,
        orgBusinessId?: string | null,
        group?: string | null,
        name: string,
        displayName?: string | null,
        email?: string | null,
        address?: string | null,
        postalCode?: string | null,
        city?: string | null,
        orderNumber?: number | null,
        orderNumberDealer?: number | null,
        phone?: string | null,
        businessId?: string | null,
        termsUrl?: string | null,
        timezone: string,
        signConfirmationEmail?: string | null,
        nameInEmail?: string | null,
        senderNameSMS?: string | null,
        confirmationMsg?: string | null,
        feedbackMsg?: string | null,
        statusLinkMsg?: string | null,
        livionDeviceId?: string | null,
        livionDeviceType?: LivionDevice | null,
        enableReservationDeposit?: boolean | null,
        reservationLinkExpirationHours?: number | null,
        disableOffHourReturn?: boolean | null,
        disableMultiday?: boolean | null,
        disableSameDay?: boolean | null,
        disableStartDaysFromNow?: number | null,
        enableCart?: boolean | null,
        enableMonthlyView?: boolean | null,
        enableRegistrationPlate?: boolean | null,
        enableVehicleStartReturnTimes?: boolean | null,
        enableMustSign?: boolean | null,
        enablePaymentOnlineOnly?: boolean | null,
        enableGTM?: boolean | null,
        enableNetPromoterScore?: boolean | null,
        enableNPS?: boolean | null,
        limitReservationOncePerDay?: boolean | null,
        hidden?: boolean | null,
        locationType?: string | null,
        offHourAvailability?: boolean | null,
        offHourAvailabilityFee?: number | null,
        deliveryFee?: number | null,
        deliveryBufferHours?: number | null,
        notAllowedCategoryIds?: Array< string | null > | null,
        importIdentifier?: string | null,
        contractSettingsJSON?: string | null,
        disableReservationCategoryIds?: Array< string | null > | null,
        locationColor?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    pricings?:  {
      __typename: "ModelPricingConnection",
      items:  Array< {
        __typename: "Pricing",
        id: string,
        name: string,
        group?: string | null,
        organizationId?: string | null,
        orgBusinessId?: string | null,
        orgExternalBusinessId?: string | null,
        externalBusinessCategoryAssociation?: string | null,
        businessCategoryAssociation?: string | null,
        useNextDayPrice?: boolean | null,
        useLastDayPrice?: boolean | null,
        tax?: number | null,
        _removed?: string | null,
        deductible?: number | null,
        reducedDeductible?: number | null,
        reducedDeductiblePrice?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type OnUpdateExternalBusinessSubscriptionVariables = {
};

export type OnUpdateExternalBusinessSubscription = {
  onUpdateExternalBusiness?:  {
    __typename: "ExternalBusiness",
    id: string,
    organizationId?: string | null,
    type?: ExternalBusinessType | null,
    name?: string | null,
    insuranceProducts?:  Array< {
      __typename: "InsuranceProducts",
      name?: string | null,
      coveragePercentage?: number | null,
      compensatableDays?: number | null,
      billingPermitCode?: string | null,
    } | null > | null,
    insuranceRegionalCompanies?:  Array< {
      __typename: "InsuranceRegionalCompany",
      name?: string | null,
      companyName?: string | null,
      businessId?: string | null,
      address?: string | null,
      postalCode?: string | null,
      postalArea?: string | null,
      email?: string | null,
      innovoiceId?: string | null,
    } | null > | null,
    insuranceCompensatableTimes?: Array< number | null > | null,
    services?:  Array< {
      __typename: "CustomService",
      description?: string | null,
      price?: number | null,
      category?: string | null,
      deductibleValue?: number | null,
    } | null > | null,
    useHansel?: boolean | null,
    hanselId?: string | null,
    availableOrgBusinessIds?: Array< string | null > | null,
    availableCompanyIds?: Array< string | null > | null,
    availableVehicleClasses?: Array< string | null > | null,
    eBillingRef?: string | null,
    eBillingOperator?: string | null,
    eBillingAddress?: string | null,
    createdAt: string,
    updatedAt: string,
    companies?:  {
      __typename: "ModelCompanyConnection",
      items:  Array< {
        __typename: "Company",
        id: string,
        organizationId?: string | null,
        orgBusinessId?: string | null,
        group?: string | null,
        name: string,
        displayName?: string | null,
        email?: string | null,
        address?: string | null,
        postalCode?: string | null,
        city?: string | null,
        orderNumber?: number | null,
        orderNumberDealer?: number | null,
        phone?: string | null,
        businessId?: string | null,
        termsUrl?: string | null,
        timezone: string,
        signConfirmationEmail?: string | null,
        nameInEmail?: string | null,
        senderNameSMS?: string | null,
        confirmationMsg?: string | null,
        feedbackMsg?: string | null,
        statusLinkMsg?: string | null,
        livionDeviceId?: string | null,
        livionDeviceType?: LivionDevice | null,
        enableReservationDeposit?: boolean | null,
        reservationLinkExpirationHours?: number | null,
        disableOffHourReturn?: boolean | null,
        disableMultiday?: boolean | null,
        disableSameDay?: boolean | null,
        disableStartDaysFromNow?: number | null,
        enableCart?: boolean | null,
        enableMonthlyView?: boolean | null,
        enableRegistrationPlate?: boolean | null,
        enableVehicleStartReturnTimes?: boolean | null,
        enableMustSign?: boolean | null,
        enablePaymentOnlineOnly?: boolean | null,
        enableGTM?: boolean | null,
        enableNetPromoterScore?: boolean | null,
        enableNPS?: boolean | null,
        limitReservationOncePerDay?: boolean | null,
        hidden?: boolean | null,
        locationType?: string | null,
        offHourAvailability?: boolean | null,
        offHourAvailabilityFee?: number | null,
        deliveryFee?: number | null,
        deliveryBufferHours?: number | null,
        notAllowedCategoryIds?: Array< string | null > | null,
        importIdentifier?: string | null,
        contractSettingsJSON?: string | null,
        disableReservationCategoryIds?: Array< string | null > | null,
        locationColor?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    pricings?:  {
      __typename: "ModelPricingConnection",
      items:  Array< {
        __typename: "Pricing",
        id: string,
        name: string,
        group?: string | null,
        organizationId?: string | null,
        orgBusinessId?: string | null,
        orgExternalBusinessId?: string | null,
        externalBusinessCategoryAssociation?: string | null,
        businessCategoryAssociation?: string | null,
        useNextDayPrice?: boolean | null,
        useLastDayPrice?: boolean | null,
        tax?: number | null,
        _removed?: string | null,
        deductible?: number | null,
        reducedDeductible?: number | null,
        reducedDeductiblePrice?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type OnDeleteExternalBusinessSubscriptionVariables = {
};

export type OnDeleteExternalBusinessSubscription = {
  onDeleteExternalBusiness?:  {
    __typename: "ExternalBusiness",
    id: string,
    organizationId?: string | null,
    type?: ExternalBusinessType | null,
    name?: string | null,
    insuranceProducts?:  Array< {
      __typename: "InsuranceProducts",
      name?: string | null,
      coveragePercentage?: number | null,
      compensatableDays?: number | null,
      billingPermitCode?: string | null,
    } | null > | null,
    insuranceRegionalCompanies?:  Array< {
      __typename: "InsuranceRegionalCompany",
      name?: string | null,
      companyName?: string | null,
      businessId?: string | null,
      address?: string | null,
      postalCode?: string | null,
      postalArea?: string | null,
      email?: string | null,
      innovoiceId?: string | null,
    } | null > | null,
    insuranceCompensatableTimes?: Array< number | null > | null,
    services?:  Array< {
      __typename: "CustomService",
      description?: string | null,
      price?: number | null,
      category?: string | null,
      deductibleValue?: number | null,
    } | null > | null,
    useHansel?: boolean | null,
    hanselId?: string | null,
    availableOrgBusinessIds?: Array< string | null > | null,
    availableCompanyIds?: Array< string | null > | null,
    availableVehicleClasses?: Array< string | null > | null,
    eBillingRef?: string | null,
    eBillingOperator?: string | null,
    eBillingAddress?: string | null,
    createdAt: string,
    updatedAt: string,
    companies?:  {
      __typename: "ModelCompanyConnection",
      items:  Array< {
        __typename: "Company",
        id: string,
        organizationId?: string | null,
        orgBusinessId?: string | null,
        group?: string | null,
        name: string,
        displayName?: string | null,
        email?: string | null,
        address?: string | null,
        postalCode?: string | null,
        city?: string | null,
        orderNumber?: number | null,
        orderNumberDealer?: number | null,
        phone?: string | null,
        businessId?: string | null,
        termsUrl?: string | null,
        timezone: string,
        signConfirmationEmail?: string | null,
        nameInEmail?: string | null,
        senderNameSMS?: string | null,
        confirmationMsg?: string | null,
        feedbackMsg?: string | null,
        statusLinkMsg?: string | null,
        livionDeviceId?: string | null,
        livionDeviceType?: LivionDevice | null,
        enableReservationDeposit?: boolean | null,
        reservationLinkExpirationHours?: number | null,
        disableOffHourReturn?: boolean | null,
        disableMultiday?: boolean | null,
        disableSameDay?: boolean | null,
        disableStartDaysFromNow?: number | null,
        enableCart?: boolean | null,
        enableMonthlyView?: boolean | null,
        enableRegistrationPlate?: boolean | null,
        enableVehicleStartReturnTimes?: boolean | null,
        enableMustSign?: boolean | null,
        enablePaymentOnlineOnly?: boolean | null,
        enableGTM?: boolean | null,
        enableNetPromoterScore?: boolean | null,
        enableNPS?: boolean | null,
        limitReservationOncePerDay?: boolean | null,
        hidden?: boolean | null,
        locationType?: string | null,
        offHourAvailability?: boolean | null,
        offHourAvailabilityFee?: number | null,
        deliveryFee?: number | null,
        deliveryBufferHours?: number | null,
        notAllowedCategoryIds?: Array< string | null > | null,
        importIdentifier?: string | null,
        contractSettingsJSON?: string | null,
        disableReservationCategoryIds?: Array< string | null > | null,
        locationColor?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    pricings?:  {
      __typename: "ModelPricingConnection",
      items:  Array< {
        __typename: "Pricing",
        id: string,
        name: string,
        group?: string | null,
        organizationId?: string | null,
        orgBusinessId?: string | null,
        orgExternalBusinessId?: string | null,
        externalBusinessCategoryAssociation?: string | null,
        businessCategoryAssociation?: string | null,
        useNextDayPrice?: boolean | null,
        useLastDayPrice?: boolean | null,
        tax?: number | null,
        _removed?: string | null,
        deductible?: number | null,
        reducedDeductible?: number | null,
        reducedDeductiblePrice?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type OnCreateBusinessSubscriptionVariables = {
};

export type OnCreateBusinessSubscription = {
  onCreateBusiness?:  {
    __typename: "Business",
    id: string,
    organizationId?: string | null,
    telegramChatId?: string | null,
    telegramConnections?:  Array< {
      __typename: "TelegramInformation",
      chatId?: string | null,
      name?: string | null,
    } | null > | null,
    group?: string | null,
    name?: string | null,
    address?: string | null,
    city?: string | null,
    email?: string | null,
    phone?: string | null,
    insuranceCoverageZone?:  {
      __typename: "PostalCodeRange",
      start?: string | null,
      end?: string | null,
    } | null,
    insuranceCoverageZones?:  Array< {
      __typename: "PostalCodeRange",
      start?: string | null,
      end?: string | null,
    } | null > | null,
    importIdentifier?: string | null,
    categoryDynamicPricings?:  Array< {
      __typename: "CategoryDynamicPricing",
      categoryId?: string | null,
      pricingId?: string | null,
      percentage?: number | null,
    } | null > | null,
    startLocations?:  Array< {
      __typename: "PickupLocation",
      id?: string | null,
      name?: string | null,
      locationColor?: string | null,
      companyId?: string | null,
    } | null > | null,
    pickupLocations?:  Array< {
      __typename: "PickupLocation",
      id?: string | null,
      name?: string | null,
      locationColor?: string | null,
      companyId?: string | null,
    } | null > | null,
    createdAt: string,
    updatedAt: string,
    companies?:  {
      __typename: "ModelCompanyConnection",
      items:  Array< {
        __typename: "Company",
        id: string,
        organizationId?: string | null,
        orgBusinessId?: string | null,
        group?: string | null,
        name: string,
        displayName?: string | null,
        email?: string | null,
        address?: string | null,
        postalCode?: string | null,
        city?: string | null,
        orderNumber?: number | null,
        orderNumberDealer?: number | null,
        phone?: string | null,
        businessId?: string | null,
        termsUrl?: string | null,
        timezone: string,
        signConfirmationEmail?: string | null,
        nameInEmail?: string | null,
        senderNameSMS?: string | null,
        confirmationMsg?: string | null,
        feedbackMsg?: string | null,
        statusLinkMsg?: string | null,
        livionDeviceId?: string | null,
        livionDeviceType?: LivionDevice | null,
        enableReservationDeposit?: boolean | null,
        reservationLinkExpirationHours?: number | null,
        disableOffHourReturn?: boolean | null,
        disableMultiday?: boolean | null,
        disableSameDay?: boolean | null,
        disableStartDaysFromNow?: number | null,
        enableCart?: boolean | null,
        enableMonthlyView?: boolean | null,
        enableRegistrationPlate?: boolean | null,
        enableVehicleStartReturnTimes?: boolean | null,
        enableMustSign?: boolean | null,
        enablePaymentOnlineOnly?: boolean | null,
        enableGTM?: boolean | null,
        enableNetPromoterScore?: boolean | null,
        enableNPS?: boolean | null,
        limitReservationOncePerDay?: boolean | null,
        hidden?: boolean | null,
        locationType?: string | null,
        offHourAvailability?: boolean | null,
        offHourAvailabilityFee?: number | null,
        deliveryFee?: number | null,
        deliveryBufferHours?: number | null,
        notAllowedCategoryIds?: Array< string | null > | null,
        importIdentifier?: string | null,
        contractSettingsJSON?: string | null,
        disableReservationCategoryIds?: Array< string | null > | null,
        locationColor?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    categories?:  {
      __typename: "ModelCategoryConnection",
      items:  Array< {
        __typename: "Category",
        id: string,
        group?: string | null,
        organizationId?: string | null,
        name: string,
        image?: string | null,
        orderNumber?: number | null,
        backgroundColor?: string | null,
        minuteBuffer?: number | null,
        reservationBlockFromNow?: number | null,
        typeId?: string | null,
        _removed?: string | null,
        oldTujausValue?: string | null,
        exampleModelName?: string | null,
        exampleModelImage?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type OnUpdateBusinessSubscriptionVariables = {
};

export type OnUpdateBusinessSubscription = {
  onUpdateBusiness?:  {
    __typename: "Business",
    id: string,
    organizationId?: string | null,
    telegramChatId?: string | null,
    telegramConnections?:  Array< {
      __typename: "TelegramInformation",
      chatId?: string | null,
      name?: string | null,
    } | null > | null,
    group?: string | null,
    name?: string | null,
    address?: string | null,
    city?: string | null,
    email?: string | null,
    phone?: string | null,
    insuranceCoverageZone?:  {
      __typename: "PostalCodeRange",
      start?: string | null,
      end?: string | null,
    } | null,
    insuranceCoverageZones?:  Array< {
      __typename: "PostalCodeRange",
      start?: string | null,
      end?: string | null,
    } | null > | null,
    importIdentifier?: string | null,
    categoryDynamicPricings?:  Array< {
      __typename: "CategoryDynamicPricing",
      categoryId?: string | null,
      pricingId?: string | null,
      percentage?: number | null,
    } | null > | null,
    startLocations?:  Array< {
      __typename: "PickupLocation",
      id?: string | null,
      name?: string | null,
      locationColor?: string | null,
      companyId?: string | null,
    } | null > | null,
    pickupLocations?:  Array< {
      __typename: "PickupLocation",
      id?: string | null,
      name?: string | null,
      locationColor?: string | null,
      companyId?: string | null,
    } | null > | null,
    createdAt: string,
    updatedAt: string,
    companies?:  {
      __typename: "ModelCompanyConnection",
      items:  Array< {
        __typename: "Company",
        id: string,
        organizationId?: string | null,
        orgBusinessId?: string | null,
        group?: string | null,
        name: string,
        displayName?: string | null,
        email?: string | null,
        address?: string | null,
        postalCode?: string | null,
        city?: string | null,
        orderNumber?: number | null,
        orderNumberDealer?: number | null,
        phone?: string | null,
        businessId?: string | null,
        termsUrl?: string | null,
        timezone: string,
        signConfirmationEmail?: string | null,
        nameInEmail?: string | null,
        senderNameSMS?: string | null,
        confirmationMsg?: string | null,
        feedbackMsg?: string | null,
        statusLinkMsg?: string | null,
        livionDeviceId?: string | null,
        livionDeviceType?: LivionDevice | null,
        enableReservationDeposit?: boolean | null,
        reservationLinkExpirationHours?: number | null,
        disableOffHourReturn?: boolean | null,
        disableMultiday?: boolean | null,
        disableSameDay?: boolean | null,
        disableStartDaysFromNow?: number | null,
        enableCart?: boolean | null,
        enableMonthlyView?: boolean | null,
        enableRegistrationPlate?: boolean | null,
        enableVehicleStartReturnTimes?: boolean | null,
        enableMustSign?: boolean | null,
        enablePaymentOnlineOnly?: boolean | null,
        enableGTM?: boolean | null,
        enableNetPromoterScore?: boolean | null,
        enableNPS?: boolean | null,
        limitReservationOncePerDay?: boolean | null,
        hidden?: boolean | null,
        locationType?: string | null,
        offHourAvailability?: boolean | null,
        offHourAvailabilityFee?: number | null,
        deliveryFee?: number | null,
        deliveryBufferHours?: number | null,
        notAllowedCategoryIds?: Array< string | null > | null,
        importIdentifier?: string | null,
        contractSettingsJSON?: string | null,
        disableReservationCategoryIds?: Array< string | null > | null,
        locationColor?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    categories?:  {
      __typename: "ModelCategoryConnection",
      items:  Array< {
        __typename: "Category",
        id: string,
        group?: string | null,
        organizationId?: string | null,
        name: string,
        image?: string | null,
        orderNumber?: number | null,
        backgroundColor?: string | null,
        minuteBuffer?: number | null,
        reservationBlockFromNow?: number | null,
        typeId?: string | null,
        _removed?: string | null,
        oldTujausValue?: string | null,
        exampleModelName?: string | null,
        exampleModelImage?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type OnDeleteBusinessSubscriptionVariables = {
};

export type OnDeleteBusinessSubscription = {
  onDeleteBusiness?:  {
    __typename: "Business",
    id: string,
    organizationId?: string | null,
    telegramChatId?: string | null,
    telegramConnections?:  Array< {
      __typename: "TelegramInformation",
      chatId?: string | null,
      name?: string | null,
    } | null > | null,
    group?: string | null,
    name?: string | null,
    address?: string | null,
    city?: string | null,
    email?: string | null,
    phone?: string | null,
    insuranceCoverageZone?:  {
      __typename: "PostalCodeRange",
      start?: string | null,
      end?: string | null,
    } | null,
    insuranceCoverageZones?:  Array< {
      __typename: "PostalCodeRange",
      start?: string | null,
      end?: string | null,
    } | null > | null,
    importIdentifier?: string | null,
    categoryDynamicPricings?:  Array< {
      __typename: "CategoryDynamicPricing",
      categoryId?: string | null,
      pricingId?: string | null,
      percentage?: number | null,
    } | null > | null,
    startLocations?:  Array< {
      __typename: "PickupLocation",
      id?: string | null,
      name?: string | null,
      locationColor?: string | null,
      companyId?: string | null,
    } | null > | null,
    pickupLocations?:  Array< {
      __typename: "PickupLocation",
      id?: string | null,
      name?: string | null,
      locationColor?: string | null,
      companyId?: string | null,
    } | null > | null,
    createdAt: string,
    updatedAt: string,
    companies?:  {
      __typename: "ModelCompanyConnection",
      items:  Array< {
        __typename: "Company",
        id: string,
        organizationId?: string | null,
        orgBusinessId?: string | null,
        group?: string | null,
        name: string,
        displayName?: string | null,
        email?: string | null,
        address?: string | null,
        postalCode?: string | null,
        city?: string | null,
        orderNumber?: number | null,
        orderNumberDealer?: number | null,
        phone?: string | null,
        businessId?: string | null,
        termsUrl?: string | null,
        timezone: string,
        signConfirmationEmail?: string | null,
        nameInEmail?: string | null,
        senderNameSMS?: string | null,
        confirmationMsg?: string | null,
        feedbackMsg?: string | null,
        statusLinkMsg?: string | null,
        livionDeviceId?: string | null,
        livionDeviceType?: LivionDevice | null,
        enableReservationDeposit?: boolean | null,
        reservationLinkExpirationHours?: number | null,
        disableOffHourReturn?: boolean | null,
        disableMultiday?: boolean | null,
        disableSameDay?: boolean | null,
        disableStartDaysFromNow?: number | null,
        enableCart?: boolean | null,
        enableMonthlyView?: boolean | null,
        enableRegistrationPlate?: boolean | null,
        enableVehicleStartReturnTimes?: boolean | null,
        enableMustSign?: boolean | null,
        enablePaymentOnlineOnly?: boolean | null,
        enableGTM?: boolean | null,
        enableNetPromoterScore?: boolean | null,
        enableNPS?: boolean | null,
        limitReservationOncePerDay?: boolean | null,
        hidden?: boolean | null,
        locationType?: string | null,
        offHourAvailability?: boolean | null,
        offHourAvailabilityFee?: number | null,
        deliveryFee?: number | null,
        deliveryBufferHours?: number | null,
        notAllowedCategoryIds?: Array< string | null > | null,
        importIdentifier?: string | null,
        contractSettingsJSON?: string | null,
        disableReservationCategoryIds?: Array< string | null > | null,
        locationColor?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    categories?:  {
      __typename: "ModelCategoryConnection",
      items:  Array< {
        __typename: "Category",
        id: string,
        group?: string | null,
        organizationId?: string | null,
        name: string,
        image?: string | null,
        orderNumber?: number | null,
        backgroundColor?: string | null,
        minuteBuffer?: number | null,
        reservationBlockFromNow?: number | null,
        typeId?: string | null,
        _removed?: string | null,
        oldTujausValue?: string | null,
        exampleModelName?: string | null,
        exampleModelImage?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type OnCreateArchiveReservationSubscriptionVariables = {
};

export type OnCreateArchiveReservationSubscription = {
  onCreateArchiveReservation?:  {
    __typename: "ArchiveReservation",
    id: string,
    organizationId: string,
    orgBusinessId: string,
    reservationJSON: string,
    archiveMessage?: string | null,
    archivedAt: string,
    archivedByUserId: string,
    startTime?: string | null,
    returnTime?: string | null,
    reservationCreatedAt?: string | null,
    customerBrokerId?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateArchiveReservationSubscriptionVariables = {
};

export type OnUpdateArchiveReservationSubscription = {
  onUpdateArchiveReservation?:  {
    __typename: "ArchiveReservation",
    id: string,
    organizationId: string,
    orgBusinessId: string,
    reservationJSON: string,
    archiveMessage?: string | null,
    archivedAt: string,
    archivedByUserId: string,
    startTime?: string | null,
    returnTime?: string | null,
    reservationCreatedAt?: string | null,
    customerBrokerId?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteArchiveReservationSubscriptionVariables = {
};

export type OnDeleteArchiveReservationSubscription = {
  onDeleteArchiveReservation?:  {
    __typename: "ArchiveReservation",
    id: string,
    organizationId: string,
    orgBusinessId: string,
    reservationJSON: string,
    archiveMessage?: string | null,
    archivedAt: string,
    archivedByUserId: string,
    startTime?: string | null,
    returnTime?: string | null,
    reservationCreatedAt?: string | null,
    customerBrokerId?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateReservationSubscriptionVariables = {
};

export type OnCreateReservationSubscription = {
  onCreateReservation?:  {
    __typename: "Reservation",
    id: string,
    shortId?: string | null,
    language?: Language | null,
    group?: string | null,
    organizationId?: string | null,
    orgBusinessId?: string | null,
    orgExternalBusinessId?: string | null,
    companyId?: string | null,
    returnCompanyId?: string | null,
    startLocation?: string | null,
    returnLocation?: string | null,
    startLocationID?: string | null,
    returnLocationID?: string | null,
    reviewId?: string | null,
    customerBrokerId?: string | null,
    customerCompanyId?: string | null,
    customerPersonalId?: string | null,
    reservationVehicleId?: string | null,
    reservationVehicles?:  Array< {
      __typename: "ReservationVehicle",
      id?: string | null,
      price?: number | null,
      tax?: number | null,
      name?: string | null,
      registrationPlate?: string | null,
      deductible?: number | null,
      includedDistance?: string | null,
      powerType?: string | null,
      mileage?: string | null,
      startMileage?: string | null,
      endMileage?: string | null,
      extraInfo?: string | null,
      damages?: string | null,
      hideExport?: boolean | null,
      createdAt?: string | null,
      addedAt?: string | null,
      categoryName?: string | null,
    } | null > | null,
    wildCardVehicles?:  Array< {
      __typename: "WildCardVehicle",
      name?: string | null,
      price?: number | null,
      tax?: number | null,
      categoryId?: string | null,
      quantity?: number | null,
      deductible?: number | null,
    } | null > | null,
    createdAt: string,
    updatedAt: string,
    startTime?: string | null,
    returnTime?: string | null,
    endTime?: string | null,
    name?: string | null,
    address?: string | null,
    postalCode?: string | null,
    city?: string | null,
    phone?: string | null,
    email?: string | null,
    billingRef?: string | null,
    customerNotes?: string | null,
    ssn?: string | null,
    rentalNotes?: string | null,
    internalNotes?: string | null,
    externalNotes?: string | null,
    outOfCommission?: boolean | null,
    vehiclePrice?: number | null,
    additionalServices?:  Array< {
      __typename: "AdditionalServiceReservation",
      key: string,
      price: number,
      details?: string | null,
      quantity?: number | null,
      type?: ServiceType | null,
      deductibleValue?: number | null,
      groupFields?: Array< string | null > | null,
      tax?: number | null,
      enableNotification?: boolean | null,
      description?: string | null,
      category?: string | null,
    } > | null,
    driverSSN?: string | null,
    differentDriver?: boolean | null,
    driverName?: string | null,
    driverAddress?: string | null,
    driverPostalCode?: string | null,
    driverCity?: string | null,
    driverPhone?: string | null,
    driverEmail?: string | null,
    additionalDriver?: boolean | null,
    additionalDriverName?: string | null,
    additionalDriverPhone?: string | null,
    additionalDriverSSN?: string | null,
    billPayment?: boolean | null,
    isCompany?: boolean | null,
    carStatus?: RentalStatus | null,
    rentalStatus?: RentalStatus | null,
    innovoiceStatus?: ReservationInnovoiceStatus | null,
    companyName?: string | null,
    companyBusinessId?: string | null,
    companyCity?: string | null,
    companyPostalCode?: string | null,
    companyBillingRef?: string | null,
    companyBillingAddress?: string | null,
    companyEBillingOperator?: string | null,
    companyEBillingAddress?: string | null,
    paymentToken?: string | null,
    npsStatus?: NpsStatus | null,
    paymentStatus?: PaymentStatus | null,
    paymentData?:  {
      __typename: "PaymentData",
      paymentTotal?: number | null,
      paymentTodo?: number | null,
      depositPending?: boolean | null,
      deposit?: number | null,
    } | null,
    signStatus?: SignStatus | null,
    signDocumentId?: string | null,
    signerName?: string | null,
    reviewStatus?: ReviewStatus | null,
    authStatus?: AuthStatus | null,
    authTime?: string | null,
    completeBefore?: string | null,
    mustSign?: boolean | null,
    livionData?:  Array< {
      __typename: "LivionData",
      id?: string | null,
      pincode?: string | null,
      keyId?: string | null,
      name?: string | null,
      registrationPlate?: string | null,
    } | null > | null,
    tilakoneData?:  {
      __typename: "TilakoneData",
      access_id?: string | null,
      begin_timestamp?: string | null,
      end_timestamp?: string | null,
      pincode?: string | null,
    } | null,
    voucherData?:  {
      __typename: "VoucherData",
      name: string,
      code: string,
      amount: number,
    } | null,
    customerCarRegistrationPlate?: string | null,
    insuranceData?:  {
      __typename: "InsuranceData",
      customerContact?: boolean | null,
      urgent?: boolean | null,
      customerCarModel?: string | null,
      customerCarMaker?: string | null,
      product?: string | null,
      coveragePercentage?: number | null,
      compensatableDays?: string | null,
      billingPermitCode?: string | null,
      coverageDuration?: number | null,
      providerCompany?: string | null,
      providerBusinessId?: string | null,
      claimAdjusterName?: string | null,
      claimAdjusterEmail?: string | null,
      receipt?: boolean | null,
      replacementVehicleClass?: string | null,
      claimId?: string | null,
      vatDeductible?: boolean | null,
      deliveryCosts?: boolean | null,
      returnCosts?: boolean | null,
    } | null,
    externalData?:  {
      __typename: "ExternalData",
      orderId?: string | null,
      orderReference?: string | null,
      serviceAgreementName?: string | null,
      operatorName?: string | null,
      orderInfo?: string | null,
      billingPayer?: string | null,
      billingDescription?: string | null,
      customerVehicle?:  {
        __typename: "CustomerVehicle",
        brand?: string | null,
        model?: string | null,
        registrationPlate?: string | null,
      } | null,
      incidentLocation?:  {
        __typename: "Location",
        description?: string | null,
        address?: string | null,
        city?: string | null,
        zipCode?: string | null,
      } | null,
      repairShop?:  {
        __typename: "Location",
        description?: string | null,
        address?: string | null,
        city?: string | null,
        zipCode?: string | null,
      } | null,
    } | null,
    enableRegistrationPlate?: boolean | null,
    disablePricesInPrintouts?: boolean | null,
    hanselData?:  {
      __typename: "HanselData",
      contractNro?: string | null,
      customerInvoiceId?: string | null,
      customerInvoiceDate?: string | null,
      hanselAgent?: string | null,
      hanselClass?: string | null,
      customerId?: string | null,
      subContractorNumber?: string | null,
      subContractorName?: string | null,
      suplierProductId?: string | null,
      suplierProductName?: string | null,
      quantity?: string | null,
      price?: string | null,
      totalPrice?: string | null,
      priceNoVAT?: string | null,
      additionalInfo1?: string | null,
      additionalInfo2?: string | null,
      additionalInfo3?: string | null,
      desc?: string | null,
    } | null,
    invoiceIds?: Array< string > | null,
    consolidatedInvoiceIds?: Array< string > | null,
    channel?: ReservationChannel | null,
    originURL?: string | null,
    type?: ReservationType | null,
    status?: string | null,
    reservationStatusInfo?:  {
      __typename: "ReservationStatusInfo",
      deliveryTimestamp?: string | null,
      deliveryAddress?: string | null,
      deliveryZipcode?: string | null,
      deliveryCity?: string | null,
      deliveryCountry?: string | null,
      deliveryDescription?: string | null,
      returnTimestamp?: string | null,
      returnAddress?: string | null,
      returnZipcode?: string | null,
      returnCity?: string | null,
      returnCountry?: string | null,
      returnDescription?: string | null,
      cancelReason?: string | null,
    } | null,
    initialCategory?:  {
      __typename: "CategoryInfo",
      id?: string | null,
      name?: string | null,
      hanselClass?: string | null,
    } | null,
    initialClassInfo?:  {
      __typename: "InitialClassInfo",
      categoryId?: string | null,
      categoryName?: string | null,
      hanselClass?: string | null,
    } | null,
    orgInvoiceCustomer?:  {
      __typename: "OrganizationInvoiceCustomer",
      name?: string | null,
      businessId?: string | null,
    } | null,
    pricingCategoryId?: string | null,
    customImportDate?: string | null,
    vehicle?:  {
      __typename: "Vehicle",
      id: string,
      group?: string | null,
      organizationId?: string | null,
      orgBusinessId?: string | null,
      companyId: string,
      name: string,
      nameT?:  {
        __typename: "Translation",
        fi?: string | null,
        en?: string | null,
        sv?: string | null,
      } | null,
      registrationPlate?: string | null,
      damages?: string | null,
      damageRecords?:  Array< {
        __typename: "DamageRecord",
        normalizedX?: number | null,
        normalizedY?: number | null,
        label?: string | null,
        description?: string | null,
        createdAt?: string | null,
      } > | null,
      additionalFields:  Array< {
        __typename: "AdditionalField",
        key: string,
        value?: string | null,
      } >,
      image?: string | null,
      powerType?: string | null,
      deductible?: number | null,
      includedDistance?: string | null,
      mileage?: string | null,
      orderNumber?: number | null,
      vehicleCategoryId?: string | null,
      useCategoryDefaults?: boolean | null,
      useStartReturnTimes?: boolean | null,
      customStartReturns?:  Array< {
        __typename: "CustomStartReturn",
        priority?: number | null,
      } | null > | null,
      startReturnInterval?:  {
        __typename: "StartReturnInterval",
        start?: string | null,
        end?: string | null,
      } | null,
      startReturnTimes?:  Array< {
        __typename: "StartReturnTimes",
        start?: Array< string | null > | null,
        return?: Array< string | null > | null,
      } | null > | null,
      hideExport?: boolean | null,
      extraInfo?: string | null,
      extraInfoT?: string | null,
      extraToConfirmation?: string | null,
      extraToConfirmationT?: string | null,
      livionKeyId?: string | null,
      phoneNumber?: string | null,
      importData?: string | null,
      disableOnline?: boolean | null,
      activeRange?:  {
        __typename: "StartReturnInterval",
        start?: string | null,
        end?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      category?:  {
        __typename: "Category",
        id: string,
        group?: string | null,
        organizationId?: string | null,
        name: string,
        image?: string | null,
        orderNumber?: number | null,
        backgroundColor?: string | null,
        minuteBuffer?: number | null,
        reservationBlockFromNow?: number | null,
        typeId?: string | null,
        _removed?: string | null,
        oldTujausValue?: string | null,
        exampleModelName?: string | null,
        exampleModelImage?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      additionalServices?:  {
        __typename: "ModelAdditionalServicePricingConnection",
        nextToken?: string | null,
      } | null,
      reservations?:  {
        __typename: "ModelReservationConnection",
        nextToken?: string | null,
      } | null,
      pricing?:  {
        __typename: "Pricing",
        id: string,
        name: string,
        group?: string | null,
        organizationId?: string | null,
        orgBusinessId?: string | null,
        orgExternalBusinessId?: string | null,
        externalBusinessCategoryAssociation?: string | null,
        businessCategoryAssociation?: string | null,
        useNextDayPrice?: boolean | null,
        useLastDayPrice?: boolean | null,
        tax?: number | null,
        _removed?: string | null,
        deductible?: number | null,
        reducedDeductible?: number | null,
        reducedDeductiblePrice?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null,
    } | null,
    invoices?:  {
      __typename: "ModelInvoiceConnection",
      items:  Array< {
        __typename: "Invoice",
        id: string,
        createdAt: string,
        innovoiceId?: string | null,
        reservationId?: string | null,
        reservationIds?: Array< string > | null,
        companyId?: string | null,
        innovoiceCompanyId?: string | null,
        innovoiceuRentCarRentalId?: string | null,
        innovoiceOrderId?: string | null,
        group?: string | null,
        organizationId?: string | null,
        orgBusinessId?: string | null,
        type?: InvoicePaymentType | null,
        creditNoteInvoiceId?: string | null,
        payment_terms?: number | null,
        vat_included?: number | null,
        delivery_by: InvoiceDeliveryBy,
        invoice_type: InvoiceType,
        delivery_date?: string | null,
        seller_reference?: string | null,
        buyer_reference?: string | null,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    paymentEvents?:  {
      __typename: "ModelPaymentEventConnection",
      items:  Array< {
        __typename: "PaymentEvent",
        id: string,
        channel?: string | null,
        paymentMethod?: string | null,
        paymentTotal?: number | null,
        isDeleted?: boolean | null,
        deletedBy?: string | null,
        deletedAt?: string | null,
        createdByUserID?: string | null,
        createdByUserName?: string | null,
        reservationId?: string | null,
        organizationId?: string | null,
        orgBusinessId?: string | null,
        group?: string | null,
        msg?: string | null,
        state?: PaymentEventState | null,
        returnCode?: number | null,
        incidentId?: string | null,
        parameters?: string | null,
        apiPath?: string | null,
        paymentStatus?: string | null,
        orderNumber?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type OnUpdateReservationSubscriptionVariables = {
};

export type OnUpdateReservationSubscription = {
  onUpdateReservation?:  {
    __typename: "Reservation",
    id: string,
    shortId?: string | null,
    language?: Language | null,
    group?: string | null,
    organizationId?: string | null,
    orgBusinessId?: string | null,
    orgExternalBusinessId?: string | null,
    companyId?: string | null,
    returnCompanyId?: string | null,
    startLocation?: string | null,
    returnLocation?: string | null,
    startLocationID?: string | null,
    returnLocationID?: string | null,
    reviewId?: string | null,
    customerBrokerId?: string | null,
    customerCompanyId?: string | null,
    customerPersonalId?: string | null,
    reservationVehicleId?: string | null,
    reservationVehicles?:  Array< {
      __typename: "ReservationVehicle",
      id?: string | null,
      price?: number | null,
      tax?: number | null,
      name?: string | null,
      registrationPlate?: string | null,
      deductible?: number | null,
      includedDistance?: string | null,
      powerType?: string | null,
      mileage?: string | null,
      startMileage?: string | null,
      endMileage?: string | null,
      extraInfo?: string | null,
      damages?: string | null,
      hideExport?: boolean | null,
      createdAt?: string | null,
      addedAt?: string | null,
      categoryName?: string | null,
    } | null > | null,
    wildCardVehicles?:  Array< {
      __typename: "WildCardVehicle",
      name?: string | null,
      price?: number | null,
      tax?: number | null,
      categoryId?: string | null,
      quantity?: number | null,
      deductible?: number | null,
    } | null > | null,
    createdAt: string,
    updatedAt: string,
    startTime?: string | null,
    returnTime?: string | null,
    endTime?: string | null,
    name?: string | null,
    address?: string | null,
    postalCode?: string | null,
    city?: string | null,
    phone?: string | null,
    email?: string | null,
    billingRef?: string | null,
    customerNotes?: string | null,
    ssn?: string | null,
    rentalNotes?: string | null,
    internalNotes?: string | null,
    externalNotes?: string | null,
    outOfCommission?: boolean | null,
    vehiclePrice?: number | null,
    additionalServices?:  Array< {
      __typename: "AdditionalServiceReservation",
      key: string,
      price: number,
      details?: string | null,
      quantity?: number | null,
      type?: ServiceType | null,
      deductibleValue?: number | null,
      groupFields?: Array< string | null > | null,
      tax?: number | null,
      enableNotification?: boolean | null,
      description?: string | null,
      category?: string | null,
    } > | null,
    driverSSN?: string | null,
    differentDriver?: boolean | null,
    driverName?: string | null,
    driverAddress?: string | null,
    driverPostalCode?: string | null,
    driverCity?: string | null,
    driverPhone?: string | null,
    driverEmail?: string | null,
    additionalDriver?: boolean | null,
    additionalDriverName?: string | null,
    additionalDriverPhone?: string | null,
    additionalDriverSSN?: string | null,
    billPayment?: boolean | null,
    isCompany?: boolean | null,
    carStatus?: RentalStatus | null,
    rentalStatus?: RentalStatus | null,
    innovoiceStatus?: ReservationInnovoiceStatus | null,
    companyName?: string | null,
    companyBusinessId?: string | null,
    companyCity?: string | null,
    companyPostalCode?: string | null,
    companyBillingRef?: string | null,
    companyBillingAddress?: string | null,
    companyEBillingOperator?: string | null,
    companyEBillingAddress?: string | null,
    paymentToken?: string | null,
    npsStatus?: NpsStatus | null,
    paymentStatus?: PaymentStatus | null,
    paymentData?:  {
      __typename: "PaymentData",
      paymentTotal?: number | null,
      paymentTodo?: number | null,
      depositPending?: boolean | null,
      deposit?: number | null,
    } | null,
    signStatus?: SignStatus | null,
    signDocumentId?: string | null,
    signerName?: string | null,
    reviewStatus?: ReviewStatus | null,
    authStatus?: AuthStatus | null,
    authTime?: string | null,
    completeBefore?: string | null,
    mustSign?: boolean | null,
    livionData?:  Array< {
      __typename: "LivionData",
      id?: string | null,
      pincode?: string | null,
      keyId?: string | null,
      name?: string | null,
      registrationPlate?: string | null,
    } | null > | null,
    tilakoneData?:  {
      __typename: "TilakoneData",
      access_id?: string | null,
      begin_timestamp?: string | null,
      end_timestamp?: string | null,
      pincode?: string | null,
    } | null,
    voucherData?:  {
      __typename: "VoucherData",
      name: string,
      code: string,
      amount: number,
    } | null,
    customerCarRegistrationPlate?: string | null,
    insuranceData?:  {
      __typename: "InsuranceData",
      customerContact?: boolean | null,
      urgent?: boolean | null,
      customerCarModel?: string | null,
      customerCarMaker?: string | null,
      product?: string | null,
      coveragePercentage?: number | null,
      compensatableDays?: string | null,
      billingPermitCode?: string | null,
      coverageDuration?: number | null,
      providerCompany?: string | null,
      providerBusinessId?: string | null,
      claimAdjusterName?: string | null,
      claimAdjusterEmail?: string | null,
      receipt?: boolean | null,
      replacementVehicleClass?: string | null,
      claimId?: string | null,
      vatDeductible?: boolean | null,
      deliveryCosts?: boolean | null,
      returnCosts?: boolean | null,
    } | null,
    externalData?:  {
      __typename: "ExternalData",
      orderId?: string | null,
      orderReference?: string | null,
      serviceAgreementName?: string | null,
      operatorName?: string | null,
      orderInfo?: string | null,
      billingPayer?: string | null,
      billingDescription?: string | null,
      customerVehicle?:  {
        __typename: "CustomerVehicle",
        brand?: string | null,
        model?: string | null,
        registrationPlate?: string | null,
      } | null,
      incidentLocation?:  {
        __typename: "Location",
        description?: string | null,
        address?: string | null,
        city?: string | null,
        zipCode?: string | null,
      } | null,
      repairShop?:  {
        __typename: "Location",
        description?: string | null,
        address?: string | null,
        city?: string | null,
        zipCode?: string | null,
      } | null,
    } | null,
    enableRegistrationPlate?: boolean | null,
    disablePricesInPrintouts?: boolean | null,
    hanselData?:  {
      __typename: "HanselData",
      contractNro?: string | null,
      customerInvoiceId?: string | null,
      customerInvoiceDate?: string | null,
      hanselAgent?: string | null,
      hanselClass?: string | null,
      customerId?: string | null,
      subContractorNumber?: string | null,
      subContractorName?: string | null,
      suplierProductId?: string | null,
      suplierProductName?: string | null,
      quantity?: string | null,
      price?: string | null,
      totalPrice?: string | null,
      priceNoVAT?: string | null,
      additionalInfo1?: string | null,
      additionalInfo2?: string | null,
      additionalInfo3?: string | null,
      desc?: string | null,
    } | null,
    invoiceIds?: Array< string > | null,
    consolidatedInvoiceIds?: Array< string > | null,
    channel?: ReservationChannel | null,
    originURL?: string | null,
    type?: ReservationType | null,
    status?: string | null,
    reservationStatusInfo?:  {
      __typename: "ReservationStatusInfo",
      deliveryTimestamp?: string | null,
      deliveryAddress?: string | null,
      deliveryZipcode?: string | null,
      deliveryCity?: string | null,
      deliveryCountry?: string | null,
      deliveryDescription?: string | null,
      returnTimestamp?: string | null,
      returnAddress?: string | null,
      returnZipcode?: string | null,
      returnCity?: string | null,
      returnCountry?: string | null,
      returnDescription?: string | null,
      cancelReason?: string | null,
    } | null,
    initialCategory?:  {
      __typename: "CategoryInfo",
      id?: string | null,
      name?: string | null,
      hanselClass?: string | null,
    } | null,
    initialClassInfo?:  {
      __typename: "InitialClassInfo",
      categoryId?: string | null,
      categoryName?: string | null,
      hanselClass?: string | null,
    } | null,
    orgInvoiceCustomer?:  {
      __typename: "OrganizationInvoiceCustomer",
      name?: string | null,
      businessId?: string | null,
    } | null,
    pricingCategoryId?: string | null,
    customImportDate?: string | null,
    vehicle?:  {
      __typename: "Vehicle",
      id: string,
      group?: string | null,
      organizationId?: string | null,
      orgBusinessId?: string | null,
      companyId: string,
      name: string,
      nameT?:  {
        __typename: "Translation",
        fi?: string | null,
        en?: string | null,
        sv?: string | null,
      } | null,
      registrationPlate?: string | null,
      damages?: string | null,
      damageRecords?:  Array< {
        __typename: "DamageRecord",
        normalizedX?: number | null,
        normalizedY?: number | null,
        label?: string | null,
        description?: string | null,
        createdAt?: string | null,
      } > | null,
      additionalFields:  Array< {
        __typename: "AdditionalField",
        key: string,
        value?: string | null,
      } >,
      image?: string | null,
      powerType?: string | null,
      deductible?: number | null,
      includedDistance?: string | null,
      mileage?: string | null,
      orderNumber?: number | null,
      vehicleCategoryId?: string | null,
      useCategoryDefaults?: boolean | null,
      useStartReturnTimes?: boolean | null,
      customStartReturns?:  Array< {
        __typename: "CustomStartReturn",
        priority?: number | null,
      } | null > | null,
      startReturnInterval?:  {
        __typename: "StartReturnInterval",
        start?: string | null,
        end?: string | null,
      } | null,
      startReturnTimes?:  Array< {
        __typename: "StartReturnTimes",
        start?: Array< string | null > | null,
        return?: Array< string | null > | null,
      } | null > | null,
      hideExport?: boolean | null,
      extraInfo?: string | null,
      extraInfoT?: string | null,
      extraToConfirmation?: string | null,
      extraToConfirmationT?: string | null,
      livionKeyId?: string | null,
      phoneNumber?: string | null,
      importData?: string | null,
      disableOnline?: boolean | null,
      activeRange?:  {
        __typename: "StartReturnInterval",
        start?: string | null,
        end?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      category?:  {
        __typename: "Category",
        id: string,
        group?: string | null,
        organizationId?: string | null,
        name: string,
        image?: string | null,
        orderNumber?: number | null,
        backgroundColor?: string | null,
        minuteBuffer?: number | null,
        reservationBlockFromNow?: number | null,
        typeId?: string | null,
        _removed?: string | null,
        oldTujausValue?: string | null,
        exampleModelName?: string | null,
        exampleModelImage?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      additionalServices?:  {
        __typename: "ModelAdditionalServicePricingConnection",
        nextToken?: string | null,
      } | null,
      reservations?:  {
        __typename: "ModelReservationConnection",
        nextToken?: string | null,
      } | null,
      pricing?:  {
        __typename: "Pricing",
        id: string,
        name: string,
        group?: string | null,
        organizationId?: string | null,
        orgBusinessId?: string | null,
        orgExternalBusinessId?: string | null,
        externalBusinessCategoryAssociation?: string | null,
        businessCategoryAssociation?: string | null,
        useNextDayPrice?: boolean | null,
        useLastDayPrice?: boolean | null,
        tax?: number | null,
        _removed?: string | null,
        deductible?: number | null,
        reducedDeductible?: number | null,
        reducedDeductiblePrice?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null,
    } | null,
    invoices?:  {
      __typename: "ModelInvoiceConnection",
      items:  Array< {
        __typename: "Invoice",
        id: string,
        createdAt: string,
        innovoiceId?: string | null,
        reservationId?: string | null,
        reservationIds?: Array< string > | null,
        companyId?: string | null,
        innovoiceCompanyId?: string | null,
        innovoiceuRentCarRentalId?: string | null,
        innovoiceOrderId?: string | null,
        group?: string | null,
        organizationId?: string | null,
        orgBusinessId?: string | null,
        type?: InvoicePaymentType | null,
        creditNoteInvoiceId?: string | null,
        payment_terms?: number | null,
        vat_included?: number | null,
        delivery_by: InvoiceDeliveryBy,
        invoice_type: InvoiceType,
        delivery_date?: string | null,
        seller_reference?: string | null,
        buyer_reference?: string | null,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    paymentEvents?:  {
      __typename: "ModelPaymentEventConnection",
      items:  Array< {
        __typename: "PaymentEvent",
        id: string,
        channel?: string | null,
        paymentMethod?: string | null,
        paymentTotal?: number | null,
        isDeleted?: boolean | null,
        deletedBy?: string | null,
        deletedAt?: string | null,
        createdByUserID?: string | null,
        createdByUserName?: string | null,
        reservationId?: string | null,
        organizationId?: string | null,
        orgBusinessId?: string | null,
        group?: string | null,
        msg?: string | null,
        state?: PaymentEventState | null,
        returnCode?: number | null,
        incidentId?: string | null,
        parameters?: string | null,
        apiPath?: string | null,
        paymentStatus?: string | null,
        orderNumber?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type OnDeleteReservationSubscriptionVariables = {
};

export type OnDeleteReservationSubscription = {
  onDeleteReservation?:  {
    __typename: "Reservation",
    id: string,
    shortId?: string | null,
    language?: Language | null,
    group?: string | null,
    organizationId?: string | null,
    orgBusinessId?: string | null,
    orgExternalBusinessId?: string | null,
    companyId?: string | null,
    returnCompanyId?: string | null,
    startLocation?: string | null,
    returnLocation?: string | null,
    startLocationID?: string | null,
    returnLocationID?: string | null,
    reviewId?: string | null,
    customerBrokerId?: string | null,
    customerCompanyId?: string | null,
    customerPersonalId?: string | null,
    reservationVehicleId?: string | null,
    reservationVehicles?:  Array< {
      __typename: "ReservationVehicle",
      id?: string | null,
      price?: number | null,
      tax?: number | null,
      name?: string | null,
      registrationPlate?: string | null,
      deductible?: number | null,
      includedDistance?: string | null,
      powerType?: string | null,
      mileage?: string | null,
      startMileage?: string | null,
      endMileage?: string | null,
      extraInfo?: string | null,
      damages?: string | null,
      hideExport?: boolean | null,
      createdAt?: string | null,
      addedAt?: string | null,
      categoryName?: string | null,
    } | null > | null,
    wildCardVehicles?:  Array< {
      __typename: "WildCardVehicle",
      name?: string | null,
      price?: number | null,
      tax?: number | null,
      categoryId?: string | null,
      quantity?: number | null,
      deductible?: number | null,
    } | null > | null,
    createdAt: string,
    updatedAt: string,
    startTime?: string | null,
    returnTime?: string | null,
    endTime?: string | null,
    name?: string | null,
    address?: string | null,
    postalCode?: string | null,
    city?: string | null,
    phone?: string | null,
    email?: string | null,
    billingRef?: string | null,
    customerNotes?: string | null,
    ssn?: string | null,
    rentalNotes?: string | null,
    internalNotes?: string | null,
    externalNotes?: string | null,
    outOfCommission?: boolean | null,
    vehiclePrice?: number | null,
    additionalServices?:  Array< {
      __typename: "AdditionalServiceReservation",
      key: string,
      price: number,
      details?: string | null,
      quantity?: number | null,
      type?: ServiceType | null,
      deductibleValue?: number | null,
      groupFields?: Array< string | null > | null,
      tax?: number | null,
      enableNotification?: boolean | null,
      description?: string | null,
      category?: string | null,
    } > | null,
    driverSSN?: string | null,
    differentDriver?: boolean | null,
    driverName?: string | null,
    driverAddress?: string | null,
    driverPostalCode?: string | null,
    driverCity?: string | null,
    driverPhone?: string | null,
    driverEmail?: string | null,
    additionalDriver?: boolean | null,
    additionalDriverName?: string | null,
    additionalDriverPhone?: string | null,
    additionalDriverSSN?: string | null,
    billPayment?: boolean | null,
    isCompany?: boolean | null,
    carStatus?: RentalStatus | null,
    rentalStatus?: RentalStatus | null,
    innovoiceStatus?: ReservationInnovoiceStatus | null,
    companyName?: string | null,
    companyBusinessId?: string | null,
    companyCity?: string | null,
    companyPostalCode?: string | null,
    companyBillingRef?: string | null,
    companyBillingAddress?: string | null,
    companyEBillingOperator?: string | null,
    companyEBillingAddress?: string | null,
    paymentToken?: string | null,
    npsStatus?: NpsStatus | null,
    paymentStatus?: PaymentStatus | null,
    paymentData?:  {
      __typename: "PaymentData",
      paymentTotal?: number | null,
      paymentTodo?: number | null,
      depositPending?: boolean | null,
      deposit?: number | null,
    } | null,
    signStatus?: SignStatus | null,
    signDocumentId?: string | null,
    signerName?: string | null,
    reviewStatus?: ReviewStatus | null,
    authStatus?: AuthStatus | null,
    authTime?: string | null,
    completeBefore?: string | null,
    mustSign?: boolean | null,
    livionData?:  Array< {
      __typename: "LivionData",
      id?: string | null,
      pincode?: string | null,
      keyId?: string | null,
      name?: string | null,
      registrationPlate?: string | null,
    } | null > | null,
    tilakoneData?:  {
      __typename: "TilakoneData",
      access_id?: string | null,
      begin_timestamp?: string | null,
      end_timestamp?: string | null,
      pincode?: string | null,
    } | null,
    voucherData?:  {
      __typename: "VoucherData",
      name: string,
      code: string,
      amount: number,
    } | null,
    customerCarRegistrationPlate?: string | null,
    insuranceData?:  {
      __typename: "InsuranceData",
      customerContact?: boolean | null,
      urgent?: boolean | null,
      customerCarModel?: string | null,
      customerCarMaker?: string | null,
      product?: string | null,
      coveragePercentage?: number | null,
      compensatableDays?: string | null,
      billingPermitCode?: string | null,
      coverageDuration?: number | null,
      providerCompany?: string | null,
      providerBusinessId?: string | null,
      claimAdjusterName?: string | null,
      claimAdjusterEmail?: string | null,
      receipt?: boolean | null,
      replacementVehicleClass?: string | null,
      claimId?: string | null,
      vatDeductible?: boolean | null,
      deliveryCosts?: boolean | null,
      returnCosts?: boolean | null,
    } | null,
    externalData?:  {
      __typename: "ExternalData",
      orderId?: string | null,
      orderReference?: string | null,
      serviceAgreementName?: string | null,
      operatorName?: string | null,
      orderInfo?: string | null,
      billingPayer?: string | null,
      billingDescription?: string | null,
      customerVehicle?:  {
        __typename: "CustomerVehicle",
        brand?: string | null,
        model?: string | null,
        registrationPlate?: string | null,
      } | null,
      incidentLocation?:  {
        __typename: "Location",
        description?: string | null,
        address?: string | null,
        city?: string | null,
        zipCode?: string | null,
      } | null,
      repairShop?:  {
        __typename: "Location",
        description?: string | null,
        address?: string | null,
        city?: string | null,
        zipCode?: string | null,
      } | null,
    } | null,
    enableRegistrationPlate?: boolean | null,
    disablePricesInPrintouts?: boolean | null,
    hanselData?:  {
      __typename: "HanselData",
      contractNro?: string | null,
      customerInvoiceId?: string | null,
      customerInvoiceDate?: string | null,
      hanselAgent?: string | null,
      hanselClass?: string | null,
      customerId?: string | null,
      subContractorNumber?: string | null,
      subContractorName?: string | null,
      suplierProductId?: string | null,
      suplierProductName?: string | null,
      quantity?: string | null,
      price?: string | null,
      totalPrice?: string | null,
      priceNoVAT?: string | null,
      additionalInfo1?: string | null,
      additionalInfo2?: string | null,
      additionalInfo3?: string | null,
      desc?: string | null,
    } | null,
    invoiceIds?: Array< string > | null,
    consolidatedInvoiceIds?: Array< string > | null,
    channel?: ReservationChannel | null,
    originURL?: string | null,
    type?: ReservationType | null,
    status?: string | null,
    reservationStatusInfo?:  {
      __typename: "ReservationStatusInfo",
      deliveryTimestamp?: string | null,
      deliveryAddress?: string | null,
      deliveryZipcode?: string | null,
      deliveryCity?: string | null,
      deliveryCountry?: string | null,
      deliveryDescription?: string | null,
      returnTimestamp?: string | null,
      returnAddress?: string | null,
      returnZipcode?: string | null,
      returnCity?: string | null,
      returnCountry?: string | null,
      returnDescription?: string | null,
      cancelReason?: string | null,
    } | null,
    initialCategory?:  {
      __typename: "CategoryInfo",
      id?: string | null,
      name?: string | null,
      hanselClass?: string | null,
    } | null,
    initialClassInfo?:  {
      __typename: "InitialClassInfo",
      categoryId?: string | null,
      categoryName?: string | null,
      hanselClass?: string | null,
    } | null,
    orgInvoiceCustomer?:  {
      __typename: "OrganizationInvoiceCustomer",
      name?: string | null,
      businessId?: string | null,
    } | null,
    pricingCategoryId?: string | null,
    customImportDate?: string | null,
    vehicle?:  {
      __typename: "Vehicle",
      id: string,
      group?: string | null,
      organizationId?: string | null,
      orgBusinessId?: string | null,
      companyId: string,
      name: string,
      nameT?:  {
        __typename: "Translation",
        fi?: string | null,
        en?: string | null,
        sv?: string | null,
      } | null,
      registrationPlate?: string | null,
      damages?: string | null,
      damageRecords?:  Array< {
        __typename: "DamageRecord",
        normalizedX?: number | null,
        normalizedY?: number | null,
        label?: string | null,
        description?: string | null,
        createdAt?: string | null,
      } > | null,
      additionalFields:  Array< {
        __typename: "AdditionalField",
        key: string,
        value?: string | null,
      } >,
      image?: string | null,
      powerType?: string | null,
      deductible?: number | null,
      includedDistance?: string | null,
      mileage?: string | null,
      orderNumber?: number | null,
      vehicleCategoryId?: string | null,
      useCategoryDefaults?: boolean | null,
      useStartReturnTimes?: boolean | null,
      customStartReturns?:  Array< {
        __typename: "CustomStartReturn",
        priority?: number | null,
      } | null > | null,
      startReturnInterval?:  {
        __typename: "StartReturnInterval",
        start?: string | null,
        end?: string | null,
      } | null,
      startReturnTimes?:  Array< {
        __typename: "StartReturnTimes",
        start?: Array< string | null > | null,
        return?: Array< string | null > | null,
      } | null > | null,
      hideExport?: boolean | null,
      extraInfo?: string | null,
      extraInfoT?: string | null,
      extraToConfirmation?: string | null,
      extraToConfirmationT?: string | null,
      livionKeyId?: string | null,
      phoneNumber?: string | null,
      importData?: string | null,
      disableOnline?: boolean | null,
      activeRange?:  {
        __typename: "StartReturnInterval",
        start?: string | null,
        end?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      category?:  {
        __typename: "Category",
        id: string,
        group?: string | null,
        organizationId?: string | null,
        name: string,
        image?: string | null,
        orderNumber?: number | null,
        backgroundColor?: string | null,
        minuteBuffer?: number | null,
        reservationBlockFromNow?: number | null,
        typeId?: string | null,
        _removed?: string | null,
        oldTujausValue?: string | null,
        exampleModelName?: string | null,
        exampleModelImage?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      additionalServices?:  {
        __typename: "ModelAdditionalServicePricingConnection",
        nextToken?: string | null,
      } | null,
      reservations?:  {
        __typename: "ModelReservationConnection",
        nextToken?: string | null,
      } | null,
      pricing?:  {
        __typename: "Pricing",
        id: string,
        name: string,
        group?: string | null,
        organizationId?: string | null,
        orgBusinessId?: string | null,
        orgExternalBusinessId?: string | null,
        externalBusinessCategoryAssociation?: string | null,
        businessCategoryAssociation?: string | null,
        useNextDayPrice?: boolean | null,
        useLastDayPrice?: boolean | null,
        tax?: number | null,
        _removed?: string | null,
        deductible?: number | null,
        reducedDeductible?: number | null,
        reducedDeductiblePrice?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null,
    } | null,
    invoices?:  {
      __typename: "ModelInvoiceConnection",
      items:  Array< {
        __typename: "Invoice",
        id: string,
        createdAt: string,
        innovoiceId?: string | null,
        reservationId?: string | null,
        reservationIds?: Array< string > | null,
        companyId?: string | null,
        innovoiceCompanyId?: string | null,
        innovoiceuRentCarRentalId?: string | null,
        innovoiceOrderId?: string | null,
        group?: string | null,
        organizationId?: string | null,
        orgBusinessId?: string | null,
        type?: InvoicePaymentType | null,
        creditNoteInvoiceId?: string | null,
        payment_terms?: number | null,
        vat_included?: number | null,
        delivery_by: InvoiceDeliveryBy,
        invoice_type: InvoiceType,
        delivery_date?: string | null,
        seller_reference?: string | null,
        buyer_reference?: string | null,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
    paymentEvents?:  {
      __typename: "ModelPaymentEventConnection",
      items:  Array< {
        __typename: "PaymentEvent",
        id: string,
        channel?: string | null,
        paymentMethod?: string | null,
        paymentTotal?: number | null,
        isDeleted?: boolean | null,
        deletedBy?: string | null,
        deletedAt?: string | null,
        createdByUserID?: string | null,
        createdByUserName?: string | null,
        reservationId?: string | null,
        organizationId?: string | null,
        orgBusinessId?: string | null,
        group?: string | null,
        msg?: string | null,
        state?: PaymentEventState | null,
        returnCode?: number | null,
        incidentId?: string | null,
        parameters?: string | null,
        apiPath?: string | null,
        paymentStatus?: string | null,
        orderNumber?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type OnCreateReservationCountSubscriptionVariables = {
};

export type OnCreateReservationCountSubscription = {
  onCreateReservationCount?:  {
    __typename: "ReservationCount",
    tenant: string,
    date: string,
    count: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateReservationCountSubscriptionVariables = {
};

export type OnUpdateReservationCountSubscription = {
  onUpdateReservationCount?:  {
    __typename: "ReservationCount",
    tenant: string,
    date: string,
    count: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteReservationCountSubscriptionVariables = {
};

export type OnDeleteReservationCountSubscription = {
  onDeleteReservationCount?:  {
    __typename: "ReservationCount",
    tenant: string,
    date: string,
    count: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateInvoiceSubscriptionVariables = {
};

export type OnCreateInvoiceSubscription = {
  onCreateInvoice?:  {
    __typename: "Invoice",
    id: string,
    createdAt: string,
    innovoiceId?: string | null,
    reservationId?: string | null,
    reservationIds?: Array< string > | null,
    companyId?: string | null,
    innovoiceCompanyId?: string | null,
    innovoiceuRentCarRentalId?: string | null,
    innovoiceOrderId?: string | null,
    group?: string | null,
    organizationId?: string | null,
    orgBusinessId?: string | null,
    type?: InvoicePaymentType | null,
    creditNoteInvoiceId?: string | null,
    payment_terms?: number | null,
    vat_included?: number | null,
    delivery_by: InvoiceDeliveryBy,
    invoice_type: InvoiceType,
    delivery_date?: string | null,
    seller_reference?: string | null,
    buyer_reference?: string | null,
    items:  Array< {
      __typename: "InvoiceItem",
      name: string,
      description?: string | null,
      quantity: number,
      unit: string,
      unitprice: number,
      discount?: number | null,
      vat: number,
      type?: string | null,
      insuranceItemType?: InsuranceItemType | null,
      reservationId?: string | null,
    } >,
    customer:  {
      __typename: "InvoiceCustomer",
      customer_type?: InvoiceCustomerType | null,
      firstname?: string | null,
      lastname?: string | null,
      ssn?: string | null,
      address?: string | null,
      zipcode?: string | null,
      city?: string | null,
      email?: string | null,
      phone?: string | null,
      company_name?: string | null,
      contact_person?: string | null,
      e_invoice_operator?: string | null,
      e_invoice_address?: string | null,
    },
    insurance?:  {
      __typename: "InvoiceInsurance",
      insurance_company_id?: number | null,
      vehicle_class?: string | null,
      vehicle_regno?: string | null,
      claim_no?: string | null,
      rental_start_date?: string | null,
      rental_end_date?: string | null,
      rental_days?: number | null,
      amount_cost_per_day?: number | null,
      amount_to_pay_customer?: number | null,
      amount_to_pay_insurance?: number | null,
      coveragePercentage?: number | null,
    } | null,
    updatedAt: string,
  } | null,
};

export type OnUpdateInvoiceSubscriptionVariables = {
};

export type OnUpdateInvoiceSubscription = {
  onUpdateInvoice?:  {
    __typename: "Invoice",
    id: string,
    createdAt: string,
    innovoiceId?: string | null,
    reservationId?: string | null,
    reservationIds?: Array< string > | null,
    companyId?: string | null,
    innovoiceCompanyId?: string | null,
    innovoiceuRentCarRentalId?: string | null,
    innovoiceOrderId?: string | null,
    group?: string | null,
    organizationId?: string | null,
    orgBusinessId?: string | null,
    type?: InvoicePaymentType | null,
    creditNoteInvoiceId?: string | null,
    payment_terms?: number | null,
    vat_included?: number | null,
    delivery_by: InvoiceDeliveryBy,
    invoice_type: InvoiceType,
    delivery_date?: string | null,
    seller_reference?: string | null,
    buyer_reference?: string | null,
    items:  Array< {
      __typename: "InvoiceItem",
      name: string,
      description?: string | null,
      quantity: number,
      unit: string,
      unitprice: number,
      discount?: number | null,
      vat: number,
      type?: string | null,
      insuranceItemType?: InsuranceItemType | null,
      reservationId?: string | null,
    } >,
    customer:  {
      __typename: "InvoiceCustomer",
      customer_type?: InvoiceCustomerType | null,
      firstname?: string | null,
      lastname?: string | null,
      ssn?: string | null,
      address?: string | null,
      zipcode?: string | null,
      city?: string | null,
      email?: string | null,
      phone?: string | null,
      company_name?: string | null,
      contact_person?: string | null,
      e_invoice_operator?: string | null,
      e_invoice_address?: string | null,
    },
    insurance?:  {
      __typename: "InvoiceInsurance",
      insurance_company_id?: number | null,
      vehicle_class?: string | null,
      vehicle_regno?: string | null,
      claim_no?: string | null,
      rental_start_date?: string | null,
      rental_end_date?: string | null,
      rental_days?: number | null,
      amount_cost_per_day?: number | null,
      amount_to_pay_customer?: number | null,
      amount_to_pay_insurance?: number | null,
      coveragePercentage?: number | null,
    } | null,
    updatedAt: string,
  } | null,
};

export type OnDeleteInvoiceSubscriptionVariables = {
};

export type OnDeleteInvoiceSubscription = {
  onDeleteInvoice?:  {
    __typename: "Invoice",
    id: string,
    createdAt: string,
    innovoiceId?: string | null,
    reservationId?: string | null,
    reservationIds?: Array< string > | null,
    companyId?: string | null,
    innovoiceCompanyId?: string | null,
    innovoiceuRentCarRentalId?: string | null,
    innovoiceOrderId?: string | null,
    group?: string | null,
    organizationId?: string | null,
    orgBusinessId?: string | null,
    type?: InvoicePaymentType | null,
    creditNoteInvoiceId?: string | null,
    payment_terms?: number | null,
    vat_included?: number | null,
    delivery_by: InvoiceDeliveryBy,
    invoice_type: InvoiceType,
    delivery_date?: string | null,
    seller_reference?: string | null,
    buyer_reference?: string | null,
    items:  Array< {
      __typename: "InvoiceItem",
      name: string,
      description?: string | null,
      quantity: number,
      unit: string,
      unitprice: number,
      discount?: number | null,
      vat: number,
      type?: string | null,
      insuranceItemType?: InsuranceItemType | null,
      reservationId?: string | null,
    } >,
    customer:  {
      __typename: "InvoiceCustomer",
      customer_type?: InvoiceCustomerType | null,
      firstname?: string | null,
      lastname?: string | null,
      ssn?: string | null,
      address?: string | null,
      zipcode?: string | null,
      city?: string | null,
      email?: string | null,
      phone?: string | null,
      company_name?: string | null,
      contact_person?: string | null,
      e_invoice_operator?: string | null,
      e_invoice_address?: string | null,
    },
    insurance?:  {
      __typename: "InvoiceInsurance",
      insurance_company_id?: number | null,
      vehicle_class?: string | null,
      vehicle_regno?: string | null,
      claim_no?: string | null,
      rental_start_date?: string | null,
      rental_end_date?: string | null,
      rental_days?: number | null,
      amount_cost_per_day?: number | null,
      amount_to_pay_customer?: number | null,
      amount_to_pay_insurance?: number | null,
      coveragePercentage?: number | null,
    } | null,
    updatedAt: string,
  } | null,
};

export type OnCreateSignEventSubscriptionVariables = {
};

export type OnCreateSignEventSubscription = {
  onCreateSignEvent?:  {
    __typename: "SignEvent",
    id: string,
    reservationId: string,
    company: string,
    organizationId?: string | null,
    orgBusinessId?: string | null,
    group?: string | null,
    status: SignStatus,
    documentId?: string | null,
    source?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateSignEventSubscriptionVariables = {
};

export type OnUpdateSignEventSubscription = {
  onUpdateSignEvent?:  {
    __typename: "SignEvent",
    id: string,
    reservationId: string,
    company: string,
    organizationId?: string | null,
    orgBusinessId?: string | null,
    group?: string | null,
    status: SignStatus,
    documentId?: string | null,
    source?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteSignEventSubscriptionVariables = {
};

export type OnDeleteSignEventSubscription = {
  onDeleteSignEvent?:  {
    __typename: "SignEvent",
    id: string,
    reservationId: string,
    company: string,
    organizationId?: string | null,
    orgBusinessId?: string | null,
    group?: string | null,
    status: SignStatus,
    documentId?: string | null,
    source?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreatePaymentEventSubscriptionVariables = {
};

export type OnCreatePaymentEventSubscription = {
  onCreatePaymentEvent?:  {
    __typename: "PaymentEvent",
    id: string,
    channel?: string | null,
    paymentMethod?: string | null,
    paymentTotal?: number | null,
    isDeleted?: boolean | null,
    deletedBy?: string | null,
    deletedAt?: string | null,
    createdByUserID?: string | null,
    createdByUserName?: string | null,
    reservationId?: string | null,
    organizationId?: string | null,
    orgBusinessId?: string | null,
    group?: string | null,
    msg?: string | null,
    state?: PaymentEventState | null,
    returnCode?: number | null,
    incidentId?: string | null,
    parameters?: string | null,
    apiPath?: string | null,
    paymentStatus?: string | null,
    orderNumber?: string | null,
    createdAt: string,
    updatedAt: string,
    reservation?:  {
      __typename: "Reservation",
      id: string,
      shortId?: string | null,
      language?: Language | null,
      group?: string | null,
      organizationId?: string | null,
      orgBusinessId?: string | null,
      orgExternalBusinessId?: string | null,
      companyId?: string | null,
      returnCompanyId?: string | null,
      startLocation?: string | null,
      returnLocation?: string | null,
      startLocationID?: string | null,
      returnLocationID?: string | null,
      reviewId?: string | null,
      customerBrokerId?: string | null,
      customerCompanyId?: string | null,
      customerPersonalId?: string | null,
      reservationVehicleId?: string | null,
      reservationVehicles?:  Array< {
        __typename: "ReservationVehicle",
        id?: string | null,
        price?: number | null,
        tax?: number | null,
        name?: string | null,
        registrationPlate?: string | null,
        deductible?: number | null,
        includedDistance?: string | null,
        powerType?: string | null,
        mileage?: string | null,
        startMileage?: string | null,
        endMileage?: string | null,
        extraInfo?: string | null,
        damages?: string | null,
        hideExport?: boolean | null,
        createdAt?: string | null,
        addedAt?: string | null,
        categoryName?: string | null,
      } | null > | null,
      wildCardVehicles?:  Array< {
        __typename: "WildCardVehicle",
        name?: string | null,
        price?: number | null,
        tax?: number | null,
        categoryId?: string | null,
        quantity?: number | null,
        deductible?: number | null,
      } | null > | null,
      createdAt: string,
      updatedAt: string,
      startTime?: string | null,
      returnTime?: string | null,
      endTime?: string | null,
      name?: string | null,
      address?: string | null,
      postalCode?: string | null,
      city?: string | null,
      phone?: string | null,
      email?: string | null,
      billingRef?: string | null,
      customerNotes?: string | null,
      ssn?: string | null,
      rentalNotes?: string | null,
      internalNotes?: string | null,
      externalNotes?: string | null,
      outOfCommission?: boolean | null,
      vehiclePrice?: number | null,
      additionalServices?:  Array< {
        __typename: "AdditionalServiceReservation",
        key: string,
        price: number,
        details?: string | null,
        quantity?: number | null,
        type?: ServiceType | null,
        deductibleValue?: number | null,
        groupFields?: Array< string | null > | null,
        tax?: number | null,
        enableNotification?: boolean | null,
        description?: string | null,
        category?: string | null,
      } > | null,
      driverSSN?: string | null,
      differentDriver?: boolean | null,
      driverName?: string | null,
      driverAddress?: string | null,
      driverPostalCode?: string | null,
      driverCity?: string | null,
      driverPhone?: string | null,
      driverEmail?: string | null,
      additionalDriver?: boolean | null,
      additionalDriverName?: string | null,
      additionalDriverPhone?: string | null,
      additionalDriverSSN?: string | null,
      billPayment?: boolean | null,
      isCompany?: boolean | null,
      carStatus?: RentalStatus | null,
      rentalStatus?: RentalStatus | null,
      innovoiceStatus?: ReservationInnovoiceStatus | null,
      companyName?: string | null,
      companyBusinessId?: string | null,
      companyCity?: string | null,
      companyPostalCode?: string | null,
      companyBillingRef?: string | null,
      companyBillingAddress?: string | null,
      companyEBillingOperator?: string | null,
      companyEBillingAddress?: string | null,
      paymentToken?: string | null,
      npsStatus?: NpsStatus | null,
      paymentStatus?: PaymentStatus | null,
      paymentData?:  {
        __typename: "PaymentData",
        paymentTotal?: number | null,
        paymentTodo?: number | null,
        depositPending?: boolean | null,
        deposit?: number | null,
      } | null,
      signStatus?: SignStatus | null,
      signDocumentId?: string | null,
      signerName?: string | null,
      reviewStatus?: ReviewStatus | null,
      authStatus?: AuthStatus | null,
      authTime?: string | null,
      completeBefore?: string | null,
      mustSign?: boolean | null,
      livionData?:  Array< {
        __typename: "LivionData",
        id?: string | null,
        pincode?: string | null,
        keyId?: string | null,
        name?: string | null,
        registrationPlate?: string | null,
      } | null > | null,
      tilakoneData?:  {
        __typename: "TilakoneData",
        access_id?: string | null,
        begin_timestamp?: string | null,
        end_timestamp?: string | null,
        pincode?: string | null,
      } | null,
      voucherData?:  {
        __typename: "VoucherData",
        name: string,
        code: string,
        amount: number,
      } | null,
      customerCarRegistrationPlate?: string | null,
      insuranceData?:  {
        __typename: "InsuranceData",
        customerContact?: boolean | null,
        urgent?: boolean | null,
        customerCarModel?: string | null,
        customerCarMaker?: string | null,
        product?: string | null,
        coveragePercentage?: number | null,
        compensatableDays?: string | null,
        billingPermitCode?: string | null,
        coverageDuration?: number | null,
        providerCompany?: string | null,
        providerBusinessId?: string | null,
        claimAdjusterName?: string | null,
        claimAdjusterEmail?: string | null,
        receipt?: boolean | null,
        replacementVehicleClass?: string | null,
        claimId?: string | null,
        vatDeductible?: boolean | null,
        deliveryCosts?: boolean | null,
        returnCosts?: boolean | null,
      } | null,
      externalData?:  {
        __typename: "ExternalData",
        orderId?: string | null,
        orderReference?: string | null,
        serviceAgreementName?: string | null,
        operatorName?: string | null,
        orderInfo?: string | null,
        billingPayer?: string | null,
        billingDescription?: string | null,
      } | null,
      enableRegistrationPlate?: boolean | null,
      disablePricesInPrintouts?: boolean | null,
      hanselData?:  {
        __typename: "HanselData",
        contractNro?: string | null,
        customerInvoiceId?: string | null,
        customerInvoiceDate?: string | null,
        hanselAgent?: string | null,
        hanselClass?: string | null,
        customerId?: string | null,
        subContractorNumber?: string | null,
        subContractorName?: string | null,
        suplierProductId?: string | null,
        suplierProductName?: string | null,
        quantity?: string | null,
        price?: string | null,
        totalPrice?: string | null,
        priceNoVAT?: string | null,
        additionalInfo1?: string | null,
        additionalInfo2?: string | null,
        additionalInfo3?: string | null,
        desc?: string | null,
      } | null,
      invoiceIds?: Array< string > | null,
      consolidatedInvoiceIds?: Array< string > | null,
      channel?: ReservationChannel | null,
      originURL?: string | null,
      type?: ReservationType | null,
      status?: string | null,
      reservationStatusInfo?:  {
        __typename: "ReservationStatusInfo",
        deliveryTimestamp?: string | null,
        deliveryAddress?: string | null,
        deliveryZipcode?: string | null,
        deliveryCity?: string | null,
        deliveryCountry?: string | null,
        deliveryDescription?: string | null,
        returnTimestamp?: string | null,
        returnAddress?: string | null,
        returnZipcode?: string | null,
        returnCity?: string | null,
        returnCountry?: string | null,
        returnDescription?: string | null,
        cancelReason?: string | null,
      } | null,
      initialCategory?:  {
        __typename: "CategoryInfo",
        id?: string | null,
        name?: string | null,
        hanselClass?: string | null,
      } | null,
      initialClassInfo?:  {
        __typename: "InitialClassInfo",
        categoryId?: string | null,
        categoryName?: string | null,
        hanselClass?: string | null,
      } | null,
      orgInvoiceCustomer?:  {
        __typename: "OrganizationInvoiceCustomer",
        name?: string | null,
        businessId?: string | null,
      } | null,
      pricingCategoryId?: string | null,
      customImportDate?: string | null,
      vehicle?:  {
        __typename: "Vehicle",
        id: string,
        group?: string | null,
        organizationId?: string | null,
        orgBusinessId?: string | null,
        companyId: string,
        name: string,
        registrationPlate?: string | null,
        damages?: string | null,
        image?: string | null,
        powerType?: string | null,
        deductible?: number | null,
        includedDistance?: string | null,
        mileage?: string | null,
        orderNumber?: number | null,
        vehicleCategoryId?: string | null,
        useCategoryDefaults?: boolean | null,
        useStartReturnTimes?: boolean | null,
        hideExport?: boolean | null,
        extraInfo?: string | null,
        extraInfoT?: string | null,
        extraToConfirmation?: string | null,
        extraToConfirmationT?: string | null,
        livionKeyId?: string | null,
        phoneNumber?: string | null,
        importData?: string | null,
        disableOnline?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      invoices?:  {
        __typename: "ModelInvoiceConnection",
        nextToken?: string | null,
      } | null,
      paymentEvents?:  {
        __typename: "ModelPaymentEventConnection",
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type OnUpdatePaymentEventSubscriptionVariables = {
};

export type OnUpdatePaymentEventSubscription = {
  onUpdatePaymentEvent?:  {
    __typename: "PaymentEvent",
    id: string,
    channel?: string | null,
    paymentMethod?: string | null,
    paymentTotal?: number | null,
    isDeleted?: boolean | null,
    deletedBy?: string | null,
    deletedAt?: string | null,
    createdByUserID?: string | null,
    createdByUserName?: string | null,
    reservationId?: string | null,
    organizationId?: string | null,
    orgBusinessId?: string | null,
    group?: string | null,
    msg?: string | null,
    state?: PaymentEventState | null,
    returnCode?: number | null,
    incidentId?: string | null,
    parameters?: string | null,
    apiPath?: string | null,
    paymentStatus?: string | null,
    orderNumber?: string | null,
    createdAt: string,
    updatedAt: string,
    reservation?:  {
      __typename: "Reservation",
      id: string,
      shortId?: string | null,
      language?: Language | null,
      group?: string | null,
      organizationId?: string | null,
      orgBusinessId?: string | null,
      orgExternalBusinessId?: string | null,
      companyId?: string | null,
      returnCompanyId?: string | null,
      startLocation?: string | null,
      returnLocation?: string | null,
      startLocationID?: string | null,
      returnLocationID?: string | null,
      reviewId?: string | null,
      customerBrokerId?: string | null,
      customerCompanyId?: string | null,
      customerPersonalId?: string | null,
      reservationVehicleId?: string | null,
      reservationVehicles?:  Array< {
        __typename: "ReservationVehicle",
        id?: string | null,
        price?: number | null,
        tax?: number | null,
        name?: string | null,
        registrationPlate?: string | null,
        deductible?: number | null,
        includedDistance?: string | null,
        powerType?: string | null,
        mileage?: string | null,
        startMileage?: string | null,
        endMileage?: string | null,
        extraInfo?: string | null,
        damages?: string | null,
        hideExport?: boolean | null,
        createdAt?: string | null,
        addedAt?: string | null,
        categoryName?: string | null,
      } | null > | null,
      wildCardVehicles?:  Array< {
        __typename: "WildCardVehicle",
        name?: string | null,
        price?: number | null,
        tax?: number | null,
        categoryId?: string | null,
        quantity?: number | null,
        deductible?: number | null,
      } | null > | null,
      createdAt: string,
      updatedAt: string,
      startTime?: string | null,
      returnTime?: string | null,
      endTime?: string | null,
      name?: string | null,
      address?: string | null,
      postalCode?: string | null,
      city?: string | null,
      phone?: string | null,
      email?: string | null,
      billingRef?: string | null,
      customerNotes?: string | null,
      ssn?: string | null,
      rentalNotes?: string | null,
      internalNotes?: string | null,
      externalNotes?: string | null,
      outOfCommission?: boolean | null,
      vehiclePrice?: number | null,
      additionalServices?:  Array< {
        __typename: "AdditionalServiceReservation",
        key: string,
        price: number,
        details?: string | null,
        quantity?: number | null,
        type?: ServiceType | null,
        deductibleValue?: number | null,
        groupFields?: Array< string | null > | null,
        tax?: number | null,
        enableNotification?: boolean | null,
        description?: string | null,
        category?: string | null,
      } > | null,
      driverSSN?: string | null,
      differentDriver?: boolean | null,
      driverName?: string | null,
      driverAddress?: string | null,
      driverPostalCode?: string | null,
      driverCity?: string | null,
      driverPhone?: string | null,
      driverEmail?: string | null,
      additionalDriver?: boolean | null,
      additionalDriverName?: string | null,
      additionalDriverPhone?: string | null,
      additionalDriverSSN?: string | null,
      billPayment?: boolean | null,
      isCompany?: boolean | null,
      carStatus?: RentalStatus | null,
      rentalStatus?: RentalStatus | null,
      innovoiceStatus?: ReservationInnovoiceStatus | null,
      companyName?: string | null,
      companyBusinessId?: string | null,
      companyCity?: string | null,
      companyPostalCode?: string | null,
      companyBillingRef?: string | null,
      companyBillingAddress?: string | null,
      companyEBillingOperator?: string | null,
      companyEBillingAddress?: string | null,
      paymentToken?: string | null,
      npsStatus?: NpsStatus | null,
      paymentStatus?: PaymentStatus | null,
      paymentData?:  {
        __typename: "PaymentData",
        paymentTotal?: number | null,
        paymentTodo?: number | null,
        depositPending?: boolean | null,
        deposit?: number | null,
      } | null,
      signStatus?: SignStatus | null,
      signDocumentId?: string | null,
      signerName?: string | null,
      reviewStatus?: ReviewStatus | null,
      authStatus?: AuthStatus | null,
      authTime?: string | null,
      completeBefore?: string | null,
      mustSign?: boolean | null,
      livionData?:  Array< {
        __typename: "LivionData",
        id?: string | null,
        pincode?: string | null,
        keyId?: string | null,
        name?: string | null,
        registrationPlate?: string | null,
      } | null > | null,
      tilakoneData?:  {
        __typename: "TilakoneData",
        access_id?: string | null,
        begin_timestamp?: string | null,
        end_timestamp?: string | null,
        pincode?: string | null,
      } | null,
      voucherData?:  {
        __typename: "VoucherData",
        name: string,
        code: string,
        amount: number,
      } | null,
      customerCarRegistrationPlate?: string | null,
      insuranceData?:  {
        __typename: "InsuranceData",
        customerContact?: boolean | null,
        urgent?: boolean | null,
        customerCarModel?: string | null,
        customerCarMaker?: string | null,
        product?: string | null,
        coveragePercentage?: number | null,
        compensatableDays?: string | null,
        billingPermitCode?: string | null,
        coverageDuration?: number | null,
        providerCompany?: string | null,
        providerBusinessId?: string | null,
        claimAdjusterName?: string | null,
        claimAdjusterEmail?: string | null,
        receipt?: boolean | null,
        replacementVehicleClass?: string | null,
        claimId?: string | null,
        vatDeductible?: boolean | null,
        deliveryCosts?: boolean | null,
        returnCosts?: boolean | null,
      } | null,
      externalData?:  {
        __typename: "ExternalData",
        orderId?: string | null,
        orderReference?: string | null,
        serviceAgreementName?: string | null,
        operatorName?: string | null,
        orderInfo?: string | null,
        billingPayer?: string | null,
        billingDescription?: string | null,
      } | null,
      enableRegistrationPlate?: boolean | null,
      disablePricesInPrintouts?: boolean | null,
      hanselData?:  {
        __typename: "HanselData",
        contractNro?: string | null,
        customerInvoiceId?: string | null,
        customerInvoiceDate?: string | null,
        hanselAgent?: string | null,
        hanselClass?: string | null,
        customerId?: string | null,
        subContractorNumber?: string | null,
        subContractorName?: string | null,
        suplierProductId?: string | null,
        suplierProductName?: string | null,
        quantity?: string | null,
        price?: string | null,
        totalPrice?: string | null,
        priceNoVAT?: string | null,
        additionalInfo1?: string | null,
        additionalInfo2?: string | null,
        additionalInfo3?: string | null,
        desc?: string | null,
      } | null,
      invoiceIds?: Array< string > | null,
      consolidatedInvoiceIds?: Array< string > | null,
      channel?: ReservationChannel | null,
      originURL?: string | null,
      type?: ReservationType | null,
      status?: string | null,
      reservationStatusInfo?:  {
        __typename: "ReservationStatusInfo",
        deliveryTimestamp?: string | null,
        deliveryAddress?: string | null,
        deliveryZipcode?: string | null,
        deliveryCity?: string | null,
        deliveryCountry?: string | null,
        deliveryDescription?: string | null,
        returnTimestamp?: string | null,
        returnAddress?: string | null,
        returnZipcode?: string | null,
        returnCity?: string | null,
        returnCountry?: string | null,
        returnDescription?: string | null,
        cancelReason?: string | null,
      } | null,
      initialCategory?:  {
        __typename: "CategoryInfo",
        id?: string | null,
        name?: string | null,
        hanselClass?: string | null,
      } | null,
      initialClassInfo?:  {
        __typename: "InitialClassInfo",
        categoryId?: string | null,
        categoryName?: string | null,
        hanselClass?: string | null,
      } | null,
      orgInvoiceCustomer?:  {
        __typename: "OrganizationInvoiceCustomer",
        name?: string | null,
        businessId?: string | null,
      } | null,
      pricingCategoryId?: string | null,
      customImportDate?: string | null,
      vehicle?:  {
        __typename: "Vehicle",
        id: string,
        group?: string | null,
        organizationId?: string | null,
        orgBusinessId?: string | null,
        companyId: string,
        name: string,
        registrationPlate?: string | null,
        damages?: string | null,
        image?: string | null,
        powerType?: string | null,
        deductible?: number | null,
        includedDistance?: string | null,
        mileage?: string | null,
        orderNumber?: number | null,
        vehicleCategoryId?: string | null,
        useCategoryDefaults?: boolean | null,
        useStartReturnTimes?: boolean | null,
        hideExport?: boolean | null,
        extraInfo?: string | null,
        extraInfoT?: string | null,
        extraToConfirmation?: string | null,
        extraToConfirmationT?: string | null,
        livionKeyId?: string | null,
        phoneNumber?: string | null,
        importData?: string | null,
        disableOnline?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      invoices?:  {
        __typename: "ModelInvoiceConnection",
        nextToken?: string | null,
      } | null,
      paymentEvents?:  {
        __typename: "ModelPaymentEventConnection",
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type OnDeletePaymentEventSubscriptionVariables = {
};

export type OnDeletePaymentEventSubscription = {
  onDeletePaymentEvent?:  {
    __typename: "PaymentEvent",
    id: string,
    channel?: string | null,
    paymentMethod?: string | null,
    paymentTotal?: number | null,
    isDeleted?: boolean | null,
    deletedBy?: string | null,
    deletedAt?: string | null,
    createdByUserID?: string | null,
    createdByUserName?: string | null,
    reservationId?: string | null,
    organizationId?: string | null,
    orgBusinessId?: string | null,
    group?: string | null,
    msg?: string | null,
    state?: PaymentEventState | null,
    returnCode?: number | null,
    incidentId?: string | null,
    parameters?: string | null,
    apiPath?: string | null,
    paymentStatus?: string | null,
    orderNumber?: string | null,
    createdAt: string,
    updatedAt: string,
    reservation?:  {
      __typename: "Reservation",
      id: string,
      shortId?: string | null,
      language?: Language | null,
      group?: string | null,
      organizationId?: string | null,
      orgBusinessId?: string | null,
      orgExternalBusinessId?: string | null,
      companyId?: string | null,
      returnCompanyId?: string | null,
      startLocation?: string | null,
      returnLocation?: string | null,
      startLocationID?: string | null,
      returnLocationID?: string | null,
      reviewId?: string | null,
      customerBrokerId?: string | null,
      customerCompanyId?: string | null,
      customerPersonalId?: string | null,
      reservationVehicleId?: string | null,
      reservationVehicles?:  Array< {
        __typename: "ReservationVehicle",
        id?: string | null,
        price?: number | null,
        tax?: number | null,
        name?: string | null,
        registrationPlate?: string | null,
        deductible?: number | null,
        includedDistance?: string | null,
        powerType?: string | null,
        mileage?: string | null,
        startMileage?: string | null,
        endMileage?: string | null,
        extraInfo?: string | null,
        damages?: string | null,
        hideExport?: boolean | null,
        createdAt?: string | null,
        addedAt?: string | null,
        categoryName?: string | null,
      } | null > | null,
      wildCardVehicles?:  Array< {
        __typename: "WildCardVehicle",
        name?: string | null,
        price?: number | null,
        tax?: number | null,
        categoryId?: string | null,
        quantity?: number | null,
        deductible?: number | null,
      } | null > | null,
      createdAt: string,
      updatedAt: string,
      startTime?: string | null,
      returnTime?: string | null,
      endTime?: string | null,
      name?: string | null,
      address?: string | null,
      postalCode?: string | null,
      city?: string | null,
      phone?: string | null,
      email?: string | null,
      billingRef?: string | null,
      customerNotes?: string | null,
      ssn?: string | null,
      rentalNotes?: string | null,
      internalNotes?: string | null,
      externalNotes?: string | null,
      outOfCommission?: boolean | null,
      vehiclePrice?: number | null,
      additionalServices?:  Array< {
        __typename: "AdditionalServiceReservation",
        key: string,
        price: number,
        details?: string | null,
        quantity?: number | null,
        type?: ServiceType | null,
        deductibleValue?: number | null,
        groupFields?: Array< string | null > | null,
        tax?: number | null,
        enableNotification?: boolean | null,
        description?: string | null,
        category?: string | null,
      } > | null,
      driverSSN?: string | null,
      differentDriver?: boolean | null,
      driverName?: string | null,
      driverAddress?: string | null,
      driverPostalCode?: string | null,
      driverCity?: string | null,
      driverPhone?: string | null,
      driverEmail?: string | null,
      additionalDriver?: boolean | null,
      additionalDriverName?: string | null,
      additionalDriverPhone?: string | null,
      additionalDriverSSN?: string | null,
      billPayment?: boolean | null,
      isCompany?: boolean | null,
      carStatus?: RentalStatus | null,
      rentalStatus?: RentalStatus | null,
      innovoiceStatus?: ReservationInnovoiceStatus | null,
      companyName?: string | null,
      companyBusinessId?: string | null,
      companyCity?: string | null,
      companyPostalCode?: string | null,
      companyBillingRef?: string | null,
      companyBillingAddress?: string | null,
      companyEBillingOperator?: string | null,
      companyEBillingAddress?: string | null,
      paymentToken?: string | null,
      npsStatus?: NpsStatus | null,
      paymentStatus?: PaymentStatus | null,
      paymentData?:  {
        __typename: "PaymentData",
        paymentTotal?: number | null,
        paymentTodo?: number | null,
        depositPending?: boolean | null,
        deposit?: number | null,
      } | null,
      signStatus?: SignStatus | null,
      signDocumentId?: string | null,
      signerName?: string | null,
      reviewStatus?: ReviewStatus | null,
      authStatus?: AuthStatus | null,
      authTime?: string | null,
      completeBefore?: string | null,
      mustSign?: boolean | null,
      livionData?:  Array< {
        __typename: "LivionData",
        id?: string | null,
        pincode?: string | null,
        keyId?: string | null,
        name?: string | null,
        registrationPlate?: string | null,
      } | null > | null,
      tilakoneData?:  {
        __typename: "TilakoneData",
        access_id?: string | null,
        begin_timestamp?: string | null,
        end_timestamp?: string | null,
        pincode?: string | null,
      } | null,
      voucherData?:  {
        __typename: "VoucherData",
        name: string,
        code: string,
        amount: number,
      } | null,
      customerCarRegistrationPlate?: string | null,
      insuranceData?:  {
        __typename: "InsuranceData",
        customerContact?: boolean | null,
        urgent?: boolean | null,
        customerCarModel?: string | null,
        customerCarMaker?: string | null,
        product?: string | null,
        coveragePercentage?: number | null,
        compensatableDays?: string | null,
        billingPermitCode?: string | null,
        coverageDuration?: number | null,
        providerCompany?: string | null,
        providerBusinessId?: string | null,
        claimAdjusterName?: string | null,
        claimAdjusterEmail?: string | null,
        receipt?: boolean | null,
        replacementVehicleClass?: string | null,
        claimId?: string | null,
        vatDeductible?: boolean | null,
        deliveryCosts?: boolean | null,
        returnCosts?: boolean | null,
      } | null,
      externalData?:  {
        __typename: "ExternalData",
        orderId?: string | null,
        orderReference?: string | null,
        serviceAgreementName?: string | null,
        operatorName?: string | null,
        orderInfo?: string | null,
        billingPayer?: string | null,
        billingDescription?: string | null,
      } | null,
      enableRegistrationPlate?: boolean | null,
      disablePricesInPrintouts?: boolean | null,
      hanselData?:  {
        __typename: "HanselData",
        contractNro?: string | null,
        customerInvoiceId?: string | null,
        customerInvoiceDate?: string | null,
        hanselAgent?: string | null,
        hanselClass?: string | null,
        customerId?: string | null,
        subContractorNumber?: string | null,
        subContractorName?: string | null,
        suplierProductId?: string | null,
        suplierProductName?: string | null,
        quantity?: string | null,
        price?: string | null,
        totalPrice?: string | null,
        priceNoVAT?: string | null,
        additionalInfo1?: string | null,
        additionalInfo2?: string | null,
        additionalInfo3?: string | null,
        desc?: string | null,
      } | null,
      invoiceIds?: Array< string > | null,
      consolidatedInvoiceIds?: Array< string > | null,
      channel?: ReservationChannel | null,
      originURL?: string | null,
      type?: ReservationType | null,
      status?: string | null,
      reservationStatusInfo?:  {
        __typename: "ReservationStatusInfo",
        deliveryTimestamp?: string | null,
        deliveryAddress?: string | null,
        deliveryZipcode?: string | null,
        deliveryCity?: string | null,
        deliveryCountry?: string | null,
        deliveryDescription?: string | null,
        returnTimestamp?: string | null,
        returnAddress?: string | null,
        returnZipcode?: string | null,
        returnCity?: string | null,
        returnCountry?: string | null,
        returnDescription?: string | null,
        cancelReason?: string | null,
      } | null,
      initialCategory?:  {
        __typename: "CategoryInfo",
        id?: string | null,
        name?: string | null,
        hanselClass?: string | null,
      } | null,
      initialClassInfo?:  {
        __typename: "InitialClassInfo",
        categoryId?: string | null,
        categoryName?: string | null,
        hanselClass?: string | null,
      } | null,
      orgInvoiceCustomer?:  {
        __typename: "OrganizationInvoiceCustomer",
        name?: string | null,
        businessId?: string | null,
      } | null,
      pricingCategoryId?: string | null,
      customImportDate?: string | null,
      vehicle?:  {
        __typename: "Vehicle",
        id: string,
        group?: string | null,
        organizationId?: string | null,
        orgBusinessId?: string | null,
        companyId: string,
        name: string,
        registrationPlate?: string | null,
        damages?: string | null,
        image?: string | null,
        powerType?: string | null,
        deductible?: number | null,
        includedDistance?: string | null,
        mileage?: string | null,
        orderNumber?: number | null,
        vehicleCategoryId?: string | null,
        useCategoryDefaults?: boolean | null,
        useStartReturnTimes?: boolean | null,
        hideExport?: boolean | null,
        extraInfo?: string | null,
        extraInfoT?: string | null,
        extraToConfirmation?: string | null,
        extraToConfirmationT?: string | null,
        livionKeyId?: string | null,
        phoneNumber?: string | null,
        importData?: string | null,
        disableOnline?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      invoices?:  {
        __typename: "ModelInvoiceConnection",
        nextToken?: string | null,
      } | null,
      paymentEvents?:  {
        __typename: "ModelPaymentEventConnection",
        nextToken?: string | null,
      } | null,
    } | null,
  } | null,
};

export type OnCreateAdditionalServiceSubscriptionVariables = {
};

export type OnCreateAdditionalServiceSubscription = {
  onCreateAdditionalService?:  {
    __typename: "AdditionalService",
    id: string,
    organizationId?: string | null,
    orgBusinessId?: string | null,
    group?: string | null,
    description?: string | null,
    descriptionT?:  {
      __typename: "Translation",
      fi?: string | null,
      en?: string | null,
      sv?: string | null,
    } | null,
    description2?: string | null,
    category: string,
    type?: ServiceType | null,
    enableTextInput?: boolean | null,
    enableQuantity?: boolean | null,
    enableNotification?: boolean | null,
    maxQuantity?: number | null,
    inputLabel?: string | null,
    _removed?: string | null,
    isGroupParent?: boolean | null,
    groupFields?: Array< string | null > | null,
    groupParentId?: string | null,
    groupOrderNumber?: number | null,
    image?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateAdditionalServiceSubscriptionVariables = {
};

export type OnUpdateAdditionalServiceSubscription = {
  onUpdateAdditionalService?:  {
    __typename: "AdditionalService",
    id: string,
    organizationId?: string | null,
    orgBusinessId?: string | null,
    group?: string | null,
    description?: string | null,
    descriptionT?:  {
      __typename: "Translation",
      fi?: string | null,
      en?: string | null,
      sv?: string | null,
    } | null,
    description2?: string | null,
    category: string,
    type?: ServiceType | null,
    enableTextInput?: boolean | null,
    enableQuantity?: boolean | null,
    enableNotification?: boolean | null,
    maxQuantity?: number | null,
    inputLabel?: string | null,
    _removed?: string | null,
    isGroupParent?: boolean | null,
    groupFields?: Array< string | null > | null,
    groupParentId?: string | null,
    groupOrderNumber?: number | null,
    image?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteAdditionalServiceSubscriptionVariables = {
};

export type OnDeleteAdditionalServiceSubscription = {
  onDeleteAdditionalService?:  {
    __typename: "AdditionalService",
    id: string,
    organizationId?: string | null,
    orgBusinessId?: string | null,
    group?: string | null,
    description?: string | null,
    descriptionT?:  {
      __typename: "Translation",
      fi?: string | null,
      en?: string | null,
      sv?: string | null,
    } | null,
    description2?: string | null,
    category: string,
    type?: ServiceType | null,
    enableTextInput?: boolean | null,
    enableQuantity?: boolean | null,
    enableNotification?: boolean | null,
    maxQuantity?: number | null,
    inputLabel?: string | null,
    _removed?: string | null,
    isGroupParent?: boolean | null,
    groupFields?: Array< string | null > | null,
    groupParentId?: string | null,
    groupOrderNumber?: number | null,
    image?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreatePricingSubscriptionVariables = {
};

export type OnCreatePricingSubscription = {
  onCreatePricing?:  {
    __typename: "Pricing",
    id: string,
    name: string,
    group?: string | null,
    organizationId?: string | null,
    orgBusinessId?: string | null,
    orgExternalBusinessId?: string | null,
    externalBusinessCategoryAssociation?: string | null,
    businessCategoryAssociation?: string | null,
    elements:  Array< {
      __typename: "PricingElement",
      duration: string,
      price: number,
    } >,
    useNextDayPrice?: boolean | null,
    useLastDayPrice?: boolean | null,
    tax?: number | null,
    _removed?: string | null,
    deductible?: number | null,
    reducedDeductible?: number | null,
    reducedDeductiblePrice?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdatePricingSubscriptionVariables = {
};

export type OnUpdatePricingSubscription = {
  onUpdatePricing?:  {
    __typename: "Pricing",
    id: string,
    name: string,
    group?: string | null,
    organizationId?: string | null,
    orgBusinessId?: string | null,
    orgExternalBusinessId?: string | null,
    externalBusinessCategoryAssociation?: string | null,
    businessCategoryAssociation?: string | null,
    elements:  Array< {
      __typename: "PricingElement",
      duration: string,
      price: number,
    } >,
    useNextDayPrice?: boolean | null,
    useLastDayPrice?: boolean | null,
    tax?: number | null,
    _removed?: string | null,
    deductible?: number | null,
    reducedDeductible?: number | null,
    reducedDeductiblePrice?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeletePricingSubscriptionVariables = {
};

export type OnDeletePricingSubscription = {
  onDeletePricing?:  {
    __typename: "Pricing",
    id: string,
    name: string,
    group?: string | null,
    organizationId?: string | null,
    orgBusinessId?: string | null,
    orgExternalBusinessId?: string | null,
    externalBusinessCategoryAssociation?: string | null,
    businessCategoryAssociation?: string | null,
    elements:  Array< {
      __typename: "PricingElement",
      duration: string,
      price: number,
    } >,
    useNextDayPrice?: boolean | null,
    useLastDayPrice?: boolean | null,
    tax?: number | null,
    _removed?: string | null,
    deductible?: number | null,
    reducedDeductible?: number | null,
    reducedDeductiblePrice?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreatePricingExceptionSubscriptionVariables = {
};

export type OnCreatePricingExceptionSubscription = {
  onCreatePricingException?:  {
    __typename: "PricingException",
    id: string,
    name: string,
    group?: string | null,
    organizationId?: string | null,
    orgBusinessId?: string | null,
    type: PricingExceptionType,
    amountType: PricingExceptionAmountType,
    weeklyAmounts?: Array< number > | null,
    monthlyAmountPerDay?: number | null,
    daysBeforeMonthChange?: number | null,
    daysAfterMonthChange?: number | null,
    startDate?: string | null,
    endDate?: string | null,
    pricingIds?: Array< string > | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdatePricingExceptionSubscriptionVariables = {
};

export type OnUpdatePricingExceptionSubscription = {
  onUpdatePricingException?:  {
    __typename: "PricingException",
    id: string,
    name: string,
    group?: string | null,
    organizationId?: string | null,
    orgBusinessId?: string | null,
    type: PricingExceptionType,
    amountType: PricingExceptionAmountType,
    weeklyAmounts?: Array< number > | null,
    monthlyAmountPerDay?: number | null,
    daysBeforeMonthChange?: number | null,
    daysAfterMonthChange?: number | null,
    startDate?: string | null,
    endDate?: string | null,
    pricingIds?: Array< string > | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeletePricingExceptionSubscriptionVariables = {
};

export type OnDeletePricingExceptionSubscription = {
  onDeletePricingException?:  {
    __typename: "PricingException",
    id: string,
    name: string,
    group?: string | null,
    organizationId?: string | null,
    orgBusinessId?: string | null,
    type: PricingExceptionType,
    amountType: PricingExceptionAmountType,
    weeklyAmounts?: Array< number > | null,
    monthlyAmountPerDay?: number | null,
    daysBeforeMonthChange?: number | null,
    daysAfterMonthChange?: number | null,
    startDate?: string | null,
    endDate?: string | null,
    pricingIds?: Array< string > | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateVoucherSubscriptionVariables = {
};

export type OnCreateVoucherSubscription = {
  onCreateVoucher?:  {
    __typename: "Voucher",
    id: string,
    name: string,
    group?: string | null,
    organizationId?: string | null,
    code: string,
    amount: number,
    startDate?: string | null,
    endDate?: string | null,
    _removed?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateVoucherSubscriptionVariables = {
};

export type OnUpdateVoucherSubscription = {
  onUpdateVoucher?:  {
    __typename: "Voucher",
    id: string,
    name: string,
    group?: string | null,
    organizationId?: string | null,
    code: string,
    amount: number,
    startDate?: string | null,
    endDate?: string | null,
    _removed?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteVoucherSubscriptionVariables = {
};

export type OnDeleteVoucherSubscription = {
  onDeleteVoucher?:  {
    __typename: "Voucher",
    id: string,
    name: string,
    group?: string | null,
    organizationId?: string | null,
    code: string,
    amount: number,
    startDate?: string | null,
    endDate?: string | null,
    _removed?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateReviewSubscriptionVariables = {
};

export type OnCreateReviewSubscription = {
  onCreateReview?:  {
    __typename: "Review",
    id: string,
    group?: string | null,
    organizationId?: string | null,
    orgBusinessId?: string | null,
    orgExternalBusinessId?: string | null,
    rating: number,
    comment?: string | null,
    explanation?: string | null,
    reservationId: string,
    companyId?: string | null,
    startTime: string,
    returnTime: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateReviewSubscriptionVariables = {
};

export type OnUpdateReviewSubscription = {
  onUpdateReview?:  {
    __typename: "Review",
    id: string,
    group?: string | null,
    organizationId?: string | null,
    orgBusinessId?: string | null,
    orgExternalBusinessId?: string | null,
    rating: number,
    comment?: string | null,
    explanation?: string | null,
    reservationId: string,
    companyId?: string | null,
    startTime: string,
    returnTime: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteReviewSubscriptionVariables = {
};

export type OnDeleteReviewSubscription = {
  onDeleteReview?:  {
    __typename: "Review",
    id: string,
    group?: string | null,
    organizationId?: string | null,
    orgBusinessId?: string | null,
    orgExternalBusinessId?: string | null,
    rating: number,
    comment?: string | null,
    explanation?: string | null,
    reservationId: string,
    companyId?: string | null,
    startTime: string,
    returnTime: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateNotificationSubscriptionVariables = {
};

export type OnCreateNotificationSubscription = {
  onCreateNotification?:  {
    __typename: "Notification",
    id: string,
    type: NotificationType,
    status?: NotificationStatus | null,
    name?: string | null,
    message?: string | null,
    organizationId?: string | null,
    orgBusinessId?: string | null,
    group?: string | null,
    reservationId?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateNotificationSubscriptionVariables = {
};

export type OnUpdateNotificationSubscription = {
  onUpdateNotification?:  {
    __typename: "Notification",
    id: string,
    type: NotificationType,
    status?: NotificationStatus | null,
    name?: string | null,
    message?: string | null,
    organizationId?: string | null,
    orgBusinessId?: string | null,
    group?: string | null,
    reservationId?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteNotificationSubscriptionVariables = {
};

export type OnDeleteNotificationSubscription = {
  onDeleteNotification?:  {
    __typename: "Notification",
    id: string,
    type: NotificationType,
    status?: NotificationStatus | null,
    name?: string | null,
    message?: string | null,
    organizationId?: string | null,
    orgBusinessId?: string | null,
    group?: string | null,
    reservationId?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateCustomerSubscriptionVariables = {
};

export type OnCreateCustomerSubscription = {
  onCreateCustomer?:  {
    __typename: "Customer",
    id: string,
    organizationId?: string | null,
    orgBusinessId?: string | null,
    orgExternalBusinessId?: string | null,
    group?: string | null,
    companyName?: string | null,
    brokerName?: string | null,
    address?: string | null,
    postalCode?: string | null,
    city?: string | null,
    phone?: string | null,
    email?: string | null,
    companyBillingAddress?: string | null,
    companyBusinessId?: string | null,
    companyCity?: string | null,
    companyPostalCode?: string | null,
    companyBillingRef?: string | null,
    companyEBillingOperator?: string | null,
    companyEBillingAddress?: string | null,
    clientType?: string | null,
    isDeleted?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateCustomerSubscriptionVariables = {
};

export type OnUpdateCustomerSubscription = {
  onUpdateCustomer?:  {
    __typename: "Customer",
    id: string,
    organizationId?: string | null,
    orgBusinessId?: string | null,
    orgExternalBusinessId?: string | null,
    group?: string | null,
    companyName?: string | null,
    brokerName?: string | null,
    address?: string | null,
    postalCode?: string | null,
    city?: string | null,
    phone?: string | null,
    email?: string | null,
    companyBillingAddress?: string | null,
    companyBusinessId?: string | null,
    companyCity?: string | null,
    companyPostalCode?: string | null,
    companyBillingRef?: string | null,
    companyEBillingOperator?: string | null,
    companyEBillingAddress?: string | null,
    clientType?: string | null,
    isDeleted?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteCustomerSubscriptionVariables = {
};

export type OnDeleteCustomerSubscription = {
  onDeleteCustomer?:  {
    __typename: "Customer",
    id: string,
    organizationId?: string | null,
    orgBusinessId?: string | null,
    orgExternalBusinessId?: string | null,
    group?: string | null,
    companyName?: string | null,
    brokerName?: string | null,
    address?: string | null,
    postalCode?: string | null,
    city?: string | null,
    phone?: string | null,
    email?: string | null,
    companyBillingAddress?: string | null,
    companyBusinessId?: string | null,
    companyCity?: string | null,
    companyPostalCode?: string | null,
    companyBillingRef?: string | null,
    companyEBillingOperator?: string | null,
    companyEBillingAddress?: string | null,
    clientType?: string | null,
    isDeleted?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};
