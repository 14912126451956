import {
  Checkbox,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
  Select,
  OutlinedInput,
  InputLabel,
  FormControl,
  MenuItem,
  ListItemText,
  InputAdornment,
} from "@mui/material";
import { useFormikContext } from "formik";
import { FormGrid } from "../common/FormComponents";
import { useContext } from "react";
import { AppContext } from "../../AppContext";
import { useCategories } from "~/queries/useCategories";
import { PrintingComponent } from "~/components/ReservationDialogV2/components/PrintContract";
const ITEM_HEIGHT = 48;

const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function CompanyInformation({ visibility }) {
  const { values, touched, errors, handleBlur, handleChange, setFieldValue } =
    useFormikContext();
  const { company } = useContext(AppContext);
  const categories = useCategories();

  const handleChangeCategoryIds = (event) => {
    const {
      target: { value },
    } = event;
    setFieldValue(
      "disableReservationCategoryIds",
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  return (
    <Grid container spacing={2} direction={"row"}>
      <FormGrid md={6}>
        <TextField
          value={values?.name}
          onChange={handleChange}
          onBlur={handleBlur}
          name="name"
          label="Nimi"
          variant="outlined"
          required
          error={touched.name && !!errors.name}
          helperText={touched.name && errors.name}
          fullWidth
        />
      </FormGrid>
      <FormGrid md={6}>
        <TextField
          value={values?.displayName}
          onChange={handleChange}
          onBlur={handleBlur}
          name="displayName"
          label="Asiakkaan varauskalenterissa näkyvä nimi"
          variant="outlined"
          required
          error={touched.displayName && !!errors.displayName}
          helperText={touched.displayName && errors.displayName}
          fullWidth
        />
      </FormGrid>

      <FormGrid md={6}>
        <TextField
          value={values?.orderNumberDealer ?? ""}
          onChange={handleChange}
          onBlur={handleBlur}
          name="orderNumberDealer"
          label="Järjestysnumero (hallintapaneeli)"
          variant="outlined"
          type="number"
          fullWidth
          data-cy="companyDialogOrderNumber"
        />
      </FormGrid>
      <FormGrid md={6}>
        <TextField
          value={values?.orderNumber ?? ""}
          onChange={handleChange}
          onBlur={handleBlur}
          name="orderNumber"
          label="Järjestysnumero (asiakasnäkymä)"
          variant="outlined"
          type="number"
          fullWidth
          data-cy="companyDialogOrderNumber"
        />
      </FormGrid>

      <FormGrid md={6}>
        <TextField
          value={values?.address}
          onChange={handleChange}
          onBlur={handleBlur}
          name="address"
          label="Osoite"
          variant="outlined"
          required
          error={touched.address && !!errors.address}
          helperText={touched.address && errors.address}
          fullWidth
          data-cy="companyDialogAddress"
        />
      </FormGrid>
      <FormGrid md={6}>
        <TextField
          value={values?.businessId}
          onChange={handleChange}
          onBlur={handleBlur}
          name="businessId"
          label="Y-tunnus"
          variant="outlined"
          required
          error={touched.businessId && !!errors.businessId}
          helperText={touched.businessId && errors.businessId}
          fullWidth
          data-cy="companyDialogBusinessId"
        />
      </FormGrid>
      <FormGrid md={6}>
        <TextField
          value={values?.postalCode}
          onChange={handleChange}
          onBlur={handleBlur}
          name="postalCode"
          label="Postinumero"
          variant="outlined"
          required
          error={touched.postalCode && !!errors.postalCode}
          helperText={touched.postalCode && errors.postalCode}
          fullWidth
          data-cy="companyDialogPostalCode"
        />
      </FormGrid>
      <FormGrid md={6}>
        <TextField
          value={values?.city}
          onChange={handleChange}
          onBlur={handleBlur}
          name="city"
          label="Kaupunki"
          variant="outlined"
          required
          error={touched.city && !!errors.city}
          helperText={touched.city && errors.city}
          fullWidth
          data-cy="companyDialogCity"
        />
      </FormGrid>

      <FormGrid md={6}>
        <TextField
          value={values?.phone}
          onChange={handleChange}
          onBlur={handleBlur}
          name="phone"
          label="Puhelin"
          variant="outlined"
          required
          error={touched.phone && !!errors.phone}
          helperText={touched.phone && errors.phone}
          fullWidth
          data-cy="companyDialogPhone"
        />
      </FormGrid>
      <FormGrid md={6}>
        <TextField
          value={values?.email}
          onChange={handleChange}
          onBlur={handleBlur}
          name="email"
          label="Sähköposti"
          variant="outlined"
          required
          error={touched.email && !!errors.email}
          helperText={touched.email && errors.email}
          fullWidth
          data-cy="companyDialogEmail"
        />
      </FormGrid>
      <FormGrid md={6}>
        <TextField
          value={values?.termsUrl}
          onChange={handleChange}
          onBlur={handleBlur}
          name="termsUrl"
          label="Varausehtojen URL osoite"
          variant="outlined"
          required
          error={touched.termsUrl && !!errors.termsUrl}
          helperText={touched.termsUrl && errors.termsUrl}
          fullWidth
          data-cy="companyDialogTermsUrl"
        />
      </FormGrid>

      <FormGrid md={6}>
        <TextField
          value={values?.reservationLinkExpirationHours ?? 1}
          onChange={handleChange}
          onBlur={handleBlur}
          name="reservationLinkExpirationHours"
          label="Täydennyslinkin oletusvoimassaoloaika tunteina"
          type="number"
          variant="outlined"
          error={
            touched.receiptInfo?.reservationLinkExpirationHours &&
            !!errors.receiptInfo?.reservationLinkExpirationHours
          }
          helperText={
            touched.receiptInfo?.reservationLinkExpirationHours &&
            errors.receiptInfo?.reservationLinkExpirationHours
          }
          fullWidth
        />
      </FormGrid>
      {company?.organizationId && (
        <FormGrid md={6}>
          <TextField
            value={values?.deliveryBufferHours ?? ""}
            onChange={handleChange}
            onBlur={handleBlur}
            name="deliveryBufferHours"
            label="Varauksen aloitusajan puskuri (h)"
            variant="outlined"
            fullWidth
            type="number"
          />
        </FormGrid>
      )}

      {company?.organizationId && (
        <FormGrid md={6}>
          <TextField
            value={values?.deliveryFee ?? ""}
            onChange={handleChange}
            onBlur={handleBlur}
            name="deliveryFee"
            label="Toimitusmaksu"
            variant="outlined"
            fullWidth
            InputProps={{
              endAdornment: <InputAdornment position="end">€</InputAdornment>,
            }}
          />
        </FormGrid>
      )}
      {company?.organizationId && (
        <FormGrid md={6}>
          <TextField
            value={values?.offHourAvailabilityFee ?? ""}
            onChange={handleChange}
            onBlur={handleBlur}
            name="offHourAvailabilityFee"
            label="Päivystysmaksu"
            variant="outlined"
            fullWidth
            InputProps={{
              endAdornment: <InputAdornment position="end">€</InputAdornment>,
            }}
          />
        </FormGrid>
      )}
      {company?.organizationId && (
        <FormGrid md={6}>
          <FormControl fullWidth>
            <InputLabel id="disableReservationCategoryIds-label">
              Estä vuokraus autoluokista
            </InputLabel>
            <Select
              labelId="disableReservationCategoryIds-label"
              id="disableReservationCategoryIds"
              multiple
              value={values?.disableReservationCategoryIds ?? []}
              onChange={handleChangeCategoryIds}
              input={<OutlinedInput label="Estä vuokraus autoluokista" />}
              renderValue={(selected) =>
                selected.map(
                  (s) => `${categories?.find((c) => c.id === s)?.name}, `
                )
              }
              MenuProps={MenuProps}
            >
              {categories.map((category) => (
                <MenuItem key={category.id} value={category.id}>
                  <Checkbox
                    checked={values?.disableReservationCategoryIds?.some(
                      (id) => id === category?.id
                    )}
                  />
                  <ListItemText primary={category?.name} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </FormGrid>
      )}

      <FormGrid>
        <PrintingComponent adjustSettings={true} />
      </FormGrid>
      <FormGrid>
        <FormControlLabel
          control={
            <Checkbox
              name="enableCart"
              checked={values?.enableCart ?? false}
              color="primary"
              onChange={handleChange}
              onBlur={handleBlur}
            />
          }
          label={
            <Typography variant="caption">
              Salli asiakasnäkymässä usean tuotteen lisäys varaukselle
            </Typography>
          }
        />
      </FormGrid>
      <FormGrid>
        <FormControlLabel
          control={
            <Checkbox
              name="enableRegistrationPlate"
              checked={values?.enableRegistrationPlate}
              color="primary"
              onChange={handleChange}
              onBlur={handleBlur}
            />
          }
          label={
            <Typography variant="caption">
              Näytä rekisterinumero sopimuksella ja varausvahvistuksella
              (oletus)
            </Typography>
          }
        />
      </FormGrid>
      <FormGrid>
        <FormControlLabel
          control={
            <Checkbox
              name="receiptInfo.enabled"
              checked={values?.receiptInfo?.enabled ?? false}
              color="primary"
              onChange={handleChange}
              onBlur={handleBlur}
            />
          }
          label={
            <Typography variant="caption">
              Lisää kuitti maksettujen varausten varausvahvistuksille
            </Typography>
          }
        />
      </FormGrid>
      {values?.receiptInfo?.enabled && (
        <FormGrid container spacing={1} sx={{ justifyContent: "center" }}>
          <FormGrid>
            <TextField
              value={values?.receiptInfo?.name}
              onChange={handleChange}
              onBlur={handleBlur}
              name="receiptInfo.name"
              label="Myyjän nimi"
              variant="outlined"
              size="small"
              error={touched.receiptInfo?.name && !!errors.receiptInfo?.name}
              helperText={touched.receiptInfo?.name && errors.receiptInfo?.name}
              fullWidth
            />
          </FormGrid>
          <FormGrid>
            <TextField
              value={values?.receiptInfo?.address}
              onChange={handleChange}
              onBlur={handleBlur}
              name="receiptInfo.address"
              label="Myyjän osoite"
              variant="outlined"
              size="small"
              error={
                touched.receiptInfo?.address && !!errors.receiptInfo?.address
              }
              helperText={
                touched.receiptInfo?.address && errors.receiptInfo?.address
              }
              fullWidth
            />
          </FormGrid>
          <FormGrid md={6}>
            <TextField
              value={values?.receiptInfo?.city}
              onChange={handleChange}
              onBlur={handleBlur}
              name="receiptInfo.city"
              label="Myyjän kaupunki"
              variant="outlined"
              size="small"
              error={touched.receiptInfo?.city && !!errors.receiptInfo?.city}
              helperText={touched.receiptInfo?.city && errors.receiptInfo?.city}
              fullWidth
            />
          </FormGrid>
          <FormGrid md={4}>
            <TextField
              value={values?.receiptInfo?.postalCode}
              onChange={handleChange}
              onBlur={handleBlur}
              name="receiptInfo.postalCode"
              label="Myyjän postinumero"
              variant="outlined"
              size="small"
              error={
                touched.receiptInfo?.postalCode &&
                !!errors.receiptInfo?.postalCode
              }
              helperText={
                touched.receiptInfo?.postalCode &&
                errors.receiptInfo?.postalCode
              }
              fullWidth
            />
          </FormGrid>
        </FormGrid>
      )}
    </Grid>
  );
}
