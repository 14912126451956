import { useAtomValue } from "jotai";
import React, { useContext, useMemo, useState } from "react";
import { Grid } from "@mui/material";
import { useFormikContext } from "formik";
import { parseToCents } from "../../../../../../../shared/money";
import { ReservationContext } from "../../../../../contextProviders/ReservationContext";
import InvoiceDialog from "../../../../../components/InvoiceDialog";

import PaymentStatusFilters from "./components/PaymentStatusFilters";
import InsuranceBillingSettings from "./components/InsuranceBillingSettings";
import InvoiceItemsTable from "./components/InvoiceItemsTable";
import PaymentEventsTable from "./components/PaymentEventsTable";
import ActionButtons from "./components/ActionButtons";
import { useFetchInvoices } from "../../../../../../../services/fetchInvoices";
import { useFetchPaymentEvents } from "../../../../../../../services/fetchPaymentEvents";
import { vehiclePricesAtom } from "~/stores/vehiclePrices";
import { formatDate, formatDateTime } from "~/utils/date";
import AppliedVoucher from "../../../../../components/AppliedVoucher";

const EPaymentMethodLabels = {
  CASH: "Käteismaksu",
  CARD: "Korttimaksu",
};

export default function ReservationPayment() {
  const [showInvoiceDialog, setShowInvoiceDialog] = useState(false);
  const { FEATURE_INVOICING } = useContext(ReservationContext);
  const { values } = useFormikContext();
  const vehiclePrices = useAtomValue(vehiclePricesAtom);

  const { invoices, invoicesLoading, invoicesMutate } = useFetchInvoices(
    values?.id,
    FEATURE_INVOICING
  );

  const { paymentEvents, paymentEventsLoading, paymentEventsMutate } =
    useFetchPaymentEvents(values?.id, FEATURE_INVOICING);

  const getInvoiceTypeLabel = (type) => {
    switch (type) {
      case "FULL":
        return "Koko varaus";
      case "EXTRA":
        return "Lisälasku";
      case "INSURANCE_COMPANY":
        return "Vakuutusyhtiö";
      case "INSURANCE_CUSTOMER":
        return "Vakuutusyhtiö asiakkaan osuus";
      default:
        return "";
    }
  };

  const calculateInvoiceSum = (items) => {
    return items?.reduce(
      (total, item) => total + item.quantity * item.unitprice,
      0
    );
  };

  const invoiceItems = useMemo(() => {
    const additionalServicesItems = (values?.additionalServices ?? []).map(
      (service) => {
        const taxRate = service.tax ?? 24; // Default tax rate is 24%

        const priceWithoutTax =
          parseToCents(service.price) / (1 + taxRate / 100);
        return {
          id: service.key,
          description: service.description,
          quantity: service.quantity,
          priceExVAT: priceWithoutTax,
          VAT: `${taxRate}%`,
          priceIncVAT: parseToCents(service.price),
        };
      }
    );

    const reservationVehiclesItems = (values?.reservationVehicles ?? []).map(
      (vehicle) => {
        const taxRate = vehiclePrices[vehicle.id]?.tax ?? 24; // Default tax rate is 24%

        const priceWithoutTax =
          vehiclePrices[vehicle.id]?.price / (1 + taxRate / 100);
        return {
          id: vehicle.id,
          description: `${vehicle.name}, Rekisterinumero: ${vehicle.registrationPlate}`,
          quantity: 1, // Assuming quantity is 1 for vehicles
          priceExVAT: priceWithoutTax,
          VAT: `${taxRate}%`,
          priceIncVAT: vehiclePrices[vehicle.id]?.price,
        };
      }
    );

    const reservationWildCardItems = (values?.wildCardVehicles ?? []).map(
      (wildCardVehicle) => {
        const taxRate = vehiclePrices[wildCardVehicle.categoryId]?.tax ?? 24; // Default tax rate is 24%

        const priceWithoutTax =
          vehiclePrices[wildCardVehicle.categoryid]?.price / (1 + taxRate / 100);
        return {
          id: wildCardVehicle.id,
          description: `Jokeri: ${wildCardVehicle.name}`,
          quantity: 1, // Assuming quantity is 1 for vehicles
          priceExVAT: priceWithoutTax,
          VAT: `${taxRate}%`,
          priceIncVAT: vehiclePrices[wildCardVehicle.categoryId]?.price,
        };
      }
    );
    return [...additionalServicesItems, ...reservationVehiclesItems, ...reservationWildCardItems];
  }, [values?.additionalServices, values?.reservationVehicles]);

  const combinedEvents = useMemo(() => {
    if (!invoices || !paymentEvents) {
      return [];
    }

    const invoiceEvents = invoices.map((invoice) => {
      const sum = calculateInvoiceSum(invoice.items);
      const formattedDate = new Date(invoice.createdAt).toLocaleDateString(
        "fi-FI"
      );
      const innovoiceIdDisplay = invoice.innovoiceId
        ? ` - Innovoice ID: ${invoice.innovoiceId}`
        : "";
      const label = `Lasku: ${getInvoiceTypeLabel(
        invoice.type
      )} - Luotu: ${formattedDate}${innovoiceIdDisplay}`;

      return {
        id: invoice.id,
        type: invoice.type,
        innovoiceId: invoice.innovoiceId,
        label: label,
        sum: sum,
        createdAt: new Date(invoice.createdAt).toLocaleDateString("fi-FI"),
        channel: "Invoice",
        invoice: invoice, // Necessary for invoiceDialog (for now)
        isRefunded: !!invoice.creditNoteInvoiceId,
      };
    });

    const paymentEventItems = paymentEvents.map((event) => {
      const formattedDateTime = formatDateTime(event.createdAt);
      const innovoiceIdDisplay = event.innovoiceId
        ? ` - Innovoice ID: ${event.innovoiceId}`
        : "";
      const label =
        event.channel === "MANUAL"
          ? `${
              EPaymentMethodLabels[event.paymentMethod]
            } vastaanotettu ${formattedDateTime} (${
              event.createdByUserName
            })${innovoiceIdDisplay}`
          : `Visma pay  - Luotu: ${formattedDateTime}${innovoiceIdDisplay} (${event.paymentMethod})`;
      return {
        id: event.id,
        type: event.channel,
        label: label,
        sum: event.paymentTotal,
        createdAt: new Date(event.createdAt).toLocaleDateString("fi-FI"),
        createdByUserName: event.createdByUserName,
        channel: "Payment",
        isDeleted: event.isDeleted || false,
        deletedBy: event.deletedBy || "",
        deletedAt: event.deletedAt || "",
      };
    });

    return [...invoiceEvents, ...paymentEventItems];
  }, [invoices, paymentEvents]);

  const invoiceItemsSum = useMemo(() => {
    return invoiceItems.reduce(
      (acc, item) => acc + parseFloat(item.priceIncVAT) * item.quantity,
      0
    );
  }, [invoiceItems]);

  const unpaidAmount = useMemo(() => {
    const totalPaid = combinedEvents.reduce((acc, event) => {
      if (!event.isDeleted) {
        const numericSum = event.sum;
        return acc + numericSum;
      }
      return acc;
    }, 0);

    return invoiceItemsSum - totalPaid;
  }, [invoiceItemsSum, combinedEvents]);

  return (
    <>
      {/* TODO: Make the different fields (ie. paymentstatusfilters) work with current implementation of formik */}
      <Grid container lg={12}>
        <Grid container item xs={12}>
          <Grid item xs={12} sm={6}>
            <PaymentStatusFilters />
          </Grid>
          <Grid item xs={12} sm={6} mt={1}>
            <AppliedVoucher />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <InvoiceItemsTable
            FEATURE_INVOICING={FEATURE_INVOICING}
            invoiceItems={invoiceItems}
            invoiceItemsSum={invoiceItemsSum}
          />
        </Grid>
        <Grid item xs={12}>
          <PaymentEventsTable
            setShowInvoiceDialog={setShowInvoiceDialog}
            unpaidAmount={unpaidAmount}
            showActions={true}
            invoicesMutate={invoicesMutate}
            invoicesLoading={invoicesLoading}
            paymentEventsMutate={paymentEventsMutate}
            paymentEventsLoading={paymentEventsLoading}
            combinedEvents={combinedEvents}
            reservationId={values?.id}
          />
        </Grid>
        <Grid item xs={12}>
          <InsuranceBillingSettings />
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <ActionButtons
          setShowInvoiceDialog={setShowInvoiceDialog}
          FEATURE_INVOICING={FEATURE_INVOICING}
          invoiceItems={invoiceItems}
          invoiceItemsSum={invoiceItemsSum}
          unpaidAmount={unpaidAmount}
          showActions={true}
          invoicesMutate={invoicesMutate}
          invoicesLoading={invoicesLoading}
          paymentEventsMutate={paymentEventsMutate}
          paymentEventsLoading={paymentEventsLoading}
          combinedEvents={combinedEvents}
        />
      </Grid>
      {showInvoiceDialog && (
        <InvoiceDialog
          title="Laskutus"
          onClose={() => setShowInvoiceDialog(false)}
          invoiceState={showInvoiceDialog}
          reservationId={values?.id}
          reservationValues={values}
          FEATURE_INVOICING={FEATURE_INVOICING}
        />
      )}
    </>
  );
}
