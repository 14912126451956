import React, { useState, useMemo, useContext } from "react";
import {
  Select,
  MenuItem,
  Typography,
  useTheme,
  useMediaQuery,
  Divider,
  Stack,
  Box,
  IconButton,
} from "@mui/material";
import { Formik, useFormikContext } from "formik";
import { API, graphqlOperation } from "aws-amplify";
import { updateCompany } from "../../graphql/custom-mutations";
import { init } from "../../utils/array-utilities";
import { centsToLocalString, parseToCents } from "../../shared/money";
import { parseBusinessHours } from "../../utils/business-hours-string";
import { useNotification } from "../Notification";
import fi from "date-fns/esm/locale/fi";
import {
  startOfWeek,
  endOfWeek,
  eachDayOfInterval,
  getDay,
  format,
} from "date-fns";
import captureError from "../../utils/capture-error";
import { enableVismaSign } from "../../features";

import {
  TbClock2,
  TbCurrencyEuro,
  TbFileText,
  TbMessage2,
  TbMessageCircle2,
  TbCurrentLocation,
  TbBriefcase,
  TbMenu2,
} from "react-icons/tb";
import CompanyInformation from "./CompanyInformation";
import ConfirmationMessage from "./ConfirmationMessage";
import CompanyBusinessHours from "./CompanyBusinessHours";
import DialogComponent from "../DialogComponent";
import ReservationDeposit from "./ReservationDeposit";
import ReservationCancellation from "./ReservationCancellation";
import VismaSignSettings from "./VismaSignSettings";
import {
  useContextFetchCompanies,
  useFetchCompanies,
} from "../../services/fetchCompanies";
import { AppContext } from "../../AppContext";
import { DialogMenu } from "../common/MenuAndContent";
import TelegramMessageSettings from "./TelegramMessageSettings";
import BusinessInformation from "./BusinessInformation";
import PickupLocations from "./PickupLocations";
import { FormGrid } from "../common/FormComponents";
import ExternalBusinessSettings from "./ExternalBusinessSettings";
import { useNavigate } from "react-router-dom";
import { useSetAtom } from "jotai";
import { reservationDataAtom } from "../../atoms/reservationDataAtom";

const capitalize = (str) => {
  if (str) {
    return str[0].toUpperCase() + str.slice(1);
  } else {
    return str;
  }
};

const weekdays = (() => {
  const now = new Date();
  return eachDayOfInterval({
    start: startOfWeek(now, { locale: fi }),
    end: endOfWeek(now, { locale: fi }),
  }).map((d) => ({
    label: capitalize(format(d, "eeeeee", { locale: fi })),
    index: getDay(d),
  }));
})();

export default function CompanyDialog({ onClose, showSpecialHours }) {
  const [showBusinessSettings, setShowBusinessSettings] = useState(false);
  const [settingsOption, setSettingsOption] = useState();
  const { group, company } = useContext(AppContext);
  const { companiesMutate } = useContextFetchCompanies();
  const [submitting, setSubmitting] = useState(false);
  const theme = useTheme();
  const notification = useNotification();
  const handleDrawerToggle = () => setOpenDrawer((prevState) => !prevState);
  const FEATURE_ENABLE_VISMA_SIGN = useMemo(() => {
    return enableVismaSign(process.env.REACT_APP_ENV, group);
  }, [group]);
  const navigate = useNavigate();
  const onSubmit = async (values) => {
    setSubmitting(true);

    const {
      address,
      businessId,
      city,
      confirmationMsg,
      displayName,
      email,
      enableCart,
      enableRegistrationPlate,
      id,
      name,
      nameInEmail,
      orderNumber,
      orderNumberDealer,
      phone,
      postalCode,
      receiptInfo,
      disableReservationCategoryIds,
      reservationCancellation,
      reservationLinkExpirationHours,
      signConfirmationEmail,
      statusLinkMsg,
      termsUrl,
      deliveryBufferHours,
      weeklyBusinessHours,
      offHourAvailabilityFee,
    } = values;

    const parsedWeeklyBusinessHours = weeklyBusinessHours.map(
      (bh) => parseBusinessHours(bh).ok
    );

    const reservationDeposit = values.reservationDeposit;
    reservationDeposit.amount = parseToCents(reservationDeposit.amount);

    const deliveryFee = parseToCents(values.deliveryFee);

    try {
      const companyInput = {
        address,
        businessId,
        city,
        confirmationMsg,
        displayName,
        email,
        enableCart,
        enableRegistrationPlate,
        id,
        name,
        nameInEmail,
        orderNumber: parseInt(orderNumber) || null,
        orderNumberDealer: parseInt(orderNumberDealer) || null,
        phone,
        postalCode,
        receiptInfo,
        reservationCancellation,
        reservationLinkExpirationHours,
        signConfirmationEmail,
        statusLinkMsg,
        termsUrl,
        deliveryBufferHours,
        deliveryFee,
        disableReservationCategoryIds:
          disableReservationCategoryIds?.length > 0
            ? disableReservationCategoryIds
            : null,
        weeklyBusinessHours: parsedWeeklyBusinessHours,
      };

      if (offHourAvailabilityFee !== "") {
        companyInput.offHourAvailabilityFee = parseToCents(
          offHourAvailabilityFee
        );
      }

      if (offHourAvailabilityFee === "" || offHourAvailabilityFee === 0) {
        companyInput.offHourAvailabilityFee = null;
      }

      if (company?.enableReservationDeposit) {
        companyInput.reservationDeposit = reservationDeposit;
      }

      // Should always have id
      if (id) {
        await API.graphql(
          graphqlOperation(updateCompany, {
            input: companyInput,
          })
        );
      }
      companiesMutate();
      navigate(-1);

      return;
    } catch (e) {
      captureError("Update company failed", "UPDATE_COMPANY_FAILED", e);
      console.error("Company onsubmit failed: ", e);
      notification.show("Jokin meni vikaan");
    }
    setSubmitting(false);
  };
  const [selectedProductTab, setSelectedProductTab] = useState("info");

  const handleSelectTab = (tab) => {
    setSelectedProductTab(tab);
    if (mobileViewPort) {
      setOpenDrawer((prevState) => !prevState);
    }
  };

  const initialValues = {
    name: "",
    displayName: "",
    businessId: "",
    address: "",
    postalCode: "",
    city: "",
    phone: "",
    email: "",
    termsUrl: "",
    orderNumber: "",
    nameInEmail: "",
    confirmationMsg: "",
    statusLinkMsg: "",
    signConfirmationEmail: "",
    reservationDeposit: {
      amount: "",
      reminderBefore: "",
      paymentBefore: "",
    },
    offHourAvailabilityFee: "",
    weeklyBusinessHours: init(7, () => ({
      openAt: "",
      closeAt: "",
    })),
  };

  const mobileViewPort = useMediaQuery(theme.breakpoints.down("md"));

  const [openDrawer, setOpenDrawer] = useState(() =>
    mobileViewPort ? false : true
  );
  const initializeFormikData = (company) => {
    if (!company) {
      return {};
    }
    return {
      ...initialValues,
      ...company,
      reservationDeposit: {
        ...company?.reservationDeposit,
        amount: centsToLocalString(company?.reservationDeposit?.amount),
      },
      enableCart: company?.enableCart || false,
      enableRegistrationPlate:
        typeof company?.enableRegistrationPlate === "boolean"
          ? company?.enableRegistrationPlate
          : true,
      receiptInfo: {
        enabled: company?.receiptInfo?.enabled || false,
        name: company?.receiptInfo?.name || "",
        address: company?.receiptInfo?.address || "",
        city: company?.receiptInfo?.city || "",
        postalCode: company?.receiptInfo?.postalCode || "",
      },
      deliveryBufferHours: company?.deliveryBufferHours ?? null,
      deliveryFee: company?.deliveryFee
        ? centsToLocalString(company?.deliveryFee)
        : "",
      disableReservationCategoryIds:
        company?.disableReservationCategoryIds ?? [],
      weeklyBusinessHours: initialValues.weeklyBusinessHours.map(
        (hours, idx) => {
          return company?.weeklyBusinessHours[idx] || hours;
        }
      ),
      offHourAvailabilityFee: centsToLocalString(
        company?.offHourAvailabilityFee
      ),
    };
  };
  return (
    <Formik
      initialValues={initializeFormikData(company)}
      validate={(values) => {
        const errors = {};

        if (!values.name) {
          errors.name = "Täytä tämä kenttä";
        }

        if (!values.displayName) {
          errors.displayName = "Täytä tämä kenttä";
        }

        if (!values.email) {
          errors.email = "Täytä tämä kenttä";
        }

        if (!values.address) {
          errors.address = "Täytä tämä kenttä";
        }

        if (!values.postalCode) {
          errors.postalCode = "Täytä tämä kenttä";
        }

        if (!values.city) {
          errors.city = "Täytä tämä kenttä";
        }

        if (!values.phone) {
          errors.phone = "Täytä tämä kenttä";
        }

        if (!values.businessId) {
          errors.businessId = "Täytä tämä kenttä";
        }

        if (!values.termsUrl) {
          errors.termsUrl = "Täytä tämä kenttä";
        }

        if (company?.enableReservationDeposit) {
          const reservationDeposit = {};
          if (
            values.reservationDeposit.amount !== "" &&
            parseToCents(values.reservationDeposit.amount) == null
          ) {
            reservationDeposit.amount = 'Anna hinta muodossa "123,45"';
          }

          if (
            parseInt(values.reservationDeposit.reminderBefore) !==
            values.reservationDeposit.reminderBefore
          ) {
            reservationDeposit.reminderBefore = "Syötä numero";
          }
          if (
            parseInt(values.reservationDeposit.paymentBefore) !==
            values.reservationDeposit.paymentBefore
          ) {
            reservationDeposit.paymentBefore = "Syötä numero";
          }

          if (!values.reservationDeposit.amount) {
            reservationDeposit.amount = "Täytä tämä kenttä";
          }
          if (!values.reservationDeposit.reminderBefore) {
            reservationDeposit.reminderBefore = "Täytä tämä kenttä";
          }
          if (!values.reservationDeposit.paymentBefore) {
            reservationDeposit.paymentBefore = "Täytä tämä kenttä";
          }
          if (Object.keys(reservationDeposit).length) {
            errors.reservationDeposit = reservationDeposit;
          }
        }

        if (values?.receiptInfo?.enabled) {
          const receiptInfoErrors = {};
          if (!values.receiptInfo.name) {
            receiptInfoErrors.name = "Täytä tämä kenttä";
          }
          if (!values.receiptInfo.address) {
            receiptInfoErrors.address = "Täytä tämä kenttä";
          }
          if (!values.receiptInfo.city) {
            receiptInfoErrors.city = "Täytä tämä kenttä";
          }
          if (!values.receiptInfo.postalCode) {
            receiptInfoErrors.postalCode = "Täytä tämä kenttä";
          }
          if (Object.keys(receiptInfoErrors).length) {
            errors.receiptInfo = receiptInfoErrors;
          }
        }

        if (
          values.reservationCancellation?.cancellationEnabled &&
          !/\[LINKKI, .+\]/.test(values.confirmationMsg)
        ) {
          errors.confirmationMsg =
            "Muista lisätä [LINKKI, xxx] teksti varausvahvistukseen.";
        }

        const weeklyBusinessHoursResults =
          values.weeklyBusinessHours.map(parseBusinessHours);

        const weeklyBusinessHoursErrors = [];
        weeklyBusinessHoursResults.forEach((r, i) => {
          if (r.errors) {
            weeklyBusinessHoursErrors[i] = r.errors;
          }
        });

        if (weeklyBusinessHoursErrors.length > 0) {
          errors.weeklyBusinessHours = weeklyBusinessHoursErrors;
        }

        return errors;
      }}
      onSubmit={onSubmit}
    >
      <DialogForm
        selectedProductTab={selectedProductTab}
        submitting={submitting}
        openDrawer={openDrawer}
        onClose={onClose}
        handleSelectTab={handleSelectTab}
        company={company}
        FEATURE_ENABLE_VISMA_SIGN={FEATURE_ENABLE_VISMA_SIGN}
        showSpecialHours={showSpecialHours}
        handleDrawerToggle={handleDrawerToggle}
        setSelectedProductTab={setSelectedProductTab}
        showBusinessSettings={showBusinessSettings}
        setShowBusinessSettings={setShowBusinessSettings}
        settingsOption={settingsOption}
        setSettingsOption={setSettingsOption}
        initializeFormikData={initializeFormikData}
      />
    </Formik>
  );
}

function DialogForm(props) {
  const {
    selectedProductTab,
    setSelectedProductTab,
    openDrawer,
    submitting,
    handleDrawerToggle,
    handleSelectTab,
    company,
    FEATURE_ENABLE_VISMA_SIGN,
    showSpecialHours,
    showBusinessSettings,
    setShowBusinessSettings,
    initializeFormikData,
  } = props;
  const { submitForm, errors, setValues } = useFormikContext();
  const { mobileViewPort, business, user, setCompany, mobileSmViewPort } =
    useContext(AppContext);

  const { companies } = useFetchCompanies(user?.group, business?.id);

  const theme = useTheme();
  const FEATURE_ENABLE_BUSINESS_SETTINGS = true;
  const navigate = useNavigate();
  const setEditReservationData = useSetAtom(reservationDataAtom);
  const onClose = (success) => {
    if (success) {
      setEditReservationData(null);
      navigate(-1);
    }
  };
  function companyDialogTab(tabName) {
    switch (tabName) {
      case "info":
        return (
          <CompanyInformation
            weekdays={weekdays}
            FEATURE_ENABLE_VISMA_SIGN={FEATURE_ENABLE_VISMA_SIGN}
            company={company}
          />
        );
      case "messages":
        return <ConfirmationMessage />;

      case "businessHours":
        return (
          <CompanyBusinessHours
            showSpecialHours={showSpecialHours}
            weekdays={weekdays}
            FEATURE_ENABLE_VISMA_SIGN={FEATURE_ENABLE_VISMA_SIGN}
            company={company}
          />
        );
      case "reservationDeposit":
        return (
          <ReservationDeposit
            weekdays={weekdays}
            FEATURE_ENABLE_VISMA_SIGN={FEATURE_ENABLE_VISMA_SIGN}
            company={company}
          />
        );

      case "reservationCancellation":
        return (
          <ReservationCancellation
            weekdays={weekdays}
            FEATURE_ENABLE_VISMA_SIGN={FEATURE_ENABLE_VISMA_SIGN}
            company={company}
          />
        );
      case "vismaSignSettings":
        return (
          <VismaSignSettings
            weekdays={weekdays}
            FEATURE_ENABLE_VISMA_SIGN={FEATURE_ENABLE_VISMA_SIGN}
            company={company}
          />
        );

      case "telegramNotificationSettings":
        return <TelegramMessageSettings />;

      default:
        return <CompanyInformation />;
    }
  }

  function businessDialogTab(tabName) {
    switch (tabName) {
      case "info":
        return <BusinessInformation />;
      case "locations":
        return <PickupLocations />;
      case "externalBusinessSettings":
        return <ExternalBusinessSettings />;
      default:
        return <CompanyInformation />;
    }
  }

  const companyMenu = useMemo(() => {
    const infoSubTitle = () => {
      const errorMsg = [
        "name",
        "displayName",
        "businessId",
        "email",
        "address",
        "postalCode",
        "phone",
        "city",
        "termsUrl",
      ];

      if (errorMsg.some((msg) => Object.keys(errors).includes(msg))) {
        return (
          <>
            <span style={{ color: theme.palette.warning.main }}>
              Tarkista tiedot
            </span>
          </>
        );
      }
      return <>Toimipisteen yhteystiedot </>;
    };

    const businessHoursSubTitle = () => {
      if (Object.keys(errors).includes("weeklyBusinessHours")) {
        return (
          <span style={{ color: theme.palette.warning.main }}>
            Tarkista tiedot
          </span>
        );
      }
      return <>Muokkaa aukioloaikoja</>;
    };

    const messagesSubTitle = () => {
      if (Object.keys(errors).includes("confirmationMsg")) {
        return (
          <span style={{ color: theme.palette.warning.main }}>
            Tarkista tiedot
          </span>
        );
      }
      return <>Muokkaa vahvistusviestä</>;
    };
    const reservationDepositSubTitle = () => {
      if (!company?.enableReservationDeposit) return "Ei käytössä";
      /* if (errors?.reservationDeposit) */
      if (Object.keys(errors).includes("reservationDeposit")) {
        return (
          <span style={{ color: theme.palette.warning.main }}>
            Tarkista tiedot
          </span>
        );
      }

      return <>Varausmaksun asetukset</>;
    };

    return [
      {
        title: "Toimipisteen tiedot",
        subTitle: infoSubTitle(),
        icon: <TbFileText style={{ fontSize: 28 }} />,
        tab: "info",
        active: true,
        visibility: true,
      },
      {
        title: "Aukioloajat",
        subTitle: businessHoursSubTitle(),
        icon: <TbClock2 style={{ fontSize: 28 }} />,
        tab: "businessHours",
        active: true,
        visibility: true,
      },
      {
        title: "Varausvahvistus",
        subTitle: messagesSubTitle(),
        icon: <TbMessage2 style={{ fontSize: 28 }} />,
        tab: "messages",
        active: true,
        visibility: true,
      },
      {
        title: "Varausmaksu",
        subTitle: reservationDepositSubTitle(),
        icon: <TbCurrencyEuro style={{ fontSize: 28 }} />,
        tab: "reservationDeposit",
        active: company?.enableReservationDeposit,
        visibility: true,
      },

      {
        title: "Varauksen peruutus",
        subTitle: <>Asetukset</>,
        icon: <TbFileText style={{ fontSize: 28 }} />,
        tab: "reservationCancellation",
        active: true,
        visibility: true,
      },

      {
        title: "Telegram notifikaatiot",
        subTitle: <>Telegram viestien asetukset</>,
        icon: <TbMessageCircle2 style={{ fontSize: 28 }} />,
        tab: "telegramNotificationSettings",
        active: true,
        visibility: business,
      },

      /*  {
        title: "Visma Sign",
        subTitle: <>Määritä varausmaksu</>,
        icon: <TbFileText style={{ fontSize: 28 }} />,
        tab: "vismaSignSettings",
        active: true,
      }, */
    ];
  }, [company, errors, theme.palette.warning.main, business]);

  const businessMenu = useMemo(() => {
    return [
      {
        title: "Yrityksen tiedot",
        subTitle: "Yhteystiedot",
        icon: <TbFileText style={{ fontSize: 28 }} />,
        tab: "info",
        active: true,
        visibility: true,
      },
      {
        title: "Toimituspisteet",
        subTitle: "Lisää nouto- & palautuspisteitä ",
        icon: <TbCurrentLocation style={{ fontSize: 28 }} />,
        tab: "locations",
        active: true,
        visibility: true,
      },
      {
        title: "Yritysasiakkaat",
        subTitle: "Yritysasiakkaiden noutopisteet",
        icon: <TbBriefcase style={{ fontSize: 28 }} />,
        tab: "externalBusinessSettings",
        active: false,
        visibility: true,
      },
    ];
  }, []);

  return (
    <DialogComponent
      open={true}
      onClose={onClose}
      maxWidth="xl"
      dialogClose={onClose}
      dialogActionSubmitting={submitting}
      dialogAction={submitForm}
      dialogActionText={"Tallenna"}
      companyDialog={true}
      testName="companyDialog"
      height={"80vh"}
    >
      <FormGrid>
        <Stack
          sx={{
            marginTop: "10px",
            marginBottom: "10px",
            position: "relative",
            alignItems: "center",
            justifyContent: "flex-start",
            flexDirection: "row",
            width: "100%",
            paddingX: "10px",
          }}
        >
          {mobileSmViewPort && (
            <IconButton
              disabled={submitting}
              onClick={() => {
                handleDrawerToggle();
              }}
              data-cy="reservationDialogOpenSidebarMobile"
            >
              <TbMenu2 color="primary" style={{ fontSize: "30px" }} />
            </IconButton>
          )}
          {!mobileSmViewPort && (
            <Box sx={{ marginRight: "15px" }}>
              <Typography
                sx={{
                  fontFamily: "Sofia Pro",
                  fontSize: mobileViewPort ? 16 : 22,
                }}
              >
                Toimipisteen asetukset
              </Typography>
            </Box>
          )}
          <Box>
            <Select
              value={showBusinessSettings ? "business-settings" : company?.id}
              renderValue={(value) => {
                if (value === "business-settings") {
                  return "Yrityksen asetukset";
                } else {
                  return companies?.find((c) => c.id === value)?.name;
                }
              }}
              size="small"
              onChange={(event) => {
                if (event.target.value === "business-settings") {
                  setShowBusinessSettings(true);
                  setSelectedProductTab("info");
                } else {
                  const selectedCompany = companies.find(
                    (c) => c.id === event.target.value
                  );
                  setCompany(selectedCompany);
                  setValues(initializeFormikData(selectedCompany));
                  setShowBusinessSettings(false);
                  setSelectedProductTab("info");
                }
              }}
            >
              {FEATURE_ENABLE_BUSINESS_SETTINGS && (
                <MenuItem value={"business-settings"}>
                  <Typography sx={{ fontWeight: 550 }}>
                    Yrityksen asetukset
                  </Typography>
                </MenuItem>
              )}
              {companies &&
                companies.map((company, idx) => (
                  <MenuItem
                    value={company?.id}
                    key={idx}
                    // onClick={() => handleChangeCompany(company?.id)}
                  >
                    <Typography>{company?.name}</Typography>
                  </MenuItem>
                ))}
            </Select>
          </Box>
        </Stack>
      </FormGrid>

      <Divider />

      {showBusinessSettings ? (
        <DialogMenu
          openDrawer={openDrawer}
          handleDrawerToggle={handleDrawerToggle}
          selectedProductTab={selectedProductTab}
          setSelectedProductTab={setSelectedProductTab}
          handleSelectTab={handleSelectTab}
          menu={businessMenu}
          minHeight={"750px"}
        >
          {businessDialogTab(selectedProductTab)}
        </DialogMenu>
      ) : (
        <DialogMenu
          openDrawer={openDrawer}
          handleDrawerToggle={handleDrawerToggle}
          selectedProductTab={selectedProductTab}
          setSelectedProductTab={setSelectedProductTab}
          handleSelectTab={handleSelectTab}
          menu={companyMenu}
          minHeight={"750px"}
        >
          <FormGrid sx={{ marginTop: "25px" }}>
            {companyDialogTab(selectedProductTab)}
          </FormGrid>
        </DialogMenu>
      )}
    </DialogComponent>
  );
}
