import { Amplify, I18n, Auth as AmplifyAuth } from "aws-amplify";
import { dict } from "./amplify-i18n";
import App from "./App";
import {
  Authenticator,
  Heading,
  useTheme,
  Text,
  View,
  Image,
  Button,
  useAuthenticator,
} from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import awsExports from "./aws-exports";
import logo from "./customerLogos/uRent_logo.jpg";

Amplify.configure({
  API: {
    graphql_headers: async () => ({
      Authorization: (await AmplifyAuth.currentSession())
        .getIdToken()
        .getJwtToken(),
      "Urent-Build-Date": import.meta.env.VITE_BUILD_DATE ?? "",
    }),
  },
  ...awsExports,
});

I18n.putVocabularies(dict);
I18n.setLanguage("fi");

const components = {
  /*  Header() {
    const { tokens } = useTheme();

    return (
      <View textAlign="center" padding={tokens.space.large}>
        <Image alt="Urent" src={logo} />
      </View>
    );
  }, */

  Footer() {
    const { tokens } = useTheme();

    return (
      <View textAlign="center" padding={tokens.space.large}>
        <Text color={tokens.colors.neutral[80]}>
          {/*    &copy; All Rights Reserved */}
        </Text>
      </View>
    );
  },

  SignIn: {
    Header() {
      const { tokens } = useTheme();

      return (
        <View textAlign="center" padding={tokens.space.large} marginTop={25}>
          <Image
            objectFit="contain"
            height="55%"
            width="55%"
            alt="Urent"
            src={logo}
          />
        </View>
      );
    },
    Footer() {
      const { toResetPassword } = useAuthenticator();

      return (
        <View textAlign="center">
          <Button
            fontWeight="normal"
            onClick={toResetPassword}
            size="small"
            variation="link"
          >
            Unohtuiko salasana?
          </Button>
        </View>
      );
    },
  },

  ResetPassword: {
    Header() {
      const { tokens } = useTheme();
      return (
        <Heading
          padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
          level={3}
        >
          {/*   Enter Information: */}
        </Heading>
      );
    },
    Footer() {
      return <Text> </Text>;
    },
  },
  ConfirmResetPassword: {
    Header() {
      const { tokens } = useTheme();
      return (
        <Heading
          padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
          level={3}
        >
          Syötä sähköpostiosoite
        </Heading>
      );
    },
    Footer() {
      return <Text> </Text>;
    },
  },
};

const formFields = {
  signIn: {
    username: {
      label: " ",
      placeholder: "Sähköposti",
    },
    password: {
      label: " ",
      placeholder: "Salasana ",
    },
  },
  resetPassword: {
    username: {
      label: " ",
      placeholder: "Käyttäjätunnus tai sähköpostiosoite",
    },
  },
};

export default function Auth() {
  return (
    <div
      style={{
        // height: "100vh",
        // width: "100vw",
        display: "flex",
        flex: 1,
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Authenticator
        hideSignUp={true}
        formFields={formFields}
        components={components}
      >
        {({ signOut, user }) => <App signOut={signOut} cognitoUser={user} />}
      </Authenticator>
    </div>
  );
}
