import useSWR from "swr";
import { API, graphqlOperation } from "aws-amplify";

import {
  getReservations,
  searchReservations,
  listReservationsByStatus,
} from "../graphql/custom-queries.js";
import { useContext, useMemo } from "react";
import { AppContext } from "../AppContext";
import { getZonedTime } from "../utils/common";
import captureError from "../utils/capture-error";
import { addDays, endOfDay, endOfMonth, isValid, startOfMonth } from "date-fns";
import listAll from "../utils/list-all.js";
import { useFetchAdditionalServices } from "./fetchAdditionalServices.jsx";
import { useFetchVehicles } from "./fetchVehicles.jsx";

// Uses the Lambda function and byGroupByEndTime GSI
const fetchByGroup = async (group, dates, query) => {
  const start = dates[0];
  const end = dates[1];

  if (!group || !isValid(start) || !isValid(end)) {
    return [];
  } // group is mandatory for the fetch

  try {
    const reservations = (
      await API.graphql(
        graphqlOperation(getReservations, {
          startTime: start,
          endTime: end,
          group: group,
          queryMode: query,
        })
      )
    ).data.getReservations;

    if (reservations == null) {
      return;
    }

    return reservations;
  } catch (e) {
    console.error("fetchReservations error", e);
    captureError("Get reservations failed", "GET_RESERVATIONS_FAILED", e);
  }
};

const fetchExternalReservations = async (filter) => {
  console.log("fetching insurance orders");
  try {
    const reservations = await listAll(
      graphqlOperation(searchReservations, {
        limit: 1000,
        filter: filter,
      }),
      "searchReservations"
    );

    if (reservations == null) {
      return;
    }

    return reservations;
  } catch (e) {
    console.error("fetchExternalReservations error", e);
    captureError(
      "Get insurance orders failed",
      "GET_INSURANCE_ORDERS_FAILED",
      e
    );
  }
};

// Uses ElasticSearch
const fetchByOpenSerach = async (
  businessId,
  externalBusinessId,
  dates,
  query
) => {
  const start = dates[0];
  const end = dates[1];

  const generateFilter = () => {
    const filters = {};
    if (query === "startOrEndBetween") {
      //filters.and = [{ startTime: { gte: start } }, { endTime: { lte: end } }];
      filters.or = [
        { startTime: { range: [start, end] } },
        { endTime: { range: [start, end] } },
      ];
    } else if (query === "existBetween") {
      filters.or = [
        { startTime: { range: [start, end] } },
        { endTime: { range: [start, end] } },
        { and: [{ startTime: { lt: start } }, { endTime: { gt: end } }] },
      ];
    } else {
      console.warn("unknown query parameter", {query});
    }

    if (businessId) {
      filters.orgBusinessId = { eq: businessId };
    } else if (externalBusinessId) {
      filters.orgExternalBusinessId = { eq: externalBusinessId };
    }

    return filters;
  };
  try {
    const reservations = await listAll(
      graphqlOperation(searchReservations, {
        filter: generateFilter(),
        limit: 1000,
      }),
      "searchReservations"
    );
    return reservations;
  } catch (e) {
    console.error("fetchReservations error", e);
    captureError("Get reservations failed", "GET_RESERVATIONS_FAILED", e);
  }
};

// Uses ElasticSearch / Specified for economy dialog reservations

const fetchByOpenSearchForEconomy = async ({
  group,
  businessId,
  dates,
  query,
}) => {
  let timezoneOffset = new Date().getTimezoneOffset();

  // Convert the offset to milliseconds
  timezoneOffset *= 60 * 1000;

  const start = new Date(dates[0].getTime() - timezoneOffset).toISOString();
  const end = new Date(
    endOfDay(dates[1]).getTime() - timezoneOffset
  ).toISOString();

  const generateFilter = () => {
    const filters = {};
    if (query === "startAt") {
      //filters.and = [{ startTime: { gte: start } }, { endTime: { lte: end } }];
      filters.or = [{ startTime: { range: [start, end] } }];
    } else if (query === "createdAt") {
      filters.or = [{ createdAt: { range: [start, end] } }];
    } else if (query === "endsAt") {
      filters.or = [{ returnTime: { range: [start, end] } }];
    }

    if (businessId) {
      filters.orgBusinessId = { eq: businessId };
    }

    if (group) {
      filters.group = { eq: group };
    }

    return filters;
  };
  try {
    const reservations = await listAll(
      graphqlOperation(searchReservations, {
        filter: generateFilter(),
        limit: 1000,
      }),
      "searchReservations"
    );
    return reservations;
  } catch (e) {
    console.error("fetchReservations error", e);
    captureError("Get reservations failed", "GET_RESERVATIONS_FAILED", e);
  }
};

// Uses ElasticSearch
const fetchByOpenSearchOrgAdmin = async (organizationId, dates, query) => {
  const start = dates[0];
  const end = dates[1];

  const generateFilter = () => {
    const filters = {};

    //filters.and = [{ startTime: { gte: start } }, { endTime: { lte: end } }];
    filters.or = [
      { startTime: { range: [start, end] } },
      { endTime: { range: [start, end] } },
    ];
    filters.organizationId = { eq: organizationId };

    return filters;
  };
  try {
    const response = await API.graphql(
      graphqlOperation(searchReservations, {
        filter: generateFilter(),
        limit: 1000,
      })
    );
    const reservations = response.data.searchReservations.items;
    return reservations;
  } catch (e) {
    console.error("fetchReservations error", e);
    captureError("Get reservations failed", "GET_RESERVATIONS_FAILED", e);
  }
};

export function useFetchReservations(query, config) {
  const { user, company, group, startDate, endDate, business, timezone } =
    useContext(AppContext);

  const dates = useMemo(() => {
    return [startOfMonth(startDate), addDays(endOfMonth(endDate), 6)];
  }, [startDate, endDate]);

  // selectedBusiness is used by organization admin to show reservations by business
  const businessId = business?.orgBusinessId ?? company?.orgBusinessId;
  const externalBusinessId = user?.externalBusinessId;
  const orgAdmin = user?.role === "ORGANIZATION_ADMIN" && !business;
  let key = null;
  let fetcher = null;

  if (group) {
    key =
      isValid(dates[0]) && isValid(dates[1]) && timezone && group
        ? ["reservationsByGroup", group, dates, query]
        : null;
    fetcher = () => fetchByGroup(group, dates, query);
  }

  if (businessId || externalBusinessId) {
    const searchEntity = businessId ? businessId : externalBusinessId;
    key =
      isValid(dates[0]) && isValid(dates[1]) && searchEntity
        ? ["reservationsByOpenSearch", searchEntity, dates, query]
        : null;

    fetcher = () =>
      fetchByOpenSerach(businessId, externalBusinessId, dates, query);
  }

  if (orgAdmin) {
    key =
      isValid(dates[0]) && isValid(dates[1]) && orgAdmin
        ? ["reservationsByOpenSearch", orgAdmin, dates, query]
        : null;
    fetcher = () =>
      fetchByOpenSearchOrgAdmin(user?.organizationId, dates, query);
  }

  const { data, error, isLoading, isValidating, mutate } = useSWR(
    key,
    fetcher,
    config
  );

  const reservationData = useMemo(() => {
    if (!data) return [];
    return data.map((item) => ({
      ...item,
      startTime: getZonedTime(item.startTime, timezone),
      returnTime: getZonedTime(item.returnTime, timezone),
      endTime: getZonedTime(item.endTime, timezone),
    }));
  }, [data, timezone]);

  const unassignedReservations = useMemo(() => {
    if (!reservationData) return [];
    return reservationData.filter(
      (item) =>
        item?.wildCardVehicles?.length > 0 &&
        item?.reservationVehicles?.length === 0
    );
  }, [reservationData]);
  return {
    reservations: reservationData,
    unassignedReservations: unassignedReservations,
    reservationsLoading: isLoading,
    reservationsValidating: isValidating,
    reservationsError: error,
    reservationsMutate: mutate,
  };
}

export function useFetchExternalReservations() {
  const { user, business, timezone, startDate, endDate } =
    useContext(AppContext);

  const filter = useMemo(() => {
    const filters = {};
    if (!business && !user?.externalBusinessId) return null;
    if (!business && user?.externalBusinessId) {
      filters.orgExternalBusinessId = { eq: user?.externalBusinessId };
      filters.or = [{ startTime: { range: [startDate, endDate] } }];
    }

    if (business) {
      filters.orgBusinessId = { eq: business?.id };
      filters.status = { eq: "EXTERNAL_PENDING_DEALER" };
    }

    return filters;
  }, [user, business, startDate, endDate]);

  const id = business?.id || user?.externalBusinessId;
  const { data, error, isLoading, isValidating, mutate } = useSWR(
    id ? [id, "reservationsByStatus"] : null,
    () => fetchExternalReservations(filter)
  );

  const externalReservationData = useMemo(() => {
    if (!data) return [];
    return data.map((item) => ({
      ...item,
      createdAt: getZonedTime(item.createdAt, timezone),
      startTime: getZonedTime(item.startTime, timezone),
      returnTime: getZonedTime(item.returnTime, timezone),
      endTime: getZonedTime(item.endTime, timezone),
    }));
  }, [data, timezone]);

  return {
    externalReservations: externalReservationData,
    externalReservationsLoading: isLoading,
    externalReservationsValidating: isValidating,
    externalReservationsError: error,
    externalReservationsMutate: mutate,
  };
}

export function useFetchReservationsForEconomy(query, economyQueryDates) {
  const { company, group, business, timezone, showAllCompanies } =
    useContext(AppContext);
  const { additionalServices: fetchedAdditionalServices } =
    useFetchAdditionalServices();
  const { vehicleData } = useFetchVehicles();
  const allVehicles = vehicleData;
  // selectedBusiness is used by organization admin to show reservations by business
  const businessId = business?.orgBusinessId ?? company?.orgBusinessId;
  const legacyUser = group;
  let key = null;
  let fetcher = null;

  if (businessId || legacyUser) {
    const searchEntity = businessId ? businessId : legacyUser;
    key =
      isValid(economyQueryDates[0]) &&
      isValid(economyQueryDates[1]) &&
      searchEntity
        ? ["reservationsByOpenSearch", searchEntity, economyQueryDates, query]
        : null;
    fetcher = () =>
      fetchByOpenSearchForEconomy({
        businessId: businessId,
        dates: economyQueryDates,
        query: query,
        group: group,
      });
  }

  const { data, error, isLoading, isValidating, mutate } = useSWR(key, fetcher);

  const reservationData = useMemo(() => {
    if (!data) return [];
    return data.map((item) => ({
      ...item,
      startTime: getZonedTime(item.startTime, timezone),
      returnTime: getZonedTime(item.returnTime, timezone),
      endTime: getZonedTime(item.endTime, timezone),
    }));
  }, [data, timezone]);

  const reservedVehicles = useMemo(() => {
    if (!allVehicles) return [];
    const vehicleMap = reservationData.reduce((map, reservation) => {
      const { reservationVehicles, paymentStatus, startTime, returnTime } =
        reservation;

      if (
        (showAllCompanies && reservationVehicles) ||
        (reservation?.companyId === company?.id && reservationVehicles)
      ) {
        reservationVehicles.forEach((reservationVehicle) => {
          const { id, price } = reservationVehicle;

          // check if vehicle is populated, if not, create a new one.
          if (!map.has(id)) {
            map.set(id, {
              ...reservationVehicle,
              total: 0,
              paymentsCash: [],
              paymentsVismaPay: [],
              paymentsInvoice: [],
              quantity: 0,
              utilizationRate: 0, // Initialize utilizationRate
              name:
                reservationVehicle?.name ??
                allVehicles.find((v) => v.id === reservationVehicle.id)?.name ??
                `Tuotteen tietoja ei löydy.`, // change this to reservationVehicle.id for debugging
              registrationPlate:
                reservationVehicle?.registrationPlate ??
                allVehicles.find((v) => v.id === reservationVehicle.id)
                  ?.registrationPlate,
              companyId:
                reservationVehicle?.companyId ??
                allVehicles.find((v) => v.id === reservationVehicle.id)
                  ?.companyId,
              category:
                reservationVehicle?.category ??
                allVehicles.find((v) => v.id === reservationVehicle.id)
                  ?.category,
              image: allVehicles.find((v) => v.id === reservationVehicle.id)
                ?.image,
            });
          }

          const vehicleData = map.get(id);
          vehicleData.total += price;
          vehicleData.quantity += 1; // Increment the quantity for each reservation

          // Calculate reservation duration in milliseconds
          const reservationDuration = returnTime - startTime;

          // Conditionally add values based on paymentStatus
          if (!paymentStatus || paymentStatus === "CANCELLED") {
            vehicleData.paymentsCash.push(price);
          }

          if (paymentStatus === "COMPLETED") {
            vehicleData.paymentsVismaPay.push(price);
          }

          if (
            reservation.paymentStatus === "PENDING_INVOICE" ||
            reservation.paymentStatus === "COMPLETED_INVOICE"
          ) {
            vehicleData.paymentsInvoice.push(price);
          }

          // Update utilizationRate
          vehicleData.utilizationRate += reservationDuration;
        });
      }
      return map;
    }, new Map());

    const uniqueVehicles = Array.from(vehicleMap.values());
    uniqueVehicles.sort((a, b) => b.total - a.total);
    // Calculate utilizationRate as a percentage
    const vehiclesWithUtilization = uniqueVehicles.map((vehicle) => {
      const utilizationRate = Math.round(
        (vehicle.utilizationRate /
          (economyQueryDates[1] - economyQueryDates[0])) *
          100
      ).toFixed(2);
      return {
        ...vehicle,
        utilizationRate: utilizationRate <= 100 ? utilizationRate : 100,
      };
    });
    return vehiclesWithUtilization;
  }, [
    reservationData,
    economyQueryDates,
    company,
    showAllCompanies,
    allVehicles,
  ]);

  const reservationServices = useMemo(() => {
    const vehicleMap = reservationData.reduce((map, reservation) => {
      const { additionalServices, paymentStatus } = reservation;

      if (
        (showAllCompanies && additionalServices) ||
        (reservation?.companyId === company?.id && additionalServices)
      ) {
        additionalServices.forEach((service) => {
          const { key, price } = service;

          // check if vehicle is populated, if not, create a new one.
          if (!map.has(key)) {
            map.set(key, {
              ...service,
              total: 0,
              paymentsCash: [],
              paymentsVismaPay: [],
              paymentsInvoice: [],
              quantity: 0,
              description:
                service?.description ??
                fetchedAdditionalServices.find(
                  (additionalService) => additionalService.id === service.key
                )?.description,
            });
          }

          const serviceData = map.get(key);
          serviceData.total += price;
          serviceData.quantity += 1; // Increment the quantity for each reservation
          // Conditionally add values based on paymentStatus
          if (!paymentStatus || paymentStatus === "CANCELLED") {
            serviceData.paymentsCash.push(price);
          }

          if (paymentStatus === "COMPLETED") {
            serviceData.paymentsVismaPay.push(price);
          }
          if (
            reservation.paymentStatus === "PENDING_INVOICE" ||
            reservation.paymentStatus === "COMPLETED_INVOICE"
          ) {
            serviceData.paymentsInvoice.push(price);
          }
        });
      }
      return map;
    }, new Map());

    const uniqueServices = Array.from(vehicleMap.values());
    uniqueServices.sort((a, b) => b.total - a.total);
    return uniqueServices;
  }, [reservationData, company, showAllCompanies, fetchedAdditionalServices]);

  const paymentMethods = useMemo(() => {
    if (!reservationData) return [];

    const paymentMap = reservationData.reduce(
      (map, reservation) => {
        const {
          reservationVehicles,
          additionalServices,
          paymentStatus,
          companyId,
        } = reservation;
        if (reservationVehicles) {
          // Combine both reservationVehicles and additionalServices
          const allItems = [...reservationVehicles, ...additionalServices];

          if (
            (showAllCompanies && additionalServices) ||
            (companyId === company?.id && additionalServices)
          ) {
            allItems.forEach((item) => {
              const { price } = item;

              // Conditionally add values based on paymentStatus
              if (!paymentStatus || paymentStatus === "CANCELLED") {
                map.paymentsCash.total += price;
              }

              if (paymentStatus === "COMPLETED") {
                map.paymentsVismaPay.total += price;
              }

              if (
                reservation.paymentStatus === "PENDING_INVOICE" ||
                reservation.paymentStatus === "COMPLETED_INVOICE"
              ) {
                map.paymentsInvoice.total += price;
              }
            });
          }
        }
        return map;
      },
      {
        paymentsCash: { name: "Käteismaksut", total: 0 },
        paymentsVismaPay: { name: "Verkkomaksut", total: 0 },
        paymentsInvoice: { name: "Laskutus", total: 0 },
      }
    );

    // Convert the Map values to an array and sort by total payment value
    const sortedPaymentTypes = Object.entries(paymentMap)
      .map(([paymentType, { name, total }]) => ({ paymentType, name, total }))
      .sort((a, b) => b.total - a.total);

    return sortedPaymentTypes;
  }, [company?.id, reservationData, showAllCompanies]);

  return {
    reservations: reservationData,
    reservedVehicles: reservedVehicles,
    reservationServices: reservationServices,
    paymentMethods: paymentMethods,
    reservationsLoading: isLoading,
    reservationsValidating: isValidating,
    reservationsError: error,
    reservationsMutate: mutate,
  };
}
